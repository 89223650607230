import { Pipe, PipeTransform } from '@angular/core';
import { DURATION } from './../enums/duration.enum';

@Pipe({
  name: 'duration'
})
/** Translates the abbreviation to the understandable Word for the Duration */
export class DurationPipe implements PipeTransform {

  transform(value: any, duration): any {
    let result = value;
    if (DURATION[duration]) {
      result += ' ' + DURATION[duration];
    }
    return result;
  }

}
