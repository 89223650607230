"use strict";

var __createBinding = this && this.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  Object.defineProperty(o, k2, {
    enumerable: true,
    get: function () {
      return m[k];
    }
  });
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});
var __setModuleDefault = this && this.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});
var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
  __setModuleDefault(result, mod);
  return result;
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpMethod = void 0;
var node_fetch_1 = __importStar(require("node-fetch"));
var VERSION = "3.0.0";
var KameleoonUtils = /** @class */function () {
  function KameleoonUtils() {
    var _this = this;
    this.doRequest = function (_a) {
      var url = _a.url,
        method = _a.method,
        data = _a.data,
        callback = _a.callback,
        _b = _a.addKameleoonClientHeader,
        addKameleoonClientHeader = _b === void 0 ? true : _b,
        userAgent = _a.userAgent,
        dispatchEvent = _a.dispatchEvent;
      if (dispatchEvent) {
        var headers = new Headers({
          "Content-Type": "application/x-www-form-urlencoded"
        });
        if (addKameleoonClientHeader) {
          headers.append("Kameleoon-Client", "sdk/js/".concat(VERSION));
        }
        if (userAgent) {
          headers.append("User-Agent", userAgent.value);
        }
        dispatchEvent({
          url: url,
          method: method,
          headers: headers,
          data: data !== null && data !== void 0 ? data : null
        }).then(function (response) {
          return response.text();
        }).then(function (responseText) {
          return callback && callback(responseText);
        });
      } else {
        var headers = new node_fetch_1.Headers({
          "Content-Type": "application/x-www-form-urlencoded"
        });
        if (addKameleoonClientHeader) {
          headers.append("Kameleoon-Client", "sdk/js/".concat(VERSION));
        }
        if (userAgent) {
          headers.append("User-Agent", userAgent.value);
        }
        var eventRequest = new node_fetch_1.Request(url, {
          method: method,
          body: data !== null && data !== void 0 ? data : null,
          headers: headers
        });
        (0, node_fetch_1.default)(eventRequest).then(function (response) {
          return response.text();
        }).then(function (responseText) {
          return callback && callback(responseText);
        }).catch(function (err) {
          return console.log(err);
        });
      }
    };
    this.computeStringSha256 = function (string) {
      var i = 1,
        j;
      var K = [],
        H = [];
      while (++i < 18) {
        for (j = i * i; j < 312; j += i) {
          K[j] = 1;
        }
      }
      function x(num, root) {
        return Math.pow(num, 1 / root) % 1 * 4294967296 | 0;
      }
      for (i = 1, j = 0; i < 313;) {
        if (!K[++i]) {
          H[j] = x(i, 2);
          K[j++] = x(i, 3);
        }
      }
      function S(X, n) {
        return X >>> n | X << 32 - n;
      }
      var HASH = H.slice(i = 0),
        W = [],
        m = [];
      var s = _this.encodeUTF8(string),
        l = s.length,
        a,
        y,
        z;
      for (; i < l;) m[i >> 2] |= (s.charCodeAt(i) & 0xff) << 8 * (3 - i++ % 4);
      l *= 8;
      m[l >> 5] |= 0x80 << 24 - l % 32;
      m[z = l + 64 >> 5 | 15] = l;
      for (i = 0; i < z; i += 16) {
        a = HASH.slice(j = 0, 8);
        for (; j < 64; a[4] += y) {
          if (j < 16) {
            W[j] = m[j + i];
          } else {
            W[j] = (S(y = W[j - 2], 17) ^ S(y, 19) ^ y >>> 10) + (W[j - 7] | 0) + (S(y = W[j - 15], 7) ^ S(y, 18) ^ y >>> 3) + (W[j - 16] | 0);
          }
          var b = void 0;
          a.unshift((y = (a.pop() + (S(b = a[4], 6) ^ S(b, 11) ^ S(b, 25)) + ((b & a[5] ^ ~b & a[6]) + K[j]) | 0) + (W[j++] | 0)) + (S(l = a[0], 2) ^ S(l, 13) ^ S(l, 22)) + (l & a[1] ^ a[1] & a[2] ^ a[2] & l));
        }
        for (j = 8; j--;) HASH[j] = a[j] + HASH[j];
      }
      for (s = ""; j < 63;) s += (HASH[++j >> 3] >> 4 * (7 - j % 8) & 15).toString(16);
      return s;
    };
    this.encodeUTF8 = function (string) {
      string = string.replace(/\r\n/g, "\n");
      var utftext = "";
      for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);
        if (c < 128) {
          utftext += String.fromCharCode(c);
        } else if (c > 127 && c < 2048) {
          utftext += String.fromCharCode(c >> 6 | 192);
          utftext += String.fromCharCode(c & 63 | 128);
        } else {
          utftext += String.fromCharCode(c >> 12 | 224);
          utftext += String.fromCharCode(c >> 6 & 63 | 128);
          utftext += String.fromCharCode(c & 63 | 128);
        }
      }
      return utftext;
    };
  }
  return KameleoonUtils;
}();
var HttpMethod;
(function (HttpMethod) {
  HttpMethod["GET"] = "GET";
  HttpMethod["POST"] = "POST";
})(HttpMethod = exports.HttpMethod || (exports.HttpMethod = {}));
exports.default = new KameleoonUtils();