<div class="bdo-container bdo-space--bottom">
  <h3>{{ 'password.forgot.title' | translate }}</h3>
</div>

<div class="bdo-container--single">

  <div class="bdo-space--bottom">
    {{ 'password.forgot.description' | translate }}
  </div>

  <div *ngIf="errorShown">
    <bdo-info-box>
      {{ 'password.forgot.error.default' | translate }}
    </bdo-info-box>
  </div>

  <form class="bdo-form-container" (ngSubmit)="send()">
    <bdo-loading *ngIf="sendingData"></bdo-loading>
    <fieldset>
      <div class="bdo-form-item-container">
        <bdo-input
          [id]="'username'"
          [attr.data-qa]="'username'"
          [formControl]="$any(forgotPasswordForm.get('username'))"
          [labelText]="'register.username.title' | translate"
          [placeholder]="'register.username.title' | translate"
          [tooltipContent]="'password.forgot.usernameInfo' | translate"
        >
        </bdo-input>
      </div>
      <div class="bdo-space--bottom">
        <button class="bdo-button-primary bdo-button--text re-mb-30" type="submit"
          [disabled]="sendingData" >
          {{ 'general.action.submit' | translate }}
        </button>
        <a class="bdo-nolink" [routerLink]="['/login']" data-qa="cancel">
          <div class="bold"> {{ 'general.action.cancel' | translate }}</div>
        </a>
      </div>

      <bdo-text-link-icon [ngClass]="'bdo-form__helptext'"
        [iconPosition]="'right'"
        [linkText]="'username.forgot.title' | translate"
        [routerUrl]="'/benutzername-vergessen'"
        [tracking]="[TRACKING.LOCATION.PASSWORD_FORGOT, TRACKING.ACTION.GOTO, TRACKING.LOCATION.USERNAME_FORGOT]"
        >
      </bdo-text-link-icon>
    </fieldset>
  </form>

</div>


<bdo-simple-text-popup [isVisible]="mailSent" (closePopupEvent)="closeMessage()"
[title]="'password.forgot.success.title' | translate" [icon]="'success'">
  <div class="popup--success">
    {{ 'password.forgot.success.description' | translate }}
  </div>
</bdo-simple-text-popup>
