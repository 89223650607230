<h4 class="re-container-with-margin re-mb-20 re-mt-60 md:re-mb-30 re-ml-20 md:re-ml-auto">
  {{ 'personaldata.optin.title' | translate }}
</h4>

<div class="re-container-with-margin re-mx-0 md:re-mx-auto" data-qa="optinErrorbox">
  <bdo-info-box *ngIf="state === LoadingState.ERROR">
    {{ 'general.error.error' | translate }}
  </bdo-info-box>
</div>

<bdo-edit-card
  class="re-relative"
  [isEditable]="!isInEditMode"
  [disabled]="disabled"
  (edit)="onEdit()">
  <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>

  <bdo-checkbox *ngIf="checkedOptin || isInEditMode"
    [checked]="checkedOptin"
    [disabled]="!isInEditMode"
    (change)="checkedOptin = !checkedOptin"
    data-qa="communicationAcceptedCheckbox">
    <div [innerHTML]="(isInEditMode ? 'legal.accepted' :
                      'personaldata.optin.acceptedShort') | translate:
                      { companyName: tenantService.getCurrentTenantData().companyName }">
    </div>
  </bdo-checkbox>
  <div *ngIf="!checkedOptin && !isInEditMode">
    {{ 'personaldata.optin.deniedShort' | translate:
       { companyName: tenantService.getCurrentTenantData().companyName } }}
  </div>

  <div *ngIf="isInEditMode">
    <div class="re-mb-15 re-mt-45">
      <button class="bdo-button-primary bdo-button--text" type="submit" (click)="onSave()">
        {{ 'general.action.save' | translate }}
      </button>
    </div>

    <button class="bdo-nolink" data-qa="cancel" type="button" (click)="onEditDone()">
      <div class="bold">{{ 'general.action.cancel' | translate }}</div>
    </button>
  </div>

</bdo-edit-card>
