<div *ngIf="isVisible" class="dialog re-min-w-mobile" [@fadeAnimation]>
  <div class="re-relative md:re-h-screen lg:re-h-auto">
    <div class="re-mx-auto bdo-space--bottom re-w-75 re-h-75" *ngIf="icon">
      <svg-icon *ngIf="icon === 'success'" class="re-w-50 re-text-success re-stroke-current re-fill-current" src="assets/icons/system_icons/ok-circle.svg"></svg-icon>
      <svg-icon *ngIf="icon === 'error'" class="re-w-50 re-text-error re-stroke-current re-fill-current" src="assets/icons/system_icons/alert.svg"></svg-icon>
      <svg-icon *ngIf="icon === 'warning'" class="re-w-50 re-text-alert re-stroke-current re-fill-current" src="assets/icons/system_icons/alert.svg"></svg-icon>
    </div>
    <h4 *ngIf="title" class="bdo-space--top bdo-space--bottom center"> {{title}} </h4>
    <ng-content></ng-content>
  </div>
  <button *ngIf="isManuallyClosable" (click)="onClose()" class="dialog__close-btn"
          [ngClass]="{'re-w-25 re-h-25 re-text-white hover:re-text-primary-hover': closeButtonModifier,
          're-w-20 re-h-20 hover:re-text-secondary-hover': !closeButtonModifier}"
          title="{{ 'general.action.close' | translate }}"
          type="button">
    <svg-icon src="assets/icons/close.svg"></svg-icon>
  </button>
</div>
<div *ngIf="isVisible" class="overlay" (click)="onClose()" [@fadeAnimation]></div>
