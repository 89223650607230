import { Component, Input } from '@angular/core';
import { Forderung } from '../../../../../assets/js/com/ts_api_client';
import { CustomerStoreService } from '../../../services/customer-store.service';
import { BankAccountAssignments } from './models/bank-account-assignments.interface';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-banking-tile',
  templateUrl: './banking-tile.component.html',
  styleUrls: ['./banking-tile.component.scss']
})
export class BankingTileComponent {
  @Input() kundennummer: string;
  @Input() payments: Forderung;
  @Input() bankAccountAssignments: BankAccountAssignments;
  public TRACKING = TRACKING;

  constructor(
    public translateService: TranslateService,
    public customerStore: CustomerStoreService,
  ) { }

  getTileTitle(): string {
    if (!this.payments || !this.payments.offeneSumme) {
      return this.translateService.instant('dashboard.banking.title');
    } else if (this.payments.offeneSumme > 0) {
      return this.translateService.instant('dashboard.banking.claims');
    } else {
      return this.translateService.instant('dashboard.banking.creditTitle');
    }
  }
}
