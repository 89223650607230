import { Component, OnInit } from '@angular/core';
import { TenantService } from '../../services/tenant.service';
import { TRACKING } from '../../enums/trackingParts.enum';
import { TrackingService } from '../../services/tracking.service';

@Component({
  selector: 'bdo-logout-success',
  templateUrl: './logout-success.component.html',
  styleUrls: ['./logout-success.component.scss']
})
export class LogoutSuccessComponent implements OnInit {
  public TRACKING = TRACKING;

  constructor(
    private trackingService: TrackingService, public tenantService: TenantService
  ) { }

  ngOnInit() {
    this.trackingService.postTracking(TRACKING.LOCATION.LOGOUT_SUCCESS, TRACKING.ACTION.ENTER);
  }

  goToMainPage() {
    this.trackingService.postTracking(TRACKING.LOCATION.LOGOUT_SUCCESS, TRACKING.ACTION.GOTO, TRACKING.LOCATION.HOMEPAGE);
  }
}
