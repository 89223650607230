<div class="re-container-headline re-mb-30 lg:re-mb-60">
  <h3>{{ 'payment.title' | translate }}</h3>
</div>

<form (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" bdoScrollToInvalidControl [formGroup]="paymentMoveForm">
  <div class="re-bg-white re-w-full re-p-20 re-pt-30 re-pb-30 re-mb-45
              md:re-w-600 md:re-pt-45 md:re-pb-45 md:re-mb-60 md:re-mx-auto
              lg:re-w-900">

    <div class="re-form-item-container re-mb-30">
      <p *ngIf="!availableDivisionInfos?.onlyExternalAreas; else onlyFinalInvoice" data-qa="intro-text">
        {{ 'payment.description' | translate }}
      </p>
      <ng-template #onlyFinalInvoice>
        <p data-qa="intro-text">{{ 'payment.finalInvoice.description' | translate }}</p>
      </ng-template>
    </div>

    <bdo-payment-form
      [trackingSepaForm]="formTracking"
      [paymentForm]="paymentMoveForm">
    </bdo-payment-form>

    <div class="re-form-item-container" *ngIf="!availableDivisionInfos?.onlyExternalAreas">
      <p class="bold re-mb-10">{{ 'payment.finalInvoice.hintTitle' | translate }}</p>
      <p>{{ 'payment.hintText' | translate }}</p>
    </div>
  </div>

  <div class="re-w-full
              md:re-w-600 md:re-mx-auto
              lg:re-w-900">
    <div class="md:re-pl-0 re-pl-15">
      <bdo-validate-submit
        [textButtonLong]="'summary.linkTo' | translate"
      ></bdo-validate-submit>
      <div class="re-pt-20">
        <bdo-text-icon
         [iconPosition]="'left'"
         [text]="'general.action.back' | translate"
         (itemClicked)="location.back()"
        >
        </bdo-text-icon>
      </div>
    </div>
  </div>
</form>
