<div>
  <div class="bdo-container bdo-section-headline">
    <bdo-section-headline>{{ 'dashboard.moreServices.title' | translate }}</bdo-section-headline>
  </div>

  <div class="bdo-container-notablet--single">
    <bdo-dashboard-tile
      [tileTitle]="'dashboard.moreServices.subtitle' | translate"
    >

      <div class="re-mb-15" *ngIf="!isRestricted">
        <bdo-text-link-icon
          [iconPosition]="'right'"
          [linkText]="'dashboard.moreServices.contractEnd' | translate"
          [routerUrl]="linkToSelection ? '/abmelden/' + accountId + '/auswahl' : '/abmelden/' + accountId "
          [tracking]="[TRACKING.LOCATION.DASHBOARD, TRACKING.ACTION.GOTO, TRACKING.LOCATION.CONTRACT_END_RECENT]"
        ></bdo-text-link-icon>
      </div>
      <div class="re-mb-15">
        <bdo-text-link-icon
          data-qa="contact"
          [iconPosition]="'right'"
          [href]="currentTenant === Tenant.Rheinenergie ? tenantService.getCurrentTenantData().hrefContact : undefined"
          [openInNewTab]="currentTenant === Tenant.Rheinenergie ? true : false"
          [linkText]="'dashboard.moreServices.contact' | translate"
          [routerUrl]="currentTenant !== Tenant.Rheinenergie ? ('/kontakt/' + accountId)  : undefined"
          [tracking]="[TRACKING.LOCATION.DASHBOARD, TRACKING.ACTION.GOTO, TRACKING.LOCATION.CONTACT]"
          >
          </bdo-text-link-icon>
      </div>
      <div>
        <bdo-text-link-icon
          [iconPosition]="'right'"
          [href]="tenantService.getCurrentTenantData().hrefPriceCap"
          [openInNewTab]="true"
          [linkText]="'dashboard.moreServices.priceCap' | translate"
          [tracking]="[TRACKING.LOCATION.DASHBOARD, TRACKING.ACTION.GOTO, TRACKING.LOCATION.EXTERNAL_PRICECAP]"
          >
          </bdo-text-link-icon>
      </div>
      <div class="re-mt-15" *ngIf="tenantService.getCurrentTenantData().hrefBillExplanation">
        <bdo-text-link-icon
          [iconPosition]="'right'"
          [href]="tenantService.getCurrentTenantData().hrefBillExplanation"
          [openInNewTab]="true"
          [linkText]="'dashboard.moreServices.billExplanation' | translate"
          [tracking]="[TRACKING.LOCATION.DASHBOARD, TRACKING.ACTION.GOTO, TRACKING.LOCATION.EXTERNAL_BILL_EXPLANATION]"
          >
          </bdo-text-link-icon>
      </div>
    </bdo-dashboard-tile>

  </div>
</div>
