
<cookie-banner-component
  font-family-regular="Suisse Light"
  font-family-bold="Suisse Medium"
  ref="cookieBanner"
  [colorPrimary]="colorPrimary"
  [colorSecondary]="colorSecondary"
  [colorText]="colorText"
  color-backdrop="rgba(24, 24, 24, 0.6)"
  img-src="./img/logo.svg"
  >
  <p
    slot="topic"
    class="bold re-font-normal re-text-l re-leading-8">
    {{ 'cookiebanner.title' | translate }}
  </p>
  <span slot="description">
    <span [innerHTML]="'cookiebanner.description' | translate :
      { href: tenantService.getCurrentTenantData().hrefDataPrivacy } "
    ></span>
  </span>
  <span
    slot="primary-action-text"
    class="bold re-font-normal re-leading-6">
    {{ 'general.action.accept' | translate }}
  </span>
  <span
    slot="secondary-action-text"
    class="bold re-font-normal re-leading-6">
    {{ 'general.action.reject' | translate }}
  </span>
</cookie-banner-component>
