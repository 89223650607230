<div *ngIf="supplierControl">
  <bdo-input [formControl]="$any(supplierControl)"
              ngDefaultControl
              [inputType]="INPUT_TYPE.TEXT"
              [labelText]="'delivery.situation.details.previousSupplier' | translate"
              [enableAutocomplete]="true"
              [id]="id"
              #previousSupplier>
  </bdo-input>

  <bdo-autosuggest
    [dataSource$]="suppliers$"
    [searchString$]="searchString$"
    [for]="previousSupplier"
    [sort]="sortAutosuggest"
    (itemSelected)="onItemSelected($event)">
  </bdo-autosuggest>
</div>
