import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../../services/storage.service';
import { TariffSelection } from '../../../../../shared/models/tariff-selection';

@Component({
  selector: 'bdo-tariff-total-summary',
  templateUrl: './tariff-total-summary.component.html',
  styleUrls: ['./tariff-total-summary.component.scss']
})
export class TariffTotalSummaryComponent implements OnInit {

  public totalPrice: number = 0;
  private tariffSelections: TariffSelection[];

  ngOnInit(): void {

    const tariffSelections: TariffSelection[] = StorageService.getTariffSelections();
    if (tariffSelections) {
      this.tariffSelections = tariffSelections;

      this.tariffSelections.forEach(division => {
        this.totalPrice = this.totalPrice + division.monthlyPrice;
      });
    }
  }
}
