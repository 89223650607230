<div class="re-summary-row">
  <div [innerHTML]="((contextContractEnd ?
       'summary.address.oldHome' : 'summary.address.endDate') | translate)">
  </div>
  <div>{{ data?.date | date }}</div>
</div>
<div class="re-summary-row">
  <div>{{ 'address.postCode.postCodeAndCity' | translate }}</div>
  <div>{{ data?.addressData.street.city?.postCode }}, {{ data?.addressData.street.city?.name }}</div>
</div>
<div class="re-summary-row">
  <div>{{ 'address.street.streetAndHousenumber' | translate }}</div>
  <div>{{ data?.addressData.street.name }} {{ data?.addressData.houseNum }}</div>
</div>

<div *ngFor="let meter of meterReadings">
  <h5 class="re-mb-30 re-mt-45">
    {{ 'general.tariff.meterWithDivision' | translate: { division: meter?.meterReading?.sparte | verbrauchstyp } }}
  </h5>
  <div class="re-summary-row">
    <div>{{ 'general.customerData.meternumber' | translate }}</div>
    <div>{{meter?.meterReading?.meterNumber}}</div>
  </div>
  <ng-container *ngIf="meter?.meterReading?.zaehlwerkeListe?.length">
    <div class="re-summary-row" *ngFor="let register of meter?.meterReading?.zaehlwerkeListe">
      <div>{{ 'meterreadings.meterreading' | translate }} {{ ['HT', 'NT'].includes(register.typ) ? ('' | zaehlwerktyp: register.typ) : '' }}</div>
      <div>{{ register.wert ? register.wert + ' ' + [register.einheit | unitPretty] :
        (meter.isSmartMeter ? 'meterreadings.smartmeter.readingInfoShort' : 'meterreadings.info.enterLaterShort') | translate }}
      </div>
    </div>
  </ng-container>
</div>
