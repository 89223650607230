import { Component, OnInit } from '@angular/core';
import { AddressData, FullAddress } from '../../../../../assets/js/com/ts_api_client';
import { AuthDataStorage } from '../../../models/AuthData.storage';
import { BdoApiService } from '../../../services/bdo-api.service';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { AccountWithAddress } from '../../contract-overview/contract-overview.component';
import { DropdownItem } from '../../../models/dropdownItem';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { Utilities } from '../../../../shared/utils/utilities';

@Component({
  selector: 'bdo-contract-cancel-selection',
  templateUrl: './contract-cancel-selection.component.html',
  styleUrls: ['./contract-cancel-selection.component.scss']
})
export class ContractCancelSelectionComponent implements OnInit {

  public Utilities = Utilities;
  public dropdownAccounts: Array<DropdownItem> = [];
  public selectedAccount: AccountWithAddress;
  public selectedAddress: AddressData;
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public authMeterNumber: string = '';
  private authAccountId: string = '';
  private allAccountsWithAddress: Array<AccountWithAddress> = [];

  constructor(
    public apiService: BdoApiService,
    private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_CANCEL_CONTRACTS, TRACKING.ACTION.ENTER);

    const authData = StorageService.getValue<AuthDataStorage>(ALLOWED_KEYS.AUTH_DATA);
    this.authAccountId = authData?.checkIdentificationData?.accountId;
    this.authMeterNumber = authData?.checkIdentificationData?.meterNumberOrRegisterCode;
    this.state = LOADING_STATE.LOADING;

    // Future: Search for all accounts and then get address to all accounts
    this.apiService.getAddressAnonymous(this.authAccountId, this.authMeterNumber)
      .subscribe({ next: (address: FullAddress) => {
        const dataAccount: AccountWithAddress = {
          'accountId': this.authAccountId,
          'displayAddress': address.premiseAddress.street.name + ' ' + address.premiseAddress.houseNum + ', ' +
                            address.premiseAddress.street.city?.postCode + ' ' + address.premiseAddress.street.city?.name
        };
        this.allAccountsWithAddress.push(dataAccount);

        const dataDropDown: DropdownItem = {
          'value': this.authAccountId,
          'label': this.authAccountId + (this.allAccountsWithAddress?.length > 1 ? ' / ' + dataAccount.displayAddress : '')
        };
        this.dropdownAccounts.push(dataDropDown);

        // Future: get the right account (if (dataAccount.accountID === this.authAccountId))
        this.selectedAccount = dataAccount;
        this.selectedAddress = {
          postCode: address?.premiseAddress?.street?.city?.postCode,
          cityName: address?.premiseAddress?.street?.city?.name,
          streetName: address?.premiseAddress?.street?.name,
          houseNum: address?.premiseAddress?.houseNum,
        };
        this.state = LOADING_STATE.IDLE;
      }, error: (error: unknown) => {
        this.state = LOADING_STATE.ERROR;
    } });
  }

  // For the future
  updateAccount(newAccount: string) {
    this.selectedAccount = this.allAccountsWithAddress?.find(account => account.accountId === newAccount);
  }
}
