import { Component, OnInit, Input } from '@angular/core';
import { TenantService } from '../../../services/tenant.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { Environment } from '../../../../../environments/environment';
import { TENANT } from '../../../enums/tenant.enum';

@Component({
  selector: 'bdo-more-services-tile',
  templateUrl: './more-services-tile.component.html',
  styleUrls: ['./more-services-tile.component.scss']
})
export class MoreServicesTileComponent implements OnInit {
  @Input() isRestricted: boolean = false;
  @Input() accountId: string;
  public TRACKING = TRACKING;
  public Tenant = TENANT;
  public currentTenant = Environment.tenant;
  public linkToSelection: boolean = true;

  constructor(public tenantService: TenantService) { }

  ngOnInit() {
    this.linkToSelection = this.currentTenant === TENANT.Rheinenergie || this.currentTenant === TENANT.Belkaw || this.currentTenant === TENANT.StadtwerkeSanktAugustin;
  }

}
