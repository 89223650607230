import { Component, OnInit } from '@angular/core';
import { AddressData, SituationData, TariffData } from '../../../../../assets/js/com/ts_api_client';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { TenantService } from '../../../services/tenant.service';
import { uniq as _uniq } from 'lodash';
import { BdoApiService } from '../../../services/bdo-api.service';
import { LocationStrategy } from '@angular/common';
import { TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { TrustpilotService } from '../../../services/trustpilot.service';
import { Utilities } from '../../../../shared/utils/utilities';
import { TariffAdvisorService } from '../../../services/tariff-advisor.service';


@Component({
  selector: 'bdo-contract-new-confirmation',
  templateUrl: './contract-new-confirmation.component.html',
  styleUrls: ['./contract-new-confirmation.component.scss']
})
export class ContractNewConfirmationComponent implements OnInit {
  public email: string = '';
  public situationData: SituationData;
  public previousSuppliers: Array<string> = [];
  public TRACKING = TRACKING;
  public trustpilotUniqueUrl: string;
  public productId: string;
  public addressData: AddressData;
  public tariffData: TariffData;
  private trustpilotSecureToken: string;

  constructor(
    public tenantService: TenantService,
    public tariffService: TariffAdvisorService,
    private apiService: BdoApiService,
    private locationStrategy: LocationStrategy,
    private trackingService: TrackingService,
    private trustpilotService: TrustpilotService
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_NEW_DONE, TRACKING.ACTION.ENTER);
    this.trustpilotSecureToken = StorageService.getTrustPilotToken();
    this.addressData = StorageService.getAddressData();
    this.situationData = StorageService.getSituationData();
    this.tariffData = StorageService.getTariffData()?.[0]; // We can only add 1 tariff at a time

    // Get list of unique suppliers not null
    this.previousSuppliers = _uniq(this.situationData?.meterData?.map( item => {
      return item.previousSupplier;
    }).filter(Boolean));
    this.apiService.getEmail().subscribe( { next: res => {
      this.email = res.hasEmail ? res.email : '';
    } });
    this.trustpilotUniqueUrl = this.trustpilotService.buildTrustpilotUniqueUrl(this.trustpilotSecureToken);

    // Disable Browser back History to prevent going back into process after finishing it
    Utilities.disableBrowsersBackButton(this.locationStrategy);
    window.sessionStorage.clear();
  }

}
