import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SwitchCardConfiguration } from '../../../../shared/models/switchCardConfiguration';
import { FormGroup } from '@angular/forms';
import { AddressFormComponent } from '../../address-form/address-form.component';
import { AddressData } from '../../../../../assets/js/com/ts_api_client';
import { StorageService, ALLOWED_KEYS } from '../../../services/storage.service';
import { BdoApiService } from '../../../services/bdo-api.service';
import { Utilities } from '../../../../shared/utils/utilities';
import { isEqual as _isEqual } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { AddressFormtype } from '../../../../shared/formtypes/address.formtype';
import { TranslateService } from '@ngx-translate/core';
import { LOADING_STATE } from '../../../enums/loadingState.enum';

export enum TILESTATE {
  'NONE'= '',
  'CURRENT'= 'current',
  'OTHER' = 'other'
}

@Component({
  selector: 'bdo-contract-new-address',
  templateUrl: './contract-new-address.component.html',
  styleUrls: ['./contract-new-address.component.scss']
})
export class ContractNewAddressComponent implements OnInit {
  public addressForm: FormGroup<AddressFormtype>;
  @ViewChild(AddressFormComponent, { static: false }) set content(content: AddressFormComponent) {
    if (content) { // initially setter gets called with undefined
      this.addressForm = content.form;
    }
  }
  public configuration: SwitchCardConfiguration[];
  public selected: TILESTATE = TILESTATE.NONE;
  public form = new FormGroup<{
    address?: FormGroup<AddressFormtype>
  }>({});
  public currentAddress: AddressData;
  public TILESTATE = TILESTATE;
  public TRACKING = TRACKING;
  public LoadingState = LOADING_STATE;
  public state: LOADING_STATE = LOADING_STATE.IDLE;

  constructor(
    private translateService: TranslateService,
    private apiService: BdoApiService,
    private cdref: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_NEW_ADDRESS, TRACKING.ACTION.ENTER);
    const savedAddress: AddressData = StorageService.getAddressData();
    this.state = LOADING_STATE.LOADING;

    this.apiService.getAccountInfo()
    .subscribe({
      next: res => {
        this.currentAddress = Utilities.flattenAddress(res.address);
        this.setConfig();
        if (savedAddress && _isEqual(this.currentAddress, savedAddress)) {
          this.selected = TILESTATE.CURRENT;
        } else if (savedAddress) {
          this.onSelect(TILESTATE.OTHER);
          this.addressForm.get('postCode').setValue(savedAddress.postCode);
          this.addressForm.get('city').setValue(savedAddress.cityName);
          this.addressForm.get('street').setValue(savedAddress.streetName);
          this.addressForm.get('housenumber').setValue(savedAddress.houseNum);
        }
        this.state = LOADING_STATE.IDLE;
      },
      error: () => {
        this.state = LOADING_STATE.ERROR;
      }
    });
  }

  onSelect(selectedTile: string) {
    this.selected = selectedTile as TILESTATE;
    if (selectedTile === TILESTATE.OTHER) {
      this.cdref.detectChanges();
      this.form?.addControl('address', this.addressForm);
      this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_NEW_ADDRESS, TRACKING.FORM_ACTION.SELECT, 'Neue Adresse');
    } else {
      this.form?.removeControl('address');
      this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_NEW_ADDRESS, TRACKING.FORM_ACTION.SELECT, 'Aktuelle Adresse');
    }
  }

  save() {
    if (this.selected === TILESTATE.CURRENT) {
      this.next(this.currentAddress);
    } else if (this.addressForm.valid) {
      const address: AddressData = {
        cityName: this.addressForm.get('city').value,
        postCode: this.addressForm.get('postCode').value,
        streetName: this.addressForm.get('street').value,
        houseNum: this.addressForm.get('housenumber').value
      };
      this.next(address);
    } else {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
      this.cdref.detectChanges();
    }
  }

  private next(address: AddressData) {
    StorageService.setValue(ALLOWED_KEYS.ADDRESS_DATA, address);
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_NEW_ADDRESS, TRACKING.ACTION.GOTO, 'Spartenauswahl');
    this.router.navigate(['../energiearten'], {
      relativeTo: this.activatedRoute
    });
  }

  private setConfig() {
    const displayAddress: string = this.currentAddress.streetName + ' ' + this.currentAddress.houseNum + '<br>' +
      this.currentAddress.postCode + ' ' + this.currentAddress.cityName;
    this.configuration  = [
      {
        id: TILESTATE.CURRENT,
        title: this.translateService.instant('contract.new.address.current.buttonTitle'),
        description: displayAddress,
        src: {
          default: 'assets/icons/bdo/home-check_75x75.svg',
          sm: 'assets/icons/bdo/home-check_75x75.svg',
          md: 'assets/icons/bdo/home-check_90x90.svg',
          lg: 'assets/icons/bdo/home-check_120x120.svg',
        }
      },
      {
        id: TILESTATE.OTHER,
        title: this.translateService.instant('contract.new.address.other.buttonTitle'),
        description: this.translateService.instant('contract.new.address.other.buttonDescription'),
        src: {
          default: 'assets/icons/bdo/home-plus_75x75.svg',
          sm: 'assets/icons/bdo/home-plus_75x75.svg',
          md: 'assets/icons/bdo/home-plus_90x90.svg',
          lg: 'assets/icons/bdo/home-plus_120x120.svg',
        }
      }
    ];
  }
}
