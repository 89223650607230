<div class="re-relative re-h-full">
  <div class="re-h-full re-flex re-flex-col md:re-flex-row">

    <!-- Title and text + Image for mobile and tablet view -->
    <div class="re-flex re-flex-col re-justify-between">
      <div class="re-mx-20 re-mt-30 lg:re-ml-30 re-order-1 re-block md:re-flex lg:re-block">
        <div>
          <h4 class="re-mb-30">{{ 'dashboard.billingConsumptionTile.title' | translate }}</h4>
          <svg-icon class="md:re-hidden re-flex re-justify-center re-mb-15" [src]="'assets/img/consumption-s.svg'"></svg-icon>
          <p>{{ 'dashboard.billingConsumptionTile.description' | translate }}</p>
        </div>
        <div class="md:re-pr-25 md:re-pt-25">
          <svg-icon class="re-hidden md:re-flex lg:re-hidden re-justify-center" [src]="'assets/img/consumption-s.svg'"></svg-icon>
        </div>
      </div>

      <!-- buttons -->
      <div class="re-mx-20 re-my-30 re-flex re-flex-col re-w-fit re-flex-wrap re-order-2
                  md:re-mt-0 md:re-flex-row md:re-mx-30 md:re-order-3
                  lg:re-mt-30">
        <a class="bdo-button-primary re-mr-0 md:re-mr-20 md:re-mt-15 re-min-w-[225px]"
           [routerLink]="'/abschlag/' + accountId">
          {{ 'dashboard.billingConsumptionTile.linkToBilling' | translate }}
        </a>
        <a class="re-button-outline re-mt-20 md:re-mt-15 re-min-w-[225px]"
           [routerLink]="'/verbrauch/' + accountId">
          {{ 'dashboard.billingConsumptionTile.linkToConsumptionHistory' | translate }}
        </a>
      </div>
    </div>

    <!-- Image for desktop view -->
    <div class="re-hidden re-mx-0 re-mt-60 re-mb-30 overwrite-margin re-order-3 lg:re-inline">
      <svg-icon [src]="'assets/img/consumption-xl.svg'"></svg-icon>
    </div>
  </div>
</div>
