import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomerStoreService } from '../../../services/customer-store.service';
import { TenantService } from '../../../services/tenant.service';
import { TENANT_ACRONYM } from '../../../enums/tenant.enum';
import { Environment } from '../../../../../environments/environment';
import { PromoLinkData } from '../../../../shared/molecules/promo-link-wrapper/promo-link-wrapper.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bdo-personal-data-faqs',
  templateUrl: './personal-data-faqs.component.html',
  styleUrls: ['./personal-data-faqs.component.scss']
})
export class PersonalDataFaqsComponent implements OnInit, OnDestroy {
  public accountId: string;
  public tenant: string;
  public promoLinkDataSet: Array<PromoLinkData>;
  private subscriptions: Subscription = new Subscription();

  constructor(
    public customerStore: CustomerStoreService,
    public tenantService: TenantService,
    private translateService: TranslateService
  ) {
    this.subscriptions.add(this.translateService.onLangChange.subscribe({
      next: () => {
        this.setPromoLinkData();
      }
    }));
   }

  ngOnInit(): void {
    this.accountId = this.customerStore.getAccountId();
    this.tenant = this.tenantService.getTenantDataByShort(TENANT_ACRONYM[Environment.tenant].toUpperCase()).companyName;
    this.setPromoLinkData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setPromoLinkData(): void {
    this.promoLinkDataSet = [
      {
        linkText: this.translateService.instant('personaldata.moreServices.meterreadings'),
        routerUrl: '/zaehlerstand/' + this.accountId
      },
      {
        linkText: this.translateService.instant('personaldata.moreServices.bills'),
        routerUrl: '/dokumente/' + this.accountId
      },
      {
        linkText: this.translateService.instant('personaldata.moreServices.questions'),
        href: this.tenantService.getCurrentTenantData().hrefFrequentQuestions
      }
    ];
  }
}
