/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TariffSummaryContractSums { 
    description?: string;
    baseGross?: number;
    baseNet?: number;
    workGross?: number;
    workNet?: number;
    /**
     * The level indicates whether it is only the sum of the basic price or other components are included. (1 = basic price only, 11 = basic price and options, other possibilities not excluded).
     */
    level?: number;
}

