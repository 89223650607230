<div class="bdo-container bdo-space--bottom">
  <h3 class="re-mb-45">{{ 'tariffAdvisor.summary.title' | translate }}</h3>
  <div class="text bold re-mb-15 re-w-full md:re-w-600 md:re-mx-auto lg:re-w-900" [@fadeIn]>
    {{ 'tariffAdvisor.summary.subtitle' | translate }}
  </div>
  <div class="re-bg-white re-w-full re-text re-flex re-p-30 re-pb-20 re-flex-col re-relative
              md:re-w-600 md:re-mx-auto
              lg:re-w-900"[@fadeIn]>
    <div class="re-border-b re-border-monochrome-light re-border-solid re-pb-10 re-mb-20">
      <div *ngFor="let tariff of getCompletedTariffSelections()" class="re-mb-20">
        <div class="re-mb-5">
          <svg-icon [applyClass]="true" class="re-fill-current re-stroke-current re-h-20 re-w-20 re-text-0 re-align-middle" src="{{ tariff?.divisionConfiguration?.iconSrc }}"></svg-icon>
          <span class="bold">{{ tariff?.divisionConfiguration?.title }}</span>
        </div>
        <div class="re-flex re-flex-row re-justify-between">
          <div [innerHTML]="tariff.selectedTariff.name">
          </div>
          <div class="bold text">
            {{tariff.monthlyPrice | number:'1.2-2'}} €
          </div>
        </div>
      </div>
    </div>
    <div class="re-flex re-flex-col md:re-ml-auto">
      <div class="re-text-base md:re-ml-auto bold">
        {{getSum() | number:'1.2-2'}} €
      </div>
      <div class="re-text-xs">
        {{ 'tariffAdvisor.summary.monthlyCosts' | translate }}
      </div>
    </div>
  </div>

  <div class="re-w-full
              re-mt-45
              md:re-w-600 md:re-mx-auto
              lg:re-w-900">
    <div class="md:re-pl-0 re-pl-15">
      <div class="re-mb-15" [@fadeIn]>
        <bdo-validate-submit
          (click)="continue()"
          [textButtonLong]="'wizard.button.payment' | translate"
        ></bdo-validate-submit>
      </div>
      <div>
        <bdo-text-icon
          [iconPosition]="'left'"
          [text]="'general.action.back' | translate"
          (itemClicked)="location.back()"
        >
        </bdo-text-icon>
      </div>
    </div>
  </div>
</div>
