import { Component } from '@angular/core';
import { PLACEHOLDER_ROUTE_ACCOUNTID } from '../../../services/customer-store.service';

@Component({
  selector: 'bdo-contract-edit-auth',
  templateUrl: './contract-edit-auth.component.html',
  styleUrls: ['./contract-edit-auth.component.scss']
})
export class ContractEditAuthComponent {

  public placeholderAccountId : string = PLACEHOLDER_ROUTE_ACCOUNTID;
}
