<h4 class="re-container-with-margin re-mb-20 re-mt-60 md:re-mb-30 re-ml-20 md:re-ml-auto">
  {{ 'personaldata.password.title' | translate }}
</h4>
<bdo-edit-card
  class="re-relative"
  [isEditable]="!isInEditMode"
  [disabled]="disabled"
  (edit)="edit.emit($event); isInEditMode = !isInEditMode;">
  <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>

  <div *ngIf="!isInEditMode">
    <div class="re-summary-row">
      <div>{{ 'register.username.title' | translate }}</div>
      <div>{{username}}</div>
    </div>
    <div class="re-summary-row re-mb-0">
      <div>{{ 'register.password.title' | translate }}</div>
      <div>**********</div>
    </div>
  </div>

  <bdo-password-change *ngIf="isInEditMode"
    [username]="username"
    (editDone)="onEditDone()">
  </bdo-password-change>
</bdo-edit-card>
