<div class="re-container-headline bdo-space--bottom">
  <h3>
    {{ 'summary.title' | translate }}
  </h3>
</div>
<div class="re-container-with-margin">
  <div class="re-copytext-with-margin">
    {{ 'summary.description' | translate }}
  </div>
</div>

<!-- shown on all backend errors -->
<div class="bdo-container--single">
  <div [class.re-mb-15]="state === LoadingState.ERROR">
    <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">
      {{ 'general.error.error' | translate }}
    </bdo-info-box>
  </div>
</div>


<div class="re-relative" [ngClass]="{'re-h-200 re-overflow-y-clip re-container-with-margin' : state === LoadingState.LOADING}">
  <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
  <!-- Dates for Cancelation -->
  <h4 class="re-container-with-margin re-mb-20 re-ml-20
             md:re-mb-30 md:re-ml-auto">
    {{ 'contract.end.summary.cancellationDetails.title' | translate }}
  </h4>
  <bdo-edit-card
    (edit)="onEdit(1, 'adresse')">
    <bdo-old-home-summary
      [contextContractEnd]="true">
    </bdo-old-home-summary>
  </bdo-edit-card>

  <!-- Payment -->
  <h4 class="re-container-with-margin re-mt-60 re-mb-20 re-ml-20
             md:re-mb-30 md:re-ml-auto">
    {{ 'summary.payment.title' | translate }}
  </h4>
  <bdo-edit-card
    (edit)="onEdit(2, 'zahlungsart')">
    <bdo-payments-summary [noFutureContract]="true"></bdo-payments-summary>
  </bdo-edit-card>

  <!-- Invoice Recipient -->
  <h4 class="re-container-with-margin re-mt-60 re-mb-20 re-ml-20
             md:re-mb-30 md:re-ml-auto">
    {{ 'address.invoiceRecipient' | translate }}
  </h4>
  <bdo-edit-card
    (edit)="onEdit(3, 'rechnungsadresse')">
    <bdo-billing-address-summary></bdo-billing-address-summary>
  </bdo-edit-card>

  <!-- Legal Notice -->
  <div class="re-container-with-margin re-mt-60 re-ml-20 md:re-ml-auto">
    <h4 class="re-mb-30">{{ 'summary.legal.title' | translate }}</h4>
    <bdo-info-box *ngIf="translateService.instant('summary.legal.hint') !== ''" [type]="'info'" [marginBottom]="false">
      {{ 'summary.legal.hint' | translate }}
    </bdo-info-box>
  </div>
  <bdo-edit-card
    [isEditable]="false">
    <bdo-legal-summary
      [isGewe]="isGewe"
      [endingProcess]="'contractEnd'"
      [validateCheckboxes]="validateCheckboxes"
      [canceledDivisions]="recentDivisionIds"
      (tosChange)="onChangeTermsOfConditions($event)"
      (rightOfWithdrawalChange)="onChangeRightOfWithdrawalAccepted($event)">
    </bdo-legal-summary>
  </bdo-edit-card>

  <div class="re-w-full md:re-w-600 lg:re-w-900 md:re-mx-auto re-mt-30 md:re-mt-45">
    <div class="md:re-pl-0 re-pl-20">
      <bdo-validate-submit
        (click)="submit()"
        [textButtonLong]="'contract.end.summary.submit' | translate"
      ></bdo-validate-submit>
      <div class="re-pt-15">
        <bdo-text-icon
          [iconPosition]="'left'"
          [text]="'general.action.back' | translate"
          (itemClicked)="location.back()"
        >
        </bdo-text-icon>
      </div>
    </div>
  </div>
</div>
