import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TenantService } from '../../../bdo/services/tenant.service';

@Component({
  selector: 'bdo-edit-card',
  templateUrl: './edit-card.component.html',
  styleUrls: ['./edit-card.component.scss']
})
export class EditCardComponent {
  @Input() isEditable: boolean = true;
  @Input() isDeletable: boolean = false;
  @Input() disabled: boolean = false;
  @Input() showFrame: boolean = true;
  @Output() edit: EventEmitter<boolean> = new EventEmitter();
  @Output() delete: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public tenantService: TenantService
  ) { }

  onEdit() {
    this.edit.emit(true);
  }

  onDelete() {
    this.delete.emit();
  }

}
