import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { CustomerStoreService } from '../../../services/customer-store.service';
import { BdoApiService } from '../../../services/bdo-api.service';
import { Feedback } from '../../../../../assets/js/com/ts_api_client';
import { Utilities } from '../../../../shared/utils/utilities';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { TrackingService } from '../../../services/tracking.service';
import { INPUT_TYPE } from '../../../enums/inputType.enum';

@Component({
  selector: 'bdo-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements OnInit, OnDestroy {
  @Output() done: EventEmitter<void> = new EventEmitter();

  public INPUT_TYPE = INPUT_TYPE;
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  subscriptions = new Subscription();
  noStarError: boolean = true;
  showNoStarError: boolean = false;
  dirty: boolean = false;

  fullStar: string = 'assets/icons/bdo/star_active.svg';
  emptyStar: string = 'assets/icons/bdo/star_passive.svg';

  starSrc1: string = this.emptyStar;
  starSrc2: string = this.emptyStar;
  starSrc3: string = this.emptyStar;
  starSrc4: string = this.emptyStar;

  rating: number = 0.0;
  text: string = '';
  isError: boolean = false;

  customerName: string;
  customerEmail: string;

  constructor(
    private customerStore: CustomerStoreService,
    private trackingService: TrackingService,
    private router: Router,
    private apiService: BdoApiService
  ) { }

  reset() {
    this.rating = 0.0;
    this.text = '';

    this.starSrc1 = this.emptyStar;
    this.starSrc2 = this.emptyStar;
    this.starSrc3 = this.emptyStar;
    this.starSrc4 = this.emptyStar;

    this.isError = false;
    this.noStarError = true;
    this.showNoStarError = false;
    this.dirty = false;
    this.state = LOADING_STATE.IDLE;
  }
  ngOnInit() {
    this.customerName = this.customerStore.getCustomerName();
    this.customerEmail = this.customerStore.getCustomerEmail();
    this.subscriptions.add(this.customerStore.customerNameChangedEvent
      .subscribe({ next: newName => {
        this.customerName = newName;
      } }));
    this.subscriptions.add(this.customerStore.customerEmailChangedEvent
      .subscribe({ next: newEmail => {
        this.customerEmail = newEmail;
      } }));
  }

  ngOnDestroy(): void {
   this.subscriptions.unsubscribe();
  }

  clickOntoStar1() {
    this.starSrc1 = this.fullStar;
    this.starSrc2 = this.emptyStar;
    this.starSrc3 = this.emptyStar;
    this.starSrc4 = this.emptyStar;
    this.rating = 0;
    this.noStarError = false;
    this.showNoStarError = false;
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.FEEDBACK, TRACKING.ACTION.CLICK + ' Stars.1');

  }

  clickOntoStar2() {
    this.starSrc1 = this.fullStar;
    this.starSrc2 = this.fullStar;
    this.starSrc3 = this.emptyStar;
    this.starSrc4 = this.emptyStar;
    this.rating = 0.3333;
    this.noStarError = false;
    this.showNoStarError = false;
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.FEEDBACK, TRACKING.ACTION.CLICK + ' Stars.2');
  }

  clickOntoStar3() {
    this.starSrc1 = this.fullStar;
    this.starSrc2 = this.fullStar;
    this.starSrc3 = this.fullStar;
    this.starSrc4 = this.emptyStar;
    this.rating = 0.6666;
    this.noStarError = false;
    this.showNoStarError = false;
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.FEEDBACK, TRACKING.ACTION.CLICK + ' Stars.3');
  }

  clickOntoStar4() {
    this.starSrc1 = this.fullStar;
    this.starSrc2 = this.fullStar;
    this.starSrc3 = this.fullStar;
    this.starSrc4 = this.fullStar;
    this.rating = 1;
    this.noStarError = false;
    this.showNoStarError = false;
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.FEEDBACK, TRACKING.ACTION.CLICK + ' Stars.4');
  }


  onKey() {
    this.dirty = this.text.length > 0;
  }

  submitPressed() {
    if (this.noStarError) {
      this.showNoStarError = true;
    } else {
      const feedback: Feedback = {};
      feedback.bewertung = this.rating;
      const list = this.router.url.split('/');
      if (list.length <= 1) {
        feedback.pfad = '';
      } else {
        feedback.pfad = list[1];
      }

      feedback.kommentar = this.text.replace(Utilities.blackList, ' ').substring(0, Utilities.maxLength);
      feedback.userAgent = window.navigator.userAgent.replace(Utilities.blackList, ' ').substring(0, Utilities.maxLength);
      feedback.istFehler = this.isError;
      feedback.aufloesung = window.outerWidth + 'X' + window.outerHeight;

      feedback.accountId = this.customerStore.getAccountId();
      feedback.email = this.customerEmail;

      this.state = LOADING_STATE.LOADING;
      this.apiService.postFeedback(feedback)
        .subscribe(
          {
            next: () => {
              this.state = LOADING_STATE.IDLE;
              this.done.emit();
            },
            error: () => {
              this.state = LOADING_STATE.ERROR;
            }
          });
    }
  }

}
