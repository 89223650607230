import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { defaultValidatorProxy } from '../../../../shared/validators/default-validator-proxy';
import { phonenumberValidator } from '../../../../shared/validators/phonenumber-validator';
import { PersonalUserData, UpdateablePersonalUserData, Sectors, LegalEntities } from '../../../../../assets/js/com/ts_api_client';
import { BdoApiService } from '../../../services/bdo-api.service';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { PersonalDataPersonalFormtype } from '../../../../shared/formtypes/personal-data-personal.formtype';
import { TranslateService } from '@ngx-translate/core';
import punycode from 'punycode/';
import { LoginService } from '../../../../login/login.service';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'bdo-personal-data-personal',
  templateUrl: './personal-data-personal.component.html',
  styleUrls: ['./personal-data-personal.component.scss']
})
export class PersonalDataPersonalComponent implements OnInit {
  @Input() accountId: string;
  @Input() disabled: boolean = false;
  @Output() edit: EventEmitter<boolean> = new EventEmitter();

  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public isInEditMode: boolean = false;
  public personalData: PersonalUserData;
  public form: FormGroup = new FormGroup<PersonalDataPersonalFormtype>({});
  public showPhonenumber: string;
  public sectorDisplayText: string;
  public legalDisplayText: string;
  public isGewe: boolean;
  public isVerified: boolean;
  public tmpEmail: string; // In case of pending verification
  public emailAddressChanged: boolean = false;

  constructor(
    public apiService: BdoApiService,
    private translateService: TranslateService,
    private loginService: LoginService,
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    this.getPersonalData();
  }

  getPersonalData() {
    this.state = LOADING_STATE.LOADING;
    this.apiService.getPersonalUserData(
      ).subscribe(
      {
        next: (res: PersonalUserData) => {
          this.personalData = res;
          this.getShowPhonenumber();
          this.isGewe = this.personalData.partnerKind === 'GEWE';
          if (this.isGewe) {
            this.getIdsToText();
          }
          this.state = LOADING_STATE.IDLE;
        },
        error: () => {
          this.state = LOADING_STATE.ERROR;
        }
      });

    this.loginService.getTempUnverifiedEmail().subscribe( mail => this.tmpEmail = mail);
  }

  getShowPhonenumber(): void {
    if (this.personalData.phonenumber) {
      this.showPhonenumber = this.personalData.phonenumber.number;
    } else if (this.personalData.mobilenumber) {
      this.showPhonenumber = this.personalData.mobilenumber.number;
    } else {
      this.showPhonenumber = '-';
    }
  }

  getIdsToText(): void {
    this.apiService.getSectors(
    ).subscribe({ next: (sectors: Sectors) => {
      const sectorText = sectors.list.find((value) => value.sectorId === this.personalData.org.sectorId);
      if (sectorText) {
      this.sectorDisplayText = sectorText.text;
      } else {
        this.sectorDisplayText = this.translateService.instant('personaldata.personal.noAssignmentToId');
      }
    }, error: (error: unknown) => {
      this.sectorDisplayText = this.translateService.instant('personaldata.personal.noAssignmentToId');
    } });

    this.apiService.getLegalEntities(
    ).subscribe(
      {
        next: (legalEntities: LegalEntities) => {
          const legalEntityText = legalEntities.list.find((value) => value.legalEntityId === this.personalData.org.legalEntityId);
          if (legalEntityText) {
            this.legalDisplayText = legalEntityText.text;
          } else {
            this.legalDisplayText = this.translateService.instant('personaldata.personal.noAssignmentToId');
          }
        },
        error: () => {
          this.legalDisplayText = this.translateService.instant('personaldata.personal.noAssignmentToId');

        }
      });
  }

  changeEditMode() {
    this.isInEditMode = !this.isInEditMode;

    if (this.isInEditMode) {

      if (this.personalData.phonenumber || !this.personalData.mobilenumber && !this.personalData.phonenumber) {
        this.form.addControl('phonenumber', new FormControl(''));
        this.form.get('phonenumber').setValue(this.personalData?.phonenumber?.number ? this.personalData.phonenumber.number : '');
        this.form.get('phonenumber').setValidators([
          defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
          phonenumberValidator(this.translateService),
          defaultValidatorProxy(Validators.maxLength(35), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 35 }))
        ]);
      }

      if (this.personalData.mobilenumber) {
        this.form.addControl('mobilenumber', new FormControl(''));
        this.form.get('mobilenumber').setValue(this.personalData.mobilenumber.number);
        this.form.get('mobilenumber').setValidators([
          defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
          phonenumberValidator(this.translateService),
          defaultValidatorProxy(Validators.maxLength(35), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 35 }))
        ]);
      }

      this.form.addControl('email', new FormControl(this.personalData?.email?.smtpAddress));
        this.form.get('email').setValidators([
          defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
          defaultValidatorProxy(Validators.email, this.translateService.instant('address.validator.validEmail'))
        ]);

    } else {
      this.form.removeControl('phonenumber');
      this.form.removeControl('mobilenumber');
      this.form.removeControl('email');
      this.getPersonalData();
    }
    this.edit.emit(this.isInEditMode);
  }

  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.get('email')?.value !== this.personalData?.email?.smtpAddress) {
      this.emailAddressChanged = true;
    }

    if (this.form.valid) {
      this.state = LOADING_STATE.LOADING;
      if (this.form.get('mobilenumber')) {
        this.personalData.mobilenumber = {
          'addressId': this.personalData?.mobilenumber?.addressId ? this.personalData.mobilenumber.addressId : '',
          'number': this.form.get('mobilenumber').value,
          'isDefault': true };
      }
      if (this.form.get('phonenumber')) {
        this.personalData.phonenumber = {
          'addressId': this.personalData?.phonenumber?.addressId ? this.personalData.phonenumber.addressId : '',
          'number': this.form.get('phonenumber').value,
          'isDefault': true };
      }
      if (this.form.get('email')) {
        this.personalData.email = {
          addressId: this.personalData?.email?.addressId || '',
          smtpAddress: punycode.toUnicode(this.form.get('email')?.value),
          isDefault: true
        };
      }

      const updateablePersonalData: UpdateablePersonalUserData = {
        'partnerId': this.personalData.partnerId,
        'phonenumber': this.personalData.phonenumber,
        'mobilenumber': this.personalData.mobilenumber,
        'faxnumber': this.personalData.faxnumber,
        'email': this.personalData.email
      };

      this.apiService.postPersonalUserData(updateablePersonalData).subscribe(
        {
          next: () => {
            this.state = LOADING_STATE.IDLE;
            this.toastService.pushToast({
              headline: this.translateService.instant('personaldata.personal.success'),
              content: this.emailAddressChanged ?
                this.translateService.instant('personaldata.personal.emailSuccessText' ):
                '',
            });
            this.changeEditMode();
            this.getShowPhonenumber();
            this.emailAddressChanged = false;
          },
          error: () => {
            this.state = LOADING_STATE.ERROR;
          }
        });
    }
  }

  // To resend the verification, trigger changeEmail again
  resendVerification() {
    this.state = LOADING_STATE.LOADING;
    const updateablePersonalData: UpdateablePersonalUserData = {
      'partnerId': this.personalData.partnerId,
      'phonenumber': this.personalData.phonenumber,
      'mobilenumber': this.personalData.mobilenumber,
      'faxnumber': this.personalData.faxnumber,
      'email': { ...this.personalData.email, smtpAddress: this.tmpEmail }
    };
    this.apiService.postPersonalUserData(updateablePersonalData).subscribe(
      {
        next: () => {
          this.state = LOADING_STATE.IDLE;
          this.toastService.pushToast({
            headline: this.translateService.instant('personaldata.personal.success'),
            content: this.translateService.instant('personaldata.personal.emailSuccessText' ),
          });
        },
        error: () => {
          this.state = LOADING_STATE.ERROR;
        }
      });
  }
}
