<div *ngIf="isVisible" class="dialog__container" (click)="onClose()">
  <div class="dialog" (click)="$event.stopPropagation()">
    <div class="modal__header">
      <label class="dialog__close-btn">
        <bdo-svg (click)="onClose()"  name="icon-close" styling="icon__close bdo-svg bdo-svg--hover" attr.aria-label="{{ 'general.action.close' | translate }}"></bdo-svg>
      </label>
    </div>
    <div class="content__container"><ng-content></ng-content></div>
  </div>
</div>
<div *ngIf="isVisible" class="overlay" (click)="onClose()"></div>
