export class TrackingPixelData {
  s_id: string;
  ad_start: string;
  ad_duration: number;
  orderId: string;
  re_channel: string;
  re_partnerid: string;

  constructor(channel: string, partnerId: string, ad_start: string, s_id?: string) {
    this.re_channel = channel;
    this.re_partnerid = partnerId;
    this.s_id = s_id;
    this.ad_start = ad_start;
    // specification of how many days the s_id can be kept
    this.ad_duration = 30;

    if (!ad_start && s_id) {
      this.ad_start = new Date(Date.now()).toISOString();
    }
  }
}
