<div class="re-container-headline re-mb-45 md:re-mb-45">
  <h3>
    {{ 'move.newHome.title' | translate }}
  </h3>
</div>
<p class="re-container-with-margin re-mb-30 md:re-mb-45 re-text-sm re-leading-m md:re-text-base md:re-leading-2xl">
  {{ 'move.newHome.description' | translate }}
</p>
<h4 class="re-container-with-margin re-mb-30 re-ml-20 md:re-mb-45 md:re-ml-auto">
  {{ 'move.newHome.address.title' | translate }}
</h4>
<form (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" bdoScrollToInvalidControl [bdoScrollToInvalidControlForm]="newHomeForm">
  <div class="re-bg-white re-w-full re-p-20 re-py-45 re-text re-mb-45
              md:re-w-600 md:re-mx-auto md:re-pt-20 md:re-pb-60 md:re-mb-60
              lg:re-w-900">
    <fieldset class="re-mb-0 md:re-mt-45">

      <bdo-address-form [parentForm]="newHomeForm" [initialData]="initialAddressData" [formTracking]="formTracking"></bdo-address-form>

      <div class="re-form-item-container" *ngIf="!hasEmail">
        <bdo-input #emailInput
                  [formControl]="$any(newHomeForm.get('email'))"
                  [inputType]="INPUT_TYPE.EMAIL"
                  [validationIconHidden]="state === LoadingState.ERROR"
                  [labelText]="'address.email.title' | translate"
                  [formTracking]="formTracking"
                  >
        </bdo-input>
      </div>

      <div class="re-form-item-container re-mb-0">
        <bdo-input [formControl]="$any(newHomeForm.get('phonenumber'))"
                  [inputType]="INPUT_TYPE.TEL"
                  [validationIconHidden]="state === LoadingState.ERROR"
                  [labelText]="'address.phonenumber.optional' | translate"
                  [formTracking]="formTracking"
        >
        </bdo-input>
      </div>

    </fieldset>
  </div>

  <bdo-product-selection *ngIf="newHomeForm.get('address')?.valid"
                         [address]="getAddress()"
                         [newHomeForm]="newHomeForm"
                         [formTracking]="formSelectionTracking"
                         [forceValidateDate]="forceValidateDate"
                         (updateAvailableDivisions)="onUpdateAvailableDivisions($event)">
  </bdo-product-selection>

  <div class="re-w-full
              re-mt-45
              md:re-mt-60
              md:re-w-600 md:re-mx-auto
              lg:re-w-900">
    <div class="md:re-pl-0 re-pl-15">
      <bdo-validate-submit
        [textButtonLong]="'wizard.button.productSelection' | translate"
      ></bdo-validate-submit>
      <div class="re-pt-15">
        <bdo-text-icon
        [iconPosition]="'left'"
        [text]="'general.action.back' | translate"
        (itemClicked)="location.back()"
        >
        </bdo-text-icon>
<!--        Easy Debugging of invalid controls-->
<!--        {{newHomeForm.valid}} {{this.noDivisionSelected()}}-->
<!--        <pre>{{-->
<!--          findInvalidControlsRecursive(newHomeForm) | json-->
<!--          }}</pre>-->
      </div>
    </div>
  </div>
</form>
