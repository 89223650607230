import { ChangeDetectorRef, Component, effect, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastService } from '../../../bdo/services/toast.service';
import { SlideInAnimation } from '../../animations/slide-in.animation';
import { SlideOutAnimation } from '../../animations/slide-out.animation';

@Component({
  selector: 'bdo-toast-notification',
  templateUrl: './toast-notification.component.html',
  styleUrl: './toast-notification.component.scss',
  animations: [ SlideInAnimation, SlideOutAnimation ]
})
export class ToastNotificationComponent implements OnInit{
  @Output() closeToast: EventEmitter<boolean> = new EventEmitter();
  public fadeOut: 'out' | 'in' = 'in'; // Needed to trigger leave animation
  private timeout: NodeJS.Timeout;

  constructor(
    public toastService: ToastService,
    private cdref: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    effect(() => {
      if (this.toastService.currentToast() && this.toastService.currentToast().dataQa) {
        this.elementRef.nativeElement.setAttribute('data-qa', this.toastService.currentToast().dataQa);
      }
    });

  }

  ngOnInit(): void {
    this.reset();
  }

  reset() {
    this.fadeOut = 'in';
    if (this.toastService.currentToast()?.durationIndicator !== false) {
      this.timeout = setTimeout(() => {
        this.onClose();
      }, 4000);
    }
  }

  /*
  * manual: If close is triggered manually or automatically after timeout
  */
  onClose(manual = false) {
    this.fadeOut = 'out';
    if (manual) {
      this.cdref.detectChanges(); // Needed to stop/reset the animation/progress bar
      clearTimeout(this.timeout);
    }
    this.closeAfterDelay();
  }

  /* For the smooth slide out animation we delay the actual closing */
  closeAfterDelay() {
    setTimeout(() => {
      this.toastService.removeToast();
      if (this.toastService.currentToast()) {
        this.reset();
      }
    }, 1000);
  }

}
