import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login.service';
import { LoginResponse } from '../../bdo/models/loginResponse';
import { TRACKING } from '../../bdo//enums/trackingParts.enum';
import { TrackingService } from '../../bdo/services/tracking.service';
import { tap, switchMap, of, catchError, EMPTY } from 'rxjs';
import { URLs } from '../../../environments/environment';

export interface Credentials {
  username: string;
  password: string;
}

@Component({
  selector: 'bdo-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  @Output() submitFormEvent: EventEmitter<LoginResponse> = new EventEmitter();
  @Output() submitLogin: EventEmitter<Credentials> = new EventEmitter();
  @Output() hideErrorEvent: EventEmitter<any> = new EventEmitter();
  @Input() name: string;
  @Input() isFetching: boolean = false;
  @Output() isFetchingChange = new EventEmitter<boolean>();

  public password: string;
  public userExistsInCognito: boolean = false;
  public error : 'general' | 'notfound' | '' = '';

  validateForm: boolean = false;

  validateAll: boolean = false;
  validationIconHidden: boolean = false;
  public TRACKING = TRACKING;

  constructor(
    private loginService: LoginService,
    private router: Router,
    public trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    if (this.name) {
      this.checkUsername(this.name);
    }
  }

  login(form: any) {
    if (!this.name) {
      this.validateAll = true;
    } else if (!this.userExistsInCognito) {
      this.checkUsername(this.name);
    } else if (form.valid) {
      this.submitLogin.emit({
        password: this.password,
        username: this.name
      });
    } else {
      this.validateAll = true;
    }
  }

  checkUsername(username: string) {
    this.userExistsInCognito = false;
    this.error = '';
    this.isFetching = true;
    if (username) {
      this.loginService.checkUsernameAvailable(username).pipe(
        tap((res) => {
          if (res.available === false) {
            this.userExistsInCognito = true;
          }
        }),
        switchMap((res) => {
          if (!this.userExistsInCognito) {
            return this.loginService.checkSalesforceUsernameAvailable(username);
          } else {
            return of(null); // Return an observable of null if the username is not available in the second system
          }
        }),
        tap((sfRes) => {
          if (sfRes && sfRes.available === false) {
            this.trackingService.postTracking(TRACKING.LOCATION.LOGIN, TRACKING.ACTION.GOTO, TRACKING.LOCATION.SALESFORCE);
            window.location.href = URLs.sfLoginUrl();
          } else if (!this.userExistsInCognito) {
            this.error = 'notfound';
          }
          this.isFetching = false;
        }),
        catchError(() => {
          if (this.userExistsInCognito) {
            this.error = 'notfound';
          } else {
            this.error = 'general';
          }
          this.isFetching = false;
          return EMPTY; // Handle error and complete the observable
        })
      ).subscribe();
    } else {
      this.validateAll = true;
    }
  }

  goTo(destination: string) {
    this.router.navigate([destination]);
  }

  onFocus() {
    this.hideErrorEvent.emit();
    this.validationIconHidden = false;
    this.error = '';
  }
}
