<div class="re-container-headline bdo-space--bottom">
	<h3>
		{{ 'confirmation.cancel.title' | translate }}
  </h3>
</div>
<div class="re-container-with-margin">

  <div class="re-copytext-with-margin">
    {{ 'confirmation.cancel.description' | translate }}
  </div>
  <h2 class="re-mb-45">{{ 'confirmation.title' | translate }}</h2>
  <ul class="bdo-list__bullet_bigger re-text-sm re-leading-m re-mb-30 lg:re-text-base lg:re-leading-2xl lg:re-mb-45">
    <li class="re-mb-5 md:re-mb-10" [innerHTML]="'confirmation.cancel.acknowledgment' | translate: { emailAddress: billingAddress?.email }"></li>
    <li class="re-mb-5 md:re-mb-10" [innerHTML]="'confirmation.cancel.contractEndDate' | translate: { division: oldContract?.division | verbrauchstyp, endDate: homeData?.date | date }"></li>
    <li [innerHTML]="'confirmation.cancel.secureSupply' | translate: { division: oldContract?.division | verbrauchstyp }"></li>
    <li>{{ 'confirmation.cancel.onlineAccount' | translate }}</li>
  </ul>

  <div class="re-container-with-margin">
    <bdo-text-link-icon
      [iconPosition]="'right'"
      [linkText]="'confirmation.cancel.nextCancellation' | translate"
      [routerUrl]="'../auth'"
    ></bdo-text-link-icon>
  </div>

</div>
