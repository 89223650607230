import { Component, OnInit } from '@angular/core';
import { FormFieldIdentifierTracking, TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContractPaymentData } from '../../../../../assets/js/com/ts_api_client';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AvailableDivisionInfos } from '../new-home/product-selection/product-selection.component';
import { PaymentParentFormtype } from '../../../../shared/formtypes/payment-parent.formtype';

@Component({
  selector: 'bdo-move-payment',
  templateUrl: './move-payment.component.html',
  styleUrls: ['./move-payment.component.scss']
})
export class MovePaymentComponent implements OnInit {

  public formTracking: FormFieldIdentifierTracking = {
    orca_field_name: '',
    orca_field_section: TRACKING.FORM.MOVE_PAYMENT_SECTION_OTHER,
    orca_form_name: TRACKING.FORM.MOVE_PAYMENT_NAME
  };

  paymentMoveForm = new FormGroup<PaymentParentFormtype>({
    paymentOptions: new FormControl('keepPayment', { validators: [Validators.required] }),
    paymentMethod: new FormControl(null)
  });
  public availableDivisionInfos: AvailableDivisionInfos;

  constructor(
    public location: Location,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private trackingService: TrackingService
  ) {
    this.availableDivisionInfos = StorageService.getValue<AvailableDivisionInfos>(ALLOWED_KEYS.AVAILABLE_DIVISIONS);
  }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.MOVE_PAYMENT, TRACKING.ACTION.ENTER);
    this.trackingService.postFormTracking(TRACKING.FORM.MOVE_PAYMENT_NAME, TRACKING.FORM_ACTION.VIEW);
  }

  public save(): void {
    if (this.paymentMoveForm.invalid) {
      // mark all input fields as touched to display validation info
      this.paymentMoveForm.markAllAsTouched();
    } else {
      const formData: ContractPaymentData = {};
      Object.keys(this.paymentMoveForm.controls).forEach(key => {
        formData[key] = this.paymentMoveForm.controls[key].value;
      });

      StorageService.setValue(ALLOWED_KEYS.PAYMENT_DATA, formData);
      const paymentInfo = this.paymentMoveForm?.get('paymentMethod')?.value === 'keepPayment' ?
        'Bisherige Zahlungsinformation verwenden' : 'Abweichende Zahlungsweise hinzufügen';
      this.trackingService.postBaseAndAdditionalTracking({
        orca_event_type: TRACKING.LOCATION.MOVE_PAYMENT,
        orca_event_action: 'goto zusammenfassung',
        orca_zahlungsart: paymentInfo + ': ' + (formData?.paymentMethod === 'sepa' ? 'SEPA' : 'Überweisung'),
      });

      this.router.navigate(['../uebersicht'], { relativeTo: this.activatedRoute });
    }
  }
}
