import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TariffPriceAnnual } from '../../../shared/models/tariff-price.annual';
import { TariffSummaryResults, TariffTextItem } from '../../../../assets/js/com/ts_api_client';
import { CUSTOMERMODE } from '../../enums/customerMode';
import { TariffAdvisorService } from '../../services/tariff-advisor.service';
import { BdoApiService } from '../../services/bdo-api.service';
import { TARIFF_TEXT_KEYS } from '../../enums/tariffTextKeys';

@Component({
  selector: 'bdo-tariff-prices-overview',
  templateUrl: './tariff-prices-overview.component.html',
  styleUrls: ['./tariff-prices-overview.component.scss']
})
export class TariffPricesOverviewComponent implements OnChanges {
  @Input() prices: TariffPriceAnnual[];
  @Input() tariffData: TariffSummaryResults;
  @Input() withFrame: boolean = false;
  public hasBonus: boolean;
  public CUSTOMERMODE = CUSTOMERMODE;
  public meterTooltip: string;
  public isHTNT: boolean = false;
  public isGewe: boolean;
  public hasPriceCap: boolean;
  public infoWorkprice: string = '';
  public infoBaseprice: string = '';
  public hasMissingNtConsumptionForHTNT: boolean = false;

  constructor(
    public tariffAdvisorService: TariffAdvisorService,
    private apiService: BdoApiService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.hasBonus = this.tariffData?.parts?.[0]?.contractPayments?.some(elem => elem.description.length > 0);
    this.isHTNT = this.tariffData?.parts?.[0]?.contractLevel?.filter(contractLevel => contractLevel.level === 4).length >= 2;
    this.hasMissingNtConsumptionForHTNT = this.isHTNT && this.tariffData.annualConsumption && !this.tariffData.annualConsumptionNt;
    this.meterTooltip = this.tariffAdvisorService.getMeterTooltip(this.tariffData?.customerType);
    this.isGewe = this.tariffData?.customerType === CUSTOMERMODE.GEWE;
    this.hasPriceCap = this.tariffData?.parts?.[0]?.priceCap ? true : false;

    this.apiService.getTariffText(TARIFF_TEXT_KEYS.InfoI_workprice).pipe()
      .subscribe({
        next: (tariffTextItem: TariffTextItem) => {
          this.infoWorkprice = tariffTextItem?.infotext;
        },
        error: () => {
          this.infoWorkprice = undefined;
        }
    });

    this.apiService.getTariffText(TARIFF_TEXT_KEYS.InfoI_baseprice).pipe()
      .subscribe({
        next: (tariffTextItem: TariffTextItem) => {
          this.infoBaseprice = tariffTextItem?.infotext;
        },
        error: () => {
          this.infoWorkprice = undefined;
        }
    });
  }


}
