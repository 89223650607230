<bdo-large-popup *ngIf="bannerId$ | async" [isVisible]="showDelayed$ | async" (closePopupEvent)="closeAndDismiss()">
  <bdo-campaign-tile
    [mode]="CampaignTileType.POPUP"
    [showDismissCheckbox]="true"
    [campaign]="campaign"
    [dismissIsChecked]="dismissIsChecked"
    (clickDismissCheckbox)="onClickCheckbox()"
    (clickCampaignButton)="onClickCampaignButton()"
  >
  </bdo-campaign-tile>
</bdo-large-popup>
