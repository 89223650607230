import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  Account,
  AccountInfo,
  Alert,
  Forderung,
  KurzVerbrauchPrognose,
  OptInData
} from '../../../../assets/js/com/ts_api_client';
import { Environment } from '../../../../environments/environment';
import { LoginService } from '../../../login/login.service';
import { TENANT } from '../../enums/tenant.enum';
import { BdoApiService } from '../../services/bdo-api.service';
import { CampaignService } from '../../services/campaign.service';
import { CustomerStoreService } from '../../services/customer-store.service';
import { TenantService } from '../../services/tenant.service';
import { TrackingService } from '../../services/tracking.service';
import { BankAccountAssignments } from './banking-tile/models/bank-account-assignments.interface';
import { CAMPAIGN_TILE_TYPE } from './campaign-tile/campaign-tile.component';
import { TRACKING } from './../../enums/trackingParts.enum';
import { DebugLogger } from '../../../shared/utils/debugLogger';
import moment from 'moment';

@Component({
  selector: 'bdo-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public loadingAll = true;
  public loadingVerbrauch = false;
  public loadingPartner = false;
  public loadingForderung = false;
  public CampaignTileType = CAMPAIGN_TILE_TYPE;

  public accounts: Array<Account>;
  public selectedAccount: AccountInfo;
  public alert: Alert;
  public forderung: Forderung;
  public verbrauchPrognose: KurzVerbrauchPrognose;
  public canShowVerbrauchPrognose: boolean = false;
  public isInactive: boolean = false;
  public isRegional: boolean = false;
  public hasNightStorageHeater = false;
  public isEmployee = false;
  public showWelcomeInProgress: boolean = false;
  public moveAvailable: boolean = true;
  public greeting: string = this.generateGreeting();
  public showOptinTeaser: boolean = false;

  public accountId: string = '';
  public showRheinenergieAdvertisement: boolean = false;
  public bankAccountAssignments: BankAccountAssignments;
  public error: any;

  private customerSub: Subscription;

  constructor(
    private apiService: BdoApiService,
    private trackingService: TrackingService,
    private router: Router,
    private customerStore: CustomerStoreService,
    public tenantService: TenantService,
    public campaignService: CampaignService,
    private loginService: LoginService
  ) {
  }

  ngOnInit() {
    this.showRheinenergieAdvertisement = (Environment.tenant === TENANT.Rheinenergie);
    this.trackingService.postTracking(TRACKING.LOCATION.DASHBOARD, TRACKING.ACTION.ENTER);
    this.accountId = this.customerStore.getAccountId();

    this.apiService.getAccounts()
      .subscribe({
          next: (data) => {
            if (data?.length) {
              this.accounts = data;
              // Noch keine Kundennummer gesetzt oder eine veraltete gespeichert?
              if (!this.accountId || data.filter(obj => obj.accountId === this.accountId).length === 0) {
                this.customerStore.setAccountId(this.accounts[0].accountId);
                this.onCustomerSelected(this.accounts[0].accountId);
              } else {
                this.onCustomerSelected(this.accountId);
              }
              this.checkOptinFlag();
            } else {
              this.showWelcomeInProgress = true;
              this.trackingService.postTracking(TRACKING.LOCATION.DASHBOARD, TRACKING.ACTION.SHOW, TRACKING.LOCATION.ACCOUNTINPROGRESS);
            }
            this.loadingAll = false;
          },
          error: (error: unknown) => {
            this.setError(error, 'getAccounts()');
          }
        });
  }

  ngOnDestroy(): void {
    if (this.customerSub && this.customerSub instanceof Subscription) {
      this.customerSub.unsubscribe();
    }
  }

  loadBankAccountsAndAssignments() {
    forkJoin([
      this.apiService.getBankAccounts(),
      this.apiService.getBankAccountAssignment()
    ]).pipe(
      map(([bankAccounts, bankAccountAssignment]) => {
        return {
          hasBankAccounts: bankAccounts.length > 0,
          credit: bankAccounts.find((item) => bankAccountAssignment.credit && item.id === bankAccountAssignment.credit.id),
          debit: bankAccounts.find((item) => bankAccountAssignment.debit && item.id === bankAccountAssignment.debit.id)
        };
      })
    ).
    subscribe({ next: (result) => {
      this.bankAccountAssignments = result;
    } });
  }

  setError(error, context) {
    DebugLogger.debug(this.constructor.name, 'setError', context, error);
    this.error = error;
    this.loadingAll = false;
  }

  onClickCampaignButton(): void {
    this.campaignService.goToCampaign().pipe(
      first(),
    ).subscribe();
  }

  /**
   *
   * @param accountId Load Data for the selected Customer
   */
  onCustomerSelected(accountId: string) {
    if (this.accountId !== accountId) {
      this.accountId = accountId; // for input mapping to the child-tiles
      this.customerStore.setAccountId(accountId);
      this.trackingService.postSimpleTracking(TRACKING.LOCATION.DASHBOARD, 'SelectNewCustomer');
    }


    this.loadingForderung = true;
    // this.loadingVerbrauch = true;
    this.canShowVerbrauchPrognose = false;
    this.verbrauchPrognose = {};

    const selectedAccount = this.accounts.find( element => element.accountId === accountId);
    this.isInactive = selectedAccount ? selectedAccount.inaktiv : false;
    this.isRegional = selectedAccount ? selectedAccount.eingeschraenkteRegio : false;
    this.hasNightStorageHeater = selectedAccount ? selectedAccount.hasNightStorageHeater : false;
    this.isEmployee = selectedAccount ? selectedAccount.isEmployee : false;

    this.loadBankAccountsAndAssignments();
    this.loadingPartner = true;
    this.apiService.getAccountInfo(accountId).subscribe({
      next: (data) => {
        this.selectedAccount = data;
        // Same for all accounts
        this.customerStore.setCustomerName(this.selectedAccount.name);
        this.loadingPartner = false;
      },
      error: () => {
        this.loadingPartner = false;
      }
    });

    this.apiService.getAlert()
      .subscribe({ next: data => {
        this.alert = data;
      } });

    this.apiService.getForderung()
      .subscribe(
        {
          next: (data) => {
            this.forderung = data;
            this.loadingForderung = false;
          },
          error: () => {
            this.loadingForderung = false;
          }
        });

    if (this.isInactive || this.isRegional || this.isEmployee) {
        this.canShowVerbrauchPrognose = false;
        this.loadingVerbrauch = false;
        this.moveAvailable = false;
        return;
    } else {
      this.moveAvailable = true;
    }

    // Disable ConsumptionPrognosis Dashboardtile as it mismatches with pricecap
/*     this.apiService.getDashboardVerbrauchPrognose()
      .subscribe(
        {
          next: (resp) => {
            // Show fallback when prognosis couldn't be loaded
            if (!resp || !resp.body || resp.status !== ReturnCodes.OK ) {
              this.canShowVerbrauchPrognose = false;
              this.loadingVerbrauch = false;
              this.verbrauchPrognose = null;
              return;
            }

            // If Data is not in Abrechnungszeitraum, null it
            if (resp.body.datum < resp.body.abrechnungszeitraumAnfang) {
              resp.body.datum = null;
            }

            this.loadingVerbrauch = false;
            this.canShowVerbrauchPrognose = true;
            this.verbrauchPrognose = resp.body;
          },
          error: () => {
            this.canShowVerbrauchPrognose = false;
            this.loadingVerbrauch = false;
          }
        }); */
  }

  /* Display a greeting according to daytime */
  generateGreeting(): string {
    let greeting = '';
    const hour = moment(moment.now()).get('hour');
    if (hour >= 3 && hour < 12) {
      greeting = 'general.greeting.morning';
    } else if (hour >= 12 && hour < 17) {
      greeting = 'general.greeting.afternoon';
    } else {
      greeting = 'general.greeting.evening';
    }
    return greeting;
  }

  goTo(destination: string) {
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.DASHBOARD, TRACKING.ACTION.GOTO + destination);
    if (destination === '/zaehlerstand' || destination === '/verbrauch') {
      this.router.navigate([ destination + '/' + this.accountId]);
    } else {
      this.router.navigate(['/' + destination]);
    }
  }

  onCloseWelcomeInProgress() {
    this.trackingService.postTracking(TRACKING.LOCATION.DASHBOARD, TRACKING.ACTION.CLOSE, TRACKING.LOCATION.ACCOUNTINPROGRESS);
    this.showWelcomeInProgress = false;
    this.loginService.logout().subscribe({ next: () => {
      this.goTo('login');
    } });
  }

  private checkOptinFlag(): void {
    if (Environment.tenant === TENANT.Rheinenergie) {
      this.apiService.getOptin(this.accountId).subscribe({
        next: (res: OptInData) => {
          this.showOptinTeaser = !res.optInFlag;
        }
      });
    } else {
      this.showOptinTeaser = false;
    }
  }
}
