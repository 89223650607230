<div class="re-container-headline re-mb-30 lg:re-mb-45">
  <h3>
    {{ 'confirmation.order.title' | translate }}
  </h3>
</div>

<div class="re-container-with-margin re-mb-60">
  <div class="re-text-sm re-leading-m re-mb-30 md:re-mb-45 lg:re-text-base lg:re-leading-2xl">
    {{ 'confirmation.order.description' | translate }}
  </div>
  <h2 class="re-mb-15 lg:re-mb-30">{{ 'confirmation.title' | translate }}</h2>
  <ul class="bdo-list__bullet_bigger re-text-sm re-leading-m lg:re-text-base lg:re-leading-2xl">
    <li
      [innerHTML]="'confirmation.order.acknowledgment' | translate: { emailAddress: email }">
    </li>
    <li class="re-mt-5" *ngIf="cancelDate"
        [innerHTML]="'confirmation.end.cancellationDate' | translate:
        { endDate: cancelDate | date }">
    </li>
    <li class="re-mt-5" *ngIf="startDate"
        [innerHTML]="'confirmation.move.supplyBegins' | translate: { startDate: startDate | date }">
    </li>
    <li *ngIf="!availableDivisionInfos?.allUnavailableOrExternal" class="re-mt-5" [innerHTML]="'confirmation.order.documents' | translate"></li>
    <li class="re-mt-5" *ngIf="isRegistered || (loginService.isLoggedIn$ | async)" data-qa="online-account-stays">
      {{ 'confirmation.end.onlineAccount' | translate: { companyName: tenantService.getCurrentTenantData().companyName } }}
    </li>
    <li class="re-mt-5" *ngIf="divisionsForInfo" data-qa="cancelled-divisions">
      {{ 'confirmation.end.secureSupply' | translate: { division: divisionsForInfo } }}
    </li>
    <ng-container *ngIf="tariffService.isEffzeh(productIds)">
      <li class="re-mt-5 md:re-mt-10">{{ 'confirmation.order.effzehVoucher' | translate}}</li>
      <li class="re-mt-5 md:re-mt-10">{{ 'confirmation.order.effzehPayout' | translate}}</li>
      <li class="re-mt-5 md:re-mt-10">{{ 'confirmation.order.effzehCompetition' | translate}}</li>
    </ng-container>
  </ul>
</div>

<div *ngIf="externalTenantConfigurations?.length > 0" class="re-mb-30">
  <bdo-external-tenant-tile *ngFor="let externalTenantConfiguration of externalTenantConfigurations"
                            [externalTenantConfiguration]="externalTenantConfiguration">
  </bdo-external-tenant-tile>
</div>

<!-- Registration -->
<div *ngIf="registrationShown" class="re-mt-60">

  <div class="re-container-with-margin">
    <h4 class="re-mb-15 md:re-mb-20 lg:re-mb-30" *ngIf="!registrationDone">
      {{ 'register.delivery.title' | translate }}
    </h4>
    <div #registerErrorBox [class.re-mb-15]="registerState===LoadingState.ERROR" data-qa="registerErrorBox">
      <bdo-info-box class="bdo-container--single" *ngIf="registerState===LoadingState.ERROR">
        {{ 'general.error.error' | translate }}
      </bdo-info-box>
    </div>
  </div>
  <div class="re-bg-white re-w-full re-px-20 re-py-30 re-text
              md:re-w-600 md:re-mx-auto md:re-p-45
              lg:re-w-900 re-relative">
    <bdo-loading *ngIf="registerState===LoadingState.LOADING"></bdo-loading>
    <ng-container *ngIf="!registrationDone; else registrationSuccess">
      <form class="re-container__content re-bg-white" (ngSubmit)="register()" (keydown.enter)="$event.preventDefault()" bdoScrollToInvalidControl [formGroup]="registerForm">
        <bdo-quick-register-form [personalDataForm]="registerForm" [isRequired]="true"></bdo-quick-register-form>
        <button type="submit" class="re-button re-primary">{{ 'register.submit' | translate }}</button>
      </form>
    </ng-container>
    <ng-template #registrationSuccess>
      <div class="re-mb-20">
        <div class="re-w-30 re-h-30 md:re-w-40 md:re-h-40 lg:re-w-60 lg:re-h-60 re-inline-block re-mr-10 md:re-mr-15 lg:re-mr-30">
          <svg-icon class="icon re-stroke-current re-fill-current"
          src="assets/icons/system_icons/ok-circle.svg">
        </svg-icon>
        </div>
        <h4 class="re-inline">{{ 'register.success' | translate }}</h4>
      </div>
      <div>
        {{ 'register.successInfo' | translate }}
      </div>
    </ng-template>
  </div>
</div>

<bdo-trustpilot *ngIf="trustpilotUniqueUrl"
                [trustpilotUniqueUrl]="trustpilotUniqueUrl">
</bdo-trustpilot>

<bdo-netcologne-teaser
  *ngIf="!tariffService.isEffzeh(productIds)"
  [postCode]="postCode"
  [trackingLocation]="TRACKING.LOCATION.MOVE_CONFIRMATION"
></bdo-netcologne-teaser>

<bdo-effzeh-strom-teaser [productIds]="productIds"></bdo-effzeh-strom-teaser>

<div class="re-container-with-margin re-mt-30">
  <bdo-text-link-icon
    [iconPosition]="'left'"
    [linkText]="'general.navigation.backToHomepage' | translate"
    [href]="tenantService.getCurrentTenantData().hrefHomepage"
  ></bdo-text-link-icon>
</div>
