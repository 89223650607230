<div class="progress re-bg-monochrome-medium-light">
  <div class="progress-bar progress-bar--custom re-h-3"
    [style.width]="(getPasswordStrength(passwordNew) / amountOfPWStrengths) * 100 + '%'"
    [class.password--weak]="getPasswordStrength(passwordNew) === 1"
    [class.password--medium]="getPasswordStrength(passwordNew) === 2"
    [class.password--awesome]="getPasswordStrength(passwordNew)>=3"
    role="progressbar"
    [title]="'password.strength.title' | translate"
    [attr.aria-valuenow]="(getPasswordStrength(passwordNew) / amountOfPWStrengths) * 100"
    aria-valuemin="0" aria-valuemax="100">
  </div>
</div>
<div class="re-opacity-75 re-text-xs re-leading-s">
  <span>{{getPasswordStrengthText(passwordNew)}}</span>
</div>
