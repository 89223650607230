<div class="re-flex re-flex-col re-break-words md:re-flex-row re-bg-white re-h-auto re-p-30 lg:re-py-45">
    <!--Icon-->
    <div
    *ngIf="this.iconSrc.length>0"
    class="re-flex-none re-w-45 re-h-45 re-text-secondary-hover re-mb-30 md:re-mr-30 md:re-mb-0 lg:re-w-60 lg:re-h-60">
      <svg-icon src = {{iconSrc}} ></svg-icon>
    </div>
  <div class="re-flex re-flex-col re-min-w-0">
    <!--Title-->
    <h4 class="re-mb-15 lg:re-mb-20">
      {{title}}
    </h4>
    <!--Text-->
    <div class="re-copytext" [ngClass]="hasButton ? 're-block re-mb-30 md:re-mb-25 lg:re-mb-30':'re-block'">
      <ng-content></ng-content>
    </div>
    <!--Button or TextLinkIcon-->
    <div bdoRouterLinkDirective *ngIf="hasButton">
      <!--Button-->
      <a *ngIf="buttonStyle==='button'; else typeTextLinkIcon"
         class="bdo-button-primary"
         [routerLink]="routerUrl"
         [fragment]="fragment"
         (click)="trackIt()">
        {{buttonText}}
      </a>
      <ng-template #typeTextLinkIcon>
        <!--TextLinkIcon-->
        <bdo-text-link-icon
          [iconPosition]="'right'"
          [linkText]= buttonText
          [routerUrl]="routerUrl"
          [tracking]="tracking"
          [fragment]="fragment">
        </bdo-text-link-icon>
      </ng-template>
    </div>
  </div>
</div>
