import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ChartOptions, ChartConfiguration, ChartEvent } from 'chart.js';
import moment from 'moment';
import { CHART_UNIT } from '../../../enums/chartUnit.enum';
import { ChartPeriods } from '../../../models/chartPeriods';
import { BdoApiService } from '../../../services/bdo-api.service';
import { ProfileData, ProfileDataBlock, ProfileDatas } from '../../../../../assets/js/com/ts_api_client';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import tailwindJs from '../../../../../environments/rheinenergie/tailwind.config.js';
import { Tooltip } from '../../../models/tooltip';
import { ChartDrillUp } from '../../../models/chartDrillUp';
import { UnitPrettyPipe } from '../../../pipes/unit-pretty.pipe';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { TrackingService } from '../../../services/tracking.service';
import { BaseChartDirective } from 'ng2-charts';
import { TimeAggregation } from '../../../models/timeAggregation';
const fontFamily = 'Suisse Light';
const fontSize = 14;

@Component({
  selector: 'bdo-diagramm',
  templateUrl: './diagramm.component.html',
  styleUrls: ['./diagramm.component.scss']
})

export class DiagrammComponent implements OnInit, OnChanges {

  @Input() contractId: string;
  @Input() showTable: boolean;

  @ViewChild(BaseChartDirective) public chart: BaseChartDirective;
  @ViewChild('chartAreaWrapper') chartAreaWrapper: ElementRef;
  @ViewChild('tooltip') tooltip: ElementRef;
  @ViewChild('tooltipArrowDown') tooltipArrowDown: ElementRef;
  @ViewChild('chartScrollbar') chartScrollbar: ElementRef;
  @ViewChild('customYAxis') customYAxis: ElementRef;

  public maxBarThickness: number = 15;

  // Chart Colors
  public chartColor: string = tailwindJs.theme.extend.colors['chart-bar-1'];
  public chartHoverColor: string =  tailwindJs.theme.extend.colors['chart-bar-1-hover'];

  public hasCosts: boolean;
  public chartTitle: string = '';
  public showEurNonAvailableMessage: boolean = false;
  public selectedUnit = CHART_UNIT.NOVALUE;
  public ChartUnit = CHART_UNIT;
  public consumptionUnit: string;
  public chartDataWidth: number;
  public dataPoints: Array<Tooltip> = [];
  public barChartLabels: Array<any> = [];
  public chartData:  ChartConfiguration<'bar'>['data'];
  public openTooltip: Tooltip = {};
  public consumptions: Array<number> = [];
  public currentAggregationIndex: number = 0;
  public costs: Array<number> = [];
  public displayScrollLeft: boolean = false;
  public displayScrollRight: boolean = true;
  public state: LOADING_STATE = LOADING_STATE.LOADING;
  public LoadingState = LOADING_STATE;
  public aggregationOrdering: Array<TimeAggregation> = ['YEARLY', 'MONTHLY', 'DAILY'];
  public aggregations: ChartPeriods = {
    'DAILY': {
      title: 'Tagesansicht',
      timeUnitHeadline: 'Uhrzeit',
      timeUnitFormat: 'HH:mm',
      drillUp: {}
    },
    'MONTHLY': {
      title: 'Monatsansicht',
      timeUnitHeadline: 'Datum',
      timeUnitFormat: 'dd.MM.y',
      drillUp: {}
    },
    'YEARLY': {
      title: 'Jahresansicht',
      timeUnitHeadline: 'Datum',
      timeUnitFormat: 'dd.MM.y',
      drillUp: {}
    },
  };

  public barChartOptions: ChartOptions<'bar'> = {
    datasets: {
      bar: {
        maxBarThickness: this.maxBarThickness,
        hoverBackgroundColor: this.chartHoverColor,
      }
    },
    responsive: true,
    color: this.chartColor,
    backgroundColor: this.chartColor,

    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    onHover: function (event: ChartEvent, activeElements: any): any {
      this.canvas.style.cursor = activeElements.length ? 'pointer' : 'default';
    },

    scales: {
      yAxes: {
        stacked: true,
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: false,
          drawTicks: false
        },
        beginAtZero: true,
        ticks: {
          font: {
            family: fontFamily,
            size: fontSize
          },
          padding: 10,
          // autoSkipPadding: 50, currently not working in chartjs^2.8; do NOT update to ^2.9 (not working in IE) // fix below in callback
          autoSkip: true,
          callback: function (value, index) {
            // do not print first zero on x-axis
            // or should be removed as soon as autoSkipPadding is working
            if (value === 0 || index % 2 === 0) {
              return null;
            }
            return value;
          }
        }
      },

      xAxes: {
        stacked: true,
        offset: true,
        grid: {
          display: true,
          drawBorder: true,
          drawOnChartArea: false,
          drawTicks: false
        },
        ticks: {
          font: {
            family: fontFamily,
            size: fontSize
          },
          maxRotation: 0,
          autoSkip: true,
          padding: 10,
          autoSkipPadding: 25,
        }
      }
    },
    animation: {
      duration: 0,
      onComplete: function (this) {
        // redraw y-axis for fixed position, needed for horizontal scrolling
        const scale = window.devicePixelRatio;
        const sourceCanvas = this.canvas;
        const copyWidth = this.scales['yAxes'].width + 1;
        const copyHeight = this.scales['yAxes']?.height + this.scales['yAxes'].top + 5;
        const tempCustomYAxis = document.getElementById('customYAxis');
        const targetCanvas = tempCustomYAxis.firstElementChild as HTMLCanvasElement;
        const ctx = targetCanvas?.getContext('2d');
        tempCustomYAxis.style.width = copyWidth + 'px';
        if (targetCanvas && ctx) {
          targetCanvas.height = copyHeight;
          ctx.scale(scale, scale);
          ctx.canvas.width = copyWidth * scale;
          ctx.canvas.height = copyHeight * scale;
          ctx.canvas.style.width = copyWidth + 'px';
          ctx.canvas.style.height = copyHeight + 'px';
          ctx.drawImage(sourceCanvas, 0, 0, copyWidth * scale, copyHeight * scale, 0, 0, copyWidth * scale, copyHeight * scale);
        }

      },
    }
  };

  /**
   *
   * @param apiService
   * @param trackingService
   * @param unitPrettyPipe
   */
  constructor(
    private apiService: BdoApiService,
    private trackingService: TrackingService,
    public unitPrettyPipe: UnitPrettyPipe
  ) {
    moment.locale('de');
  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.onResize(event);
  }


  /**
   * hide stuff on start-chart
   * get and show first data for screen
   */
  ngOnInit() {
    this.getDataPoints(TimeAggregation.YEARLY);
  }

  /**
   * setupChartLayout if we are switching back from table to chart
   */
  ngOnChanges(): void {
    if (!this.showTable) {
      setTimeout(() => {
        this.setUpChartLayout();
      }, 0); // 0 -> run after Angular Rendering Process
    }
  }


  public onResize(event) {
    if (this.selectedUnit) {
      this.setUpChartLayout();
    }
  }

  public getDataPoints(aggregation: TimeAggregation, startDate?: any, endDate?: any) {
    this.state = LOADING_STATE.LOADING;
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.IMSYS_CHART, 'set Aggregation.' + aggregation);
    this.apiService.getProfiles(this.contractId, aggregation, startDate?.getTime(), endDate?.getTime()).subscribe(
      {
        next: (res) => {
          if (res) {
            this.aggregations[aggregation].drillUp = {
              endDate: endDate,
              startDate: startDate
            };
            this.currentAggregationIndex = this.aggregationOrdering.indexOf(aggregation);

            this.closeTooltip();
            this.dataPoints = [];
            this.barChartLabels = [];
            this.consumptions = [];
            this.costs = [];
            this.consumptionUnit = res.unit;

            /** Fallback: show empty Chart if no data received */
            if (res.dataBlock.length === 0) {
              this.createEmptyChart(res);
              return;
            }

            if (aggregation !== TimeAggregation.MONTHLY) {
              this.chartTitle = moment(res.dataBlock[0].startDate).format('LL') + ' - ' + moment(res.dataBlock[res.dataBlock.length - 1].endDate).format('LL');
            } else {
              this.chartTitle = moment(res.dataBlock[0].startDate).format('LL');
            }
            this.selectedUnit = CHART_UNIT[res.unit];
            this.hasCosts = res.hasCosts;
            // Ein PofilesDataBlock = Ein Balken
            res.dataBlock.forEach( (profileDataBlock: ProfileDataBlock) => {
              const provisionalText = profileDataBlock.data[0].isProvisional ? ' - Ersatzwert -' : '';
              const titleFormat = (aggregation !== TimeAggregation.DAILY) ? 'L' : 'HH:mm';

              this.dataPoints.push(
                {
                  consumption: profileDataBlock.data[0].consumption + ' ' + this.unitPrettyPipe.transform(res.unit) + provisionalText,
                  consumptionRaw: profileDataBlock.data[0].consumption,
                  cost: profileDataBlock.data[0].cost,
                  isDrillDownPossible: profileDataBlock.isDrillDownPossible,
                  startDate: profileDataBlock.startDate as any,
                  endDate: profileDataBlock.endDate as any,
                  title: ((aggregation !== TimeAggregation.MONTHLY) ?
                    moment(profileDataBlock.startDate).format(titleFormat) + ' - ' + moment(profileDataBlock.endDate).format(titleFormat)
                    : moment(profileDataBlock.startDate).format(titleFormat)),
                }
              );

              if (aggregation !== TimeAggregation.DAILY) {
                this.barChartLabels.push([moment(profileDataBlock.endDate).format('DD.MM.'), moment(profileDataBlock.endDate).format('YYYY')]);
              } else {
                this.barChartLabels.push(moment(profileDataBlock.endDate).format('HH:mm'));
              }
              profileDataBlock.data.forEach( (profileData: ProfileData) => {
                this.consumptions.push(profileData.consumption);
                this.costs.push(profileData.cost);
              });

            });
            this.state = LOADING_STATE.IDLE;
            this.updateChartData('consumptionRaw');
          } else {
            this.state = LOADING_STATE.ERROR;
          }
        },
        error: () => {
          this.state = LOADING_STATE.ERROR;
        }
      });
  }


  /**
   * just close tooltip
   */
  public closeTooltip(): void {
    setTimeout(() => {
      this.tooltip.nativeElement.style.visibility = 'hidden';
    }, 0);
  }

  /**
   * just change unit eg kwh/eur
   *
   * @param unit
   */
  public updateUnit(unit: CHART_UNIT) {
    this.closeTooltip();
    if (unit === this.selectedUnit) {
      return;
    }
    if (unit === CHART_UNIT.EUR) {
      this.updateChartData('cost');
    } else {
      this.updateChartData('consumptionRaw');
    }
    this.selectedUnit = unit;
  }

  /**
   * check chart-width and if we need to display scrollbars
   */
  public setUpChartLayout() {
    const neededChartSize = this.customYAxis.nativeElement.clientWidth + (this.barChartLabels.length * (this.maxBarThickness + 5));
    const availableChartSize = this.chartAreaWrapper.nativeElement.clientWidth;

    this.chartAreaWrapper.nativeElement.scrollTo(0, 0);

    if (availableChartSize < neededChartSize) {
      this.chartScrollbar.nativeElement.style.marginLeft = this.customYAxis.nativeElement.clientWidth + 'px';
      this.chartDataWidth = neededChartSize;
      this.chartScrollbar.nativeElement.style.visibility = 'visible';
    } else {
      this.chartDataWidth = availableChartSize;
      this.chartScrollbar.nativeElement.style.visibility = 'hidden';
    }
  }

  /**
   * smooth scrolling in chartArea by clicking arrows
   *
   * @param movement in pixels
   */
  public scroll(movement: number) {
    this.chartAreaWrapper.nativeElement.scrollBy({ left: movement, behavior: 'smooth' });
  }

  /**
   * enable/disable scroll-arrows depending on scroll-position
   * close tooltip
   */
  public scrollChart() {
    this.closeTooltip();

    this.displayScrollLeft = this.displayScrollRight = true;

    // calculate if scrolling left is possible
    if (this.chartAreaWrapper.nativeElement.scrollLeft <= 0) {
      this.displayScrollLeft = false;
    }

    // calculate if scrolling right is possible
    if (this.chartAreaWrapper.nativeElement.scrollLeft +
      this.chartAreaWrapper.nativeElement.clientWidth + 3 > this.chartAreaWrapper.nativeElement.scrollWidth) {
      this.displayScrollRight = false;
    }
  }

  /**
   *  1. update tooltip content
   *  2. calculate position of tooltip+tooltip-iconPosition; eg if tooltip is overlapping chart-area, dock to closest chart-border
   *  3. show tooltip
   *
   * @param clickEvent
   */
  public showTooltip(clickEvent: object) {

    const active = clickEvent['active'];
    // click on chart, but _NOT_ on bar
    if (active.length === 0) {
      this.closeTooltip();
      return null;
    }

    /* update tooltip-content */
    try {
      // kwh/eur value
      this.tooltip.nativeElement.style.visibility = 'visible';
      const clickedBar = active[0];
      this.openTooltip = this.dataPoints[clickedBar['index']];

      setTimeout(() => {
        /* position tooltip  */
        const tooltipWidth = this.tooltip.nativeElement.clientWidth;
        const tooltipHeight = this.tooltip.nativeElement.clientHeight;
        let arrowLeft = tooltipWidth / 2 - 10;
        const scrollLeft = this.chartAreaWrapper.nativeElement.scrollLeft;

        // default, tooltip is just centered above the bar
        let x = clickedBar['element']['x'] - (tooltipWidth / 2);
        let y = clickedBar['element']['y'] - tooltipHeight - 10;

        // dock tooltip top, if tooltip is overlapping chartarea
        if (clickedBar['element']['y'] - tooltipHeight - 10 <= 0) {
          y = 0;
        }

        // dock tooltip left, if tooltip is overlapping chartarea / calculate iconPosition-position
        if (x <= this.chart.chart.scales['yAxes'].width + scrollLeft) {
          arrowLeft = clickedBar['element']['x'] - 10 - scrollLeft;
          x = scrollLeft;
        }

        // dock tooltip right, if tooltip is overlapping chartarea / calculate iconPosition-position
        if (x + tooltipWidth + this.chart.chart.scales['yAxes'].width >= this.chartAreaWrapper.nativeElement.clientWidth + scrollLeft) {
          arrowLeft = tooltipWidth - (this.chartAreaWrapper.nativeElement.clientWidth + scrollLeft - clickedBar['element']['x'] + 10 - 4);
          x = this.chartAreaWrapper.nativeElement.clientWidth + scrollLeft - tooltipWidth - 4;
        }

        // set positions and show tooltip
        this.tooltip.nativeElement.style.left = x + 'px';
        this.tooltip.nativeElement.style.top = y + 'px';
        this.tooltipArrowDown.nativeElement.style.left = arrowLeft + 'px';

      }, 1);

    } catch (e) {
      console.log(e);
    }
  }

  isVisibleChartTabs() {
    return this.dataPoints?.length > 0;
  }


  private updateChartData(type: 'consumptionRaw' | 'cost') {
    this.chartData = {
      datasets: [{
        data: this.dataPoints.map((item) => item[type]),
        label: ''
      }],
      labels: this.barChartLabels
    };
    setTimeout(() => {
      this.setUpChartLayout();
    }, 0);
  }

  private createEmptyChart(res: ProfileDatas) {
    this.state = LOADING_STATE.IDLE;
    this.chartData = {
      datasets: [],
      labels: []
    };
    this.chartTitle = moment(res.periodFrom).format('LL') + ' - ' + moment().format('LL');
    this.hasCosts = false;
    this.selectedUnit = CHART_UNIT.NOVALUE;
  }
}
