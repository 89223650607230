import { Component, OnInit } from '@angular/core';
import { Environment } from '../../../../../environments/environment';
import { TENANT } from '../../../enums/tenant.enum';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { TrackingService } from '../../../services/tracking.service';

@Component({
  selector: 'bdo-contract-cancel-auth',
  templateUrl: './contract-cancel-auth.component.html',
  styleUrls: ['./contract-cancel-auth.component.scss']
})
export class ContractCancelAuthComponent implements OnInit {
  public Environment = Environment;
  public Tenant = TENANT;
  public TRACKING = TRACKING;

  constructor(
    private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_CANCEL_AUTH, TRACKING.ACTION.ENTER);
  }

}
