import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { City } from '../../../../../assets/js/com/ts_api_client';
import { AutosuggestComponent } from '../../../../shared/atoms/autosuggest/autosuggest.component';
import { AutosuggestItem } from '../../../../shared/atoms/autosuggest/models/autosuggest-item.model';
import { defaultValidatorProxy } from '../../../../shared/validators/default-validator-proxy';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { BdoApiService } from '../../../services/bdo-api.service';
import { StorageService } from '../../../services/storage.service';
import { TrackingService } from '../../../services/tracking.service';
import { autosuggestAvailable } from '../../../../shared/validators/autosuggest-available-validator';
import { autosuggestMatches } from '../../../../shared/validators/autosuggest-matches-validator';
import { flatten as _flatten } from 'lodash';
import { AddressFormtype } from '../../../../shared/formtypes/address.formtype';
import { PersonalDataFormtype } from '../../../../shared/formtypes/personal-data.formtype';
import { StoragePersonalData } from '../../../models/storagePersonalData';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnDestroy {
  @ViewChild('autosuggestComponent') autoSuggestComponent: AutosuggestComponent;
  @Input() orderForm: FormGroup<PersonalDataFormtype>;
  public personalData: StoragePersonalData;
  public streets$: Observable<string[]>;
  public searchString$: Observable<string>;
  public noStreetsAvailable$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public city: City;
  public addressForm = new FormGroup<AddressFormtype>({
    cityDisplay: new FormControl(''),
    city: new FormControl(''),
    postCode: new FormControl(StorageService.getPersonalData()?.addressData?.postCode),
    street: new FormControl('', {
      validators: [
        defaultValidatorProxy(Validators.minLength(2), this.translateService.instant('general.validator.minLength', { numberOfCharacters: 2 } )),
        defaultValidatorProxy(Validators.required, this.translateService.instant('address.street.required')),
      ]
    }),
    housenumber: new FormControl('', {
      validators: [defaultValidatorProxy(Validators.required, this.translateService.instant('address.housenumber.required'))],
      asyncValidators: [
        (control) => {
          if (!this.addressForm || !this.addressForm.get('street').value || !control.value || !this.city) {
            return of(null);
          } else {
            return this.apiService.validateAddress({
              houseNum: control.value,
              street: {
                name: this.addressForm.get('street').value,
                city: this.city
              }
            }).pipe(map((addressValidate) => {
              return addressValidate.valid ? null : { notValid: this.translateService.instant('address.housenumber.notFound') };
            }));
          }
        }
      ] })
  });

  private subscriptions: Subscription = new Subscription();

  constructor (
    private apiService: BdoApiService,
    private trackingService: TrackingService,
    private translateService: TranslateService
    ) { }


  /**
   * Sort Autosuggest Result:
   * Place the strings that begin with the searchstring first, then the other items from a-z
   * Example:
   * searchstring: su
   * result before sort: asu, bsu, sua, sub
   * result after sort: sua, sub, asu, bsu
   * @param {AutosuggestItem} a
   * @param {AutosuggestItem} b
   * @returns {number}
   */
  public sortAutosuggest = function sortAutosuggest(a: AutosuggestItem, b: AutosuggestItem) {
    if (a.index === 0) { return -1; }
    return 0;
  };

  ngOnInit(): void {
    this.orderForm.addControl('addressData', this.addressForm);
    this.addressForm.get('cityDisplay').disable();
    this.personalData = StorageService.getPersonalData();
    // Only if not given by tariffadvisor

    const getCity$ = of(this.personalData?.addressData?.city).pipe(
      switchMap((city: string) => {
        if (city) {
          return of({
            name: this.personalData?.addressData?.city,
            postCode: this.personalData?.addressData?.postCode
          });
        } else {
          return this.apiService.getCityByPostCode(this.personalData?.addressData?.postCode).pipe(map((result) => {
            return result?.list[0];
          }));
        }
      }),
      tap({ next: (city: City) => {
        if (city) {
          this.addressForm.get('city').setValue(city.name);
          this.addressForm.get('cityDisplay').setValue(city.postCode + ', ' + city.name);
          this.city = city;
        } else {
          this.addressForm.get('cityDisplay').setValue('');
          this.addressForm.get('city').setValue('');
        }
        this.addressForm.controls.housenumber.updateValueAndValidity();
      } }),
      shareReplay(1)
    );
    this.subscriptions.add(getCity$.subscribe());
    this.searchString$ = this.addressForm.get('street').valueChanges;


    this.streets$ = getCity$.pipe(
      switchMap(() => this.apiService.getStreetsByPostCode(this.personalData?.addressData?.postCode)),
      map(res => {
        return _flatten(res.list.filter(item => item.name === this.city.name).map(values => values.streets));
      }),
      shareReplay(1)
    );
    this.subscriptions.add(this.streets$.subscribe()); // intial load of street

    // set street async Validators
    this.addressForm.get('street').setAsyncValidators([
      autosuggestAvailable(this.noStreetsAvailable$, this.translateService.instant('general.validator.autosuggestNoResult')),
      autosuggestMatches(this.streets$, this.translateService.instant('address.notValid'))
    ]);

    this.personalData = StorageService.getPersonalData();
    this.addressForm.get('street').setValue(this.personalData?.addressData?.street);
    this.addressForm.get('housenumber').setValue(this.personalData?.addressData?.housenumber);

    // inputs are not editable when they come from previous process
    if (!this.personalData?.streetIsChangeable) {
      this.addressForm.get('street').disable();
    }
    if (!this.personalData?.housenumIsChangeable) {
      this.addressForm.get('housenumber').disable();
    }

  }


  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onItemSelected(selectedStreet: string) {
    this.trackAutosuggestSelection(selectedStreet);
    this.addressForm.get('street').setValue(selectedStreet);
    // revalidate housenumber, since the housenumber, that has been entered before might be invalid for the new street
    this.addressForm.get('housenumber').updateValueAndValidity();
  }

  private trackAutosuggestSelection(selectedStreet: string) {
    this.trackingService.postAutosuggestTracking(
      TRACKING.FORM.DELIVERY_PERSONALDATA_SECTION_DATA,
      'street',
      this.addressForm.get('street').value,
      selectedStreet, {
        postCode: this.personalData?.addressData?.postCode
      }
    );
  }
}
