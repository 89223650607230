import { Component, Input } from '@angular/core';

@Component({
  selector: 'bdo-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
  @Input() fullscreen: boolean = false;
  @Input() validation: boolean = false;

  constructor() { }
}
