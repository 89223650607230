"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeviceType = exports.UserAgent = exports.Device = exports.Conversion = exports.PageView = exports.BrowserType = exports.Browser = exports.CustomData = void 0;
var nonce_1 = require("./nonce");
var CustomData = /** @class */function () {
  function CustomData(id, value) {
    var _this = this;
    this.obtainFullPostTextLine = function () {
      var encodedValue = encodeURIComponent(JSON.stringify([[_this.value, 1]]));
      return "eventType=customData&index=" + _this.id + "&valueToCount=" + encodedValue + "&overwrite=true&nonce=" + _this.nonce;
    };
    this.id = id;
    this.value = value;
    this.nonce = (0, nonce_1.obtainNonce)();
  }
  return CustomData;
}();
exports.CustomData = CustomData;
var Browser = /** @class */function () {
  function Browser(browser) {
    var _this = this;
    this.obtainFullPostTextLine = function () {
      if (_this.browser < 0) {
        throw "Browser not recognized";
      }
      return "eventType=staticData&browserIndex=" + _this.browser + "&nonce=" + _this.nonce;
    };
    this.browser = browser;
    this.nonce = (0, nonce_1.obtainNonce)();
  }
  return Browser;
}();
exports.Browser = Browser;
var BrowserType;
(function (BrowserType) {
  BrowserType[BrowserType["Chrome"] = 0] = "Chrome";
  BrowserType[BrowserType["InternetExplorer"] = 1] = "InternetExplorer";
  BrowserType[BrowserType["Firefox"] = 2] = "Firefox";
  BrowserType[BrowserType["Safari"] = 3] = "Safari";
  BrowserType[BrowserType["Opera"] = 4] = "Opera";
  BrowserType[BrowserType["Other"] = 5] = "Other";
})(BrowserType = exports.BrowserType || (exports.BrowserType = {}));
var PageView = /** @class */function () {
  function PageView(url, title, referrers) {
    var _this = this;
    this.obtainFullPostTextLine = function () {
      var _a, _b;
      return "eventType=page&href=" + encodeURIComponent(_this.url) + "&title=" + _this.title + (((_b = (_a = _this.referrers) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 0 ? "&referrersIndices=[" + _this.referrers + "]" : "") + "&nonce=" + _this.nonce;
    };
    this.url = url;
    this.title = title;
    this.referrers = referrers;
    this.nonce = (0, nonce_1.obtainNonce)();
  }
  return PageView;
}();
exports.PageView = PageView;
var Conversion = /** @class */function () {
  function Conversion(goalId, revenue, negative) {
    var _this = this;
    if (revenue === void 0) {
      revenue = 0;
    }
    if (negative === void 0) {
      negative = false;
    }
    this.obtainFullPostTextLine = function () {
      return "eventType=conversion&goalId=" + _this.goalId.toString() + "&revenue=" + _this.revenue.toString() + "&negative=" + _this.negative.toString() + "&nonce=" + _this.nonce;
    };
    this.goalId = goalId;
    this.revenue = revenue;
    this.negative = negative;
    this.nonce = (0, nonce_1.obtainNonce)();
  }
  return Conversion;
}();
exports.Conversion = Conversion;
var Device = /** @class */function () {
  function Device(device) {
    var _this = this;
    this.obtainFullPostTextLine = function () {
      return "eventType=staticData&deviceType=" + _this.device + "&nonce=" + _this.nonce;
    };
    this.device = device;
    this.nonce = (0, nonce_1.obtainNonce)();
  }
  return Device;
}();
exports.Device = Device;
var UserAgent = /** @class */function () {
  function UserAgent(value) {
    this.obtainFullPostTextLine = function () {
      return "";
    };
    this.value = value;
  }
  return UserAgent;
}();
exports.UserAgent = UserAgent;
var DeviceType;
(function (DeviceType) {
  DeviceType["Phone"] = "PHONE";
  DeviceType["Tablet"] = "TABLET";
  DeviceType["Desktop"] = "DESKTOP";
})(DeviceType = exports.DeviceType || (exports.DeviceType = {}));
exports.default = {
  browsers: {
    CHROME: 0,
    INTERNET_EXPLORER: 1,
    FIREFOX: 2,
    SAFARI: 3,
    OPERA: 4,
    OTHER: 5
  },
  Conversion: Conversion,
  CustomData: CustomData,
  Browser: Browser,
  BrowserType: BrowserType,
  UserAgent: UserAgent,
  PageView: PageView,
  Device: Device,
  DeviceType: DeviceType
};