<div class="bdo-loading__wrapper" [class.bdo-loading__wrapper--height]="state === LoadingState.LOADING">
  <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
  <bdo-info-box *ngIf="state === LoadingState.ERROR">
    {{ 'general.error.error' | translate }}
  </bdo-info-box>
  <div *ngIf="state === LoadingState.IDLE">
    <div class="re-summary-row">
      <div>{{ 'general.customerData.account' | translate }}</div>
      <div>{{accountId}}</div>
    </div>
    <div class="re-summary-row re-mb-0">
      <div>{{ 'general.tariff.deliveryAddress' | translate }}</div>
      <div [innerHtml]="address | address"></div>
    </div>
  </div>
</div>
