import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DateUtils } from '../utils/dateUtils';

@Injectable()
export class DateDeserializerInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          const body = DateUtils.convertToDate(event.body);
          return event.clone({ body });
        }

        return event;
      })
    );
  }
}
