import { Component, Input } from '@angular/core';
import { TariffAdvisorService } from '../../../services/tariff-advisor.service';
import { ContractDetails } from '../../../../../assets/js/com/ts_api_client';
import { CUSTOMERMODE } from '../../../enums/customerMode';

@Component({
  selector: 'bdo-contract-details-small-tile',
  templateUrl: './contract-details-small-tile.component.html',
  styleUrls: ['./contract-details-small-tile.component.scss']
})
export class ContractDetailsSmallTileComponent {
  @Input() contract: ContractDetails;
  @Input() customerMode: CUSTOMERMODE;
  @Input() accountId: string = '';
  @Input() changeReason: 'priceChanges' | 'productChanges' = 'priceChanges';
  @Input() showPriceCap: boolean = false;

  constructor(
    public tariffAdvisorService: TariffAdvisorService
  ) {}
}
