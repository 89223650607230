<div [formGroup]="form">

  <div class="re-form-item-container">
    <bdo-input
      formControlName="accountholder"
      [formTracking]="formTracking"
      [validationIconHidden]="validationIconHidden"
      [placeholder]="'banking.accountholder' | translate"
      [formControl]="$any(form.get('accountholder'))"
    ></bdo-input>
  </div>

  <div class="re-form-item-container re-mb-5">
    <bdo-input
      formControlName="iban"
      [formTracking]="formTracking"
      [validationIconHidden]="validationIconHidden"
      [placeholder]="'banking.iban' | translate"
      [formControl]="$any(form.get('iban'))"
      [mask]="'iban'"
    ></bdo-input>
  </div>

  <div class="re-form-item-container re-mb-30">
    <div class="re-min-h-27 re-opacity-75 re-text-xs" *ngIf="form.get('bankname')?.value?.length > 0; else placeholder">
      {{ form.get('bankname').value }}
    </div>

    <ng-template #placeholder>
      <div class="re-min-h-27 re-opacity-75 re-text-xs">
        {{ 'banking.banknamePlaceholder' | translate }}
      </div>
    </ng-template>
  </div>

</div>
