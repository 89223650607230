import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { CustomerStoreService, PLACEHOLDER_ROUTE_ACCOUNTID } from '../bdo/services/customer-store.service';
import { LoginService } from '../login/login.service';
import { switchMap } from 'rxjs/operators';
import { BdoApiService } from '../bdo/services/bdo-api.service';
import { StorageService } from '../bdo/services/storage.service';

const getFirstAccountId = (loginService: LoginService, apiService: BdoApiService, onlyActive: boolean = false): Observable<{
  isLoggedIn: boolean;
  accountId?: string;
  isActive?: boolean;
}> => {
  return loginService.isLoggedIn().pipe(
    switchMap((isLoggedIn) => {
      if (isLoggedIn) {
        return apiService.getAccounts().pipe(
          map((accounts) => accounts.filter((account) => !onlyActive || account.inaktiv === false)),
          map((accounts) => ({
            accountId: accounts?.[0]?.accountId,
            isLoggedIn,
            isActive: accounts?.[0]?.inaktiv === false
          }))
        );
      } else {
        const authData = StorageService.getAuthData();
        if (authData?.checkIdentificationData?.accountId) {
          return of({
            accountId: authData.checkIdentificationData.accountId,
            isLoggedIn
          });
        }
        return of({
          accountId: undefined,
          isLoggedIn
        });
      }
    }));
};

export const guardAccountId: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const accountIdFromRoute = route.paramMap.get('kundennummer') || route.parent.paramMap.get('kundennummer');
  const apiService = inject(BdoApiService);
  const router = inject(Router);
  const loginService = inject(LoginService);
  const customerStoreService = inject(CustomerStoreService);

  /**
   * assign first account id to customer store
   * @param onlyActive if true, only active accounts are considered
   */

  /*
  * 1. if accountId is placeholder, assign first account id to customer store
   */
  if (accountIdFromRoute === PLACEHOLDER_ROUTE_ACCOUNTID){
    return getFirstAccountId(loginService, apiService, true).pipe(
      map(({ accountId }) => {
        const updatedUrl = state.url.replace(PLACEHOLDER_ROUTE_ACCOUNTID, (accountId ? accountId : ''));
        customerStoreService.setAccountId(accountIdFromRoute);
        return router.createUrlTree([updatedUrl], {
          queryParams: route.queryParams,
          fragment: route.fragment,
          queryParamsHandling: 'merge'
        });
      }));
    /*
    * 2. if accountId is not placeholder, but part of the route, set it always
     */
  } else if (accountIdFromRoute && !accountIdFromRoute.includes(PLACEHOLDER_ROUTE_ACCOUNTID)) {
    customerStoreService.setAccountId(accountIdFromRoute);
    return true;
    /*
    * 3. if accountId is not set in route and not set in customer store, assign first account id to customer store
     */
  } else {
    return getFirstAccountId(loginService, apiService).pipe(
      map(({ accountId, isLoggedIn, isActive }) => {
        if (!accountId && isLoggedIn){
          loginService.logout();
          return router.createUrlTree(['/in-bearbeitung']);
        } else if (!customerStoreService.getAccountId()){
          customerStoreService.setAccountId(accountId);
          customerStoreService.setCurrentAccountIdIsInactive(!isActive);
        }
        return true;
      })
    );
  }
};
