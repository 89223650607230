/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AccountPeriod { 
    /**
     * accountId of existing contract, also knows as VK
     */
    accountId?: string;
    periodStart?: Date;
    periodEnd?: Date;
}

