/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Information about a document
 */
export interface DocumentData { 
    date?: Date;
    title?: string;
    group?: string;
    id?: string;
}

