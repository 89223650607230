"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var TargetingTree = /** @class */function () {
  function TargetingTree(orOperator, leftChild, rightChild, targetingCondition) {
    this.orOperator = orOperator;
    this.leftChild = leftChild;
    this.rightChild = rightChild;
    this.targetingCondition = targetingCondition;
  }
  return TargetingTree;
}();
exports.default = TargetingTree;