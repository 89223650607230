<div class="re-fixed re-right-0 re-bottom-0 re-gap-x-3 re-z-3000">
  <button class="re-bg-mineshaft hover:re-bg-primary re-cursor-pointer mr-0 md:re-mr-30 lg:re-mr-45 transition re-mt-10"
    type="button"
    [attr.data-qa]="'open-slide-over'"
    [title]="'hotlineSlider.aria.open' | translate"
    (click)="openSlideOver()">
    <div class="re-flex re-items-center re-h-60 re-w-60 re-justify-center re-text-white">
      <svg-icon
        src="assets/icons/phone-icon-slider.svg"
       >
      </svg-icon>
    </div>
  </button>
</div>

<div *ngIf="isVisible" class="re-top-0 re-left-0 re-fixed re-h-auto re-inset-0 re-z-3000 re-text-xs">
  <div (click)="closeSlideOver($event)" class="backdrop re-fixed re-inset-0 close-trigger">
    <div class="modal-sidebar-inner re-ml-auto re-overflow-y-auto" [@slideIn]>
      <div class="re-modal-header re-relative">
        <button class="close-button close-trigger re-fixed re-right-0 re-flex re-w-60 re-h-60 mx-w-60 re-border-none re-items-center re-no-underline re-justify-center re-bg-mineshaft hover:re-bg-primary"
          type="button"
          [title]="'hotlineSlider.aria.closeIcon' | translate"
          (click)="closeSlideOver($event, true)">
            <svg-icon
              class="re-text-white re-w-20"
              src="assets/icons/close.svg">
            </svg-icon>
        </button>
      </div>
      <div class="re-modal-content sm:re-h-full">
        <div class="re-bg-white sm:re-h-screen">
          <div class="re-overflow-y-auto re-h-full">
            <div class="re-pl-30 re-pr-45 re-pt-60 sm:re-pt-120">
              <h2 class="re-text-sm re-leading-m" [attr.data-qa]="'slide-over-headline'"><span>{{ slideOverHeadline }}</span></h2>
              <div class="re-leading-s" [attr.data-qa]="'slide-over-text'" [innerHTML]="slideOverText"></div>
            </div>
            <div class="re-border-t-2 re-border-solid re-my-20 re-border-white-smoke"></div>
            <div class="re-pl-30 re-pr-45 re-flex re-gap-20 re-antialiased re-leading-s">
              <svg-icon
                src="assets/icons/phone-icon.svg"
                [svgAriaLabel]="'hotlineSlider.aria.phoneIcon' | translate">
              </svg-icon>
              <div>
                <h2 class="re-text-sm re-leading-m">
                  <span>{{ 'hotlineSlider.phone' | translate }}</span>
                </h2>
                <a href="{{'tel: ' + slideOverTelephoneNumber}}" [attr.data-qa]="'slide-over-phonenumber'"
                  class="re-font-regular re-leading-s"
                  (click)="onPhoneSelection()">
                  {{ slideOverTelephoneNumber }}
                </a>
                <div class="re-leading-s" [innerHTML]="'hotlineSlider.openHours' | translate"></div>
              </div>
            </div>
            <div class="re-border-t-2 re-border-solid re-my-20 re-border-white-smoke"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
