import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskIBAN'
})
/** Masking Iban only showing the last 4 Characters and 4 Stars in front */
export class MaskIBANPipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    if (!value) {
      return value;
    }
    value = '****' + value.substring(value.length - 4);

    return value;
  }

}
