<h4 class="space--bottom" [innerHTML]="'termsOfService.title' | translate"></h4>

<p class="space--bottom">{{ 'termsOfService.description' | translate }}</p>

<div class="space--bottom">
  <bdo-checkbox
    [checked]="tosAccepted"
    [required]="tosTouched"
    [errorText]="'termsOfService.error' | translate"
    (change)="onTosClicked();">
    <span>{{ 'termsOfService.agreement.1' | translate }}
      <a class="bdo-link--inline" [routerLink]="['/nutzungsbedingungen']" (click)="trackIt()" target="_blank">
        {{ 'termsOfService.agreement.2' | translate }}
      </a>
      {{ 'termsOfService.agreement.3' | translate }}</span>
  </bdo-checkbox>
</div>
<button class="bdo-button-primary" (click)="acceptTos(tosAccepted)">{{ 'termsOfService.submit' | translate }}</button>
