import { Component } from '@angular/core';
import { PollQuestionComponent } from '../poll-question/poll-question.component';

@Component({
  selector: 'bdo-scale-question',
  templateUrl: './scale-question.component.html',
  styleUrls: ['./scale-question.component.scss']
})
export class ScaleQuestionComponent extends PollQuestionComponent{
  selectedValue: number = 1;
  disabled = true;

  constructor() {
    super();
   }

}
