import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'delimiter'
})
/** Takes an array and converts it to a String using a given delimiter*/
export class DelimiterPipe implements PipeTransform {

  transform(value: any, delimiter: string): any {
    if (value instanceof String ) { return value; }
    let delimitedValue = '';

    if (value instanceof Array ) {
      for (let i = 0; i < value.length; i++) {
        delimitedValue += value[i];
        if (i < value.length - 1) {
          delimitedValue += delimiter;
        }

      }
    } else { return value; }
    return delimitedValue;
  }

}
