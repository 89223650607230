import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnonymousMeterReadingComponent } from '../bdo/components/anonymous/anonymous-meter-reading/anonymous-meter-reading.component';
import { BillingPlanComponent } from '../bdo/components/billing/billing-plan/billing-plan.component';
import { ContactComponent } from '../bdo/components/contact/contact.component';
import { DashboardComponent } from '../bdo/components/dashboard/dashboard.component';
import { DocumentsComponent } from '../bdo/components/documents/documents.component';
import { LogoutSuccessComponent } from '../bdo/components/logout-success/logout-success.component';
import { PaymentsComponent } from '../bdo/components/payments/payments.component';
import { TermsOfServiceComponent } from '../bdo/components/terms-of-service/terms-of-service.component';
import { VerbrauchComponent } from '../bdo/components/verbrauch/verbrauch.component';
import { DELIVERY_TYPE } from '../bdo/enums/deliveryType';
import { PasswordForgotComponent } from '../login/password-forgot/password-forgot.component';
import { RegisterActivateComponent } from '../login/register-activate/register-activate.component';
import { RegisterComponent } from '../login/register/register.component';
import { UsernameForgotComponent } from '../login/username-forgot/username-forgot.component';
import { MeterreadingsComponent } from '../bdo/components/meterreadings/meterreadings.component';
import { BillingEditComponent } from '../bdo/components/billing/billing-edit/billing-edit.component';
import { ConstructionSiteComponent } from '../bdo/components/construction-site/construction-site.component';
import { CustomerStatusComponent } from '../bdo/components/delivery/customer-status/customer-status.component';
import { CustomerDataInitComponent } from '../bdo/components/customer-data-init/customer-data-init.component';
import { WizardComponent } from '../bdo/components/delivery/wizard/wizard.component';
import { SituationComponent } from '../bdo/components/delivery/situation/situation.component';
import { PersonalDataFormComponent } from '../bdo/components/delivery/personal-data-form/personal-data-form.component';
import { SituationFormComponent } from '../bdo/components/delivery/situation-form/situation-form.component';
import { PaymentComponent } from '../bdo/components/delivery/payment/payment.component';
import { SummaryComponent } from '../bdo/components/delivery/summary/summary.component';
import { ConfirmationComponent } from '../bdo/components/delivery/confirmation/confirmation.component';
import { DenialComponent } from '../bdo/components/delivery/denial/denial.component';
import { NewHomeComponent } from '../bdo/components/move/new-home/new-home.component';
import { MoveSummaryComponent } from '../bdo/components/move/move-summary/move-summary.component';
import { TariffAdvisorComponent } from '../bdo/components/tariff-advisor/tariff-advisor.component';
import { guardLoggedIn } from './logged-in.guard';
import { RecentHomeComponent } from '../bdo/components/move/recent-home/recent-home.component';
import { MoveConfirmationComponent } from '../bdo/components/move/move-confirmation/move-confirmation.component';
import { TariffAdivsorSummaryComponent } from '../bdo/components/tariff-advisor/tariff-adivsor-summary/tariff-adivsor-summary.component';
import { MoveStartComponent } from '../bdo/components/move/move-start/move-start.component';
import { LoginpageComponent } from '../login/loginpage/loginpage.component';
import { MovePaymentComponent } from '../bdo/components/move/move-payment/move-payment.component';
import { PersonalDataComponent } from '../bdo/components/personal-data/personal-data.component';
import { ProcessNotAvailableComponent } from '../bdo/components/process-not-available/process-not-available.component';
import { guardTenantMove } from './tenant-move.guard';
import { ContractOverviewComponent } from '../bdo/components/contract-overview/contract-overview.component';
import { ContractEditComponent } from '../bdo/components/contract-edit/contract-edit.component';
import { ContractNewAddressComponent } from '../bdo/components/contract-new/contract-new-address/contract-new-address.component';
import { ContractEditSummaryComponent } from '../bdo/components/contract-edit/contract-edit-summary/contract-edit-summary.component';
import { ContractEditConfirmationComponent } from '../bdo/components/contract-edit/contract-edit-confirmation/contract-edit-confirmation.component';
import { ContractNewProductsComponent } from '../bdo/components/contract-new/contract-new-products/contract-new-products.component';
import { ContractNewPaymentComponent } from '../bdo/components/contract-new/contract-new-payment/contract-new-payment.component';
import { ContractEndRecentHomeComponent } from '../bdo/components/contract-end/contract-end-recent-home/contract-end-recent-home.component';
import { ContractEndPaymentComponent } from '../bdo/components/contract-end/contract-end-payment/contract-end-payment.component';
import { BillingAddressComponent } from '../bdo/components/contract-end/billing-address/billing-address.component';
import { ContractNewTariffsComponent } from '../bdo/components/contract-new/contract-new-tariffs/contract-new-tariffs.component';
import { ContractEndSummaryComponent } from '../bdo/components/contract-end/contract-end-summary/contract-end-summary.component';
import { ContractEndConfirmationComponent } from '../bdo/components/contract-end/contract-end-confirmation/contract-end-confirmation.component';
import { DeferPaymentComponent } from '../bdo/components/payments/defer-payment/defer-payment.component';
import { SiminvoiceOverviewComponent } from '../bdo/components/siminvoice/siminvoice-overview/siminvoice-overview.component';
import { ContractNewSummaryComponent } from '../bdo/components/contract-new/contract-new-summary/contract-new-summary.component';
import { ContractNewConfirmationComponent } from '../bdo/components/contract-new/contract-new-confirmation/contract-new-confirmation.component';
import { ContractCancelAuthComponent } from '../bdo/components/contract-cancel/contract-cancel-auth/contract-cancel-auth.component';
import { ContractCancelSelectionComponent } from '../bdo/components/contract-cancel/contract-cancel-selection/contract-cancel-selection.component';
import { ContractCancelAddressComponent } from '../bdo/components/contract-cancel/contract-cancel-address/contract-cancel-address.component';
import { ContractCancelDateComponent } from '../bdo/components/contract-cancel/contract-cancel-date/contract-cancel-date.component';
import { ContractCancelSummaryComponent } from '../bdo/components/contract-cancel/contract-cancel-summary/contract-cancel-summary.component';
import { ContractCancelConfirmationComponent } from '../bdo/components/contract-cancel/contract-cancel-confirmation/contract-cancel-confirmation.component';
import { ContractCancelStatusComponent } from '../bdo/components/contract-cancel-status/contract-cancel-status.component';
import { PasswordRenewComponent } from '../login/password-renew/password-renew.component';
import { ContractEditAuthComponent } from '../bdo/components/contract-edit/contract-edit-auth/contract-edit-auth.component';
import { OfferStatusComponent } from '../bdo/components/offer/offer-status/offer-status.component';
import { OfferTariffAdvisorComponent } from '../bdo/components/offer/offer-tariff-advisor/offer-tariff-advisor.component';
import { guardAuthAll } from './auth-all.guard';
import { guardVebOut } from './veb-out.guard';
import { VeboutExistingComponent } from '../bdo/components/vebout-existing/vebout-existing.component';
import { guardAccountId } from './account-id-guard.guard';
import {
  AccountInProgressWarningComponent
} from '../bdo/components/account-in-progress-warning/account-in-progress-warning.component';
import { HOTLINE_SLIDE_PROCESS } from '../bdo/components/hotline-slide-over/hotline-slide-over.component';
import { ContractEndStatusComponent } from '../bdo/components/contract-end/contract-end-status/contract-end-status.component';
import { EmailVerifyComponent } from '../login/email-verify/email-verify.component';
import { VEBOUTGUARD_PROCESS } from '../bdo/enums/vebOutGuardProcess.enum';
import { guardRestrictedRegio } from './restricted-regio.guard';
import { guardAuth } from './auth.guard';
import { readonlyGuard } from './readonly.guard';
import { ChargeFlexComponent } from '../bdo/components/contract-edit/charge-flex/charge-flex.component';
import { StorageService } from '../bdo/services/storage.service';


// Add data - breadcumb to add path to breadcrumb
// When a path has children add the component of the parent path as pathMatch-full-child instead of at parent level
// data-example data: { breadcrumb: 'Zählerstand erfassen', pageTitle: 'Jetzt Zählerstand erfassen', enableCrawling: true, metaDescription: 'Hallo Welt' }
export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'zaehlerstand/erfassen', component: AnonymousMeterReadingComponent,
    canActivate: [readonlyGuard],
    data: {
      pageTitle: 'Zählerstand erfassen',
      enableCrawling: true,
      metaDescription: 'OnlineService für Privat- und Gewerbekunden - Erfassen Sie Ihren Zählerstand online unter Angabe Ihrer Kunden- und Zählernummer.'
    }
  },
  {
    path: 'baustelle',
    component: ConstructionSiteComponent,
    data: { breadcrumb: 'breadcrumb.construction' },
  },
  {
    path: 'in-bearbeitung',
    component: AccountInProgressWarningComponent,
    data: { breadcrumb: 'breadcrumb.inProgress', context: 'standalone' },
  },
  {
    path: 'hinweis', component: ProcessNotAvailableComponent,
    data: { breadcrumb: 'breadcrumb.processNotAvailable' },
  },
  {
    path: 'hinweis-auszug', component: VeboutExistingComponent,
    data: { breadcrumb: 'breadcrumb.vebOutExisting' },
  },
  {
    path: 'angebot',
    canActivate: [readonlyGuard],
    component: OfferStatusComponent,
  },

  {
    path: 'vertrag-abschliessen',
    canActivate: [readonlyGuard],
    children: [
      {
        path: 'danke', component: ConfirmationComponent,
      },
      {
        path: 'ablehnung', component: DenialComponent
      },
      { path: '', redirectTo: 'kundenstatus', pathMatch: 'full' },
      {
        path: 'kundenstatus',
        component: CustomerStatusComponent
      },
      {
        path: 'neu', component: WizardComponent,
        data: {
          wizardSteps: [
            { title: 'wizard.step.offer',
              iconPath: 'assets/icons/wizard_steps/choose_tariff.svg',
              path: 'vertragsauswahl',
              optional: true,
              condition: () => { return StorageService.getOfferContext(); }
             },
            { title: 'wizard.step.personal', iconPath: 'assets/icons/wizard_steps/avatar.svg', path: 'persoenliche-daten' },
            { title: 'wizard.step.supply', iconPath: 'assets/icons/wizard_steps/calendar.svg', path: 'situation' },
            { title: 'wizard.step.payment', iconPath: 'assets/icons/wizard_steps/pricing.svg', path: 'zahlungsart' },
            { title: 'wizard.step.summary', iconPath: 'assets/icons/wizard_steps/overview.svg', path: 'uebersicht' }
          ],
          hotlineSlider: HOTLINE_SLIDE_PROCESS.DELIVERY
        },
        children: [
          { path: '', redirectTo: 'persoenliche-daten', pathMatch: 'full' },
          {
            path: 'vertragsauswahl', component: OfferTariffAdvisorComponent,
          },
          {
            path: 'persoenliche-daten', component: PersonalDataFormComponent,
          },
          {
            path: 'situation', component: SituationComponent,
            children: [
              {
                path: 'wechsel', component: SituationFormComponent,
                data: {
                  situation: DELIVERY_TYPE.CHANGE
                }
              },
              {
                path: 'umzug', component: SituationFormComponent,
                data: {
                  situation: DELIVERY_TYPE.MOVE,
                }
              }
            ]
          },
          {
            path: 'zahlungsart', component: PaymentComponent,
          },
          {
            path: 'uebersicht', component: SummaryComponent
          },
        ]
      }
    ]
  },
  {
    path: 'umzug',
    canActivate: [readonlyGuard],
    canActivateChild: [guardTenantMove],
    children: [
      { path: '', redirectTo: 'auth', pathMatch: 'full' },
      {
        path: 'auth', component: MoveStartComponent
      },
      {
        path: 'danke', component: MoveConfirmationComponent
      },
      { path: '', component: CustomerDataInitComponent, pathMatch: 'full', canActivate: [guardAccountId] },
      {
        path: ':kundennummer',
        canActivate: [ guardAccountId, guardAuthAll, guardVebOut(VEBOUTGUARD_PROCESS.MOVE), guardRestrictedRegio ],
        canActivateChild: [ guardAccountId, guardRestrictedRegio ],
        children: [
        {
          path: 'neu', component: WizardComponent,
          data: {
            wizardSteps: [
              { title: 'wizard.step.recentHome', iconPath: 'assets/icons/wizard_steps/current_home.svg', path: 'bisheriges-zuhause' },
              { title: 'wizard.step.newHome', iconPath: 'assets/icons/wizard_steps/new_home.svg', path: 'neues-zuhause' },
              { title: 'wizard.step.productSelection', iconPath: 'assets/icons/wizard_steps/choose_tariff.svg', path: 'tarifauswahl' },
              { title: 'wizard.step.payment', iconPath: 'assets/icons/wizard_steps/pricing.svg', path: 'zahlungsart' },
              { title: 'wizard.step.summary', iconPath: 'assets/icons/wizard_steps/overview.svg', path: 'uebersicht' }
            ]
          },
          children: [
              { path: '', redirectTo: 'bisheriges-zuhause', pathMatch: 'full' },
              {
                path: 'bisheriges-zuhause', component: RecentHomeComponent,
              },
              {
                path: 'neues-zuhause', component: NewHomeComponent,
              },
              {
                path: 'tarifauswahl/zusammenfassung',
                component: TariffAdivsorSummaryComponent,
              },
              {
                path: 'tarifauswahl/:step',
                component: TariffAdvisorComponent,
              },
              {
                path: 'tarifauswahl',
                redirectTo: 'tarifauswahl/1'
              },
              {
                path: 'zahlungsart', component: MovePaymentComponent,
              },
              {
                path: 'uebersicht', component: MoveSummaryComponent,
              },
            ]
          }
        ]
      }
    ]
  },
  {
    path: 'kuendigungsart',
    canActivate: [readonlyGuard],
    component: ContractCancelStatusComponent,
  },
  {
    path: 'kuendigen',
    canActivate: [readonlyGuard],
    children: [
      { path: '', redirectTo: 'auth', pathMatch: 'full' },
      {
        path: 'auth', component: ContractCancelAuthComponent
      },
      {
        path: 'danke', component: ContractCancelConfirmationComponent
      },
      {
        path: 'neu', component: WizardComponent,
        canActivate: [ guardAuthAll, guardVebOut(VEBOUTGUARD_PROCESS.CANCEL) ],
        data: {
          wizardSteps: [
            { title: 'wizard.step.chooseContract', iconPath: 'assets/icons/wizard_steps/choose_tariff.svg', path: 'vertragsauswahl' },
            { title: 'wizard.step.contractEnd', iconPath: 'assets/icons/wizard_steps/calendar.svg', path: 'vertragsende' },
            { title: 'wizard.step.paymentAddress', iconPath: 'assets/icons/haeufigeFunktionen/icon-edit-data.svg', path: 'rechnungsadresse' },
            { title: 'wizard.step.summary', iconPath: 'assets/icons/wizard_steps/overview.svg', path: 'uebersicht' }
          ],
          hotlineSlider: HOTLINE_SLIDE_PROCESS.CANCEL
        },
        children: [
          { path: '', redirectTo: 'vertragsauswahl', pathMatch: 'full' },
          {
            path: 'vertragsauswahl', component: ContractCancelSelectionComponent,
          },
          {
            path: 'vertragsende', component: ContractCancelDateComponent,
          },
          {
            path: 'rechnungsadresse', component: ContractCancelAddressComponent,
          },
          {
            path: 'uebersicht', component: ContractCancelSummaryComponent,
          },
        ]
      }
    ]
  },
  {
    path: 'dashboard',
    canActivate: [guardAuth],
    component: DashboardComponent,
    data: { breadcrumb: 'breadcrumb.dashboard' },
  },
  {
    path: 'simulationsrechnung',
    canActivate: [readonlyGuard, guardAccountId, guardAuth],
    canActivateChild: [guardAccountId, guardAuth],
    data: { breadcrumb: 'breadcrumb.simInvoice' },
    children: [
      { path: '', component: CustomerDataInitComponent, pathMatch: 'full' },
      {
        path: ':kundennummer',
        canActivate: [guardRestrictedRegio],
        children: [
          { path: '', component: SiminvoiceOverviewComponent, pathMatch: 'full' },
        ]
      }
    ]
  },
  {
    path: 'umziehen',
    redirectTo: '/umzug/auth',
  },
  {
    path: 'umzug-melden',
    redirectTo: '/umzug/auth',
  },
  {
    path: 'kontakt',
    canActivate: [readonlyGuard, guardAccountId, guardAuth],
    canActivateChild: [guardAccountId, guardAuth],
    data: { breadcrumb: 'breadcrumb.contact' },
    children: [
      { path: '', component: CustomerDataInitComponent, pathMatch: 'full' },
      {
        path: ':kundennummer',
        component: ContactComponent
      }
    ]
  },
  {
    path: 'abschlag',
    canActivate: [guardAccountId, guardAuth],
    canActivateChild: [guardAccountId, guardAuth],
    data: { breadcrumb: 'breadcrumb.billingOverview' },
    children: [
      { path: '', component: CustomerDataInitComponent, pathMatch: 'full' },
      {
        path: ':kundennummer',
        children: [
          { path: '', component: BillingPlanComponent, pathMatch: 'full' },
          {
            path: 'anpassen',
            canActivate: [readonlyGuard, guardRestrictedRegio],
            component: BillingEditComponent,
            data: { breadcrumb: 'breadcrumb.billingEdit' }
          }
        ]
      }
    ]
  },
  {
    path: 'dokumente',
    canActivate: [guardAccountId, guardAuth],
    canActivateChild: [guardAccountId, guardAuth],
    data: { breadcrumb: 'breadcrumb.documents' },
    children: [
      { path: '', component: CustomerDataInitComponent, pathMatch: 'full' },
      {
        path: ':kundennummer',
        component: DocumentsComponent
      }
    ]
  },
  {
    path: 'anmelden',
    canActivate: [readonlyGuard, guardAccountId, guardAuth],
    canActivateChild: [guardAccountId, guardAuth],
    children: [
      { path: '', component: CustomerDataInitComponent, pathMatch: 'full' },
      {
        path: ':kundennummer',
        children: [
          {
            path: 'danke', component: ContractNewConfirmationComponent
          },
          { path: '', redirectTo: 'neu', pathMatch: 'full' },
          {
            path: 'neu', component: WizardComponent,
            canActivate: [guardRestrictedRegio],
            data: {
              wizardSteps: [
                { title: 'wizard.step.address', iconPath: 'assets/icons/wizard_steps/new-contract.svg', path: 'adresse' },
                { title: 'wizard.step.products', iconPath: 'assets/icons/wizard_steps/energy.svg', path: 'energiearten' },
                { title: 'wizard.step.productSelection', iconPath: 'assets/icons/wizard_steps/choose_tariff.svg', path: 'tarifauswahl' },
                { title: 'wizard.step.payment', iconPath: 'assets/icons/wizard_steps/pricing.svg', path: 'zahlungsart' },
                { title: 'wizard.step.summary', iconPath: 'assets/icons/wizard_steps/overview.svg', path: 'uebersicht' },
              ]
            },
            children: [
              { path: '', redirectTo: 'adresse', pathMatch: 'full' },
              {
                path: 'adresse',
                component: ContractNewAddressComponent,
              },
              {
                path: 'energiearten',
                component: ContractNewProductsComponent,
              },
              {
                path: 'tarifauswahl/zusammenfassung',
                component: TariffAdivsorSummaryComponent,
              },
              {
                path: 'tarifauswahl/:step',
                component: ContractNewTariffsComponent,
              },
              {
                path: 'tarifauswahl',
                redirectTo: 'tarifauswahl/1'
              },
              {
                path: 'zahlungsart',
                component: ContractNewPaymentComponent,
              },
              {
                path: 'uebersicht',
                component: ContractNewSummaryComponent,
              },
            ]
          }
        ]
    }]
  },
  {
    path: 'abmelden',
    canActivate: [readonlyGuard, guardAccountId, guardAuth],
    canActivateChild: [guardAccountId, guardAuth],
    children: [
      { path: '', component: CustomerDataInitComponent, pathMatch: 'full' },
      {
        path: ':kundennummer',
        canActivate: [ guardVebOut(VEBOUTGUARD_PROCESS.END), guardRestrictedRegio ],
        children: [
          { path: '', redirectTo: 'neu', pathMatch: 'full' },
          {
            path: 'auswahl', component: ContractEndStatusComponent,
            data: { hotlineSlider: HOTLINE_SLIDE_PROCESS.CANCEL }
          },
          {
            path: 'danke', component: ContractEndConfirmationComponent,
            data: {
              breadcrumbDisabled: true
            }
          },
          {
            path: 'neu', component: WizardComponent,
            data: {
              wizardSteps: [
                { title: 'wizard.step.recentAddress', iconPath: 'assets/icons/wizard_steps/current_home.svg', path: 'adresse' },
                { title: 'wizard.step.payment', iconPath: 'assets/icons/wizard_steps/pricing.svg', path: 'zahlungsart' },
                { title: 'wizard.step.paymentAddress', iconPath: 'assets/icons/haeufigeFunktionen/icon-edit-data.svg', path: 'rechnungsadresse' },
                { title: 'wizard.step.summary', iconPath: 'assets/icons/wizard_steps/overview.svg', path: 'uebersicht' },
              ]
            },
            children: [
              { path: '', redirectTo: 'adresse', pathMatch: 'full' },
              {
                path: 'adresse',
                component: ContractEndRecentHomeComponent,
              },
              {
                path: 'zahlungsart',
                component: ContractEndPaymentComponent,
              },
              {
                path: 'rechnungsadresse',
                component: BillingAddressComponent,
              },

              {
                path: 'uebersicht',
                component: ContractEndSummaryComponent,
              },
            ]
          }
        ]
    }]
  },
  {
    path: 'vertrag',
    children: [
      {
        path: '',
        canActivate: [guardAccountId, guardAuth],
        component: CustomerDataInitComponent,
        pathMatch: 'full'
      },
      {
        path: 'auth',
        canActivate: [readonlyGuard],
        component: ContractEditAuthComponent,
      },
      {
        path: 'danke',
        canActivate: [readonlyGuard],
        component: ContractEditConfirmationComponent,
      },
      {
        path: ':kundennummer',
        canActivate: [guardAccountId, guardAuthAll, guardRestrictedRegio],
        canActivateChild: [guardAccountId, guardRestrictedRegio],
        children: [
          {
            path: '',
            component: ContractOverviewComponent,
            pathMatch: 'full',
            data: { breadcrumb: 'breadcrumb.contractOverview' }
          },
          {
          path: 'anpassen',
          canActivate: [readonlyGuard],
          component: WizardComponent,
          data: {
            wizardSteps: [
              { title: 'wizard.step.meterSelection',
                iconPath: 'assets/icons/wizard_steps/zaehlerstand_30x30.svg',
                path: 'zaehlerauswahl',
                optional: true,
                condition: () => { return StorageService.getChargeFlexContext(); }
              },
              { title: 'wizard.step.offer', iconPath: 'assets/icons/wizard_steps/offer_tariff.svg', path: 'tarifauswahl' },
              { title: 'wizard.step.summary', iconPath: 'assets/icons/wizard_steps/overview.svg', path: 'uebersicht' }
            ],
          },
          children: [
            { path: '', redirectTo: 'tarifauswahl', pathMatch: 'full' },
            {
              path: 'zaehlerauswahl',
              component: ChargeFlexComponent,
            },
            {
              path: 'tarifauswahl',
              component: ContractEditComponent,
            },

            {
              path: 'uebersicht',
              component: ContractEditSummaryComponent,
            },
          ]
        }]
      }
    ]
  },
  {
    path: 'tarif',
    redirectTo: 'vertrag'
  },
  {
    path: 'daten',
    canActivate: [guardAccountId, guardAuth],
    canActivateChild: [guardAccountId, guardAuth],
    data: { breadcrumb: 'breadcrumb.personal' },
    children: [
      { path: '', component: CustomerDataInitComponent, pathMatch: 'full' },
      {
        path: ':kundennummer',
        component: PersonalDataComponent
      }
    ]
  },
  {
    path: 'verbrauch/:kundennummer',
    canActivate: [guardAccountId, guardAuth],
    component: VerbrauchComponent,
    data: { breadcrumb: 'breadcrumb.consumptionHistory' }
  },
  {
    path: 'bankdaten',
    children: [
      { path: '', redirectTo: '/daten', pathMatch: 'full' },
      { path: ':kundennummer', redirectTo: '/daten/:kundennummer' }
    ]
  },
  {
    path: 'zahlungen',
    canActivate: [guardAccountId, guardAuth],
    canActivateChild: [guardAccountId, guardAuth],
    data: { breadcrumb: 'breadcrumb.payments' },
    children: [
      { path: '', component: CustomerDataInitComponent, pathMatch: 'full' },
      {
        path: ':kundennummer',
        component: PaymentsComponent,
      },
      {
        path: ':kundennummer/verschieben/:paymentId',
        canActivate: [readonlyGuard],
        component: DeferPaymentComponent,
        data: { breadcrumb: 'breadcrumb.deferPayment' }
      }
    ]
  },
  {
    path: 'zaehlerstand',
    canActivate: [guardAccountId, guardAuth],
    canActivateChild: [guardAccountId, guardAuth],
    data: { breadcrumb: 'breadcrumb.meterreadings' },
    children: [
      { path: '', component: CustomerDataInitComponent, pathMatch: 'full' },
      {
        path: ':kundennummer',
        children: [
          { path: '', component: MeterreadingsComponent },
          {
            path: 'erfassen',
            redirectTo: ''
          }
        ]
      }
    ]
  },
  {
    path: 'zaehlerstandeingabe/:kundennummer',
    redirectTo: 'zaehlerstand/:kundennummer'
  },

  {
    path: 'login',
    canActivate: [guardLoggedIn],
    component: LoginpageComponent,
    data: {
      enableCrawling: true
    },
  },
  { path: 'registrieren',
    canActivate: [readonlyGuard],
    component: RegisterComponent,
    data: {
      pageTitle: 'Registrierung',
      enableCrawling: true
    }
  },
  {
    path: 'passwort-vergessen',
    component: PasswordForgotComponent,
    data: { pageTitle: 'Passwort vergessen', enableCrawling: true }
  },
  {
    path: 'passwort-erneuern',
    component: PasswordRenewComponent,
    data: { pageTitle: 'Passwort erneuern', enableCrawling: true }
  },
  {
    path: 'benutzername-vergessen',
    component: UsernameForgotComponent,
    data: { pageTitle: 'Benutzername vergessen', enableCrawling: true }
  },
  {
    path: 'aktivieren',
    canActivate: [readonlyGuard],
    component: RegisterActivateComponent
  },
  {
    path: 'verifizieren',
    canActivate: [readonlyGuard],
    component: EmailVerifyComponent
  },
  {
    path: 'nutzungsbedingungen',
    component: TermsOfServiceComponent,
    data: { pageTitle: 'Nutzungsbedingungen', enableCrawling: true }
  },
  // Old or for Tests
  { path: 'logout', component: LogoutSuccessComponent, data: { pageTitle: 'Erfolgreich ausgeloggt' } },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    scrollOffset: [0, 0],
    anchorScrolling: 'enabled'
})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
