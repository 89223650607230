import { Injectable } from '@angular/core';
import { DatadogConfig, Environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { datadogRum } from '@datadog/browser-rum';
import { DebugLogger } from '../../shared/utils/debugLogger';

@Injectable({
  providedIn: 'root'
})
export class DatadogService {
  private datadogLoaded$ = new BehaviorSubject<boolean>(false);

  constructor() {
    this.datadogLoaded$.next(false);
  }

  public getIsLoaded() {
    return this.datadogLoaded$.getValue(); // current value of the BS
  }

  loadDatadog(cookiesEnabled: boolean) {
    try {
      datadogRum.init({
        applicationId: DatadogConfig?.applicationId,
        clientToken: DatadogConfig?.clientToken,
        site: 'datadoghq.eu',
        service: 'onlineservice-frontend',
        env: Environment.stage.toLowerCase(),
        version: DatadogConfig?.appVersion,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: false,
        trackViewsManually: true,
        defaultPrivacyLevel: 'mask',
        allowedTracingUrls: [
          { match: /http(s?):\/\/.*\.gewkoelnag\.de/, propagatorTypes: ['tracecontext'] },
          { match: /http(s?):\/\/.*\.execute-api\.eu-central-1\.amazonaws\.com/, propagatorTypes: ['tracecontext'] }
        ]
      });
      DebugLogger.debug(this.constructor.name, 'loadDatadog()', `loading with cookies ${cookiesEnabled ? 'enabled' : 'disabled'}`);
      this.datadogLoaded$.next(true);
    } catch (error) {
      DebugLogger.error(this.constructor.name, 'loadDatadog()', error);
    }
  }

  public unloadDatadog() {
    DebugLogger.debug(this.constructor.name, 'unloadDatadog()');
    this.datadogLoaded$.next(false);
  }

  public trackCustomEvent(name: string) {
    this.datadogLoaded$.pipe(
      filter((isLoaded) => !!isLoaded),
      first()
    ).subscribe({
      next: () => {
        datadogRum.addAction(name);
      }
    });
  }

  public trackPageView(page: string) {
    // track pageView after datadog is loaded (delayed, because we have to wait for the user input of the cookie handling
    // to load datadog with the correct disableCookieUsage setting)
    this.datadogLoaded$.pipe(
      filter((isLoaded) => !!isLoaded),
      first()
    ).subscribe(
      {
        next: () => {
          DebugLogger.debug(this.constructor.name, 'trackPageView()', page);

          datadogRum.startView({
            name: page
          });
        },
        error: (error: unknown) => {
          DebugLogger.error(this.constructor.name, 'trackPageView()', error);
        }
      });
  }

}
