/* eslint-disable @typescript-eslint/naming-convention */
export enum TARIFF_TEXT_KEYS {
    InfoI_withBonus = 'infotextBonus',
    InfoI_withoutBonus = 'infotext',
    InfoI_workprice = 'workPrice',
    InfoI_baseprice = 'base',
    InfoI_priceCapS4 = 'priceBrake_E',
    InfoI_priceCapG6 = 'priceBrake_G',
    PriceInfo_privateCustomer_S4 = 'noteGross_E',
    PriceInfo_privateCustomer_G6 = 'noteGross_G',
    PriceInfo_tradeCustomer_S4 = 'noteNet_E',
    PriceInfo_tradeCustomer_G6 = 'noteNet_G'
}
