<div *ngIf="situationData?.deliveryType === DeliveryType.CHANGE" class="re-mb-45">
  <h5 class="re-mb-30">
    {{ 'summary.situation.cancel.title' | translate: { division: divisionTitle } }}
  </h5>

  <ul class="bdo-list__bullet re-mt-30">
    <li *ngIf="situationData.termination === 'auto' || situationData.revocationWanted; else self">
      {{ 'summary.situation.cancel.auto' | translate: { division: divisionTitle } }}
    </li>
    <ng-template #self>
      <li>
        {{ 'summary.situation.cancel.self' | translate: { division: divisionTitle } }}
      </li>
    </ng-template>
  </ul>
</div>

<h5 class="re-mb-30">
  {{ 'delivery.situation.details.title' | translate }}
</h5>

<div class="re-summary-row">
  <div>{{ 'delivery.situation.details.startDate' | translate }}</div>
  <div>{{ situationData?.startDate | date: 'dd.MM.yyyy' }}</div>
</div>

<div class="re-summary-row" *ngIf="situationData.previousSupplier">
  <div>{{ 'delivery.situation.details.previousSupplierShort' | translate }}</div>
  <div>{{ situationData?.previousSupplier }}</div>
</div>


<div *ngFor="let meter of meterData">
  <h5 class="re-mb-30 re-mt-45">
    {{ 'general.tariff.meterWithDivision' | translate: { division: meter.divisionId | verbrauchstyp } }}
  </h5>
  <div class="re-summary-row">
    <div>{{ 'general.customerData.meternumber' | translate }}</div>
    <div>{{ meter?.meterNumber }}</div>
  </div>
  <ng-template [ngIf]="meter.register?.length && situationData.deliveryType === DeliveryType.MOVE">
    <div class="re-summary-row" *ngFor="let register of meter?.register">
      <ng-template [ngIf]="register.wert">
        <div>{{ 'meterreadings.meterreading' | translate }} {{ ['HT', 'NT'].includes(register.typ) ? ('' | zaehlwerktyp: register.typ) : '' }}</div>
        <div>{{ register?.wert}} {{ register?.einheit | unitPretty }}</div>
      </ng-template>
    </div>
  </ng-template>
  <!-- Optional for meters with separate previous Suppliers / cancellations -->
  <div class="re-summary-row" *ngIf="meter.previousSupplier">
    <div>{{ 'delivery.situation.details.previousSupplierShort' | translate }}</div>
    <div>{{ meter?.previousSupplier }}</div>
  </div>
  <ul class="bdo-list__bullet re-mt-15" *ngIf="meter.termination">
    <li *ngIf="meter.termination === 'auto'; else self">
      {{ 'summary.situation.cancel.auto' | translate: { division: meter.divisionId | verbrauchstyp } }}
    </li>
    <ng-template #self>
      <li>
        {{ 'summary.situation.cancel.self' | translate: { division: meter.divisionId | verbrauchstyp } }}
      </li>
    </ng-template>
  </ul>

</div>
