<ng-container>
  <div class="re-relative">
    <label [for]="inputName" class="animated fadeInUp re-block re-mr-20"
           [class.re-hidden]="(showValidationErrors && formControl.invalid)"
           [class.hidden]="(inputIsEmpty() && !focused)">
           {{ labelText }}
    </label>

    <ng-container *ngIf="formControl?.invalid && showValidationErrors">
      <ng-container *ngFor="let error of formControl?.errors | keyvalue; let i=index" >
        <!-- show only the first error -->
        <div class="error re-mr-20" *ngIf="i < 1">
          {{error.value}}
        </div>
      </ng-container>
    </ng-container>
  </div>


  <div class="re-relative">
    <input
      [id]="id || inputName"
      [name]="inputName"
      [attr.data-qa]="dataQa"
      [formControl]="$any(control)"
      [type]="inputType"
      [placeholder]="placeholder"
      (blur)="onBlur()"
      (select)="onSelect()"
      (focus)="onFocus()"
      (keydown)="onKeyDown()"
      [mask]="maskString"
      [dropSpecialCharacters]="mask === 'date' ? false : true"
      [showMaskTyped]="mask === 'date' ? true : false"
      [autocomplete]="inputType === INPUT_TYPE.PASSWORD || !enableAutocomplete ?
        (autocomplete !== 'off' ? autocomplete : 'new-password') : 'off'"> <!-- Prevent Chrome Autofill-->

    <div *ngIf="showValidationIcon && !validationIconHidden && !formControl?.disabled && (!formControl?.pending)" class="validation-icon">
      <bdo-svg [name]="formControl?.valid ? (isWarning ? 'icon-questionmark' : 'icon-success') : 'icon-alert'"
               [styling]="formControl?.valid ? (isWarning ? 'bdo-svg--alert' : 'bdo-svg--success') : 'bdo-svg--error'"></bdo-svg>
    </div>
    <div class="validation-icon">
      <bdo-loading *ngIf="formControl?.pending" [validation]=true></bdo-loading>
    </div>
  </div>

  <div class="bdo-form__helptext" *ngIf="help" [innerHTML]="help"></div>

  <!-- Tooltip - show if tooltipContent is set -->
  <div *ngIf="tooltipContent">
    <div class="re-absolute re-right-0 re-top-2">
      <bdo-inline-tooltip>
        <div [innerHTML]="tooltipContent"></div>
      </bdo-inline-tooltip>
    </div>
  </div>

</ng-container>
