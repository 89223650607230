import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BdoApiService } from '../../../services/bdo-api.service';
import { ZaehlerIdentifier, Zaehlerstand } from '../../../../../assets/js/com/ts_api_client';
import { Router } from '@angular/router';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { DivisionToUnitPipe } from '../../../pipes/division-to-unit.pipe';
import { TrackingService } from '../../../services/tracking.service';
import { CustomerStoreService } from '../../../services/customer-store.service';

export const CONTEXT_FROM_SIMINVOICE_METER_READING = 'CONTEXT_FROM_SIMINVOICE_METER_READING';
@Component({
  selector: 'bdo-siminvoice-meter-readings',
  templateUrl: './siminvoice-meter-readings.component.html',
  styleUrls: ['./siminvoice-meter-readings.component.scss']
})
export class SiminvoiceMeterReadingsComponent implements OnChanges {

  @Input() selectedDate: Date;
  @Input() meter: ZaehlerIdentifier;
  @Input() invalidText: boolean = false;
  @Output() validEvent: EventEmitter<Zaehlerstand> = new EventEmitter();
  public meterreading: Zaehlerstand;
  public isExistingMeterreading: boolean = false;
  public accountId: string = '';

  public form = new FormGroup({
    firstZaehlwerkValue: new FormControl({
      value: 'Meterreading ERROR',
      disabled: true
    }),
    secondZaehlwerkValue: new FormControl({
      value: 'Meterreading ERROR',
      disabled: true
    })
  });

  constructor(
    private readonly customerStoreService: CustomerStoreService,
    public apiService: BdoApiService,
    public router: Router,
    public divisiontoUnitPipe: DivisionToUnitPipe,
    public trackingService: TrackingService) {
  }

  ngOnChanges() {
    this.initMeterreading();
  }

  initMeterreading() {
    this.apiService.getZaehlerstand(this.meter?.meterNumber, this.selectedDate).subscribe({ next: (result) => {
      this.meterreading = result;
      this.initMeterreadingExists();
      this.validOutput();
    } });
  }

  initMeterreadingExists() {
    let temp: string = '';
    if (this.meterreading.zaehlwerkeListe.length === 2) {
      if (this.meterreading.zaehlwerkeListe[1].wert !== null) {
         this.isExistingMeterreading = true;
         temp = ' (HT)';
         this.form.get('secondZaehlwerkValue').setValue(this.meterreading.zaehlwerkeListe[1].wert + ' '
         + this.divisiontoUnitPipe.mapDivision(this.meterreading.sparte) + ' (NT)');
      }
    }
    this.isExistingMeterreading = (this.meterreading.zaehlwerkeListe[0].wert !== null);
    this.form.get('firstZaehlwerkValue').setValue(this.meterreading.zaehlwerkeListe[0].wert + ' '
    + this.divisiontoUnitPipe.mapDivision(this.meterreading.sparte) + temp);

  }

  toMeterreadingsAdd() {
    this.router.navigate(['zaehlerstand/' + this.customerStoreService.getAccountId()], {
      state: {
        context: CONTEXT_FROM_SIMINVOICE_METER_READING,
        date: this.selectedDate,
        meterNumber: this.meter?.meterNumber
      }
    });
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.SIMINVOICE, TRACKING.ACTION.GOTO, TRACKING.LOCATION.METERREADINGS_ADD);
  }
  validOutput() {
    this.validEvent.emit(this.meterreading);
  }
}
