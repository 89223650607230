import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TenantService } from '../../services/tenant.service';
import { Environment } from '../../../../environments/environment';
import { TENANT } from '../../enums/tenant.enum';
import { TranslateService } from '@ngx-translate/core';

export enum HOTLINE_SLIDE_PROCESS {
  'DELIVERY' = 'delivery',
  'CANCEL' = 'cancel'
}

@Component({
  selector: 'bdo-hotline-slide-over',
  templateUrl: './hotline-slide-over.component.html',
  styleUrls: ['./hotline-slide-over.component.scss']
})
export class HotlineSlideOverComponent implements OnInit, OnDestroy{
  public isDisabled: boolean = false;
  public slideOverHeadline: string = '';
  public slideOverText: string = '';
  public slideOverTelephoneNumber: string = '';
  private routerEventSub: Subscription;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private tenantService: TenantService,
    private translateService: TranslateService
  ) {
    this.setSlideOver(this.activatedRoute.root);
  }

  ngOnInit(): void {
    this.routerEventSub = this.router.events.subscribe({ next: event => {
      if (event instanceof NavigationEnd) {
        this.setSlideOver(this.activatedRoute.root);
      }
    } });
  }

  ngOnDestroy(): void {
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
  }

  private setSlideOver(route: ActivatedRoute) {
    if (Environment.tenant !== TENANT.Rheinenergie) {
      this.isDisabled = true;
      return;
    }

    const process = route?.routeConfig?.data?.hotlineSlider ? route.routeConfig.data.hotlineSlider : '' as HOTLINE_SLIDE_PROCESS;
    this.isDisabled = process === '';

    if (route.firstChild && process === '') {
      // Look in the upper paths if slider should be shown
      return this.setSlideOver(route.firstChild);
    }

    switch (process) {
      case HOTLINE_SLIDE_PROCESS.DELIVERY:
        this.slideOverHeadline = this.translateService.instant('hotlineSlider.default.title');
        this.slideOverText = this.translateService.instant('hotlineSlider.default.description');
        this.slideOverTelephoneNumber = this.translateService.instant('hotlineSlider.default.phoneNumber');
        break;

      case HOTLINE_SLIDE_PROCESS.CANCEL:
        this.slideOverHeadline = this.translateService.instant('hotlineSlider.cancel.title');
        this.slideOverText = this.translateService.instant('hotlineSlider.cancel.description');
        this.slideOverTelephoneNumber = this.translateService.instant('hotlineSlider.cancel.phoneNumber');
        break;
    }
  }
}
