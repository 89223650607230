/* eslint-disable @typescript-eslint/naming-convention */
export enum TENANT {
    Rheinenergie = 'Rheinenergie',
    Belkaw = 'Belkaw',
    StadtwerkeLeichlingen = 'StadtwerkeLeichlingen',
    StadtwerkeSanktAugustin = 'StadtwerkeSanktAugustin'
}

export enum TENANT_ACRONYM {
    Rheinenergie = 're',
    Belkaw = 'belkaw',
    StadtwerkeLeichlingen = 'swl',
    StadtwerkeSanktAugustin = 'swa'
}
