<button class="toggle re-group re-flex re-cursor-pointer re-h-30 re-my-20"
        [attr.data-qa]="'option_' + id"
        [title]="description"
        (click)="changeOption()" >
  <div class="re-flex re-rounded-full re-w-60 re-h-30 re-relative transition"
       [ngClass]="{
                  're-right-0 re-bg-primary': enabled,
                  're-left re-bg-monochrome-medium': !enabled
                }">
    <div class="toggle_dot re-rounded-full re-w-24 re-h-24 re-bg-white re-block re-ml-3 re-absolute re-mr-3 re-mt-3 re-top-0"
         [class.re-right-0]="enabled"
    ></div>
  </div>
</button>
