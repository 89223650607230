import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BillingPlan } from '../../../../../../assets/js/com/ts_api_client';
import { defaultValidatorProxy } from '../../../../../shared/validators/default-validator-proxy';
import { INPUT_TYPE } from '../../../../enums/inputType.enum';
import { BILLING_STATES } from '../../../../services/billingplan-suggestion.service';
import { TariffAdvisorService } from '../../../../services/tariff-advisor.service';
import { LOADING_STATE } from '../../../../enums/loadingState.enum';
import { TranslateService } from '@ngx-translate/core';
import { DetailedBillingPlanItem } from '../../../../services/detailed-billingplan.service';

@Component({
  selector: 'bdo-billing-edit-division',
  templateUrl: './billing-edit-division.component.html',
  styleUrls: ['./billing-edit-division.component.scss']
})
export class BillingEditDivisionComponent implements OnInit, OnDestroy {

  @Input() billingByDivision: DetailedBillingPlanItem;
  @Input() billingPlan: BillingPlan;
  @Input() parentFormgroup: FormGroup = new FormGroup({});
  @Input() globalLoadingState: LOADING_STATE;
  public BILLING_STATE = BILLING_STATES;
  public INPUT_TYPE = INPUT_TYPE;
  public LoadingState = LOADING_STATE;
  public implausible: boolean = false;
  public inputNameAmount: string;
  public inputNameImplausibleChecked: string;
  private subscription = new Subscription();
  private showWarning: boolean = true;

  constructor(
    public tariffAdvisorService: TariffAdvisorService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.inputNameAmount = 'amount-' + this.billingByDivision?.contractId + '-' + this.billingByDivision?.division;
    this.inputNameImplausibleChecked = 'implausibleChecked-' + this.billingByDivision?.contractId + '-' + this.billingByDivision?.division;

    this.parentFormgroup?.addControl(this.inputNameAmount, new FormControl<number>(this.billingByDivision?.amount));
    this.setAmountValidators();

    // reset implausible if value changes to something plausible
    this.subscription.add(this.parentFormgroup?.get(this.inputNameAmount)?.valueChanges.subscribe({ next: () => {
      this.implausible = this.isImplausible();
      if (!this.implausible) {
        this.parentFormgroup?.removeControl(this.inputNameImplausibleChecked);
      }
    } }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public isWarning(): boolean {
    return this.showWarning && this.parentFormgroup?.get(this.inputNameAmount).value < this.billingByDivision?.amount;
  }

  private setAmountValidators() {
    this.parentFormgroup?.get(this.inputNameAmount).setValidators([
      defaultValidatorProxy(Validators.required, this.translateService.instant('billing.edit.amountRequired')),
      defaultValidatorProxy(Validators.pattern(/^\d*$/), this.translateService.instant('general.error.wrongNumberFormat')),
      this.tooLowValidator(),
      this.tooHighValidator(),
      this.noChangeableUpValidator(),
      this.noChangeableDownValidator(),
    ]);
  }

  private isImplausible(): boolean {
    if (this.parentFormgroup?.get(this.inputNameAmount).valid) {
      const implausible = this.parentFormgroup?.get(this.inputNameAmount).value > this.billingByDivision?.amount * 2;
      this.parentFormgroup?.addControl(this.inputNameImplausibleChecked, new FormControl<boolean>(false));
      this.parentFormgroup?.get(this.inputNameImplausibleChecked).setValidators([
        Validators.requiredTrue
      ]);
      this.parentFormgroup?.get(this.inputNameImplausibleChecked).updateValueAndValidity();
      return implausible;
    } else {
      return false;
    }
  }


  private noChangeableUpValidator(): (control: AbstractControl) => { noChangeableUp: string } {
    return (control: AbstractControl) => {
      const newValueIsHigher = parseInt(control.value, 10) > (this.billingByDivision?.amount);
      return !this.billingPlan?.changeableUp && newValueIsHigher ? {
        noChangeableUp: this.translateService.instant('billing.edit.noChangeableUp')
      } : null;
    };
  }

  private noChangeableDownValidator(): (control: AbstractControl) => { noChangableDown: string } {
    return (control: AbstractControl) => {
      const newValueIsLower = parseInt(control.value, 10) < (this.billingByDivision?.amount);
      return !this.billingPlan?.changeableDown && newValueIsLower ? {
        noChangableDown: this.translateService.instant('billing.edit.noChangeableDown')
      } : null;
    };
  }

  private tooLowValidator(): (control: AbstractControl) => { too_low: string } {
    return (control: AbstractControl) => {
      const isLoweredMoreThan25Percent = parseInt(control.value, 10) < (this.billingByDivision?.amount * 0.75);
      return !isLoweredMoreThan25Percent ? null : {
        too_low: this.translateService.instant('billing.edit.amountTooLow')
      };
    };
  }

  private tooHighValidator(): (control: AbstractControl) => { too_high: string } {
    return (control: AbstractControl) => {
      const isHigherThan250Percent = parseInt(control.value, 10) > (this.billingByDivision?.amount * 2.5);
      return !isHigherThan250Percent ? null : {
        too_high: this.translateService.instant('billing.edit.amountMaxHigh')
      };
    };
  }
}
