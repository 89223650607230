<div class="re-w-full re-mt-10 re-mb-3 md:re-w-600 md:re-mx-auto lg:re-w-900">

    <bdo-info-box [type]="'info'" [marginBottom]="false">
      <span class="re-hidden md:re-inline">
        {{ (changeReason === 'priceChanges' ?
          'tariff.priceAdjustment.hint' :
          'tariff.changeUpcoming.hint')
          | translate
        }}
      </span>
      <span class="md:re-hidden re-inline">
        {{ (changeReason === 'priceChanges' ?
          'tariff.priceAdjustment.hintSmall' :
          'tariff.changeUpcoming.hintSmall')
          | translate
        }}
      </span>
    </bdo-info-box>

</div>

<hr class="re-border-t-3 re--mx-30 re-border-monochrome-light">

<div class="re-bg-white re-w-full re-px-20 re-py-25 re-text
            md:re-w-600 md:re-mx-auto md:re-px-30
            lg:re-w-900">
  <div class="re-flex re-flex-col md:re-flex-row md:re-items-center">
    <div class="re-flex re-flex-row md:re-items-center">
    <div class="re-w-40 re-h-40 re-mr-10 lg:re-mr-15">
      <svg-icon [src]="tariffAdvisorService.getPathForIcon(contract?.division)"></svg-icon>
    </div>
    <div class="bold">{{contract?.tariffName}}</div>
    </div>
    <div class="re-ml-50 md:re-ml-auto"
         [innerHTML]="changeReason === 'productChanges' ?
                      ('tariff.changeUpcoming.to' | translate: { beginDate: contract?.contractStartDate | date }) :
                      ('tariff.priceAdjustment.to' | translate: { beginDate: contract?.contractStartDate | date })">
    </div>
  </div>
  <hr class="re-mt-20 re-mb-20 re-border-t re-border-monochrome-medium-light">
  <bdo-single-accordion [shortInfo]="'contract.details.info.expand' | translate" [arrowRight]="true" [attr.data-qa]="'tariffdetails'" [position]="'right'">
    <bdo-contract-details-tariff-info
      [accountId]="accountId"
      [contract]="contract"
      [customerMode]="customerMode"
      [showPriceCap]="showPriceCap"
    ></bdo-contract-details-tariff-info>
  </bdo-single-accordion>
</div>


