<div class="re-flex re-justify-center re-mb-15 re-text-xs">
  <button (click)="sendAnswer.emit('1')" class="re-mr-15" [attr.aria-label]="'satisfactionPoll.agree.stepsAria.1' | translate">
    <svg-icon class="re-w-60 re-h-60" src="assets/icons/smileys/Icon_Smiley_1.svg"></svg-icon>
  </button>
  <button (click)="sendAnswer.emit('2')" class="re-mr-15" [attr.aria-label]="'satisfactionPoll.agree.stepsAria.2' | translate">
    <svg-icon class="re-w-60 re-h-60" src="assets/icons/smileys/Icon_Smiley_2.svg"></svg-icon>
  </button>
  <button (click)="sendAnswer.emit('3')" class="re-mr-15" [attr.aria-label]="'satisfactionPoll.agree.stepsAria.3' | translate">
    <svg-icon class="re-w-60 re-h-60" src="assets/icons/smileys/Icon_Smiley_3.svg"></svg-icon>
  </button>
  <button (click)="sendAnswer.emit('4')" class="re-mr-15" [attr.aria-label]="'satisfactionPoll.agree.stepsAria.4' | translate">
    <svg-icon class="re-w-60 re-h-60" src="assets/icons/smileys/Icon_Smiley_4.svg"></svg-icon>
  </button>
  <button (click)="sendAnswer.emit('5')" [attr.aria-label]="'satisfactionPoll.agree.stepsAria.5' | translate">
    <svg-icon class="re-w-60 re-h-60" src="assets/icons/smileys/Icon_Smiley_5.svg"></svg-icon>
  </button>
</div>
<div class="re-w-full re-flex re-text-xs">
  <div class="re-w-1/2 re-pr-5">{{ 'satisfactionPoll.answer.no' | translate }}, <br>{{questionData?.scaleLow}}</div>
  <div class="re-w-1/2 re-pl-5 re-text-right">{{ 'satisfactionPoll.answer.yes' | translate }}, <br>{{questionData?.scaleHigh}}</div>
</div>
