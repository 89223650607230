import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { LoginService } from '../login/login.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DebugLogger } from '../shared/utils/debugLogger';

/**
 * navigates to dashboard if already logged in (do not show login form)
 */
export const guardLoggedIn: CanActivateFn = (route, state) : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const loginService = inject(LoginService);
  const router = inject(Router);
  return loginService.isLoggedIn().pipe(
    map((loggedIn) => {
      if (loggedIn) {
        DebugLogger.debug('guardLoggedIn: ', 'already logged in -> navigating to dashboard');
        router.navigate(['dashboard']);
        return false;
      } else {
        DebugLogger.debug('guardLoggedIn: ', 'no valid session -> loginComponent can be activated');
        return true;
      }
    })
  );

};
