import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { Utilities } from '../../../../shared/utils/utilities';
import { defaultValidatorProxy } from '../../../../shared/validators/default-validator-proxy';
import { externalEmailValidator } from '../../../../shared/validators/external-email-validator';
import { phonenumberValidator } from '../../../../shared/validators/phonenumber-validator';
import { INPUT_TYPE } from '../../../enums/inputType.enum';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { BdoApiService } from '../../../services/bdo-api.service';
import punycode from 'punycode/';
import {
  Address,
  PartnerEmail,
  PersonalData,
  PersonalDataAddressData,
  SituationData
} from '../../../../../assets/js/com/ts_api_client';
import { Location } from '@angular/common';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { FormFieldIdentifierTracking, TrackingService } from '../../../services/tracking.service';
import { LoginService } from '../../../../login/login.service';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { VERBRAUCHSTYP } from '../../../enums/verbrauchstyp.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { AvailableDivisionInfos } from './product-selection/product-selection.component';
import { AuthDataStorage } from '../../../models/AuthData.storage';
import { MeterProductSelectionService } from '../../../services/meter-product-selection.service';
import { NewHomeFormType } from '../../../../shared/formtypes/new-home.formtype';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-new-home',
  templateUrl: './new-home.component.html',
  styleUrls: ['./new-home.component.scss']
})
export class NewHomeComponent implements AfterViewInit, OnDestroy {
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public Utilities = Utilities;
  public INPUT_TYPE = INPUT_TYPE;
  public searchString$: Observable<string>;
  public cities: Array<string> = [];
  public Verbrauchstyp = VERBRAUCHSTYP;
  public situationData: SituationData;

  public hasEmail: boolean = false;
  public formTracking: FormFieldIdentifierTracking = {
    orca_field_section: TRACKING.FORM.MOVE_NEWHOME_SECTION_ADDRESS,
    orca_form_name: TRACKING.FORM.MOVE_NEWHOME_NAME
  };
  public formSelectionTracking: FormFieldIdentifierTracking = {
    orca_field_section: TRACKING.FORM.MOVE_NEWHOME_SECTION_SELECTION,
    orca_form_name: TRACKING.FORM.MOVE_NEWHOME_NAME
  };
  public newHomeForm = new FormGroup<NewHomeFormType>({
    email: new FormControl('',
      [
        defaultValidatorProxy(Validators.required, this.translateService.instant('address.email.required')),
        defaultValidatorProxy(Validators.email, this.translateService.instant('address.validator.validEmail')),
        externalEmailValidator(this.translateService),
        defaultValidatorProxy(Validators.maxLength(241), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 241 }))
      ]),
    phonenumber: new FormControl('',
      [
        phonenumberValidator(this.translateService),
        defaultValidatorProxy(Validators.maxLength(35), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 35 }))
      ])
  });
  public valid: boolean;

  public availableDivisionInfos: AvailableDivisionInfos;
  public forceValidateDate: boolean = false;
  public initialAddressData: PersonalDataAddressData = null;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private apiService: BdoApiService,
    private loginService: LoginService,
    private trackingService: TrackingService,
    private activatedRoute: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    public router: Router,
    public location: Location,
    private meterProductService: MeterProductSelectionService,
    private translateService: TranslateService
  ) {
    const personalData = StorageService.getValue<PersonalData>(ALLOWED_KEYS.PERSONAL_DATA);
    const personalDataAddress = personalData?.addressData;
    if (personalDataAddress) {
      this.initialAddressData = personalDataAddress;
    }
  }

  ngAfterViewInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.MOVE_NEWHOME, TRACKING.ACTION.ENTER);
    const personalData = StorageService.getValue<PersonalData>(ALLOWED_KEYS.PERSONAL_DATA);
    if (personalData?.email) {
      this.newHomeForm.get('email').setValue(personalData.email);
    }
    if (personalData?.phonenumber) {
      this.newHomeForm.get('phonenumber').setValue(personalData.phonenumber);
    }

    this.checkMail();
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getAddress(): Address {
    return {
      houseNum: this.newHomeForm.get('address')?.get('housenumber').value,
      street: {
        name: this.newHomeForm.get('address')?.get('street').value,
        city: {
          name: this.newHomeForm.get('address')?.get('city').value,
          postCode: this.newHomeForm.get('address')?.get('postCode').value

        }
      }
    };
  }

  save() {
    this.forceValidateDate = true;

    const divisionsSelected = this.newHomeForm.get(['product-selection', VERBRAUCHSTYP.Strom, 'isChecked'])?.value ||
      this.newHomeForm.get(['product-selection', VERBRAUCHSTYP.Gas, 'isChecked'])?.value;

    if ((this.newHomeForm.invalid || !divisionsSelected) && !this.availableDivisionInfos?.allUnavailableOrExternal) {
      // mark all input fields as touched to display validation info
      this.newHomeForm.markAllAsTouched();
      return;
    }
    if (this.newHomeForm.valid) {
      this.situationData = this.meterProductService.mapMeterDataToSituationData();
      StorageService.setValue(ALLOWED_KEYS.SITUATION_DATA, this.situationData);

      this.cleanUpDivisionsOfNextSteps();

      const personalData: PersonalData = {
        addressData: {
          housenumber: this.newHomeForm.get('address').get('housenumber').value,
          street: this.newHomeForm.get('address').get('street').value,
          city: this.newHomeForm.get('address').get('city').value,
          postCode: this.newHomeForm.get('address').get('postCode').value,
        },
        email: punycode.toUnicode(this.newHomeForm.get('email')?.value),
        phonenumber: this.newHomeForm.get('phonenumber')?.value
      };
      StorageService.setValue(ALLOWED_KEYS.PERSONAL_DATA, personalData);
      StorageService.setValue(ALLOWED_KEYS.HAS_EMAIL, this.hasEmail);
      this.trackingService.postSimpleTracking(TRACKING.LOCATION.MOVE_RECENTHOME, TRACKING.ACTION.GOTO,
        !this.availableDivisionInfos.allUnavailableOrExternal ? '../tarifauswahl' : '../zahlungsart'
      );
      this.router.navigate([!this.availableDivisionInfos.allUnavailableOrExternal ? '../tarifauswahl' : '../zahlungsart'], {
        relativeTo: this.activatedRoute
      });
    } else {
      this.newHomeForm.markAllAsTouched();
    }
  }

  onUpdateAvailableDivisions(availableDivisionInfos: AvailableDivisionInfos) {
    this.availableDivisionInfos = availableDivisionInfos;
  }


  private cleanUpDivisionsOfNextSteps() {
    const tariffSelections = StorageService.getTariffSelections();
    let selectedTariffsState = StorageService.getTariffData();
    if (!Array.isArray(selectedTariffsState)) {
      StorageService.clearTariffData();
      selectedTariffsState = [];
    }
    for (const divisionId of [VERBRAUCHSTYP.Strom, VERBRAUCHSTYP.Gas]) {
      const isProvided = this.availableDivisionInfos.availableDivisions.find((item) => item.divisionId)?.areaBelongsToCurrentTenant;
      const checked = this.newHomeForm.get('product-selection')?.get(divisionId)?.get('isChecked').value;
      const moveTariffStateHasDivision = tariffSelections?.find((item) => item.type === divisionId.toString());
      const selectedTariffsStateHasDivision = selectedTariffsState?.find((item) => item.divisionId === divisionId.toString());

      // remove unchecked division(s) from moveTariffState of the tariffAdvisor, if empty afterwords, remove key at all
      if (tariffSelections && moveTariffStateHasDivision && (!checked || !isProvided)) {
        const newMoveTariffDataValue = tariffSelections.filter((item) => item.type !== divisionId.toString());
        if (newMoveTariffDataValue.length) {
          StorageService.setTariffSelections(newMoveTariffDataValue);
        } else {
          StorageService.clearTariffSelections();
        }
      }

      // remove unchecked division(s) from selectedTariffState of the summaryPage, if empty afterwords, remove key at all
      if (selectedTariffsState && selectedTariffsStateHasDivision && (!checked || !isProvided)) {
        const newTariffDataValue = selectedTariffsState.filter((item) => item.divisionId !== divisionId.toString());
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        newTariffDataValue.length ?
          StorageService.setTariffData(newTariffDataValue)
          : StorageService.clearTariffData();
      }
    }
  }


  private checkMail() {
    this.loginService.isLoggedIn$.pipe(
      first(),
      switchMap((loggedIn) => {
        // TODO: Use anonymousMeterdata for anonymous Call once screen is implemented
        const authData = StorageService.getValue<AuthDataStorage>(ALLOWED_KEYS.AUTH_DATA);
        return loggedIn ? this.apiService.getEmail() :
          this.apiService.getEmailAnonymous(authData?.checkIdentificationData?.accountId, authData?.checkIdentificationData?.meterNumberOrRegisterCode);
      })
    ).subscribe(
      {
        next: (result: PartnerEmail) => {
          this.hasEmail = result?.hasEmail;
          if (this.hasEmail) {
            // Remove it from validation
            this.newHomeForm.get('email').setValue(result.email);
            this.newHomeForm.get('email').disable();
          }
        },
        error: () => {
          this.hasEmail = false;
        },
        complete: () => {
          this.cdRef.detectChanges();
        }
      });
  }
}

