import { Component } from '@angular/core';
import { TRACKING } from '../../../enums/trackingParts.enum';

@Component({
  selector: 'bdo-contract-end-recent-home',
  templateUrl: './contract-end-recent-home.component.html',
  styleUrls: ['./contract-end-recent-home.component.scss']
})
export class ContractEndRecentHomeComponent {
  public TRACKING = TRACKING;

}
