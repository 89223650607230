import { Component, OnInit, OnDestroy } from '@angular/core';
import { TenantService } from '../../services/tenant.service';
import { LoginService } from '../../../login/login.service';
import { filter, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { CustomerStoreService } from '../../services/customer-store.service';
import { Utilities } from '../../../shared/utils/utilities';
import { TRACKING } from '../../enums/trackingParts.enum';
import { TrackingService } from '../../services/tracking.service';
import { StorageService } from '../../services/storage.service';
import { OFFER_CONTEXT, OFFER_CONTEXT_SPECIAL } from '../../enums/offer-context';
import { DropdownItem } from '../../models/dropdownItem';
import { LanguageService } from '../../services/language.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'bdo-minimal-header',
  templateUrl: './minimal-header.component.html',
  styleUrls: ['./minimal-header.component.scss']
})
export class MinimalHeaderComponent implements OnInit, OnDestroy {
  public isLoggedIn: boolean = false;
  public loginIconSrc: string = '';
  public loginHidden: boolean = false;
  public languageHidden: boolean = false;
  public showLanguageSelection: boolean = false;
  public availableLanguages: Array<DropdownItem>;
  public selectedLanguage: string;
  public form = new FormGroup({
    selected: new FormControl()
  });
  private subscriptions: Subscription = new Subscription();
  private offerContext: OFFER_CONTEXT | OFFER_CONTEXT_SPECIAL;

  constructor(
    private trackingService: TrackingService,
    private loginService: LoginService,
    private languageService: LanguageService,
    public customerStore: CustomerStoreService,
    public router: Router,
    public tenantService: TenantService
  ) {
    /** Update Icon when receiving an authorized/unauthorized Call */
    this.subscriptions.add(this.loginService.isLoggedIn$.subscribe({ next: valid => {
      this.isLoggedIn = valid;
      this.updateIconSrc();
    } }));
  }

  ngOnInit() {
    this.showLanguageSelection = this.languageService.getLanguageSelectionAvailable();
    this.availableLanguages = this.languageService.availableLanguages.map(item => new DropdownItem(item.file, item.acronym));
    this.selectedLanguage = this.languageService.getSelectedLanguage();
    this.form.get('selected').setValue(this.selectedLanguage);

    this.offerContext = StorageService.getOfferContext();
    /* Updating the header after navigating to hide or show the Login Part again */
    this.subscriptions.add(this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe({ next: () => {
      this.loginHidden = this.getLoginHidden();
      this.languageHidden = this.getLanguageHidden();
    } }));
   }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  // Showing Logout Confirmation or navigating back to Login Page
  onLog() {
    if (this.isLoggedIn) {
      this.trackingService.postTracking(TRACKING.LOCATION.LOGOUT, TRACKING.ACTION.CLICK);
      this.onLogout(true);
    } else {
      this.trackingService.postTracking(TRACKING.LOCATION.LOGIN, TRACKING.ACTION.CLICK);
      this.router.navigate(['/login']);
    }
  }

  /** Logging out everywhere: Deleting Backend-Cache, logging out of IAM and deleting Cookie */
  onLogout(logoutConfirmed: boolean) {
    if (logoutConfirmed) {
      this.isLoggedIn = false;
      this.loginService.logout().subscribe();
      Utilities.deleteCookies();

      this.loginService.loginStatusEvent.emit(false);
      this.customerStore.deleteAccountId();
      this.router.navigate(['/logout']);
    }
  }

  onUserInfo() {
    this.trackingService.postTracking(TRACKING.LOCATION.USERICON, TRACKING.ACTION.CLICK);
  }

  onClickOnLogo() {
    this.trackingService.postTracking(TRACKING.LOCATION.LOGO, TRACKING.ACTION.CLICK);
  }

  updateIconSrc() {
    if (this.isLoggedIn) {
      this.loginIconSrc = 'assets/icons/bdo/user-active.svg';
    } else {
      this.loginIconSrc = 'assets/icons/bdo/user-anonymous.svg';
    }
  }

  /* Login Part in the header shall be hidden for multi step processes like contract start/end/cancel/add/edit/move unless we are already logged in */
  getLoginHidden(): boolean {
    const onlyLoggedInRoutes = ['/umzug', '/vertrag/anpassen', 'anmelden', '/abmelden'];
    const allHiddenRoutes = ['/kuendigen', '/vertrag-abschliessen', '/angebot'];
    const hidden = !this.isLoggedIn && onlyLoggedInRoutes.some(url => this.router?.url.startsWith(url)) ||
      allHiddenRoutes.some(url => this.router?.url.startsWith(url)) || this.offerContext?.includes(OFFER_CONTEXT.UPSELLING);
    return hidden;
  }

  getLanguageHidden(): boolean {
    const hiddenRoutesRegex = [/\/umzug\/\d+\/neu/, /\/vertrag\/\d+\/anpassen/, /\/anmelden\/\d+\/neu/, /\/abmelden\/\d+\/neu/, /kuendigen\/neu/];
    const hiddenRegex = hiddenRoutesRegex.some(regex => regex.test(this.router?.url));

    return hiddenRegex;
  }

  updateLanguage(newLanguage: Event) {
    this.languageService.setLanguage((newLanguage.target as HTMLInputElement).value);
    this.selectedLanguage = this.languageService.getSelectedLanguage();
  }

}
