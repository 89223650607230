import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[bdoRouterLinkDirective]'
})
/**
 * Directive to use Angular Routing in Links from the tenantService
 * Add this to the container with an innerHtml containing a relative Angular Link
 */
export class RouterLinkDirective {

  constructor(private el: ElementRef, private router: Router) {}

  @HostListener('click', ['$event'])
  public onClick(e) {
    // detect closest a tag, if the a-tag contains another div tag that is recognized as the click target
    const element = (e.target.closest('[bdorouterlinkdirective] A'));
    if (element) {
      const href = element.getAttribute('href');
      const [path, fragment] = href?.split('#');
      if (this.isLocalLink(href)) {
        e.preventDefault();
        this.router.navigate([path], { fragment: fragment });
      }
    }
  }

  /** Angular Router Links are relative */
  public isLocalLink(uri: string) {
    return uri?.startsWith('/') || uri?.startsWith('./');
  }
}
