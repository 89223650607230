<div class="bdo-container--single">

  <h3 class="bdo-space--bottom">{{ 'password.renew.title' | translate }}</h3>
  <div class="bdo-space--bottom">
    {{ 'password.renew.description' | translate }}</div>

  <div *ngIf="errorShown" bdoRouterLinkDirective>
    <bdo-info-box>
      <span [innerHTML]="'password.renew.error.default' | translate "></span>
    </bdo-info-box>
  </div>

  <form #passwordForm="ngForm" class="bdo-form-container" (ngSubmit)="send(passwordForm)" autocomplete="off">
    <bdo-loading *ngIf="sendingData"></bdo-loading>
    <fieldset>
      <div class="bdo-form-item-container">
        <bdo-input
          [labelText]="'register.username.title' | translate"
          [placeholder]="'register.username.title' | translate"
          [formControl]="$any(passwordRenewForm.get('username'))"
        ></bdo-input>
      </div>
      <div class="bdo-form-item-container">
        <bdo-input
          [labelText]="'password.renew.code' | translate"
          [placeholder]="'password.renew.code' | translate"
          [formControl]="$any(passwordRenewForm.get('code'))"
          [help]="'password.renew.codeHelp' | translate"
        ></bdo-input>
      </div>
      <div class="bdo-form-item-container">
        <bdo-input
        [labelText]="'register.password.title' | translate"
        [placeholder]="'register.password.title' | translate"
        [inputType]="INPUT_TYPE.PASSWORD"
        [formControl]="$any(passwordRenewForm.get([PASSWORD_GROUP_NAME, 'new-password']))"
        ></bdo-input>
        <bdo-password-strength [passwordNew]="passwordRenewForm.get([PASSWORD_GROUP_NAME, 'new-password']).value"></bdo-password-strength>
      </div>
      <div class="bdo-form-item-container">
        <bdo-input
          [labelText]="'register.password.repeat' | translate"
          [placeholder]="'register.password.repeat' | translate"
          [inputType]="INPUT_TYPE.PASSWORD"
          [formControl]="$any(passwordRenewForm.get([PASSWORD_GROUP_NAME, 'confirmPassword']))"
        ></bdo-input>
      </div>

      <div>
        <button class="bdo-button-primary bdo-button--text re-mb-30" type="submit"
          [disabled]="sendingData">
          {{ 'general.action.save' | translate }}</button>
      </div>
      <div>
        <a class="bdo-nolink" [routerLink]="['/login']" data-qa="cancel">
          <div class="bold"> {{ 'general.action.cancel' | translate }}</div>
        </a>
      </div>
    </fieldset>
  </form>
  </div>


  <bdo-simple-text-popup [isVisible]="passwordChanged" (closePopupEvent)="closeMessage()"
  [title]="'password.renew.success.title' | translate" [icon]="'success'">
    <div class="popup--success">
      {{ 'password.renew.success.description' | translate }}
    </div>
  </bdo-simple-text-popup>
