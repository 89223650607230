import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { TenantService } from '../../../services/tenant.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Utilities } from '../../../../shared/utils/utilities';
import {
  CampaignInfo,
  CancelReasonData,
  CheckIdentificationData,
  ContractDetails,
  ZaehlerIdentifier,
  Zaehlerstand
} from '../../../../../assets/js/com/ts_api_client';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { VERBRAUCHSTYP } from '../../../enums/verbrauchstyp.enum';
import { BdoApiService } from '../../../services/bdo-api.service';
import { CONTEXT_FROM_SUMMARY } from '../../contract-new/contract-new-summary/contract-new-summary.component';
import { Router, ActivatedRoute } from '@angular/router';
import { TrackingService } from '../../../services/tracking.service';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { TRACKING } from '../../../enums/trackingParts.enum';
import moment from 'moment';
import { CAMPAIGN_TILE_TYPE } from '../../dashboard/campaign-tile/campaign-tile.component';
import { CampaignService, CurrentCampaign } from '../../../services/campaign.service';
import { DropdownItem } from '../../../models/dropdownItem';
import { CancelHomeData } from '../../../models/cancelHome';
import { defaultValidatorProxy } from '../../../../shared/validators/default-validator-proxy';
import { NavigationState } from '../../../models/navigationState';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-contract-cancel-date',
  templateUrl: './contract-cancel-date.component.html',
  styleUrls: ['./contract-cancel-date.component.scss']
})
export class ContractCancelDateComponent implements OnInit {
  public Utilities = Utilities;
  public minDate: Date;
  public tariffName: string = '';
  public division: VERBRAUCHSTYP;
  public meter: ZaehlerIdentifier;
  public authData: CheckIdentificationData;
  public form = new FormGroup({
    canceldate: new FormControl<number | Date>(null),
    cancelReasonId: new FormControl<string>(null,
      [ defaultValidatorProxy(Validators.required, this.translateService.instant('contract.cancel.date.selection.reasonRequired'))])
  });
  public CONTEXT_FROM_SUMMARY = CONTEXT_FROM_SUMMARY;
  public context: string;
  public meterreading: Zaehlerstand;
  public LoadingState = LOADING_STATE;
  public state = LOADING_STATE.LOADING;
  public meternumber: string;
  public CampaignTileType = CAMPAIGN_TILE_TYPE;
  public cancelCampaign: CurrentCampaign;
  public cancelReasons: CancelReasonData[];
  public cancelReasonDropdown: DropdownItem[] = [];
  public forceValidate = false;
  public loadingCancelReasonState = LOADING_STATE.IDLE;

  constructor(
    public location: Location,
    private apiService: BdoApiService,
    private router: Router,
    private trackingService: TrackingService,
    private activatedRoute: ActivatedRoute,
    private campaignService: CampaignService,
    private translateService: TranslateService
  ) {
    const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
    this.context = currentState?.context;
   }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_CANCEL_DATE, TRACKING.ACTION.ENTER);
    const contractData: ContractDetails = StorageService.getOldContract();
    const inSevenDays = moment().add(7, 'days').toDate();
    const homeData: CancelHomeData = StorageService.getValue<CancelHomeData>(ALLOWED_KEYS.RECENT_HOME_DATA);
    this.minDate = new Date(homeData?.date ? homeData.date : contractData?.contractEndMinDate > inSevenDays ? contractData?.contractEndMinDate : inSevenDays);
    this.form.get('canceldate').setValue(this.minDate.getTime());
    this.tariffName = contractData?.tariffName;
    this.meternumber = contractData?.meterNumbers?.[0];
    this.division = contractData?.division as VERBRAUCHSTYP;

    this.authData = StorageService.getAuthData()?.checkIdentificationData;
    if (this.authData) {
      this.authData.atDate = new Date(Date.now());
    }
    // create fake campaign for CANCEL
    const campaignInfo: CampaignInfo = {
      bannerId: 'CANCEL',
      accountId: this.authData?.accountId,
    };
    this.cancelCampaign = {
      info: campaignInfo,
      linkToTariff: '/vertrag/' + this.authData?.accountId + '/anpassen/tarifauswahl',
      dismissed: false,
      config: this.campaignService.getCampaignConfig(campaignInfo)
    };

    this.meterreading = homeData?.meterData[0]?.meterNumber === this.meternumber ? homeData?.meterData[0] : null;
    this.form.get('cancelReasonId').setValue(homeData?.cancelReasonId);

    this.apiService.getMetersAnonymously(this.authData?.accountId, this.authData?.meterNumberOrRegisterCode)
    .subscribe({
      next: (result: Array<ZaehlerIdentifier>) => {
        if (result.length > 0) {
          this.meter = result.map((zaehler: ZaehlerIdentifier) => {
            if (!zaehler.inaktiv && !zaehler.isSmartMeter && contractData?.meterNumbers?.[0] === zaehler.meterNumber) {
              return zaehler;
            }
          })?.filter(Boolean)?.[0];
        }
        this.state = LOADING_STATE.IDLE;
      },
      error: (error: unknown) => {
        this.state = LOADING_STATE.ERROR;
    } });

    this.loadingCancelReasonState = LOADING_STATE.LOADING;
    this.apiService.getCancelReasons(this.authData?.accountId, this.authData?.meterNumberOrRegisterCode)
    .subscribe({
      next: (reasons) => {
         this.cancelReasonDropdown = reasons.map(reason => ({
          value: reason.cancelReasonId,
          label: reason.cancelText
        }));
        this.loadingCancelReasonState = LOADING_STATE.IDLE;
      },
      error: () => {
        this.loadingCancelReasonState = LOADING_STATE.ERROR;
      }
    });
  }

  onReadingChange(event: Zaehlerstand) {
    this.meterreading = event;
  }

  onDateChanged(date: any) {
    this.form?.get('canceldate').setValue(date);
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_CANCEL_DATE, TRACKING.ACTION.SET, 'Kündigungstermin');
  }

  onReasonChanged(reasonId: string) {
    this.form.get('cancelReasonId').setValue(reasonId);
  }

  save() {
    if (!this.form.get('cancelReasonId').value) {
      this.forceValidate = true;
      this.form.markAllAsTouched();
      return;
    }
    const homeData: CancelHomeData = {
      addressData: {},
      date: new Date(this.form.get('canceldate').value),
      meterData: this.meterreading ? [this.meterreading] : [],
      meters: [this.meter],
      cancelReasonId: this.form.get('cancelReasonId').value
    };
    StorageService.setValue(ALLOWED_KEYS.RECENT_HOME_DATA, homeData);

    if (this.context === CONTEXT_FROM_SUMMARY) {
      this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_CANCEL_DATE, TRACKING.ACTION.GOTO, 'zusammenfassung');
      this.router.navigate(['../uebersicht'], {
        relativeTo: this.activatedRoute
      });
    } else {
      this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_CANCEL_DATE, TRACKING.ACTION.GOTO, 'rechnungsadresse');
      this.router.navigate(['../rechnungsadresse'], {
        relativeTo: this.activatedRoute
      });
    }

  }

  track(destination: string) {
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_CANCEL_DATE, TRACKING.ACTION.GOTO, destination);
  }

  onClickCampaignButton() {
    this.track('Tarifwechsel-Login');
    this.campaignService.goToCampaign(this.cancelCampaign).subscribe();
  }
}
