import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zaehlwerktyp'
})
/** Adds the Zaehlwerktyp in Brackets to the Value */
export class ZaehlwerktypPipe implements PipeTransform {

  transform(value: any, typ: string): any {
    if (!typ) {
      return value;
    }
    typ = typ.toLowerCase();
    let result = value.toString();
    if (typ && typ.length > 0) {
      if (result.length > 0) {
        result += ' ';
      }
      result += '(' + typ.toUpperCase() + ')';
    }
    return result;
  }

}
