import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { CampaignConfig, CampaignService, CurrentCampaign } from '../../../services/campaign.service';
import { Environment } from '../../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

export enum CAMPAIGN_TILE_TYPE {
  'TILE' = 'Tile',
  'POPUP' = 'Popup'
}

@Component({
  selector: 'bdo-campaign-tile',
  templateUrl: './campaign-tile.component.html',
  styleUrls: ['./campaign-tile.component.scss']
})
export class CampaignTileComponent implements OnChanges, OnDestroy {
  @Input() showDismissCheckbox = false;
  @Input() dismissIsChecked = false;
  @Input() mode: CAMPAIGN_TILE_TYPE = CAMPAIGN_TILE_TYPE.TILE;
  @Input() campaign: CurrentCampaign;
  @Output() clickDismissCheckbox = new EventEmitter<void>();
  @Output() clickCampaignButton = new EventEmitter<void>();

  public CampaignTileType = CAMPAIGN_TILE_TYPE;
  public mainLogoSrc: string = this.translateService.instant('campaign.default.logo');
  public mainLogoAlt: string = this.translateService.instant('campaign.default.logoAlt');
  public textHeadline: string = this.translateService.instant('campaign.default.title');
  public textDescription: string = this.translateService.instant('campaign.default.description');
  private subscription: Subscription = new Subscription();

  constructor(
    public translateService: TranslateService,
    private campaignService: CampaignService
  ) {
    this.subscription.add(this.translateService.onLangChange.subscribe({
      next: () => {
        const config: CampaignConfig = this.campaignService.getCampaignConfig(this.campaign.info);
        this.mainLogoSrc = config.mainLogoSrc;
        this.mainLogoAlt = config.mainLogoAlt;
        this.textHeadline = config.textHeadline;
        this.textDescription = config.textDescription;
      }
    }));
  }

  public onClickDismissCheckbox() {
    this.clickDismissCheckbox.emit();
  }

  public onClickCampaignButton() {
    this.clickCampaignButton.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentCampaign: CurrentCampaign = changes?.campaign?.currentValue;
    if (currentCampaign) {
       this.mainLogoSrc = currentCampaign.config.mainLogoSrc;
       this.mainLogoAlt = currentCampaign.config.mainLogoAlt;
       this.textHeadline = currentCampaign.config.textHeadline;
       this.textDescription = currentCampaign.config.textDescription;
    }

  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }
}
