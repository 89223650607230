import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bdo-circle-icon-text-small',
  templateUrl: './circle-icon-text-small.component.html',
  styleUrls: ['./circle-icon-text-small.component.scss']
})
export class CircleIconTextSmallComponent {
  @Input() label: string;
  @Input() iconStyle: 'plus' | 'minus' = 'plus';

  @Output() clickEvent: EventEmitter<boolean> = new EventEmitter();

  onClickButton(): void {
    this.clickEvent.emit();
  }
}
