import { Component, ViewChild } from '@angular/core';
import { LoginService } from '../../../../../../src/app/login/login.service';
import { TrackingService } from '../../../services/tracking.service';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { TRACKING } from '../../../enums/trackingParts.enum';

@Component({
  selector: 'bdo-feedback-button',
  templateUrl: './feedback-button.component.html',
  styleUrls: ['./feedback-button.component.scss']
})
export class FeedbackButtonComponent {
  @ViewChild('feedbackDialog', { static: true }) feedbackDialog: FeedbackDialogComponent;

  showDialog: boolean = false;
  showDone: boolean = false;

  constructor(public loginService: LoginService, private trackingService: TrackingService) { }

  feedbackClick() {
    this.showDialog = true;
    this.showDone = false;
    this.feedbackDialog.reset();
    this.trackingService.postTracking(TRACKING.LOCATION.FEEDBACK, TRACKING.ACTION.CLICK, TRACKING.ELEMENTS.BUTTON);
  }

  dialogFinished() {
    this.showDialog = false;
    this.showDone = true;
    this.feedbackDialog.reset();
    this.trackingService.postTracking(TRACKING.LOCATION.FEEDBACK, TRACKING.ACTION.SHOW, TRACKING.ELEMENTS.THANKYOU);
  }

  dialogAborted() {
    this.showDialog = false;
    this.showDone = false;
    this.feedbackDialog.reset();
    this.trackingService.postTracking(TRACKING.LOCATION.FEEDBACK, TRACKING.ACTION.CANCEL, TRACKING.ELEMENTS.DIALOG);
  }

  doneFinished() {
    this.showDialog = false;
    this.showDone = false;
    this.feedbackDialog.reset();
    this.trackingService.postTracking(TRACKING.LOCATION.FEEDBACK, TRACKING.ACTION.DONE);
  }

}
