/* eslint-disable rxjs/no-implicit-any-catch */
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Md5 } from 'ts-md5';
import {
  AddressData, Contract,
  ContractAddResponse, ContractPaymentData, PersonalDataAddressData, SituationData, StatusResponse, TariffData
} from '../../../../../assets/js/com/ts_api_client';
import { DELIVERY_TYPE } from '../../../enums/deliveryType';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { LegalData } from '../../../models/legalData';
import { VerbrauchstypPipe } from '../../../pipes/verbrauchstyp.pipe';
import { BdoApiService } from '../../../services/bdo-api.service';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { TariffAdvisorService } from '../../../services/tariff-advisor.service';
import { ContractsTracking, ProductTracking, TrackingService } from '../../../services/tracking.service';
import { PathAndDivision } from '../../delivery/summary/legal-summary/legal-summary.component';
import { SUBMISSION_ERRORS } from '../../delivery/summary/summary.component';
import { DatadogService } from '../../../services/datadog.service';
import { TariffSelection } from '../../../../shared/models/tariff-selection';
import { TranslateService } from '@ngx-translate/core';

export const CONTEXT_FROM_SUMMARY = 'CONTEXT_FROM_SUMMARY';

@Component({
  selector: 'bdo-contract-new-summary',
  templateUrl: './contract-new-summary.component.html',
  styleUrls: ['./contract-new-summary.component.scss']
})
export class ContractNewSummaryComponent implements OnInit {
  public state = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public errorMessage: string = '';
  public addressData: AddressData;
  public selectedTariffs: Array<TariffSelection> = [];
  public termsOfConditionsAccepted: boolean = false;
  public rightOfWithdrawalAccepted: boolean = false;
  public communicationAccepted: boolean | null = null;
  public validateCheckboxes: boolean = false;
  public pathAndDivisions: Array<PathAndDivision> = [];
  private orderId: string;

  constructor(
    public location: Location,
    public translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: BdoApiService,
    private tariffAdvisorService: TariffAdvisorService,
    private dataDogService: DatadogService,
    private verbrauchstypPipe: VerbrauchstypPipe,
    private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_NEW_SUMMARY, TRACKING.ACTION.ENTER);
    const legalData = StorageService.getValue<LegalData>(ALLOWED_KEYS.LEGAL_DATA);
    this.addressData = StorageService.getValue<AddressData>(ALLOWED_KEYS.ADDRESS_DATA);
    const selectedTariffs = StorageService.getTariffSelections();

    if (!legalData) {
      this.dataDogService.trackCustomEvent('contract_new__summary__legalDataMissing');
    }
    if (!this.addressData) {
      this.dataDogService.trackCustomEvent('contract_new__summary__addressDataMissing');
    }
    if (!selectedTariffs) {
      this.dataDogService.trackCustomEvent('contract_new__summary__selectedTariffsDataMissing');
    }
    // Causes problems if data is not an array and is iterated in ngfor
    this.selectedTariffs = Array.isArray(selectedTariffs) ? selectedTariffs : [];

    this.selectedTariffs.forEach((divisionState: TariffSelection) => {
      this.pathAndDivisions.push({
        division: this.verbrauchstypPipe.transform(divisionState?.selectedTariff?.division),
        path: this.tariffAdvisorService.getDocumentLink() + divisionState?.selectedTariff?.layer?.downloads.find(item => item.title.includes('Vertragsbedingungen')).link
      });
    });
  }

  /**
   *
   * @param wizardStep Number of the wizard step to go to
   * @param fragment part of the destination page
   */
   onEdit(wizardStep: number, fragment?: string) {
    switch (wizardStep) {
      case 1:
        this.router.navigate(['../adresse'], {
          relativeTo: this.activatedRoute,
          state: {
            context: CONTEXT_FROM_SUMMARY
          }
        });
        break;
      case 2:
        this.router.navigate(['../energiearten'],  {
          relativeTo: this.activatedRoute,
          state: {
            context: CONTEXT_FROM_SUMMARY
          }
        });
        break;
      case 3:
        this.router.navigate(['../tarifauswahl/' + fragment],  {
          relativeTo: this.activatedRoute,
          state: {
            context: CONTEXT_FROM_SUMMARY
          }
        });
        break;
      case 4:
        this.router.navigate(['../zahlungsart'],  {
          relativeTo: this.activatedRoute,
          state: {
            context: CONTEXT_FROM_SUMMARY
          }
        });
        break;
      default:
        break;
    }
  }

  onChangeTermsOfConditions(accepted: boolean) {
    this.termsOfConditionsAccepted = accepted;
    StorageService.setProperty<LegalData>(ALLOWED_KEYS.LEGAL_DATA, 'termsOfConditionsAccepted', this.termsOfConditionsAccepted);
  }

  onChangeRightOfWithdrawalAccepted(accepted: boolean) {
    this.rightOfWithdrawalAccepted = accepted;
    StorageService.setProperty<LegalData>(ALLOWED_KEYS.LEGAL_DATA, 'rightOfWithdrawalAccepted', this.rightOfWithdrawalAccepted);
  }

  onCommunicationAcceptedChange(accepted: boolean) {
    this.communicationAccepted = accepted;
    StorageService.setProperty<LegalData>(ALLOWED_KEYS.LEGAL_DATA, 'communicationAccepted', this.communicationAccepted);
  }

  submit() {
    this.validateCheckboxes = true;
    if (!this.termsOfConditionsAccepted || !this.rightOfWithdrawalAccepted) {
      return;
    } else {
      this.state = LOADING_STATE.LOADING;
      const personalDataAddress: PersonalDataAddressData = {
        postCode: this.addressData?.postCode,
        city: this.addressData?.cityName,
        street: this.addressData?.streetName,
        housenumber: this.addressData?.houseNum
      };
      const cooperationPartnerId = this.tariffAdvisorService.getCooperationPartnerId(
        this.addressData?.postCode,
        StorageService.getTariffData()?.map(tariff => tariff.productId)
      );

      const contract: Contract = {
        paymentData: StorageService.getValue<ContractPaymentData>(ALLOWED_KEYS.PAYMENT_DATA),
        situationData: { ...StorageService.getValue<SituationData>(ALLOWED_KEYS.SITUATION_DATA),
                        deliveryType: DELIVERY_TYPE.ADD_CONSUMPTION_POINT
                      },
        tariffData: StorageService.getTariffData(),
        personalData: { addressData: personalDataAddress },
        cooperationPartnerId
      };
      if (contract.paymentData?.bankData?.iban) {
        // remove special chars
        contract.paymentData.bankData.iban = contract.paymentData.bankData.iban.replace(/[^a-zA-Z0-9]/g, '');
      }
      this.orderId = this.generateOrderId(contract).toString();
      StorageService.setProperty(ALLOWED_KEYS.TRACKINGPIXEL_DATA, 'orderId', this.orderId);
      // Set orderId for all contracts
      contract.tariffData.forEach(item => item.orderId = this.orderId);
      contract.personalData.emailOptIn = this.communicationAccepted;
      this.apiService.postContractAdd(contract).subscribe({
        error: (error: {
          error: StatusResponse
        } ) => {
          switch (error?.error?.info) {
            case (SUBMISSION_ERRORS.UNKNOWN_ERROR):
            case (SUBMISSION_ERRORS.WRITE_CONTRACT_FAILED):
            case (SUBMISSION_ERRORS.WRITE_CONTRACT_FAILED_ALREADY_APPLIED):
            case (SUBMISSION_ERRORS.WRITE_CONTRACT_FAILED_OTHER_CONTRACT):
              this.errorMessage = this.translateService.instant('summary.error.' + error.error.info);
              this.state = LOADING_STATE.ERROR;
              break;
            default:
              this.state = LOADING_STATE.ERROR;
              this.errorMessage = this.translateService.instant('general.error.error');
          }
          // In case of unexpected error
          if (!error?.error?.info) {
            this.state = LOADING_STATE.ERROR;
            this.errorMessage = this.translateService.instant('general.error.error');
          }
        },
        next: (contractAddResponse: ContractAddResponse) => {
          this.trackContract(contract);
          // store the trustpilot to display the tp-form on the confirmation page
          StorageService.setTrustPilotToken(contractAddResponse.trustpilotSecureToken);
          this.errorMessage = null;
          this.state = LOADING_STATE.IDLE;
          this.router.navigate(['../../danke'], { relativeTo: this.activatedRoute });
        }
      });
    }
  }

  trackContract(contract: Contract) {
    const situationData = StorageService.getValue<SituationData>(ALLOWED_KEYS.SITUATION_DATA);
    const tariffData: TariffData[] = StorageService.getTariffData();
    const tariffSelectionPrices: TariffSelection[] = StorageService.getTariffSelections();
    const tariffTrackings: Array<ProductTracking> = tariffData?.map(tariff => {
      return {
      orca_product_id: tariff?.productId,
      orca_product_name: tariff?.tbTariffName,
      orca_product_optionen: '',
      orca_product_sparte: this.verbrauchstypPipe.transform(tariff?.divisionId, false),
      orca_jahresverbrauch: parseInt(tariff?.annualConsumption, 10),
      orca_preis: tariffSelectionPrices.find(item => item.type === tariff.divisionId)?.monthlyPrice,
      orca_kundenart: tariffData?.[0]?.mode,
      orca_meter_quantity: situationData?.meterData?.filter(item => item.divisionId === tariff.divisionId)?.length
      };
    });
    const tracking: ContractsTracking = {
      event: 'orca-weiterelieferstelle',
      orca_order_id: `orca-${this.orderId}`,
      orca_ordered_products: tariffTrackings,
      orca_gesamtverbrauch: tariffData?.reduce((accumulator, item) => {
        return accumulator + parseFloat(item.annualConsumption);
      }, 0),
      orca_kundenstatus: 'Weitere Lieferstelle',
      orca_kundenart: tariffData?.[0]?.mode,
      orca_zahlungsart: contract.paymentData.bankData?.iban ? 'SEPA Mandat' : 'Überweisung'

    };
    this.trackingService.postBaseAndAdditionalTracking({ ...tracking });

    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_NEW_SUMMARY, TRACKING.ACTION.SUCCESS);

    this.trackingService.postBaseAndAdditionalTracking({
      orca_event_type: TRACKING.LOCATION.CONTRACT_NEW_SUMMARY,
      orca_event_action: 'goto fertig'
    });
  }

  private generateOrderId(contract: Contract) {
    return Md5.hashStr(JSON.stringify(contract));
  }


}
