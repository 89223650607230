/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Organisation data
 */
export interface Organisation { 
    /**
     * part of name of Organisation
     */
    nameOrg1?: string;
    /**
     * part of name of Organisation
     */
    nameOrg2?: string;
    /**
     * part of name of Organisation
     */
    nameOrg3?: string;
    /**
     * part of name of Organisation
     */
    nameOrg4?: string;
    /**
     * GEWE customer \'Branche\'; internal SAP ID of sector
     */
    sectorId?: string;
    /**
     * GEWE customer \'Rechtsform\'; internal SAP ID of legal entity
     */
    legalEntityId?: string;
    /**
     * GEWE customer \'Handelsregisternummer\'
     */
    commercialRegisterNr?: string;
}

