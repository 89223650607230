import { Component } from '@angular/core';
import { TRACKING } from '../../../enums/trackingParts.enum';

@Component({
  selector: 'bdo-contract-new-tariffs',
  templateUrl: './contract-new-tariffs.component.html',
  styleUrls: ['./contract-new-tariffs.component.scss']
})
export class ContractNewTariffsComponent {
  public trackingLocation = TRACKING.LOCATION.CONTRACT_NEW_TARIFFS;

}
