<div class="error" *ngIf="required && !checked">
  {{errorText? errorText : 'Bitte überprüfen Sie Ihre Eingabe.'}}
</div>
<div class="bdo-checkbox-container">
  <label (click)="onSelection()">
    <input type="checkbox" [(ngModel)]="checked" [disabled]="disabled" [ariaLabel]="ariaLabel">
    <span class="re-block label__text">
      <ng-content></ng-content>
    </span>
  </label>
</div>
