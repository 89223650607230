<div class="bdo-table bdo-mb-45">

  <div class="bdo-tile">
    <h5 class="bold re-mb-10">{{ 'consumptionHistory.table.title' | translate }}</h5>
    <div *ngFor="let zaehler of historie">
      {{ 'meterreadings.input.meterId'  | translate }}
      <span class="bold">{{zaehler?.meterNumber}}</span>
      <span *ngIf="zaehler?.ablesungen && zaehler?.sparte === sparteEnum.Strom">
        -
        <span *ngIf="zaehler?.imsys; then showImsys else showDefault"></span>
        <ng-template #showImsys>{{ 'meterreadings.meterTypes.ims' | translate }}</ng-template>
        <ng-template
          #showDefault>{{ ((zaehler?.ablesungen[0]?.staende.length > 1) ?
                          'meterreadings.meterTypes.htNtMeter' : 'meterreadings.meterTypes.oneTariffMeter') | translate }}</ng-template>
      </span>
      <span *ngIf="zaehler?.inaktivSeit"> (inaktiv seit {{zaehler?.inaktivSeit | date}})</span>
    </div>

    <!-- chart/table-toggle -->
    <button *ngIf="imsysContracts?.includes(vertragNummer) && state === LoadingState.IDLE"
            class="toggle re-group re-flex re-cursor-pointer re-h-30 re-my-20 lg:re-absolute lg:re-right-0 lg:re-mr-20 lg:re-top-0"
            (click)="toggleView()"
            [title]="(showTable ?
                      'consumptionHistory.table.showGraphic' : 'consumptionHistory.table.showTable') | translate">
      <span
        class="re-flex-1 re-text-center re-pr-5 re-leading-l">{{ 'consumptionHistory.table.switchGraphic' | translate }}</span>
      <span
        class="re-flex-1 re-left re-bg-primary re-rounded-full re-w-60 re-h-30 re-relative group-hover:re-bg-secondary transition">
      <span
        class="toggle_dot re-rounded-full re-w-24 re-h-24 re-bg-white re-block re-ml-3 re-absolute re-mr-3 re-mt-3 re-top-0"
        [class.re-right-0]="showTable"></span>
    </span>
      <span
        class="re-flex-1 re-text-center re-pl-5 re-leading-l">{{ 'consumptionHistory.table.switchTable' | translate }}</span>
    </button>
  </div>

  <div class="re-relative">
    <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>

    <!-- show errors on all backend errors -->
    <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">
      {{ 'general.error.error' | translate }}
    </bdo-info-box>

    <bdo-zaehlerstand-historie-tabelle
      *ngIf="showTable && state === LoadingState.IDLE && !imsysContracts?.includes(vertragNummer)"
      [ablesungen]="ablesungen"
    ></bdo-zaehlerstand-historie-tabelle>

    <bdo-diagramm
      *ngIf="state === LoadingState.IDLE && imsysContracts?.includes(vertragNummer)"
      [contractId]="vertragNummer"
      [showTable]="showTable"
    ></bdo-diagramm>
  </div>
</div>
