<div class="teaser__container media__container" (click)="showVideo()">
  <div class="picture">
    <img
    [attr.alt]="altImgText ? altImgText : null"
    [src]="imgSrc">
    <div class="play-btn__container">
      <div class="play-btn">
        <bdo-svg name="icon-play-btn" styling="bdo-svg bdo-svg--invers"></bdo-svg>
      </div>
    </div>
    <div class="timer"><span>{{time}}</span></div>
  </div>

</div>

<div class="media__container">
  <h3>{{headline}}</h3>
  <section class="re-copytext-with-margin margin--less">
    <ng-content></ng-content>
  </section>

  <button class="bdo-button-primary" (click)="showVideo()">Film ab!</button>
</div>

<bdo-video-popup [isVisible]="videoOpen" (closePopupEvent)="closeVideo()">
  <div class="video__container">
    <iframe
      [src]="trustedVideoSrc"
      frameborder="0"
      allowfullscreen>
    </iframe>
  </div>
</bdo-video-popup>
