import { Component, Input, OnInit } from '@angular/core';
import { ALLOWED_KEYS, StorageService } from '../../../../services/storage.service';
import { BillingAddressFormData } from '../../../../models/billingAddressFormData';
import { AuthDataStorage } from '../../../../models/AuthData.storage';

@Component({
  selector: 'bdo-billing-address-summary',
  templateUrl: './billing-address-summary.component.html',
  styleUrls: ['./billing-address-summary.component.scss']
})
export class BillingAddressSummaryComponent implements OnInit {

  @Input() showAccountId: boolean = false;
  public billingAddress: BillingAddressFormData;
  public accountId: string = '';

  ngOnInit(): void {
    this.billingAddress = StorageService.getValue(ALLOWED_KEYS.BILLING_ADDRESS);
    this.accountId = StorageService.getValue<AuthDataStorage>(ALLOWED_KEYS.AUTH_DATA)?.checkIdentificationData?.accountId;
  }

}
