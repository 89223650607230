/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CompetitionData { 
    /**
     * Email address
     */
    email?: string;
    /**
     * id for identifying a competition uniquely
     */
    competitionId?: string;
}

