import { Component, Input } from '@angular/core';

@Component({
  selector: 'bdo-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent {
  @Input() name: string;
  @Input() styling: string;

  get absUrl() {
    return window.location.href.split('#')[0];
  }

}
