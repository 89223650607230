"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VariationByExposition = void 0;
var VariationByExposition = /** @class */function () {
  function VariationByExposition(variationByExposition) {
    this.variationId = variationByExposition.variationId;
    this.variationKey = variationByExposition.variationKey;
    this.exposition = variationByExposition.exposition;
  }
  return VariationByExposition;
}();
exports.VariationByExposition = VariationByExposition;