import { Component, OnInit, OnDestroy, HostBinding, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { LoginService } from '../../../login/login.service';

interface Breadcrumb {
  label: string;
  url: string;
}

@Component({
  selector: 'bdo-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
/**
 * Only shown for logged-in services and also hidden on the dashboard.
 * Add the title as "breadcrumb" data in app-routing.module to extend the breadcrumb
 */
export class BreadcrumbComponent implements OnInit, OnDestroy {
 /* eslint @angular-eslint/no-input-rename: "off" */
 @HostBinding('class') @Input('class') classList: string = '';
 public breadcrumbs: Breadcrumb[];
 public isDisabled: boolean = false; // If any of the subroutes wants the breadcrumb disabled
 public isLoggedIn$ = this.loginService.isLoggedIn$;
 private routerEventSub: Subscription;

 constructor(
   private router: Router,
   private activatedRoute: ActivatedRoute,
   private loginService: LoginService
 ) {
   this.breadcrumbs = this.buildBreadcrumb(this.activatedRoute.root);
 }

 ngOnInit() {
   // Needed if breadcrumb component stays on router change
   this.routerEventSub = this.router.events.subscribe({ next: event => {
     if (event instanceof NavigationEnd) {
       this.isDisabled = false;
       this.breadcrumbs = this.buildBreadcrumb(this.activatedRoute.root);
     }
  } });
 }

 ngOnDestroy(): void {
   if (this.routerEventSub) {
     this.routerEventSub.unsubscribe();
   }
 }

 private buildBreadcrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
   // If no routeConfig is avalailable we are on the root path
   const label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.breadcrumb : '';
   let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';
   if (route?.routeConfig?.data?.breadcrumbDisabled) {
     this.isDisabled = true;
   }

   // If the route is dynamic route such as ':id', replace it with value
   const lastRoutePart = path.split('/').pop();
   const isDynamicRoute = lastRoutePart.startsWith(':');
   if (isDynamicRoute && !!route.snapshot) {
     const paramName = lastRoutePart.split(':')[1];
     path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
   }

   // In the routeConfig the complete path is not available,
   // so we rebuild it each time
   const nextUrl = path ? `${url}/${path}` : url;

   const breadcrumb: Breadcrumb = {
       label: label,
       url: nextUrl,
   };
   // Only adding route with non-empty label
   const newBreadcrumbs = breadcrumb.label ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];
   if (route.firstChild) {
       // If we are not on our current path yet,
       // there will be more children to look after, to build our breadcumb
       return this.buildBreadcrumb(route.firstChild, nextUrl, newBreadcrumbs);
   }
   return newBreadcrumbs;
 }

}
