<!-- wrap component with 'bdo-tile' if mode is 'Tile' -->
<div class="bdo-tile" *ngIf="mode === CampaignTileType.TILE; else campaignTemplate">
  <ng-container *ngTemplateOutlet="campaignTemplate"></ng-container>
</div>
<ng-template #campaignTemplate>

  <div class="campaign re-flex re-flex-auto re-flex-col re-min-h-full
              md:re-flex-row-reverse"
              data-qa="campaign"
              [class.popup]="mode === CampaignTileType.POPUP"
   >
    <div class="re-max-h-1/2
                md:re-w-1/2 md:re-min-w-1/2"
    >
      <img class="re-object-cover re-h-full re-w-full"
           [src]="mainLogoSrc"
           [alt]="mainLogoAlt"/>
    </div>
    <div class="re-flex re-flex-auto re-flex-col re-justify-between re-px-20 re-pt-30
                md:re-p-45 md:re-pb-60 md:re-w-1/2 md:re-min-w-1/2">
      <div class="re-max-w-full re-mb-30 re-overflow-hidden">
        <h1 class="re-text-primary re-overflow-hidden re-mb-15
                  md:re-mb-30 md:re-py-5" [innerHTML]="textHeadline">
        </h1>
        <p class="md:re-text-base">
          {{textDescription}}
        </p>
      </div>
      <div class="re-flex re-flex-col re-justify-end re-pb-20
                  md:re-pb-0">
        <button class="re-button re-primary re-px-25 re-text-center
                  md:re-mr-auto"
          data-qa="changeInsteadLink"
          (click)="onClickCampaignButton()">
          {{ 'campaign.button' | translate }}
          </button>
        <div *ngIf="showDismissCheckbox"
             class="re-mb-20 re-mt-15
                    md:re-mb-0 md:re-mt-30">
          <bdo-checkbox
            [checked]="dismissIsChecked"
            (change)="onClickDismissCheckbox()">
            {{ 'campaign.doNotShowAgain' | translate }}
          </bdo-checkbox>
        </div>
      </div>
    </div>
  </div>
</ng-template>

