import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { forwardCustomHttpStatusCodes, RETURN_CODES } from '../../../enums/returnCodes.enum';
import { BdoApiService } from '../../../services/bdo-api.service';
import { ZaehlerIdentifier, Zaehlerstand, Zaehlwerk } from '../../../../../assets/js/com/ts_api_client';
import { ZaehlerstandValidatorDirective } from '../../../../shared/validators/zaehlerstand-validator.directive';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { TrackingService } from '../../../services/tracking.service';
import { SAVE_METER_ERROR } from '../../../enums/saveMeterError.enum';
import { MeterreadingInfoService } from '../../../services/meterreading-info.service';
import { ActivatedRoute, Router } from '@angular/router';
import { defaultValidatorProxy } from '../../../../shared/validators/default-validator-proxy';
import { Location } from '@angular/common';
import { NavigationState } from '../../../models/navigationState';
import { Utilities } from '../../../../shared/utils/utilities';
import { HttpStatusCode } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'bdo-zaehlerstand-eingabe-form',
  templateUrl: './zaehlerstand-eingabe-form.component.html',
  styleUrls: ['./zaehlerstand-eingabe-form.component.scss']
})
export class ZaehlerstandEingabeFormComponent implements OnChanges {
  @Input() submittedMeterNumber: string;
  @Input() zaehleridentifiers: ZaehlerIdentifier[];
  @Input() selectedDate: Date = new Date(Date.now());
  @Output() done: EventEmitter<boolean> = new EventEmitter(); // true if changed, false if done without change
  @Output() saved: EventEmitter<boolean> = new EventEmitter();
  @Output() selectedDateChange: EventEmitter<Date> = new EventEmitter();

  public state: LOADING_STATE = LOADING_STATE.LOADING;
  public LoadingState = LOADING_STATE;

  meterreading: Zaehlerstand;
  selectedZaehler: ZaehlerIdentifier;
  readingExists: boolean = false;
  implausible: boolean = false;
  checked: boolean = false;
  savingDone: boolean = false;
  validateForm: boolean = false;
  validationIconHidden: boolean = false;
  zaehlerValidator: ZaehlerstandValidatorDirective = new ZaehlerstandValidatorDirective(this.translateService);
  public showPoll: boolean = false;
  public submittedContext: string;
  public isFromSiminvoice: boolean = false;
  public accountId: string = '';
  public saveMeterError: SAVE_METER_ERROR;
  public SaveMeterError = SAVE_METER_ERROR;
  public readingForm = new FormGroup({});

  constructor(
    private apiService: BdoApiService,
    private trackingService: TrackingService,
    private location: Location,
    private translateService: TranslateService,
    public toastService: ToastService,
    public meterreadingInfoService: MeterreadingInfoService,
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) {
    const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
    this.submittedContext = currentState?.context;
    this.selectedDate = currentState?.date;
    this.submittedMeterNumber = this.activatedRoute?.snapshot?.queryParamMap?.get('meternumber') || currentState?.meterNumber;
    if (this.submittedContext === 'CONTEXT_FROM_SIMINVOICE_METER_READING') {
      this.isFromSiminvoice = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.submittedMeterNumber?.currentValue) {
      this.state = LOADING_STATE.LOADING;
      this.updateDropdown(this.zaehleridentifiers);
    } else if (changes.selectedDate?.currentValue) {
      this.state = LOADING_STATE.LOADING;
      this.getMeter(this.submittedMeterNumber, changes.selectedDate.currentValue);
    }
  }

  setControls() {
    this.readingForm = new FormGroup({});
    this.meterreading.zaehlwerkeListe.forEach((zaehlwerk: Zaehlwerk) => {
      this.readingForm.addControl('reading-' + zaehlwerk.zaehlwerknummer, new FormControl<string>(zaehlwerk.wert?.toString().replace('.',',')));
      this.readingForm.get('reading-' + zaehlwerk.zaehlwerknummer).setValidators([
        defaultValidatorProxy(Validators.required, this.translateService.instant('meterreadings.input.meterreadingRequired')),
        defaultValidatorProxy(Validators.pattern(/^\d+(,\d+)*$/), this.translateService.instant('meterreadings.input.validNumberRequired')),
        (control) => this.zaehlerValidator.validate(control),
      ]);
    });
  }

  getPlaceholder(zaehlwerkData: Zaehlwerk) {
    const formatWithoutReadingType = this.translateService.instant('meterreadings.meterreading');
    const HT_NT = ['HT', 'NT'].includes(zaehlwerkData.typ) ? zaehlwerkData.typ : '';
    return formatWithoutReadingType + ' ' + (HT_NT ? HT_NT + ' ' : '');
  }

  /** Called OnInit()
   * Takes a list of identifiers and maps it to a list of Dropdown-Items with labels:
   * */
  updateDropdown(meteridentifiers: Array<ZaehlerIdentifier>) {
    if (this.submittedMeterNumber) {
      this.selectedZaehler = meteridentifiers.find((item) => item.meterNumber === this.submittedMeterNumber);
      this.getMeter(this.submittedMeterNumber, this.selectedDate);
    } else if (meteridentifiers.length === 1) {
      this.selectedZaehler = meteridentifiers[0];
      this.getMeter(meteridentifiers[0].meterNumber, this.selectedDate);
    }
  }

  save() {
    this.readingForm.updateValueAndValidity();
    if (this.readingForm.valid) {
      this.trackingService.postTracking(TRACKING.LOCATION.METERREADINGS, TRACKING.ACTION.SAVE);

      this.meterreading.datum = this.selectedDate;
      this.meterreading.geprueft = this.checked;
      this.meterreading.meterNumber = this.selectedZaehler.meterNumber;
      this.state = LOADING_STATE.LOADING;

      this.meterreading.zaehlwerkeListe.forEach((zaehlwerk: Zaehlwerk) => {
        zaehlwerk.wert = parseFloat(this.readingForm?.get('reading-' + zaehlwerk.zaehlwerknummer).value.replaceAll(',','.'));
      });

      (this.readingExists ? this.apiService.updateZaehlerstand : this.apiService.addZaehlerstand)
        .call(this.apiService, this.meterreading)
        .pipe(
          forwardCustomHttpStatusCodes()
        )
        .subscribe(res => {
          const status = res.status;
          this.state = LOADING_STATE.IDLE;
          if (status === RETURN_CODES.PROGNOSISDIFF) {
            this.implausible = true;
            this.trackingService.postTracking(TRACKING.LOCATION.METERREADINGS, TRACKING.ACTION.IMPLAUSIBLE);
          } else if (status === RETURN_CODES.NO_ACTIVE_CONTRACT) {
            this.saveMeterError = SAVE_METER_ERROR.NO_ACTIVE_CONTRACT;
            this.trackingService.postTracking(TRACKING.LOCATION.METERREADINGS, TRACKING.ACTION.ERROR);
          } else if (status === RETURN_CODES.METER_ALREADY_BILLED) {
            this.saveMeterError = SAVE_METER_ERROR.METER_ALREADY_BILLED;
            this.trackingService.postTracking(TRACKING.LOCATION.METERREADINGS, TRACKING.ACTION.ERROR);
          } else if (status === HttpStatusCode.Conflict) {
            this.saveMeterError = SAVE_METER_ERROR.METERREADING_EXISTS;
            this.trackingService.postTracking(TRACKING.LOCATION.METERREADINGS, TRACKING.ACTION.ERROR);
          }
          else {
            // Reset
            this.trackingService.postTracking(TRACKING.LOCATION.METERREADINGS, TRACKING.ACTION.SUCCESS);
            this.readingExists = false;
            this.implausible = false;
            this.checked = false;
            this.toastService.pushToast({
              headline: 'Zählerstand erfolgreich übermittelt'
            });
            this.saved.emit(true);
            this.showPoll = Utilities.getCookie('poll_shown') === undefined || Utilities.getCookie('poll_shown') === '0';
            this.validateForm = false;
            this.updateMeternumber(this.selectedZaehler.meterNumber); // Reload History
          }
        }, error => {
          this.state = LOADING_STATE.ERROR;
          this.saveMeterError = SAVE_METER_ERROR.DEFAULT;
          this.validationIconHidden = true;
          this.trackingService.postTracking(TRACKING.LOCATION.METERREADINGS, TRACKING.ACTION.FAILURE);
        });

    } else {
      this.validateForm = true;
    }
  }

  resetData(resetMeterComplete: boolean) {
    if (resetMeterComplete) {
      this.readingExists = false;
    }
    this.implausible = false;
    this.checked = false;
  }


  getMeter(meternumber: string, date: Date = (new Date(Date.now()))) {

    // Prevent making multiple server calls without changed params
    if (moment(date).isSame(this.meterreading?.datum, 'day') && meternumber === this.meterreading?.meterNumber) {
      return;
    }

    this.resetData(true);

    this.state = LOADING_STATE.LOADING;

    this.apiService.getZaehlerstand(meternumber, date)
    .subscribe(
      {
        next: (res) => {
          this.state = LOADING_STATE.IDLE;

          this.meterreading = res;
          if (!this.meterreading.zaehlwerkeListe || this.meterreading.zaehlwerkeListe[0].wert != null ) {
            this.readingExists = true;
          }
          this.zaehlerValidator.sparte = this.meterreading.sparte;
          this.setControls();
        },
        error: () => {
          this.state = LOADING_STATE.ERROR;
        }
      });
  }

  onDateSelected(event: Date) {
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.METERREADINGS_ADD, TRACKING.ACTION.SET + ' Datum');
    this.selectedDate = event;
    this.getMeter(this.selectedZaehler?.meterNumber, this.selectedDate);
  }

  updateMeternumber(selected: string) {
    this.selectedZaehler = this.zaehleridentifiers.find(item => item.meterNumber === selected);
    if (this.selectedZaehler) {
      this.trackingService.postSimpleTracking(TRACKING.LOCATION.METERREADINGS, TRACKING.ACTION.SET, TRACKING.ELEMENTS.METERNUMBER );
      this.getMeter(this.selectedZaehler.meterNumber, this.selectedDate);
    }
  }

  closeMessage() {
    this.done.emit(this.savingDone);
    this.savingDone = false;
  }

  onMeterNumberSelected(selected: string) {
    this.updateMeternumber(selected);
  }
}
