import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BdoApiService } from '../../bdo/services/bdo-api.service';
import { ACTIVATION_STATE } from '../../bdo/enums/activationState.enum';
import { LOGIN_ERROR } from '../../bdo/enums/loginError.enum';
import { TrackingService } from '../../bdo/services/tracking.service';
import { LoginService } from '../login.service';
import { CustomerStoreService } from '../../bdo/services/customer-store.service';
import { TRACKING } from '../../bdo/enums/trackingParts.enum';
import { DebugLogger } from '../../shared/utils/debugLogger';
import { Credentials } from '../login-form/login-form.component';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { SignInOutput } from 'aws-amplify/auth';

@Component({
  selector: 'bdo-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  @Input() destination: string;
  @Input() title: string = '';
  loginError: string = null;
  LoginError = LOGIN_ERROR;
  activationState = ACTIVATION_STATE.NONE;
  ActivationStateEnum = ACTIVATION_STATE;
  index: number;
  tosShown: boolean = false;
  sendingData: boolean = false;
  recognizedUsername: string;
  public TRACKING = TRACKING;
  public isFetching: boolean;

  constructor(
    private router: Router,
    public activatedRoute: ActivatedRoute,
    public apiService: BdoApiService,
    private loginService: LoginService,
    public trackingService: TrackingService,
    private customerStore: CustomerStoreService,
    private location: Location,
    private translateService: TranslateService
  ) {
    // Show Message, if tried to register account
    const currentNavigation = this.router.getCurrentNavigation()?.extras?.state;
    const usernameFromUrl = this.activatedRoute.snapshot.queryParamMap.get('username');

    /* Prefill username when coming from username forgot or per direct link with username */
    if (currentNavigation || usernameFromUrl) {
      if (currentNavigation?.username || usernameFromUrl) {
        this.recognizedUsername = currentNavigation?.username || usernameFromUrl;
        this.trackingService.postSimpleTracking(TRACKING.LOCATION.LOGIN, TRACKING.ACTION.ENTER + 'WithUsername');
      } else {
        this.activationState = currentNavigation.activate;
        this.trackingService.postSimpleTracking(TRACKING.LOCATION.LOGIN, TRACKING.ACTION.ENTER + 'WithActivation.' + this.activationState);
      }
    } else {
      this.trackingService.postTracking(TRACKING.LOCATION.LOGIN, TRACKING.ACTION.ENTER);
    }
  }

  onHideError() {
    this.loginError = null;
    this.activationState = ACTIVATION_STATE.NONE;
  }

  handleToS() {
    this.sendingData = true;
    this.apiService.getToS()
      .subscribe( { next: res => {
        this.sendingData = false;
        if (res.confirmed) { // already agreed to ToS, go ahead
          this.loginService.tosPending = false;
          this.loginService.tosAccepted$.next(true);
          this.goToDashboardOrDeepLink();

          this.tosShown = true;

        } else { // need to agree to ToS
          this.tosShown = true;
        }
      }, error: (error: unknown) => {
        this.loginService.tosPending = false;
        this.trackingService.postSimpleTracking('ToS.GET', 'failed');
        this.goToDashboardOrDeepLink();
      } });
  }

  /**
   * called everytime the user loggs in
   * @param accepted
   */
  onTosClosed(accepted: boolean) {
    this.tosShown = false;
    this.loginService.tosPending = false;
    if (accepted) {
      this.apiService.postToS().subscribe({ next: () => this.loginService.tosAccepted$.next(true) });
      this.goToDashboardOrDeepLink();
    } else {
      // Force Logout, show form
      this.trackingService.postSimpleTracking(TRACKING.LOCATION.TERMSOFSERVICE_CONFIRMATION, 'not accepted');
      this.loginService.logout().subscribe();
    }
  }

  public onSubmitLogin(credentials: Credentials, errorBoxContainer: HTMLElement) {
    this.isFetching = true;
    this.loginService.tosPending = true;
    this.loginService.login(credentials, this.destination).subscribe({
      next: (result: SignInOutput) => {
        if (result.nextStep.signInStep === 'CONFIRM_SIGN_UP') {
          this.loginError = this.translateService.instant('login.error.userNotConfirmed');
          this.trackingService.postTracking(TRACKING.LOCATION.LOGIN, TRACKING.ACTION.FAILURE);
          errorBoxContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
          this.isFetching = false;
        } else {
          this.loginError = null;
          this.trackingService.postTracking(TRACKING.LOCATION.LOGIN, TRACKING.ACTION.SUCCESS);
          this.handleToS();
          this.isFetching = false;
        }
      },
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      error: (err) => {
        const error: string = err.toString();

        if (error.startsWith(LOGIN_ERROR.NOT_AUTHORIZED)) {
          this.loginError = this.translateService.instant('login.error.wrongUsernameOrPassword');
        } else if (error.startsWith(LOGIN_ERROR.PASSWORD_ATTEMTPS_EXCEEDED)) {
          this.loginError = this.translateService.instant('login.error.passwordAttemptsExceeded');
        } else if (error.startsWith(LOGIN_ERROR.USER_NOT_CONFIRMED)) {
          this.loginError = this.translateService.instant('login.error.userNotConfirmed');
        } else {
          this.loginError = this.translateService.instant('general.error.error');
        }
        this.trackingService.postTracking(TRACKING.LOCATION.LOGIN, TRACKING.ACTION.FAILURE);
        errorBoxContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
        this.isFetching = false;
      }
    });
  }

  private goToDashboardOrDeepLink() {
    const deepLink: string = this.customerStore.getDeepLink();
    this.sendingData = false; // remove loading animation
    if (this.destination) {
      this.router.navigate([this.destination]);
    } else if (deepLink && deepLink.length > 0) {
      DebugLogger.debug(this.constructor.name, 'goToDashboardOrDeepLink', 'navigate to deeplink', deepLink);
      this.trackingService.postSimpleTracking(TRACKING.LOCATION.LOGIN, TRACKING.ACTION.GOTO + ' deepLink ' + deepLink.split('/')[1]);
      this.customerStore.setDeepLink('');
      this.router.navigateByUrl(deepLink);
    } else {
      DebugLogger.debug(this.constructor.name, 'goToDashboardOrDeepLink', 'navigate to dashboard', deepLink);
      this.trackingService.postTracking(TRACKING.LOCATION.LOGIN, TRACKING.ACTION.GOTO, TRACKING.LOCATION.DASHBOARD);
      this.router.navigate(['/dashboard']);
    }
  }
}
