import { Component, Input } from '@angular/core';
import { LinkData } from '../../../bdo/models/LinkData';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-trustpilot',
  templateUrl: './trustpilot.component.html'
})
export class TrustpilotComponent {
  @Input() set trustpilotUniqueUrl(value: string) {
    this.linkData = [{
      linkName: this.translateService.instant('confirmation.rating.link'),
      href: value,
      tracking: ['trustpilot']
    }];
  }
  public linkData: LinkData[];

  constructor(
    private translateService: TranslateService
  ) { }
}
