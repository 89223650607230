<div class="re-bg-white re-border-l-5 re-border-error">
    <div class="re-pl-15 re-pr-20 re-py-15 md:re-pl-25 md:re-pr-30">
      <div class="re-flex re-flex-row re-items-center re-relative re--mt-5 re-mb-20 md:re-mb-15 lg:re-mt-0">
      <span>
        <h5 class="re-text-base md:h5">{{ 'tariff.priceCap.titleCalculation' | translate }}</h5>
      </span>
      <span class="re-ml-10 re-items-center" *ngIf="priceCapInfo">
        <bdo-inline-tooltip>
          <span [innerHTML]="priceCapInfo"></span>
        </bdo-inline-tooltip>
      </span>
    </div>

    <div class="re-tariff-row">
      <div [innerHTML]="'tariff.priceCap.workingPrice' | translate"></div>
      <div>{{ (isGewe ? tariffDataParts?.priceCap?.workNet : tariffDataParts?.priceCap?.workGross) | currency: 'Cent' }}</div>
    </div>

    <div class="re-tariff-row re-text-success">
      <div>{{ 'tariff.priceCap.totalSaving' | translate }}</div>
      <div>{{ (isGewe ? tariffDataParts?.priceCap?.totalSavingsNet : tariffDataParts?.priceCap?.totalSavingsGross) | currency: 'EUR' }}</div>
    </div>

    <hr class="re-my-15 re-border-t re-border-monochrome-medium-light">

    <div class="re-tariff-row re-mb-5">
      <div>{{ (hasBonus ? 'tariff.priceCap.annualCostsBonus' :
                         'tariff.priceCap.annualCosts') | translate }}</div>
      <div class="bold">{{ (isGewe ? tariffDataParts?.priceCap?.annualPriceNet : tariffDataParts?.priceCap?.annualPriceGross) | currency: 'EUR' }}</div>
    </div>

    <div class="re-tariff-row re-mb-0">
      <div class="bold h5">{{ (hasBonus ? 'tariff.priceCap.monthlyCostsBonus' :
                                         'tariff.priceCap.monthlyCosts') | translate }}</div>
      <div class="bold h4">{{ (isGewe ? tariffDataParts?.priceCap?.monthlyPriceFirstYearNet : tariffDataParts?.priceCap?.monthlyPriceFirstYearGross) | currency: 'EUR' }}</div>
    </div>
  </div>
</div>
