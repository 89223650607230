import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OptInPostData } from '../../../../../assets/js/com/ts_api_client';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { BdoApiService } from '../../../services/bdo-api.service';
import { TenantService } from '../../../services/tenant.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../services/toast.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { TrackingService } from '../../../services/tracking.service';

@Component({
  selector: 'bdo-personal-data-optin',
  templateUrl: './personal-data-optin.component.html',
  styleUrls: ['./personal-data-optin.component.scss']
})
export class PersonalDataOptinComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() accountId: string = '';
  @Input() isInEditMode: boolean = false;
  @Input() optInEditTriggeredByPopup: boolean = false;
  @Output() edit: EventEmitter<boolean> = new EventEmitter();

  public checkedOptin: boolean = false;
  public savedOptIn: boolean = false;
  public LoadingState = LOADING_STATE;
  public state = LOADING_STATE.IDLE;

  constructor(
    public tenantService: TenantService,
    private apiService: BdoApiService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.state = LOADING_STATE.LOADING;
    this.apiService.getOptin().subscribe({
      next: (res) => {
        this.savedOptIn = res.optInFlag;
        this.checkedOptin = res.optInFlag;
        this.state = LOADING_STATE.IDLE;
      },
      error: () => {
        this.state = LOADING_STATE.ERROR;
      }
    });
  }

  onEdit() {
    this.isInEditMode = !this.isInEditMode;
    this.edit.emit(this.isInEditMode);
    this.state = LOADING_STATE.IDLE;
  }

  onSave() {
    this.state = LOADING_STATE.LOADING;
    const data: OptInPostData = { optInFlag: this.checkedOptin };
    this.apiService.postOptin(data, this.accountId).subscribe({
      next: () => {
        this.savedOptIn = this.checkedOptin;
        this.isInEditMode = false;
        if (data.optInFlag === true) {
          const action = this.optInEditTriggeredByPopup ? TRACKING.LOCATION.PERSONAL_DATA_OPT_IN_FROM_POPUP : TRACKING.LOCATION.PERSONAL_DATA_OPT_IN;
          this.trackingService.postTracking(action, TRACKING.ACTION.SAVE);
        }
        this.state = LOADING_STATE.IDLE;
        this.toastService.pushToast({
          headline: this.translateService.instant('personaldata.optin.success')
        });
        this.edit.emit(false);
      },
      error: () => {
        this.state = LOADING_STATE.ERROR;
      }
    });
  }

  public onEditDone() {
    this.state = LOADING_STATE.IDLE;
    this.checkedOptin = this.savedOptIn;
    this.isInEditMode = false;
    this.edit.emit(false);
  }

}
