import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import moment from 'moment';
import { CustomHeaderComponent } from './custom-header/custom-header.component';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'bdo-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatePickerComponent implements OnChanges, OnInit {
  @Input() minDateForChanges: Date;
  // maxDate defaults to today. To allow future date you can set this to 8640000000000000 which is the maximum possible value
  @Input() maxDateForChanges: Date = new Date(Date.now());
  // selectedDate should be empty at initialization
  @Input() noPreselection: boolean;
  @Input() labelText: string = '';
  @Input() requiredErrorText: string;
  @Input() forceValidate: boolean = false;

  @Input() tooltipName: string;
  @Input() tooltipContent: string;
  @Input() selectedDate: Date;
  @Input() required: boolean = true;

  @Output() dateSelected: EventEmitter<Date> = new EventEmitter();
  defaultDate: Date = new Date(Date.now());
  maxDateValue: Date;
  customHeader = CustomHeaderComponent;
  public focused: boolean = false;
  public datepickerOpen: boolean = false;
  public form = new FormGroup({
    selectedDate: new FormControl(new Date())
  });


  constructor() { }

  ngOnInit(): void {
    this.preventDiscrepancyOfInnerAndOuterDate();
    this.emitSelectedDate(this.selectedDate);
  }


  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    this.preventDiscrepancyOfInnerAndOuterDate();
    this.minDateForChanges = new Date(this.minDateForChanges);
    this.maxDateForChanges = new Date(this.maxDateForChanges);

    // Should never be the case
    if (this.maxDateForChanges < this.minDateForChanges) {
      return;
    }
    if (moment(this.selectedDate).isAfter(this.maxDateForChanges, 'day')) {
      this.selectedDate = this.maxDateForChanges;
      this.emitSelectedDate(this.selectedDate);
    } else if (moment(this.minDateForChanges).isAfter(this.selectedDate, 'day')) {
      this.selectedDate = this.minDateForChanges;
      this.emitSelectedDate(this.selectedDate);
    }
  }

  onDateChange($event: MatDatepickerInputEvent<any, any>) {
    this.selectedDate = ($event?.target?.value as moment.Moment)?.toDate();
    return this.emitSelectedDate(($event?.target?.value as moment.Moment)?.toDate());
  }

  emitSelectedDate(event: Date) {
    // Adding 12 hours to prevent wrong Date Conversions at midnight
    if (event && event instanceof Date) {
      this.dateSelected.emit(moment(event.getTime()).set({ hour: 12 }).toDate());
    } else {
      this.dateSelected.emit(null);
    }
  }

  private preventDiscrepancyOfInnerAndOuterDate() {
    if (!this.selectedDate) {
      this.selectedDate = this.noPreselection ? null : new Date(this.defaultDate);
    } else {
      this.selectedDate = new Date(this.selectedDate);
    }
    this.form.get('selectedDate').setValue(this.selectedDate);
  }
}
