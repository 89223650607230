import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectButtonConfiguration } from '../select-button/select-button-configuration.model';


/**
 * This components groups SelectButtons and controlls the allows to activate the next or last button in group and mark
 * buttons as completed
 */
@Component({
  selector: 'bdo-select-button-group',
  templateUrl: './select-button-group.component.html',
  styleUrls: ['./select-button-group.component.scss']
})
export class SelectButtonGroupComponent {

  @Input() selectButtons: SelectButtonConfiguration[];

  @Input() clickable: boolean;

  @Output() activate = new EventEmitter<SelectButtonConfiguration>();

  /**
   * callback, if one button of the group gets clicked
   * @param selectButton
   */
  onActivate(selectButton: SelectButtonConfiguration) {
    this.selectButtons.map((item) => {
      if (item.id !== selectButton.id) {
        item.active = false;
      }
    } );

    const activatedSelectButton = this.selectButtons.find((item) => selectButton.id === item.id);
    activatedSelectButton.active = true;
    this.activate.emit(activatedSelectButton);
  }

  /**
   * activate the next available button
   */
  public activateNext(): boolean {
    const activatedSelectButton = this.selectButtons.find((item) => item.active);
    const index = this.selectButtons.indexOf(activatedSelectButton);
    if (index + 1 < this.selectButtons.length) {
      this.onActivate(this.selectButtons[index + 1]);
      return true;
    }
    return false;
  }

  /**
   * activate the button before the current activated button
   */
  public activateBefore(): boolean {
    const activatedSelectButton = this.selectButtons.find((item) => item.active);
    const index = this.selectButtons.indexOf(activatedSelectButton);
    if (index - 1 >= 0) {
      this.onActivate(this.selectButtons[index - 1]);
      return true;
    }
    return false;
  }

  /**
   * mark the id with the given ID as completed
   * @param id
   */
  public completeStep(id: string) {
    const selectButton = this.selectButtons.find((item) => item.id === id);
    selectButton.completed = true;
  }
}
