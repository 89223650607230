import { Component, OnInit } from '@angular/core';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import {
  ContractChangeResponse,
  ContractDetails,
  PartnerEmail,
  TariffChangeRequest
} from '../../../../../assets/js/com/ts_api_client';
import { Router, ActivatedRoute } from '@angular/router';
import { VerbrauchstypPipe } from '../../../pipes/verbrauchstyp.pipe';
import { LegalData } from '../../../models/legalData';
import { Location } from '@angular/common';
import { CustomerStoreService } from '../../../services/customer-store.service';
import { BdoApiService } from '../../../services/bdo-api.service';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { PathAndDivision } from '../../delivery/summary/legal-summary/legal-summary.component';
import { CUSTOMERMODE } from '../../../enums/customerMode';
import { ContractsTracking, ProductTracking, TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { Observable } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import punycode from 'punycode/';
import { DatadogService } from '../../../services/datadog.service';
import { TariffAdvisorService } from '../../../services/tariff-advisor.service';
import { LoginService } from '../../../../login/login.service';
import { AuthDataStorage } from '../../../models/AuthData.storage';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { defaultValidatorProxy } from '../../../../shared/validators/default-validator-proxy';
import { externalEmailValidator } from '../../../../shared/validators/external-email-validator';
import { INPUT_TYPE } from '../../../enums/inputType.enum';
import { TariffSelection } from '../../../../shared/models/tariff-selection';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-contract-edit-summary',
  templateUrl: './contract-edit-summary.component.html',
  styleUrls: ['./contract-edit-summary.component.scss']
})
export class ContractEditSummaryComponent implements OnInit {

  public oldContract: ContractDetails;
  public emailAddress$: Observable<PartnerEmail>;
  public tariff: TariffSelection;
  public INPUT_TYPE = INPUT_TYPE;
  public validateCheckboxes: boolean = false;
  public pathAndDivisions: Array<PathAndDivision> = [];
  public termsOfConditionsAccepted: boolean = false;
  public rightOfWithdrawalAccepted: boolean = false;
  public communicationAccepted: boolean | null = null;
  public LoadingState = LOADING_STATE;
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public errorMessage: string = null;
  public isGewe: boolean = false;
  public campaignContext: boolean = false;
  public emailAddress: string;
  public hasEmailInOffer: boolean;
  public form = new FormGroup({});
  public hasVouchers: boolean = false;

  constructor(
    public translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataDogService: DatadogService,
    private customerStore: CustomerStoreService,
    private apiService: BdoApiService,
    public location: Location,
    private trackingService: TrackingService,
    private route: ActivatedRoute,
    private verbrauchstypPipe: VerbrauchstypPipe,
    private tariffAdvisorService: TariffAdvisorService,
    private loginService: LoginService,
  ) {
    const authData: AuthDataStorage = StorageService.getAuthData();
    this.emailAddress$ = apiService.getEmailNormalized(authData).pipe(tap((result) => {
      if (!result.hasEmail){
        this.addEmailControl();
      }
    }));
  }

  ngOnInit(): void {
    this.hasEmailInOffer = !!StorageService.getPersonalData()?.email;
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_CHANGE_SUMMARY, TRACKING.ACTION.ENTER);
    this.oldContract = StorageService.getOldContract();
    this.tariff = StorageService.getFirstTariffSelection();
    this.isGewe = this.tariff?.selectedTariff?.customerType === CUSTOMERMODE.GEWE;
    this.hasVouchers = StorageService.getOfferVouchers()?.list?.length > 0;

    if (!this.oldContract) {
      this.dataDogService.trackCustomEvent('contract_edit__summary__oldContractDataMissing');
    }
    if (!this.tariff) {
      this.dataDogService.trackCustomEvent('contract_new__summary__tariffDataMissing');
    }
    this.campaignContext = !!this.route?.snapshot?.queryParams['bannerId'];

    this.pathAndDivisions.push({
      division: this.verbrauchstypPipe.transform(this.tariff?.selectedTariff?.division),
      path: this.tariffAdvisorService.getDocumentLink() + this.tariff?.selectedTariff?.layer?.downloads.find(item => item.title?.includes('Vertragsbedingungen')).link
    });
  }

  onEdit(wizardStep: number, fragment: string) {
    this.router.navigate(['../' + fragment], {
      relativeTo: this.activatedRoute,
    });
  }

  onChangeTermsOfConditions(accepted: boolean) {
    this.termsOfConditionsAccepted = accepted;
    StorageService.setProperty<LegalData>(ALLOWED_KEYS.LEGAL_DATA, 'termsOfConditionsAccepted', this.termsOfConditionsAccepted);
  }

  onChangeRightOfWithdrawalAccepted(accepted: boolean) {
    this.rightOfWithdrawalAccepted = accepted;
    StorageService.setProperty<LegalData>(ALLOWED_KEYS.LEGAL_DATA, 'rightOfWithdrawalAccepted', this.rightOfWithdrawalAccepted);
  }

  onChangeCommunicationAccepted(accepted: boolean) {
    this.communicationAccepted = accepted;
    StorageService.setProperty<LegalData>(ALLOWED_KEYS.LEGAL_DATA, 'communicationAccepted', this.communicationAccepted);
  }

  submit() {
    this.validateCheckboxes = true;
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();
    if (this.termsOfConditionsAccepted && this.rightOfWithdrawalAccepted && this.form.valid) {
      const tariffChange: TariffChangeRequest = {
        accountId: this.customerStore.getAccountId(),
        meterNumber: StorageService.getAuthData()?.checkIdentificationData?.meterNumberOrRegisterCode,
        emailOptIn: this.communicationAccepted,
        contractStartDate: new Date(this.tariff.contractStartDate).toISOString(),
        cooperationPartnerId: this.tariffAdvisorService.getCooperationPartnerId(
          StorageService.getPersonalData()?.addressData?.postCode,
          [this.tariff.selectedTariff.productId]
        ),
        acceptedOfferId: StorageService.getOfferId(),
        contractId: this.oldContract.contractId,
        tbProductsToChange: [{
          divisionId: this.tariff.selectedTariff.division,
          productId: this.tariff.selectedTariff.productId,
          tbTariffName: this.tariff.selectedTariff.name,
          noMindlfzKdg: '',
          consumption: this.tariff.consumption
        }]
      };
      if (this.form?.get('email')){
        tariffChange.email = punycode.toUnicode(this.form.get('email').value);
      } else {
        tariffChange.email = null;
      }

      this.state = LOADING_STATE.LOADING;

      this.loginService.isLoggedIn$.pipe(
        first(),
        switchMap((isLoggedIn) => {
          return isLoggedIn ? this.apiService.postContractChange(tariffChange) :
                              this.apiService.postContractChangeAnonymous(tariffChange);
        })
      ).subscribe(
          {
            next: (result: ContractChangeResponse) => {
              this.state = LOADING_STATE.IDLE;
              this.errorMessage = null;
              StorageService.setTrustPilotToken(result.trustpilotSecureToken);
              this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_CHANGE_SUMMARY, TRACKING.ACTION.SUCCESS);
              this.trackChange(tariffChange);
              this.router.navigate(['../../../danke'], { relativeTo: this.activatedRoute });
            },
            error: () => {
              this.state = LOADING_STATE.ERROR;
              this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_CHANGE_SUMMARY, TRACKING.ACTION.FAILURE);
              this.errorMessage = this.translateService.instant('general.error.error');
            }
          });
    }
  }

  private addEmailControl() {
    const emailControl = new FormControl<string>('',
      [
        defaultValidatorProxy(Validators.email, this.translateService.instant('address.validator.validEmail')),
        defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
        externalEmailValidator(this.translateService),
        defaultValidatorProxy(Validators.maxLength(241), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 241 }))
      ]);

    this.form.addControl('email', emailControl);
  }

  private trackChange(contract: TariffChangeRequest) {
    const tariffData = StorageService.getTariffData();
    const tariffSelectionPrices = StorageService.getTariffSelections();
    const campaignId = this.activatedRoute?.snapshot?.queryParams['bannerId'];
    const tariffTrackings: Array<ProductTracking> = contract?.tbProductsToChange?.map(tariff => {
      return {
      orca_product_id: tariff?.productId,
      orca_product_name: tariff?.tbTariffName,
      orca_product_optionen: '',
      orca_product_sparte: this.verbrauchstypPipe.transform(tariff?.divisionId, false),
      orca_jahresverbrauch: tariff?.consumption,
      orca_preis: tariffSelectionPrices.find(item => item.type === tariff.divisionId)?.monthlyPrice,
      orca_kundenart: tariffData?.[0]?.mode || tariffSelectionPrices?.[0]?.selectedTariff?.customerType, // default tariff change only has selectedTariff - not tariffData
      };
    });
    const isOffer = !!StorageService.getOfferId();
    const tracking: ContractsTracking = {
      event: 'orca-tarifwechsel' + (isOffer ? '-dta' : ''),
      orca_order_id: '', // we do not pass an orderId to SAP in contract_change
      orca_ordered_products: tariffTrackings,
      orca_gesamtverbrauch: tariffSelectionPrices?.reduce((accumulator, item) => {
        return accumulator + item.consumption;
      }, 0),
      orca_kundenstatus: 'Bestandskunde Vertragswechsel',
      orca_kundenart: tariffData?.[0]?.mode || tariffSelectionPrices?.[0]?.selectedTariff?.customerType, // default tariff change only has selectedTariff - not tariffData
      orca_zahlungsart: '', // Is kept in tariff change, therefore not set
      orca_campaign: isOffer ? ('dta/' + StorageService.getOfferContext()) :
                    (!!campaignId ? 'os/' + campaignId : '')
    };
    this.trackingService.postBaseAndAdditionalTracking({ ...tracking });

    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_CHANGE_SUMMARY, TRACKING.ACTION.SUCCESS);

    this.trackingService.postBaseAndAdditionalTracking({
      orca_event_type: TRACKING.LOCATION.CONTRACT_CHANGE_SUMMARY,
      orca_event_action: 'goto fertig'
    });
  }
}
