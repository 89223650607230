import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { Address, MetersCheckResult, SituationData, ZaehlerIdentifier } from '../../../../../../assets/js/com/ts_api_client';
import { VERBRAUCHSTYP } from '../../../../enums/verbrauchstyp.enum';
import { BdoApiService } from '../../../../services/bdo-api.service';
import { TenantService } from '../../../../services/tenant.service';
import { ALLOWED_KEYS, StorageService } from '../../../../services/storage.service';
import { Subscription } from 'rxjs';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { MoveService } from '../../../../services/move.service';
import { LoginService } from '../../../../../login/login.service';
import { FormFieldIdentifierTracking } from '../../../../services/tracking.service';
import { FadeInAnimation } from '../../../../../shared/animations/fade-in.animation';
import { defaultValidatorProxy } from '../../../../../shared/validators/default-validator-proxy';
import { MeterProductSelectionService } from '../../../../services/meter-product-selection.service';
import { DELIVERY_TYPE } from '../../../../enums/deliveryType';
import { Utilities } from '../../../../../shared/utils/utilities';
import { MeterDataFormStructure } from '../../../delivery/situation-form/situation-form.component';
import { TRACKING } from '../../../../enums/trackingParts.enum';
import {
  ProductSelectionDivisionFormtype,
  ProductSelectionFormtype
} from '../../../../../shared/formtypes/product-selection.formtype';
import { TENANT_ACRONYM } from '../../../../enums/tenant.enum';
import { Environment } from '../../../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

type StoredMeterData = {
  [key in VERBRAUCHSTYP]?: MetersCheckResult[]
};

export interface AvailableDivision {
  divisionId: VERBRAUCHSTYP;
  tenantNameShort: string;
  areaBelongsToCurrentTenant?: boolean;
  isProvided: boolean;
}

export interface AvailableDivisionInfos {
  availableDivisions: AvailableDivision[];
  onlyExternalAreas?: boolean;
  noAvailableDivisions?: boolean;
  allUnavailableOrExternal?: boolean;
  tenantNameOfArea: string;
}

@Component({
  selector: 'bdo-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.scss'],
  animations: [
    FadeInAnimation
  ]
})
export class ProductSelectionComponent implements OnInit, OnDestroy {

  @Input() public newHomeForm: UntypedFormGroup;
  @Input() public address: Address;
  @Input() cancelOptionsShown: boolean = false;
  @Input() formTracking: FormFieldIdentifierTracking;
  // process will be abort if no divisions are available for customer. Other texts will be shown.
  @Input() abortIfNoDivisions: boolean = false;
  @Input() forceValidateDate: boolean;
  @Input() trackingEventType = TRACKING.LOCATION.PRODUCT_SELECTION;
  @Input() isContextContractNew: boolean = false;

  @Output() public meterDataChange = new EventEmitter<{
    [key: string]: { // division
      [key: string]: MetersCheckResult
    } }>();
  @Output() public situationDataChange: EventEmitter<SituationData> = new EventEmitter();

  @Output() public updateAvailableDivisions = new EventEmitter<AvailableDivisionInfos>();
  public Utilities = Utilities;
  public DeliveryType = DELIVERY_TYPE;
  public Verbrauchstyp = VERBRAUCHSTYP;
  public meterData: { [key: string]: {
      [key: string]: MetersCheckResult
  } };
  public minDate: Date = moment().subtract(42, 'd').toDate();
  public showRegisterInputs = true;
  public availableDivisions: AvailableDivision[];
  public productForm = new FormGroup<ProductSelectionFormtype>({
    startDate: new FormControl(null, {
      validators: [
        defaultValidatorProxy(Validators.required, this.translateService.instant('move.newHome.start.startDateRequired')),
      ] }),
  });

  public storedMeterData: StoredMeterData = {};

  /**
   * flag, if some of the found divisions are provided by a different tenant
   */
  public onlyExternalAreas: boolean;

  /**
   * name of the tenant, that provides the division on the entered address
   */
  public tenantNameOfArea: string;

  // divisions, that are allowed in this form
  public activatedDivisions = [VERBRAUCHSTYP.Gas, VERBRAUCHSTYP.Strom];
  public noAvailableDivisions: boolean;
  public allUnavailableOrExternal: boolean;
  public meterIdentifiers: ZaehlerIdentifier[] = [];
  private subscriptions = new Subscription();

  constructor(
    public tenantService: TenantService,
    public loginService: LoginService,
    public apiService: BdoApiService,
    public cdRef: ChangeDetectorRef,
    public moveService: MoveService,
    private meterProductService: MeterProductSelectionService,
    private translateService: TranslateService
  ) { }

  ngOnDestroy() {
    this.newHomeForm.removeControl('product-selection');
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.meterProductService.initValues(this.meterData, this.newHomeForm);

    const meterDataFromSessionStorage = StorageService.getValue<SituationData>(ALLOWED_KEYS.SITUATION_DATA)?.meterData;
    this.storedMeterData[VERBRAUCHSTYP.Strom] = meterDataFromSessionStorage?.filter((item) => item.divisionId === VERBRAUCHSTYP.Strom);
    this.storedMeterData[VERBRAUCHSTYP.Gas] = meterDataFromSessionStorage?.filter((item) => item.divisionId === VERBRAUCHSTYP.Gas);
    const startDate = moment(StorageService.getValue<SituationData>(ALLOWED_KEYS.SITUATION_DATA)?.startDate || null).toDate();
    // invalid dates should not be set, because they bypass the "required"-validator
    const startDateIsValid = startDate instanceof Date && isFinite(startDate.getTime());
    this.productForm.get('startDate').setValue(startDateIsValid ? startDate : null);

    // Prevent process contract-new with meternumbers that are already in use and should go to process contract-change instead
    if (this.isContextContractNew) {
      this.apiService.getZaehlerIdentifiers(true).subscribe(
        { next: res => {
          this.meterIdentifiers = res;
        } }
      );
    }


    this.apiService.getAvailableProducts(this.address).pipe(
      map(res => {

        // easy way to force a combination, where only one division is provided at the new address
        // if (this.address.street.name === 'Parkgürtel' && this.address.houseNum === '24' || this.address.houseNum === '23' ) {
        //   res.list.splice(0, 1);
        // }
        // convert string of response to corresponding Verbrauchstyp i.E. "S4" -> Verbrauchstyp.Strom
        return res.list.map((item, index) => {
          for (const division of Object.keys(VERBRAUCHSTYP)) {
            if (item.divisionId === VERBRAUCHSTYP[division]) {

              const areaBelongsToCurrentTenant = TENANT_ACRONYM[Environment.tenant] === item.tenantNameShort.toLowerCase();

              // // easy way to force a combination, where only one division is provided by another tenant
              // if (index === 1 && this.address.street.name === 'Schmillenburg' && this.address.houseNum === '10') {
              //   areaBelongsToCurrentTenant = true;
              // }
              // // easy way to force a combination, where no division is provided at the new address
              // if (this.address.street.name === 'Parkgürtel' && this.address.houseNum === '23') {
              //   areaBelongsToCurrentTenant = false;
              // }

              return {
                tenantNameShort: item.tenantNameShort,
                divisionId: VERBRAUCHSTYP[division],
                areaBelongsToCurrentTenant,
                isProvided: true
              };
            }
          }
        });
      }),
      map((divisions) => {
        return divisions.filter((division) => this.activatedDivisions.includes(division?.divisionId))
          // make Strom the first checkable row
          .sort((a, b) => a.divisionId === VERBRAUCHSTYP.Strom ? -1 : 1);
      }),
      map((divisions: AvailableDivision[]) => {
        const divisionsFromRecentHome = this.moveService.getDivisionsFromRecentHome();
        const missingRecentDivisionsAtNewAdress = divisionsFromRecentHome?.filter((divisionId) => {
          return !divisions.find((availableDivision) => availableDivision.divisionId === divisionId);
        });
        // add divisions, that have been provided at recent home but not on new home
        if (missingRecentDivisionsAtNewAdress?.length) {
          missingRecentDivisionsAtNewAdress.forEach((divisionId) => {
            divisions.push({
              divisionId,
              areaBelongsToCurrentTenant: undefined,
              tenantNameShort: undefined,
              isProvided: false
            } as AvailableDivision);
          });
        }
        return divisions;
      }))
      .subscribe({ next: (divisions) => {
        this.availableDivisions = divisions;
        this.onlyExternalAreas = divisions.every((item) => item.areaBelongsToCurrentTenant === false);
        this.noAvailableDivisions = divisions.every((item) => item.isProvided === false);
        this.allUnavailableOrExternal = divisions.every((item) => item.isProvided === false || item.areaBelongsToCurrentTenant === false);
        if (this.onlyExternalAreas) {
          this.tenantNameOfArea = this.tenantService.getTenantDataByShort(
            divisions.find((item) => !item.areaBelongsToCurrentTenant)?.tenantNameShort
          )?.companyName;
        }
        const availableDivisionDetails: AvailableDivisionInfos = {
          availableDivisions: divisions,
          onlyExternalAreas: this.onlyExternalAreas,
          tenantNameOfArea: this.tenantNameOfArea,
          noAvailableDivisions: this.noAvailableDivisions,
          allUnavailableOrExternal: this.allUnavailableOrExternal
        };
        this.updateAvailableDivisions.emit(availableDivisionDetails);
        StorageService.setValue(ALLOWED_KEYS.AVAILABLE_DIVISIONS, availableDivisionDetails);
        this.addControlsForDivision(...divisions.map((item) => item.divisionId));
        if (this.allUnavailableOrExternal) {
          this.productForm.get('startDate').clearValidators();
          this.productForm.get('startDate').updateValueAndValidity();
        }
        // if there is already a product-selection -> do not add but update the existing to keep the reference
        // in the parent-component
        if (this.newHomeForm.controls['product-selection']) {
          this.newHomeForm.setControl('product-selection', this.productForm);
        } else {
          this.newHomeForm.addControl('product-selection', this.productForm);
        }

        // to prevent ExpressionChangedAfterItHasBeenChecked error, adding async controls in an async leads into
        this.cdRef.detectChanges();
      } });
  }



  public onMeterCheckResult($event: { [key: string]: MetersCheckResult }, verbrauchstyp: VERBRAUCHSTYP) {

    this.meterData = {
      ...this.meterData,
      [verbrauchstyp]: $event
    };
    this.meterProductService.setMeterData(this.meterData);
    // find latest minDate in all meters
    const meterFormDataEnergy = this.newHomeForm.get('product-selection')?.get(VERBRAUCHSTYP.Strom)?.get('meterData')?.value as MeterDataFormStructure;
    const meterFormDataGas = this.newHomeForm.get('product-selection')?.get(VERBRAUCHSTYP.Gas)?.get('meterData')?.value as MeterDataFormStructure;
    const allMinDates: Array<string> = [];

    if (meterFormDataEnergy) {
      Object.entries(meterFormDataEnergy).map(([groupName, group]) => {

        allMinDates.push(group?.minDate);
      });
    }
    if (meterFormDataGas) {
      Object.entries(meterFormDataGas).map(([groupName, group]) => {
        allMinDates.push(group?.minDate);
      });
    }
    const latestMinDate = moment.max(allMinDates.map(date => moment(date)));

    if (this.minDate !== latestMinDate.toDate()) {
      this.minDate = latestMinDate.toDate();
    }
    // date field new form value after check cycle, so we have to manually detect changes after setting the min date
  }

  updateDate(date: Date) {
    this.productForm.controls.startDate.setValue(date);
  }

  clickCheckboxHandler(division: VERBRAUCHSTYP) {
    // the structure of the product-selection form is not suitable for the typed forms feature since angular 14 and should be updated
    const checkboxFormField = this.getTypedDivisionForm(division).get('isChecked');
    const currentValue = checkboxFormField.value;
    const isChecked = !currentValue;
    if (!isChecked) {
      this.getTypedDivisionForm(division).removeControl('meterData');
    }
    checkboxFormField.setValue(isChecked);
  }

  getAtLeastOneSelected() {
    let divisionChecked = false;
    this.availableDivisions?.forEach( division => {
      const divisionControl = this.productForm.get(division.divisionId)?.value;
      if (divisionControl['isChecked']) {
        divisionChecked = true;
      }
    });
    return divisionChecked;
  }

  getTypedDivisionForm(division) {
    return this.productForm.get(division) as unknown as FormGroup<ProductSelectionDivisionFormtype>;
  }

  getFormTracking(name: string) {
    const tracking = Object.assign({}, this.formTracking);
    tracking.orca_field_name = name;

    return tracking;
  }

  private addControlsForDivision(...divisions: VERBRAUCHSTYP[]) {
    divisions.forEach((division) => {
      this.productForm.addControl(division as any, new FormGroup<ProductSelectionDivisionFormtype>({
          isChecked: new FormControl(!!this.storedMeterData[division]?.length),
          startDate: new FormControl(new Date(), {
            validators: [() => {
              if (!this.meterData) {
                return null;
              }
            }]
          })
        })
      );
    });
  }

}
