<!-- Mobile View -->
<div class="md:re-hidden">
  <div class="re-bg-white re-p-20 re-min-h-45 re-mb-2">
    <div class="re-h-50 re-mb-20">
      <div class="re-table-cell re-align-middle re-pr-30" *ngIf="isSelectable">
        <ng-container *ngTemplateOutlet="checkbox"></ng-container>
      </div>
      <div class="re-table-cell re-h-50 re-align-middle">
        <ng-container *ngTemplateOutlet="logo"></ng-container>
      </div>
    </div>
    <ng-container *ngTemplateOutlet="title"></ng-container>
  </div>

  <div class="re-bg-white re-px-20 re-py-10 re-min-h-45">
    <div class="re-inline-block">
      <ng-container *ngTemplateOutlet="value"></ng-container>
    </div>
    <div class="re-inline-block re-w-30 re-h-30 re-float-right re-mt-15">
      <ng-container *ngTemplateOutlet="tooltip"></ng-container>
    </div>
  </div>
</div>

<!-- Tablet View -->
<div class="re-hidden lg:re-hidden re-w-600 re-mx-auto md:re-block">
  <div class="re-table-cell re-w-full">
    <div class="re-bg-white re-pl-30 re-pr-30 re-pt-30 re-pb-25 re-min-h-45">
      <div class="re-h-50 re-mb-20">
        <div class="re-inline-block re-align-middle re-mr-30" *ngIf="isSelectable">
          <ng-container *ngTemplateOutlet="checkbox"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="logo"></ng-container>
      </div>
      <ng-container *ngTemplateOutlet="title"></ng-container>
    </div>
  </div>
  <div class="re-min-w-120 re-h-150 re-py-15 re-bg-white re-mr-2 re-table-cell re-align-middle">
    <ng-container *ngTemplateOutlet="value"></ng-container>
  </div>
  <div class="re-w-30 re-h-150 re-pr-30 re-pl-60 re-py-15 re-bg-white re-table-cell re-align-middle">
    <ng-container *ngTemplateOutlet="tooltip"></ng-container>
  </div>
</div>

<!-- Desktop View -->
<div class="re-hidden lg:re-table-row">
  <div *ngIf="isSelectable" class="re-w-90 re-h-105 re-px-30 re-bg-white lg:re-border-r-2 re-border-solid re-border-monochrome-light re-table-cell re-align-middle">
    <ng-container *ngTemplateOutlet="checkbox"></ng-container>
  </div>
  <div class="re-h-105 re-px-30 re-bg-white re-mr-2 re-table-cell re-align-middle lg:re-border-r-2 re-border-solid re-border-monochrome-light re-text-center"
      [ngClass]="isSelectable ? 're-w-230' : 're-w-[320px]'">
    <ng-container *ngTemplateOutlet="logo"></ng-container>
  </div>
  <div class="re-w-580 re-h-105 re-px-30 re-bg-white re-table re-align-middle">
    <div class="re-table-cell re-align-middle re-w-300">
      <ng-container *ngTemplateOutlet="title"></ng-container>
    </div><div class="re-table-cell re-align-middle re-w-120">
      <ng-container *ngTemplateOutlet="value"></ng-container>
    </div><div class="re-table-cell re-align-middle re-w-30 re-h-105 re-text-right re-pl-30">
      <ng-container *ngTemplateOutlet="tooltip"></ng-container>
    </div>
  </div>
</div>


<ng-template #checkbox>
  <div class="re-w-30 re-h-30">
    <bdo-checkbox
      [checked]="isSelected"
      [ariaLabel]="data?.title"
      (change)="selectionUpdated.emit(isSelected)">
    </bdo-checkbox>
  </div>
</ng-template>

<ng-template #logo>
  <img [src]="data?.logo" [alt]="data?.id + '-Logo'" class="re-max-h-50">
</ng-template>

<ng-template #title>
  <div class="bold">{{data?.title}}</div>
  <div>{{data?.subtitle}}</div>
</ng-template>

<ng-template #value>
  <div class="re-text-xs re-leading-s md:re-text-sm md:re-leading-m">{{data?.valuetitle}}</div>
  <div class="bold re-text-base re-leading-2xl md:re-text-l">{{data?.value}}</div>
</ng-template>

<ng-template #tooltip>
  <span>
    <bdo-inline-tooltip [size]="'large'">
      <div [innerHTML]="data?.info"></div>
    </bdo-inline-tooltip>
  </span>
</ng-template>
