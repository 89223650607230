/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Campaign info
 */
export interface CampaignInfo { 
    /**
     * accountId of existing contract, also knows as VK
     */
    accountId?: string;
    /**
     * division of product e.g. S4 for power, G6 for gas or T4 for water
     */
    divisionId?: string;
    /**
     * campaign id e.g. RESFR_EVS_MVP24
     */
    campaignId?: string;
    /**
     * productId / productId for a given tariff
     */
    productId?: string;
    /**
     * id of campaign banner e.g. RE_STROM_MVP_2
     */
    bannerId?: string;
    campaignStartDate?: Date;
}

