<div class="select-button re-color-white re-mr-10 bold"
     [class]="(active || completed) ? 're-bg-primary': 're-bg-monochrome-medium'"
     (click)="onClick()"
     [ngClass]="{'active': active, 're-cursor-pointer': clickable}"
>
  <div>
    <svg-icon [applyClass]="true" class="re-fill-current re-stroke-current re-h-20 re-w-20 re-text-0 re-align-middle" src="{{iconSource}}"></svg-icon>
  </div>
  <span class="text re-truncate">{{title}}</span>
</div>
