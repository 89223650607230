"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var TargetingData = /** @class */function () {
  function TargetingData(lastActivityTime, data) {
    this.lastActivityTime = lastActivityTime;
    this.data = data;
  }
  return TargetingData;
}();
exports.default = TargetingData;