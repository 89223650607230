import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listing'
})
/**
 * Transforms an Array into a String containing the elements concatenated with comma and 'und' (German)
 */
export class ListingPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!Array.isArray(value)) {
      return value;
    }
    const max = value.length;
    let formattedString = '';
    for (let i = 0; i < max; i++) {
      if (i > 0) {
        if ( i === max - 1 ) {
          formattedString += ' und ';
        } else {
          formattedString += ', ';
        }
      }
      formattedString += value[i];
    }
    return formattedString;
  }

}
