import { Component, Input } from '@angular/core';
import { ContractDetails, ContractLevel } from '../../../../../assets/js/com/ts_api_client';
import { CUSTOMERMODE } from '../../../enums/customerMode';
import { VERBRAUCHSTYP } from '../../../enums/verbrauchstyp.enum';
import { PriceCapService } from '../../../services/price-cap.service';
import { TariffAdvisorService } from '../../../services/tariff-advisor.service';
import { TenantService } from '../../../services/tenant.service';

/* Contracts without a specific end date are set to Fri Dec 31 9999 */
const MAXCONTRACTDATE = new Date(253402214400000).toISOString();

@Component({
  selector: 'bdo-contract-details-tariff-info',
  templateUrl: './contract-details-tariff-info.component.html',
  styleUrls: ['./contract-details-tariff-info.component.scss']
})
export class ContractDetailsTariffInfoComponent {
  @Input() accountId: string = '';
  @Input() contract: ContractDetails;
  @Input() customerMode: CUSTOMERMODE = CUSTOMERMODE.GEWE;
  @Input() showPriceCap: boolean = false;
  public MAXCONTRACTDATE = MAXCONTRACTDATE;
  public Verbrauchstyp = VERBRAUCHSTYP;
  public CUSTOMERMODE = CUSTOMERMODE;

  constructor(
    public tenantService: TenantService,
    public tariffAdvisorService: TariffAdvisorService,
    public priceCapService: PriceCapService
  ) {
  }

  getLevel(division: string, contractLevel: string): 'basic' | 'meter' | 'option' {

    switch (contractLevel) {
      case '1': // frame contract
        return 'basic';

      case '15': // meter
        return 'meter';

      case '4': // for water basic and for the rest options
        if (division === VERBRAUCHSTYP.Wasser) {
          return 'basic';
        } else {
          return 'option';
        }

      default: // everything else are options (e.g. 3 = 'Öko-Option')
        return 'option';
    }
  }

  getBasicContractItem(contractLevels: Array<ContractLevel>): ContractLevel {
    return contractLevels?.find(item => item?.contractLevelNumber === '1');
  }
}
