import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { StorageService } from '../../../services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FadeInAnimation } from '../../../../shared/animations/fade-in.animation';
import { TariffSelection } from '../../../../shared/models/tariff-selection';

@Component({
  selector: 'bdo-tariff-adivsor-summary',
  templateUrl: './tariff-adivsor-summary.component.html',
  styleUrls: ['./tariff-adivsor-summary.component.scss'],
  animations: [
    FadeInAnimation
  ]
})
export class TariffAdivsorSummaryComponent {
  private tariffSelections: TariffSelection[];

  constructor(
    public location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    if (StorageService.getTariffSelections()) {
      this.tariffSelections = StorageService.getTariffSelections();
    }
  }

  public getCompletedTariffSelections(): TariffSelection[] {
    return this.tariffSelections.filter((item) => item.selectedTariff);
  }

  public getSum(): number {
    const selectedDivisions = this.getCompletedTariffSelections();
    return selectedDivisions.filter((item) => item.selectedTariff).reduce((sum, item) => {
      return sum + item.monthlyPrice;
    }, 0);
  }

  continue() {
    this.router.navigate(['../../zahlungsart'], {
      relativeTo: this.activatedRoute
    });
  }

}
