import { Component, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { SingleLineInputComponent } from '../single-line-input/single-line-input.component';

@Component({
  selector: 'bdo-multi-line-input',
  templateUrl: './multi-line-input.component.html',
  styleUrls: ['./multi-line-input.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class MultiLineInputComponent extends SingleLineInputComponent {
  @Input() rows: number = 3;
}
