import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TenantService } from '../../../services/tenant.service';
import { Location } from '@angular/common';
import { LoginService } from '../../../../login/login.service';
import { first, map, switchMap } from 'rxjs/operators';
import { BdoApiService } from '../../../services/bdo-api.service';
import { Account, Address, StatusResponse, ZaehlerIdentifier, Zaehlerstand } from '../../../../../assets/js/com/ts_api_client';
import moment from 'moment';
import { CustomerStoreService } from '../../../services/customer-store.service';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeData } from '../../../models/home';
import { AuthDataStorage } from '../../../models/AuthData.storage';
import { CONTEXT_FROM_SUMMARY } from '../../delivery/summary/summary.component';
import { TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { RESPONSE_STATUS } from '../../../enums/responseStatus.enum';
import { defaultValidatorProxy } from '../../../../shared/validators/default-validator-proxy';
import { Utilities } from '../../../../shared/utils/utilities';
import { NavigationState } from '../../../models/navigationState';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-recent-home',
  templateUrl: './recent-home.component.html',
  styleUrls: ['./recent-home.component.scss']
})

export class RecentHomeComponent implements OnInit {
  @Input() trackingLocation: TRACKING.LOCATION = TRACKING.LOCATION.MOVE_RECENTHOME;
  @Input() isMove: boolean = true;
  public address: Address;
  public accountId: string = '';
  public minDate: Date;
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public stateRecentMeters: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public meters: Array<ZaehlerIdentifier> = [];
  public divisions: Array<string> = [];
  public recentHomeForm = new FormGroup({
    moveoutDate: new FormControl<Date>(null, {
      validators: [
      defaultValidatorProxy(Validators.required, this.translateService.instant('move.recentHome.address.endDateRequired')),
      ] })
  });
  public CONTEXT_FROM_SUMMARY = CONTEXT_FROM_SUMMARY;
  public context: string;
  public forceValidate: boolean = false;
  public Utilities = Utilities;

  private authMeterNumber: string;
  private meterReadings: Array<Zaehlerstand> = [];

  constructor (
    public tenantService: TenantService,
    public location: Location,
    private apiService: BdoApiService,
    private loginService: LoginService,
    private customerStore: CustomerStoreService,
    private router: Router,
    private trackingService: TrackingService,
    public activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {
    const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
    this.context = currentState?.context;
  }

  ngOnInit(): void {
    this.trackingService.postTracking(this.trackingLocation, TRACKING.ACTION.ENTER);
    this.trackingService.postFormTracking(TRACKING.FORM.MOVE_RECENTHOME_NAME, TRACKING.FORM_ACTION.VIEW);
    this.state = LOADING_STATE.LOADING;
    this.stateRecentMeters = LOADING_STATE.LOADING;
    const authData = StorageService.getValue<AuthDataStorage>(ALLOWED_KEYS.AUTH_DATA);
    this.accountId = this.customerStore.getAccountId();

    this.loginService.isLoggedIn$.pipe(
      first(),
    ).subscribe({ next: (loggedIn) => {
      const accountId = this.customerStore.getAccountId();
      if (loggedIn && authData) { // should not happen - clean up anonymous data if already loggedIn
        StorageService.clearValues([ALLOWED_KEYS.AUTH_DATA]);
      }

      if (loggedIn && accountId) {
        this.accountId = accountId;

        this.apiService.getAccounts()
          .subscribe({ next: (res: Array<Account>) => {
            const account: Account = res.find(acc => acc.accountId === this.accountId);
          this.checkProcessAvailable(account?.isEmployee, account?.eingeschraenkteRegio,
                                     this.translateService.instant('general.tariff.contactFormOnlyRE',
                                    { contactFormLink: this.tenantService.getCurrentTenantData().hrefContactForm, accountId: this.accountId }));
          this.state = LOADING_STATE.IDLE;
        } });
        this.getAddress(this.apiService.getAccountInfo(this.accountId).pipe(map(x => x.address)));
        this.getMeters();
      } else if (loggedIn) { // if no accountId is selected, just take the first one
        this.apiService.getAccounts().subscribe( { next: res => {
          this.accountId = res[0]?.accountId;
          this.customerStore.setAccountId(this.accountId);
          this.checkProcessAvailable(res[0]?.isEmployee, res[0]?.eingeschraenkteRegio,
            this.translateService.instant('general.tariff.contactFormOnlyRE',
            { contactFormLink: this.tenantService.getCurrentTenantData().hrefContactForm, accountId: this.accountId }));
          this.getAddress(this.apiService.getAccountInfo(this.accountId).pipe(map(x => x.address)));
          this.getMeters();
          this.state = LOADING_STATE.IDLE;
        } });
      } else if (authData) { // anonymous
        this.accountId = authData?.checkIdentificationData?.accountId;
        this.authMeterNumber = authData?.checkIdentificationData?.meterNumberOrRegisterCode;
        this.customerStore.setAccountId(this.accountId);
        this.apiService.getIsEmployeeAnonymous(this.accountId, this.authMeterNumber)
        .subscribe({ next: res => {
          this.checkProcessAvailable(res.info === 'true', false, this.tenantService.getCurrentTenantData().hrefContactForm);
        } });
        this.getAddress(this.apiService.getAddressAnonymous(this.accountId, this.authMeterNumber).pipe(
          map(resFullAddress => resFullAddress.premiseAddress)
        ));
        this.getMeters();
      } else { // should not be here without any authorization
        this.router.navigate(['/umzug/auth']);
      }
    } });

    // Default today unless prefilled
    const moveOutDate = StorageService.getValue<HomeData>(ALLOWED_KEYS.RECENT_HOME_DATA)?.date;
    this.recentHomeForm?.get('moveoutDate').setValue(moveOutDate || null);
  }

  checkProcessAvailable(isEmployee: boolean, isRestrictedRegio: boolean, contactLink: string) {
    if ((this.isMove && isEmployee ) || isRestrictedRegio) {
      const process: string = this.isMove ? this.translateService.instant('move.title') :
                              this.translateService.instant('dashboard.moreServices.contractEnd');
      this.router.navigate(['/hinweis'], {
        relativeTo: this.activatedRoute,
        state: {
          link: contactLink,
          process: process
        }
      });
    }
  }

  getAddress(addressObs: Observable<Address>) {
    addressObs.subscribe({ next: (address) => {
      this.state = LOADING_STATE.IDLE;
      this.address = address;
    }, error: (error: unknown) => {
      this.state = LOADING_STATE.ERROR;
    } });
  }

  getMeters() {
    let smartMeterExisting: boolean = false;
    this.loginService.isLoggedIn$.pipe(
      first(),
      switchMap((loggedIn) => {
        return loggedIn ? this.apiService.getZaehlerIdentifiers(false) : this.apiService.getMetersAnonymously(this.accountId, this.authMeterNumber);
      })
    ).subscribe(
      {
        next: (result: Array<ZaehlerIdentifier>) => {
          if (result.length > 0) {
            this.checkStorage(result);
            result.map((zaehler: ZaehlerIdentifier) => {
              if (!zaehler.inaktiv) {
                this.meters.push(zaehler);
                smartMeterExisting = smartMeterExisting || zaehler.isSmartMeter;
                if (!this.divisions.includes(zaehler.sparte)) {
                  this.divisions.push(zaehler.sparte);
                }
                // sort divisions to show strom first
                this.divisions.sort((a, b) => {
                  return a === 'S4' && b !== 'S4' ? -1 : 1;
                });
              }
            });
            this.setMinDate(smartMeterExisting);
            this.stateRecentMeters = LOADING_STATE.IDLE;
          } else {
            this.stateRecentMeters = LOADING_STATE.ERROR;
          }
        },
        error: () => {
          this.state = LOADING_STATE.ERROR;
        }
      });
  }

  checkStorage(meters: Array<ZaehlerIdentifier>) {
    const meterData: Array<Zaehlerstand> = StorageService.getValue<HomeData>(ALLOWED_KEYS.RECENT_HOME_DATA)?.meterData;
    this.meterReadings = meterData || [];

    if (this.meterReadings) {
      this.meterReadings.map((meterReading: Zaehlerstand) => {
        const result = meters.find(meter => {
          return meter.meterNumber === meterReading.meterNumber;
        });
        if (!result) {
          this.meterReadings = [];
          StorageService.setProperty(ALLOWED_KEYS.RECENT_HOME_DATA, 'meterData', this.meterReadings);
          StorageService.setProperty(ALLOWED_KEYS.RECENT_HOME_DATA, 'meters', []);
        }
      });
    }
  }

  setMinDate(isSmartMeter: boolean) {
    if (isSmartMeter) {
      this.apiService.getBdewWorkdayOffset('+1'
      ).subscribe(
        {
          next: (result: StatusResponse) => {
            if (result.status === RESPONSE_STATUS.Success) {
              this.minDate = new Date(parseInt(result.info, 10));
            } else {
              this.minDate = moment().add(1, 'd').startOf('day').toDate();
            }
          },
          error: () => {
            this.minDate = moment().add(1, 'd').startOf('day').toDate();

          }
        });
    } else {
      this.minDate = moment().subtract(42, 'd').startOf('day').toDate();
    }
  }

  onReadingChange(reading: Zaehlerstand) {
    let meterAlreadyInArray: boolean = false;
    for (let meter = 0; meter < this.meterReadings.length; meter++) {
      if (this.meterReadings[meter].meterNumber === reading.meterNumber) {
        this.meterReadings[meter] = reading;
        meterAlreadyInArray = true;
      }
    }
    if (!meterAlreadyInArray) { this.meterReadings.push(reading); }
  }

  save() {
    this.forceValidate = true;
    if (this.stateRecentMeters === LOADING_STATE.IDLE && this.recentHomeForm?.valid) {
      const homeData: HomeData = {
        addressData: this.address,
        date: this.recentHomeForm.get('moveoutDate').value,
        meterData: this.meterReadings,
        meters: this.meters
      };
      StorageService.setValue(ALLOWED_KEYS.RECENT_HOME_DATA, homeData);

      if (!this.isMove) {
        if (this.context === CONTEXT_FROM_SUMMARY) {
          this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_END_RECENT, TRACKING.ACTION.GOTO, 'zusammenfassung');
          this.router.navigate(['../uebersicht'], {
            relativeTo: this.activatedRoute
          });
        } else {
          this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_END_RECENT, TRACKING.ACTION.GOTO, 'zahlungsart');
          this.router.navigate(['../zahlungsart'], {
            relativeTo: this.activatedRoute
          });
        }
      } else {
        this.trackingService.postFormTracking(TRACKING.FORM.MOVE_RECENTHOME_NAME, TRACKING.FORM_ACTION.SUBMIT);
        if (this.context === CONTEXT_FROM_SUMMARY) {
          this.trackingService.postSimpleTracking(TRACKING.LOCATION.MOVE_RECENTHOME, TRACKING.ACTION.GOTO, 'zusammenfassung');
          this.router.navigate(['../../../' + this.accountId + '/neu/uebersicht'], {
            relativeTo: this.activatedRoute
          });
        } else {
          this.trackingService.postSimpleTracking(TRACKING.LOCATION.MOVE_RECENTHOME, TRACKING.ACTION.GOTO, 'neues-zuhause');
          this.router.navigate(['../../../' + this.accountId + '/neu/neues-zuhause'], {
            relativeTo: this.activatedRoute
          });
        }
      }
    }
  }
}
