import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { LoginService } from '../../../../login/login.service';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { CustomerStoreService } from '../../../services/customer-store.service';

@Component({
  selector: 'bdo-personal-data-password',
  templateUrl: './personal-data-password.component.html',
  styleUrls: ['./personal-data-password.component.scss']
})
export class PersonalDataPasswordComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Output() edit: EventEmitter<boolean> = new EventEmitter();
  public LoadingState = LOADING_STATE;
  public state = LOADING_STATE.IDLE;
  public isInEditMode = false;
  public username: string;

  constructor(
    public customerStore: CustomerStoreService,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.state = LOADING_STATE.LOADING;

    // Get Username
    this.loginService.getUsername().pipe(
      map(username => this.username = username)
    ).subscribe({
        next: () => {
          this.state = LOADING_STATE.IDLE;
        },
        error: () => {
          this.state = LOADING_STATE.ERROR;
        }
    });
  }

  onEditDone() {
    this.isInEditMode = !this.isInEditMode;
    this.edit.emit(false);
  }
}
