import { Component, Input } from '@angular/core';

@Component({
  selector: 'bdo-popup-content',
  templateUrl: './popup-content.component.html',
  styleUrls: ['./popup-content.component.scss']
})
export class PopupContentComponent {
  @Input() iconName: string;
  @Input() headline: string;

}
