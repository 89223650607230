/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Zaehler- und Kundennummer zur Identifikation eines Partneraccounts
 */
export interface CheckIdentificationData { 
    /**
     * accountId of existing contract, also knows as VK
     */
    accountId: string;
    meterNumberOrRegisterCode: string;
    atDate?: Date;
}

