/* eslint-disable @typescript-eslint/naming-convention */
export const ABLESEGRUND = {
    '01' : 'Turnusablesung',
    '02' : 'Zwischenablesung mit Abrechnung',
    '03' : 'Auszug',
    '06' : 'Einzug',
    '09' : 'Zwischenablesung ohne Abrechnung',
    '10' : 'Kontrollablesung',
    '21' : 'Geräteeinbau',
    '22' : 'Geräteausbau'
};
