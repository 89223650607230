
<form (ngSubmit)="save()" [formGroup]="addressForm?.form" (keydown.enter)="$event.preventDefault()" bdoScrollToInvalidControl>
  <div class="re-relative">
    <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
    <fieldset class="re-mb-0 md:re-mt-45">

      <div [class.re-mb-15]="state === LoadingState.ERROR" data-qa="correspondenceAddressErrorbox">
        <bdo-info-box *ngIf="state === LoadingState.ERROR">
          {{ 'general.error.error' | translate }}
        </bdo-info-box>
      </div>

      <h5 class="re-mb-15 md:re-mb-30">{{ 'personaldata.address.add' | translate }}</h5>

      <bdo-address-form></bdo-address-form>

      <div class="re-mb-15 re-mt-45">
        <button class="bdo-button-primary bdo-button--text" type="submit">
          {{ 'general.action.save' | translate }}
        </button>
      </div>

      <button class="bdo-nolink" data-qa="cancel" type="button" (click)="editDone.emit(true)">
        <div class="bold">{{ 'general.action.cancel' | translate }}</div>
      </button>

    </fieldset>
  </div>
</form>
