<div class="bdo-loading__wrapper" [class.bdo-loading__wrapper--height]="isLoading">
  <bdo-loading *ngIf="isLoading"></bdo-loading>
  <div class="re-px-20 re-py-30 md:re-px-30 md:re-py-45">
    <h5 class="re-mb-20">
      <div *ngIf="iconSrc" class="re-w-60 re-h-60 re-mb-5 md:re-inline-block md:re-mb-0">
        <bdo-svg-cms [iconSrc]="iconSrc" [iconSize]="60"></bdo-svg-cms>
      </div>
      {{tariffData?.name}}
    </h5>

    <span class="bold re-mb-15">{{ 'summary.tariff.dataTitle' | translate }}</span>
    <ul class="bdo-list__bullet re-mt-30">
      <li *ngFor="let item of tariffData?.benefits">
        <span>{{item}}</span>
      </li>
    </ul>
  </div>

  <hr class="re-border-t-3 re--mx-20 re-border-monochrome-light md:re--mx-30">

  <bdo-tariff-prices-overview [prices]="prices" [tariffData]="tariffData"></bdo-tariff-prices-overview>

  <!-- Kostenberechnung -->
  <hr class="re-mb-20 md:re-mb-25 re-border-t-3 re--mx-20 re-border-monochrome-light md:re--mx-30">
  <div class="re-pl-20 md:re-pl-30 re-inline-block re-small-bold">
    {{ 'summary.tariff.calculation' | translate }}
  </div>
  <bdo-tariff-prices-cost-info
    *ngIf="tariffData"
    class="re-block"
    [tariffData]="tariffData">
  </bdo-tariff-prices-cost-info>

  <ng-container *ngIf="tariffData?.durations?.[0]?.termString">
    <hr class="re-border-t-2 re--mx-20 re-border-monochrome-light md:re--mx-30 md:re-border-t-3">
    <!-- Details -->
    <bdo-tariff-details [tariffData]="tariffData"></bdo-tariff-details>
  </ng-container>
</div>
