import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Environment } from '../../../../environments/environment';
import { TENANT } from '../../enums/tenant.enum';
import { TRACKING } from '../../enums/trackingParts.enum';
import { NetcolognePostcodes } from '../../models/netcolognePostcodes';

@Component({
  selector: 'bdo-netcologne-teaser',
  templateUrl: './netcologne-teaser.component.html',
  styleUrls: ['./netcologne-teaser.component.scss']
})
export class NetcologneTeaserComponent implements OnChanges {
  @Input() postCode: string;
  @Input() trackingLocation: string = TRACKING.LOCATION.NETCOLOGNE_TEASER;
  public isInNetcologneRegion: boolean = false;
  public isRE = Environment.tenant === TENANT.Rheinenergie;
  public TRACKING = TRACKING;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.postCode?.currentValue) {
      this.isInNetcologneRegion = NetcolognePostcodes.includes(changes.postCode?.currentValue);
    }
  }

}
