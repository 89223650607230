"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InstanceVariationStorage = void 0;
var visitorVariation_1 = require("./visitorVariation");
var InstanceVariationStorage = /** @class */function () {
  function InstanceVariationStorage() {
    var _this = this;
    this.variations = {};
    this.updateVariation = function (visitorCode, experimentId, variation) {
      if (!_this.variations[visitorCode]) {
        _this.variations[visitorCode] = {};
      }
      _this.variations[visitorCode][experimentId] = new visitorVariation_1.VisitorVariation(variation.id, variation.respoolTime);
    };
    this.getVariationId = function (visitorCode, experimentId) {
      var savedVisitorVariation = _this.variations[visitorCode] && _this.variations[visitorCode][experimentId];
      if (savedVisitorVariation && savedVisitorVariation.isValid()) {
        return savedVisitorVariation.variationId;
      }
      return null;
    };
    this.getMapVariationId = function (visitorCode) {
      var visitorExperiments = _this.variations[visitorCode];
      if (visitorExperiments) {
        var variationIds_1 = {};
        Object.keys(visitorExperiments).map(function (key) {
          var variationKey = Number(key);
          variationIds_1[variationKey] = visitorExperiments[variationKey].variationId;
        });
        return variationIds_1;
      }
      return null;
    };
  }
  return InstanceVariationStorage;
}();
exports.InstanceVariationStorage = InstanceVariationStorage;