<div class="re-bg-white re-w-full re-px-30 re-py-20 re-text re-mb-3
            md:re-w-600 md:re-mx-auto
            lg:re-w-900">
  <div class="re-inline">{{ 'general.customerData.meternumber' | translate }}</div>
  <div class="re-inline bold" data-qa="meternumber">{{meter?.meterNumber}}</div>
</div>

<div *ngIf="!meter?.isSmartMeter">
  <div class="re-bg-white re-w-full re-px-20 re-py-30 re-text re-mb-30
              md:re-w-600 md:re-mx-auto md:re-pb-60
              lg:re-w-900">
    <fieldset class="re-mb-0">
      <div class="re-relative">
      <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>

        <bdo-date-picker
          [labelText]="'meterreadings.info.meterreadingDate' | translate"
          [selectedDate]="meterReading?.datum"
          [minDateForChanges]="minDate"
          [maxDateForChanges]="maxDate"
          (dateSelected)="dateChanged($event)">
        </bdo-date-picker>

        <div *ngFor="let zaehlwerk of meterReading?.zaehlwerkeListe">
          <div class="re-form-item-container re-mt-20">
            <bdo-input
              [id]="'meterReadingInput-' + zaehlwerk.zaehlwerknummer"
              [attr.data-qa_id]="'meterReadingInput-' + zaehlwerk.zaehlwerknummer"
              [formControl]="$any(parentForm?.get('reading-' + this.meterReading.meterNumber + '-' + zaehlwerk.zaehlwerknummer))"
              (blur)="zaehlwerk.wert=parentForm?.get('reading-' + this.meterReading.meterNumber + '-' + zaehlwerk.zaehlwerknummer)?.value; saveReading()"
              [placeholder]="getPlaceholder(zaehlwerk)"
              [help]="'meterreadings.info.enterLater' | translate"
              [validationIconHidden]="parentForm?.get('reading-' + this.meterReading.meterNumber + '-' + zaehlwerk.zaehlwerknummer)?.value === ''"
              [formTracking]="formTracking"
              [tooltipContent]="meterreadingInfoService.getInfo(meterReading.sparte)"
            >
            </bdo-input>
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</div>

<div *ngIf="meter?.isSmartMeter">
  <div class="re-bg-white re-w-full re-px-20 re-py-30 re-text re-mb-30
              md:re-w-600 md:re-mx-auto md:re-pb-60
              lg:re-w-900">
    <div class="re-form-item-container">
      <div class="re-mb-15 bold">{{ 'meterreadings.smartmeter.title' | translate }}</div>
      <div data-qa="showTextSmartMeter">{{ 'meterreadings.smartmeter.readingInfo' | translate }}</div>
    </div>
  </div>
</div>
