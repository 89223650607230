import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Ablesung } from '../../../../../assets/js/com/ts_api_client';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { TrackingService } from '../../../services/tracking.service';

@Component({
  selector: 'bdo-zaehlerstand-historie-tabelle',
  templateUrl: './zaehlerstand-historie-tabelle.component.html',
  styleUrls: ['./zaehlerstand-historie-tabelle.component.scss']
})
export class ZaehlerstandHistorieTabelleComponent implements OnInit {
  @Input() zaehlerNummer;
  @Input() ablesungen: Ablesung[] = [];
  @Input() editDisabled: boolean = false;
  @Output() edit: EventEmitter<Date> = new EventEmitter();
  totalRecords: number = 0;
  recordsToAddMobile: number = 3;
  recordsToAddDesktop: number = 5;

  constructor(private trackingService: TrackingService) { }

  ngOnInit() {
    this.totalRecords = this.getRecordsToAdd();
  }

  /** Depending on Width returns the amount of records that should be added */
  getRecordsToAdd() {
    return document.documentElement.clientWidth > 760 ? this.recordsToAddDesktop : this.recordsToAddMobile;
  }

  addRecords() {
    this.trackingService.postTracking(TRACKING.LOCATION.CONSUMPTION, TRACKING.ACTION.MORE);

    const recordsToAdd = this.getRecordsToAdd();
    if (this.totalRecords + recordsToAdd > this.ablesungen.length) {
      this.totalRecords = this.ablesungen.length;
    } else {
      this.totalRecords += recordsToAdd;
    }
  }

  onEdit(readingDate: Date) {
    this.edit.emit(readingDate);
  }
}
