<h4 class="re-container-with-margin re-mb-20 re-mt-60 md:re-mb-30 re-ml-20 md:re-ml-auto">
  {{ 'personaldata.banking.title' | translate }}
</h4>
<bdo-edit-card class="re-relative"
  [isEditable]="state !== LoadingState.LOADING && !isInEditMode"
  [disabled]="disabled"
  (edit)="toggleEdit()">
  <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
  <ng-container *ngIf="!isInEditMode; else bankForm">
    <div class="re-summary-row">
      <div>{{ 'personaldata.banking.type' | translate }}</div>
      <div data-qa="titleValue">
        {{ (bankData?.debit ? 'personaldata.banking.debit' : 'personaldata.banking.noDebit') | translate }}
      </div>
    </div>
    <ng-container *ngIf="bankData?.debit; else manual">
      <div class="re-summary-row">
        <div>{{ 'address.personalInfo.name' | translate }}</div>
        <div>{{bankData.debit?.accountholder}}</div>
      </div>
      <div class="re-summary-row">
        <div>{{ 'banking.iban' | translate }}</div>
        <div>{{bankData.debit?.iban}}</div>
      </div>
      <div class="re-summary-row re-mb-0">
        <div>{{ 'personaldata.banking.bankname' | translate }}</div>
        <div>{{bankData.debit?.bankname}}</div>
      </div>
    </ng-container>
    <ng-template #manual>
      <div class="re-mt-30">
        {{ 'personaldata.banking.sepaRecommandation' | translate }}
      </div>
    </ng-template>
    <ng-container *ngIf="bankData?.debit?.iban !== bankData?.credit?.iban">
      <div *ngIf="!bankData?.credit; else differentIban" class="re-mt-30">
        {{ 'personaldata.banking.manualCredit' | translate }}
      </div>
    </ng-container>
  </ng-container>

  <ng-template #differentIban>
    <hr class="re-my-15 re-border-t re-border-monochrome-medium-light">
    <div class="bold re-mt-15 re-mb-15">{{ 'personaldata.banking.credit' | translate }}</div>
    <div class="re-summary-row">
      <div>{{ 'address.personalInfo.name' | translate }}</div>
      <div>{{bankData.credit?.accountholder}}</div>
    </div>
    <div class="re-summary-row">
      <div>{{ 'banking.iban' | translate }}</div>
      <div>{{bankData.credit?.iban}}</div>
    </div>
    <div class="re-summary-row re-mb-0">
      <div>{{ 'personaldata.banking.bankname' | translate }}</div>
      <div>{{bankData.credit?.bankname}}</div>
    </div>
  </ng-template>

  <ng-template #bankForm>
    <bdo-personal-data-banking-form
      [formWithSepaBox]="!bankData?.debit"
      (editDone)="editBankDataDone($event)"
      (cancel)="toggleEdit()"
    ></bdo-personal-data-banking-form>
  </ng-template>

</bdo-edit-card>
