import { Component, OnInit } from '@angular/core';
import { BillingAddressFormData } from '../../../models/billingAddressFormData';
import { HomeData } from '../../../models/home';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { TenantService } from '../../../services/tenant.service';
import { MoveService } from '../../../services/move.service';
import { ExternalTenantConfiguration } from '../../move/move-confirmation/external-tenant-tile/external-tenant-tile.component';
import { TENANT_ACRONYM } from '../../../enums/tenant.enum';
import { Environment } from '../../../../../environments/environment';
import { TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { Utilities } from '../../../../shared/utils/utilities';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'bdo-contract-end-confirmation',
  templateUrl: './contract-end-confirmation.component.html',
  styleUrls: ['./contract-end-confirmation.component.scss']
})
export class ContractEndConfirmationComponent implements OnInit {

  public emailAddress: string;
  public contractEndDate: Date;
  public divisionsForInfo: string;
  public externalTenantConfiguration: ExternalTenantConfiguration;

  constructor(
    private tenantService: TenantService,
    private moveService: MoveService,
    private trackingService: TrackingService,
    private locationStrategy: LocationStrategy
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_END_DONE, TRACKING.ACTION.ENTER);
    this.emailAddress = StorageService.getValue<BillingAddressFormData>(ALLOWED_KEYS.BILLING_ADDRESS)?.email;
    this.contractEndDate = StorageService.getValue<HomeData>(ALLOWED_KEYS.RECENT_HOME_DATA)?.date;
    this.divisionsForInfo = this.moveService.divisionsForInfo();

    const tenantData = this.tenantService.getTenantDataByShort(TENANT_ACRONYM[Environment.tenant].toUpperCase());
    this.externalTenantConfiguration =  {
      changeTenant: false,
      companyName: tenantData?.companyName,
      logo: tenantData?.bannerLogo,
      divisionIconSrc: '',
      divisions: [{
        divisionId: '' as any,
        tariffAdvisorLink: '../anmelden'
      }]
    };

    window.sessionStorage.clear();
    Utilities.disableBrowsersBackButton(this.locationStrategy);
  }

}
