export * from './account.service';
import { AccountService } from './account.service';
export * from './address.service';
import { AddressService } from './address.service';
export * from './anonymous.service';
import { AnonymousService } from './anonymous.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './banking.service';
import { BankingService } from './banking.service';
export * from './billingPlan.service';
import { BillingPlanService } from './billingPlan.service';
export * from './cORS.service';
import { CORSService } from './cORS.service';
export * from './checkMeter.service';
import { CheckMeterService } from './checkMeter.service';
export * from './competition.service';
import { CompetitionService } from './competition.service';
export * from './contact.service';
import { ContactService } from './contact.service';
export * from './contract.service';
import { ContractService } from './contract.service';
export * from './dashboard.service';
import { DashboardService } from './dashboard.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './documents.service';
import { DocumentsService } from './documents.service';
export * from './download.service';
import { DownloadService } from './download.service';
export * from './entities.service';
import { EntitiesService } from './entities.service';
export * from './feedback.service';
import { FeedbackService } from './feedback.service';
export * from './kundenfilter.service';
import { KundenfilterService } from './kundenfilter.service';
export * from './offer.service';
import { OfferService } from './offer.service';
export * from './payments.service';
import { PaymentsService } from './payments.service';
export * from './profiles.service';
import { ProfilesService } from './profiles.service';
export * from './salutation.service';
import { SalutationService } from './salutation.service';
export * from './secure.service';
import { SecureService } from './secure.service';
export * from './status.service';
import { StatusService } from './status.service';
export * from './suppliers.service';
import { SuppliersService } from './suppliers.service';
export * from './tariff.service';
import { TariffService } from './tariff.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './verbrauch.service';
import { VerbrauchService } from './verbrauch.service';
export * from './vouchers.service';
import { VouchersService } from './vouchers.service';
export * from './zaehlerstand.service';
import { ZaehlerstandService } from './zaehlerstand.service';
export const APIS = [AccountService, AddressService, AnonymousService, AuthService, BankingService, BillingPlanService, CORSService, CheckMeterService, CompetitionService, ContactService, ContractService, DashboardService, DefaultService, DocumentsService, DownloadService, EntitiesService, FeedbackService, KundenfilterService, OfferService, PaymentsService, ProfilesService, SalutationService, SecureService, StatusService, SuppliersService, TariffService, UserService, VerbrauchService, VouchersService, ZaehlerstandService];
