<div *ngIf="contract?.contractLevels">

    <div class="bold re-mb-10 re-mt-20">{{ 'contract.details.info.title' | translate }}</div>
    <div class="re-tariff-row">
      <div class="re-relative re-pr-30">
        <span>{{ 'general.tariff.supplyBegin' | translate }}</span>
        <span class="re-absolute re-ml-10">
          <bdo-inline-tooltip>
            <span [innerHTML]="'contract.details.info.supplyBeginHint' |
                               translate: { company: tenantService.getCurrentTenantData().companyName }"></span>
          </bdo-inline-tooltip>
        </span>
      </div>
      <div>{{contract?.deliveryStartDate | date}}</div>
    </div>
    <div class="re-tariff-row">
      <div class="re-relative re-pr-30">
        <span>{{ 'general.tariff.contractBegin' | translate }}</span>
        <span class="re-absolute re-ml-10">
          <bdo-inline-tooltip>
            <span [innerHtml]="'contract.details.info.contractBeginHint' | translate"></span>
          </bdo-inline-tooltip>
        </span>
      </div>
      <div>{{contract?.contractStartDate | date}}</div>
    </div>
    <div class="re-tariff-row" *ngIf="contract?.contractEndDate?.toISOString() !== MAXCONTRACTDATE"> <!-- Max Date Fri Dec 31 9999 -->
      <div class="re-relative re-pr-30">{{ 'general.tariff.supplyEnd' | translate }}</div>
      <div>{{contract?.contractEndDate | date}}</div>
    </div>

    <div class="bold re-mb-10 re-mt-30 md:re-mt-35 lg:re-mt-40">{{ 'contract.details.costs.title' | translate }}</div>

    <div *ngFor="let option of contract.contractLevels">
      <!-- WorkingPrice -->
      <div class="re-tariff-row" *ngIf="getLevel(contract.division, option.contractLevelNumber) === 'basic' && option.workGross !== null">
        <div class="re-relative re-pr-30">
          <span>{{ 'general.tariff.workingPriceInUnit' | translate: { unit: contract?.division | divisionToUnit: 'billing' } }}</span>
          <span class="re-absolute re-ml-10">
            <bdo-inline-tooltip>
              <span [innerHTML]="(contract.division === Verbrauchstyp.Wasser ?
                                 'contract.details.costs.workingPriceHintWater' :
                                 'contract.details.costs.workingPriceHint') | translate"></span>
            </bdo-inline-tooltip>
          </span>
        </div>
        <div>{{ (customerMode === CUSTOMERMODE.PRIV ? option.workGross : option.workNet) | currency: option.isEuro ? 'EUR' : 'Cent'}}</div>
      </div>

      <!-- Options with workingPrice -->
      <div class="re-tariff-row" *ngIf="getLevel(contract.division, option.contractLevelNumber) === 'option' && option.workGross !== null">
        <div>{{ option.description }} {{ 'general.tariff.perKwh' | translate }}</div>
        <div>{{ (customerMode === CUSTOMERMODE.PRIV ? option.workGross : option.workNet) | currency: option.isEuro ? 'EUR' : 'Cent' }}</div>
      </div>
    </div>

    <div *ngFor="let option of contract.contractLevels">
    <!-- BasePrice -->
      <div class="re-tariff-row" *ngIf="getLevel(contract.division, option.contractLevelNumber) === 'basic' && option.baseGross !== null">
        <div class="re-relative re-pr-30">
          <span>{{contract.division === Verbrauchstyp.Wasser ? option.description : ('contract.details.costs.yearlyBasePrice' | translate) }}</span>
          <span class="re-absolute re-ml-10">
            <bdo-inline-tooltip>
              <span [innerHtml]="(contract.division === Verbrauchstyp.Wasser ?
                                 'contract.details.costs.basePriceHintWater' :
                                 'contract.details.costs.basePriceHint') | translate"></span>
            </bdo-inline-tooltip>
          </span>
        </div>
        <div>{{ (customerMode === CUSTOMERMODE.PRIV ? option.baseGross : option.baseNet) | currency: 'EUR'}}</div>
      </div>

      <!-- Options with BasePrice -->
      <div class="re-tariff-row" *ngIf="getLevel(contract.division, option.contractLevelNumber) === 'option' && option.baseGross !== null && option.baseGross > 0">
        <div>{{ option?.description }}</div>
        <div>{{ (customerMode === CUSTOMERMODE.PRIV ? option.baseGross : option.baseNet) | currency: 'EUR'}}</div>
      </div>

      <!-- MeterPrice -->
      <div class="re-tariff-row" *ngIf="getLevel(contract.division, option.contractLevelNumber) === 'meter'">
        <div class="re-relative re-pr-30">
          <span>{{ 'contract.details.costs.meterPrice' | translate }}</span>
          <span class="re-absolute re-ml-10">
            <bdo-inline-tooltip>
              <span [innerHTML]="tariffAdvisorService.getMeterTooltip(customerMode)"></span>
            </bdo-inline-tooltip>
          </span>
        </div>
        <div>{{ (customerMode === CUSTOMERMODE.PRIV ? option.baseGross : option.baseNet) | currency: 'EUR'}}</div>
      </div>
    </div>

    <!-- Price Cap -->
    <ng-container *ngIf="showPriceCap">
      <div class="bold re-mb-10 re-mt-30 md:re-mt-35 lg:re-mt-40">{{ 'general.tariff.priceCap' | translate }}</div>

      <div class="re-tariff-row">
        <div [innerHTML]="'contract.details.costs.workingPriceWithPriceCap' | translate"></div>
        <div>{{priceCapService.getPriceForDivision(contract.division, customerMode) | currency: 'Cent'}}</div>
      </div>
    </ng-container>

    <!-- Mitarbeiterrabatt -->
    <div class="re-tariff-row re-mb-0 re-mt-15" *ngIf="contract?.additionalTariffInformation?.hasMira">
      <div class="re-relative re-pr-30">
        <span>{{ 'general.tariff.employeeDiscount' | translate }}</span>
      </div>
      <div></div>
    </div>

    <div *ngIf="showPriceCap" class="re-text-xs re-mt-10 re-mb-30 md:re-mt-15" id="preisbremse__info"
         [innerHTML]="'contract.details.costs.workingPriceWithPriceCapHint' |
                      translate: { division: contract.division | verbrauchstyp, value: priceCapService.getPriceForDivision(contract.division, customerMode) }">
    </div>

    <div class="re-text-xs re-mb-20 md:re-mb-30">{{ (customerMode === CUSTOMERMODE.PRIV ? 'contract.details.costs.hintBrutto' :
                                                                                          'contract.details.costs.hintNetto') | translate }}</div>

    <div *ngIf="getBasicContractItem(contract?.contractLevels)?.infoText" class="re-text-xs re-mb-20 md:re-mb-30">
      {{ getBasicContractItem(contract.contractLevels).infoText }}
    </div>

    <div class="bold re-mb-10" *ngIf="contract?.additionalTariffInformation?.termString">{{ 'contract.details.termAndPeriod.title' | translate }}</div>
    <div class="re-text-xs re-mb-10 lg:re-mb-25" *ngIf="contract?.additionalTariffInformation?.termString">{{contract?.additionalTariffInformation?.termString}}</div>

    <div class="re-flex re-flex-row re-mb-15" *ngIf="!contract?.isChangeable">
      <svg-icon [applyClass]="true" class="re-text-brand-primary re-w-30 re-h-30" src="assets/icons/system_icons/alert.svg"></svg-icon>
      <div class="re-ml-15" [innerHTML]="'contract.details.termAndPeriod.description' |
                                         translate: { contactLink: tenantService.getCurrentTenantData().hrefContact, accountId: accountId }"></div>
    </div>
</div>
