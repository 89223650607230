import { Component } from '@angular/core';
import { TenantService } from '../../services/tenant.service';
import { TRACKING } from '../../enums/trackingParts.enum';
import { TrackingService } from '../../services/tracking.service';

@Component({
  selector: 'bdo-minimal-footer',
  templateUrl: './minimal-footer.component.html',
  styleUrls: ['./minimal-footer.component.scss']
})
export class MinimalFooterComponent {

  constructor(
    private trackingService: TrackingService,
    public tenantService: TenantService
  ) { }

  trackIt(destination: string) {
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.FOOTER, TRACKING.ACTION.GOTO + destination);
  }

  editCookies() {
    const event = new CustomEvent('openCookies');
    window.dispatchEvent(event);

    this.trackingService.postTracking(TRACKING.LOCATION.FOOTER, TRACKING.ACTION.OPEN, TRACKING.LOCATION.COOKIEBAR);
  }

}
