/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TariffPriceOptions { 
    /**
     * description of tariff option
     */
    name?: string;
    /**
     * base price effect of tariff option
     */
    baseValue?: number;
    /**
     * work price effect of tariff option
     */
    workValue?: number;
}

