import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Validators, AbstractControl, FormGroup, FormControl, UntypedFormGroup } from '@angular/forms';
import { timer, of, combineLatest, Observable, BehaviorSubject, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { CitiesAndStreets, PersonalDataAddressData } from '../../../../assets/js/com/ts_api_client';
import { AutosuggestItem } from '../../../shared/atoms/autosuggest/models/autosuggest-item.model';
import { autosuggestAvailable } from '../../../shared/validators/autosuggest-available-validator';
import { autosuggestMatches } from '../../../shared/validators/autosuggest-matches-validator';
import { defaultValidatorProxy } from '../../../shared/validators/default-validator-proxy';
import { BdoApiService } from '../../services/bdo-api.service';
import { FormFieldIdentifierTracking, TrackingService } from '../../services/tracking.service';
import { flatten as _flatten, isEqual as _isEqual, union as _union } from 'lodash';
import { TRACKING } from '../../enums/trackingParts.enum';
import { DropdownItem } from '../../models/dropdownItem';
import { Utilities } from '../../../shared/utils/utilities';
import { INPUT_TYPE } from '../../enums/inputType.enum';
import { LOADING_STATE } from '../../enums/loadingState.enum';
import { AddressFormtype } from '../../../shared/formtypes/address.formtype';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, OnDestroy {
  @Input() formTracking: FormFieldIdentifierTracking;
  @Input() parentForm: UntypedFormGroup;
  @Input() initialData: PersonalDataAddressData = null;
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public Utilities = Utilities;
  public INPUT_TYPE = INPUT_TYPE;
  public addressIsValid$: Observable<boolean>;
  public citiesAndStreetsCache$: BehaviorSubject<CitiesAndStreets>;
  public streetsCache$: Observable<string[]>;
  public searchString$: Observable<string>;
  public noStreetsAvailable$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public cities: Array<string> = [];
  public citiesDropdown: Array<DropdownItem> = [];
  public forceValidate: boolean = false;

  public form = new FormGroup<AddressFormtype>({
    postCode: new FormControl('', {
      validators: [
        defaultValidatorProxy(Validators.minLength(5), this.translateService.instant('general.validator.minLength', { numberOfCharacters: 5 })),
        defaultValidatorProxy(Validators.maxLength(5), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 5 })),
        defaultValidatorProxy(Validators.required, this.translateService.instant('address.postCode.required')),
      ],
      asyncValidators: [(control) => {
        return timer(500).pipe(
          this.updateCitiesAndStreetsCache(control)
        );
      }]
    }),
    city: new FormControl('', {
      validators: [
        defaultValidatorProxy(Validators.minLength(2), this.translateService.instant('general.validator.minLength', { numberOfCharacters: 2 })),
        defaultValidatorProxy(Validators.maxLength(40), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 40 })),
        defaultValidatorProxy(Validators.required, this.translateService.instant('address.city.required')),
      ],
      asyncValidators: [
        (control) => {
          return timer(500).pipe(
            switchMap(() => this.citiesAndStreetsCache$.pipe(
              first(),
              map((streets) => {
                if (streets?.list?.length === 0 ||
                  // show error, if plz has no streets
                  streets?.list?.length === 1 && streets?.list?.[0].streets === null
                ) {
                  return {
                    error: this.translateService.instant('address.postCode.noStreets')
                  };
                } else {
                  return null;
                }
              })
              )
            )
          );
        }
      ]

    }),
    street: new FormControl('', {
      validators: [
        defaultValidatorProxy(Validators.minLength(2), this.translateService.instant('general.validator.minLength', { numberOfCharacters: 2 })),
        defaultValidatorProxy(Validators.required, this.translateService.instant('address.street.required')),
      ]
    }),
    housenumber: new FormControl('', {
      validators: [
        defaultValidatorProxy(Validators.required, this.translateService.instant('address.housenumber.required')),
      ],
      asyncValidators: [
        (control) => {
          return timer(500).pipe(
            tap({ next: () => {
              if (!this.form.get('city').value) {
                return of(null);
              }
            } }),
            switchMap(() => {
              if (!this.form || !this.form.get('street').value || !control.value) {
                return of(null);
              }
              return this.apiService.validateAddress({
                houseNum: control.value,
                street: {
                  name: this.form.get('street').value,
                  city: {
                    name: this.form.get('city').value,
                    postCode: this.form.get('postCode').value
                  }
                }
              }).pipe(map((addressValidate) => {
                return addressValidate.valid ?
                  null :
                  { notValid: this.translateService.instant('address.housenumber.notFound') };
              }));
            }), catchError((e: unknown) => of({ notValid: this.translateService.instant('general.error.servererror') }))
          );
        }
      ]
    }),
    });
  public selectedCity: string;


  private subscriptions: Subscription = new Subscription();

  constructor(
    private apiService: BdoApiService,
    public translateService: TranslateService,
    private trackingService: TrackingService
  ) {
    this.addressIsValid$ = combineLatest([
      this.form.get('street').statusChanges,
      this.form.get('housenumber').statusChanges,
    ] as string[]).pipe(
      map(([streetStatus, houseNumberStatus]) => {
        return streetStatus === 'VALID' && houseNumberStatus === 'VALID' /* && this.address */;
    }));
   }

  ngOnInit(): void {
    this.searchString$ = this.form.get('street').valueChanges;

    this.citiesAndStreetsCache$ = new BehaviorSubject({ list: [] } as CitiesAndStreets);
    this.streetsCache$ = this.citiesAndStreetsCache$.pipe(
      map(res => {
        // If city value is not yet set, take all streets for now
        if (!this.form.get('city').value) {
          return _union(_flatten(res.list?.map((value) => value.streets)));
        } else {
          return _flatten(
            res.list?.filter(item => item.name === this.form.get('city').value)
             // remove items of plz without streets like 22222 (Hamburg)
            .filter(item => item.streets !== null)
            .map(values => values.streets));
        }
      }),
      filter((streets) => !!streets.length)
    );

    this.subscriptions.add(
      this.citiesAndStreetsCache$.subscribe({ next: res => {
        const list = res.list;
        // Ignore Observable Events that do not change the cities value
        if (_isEqual(this.cities, list?.map(city => city.name))) {
          return;
        }
        this.cities = list?.map(city => city.name);
        if (list?.length === 0) {
          this.form.get('city').setValue('');
          this.form.get('city').disable({ onlySelf: true });
        } else if (list?.length === 1) {
          this.form.get('city').setValue(list[0].name);
          this.form.get('city').disable({ onlySelf: true });
        } else {
          this.form.get('city').enable();
          // Enable Dropdown
          this.citiesDropdown = [];
          this.citiesDropdown = this.cities.map(val => {
            return { label: val, value: val };
          });
          this.form.get('city').setValue(this.initialData ? this.initialData.city : list[0].name);
        }
      } })
    );
    this.subscriptions.add(
      this.form.get('city').valueChanges.subscribe((value) => {
        this.selectedCity = value;
      })
    );

    // set street async Validators
    this.form.get('street').setAsyncValidators([
      autosuggestAvailable(this.noStreetsAvailable$, this.translateService.instant('address.autosuggestNoResults')),
      autosuggestMatches(this.streetsCache$, this.translateService.instant('address.notValid'))
    ]);

    // Listen to address fields changes and revalidate address
    this.subscriptions.add(this.form.get('postCode').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe((() => {
      this.form.get('housenumber').updateValueAndValidity();
      this.form.get('street').updateValueAndValidity();
    })));
    this.subscriptions.add(this.form.get('city').valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(() => {
      this.form.get('housenumber').updateValueAndValidity();
      this.form.get('street').updateValueAndValidity();
    }));

    if (this.parentForm) {
      this.parentForm.addControl('address', this.form);
    }
    if (this.initialData) {
      this.form.setValue(this.initialData);
      this.form.updateValueAndValidity();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onItemSelected(selectedStreet: string) {
    // this.trackAutosuggestSelection(selectedStreet);
    this.form.get('street').setValue(selectedStreet);
    // revalidate housenumber, since the housenumber, that has been entered before might be invalid for the new street
    this.form.get('housenumber').updateValueAndValidity();
  }

  updateCity(selected: string) {
    this.form.get('city').setValue(selected);

    // Update the streetlist according to the selected city
    this.citiesAndStreetsCache$.next(this.citiesAndStreetsCache$.value);
  }

  /**
   * Sort Autosuggest Result:
   * Place the strings that begin with the searchstring first, then the other items from a-z
   * Example:
   * searchstring: su
   * result before sort: asu, bsu, sua, sub
   * result after sort: sua, sub, asu, bsu
   * @param {AutosuggestItem} a
   * @param {AutosuggestItem} b
   * @returns {number}
   */
   public sortAutosuggest = function sortAutosuggest(a: AutosuggestItem, b: AutosuggestItem) {
    if (a.index === 0) { return -1; }
    return 0;
  };

  private updateCitiesAndStreetsCache(control: AbstractControl) {
    return switchMap(() => this.apiService.getStreetsByPostCode(control.value.toString()).pipe(
      catchError((e: unknown) => {
        this.trackingService.postSimpleTracking('EnterPostCode', `${TRACKING.ACTION.FAILURE}`);
        const emptyList: CitiesAndStreets = {
          list: []
        };
        return of(emptyList);
      }),
      tap({ next: (val) => {
          this.citiesAndStreetsCache$.next(val);
        } }),
      map(val => {
        if (val?.list?.length === 0 || !val?.list?.[0]?.streets) {
          // Postcodes without streets are not relevant for us
          return { notValid: this.translateService.instant('address.postCode.error') };
        } else {
          return null;
        }
      }),
      tap({ next: () => this.form.get('city').updateValueAndValidity() })
    ));
  }

}
