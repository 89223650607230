
<h3 class="bdo-container re-mb-30">{{title}}</h3>
<div class="bdo-container--single">
  <div class="bdo-tile bdo-loading__wrapper" [class.bdo-loading__wrapper--height]="state === LoadingState.LOADING" *ngIf="state === LoadingState.LOADING">
    <bdo-loading></bdo-loading>
  </div>
  <div class="re-copytext" *ngIf="state === LoadingState.IDLE">
    {{ 'emailVerification.success' | translate}}
  </div>
  <bdo-info-teaser *ngIf="state === LoadingState.ERROR"
    [title]="'emailVerification.title.error' | translate"
    [iconSrc]="'offer.status.notAvailable.iconSrc' | translate"
    [hasButton]="false">
    <div [innerHTML]="'emailVerification.error' | translate"></div>
  </bdo-info-teaser>

  <div class="re-mt-30">
    <bdo-text-link-icon
      [iconPosition]="'left'"
      [linkText]="((loginService.isLoggedIn$ | async) ? 'personaldata.linkTo' : 'general.navigation.backToLogin' ) | translate"
      [routerUrl]="(loginService.isLoggedIn$ | async) ? '/daten' : '/login' "
    ></bdo-text-link-icon>
  </div>


</div>

