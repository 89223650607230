import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { ContractPaymentData } from '../../../../../assets/js/com/ts_api_client';
import { StorageService, ALLOWED_KEYS } from '../../../services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormFieldIdentifierTracking, TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { PaymentParentFormtype } from '../../../../shared/formtypes/payment-parent.formtype';

@Component({
  selector: 'bdo-contract-new-payment',
  templateUrl: './contract-new-payment.component.html',
  styleUrls: ['./contract-new-payment.component.scss']
})
export class ContractNewPaymentComponent implements OnInit {
  public trackingData: FormFieldIdentifierTracking = {
    orca_form_name: TRACKING.LOCATION.CONTRACT_NEW_BILLING,
    orca_field_section: 'Abweichende Zahlungsweise'
  };

  paymentNewContractForm = new FormGroup<PaymentParentFormtype>({
    paymentOptions: new FormControl('keepPayment', { validators: [Validators.required] }),
    paymentMethod: new FormControl(null)
  });
  constructor(
    public location: Location,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_NEW_BILLING, TRACKING.ACTION.ENTER);
  }

  save(): void {
    if (this.paymentNewContractForm.invalid) {
      // mark all input fields as touched to display validation info
      this.paymentNewContractForm.markAllAsTouched();
    } else {

      const formData: ContractPaymentData = {};
      Object.keys(this.paymentNewContractForm.controls).forEach(key => {
        formData[key] = this.paymentNewContractForm.controls[key].value;
      });

      StorageService.setValue(ALLOWED_KEYS.PAYMENT_DATA, formData);
      this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_NEW_BILLING, TRACKING.ACTION.GOTO, 'Zusammenfassung');

      this.router.navigate(['../uebersicht'], {
        relativeTo: this.activatedRoute
      });
    }
  }
}
