import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

/**
 * Additional Validator to check for the domain in an email-address
 * Use alongside the default HTML email validator
 * @param {string} customErrorMessage
 * @returns {(control: AbstractControl) => {[p: string]: string}}
 */
export function externalEmailValidator(translateService: TranslateService, errorText?: string) {
  return (control: AbstractControl): { [key: string]: string | true } | null => {
    if (!control.value) {
      return null;
    }

    errorText = errorText ? errorText : translateService.instant('address.email.invalid');
    const regex = RegExp(/.+\@[^\.]+\..+/gm);

    if (!regex.test(control?.value)) {
      return { ['externalEmail']: (errorText ? errorText : true) };
    }
    return null;
  };
}
