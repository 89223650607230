import { animate, state, style, transition, trigger } from '@angular/animations';

export const SlideInAnimation = [
  trigger('slideIn', [
    state('in', style({ transform: 'translateX(100%)' })),
    state('out', style({ transform: 'translateX(0)' })),
    transition(':enter', [
      style({ transform: 'translateX(100%)' }),
      animate('400ms ease-out')
    ])
  ])
];
