import { Component, Input, HostListener, Renderer2 } from '@angular/core';
import { TRACKING } from '../../../bdo/enums/trackingParts.enum';
import { TrackingService } from '../../../bdo/services/tracking.service';
import { SlideInAnimation } from '../../animations/slide-in.animation';

@Component({
  selector: 'bdo-slide-over',
  templateUrl: './slide-over.component.html',
  styleUrls: ['./slide-over.component.scss'],
  animations: [ SlideInAnimation ]
})
export class SlideOverComponent {

  @Input() isVisible: boolean;
  @Input() slideOverHeadline: string;
  @Input() slideOverText: string;
  @Input() slideOverTelephoneNumber: string;


  constructor(
    private renderer: Renderer2,
    private trackingService: TrackingService
  ) { }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      if (this.isVisible) {
        this.closeSlideOver(event, true);
      }
    }
  }
  public onPhoneSelection() {
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.PHONE_MODAL, TRACKING.ACTION.CLICK, this.slideOverTelephoneNumber);
  }

  openSlideOver() {
    this.isVisible = true;
    this.renderer.addClass(document.body, 'popupOpen');
    this.trackingService.postTracking(TRACKING.LOCATION.PHONE_MODAL, TRACKING.ACTION.OPEN);
  }

  closeSlideOver(event, force: boolean = false) {
    if (event.target !== event.currentTarget && !force) {
      return;
    }
    this.isVisible = false;
    this.renderer.removeClass(document.body, 'popupOpen');
    this.trackingService.postTracking(TRACKING.LOCATION.PHONE_MODAL, TRACKING.ACTION.CLOSE);
  }

}
