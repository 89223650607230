import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, first, forkJoin, map, switchMap } from 'rxjs';
import { LoginService } from '../../../login/login.service';
import { TRACKING } from '../../enums/trackingParts.enum';
import { LinkData } from '../../models/LinkData';
import { TenantService } from '../../services/tenant.service';
import { TrackingService } from '../../services/tracking.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-vebout-existing',
  templateUrl: './vebout-existing.component.html',
  styleUrls: ['./vebout-existing.component.scss']
})
export class VeboutExistingComponent implements OnInit, OnDestroy {
  linkData: LinkData;
  private subscription: Subscription;

  constructor(
    public tenantService: TenantService,
    private translateService: TranslateService,
    private loginService: LoginService,
    private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.VEBOUT_EXISTING, TRACKING.ACTION.ENTER);

    this.subscription = this.loginService.isLoggedIn$.pipe(
      switchMap((loggedIn) => {
        if (loggedIn) {
          const href: string = '/dashboard';
          return this.translateService.stream('dashboard.linkTo').pipe(map(translation => [translation, href]));
        } else {
          const href: string = this.tenantService.getCurrentTenantData().hrefHomepage;
          return this.translateService.stream('general.navigation.backToHomepage').pipe(map(translation => [translation, href]));
        }
      })
    ).subscribe({
      next: ([translation, href]) => {
        this.linkData = {
          href,
          linkName: translation
        };
      }
    });
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }
}
