<div class="re-tariff-row">
  <div class="re-relative re-pr-30">
    <span>{{ description }}</span>
    <span class="re-absolute re-ml-10">
      <bdo-inline-tooltip data-name="working-price-info">
        <span [innerHtml]="textInfoI"></span>
      </bdo-inline-tooltip>
    </span>
  </div>
  <div>
    × {{ price | currency: 'Cent' }}
  </div>
</div>
