/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * JSON Model zum BDO - Zaehlwerk Objekt.
 */
export interface Kundenfilter { 
    kannAngewendetWerden?: boolean;
}

