import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { Zaehlerstand, CheckIdentificationData, Zaehlwerk } from '../../../../../assets/js/com/ts_api_client';
import { TenantService } from '../../../services/tenant.service';
import { ZaehlerstandValidatorDirective } from '../../../../shared/validators/zaehlerstand-validator.directive';
import { FormValidationError } from '../../../models/formValidationError';
import { INPUT_TYPE } from '../../../enums/inputType.enum';
import { MeterreadingInfoService } from '../../../services/meterreading-info.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-anonymous-meter-reading-form',
  templateUrl: './anonymous-meter-reading-form.component.html',
  styleUrls: ['./anonymous-meter-reading-form.component.scss']
})
export class AnonymousMeterReadingFormComponent implements OnChanges {
  @Input() meterreading: Zaehlerstand;
  @Input() identificationData: CheckIdentificationData;
  @Input() implausible: boolean = false;
  @Output() anonymousMeterDataSubmitted: EventEmitter<Zaehlerstand> = new EventEmitter();
  INPUT_TYPE = INPUT_TYPE;
  validateAll = false;
  validationIconHidden: boolean = false;
  zaehlerValidator: ZaehlerstandValidatorDirective = new ZaehlerstandValidatorDirective(this.translateService);
  readingExists: boolean = false;

  constructor(
    private translateService: TranslateService,
    public tenantService: TenantService,
    public meterreadingInfoService: MeterreadingInfoService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.meterreading && changes.meterreading.currentValue) {
      const meterreading = changes.meterreading.currentValue as Zaehlerstand;
      this.readingExists = meterreading.zaehlwerkeListe && !!meterreading.zaehlwerkeListe.length && !!meterreading.zaehlwerkeListe[0].wert;
    }
  }

  submit(form: NgForm) {
    if (form.valid) {
      this.anonymousMeterDataSubmitted.emit(this.meterreading);
      this.validationIconHidden = true;
    } else {
      this.validateAll = true;
    }
  }

  onChange() {
    this.validationIconHidden = false;
  }

  updateMeterValue(object: Zaehlwerk, value: string) {
    object.wert = parseInt(value, 10);
    this.onChange();
  }

  public getMeterreadingValidationErrors(zaehlwerk: Zaehlwerk): Array<FormValidationError> {
    const value = zaehlwerk.wert ? zaehlwerk.wert.toString() : '';
    return [
      new FormValidationError('required', this.translateService.instant('meterreadings.input.meterreadingRequired')),
      new FormValidationError('pattern', this.translateService.instant('meterreadings.input.validNumberRequired'), !this.isValidNumber(value)),
      new FormValidationError('zaehlerstand', this.validateMeter(value) ? this.validateMeter(value).zaehlerstand : '', this.validateMeter(value))
    ];
  }

  validateMeter(value: string): any {
    if (value) {
      this.zaehlerValidator.sparte = this.meterreading.sparte;
    }
    return this.zaehlerValidator.validate(new FormControl<string>(value));
  }

  isValidNumber(value: string): boolean {
    if (!value || value.length < 1) {
      return true;
    }
    try {
      return !!value.match(/^\d+(,\d+)*$/);
    } catch (error) {
      return true;
    }
  }

}
