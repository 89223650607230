<div class="lg:re-min-h-350">
  <h4 [ngClass]="{'re-hidden': activeQuestion !== 0}" class="re-text-m">{{ 'feedback.dialog.title' | translate }}</h4>

  <div *ngIf="activeQuestion < maxQuestions && poll; else thanks">
    <div class="re-mb-10 re-mt-20">
      {{ 'satisfactionPoll.questionNumber' | translate:
         { currentQuestion: (activeQuestion + 1).toString(), maxQuestion: maxQuestions.toString() } }}
    </div>

    <bdo-poll-question
      *ngIf="activeQuestion >= 0"
      [questionData]="poll?.questions[activeQuestion]"
      (sendAnswer)="onQuestionComplete($event)"
    ></bdo-poll-question>
  </div>
  <ng-template #thanks>
    <!-- make sure, that the bdo-poll-done doesn't flash up while loading the poll -->
    <bdo-poll-done *ngIf="activeQuestion !== 0" />
  </ng-template>
</div>
