import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl, FormGroup,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { BdoApiService } from '../../../bdo/services/bdo-api.service';
import { CustomerStoreService } from '../../../bdo/services/customer-store.service';
import { defaultValidatorProxy } from '../../validators/default-validator-proxy';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { BankName } from '../../../../assets/js/com/ts_api_client';
import { FormFieldIdentifierTracking } from '../../../bdo/services/tracking.service';
import { BankaccountFormtype } from '../../formtypes/bankaccount.formtype';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-bank-account-form',
  templateUrl: './bank-account-form.component.html'
})
export class BankAccountFormComponent implements OnInit {

  @Input() parentForm: UntypedFormGroup;
  @Input() validationIconHidden: boolean = false;
  @Input() formTracking: FormFieldIdentifierTracking;

  form = new FormGroup<BankaccountFormtype>({
    bankname: new FormControl(null),
    accountholder: new FormControl(null, {
      validators: [defaultValidatorProxy(Validators.required, this.translateService.instant('banking.accountholderRequired'))]
    }),
    iban: new FormControl(null, {
      validators: [
        defaultValidatorProxy(Validators.required, this.translateService.instant('banking.ibanInvalid')),
        defaultValidatorProxy(Validators.minLength(15), this.translateService.instant('banking.ibanInvalid')),
        defaultValidatorProxy(Validators.maxLength(34), this.translateService.instant('banking.ibanInvalid'))
      ],
      asyncValidators: [this.ibanValidator.bind(this)]
    }),
  });

  constructor(
    public customerStore: CustomerStoreService,
    private apiService: BdoApiService,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    if (this.parentForm.get('bankData')) {
      this.parentForm.removeControl('bankData');
    }
    this.parentForm.addControl('bankData', this.form);
  }

  /**
   * Custom validator: Checks an Iban and sets the bankname if IBAN is valid
   *
   * @param control
   */
  ibanValidator(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    this.form.get('bankname').setValue('');
    return timer(1000).pipe(switchMap(() => {
      return !control.value ? null : this.apiService.getBankNameFromIBAN(control.value).pipe(
        map((bankname: BankName) => bankname.bankname ? this.setupBankName(bankname) : { 'iban': this.translateService.instant('banking.ibanInvalid') }),
        catchError( (err: unknown) => of({ 'iban': this.translateService.instant('general.error.servererror') }) )
      );
    }));
  }

  /**
   * set bankname
   *
   * @param bankname
   */
  setupBankName(bankname: BankName) {
    this.form.get('bankname').setValue(bankname.bankname);
    return null;
  }
}
