<form [formGroup]="form">
  <div class="re-bg-white re-w-full re-px-30 re-py-20 re-text re-mb-3
                md:re-w-600 md:re-mx-auto lg:re-w-900">
    <div class="re-inline">
          {{ 'general.customerData.meternumber' | translate }}
    </div>
    <div class="re-inline bold">{{meter?.meterNumber}}</div>
  </div>
  <div class="re-bg-white re-w-full re-px-30 re-py-20 re-text re-mb-3 md:re-w-600
              md:re-mx-auto lg:re-w-900">
    <div *ngIf="!isExistingMeterreading">
      <div [ngClass]="{'re-text-error' : invalidText}" class="bdo-container__content">
        <div class="bdo-space--bottom"
            [innerHtml]="'siminvoice.readingNeeded' | translate">
        </div>
        <button class="bdo-space--bottom re-button re-secondary"
                (click)="toMeterreadingsAdd()"
                [attr.data-qa]="'button-meterreadingAdd-' + meter?.meterNumber">
                {{ 'meterreadings.actions.enterMeterreading' | translate }}
        </button>
      </div>
    </div>
    <div *ngIf="isExistingMeterreading">
      <div class="re-form-item-container">
        <div class="re-mb-5">
             {{ 'meterreadings.meterreading' | translate }}
        </div>
        <bdo-input formControlName="firstZaehlwerkValue"
                   [help]="'siminvoice.readingAlreadyExist' | translate"
                   [formControl]="$any(form.get('firstZaehlwerkValue'))">
        </bdo-input>
        <div *ngIf="meterreading?.zaehlwerkeListe.length === 2" class="re-mt-15">
          <div class="re-mb-5">
            {{ 'meterreadings.meterreading' | translate }}
          </div>
          <bdo-input formControlName="secondZaehlwerkValue"
                     [help]="'siminvoice.readingAlreadyExist' | translate"
                     [formControl]="$any(form.get('secondZaehlwerkValue'))">
          </bdo-input>
        </div>
      </div>
    </div>
  </div>
</form>
