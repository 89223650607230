<div class="re-container-headline re-mb-30 lg:re-mb-60">
  <h3>{{ 'payment.title' | translate }}</h3>
</div>

<form (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" bdoScrollToInvalidControl [formGroup]="paymentNewContractForm">
  <div class="re-bg-white re-w-full re-p-20 re-pt-30 re-pb-30 re-mb-45
              md:re-w-600 md:re-pt-45 md:re-pb-45 md:re-mb-60 md:re-mx-auto
              lg:re-w-900">

    <div class="re-form-item-container re-mb-30">
      <p>{{ 'payment.futureInvoice.description' | translate }}</p>
    </div>

    <bdo-payment-form
      [paymentForm]="paymentNewContractForm"
      [trackingSepaForm]="trackingData"
    ></bdo-payment-form>

    <div class="re-form-item-container">
      <p class="bold re-mb-10 re-mt-25">{{ 'payment.futureInvoice.hintTitle' | translate }}</p>
      <p>{{ 'payment.hintText' | translate }}</p>
    </div>
  </div>

  <div class="re-w-full
              md:re-w-600 md:re-mx-auto
              lg:re-w-900">
    <div class="md:re-pl-0 re-pl-15">
      <bdo-validate-submit
        [textButtonLong]="'summary.linkTo' | translate"
      ></bdo-validate-submit>
      <div class="re-pt-20">
        <bdo-text-icon
         [iconPosition]="'left'"
         [text]="'general.action.back' | translate"
         (itemClicked)="location.back()"
        >
        </bdo-text-icon>
      </div>
    </div>
  </div>
</form>
