<div class="bdo-container re-mb-30 md:re-mb-60">
  <h3>{{ 'consumptionHistory.title' | translate }}</h3>
</div>
<div class="bdo-container--single">
  <div class="re-mb-30 md:re-mb-45 re-bdo-tile">
    <bdo-accountid-with-address></bdo-accountid-with-address>
  </div>

  <div *ngIf="!selectedActiveContract && !selectedInactiveContract && !isLoading">
    <p>{{ 'general.error.noDataFound' | translate }}</p>
  </div>

  <div *ngIf="selectedActiveContract || isLoading" class="bdo-loading__wrapper">
    <bdo-loading *ngIf="isLoading"></bdo-loading>

    <div>
      <h4>{{ 'consumptionHistory.active' | translate }}</h4>
      <div>
        <bdo-filtered-content
          [filters]="contentFilterActive"
          [selected]="selectedActiveContract"
          (filterSelected)="updateSelectedContract($event)"
        ></bdo-filtered-content>
      </div>
      <bdo-historie
        *ngIf="selectedActiveContract"
        [contractIdentifiers]="vertragsIdentifiers"
        [vertragNummer]="selectedActiveContract"
      ></bdo-historie>
    </div>
  </div>

  <div *ngIf="selectedInactiveContract" class="bdo-container--single">

    <h4>{{ 'consumptionHistory.inactive' | translate }}</h4>
    <div>
      <bdo-filtered-content
        [filters]="contentFilterInactive"
        [selected]="selectedInactiveContract"
        (filterSelected)="updateSelectedContract($event, true)"
      ></bdo-filtered-content>
    </div>

    <bdo-historie
      *ngIf="selectedInactiveContract"
      [contractIdentifiers]="vertragsIdentifiers"
      [vertragNummer]="selectedInactiveContract"
    ></bdo-historie>
  </div>
</div>

<div class="bdo-container">
  <bdo-section-headline>
    <div class="re-mb-default">
      {{ 'consumptionHistory.relatedTopics.title' | translate }}
    </div>
  </bdo-section-headline>
</div>

<bdo-promo-link-wrapper [promoLinkDataSet]="promoLinkDataSet"></bdo-promo-link-wrapper>

