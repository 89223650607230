import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { LoginService } from '../login.service';
import { Router } from '@angular/router';
import { DebugLogger } from '../../shared/utils/debugLogger';

@Injectable()
/**
 * Protects the Application against unauthorized Access by redirecting to the Login Page if no or invalid Token was given
 */
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private router: Router
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = req.headers.get('Authorization');
    if (token) {
      return this.handleAuthRequest(req, next);
    } else {
      // skip this interceptor if the request is not authenticated
      return next.handle(req);
    }
  }

  private handleAuthRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: unknown) => this.handleError(error))
    ) as any;
  }

  private handleError(error: any): Observable<HttpEvent<any>> {
    switch (parseInt(error.status)) {
      case HttpStatusCode.Unauthorized:
        DebugLogger.debug('AuthInterceptor', 'handleError', 'Unauthorized - redirect to login');
        this.loginService.logout().subscribe({
          next: () => this.loginService.redirectToLoginPage()
        });
        break;
      case HttpStatusCode.Forbidden:
        DebugLogger.debug('AuthInterceptor', 'handleError', 'Forbidden - redirect to dashboard');
        void this.router.navigate(['/dashboard']);
        break;
      default:
        // we should implement some kind of error handling here i.E. an orca-error-page
    }
    return throwError(error);
  }

}
