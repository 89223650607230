import { Component, EventEmitter, Input, Output } from '@angular/core';
/**
 * dumb component to show a message that the os was removed
 */
@Component({
  selector: 'bdo-online-service-removed-message',
  templateUrl: './online-service-removed-message.component.html',
  styleUrls: ['./online-service-removed-message.component.scss']
})
export class OnlineServiceRemovedMessageComponent {

  @Input() showMessage: boolean = false;
  @Input() readonlyHasStarted: boolean = false;
  @Output() closeMessage = new EventEmitter<void>();

  constructor() {}

  public onClose(){
    this.closeMessage.emit();
  }
}
