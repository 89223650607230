<form [formGroup]="situationForm" (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" bdoScrollToInvalidControl novalidate>

  <div class="re-bg-white re-w-full re-p-20 re-pt-30 re-pb-45 re-mb-30
              md:re-w-600 md:re-pt-45 md:re-pb-60 md:re-mx-auto
              lg:re-w-900">
    <fieldset class="re-mb-0">
      <div [attr.data-qa]="'termination'" class="re-mb-45" *ngIf="!isStateMove() && (!isReacquisition || offerContext === null)">
        <div class="bold re-mb-30
                    md:re-mb-20 md:re-text-base md:re-leading-2xl">
          {{ 'delivery.situation.terminateTitle' | translate: { division: supply } }}
        </div>

        <div class="re-flex re-items-start re-relative re-mb-20">
          <input id="auto" type="radio" class="" value="auto" name="termination" formControlName="termination"
                 data-qa="radio-termination-auto">
          <label class="re-ml-15" for="auto">
            {{ 'delivery.situation.withCancel' | translate:
               { companyName: tenantService.getCurrentTenantData().companyName, division: supply } }}
          </label>
        </div>

        <div class="re-flex re-items-start re-relative">
          <input id="self" type="radio" class="" value="self" name="termination" formControlName="termination"
                 data-qa="radio-termination-self">
          <label class="re-ml-15" for="self">{{ 'delivery.situation.alreadyCanceled' | translate:
                                                { division: supply } }}
          </label>
        </div>
      </div>
      <div class="md:re-text-base md:re-leading-2xl">
        <div class="bold re-mb-30"> {{ 'general.customerData.yourMeterWithDivision' | translate: { division: supply } }} </div>
      </div>
      <div class="re-mt-20 re-mb-45">
        <bdo-meter-number [parentForm]="situationForm"
						  [withRegisters]="showRegisterInputs"
						  [deliveryType]="deliveryType"
						  [readingsOptional]="true"
						  [sparte]="divisionId"
						  [storedMeterData]="storedMeterData"
						  [address]="address"
						  (meterCheckResult)="onMeterCheckResult($event)">
        </bdo-meter-number>
      </div>
      <div class="re-relative">
        <div>
          <div class="bold re-mb-30 md:re-text-base md:re-leading-2xl">
            {{ 'delivery.situation.details.title' | translate }}
          </div>

          <bdo-date-picker
            [selectedDate]="situationForm?.get('startDate').value"
            (dateSelected)="updateDate($event, true)"
            [minDateForChanges]="minDate"
            [maxDateForChanges]="maxDate"
            [noPreselection]="true"
            [labelText]="'delivery.situation.details.startDate' | translate"
            [tooltipName]="'delivery-date-info'"
            [tooltipContent]="'delivery.situation.details.startDateHint' | translate"
            [attr.data-qa_mindateforchanges]="minDate"
            [attr.data-qa_selecteddate]="situationForm?.get('startDate').value"
            >
          </bdo-date-picker>

          <!-- Autosuggest  -->
          <div class="re-mt-20 re-relative" *ngIf="!isStateMove() && situationForm.get('previousSupplier') && (situationForm.get('termination')?.value === 'auto' || isReacquisition)">
            <bdo-input [formControl]="$any(situationForm.get('previousSupplier'))"
                       ngDefaultControl
                       [inputType]="inputTypeMappings.previousSupplier"
                       [validationIconHidden]="state === LoadingState.ERROR"
                       [labelText]="'delivery.situation.details.previousSupplier' | translate"
                       (blur)="trackInvalid('previousSupplier')"
                       [enableAutocomplete]="true"
                       #previousSupplier>
            </bdo-input>
            <span class="re-absolute re-top-0 re-right-0" *ngIf="isRE">
              <bdo-inline-tooltip>
                {{ 'delivery.situation.details.previousSupplierInfo' | translate}}
              </bdo-inline-tooltip>
            </span>

            <bdo-autosuggest
              [dataSource$]="suppliers$"
              [searchString$]="searchString$"
              [for]="previousSupplier"
              [sort]="sortAutosuggest"
              (noItemsAvailable)="noSupplierAvailable$.next($event)"
              (itemSelected)="onItemSelected($event)">
            </bdo-autosuggest>
          </div>
          <div class="re-mt-45" [formGroup]="situationForm" *ngIf="isReacquisition">
            <bdo-checkbox
              [checked]="situationForm.get('revocationWanted')?.value"
              (change)="situationForm.get('revocationWanted')?.setValue(!situationForm.get('revocationWanted')?.value)"
              data-qa="revocationWanted">
              <p [innerHTML]="'delivery.situation.revocationAutomatic' | translate"></p>
            </bdo-checkbox>
          </div>
        </div>
      </div>
    </fieldset>
  </div>


  <div class="re-mx-20
              md:re-w-600 md:re-mx-auto
              lg:re-w-900">
    <bdo-validate-submit
      [isContextSummary]="context === CONTEXT_FROM_SUMMARY"
      [textButtonLong]="'wizard.button.payment' | translate"
    ></bdo-validate-submit>
  </div>

</form>
