<ol *ngIf="breadcrumbs?.length > 0 && breadcrumbs[0].url !== '/dashboard' && !isDisabled && (isLoggedIn$ | async)"
  class="re-list-none re-p-15 re-inline-block lg:re-bg-white lg:re-mx-30">
  <li class="re-inline bold">
    <a [routerLink]="'/dashboard'" class="hover:re-text-secondary-hover">
      {{ 'breadcrumb.dashboard' | translate }}
    </a>
    <span>&nbsp;/&nbsp;</span>
  </li>

  <li *ngFor="let breadcrumb of breadcrumbs; let last = last"
    class="re-inline">
    <a [routerLink]="breadcrumb.url" *ngIf="!last" class="hover:re-text-secondary-hover bold">
      {{ breadcrumb?.label | translate }}
    </a>
    <span *ngIf="!last" class="bold">&nbsp;/&nbsp;</span>

    <span *ngIf="last">
      {{ breadcrumb?.label | translate }}
    </span>
  </li>
</ol>
