<button *ngFor="let item of filters"
  class="bdo-tab-button re-mr-10"
  [ngClass]="{'md:re-mr-20' : item.title, 're-mr-20' : !item.iconSrc}"
  [class.active]="item.id === selected"
  [title]="item.title || item.id"
  (click)="onSelect(item.id)"
>
  <div [ngClass]="{'md:re-p-5 md:re-pr-10' : item.iconSrc && item.title}"
    [class.re-p-10]="item.iconSrc">
    <svg-icon *ngIf="item.iconSrc"
      class="icon re-w-25 re-h-25 re-inline-block"
      [ngClass]="{'md:re-mr-5' : item.title}"
      [applyClass]="true"
      [src]="item.iconSrc"
      ></svg-icon>

    <div *ngIf="item.title"
      class="re-inline-block re-leading-m"
      [ngClass]="{'re-hidden md:re-inline-block md:re-pr-10' : item.iconSrc}"
    >
      {{item.title}}
    </div>
  </div>
</button>
