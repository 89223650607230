import { Input, Component } from '@angular/core';
import { TenantService } from '../../../bdo/services/tenant.service';
import { TrackingService } from '../../../bdo/services/tracking.service';

@Component({
  selector: 'bdo-home-link',
  templateUrl: './home-link.component.html',
  styleUrls: ['./home-link.component.scss']
})
export class HomeLinkComponent {
  @Input() tracking: Array<string> = [];

  constructor(
    public tenantService: TenantService,
    private trackingService: TrackingService
  ) { }

  onClick() {
    if (this.tracking.length > 0) {
      this.trackingService.postSimpleTracking(...this.tracking);
    }
  }

}
