import { Component, EventEmitter, Output, OnInit } from '@angular/core';

@Component({
  selector: 'bdo-poll-frame',
  templateUrl: './poll-frame.component.html',
  styleUrl: './poll-frame.component.scss'
})
export class PollFrameComponent{
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();

  onClose() {
    this.closeEvent.emit(true);
  }
}
