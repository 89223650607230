<form [formGroup]="paymentForm" (ngSubmit)="onSubmit()" class="re-relative">
  <div class="bdo-container--single">
    <div [class.re-mb-15]="state === LoadingState.ERROR">
      <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">
        {{ 'general.error.error' | translate }}
      </bdo-info-box>
    </div>
  </div>
  <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>

  <div class="re-form-item-container">
    {{ 'personaldata.banking.sepaInfo' | translate }}
  </div>

  <fieldset class="re-mb-0" *ngIf="state !== LoadingState.LOADING">
    <!-- Only require sepa checkbox if not set before -->
    <bdo-bank-account-form *ngIf="!formWithSepaBox; else withCheckbox" [parentForm]="paymentForm"></bdo-bank-account-form>
    <ng-template #withCheckbox>
      <bdo-sepa-form #sepaForm
        [parentForm]="paymentForm"
        [sepaRequired]="true"
      ></bdo-sepa-form>
    </ng-template>

    <div class="re-form-item-container bdo-space--bottom re-mt-25 re-mb-15">
      <button class="bdo-button-primary bdo-button--text" data-qa="submit" type="submit">
        {{ 'general.action.save' | translate }}
      </button>
    </div>

    <button type="button" class="bdo-nolink" (click)="cancel.emit()">
      <div class="bold">{{ 'general.action.cancel' | translate }}</div>
    </button>

  </fieldset>
</form>
