import { Component, Input } from '@angular/core';

@Component({
  selector: 'bdo-billing-consumption-tile',
  templateUrl: './billing-consumption-tile.component.html',
  styleUrls: ['./billing-consumption-tile.component.scss']
})
export class BillingConsumptionTileComponent{
  @Input() accountId: string;

  constructor( ) {}

}
