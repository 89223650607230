<div [@fadeIn]>

  <div class="re-bg-white re-w-full re-px-20 re-py-20 md:re-px-30 md:re-py-25 re-text re-mb-3
              md:re-w-600 md:re-mx-auto
              lg:re-w-900"
       [attr.data-qa]="'onlyExternalAreas'"
       *ngIf="onlyExternalAreas || noAvailableDivisions; else divisions">
    <h4>
      {{ 'move.productSelection.notAvailable.title' | translate }}
    </h4>
    <div *ngIf="onlyExternalAreas; else noAvailableDivisionsText"
         class="re-text-sm re-leading-m md:re-text-base md:re-leading-2xl re-mt-30"
         [innerHTML]="(abortIfNoDivisions ? 'move.productSelection.notAvailable.descriptionOtherTenantWithoutOS' :
                     'move.productSelection.notAvailable.descriptionOtherTenant') | translate:
                     { linkTenant: tenantService.getTenantDataByShort(availableDivisions[0].tenantNameShort)?.tariffAdvisorLinks[availableDivisions[0]?.divisionId], companyName: tenantNameOfArea }">
    </div>
    <ng-template #noAvailableDivisionsText>
      <div class="re-text-sm re-leading-m md:re-text-base md:re-leading-2xl re-mt-30"
           [innerHTML]="(abortIfNoDivisions ? 'move.productSelection.notAvailable.description' :
                        'move.productSelection.notAvailable.descriptionNoContinue') | translate">
      </div>
    </ng-template>
    <div *ngIf="!abortIfNoDivisions">
      <div class="re-text-sm re-leading-m md:re-text-base md:re-leading-2xl"></div>
      <div class="bold e-text-sm re-leading-m md:re-text-base md:re-leading-2xl re-my-15">{{ 'move.productSelection.notAvailable.next.title' | translate }}</div>
      <ul class="bdo-list__bullet_bigger re-mt-30 re-text-sm re-leading-m md:re-text-base md:re-leading-2xl">
        <li *ngIf="moveService.isAnonymousButRegistered() || (loginService.isLoggedIn$ | async)">
          {{ 'move.productSelection.notAvailable.next.info1' | translate:
             { companyName: tenantService.getCurrentTenantData().companyName } }}
        </li>
        <li *ngIf="moveService.divisionsForInfo()">
          {{ 'move.productSelection.notAvailable.next.info2' | translate:
             { division: moveService.divisionsForInfo() } }}
        </li>
      </ul>
    </div>
  </div>
  <ng-template #divisions>

    <div class="re-container-with-margin re-mt-60 re-ml-20 re-mb-30 md:re-ml-auto">
      <h4 >
        {{ 'contract.new.newHome.products.title' | translate }}
      </h4>
      <div class="re-text-sm re-leading-m md:re-text-base md:re-leading-2xl re-mt-15">
        {{ (isContextContractNew ? 'contract.new.newHome.products.description' : 'move.newHome.products.description') | translate }}
      </div>
      <div class="re-text-sm re-leading-m md:re-text-base md:re-leading-2xl re-mt-15">
        {{ 'move.newHome.products.description2' | translate }}
      </div>

      <bdo-info-box *ngIf="!allUnavailableOrExternal && !getAtLeastOneSelected() && forceValidateDate"
        class="re-mt-30 re-block"
        [type]="'error'"
      > {{ 'contract.new.newHome.products.required' | translate }} </bdo-info-box>
    </div>

    <div class="re-mb-45 md:re-mb-60">
      <!-- show checkable divisions for all available divisions -->
      <ng-container *ngFor="let availableDivision of availableDivisions">
        <div class="re-bg-white re-w-full re-px-20 re-py-20 md:re-px-30 md:re-py-25 re-text re-mb-3
                md:re-w-600 md:re-mx-auto
                lg:re-w-900" [attr.data-qa]="availableDivision.divisionId + 'Section'"
             [class.md:re-pb-15]="productForm.get(availableDivision.divisionId).get('isChecked').value"
        >
          <div *ngIf="!availableDivision.areaBelongsToCurrentTenant; else divisionBelongsToCurrentTenant" [attr.data-qa]="availableDivision.divisionId + '_unavailable'">
            <div class="re-flex re-flex-row">
              <svg-icon [applyClass]="true" class="icon re-text-brand-primary re-opacity-50 re-w-30 re-h-30" src="assets/icons/system_icons/alert.svg"></svg-icon>
              <div class="re-flex re-flex-col re-ml-15">
                <span class="bold md:re-text-base">{{ availableDivision.divisionId | verbrauchstyp }}</span>

                <div class="re-mt-5" *ngIf="availableDivision.isProvided" [innerHTML]="(abortIfNoDivisions ?
                  'move.productSelection.notAvailable.descriptionOtherTenantWithoutOS' :
                  'move.newHome.products.notAvailable') | translate:
                  { companyName: tenantService.getTenantDataByShort(availableDivision.tenantNameShort)?.companyName,
                    linkTenant: tenantService.getTenantDataByShort(availableDivision.tenantNameShort)?.tariffAdvisorLinks[availableDivision.divisionId],
                    atCompany: tenantService.getTenantDataByShort(availableDivision.tenantNameShort)?.tenantForTexts }">
                </div>
                <div class="re-mt-5"  *ngIf="!availableDivision.isProvided">
                  {{ (abortIfNoDivisions ? 'move.productSelection.notAvailable.description' :
                     'move.newHome.products.notAvailableAlsoTenants') | translate }}
                </div>
              </div>
            </div>


          </div>

            <ng-template #divisionBelongsToCurrentTenant>
              <bdo-checkbox
                [attr.data-qa]="availableDivision?.divisionId + 'Check'"
                [checked]="productForm.get(availableDivision.divisionId).get('isChecked').value"
                [formTracking]="getFormTracking(availableDivision.divisionId | verbrauchstyp : false)"
                (change)="clickCheckboxHandler(availableDivision.divisionId)">
                <span class="bold md:re-text-base">{{ availableDivision.divisionId | verbrauchstyp }}</span>
              </bdo-checkbox>
              <fieldset *ngIf="productForm.get(availableDivision.divisionId).get('isChecked').value" [@fadeIn] class="re-mt-30">
                <div class="re-form-item-container re-mb-0">
                  <div class="re-mb-25 bold">{{ 'general.customerData.yourMeterWithDivision' | translate: { division: availableDivision.divisionId | verbrauchstyp } }}</div>

                  <bdo-meter-number [parentForm]="$any(productForm.get(availableDivision.divisionId))"
                                    [withRegisters]="showRegisterInputs"
                                    [deliveryType]="DeliveryType.MOVE"
                                    [showSmartMeterHint]="true"
                                    [readingsOptional]="true"
                                    [sparte]="availableDivision.divisionId"
                                    [storedMeterData]="storedMeterData[availableDivision.divisionId]"
                                    [address]="address"
                                    [cancelOptionsShown]="cancelOptionsShown"
                                    [formTracking]="formTracking"
                                    [trackingEventType]="trackingEventType"
                                    [meterIdentifiersInUse]="meterIdentifiers"
                                    (meterCheckResult)="onMeterCheckResult($event, availableDivision.divisionId)">
                  </bdo-meter-number>
                </div>
              </fieldset>
            </ng-template>
          </div>
      </ng-container>

    </div>

    <div *ngIf="!allUnavailableOrExternal">
      <div class="re-container-with-margin re-mb-20 re-mt-45 md:re-mt-60 re-ml-20 md:re-ml-auto">
        <h4 class="re-text-base">
          {{ 'delivery.situation.details.title' | translate }}
        </h4>
      </div>
      <div class="re-bg-white re-w-full re-px-20 re-py-20 md:re-px-30 md:re-pb-45 md:re-py-25 re-text re-mb-15
                  md:re-w-600 md:re-mx-auto
                  lg:re-w-900">

        <div class="re-form-item-container">
          <div class="re-text-sm re-my-15 re-mb-30">
            {{ 'move.newHome.start.description' | translate }}
          </div>

          <bdo-date-picker
            [noPreselection]="true"
            [forceValidate]="forceValidateDate"
            [selectedDate]="productForm?.get('startDate').value"
            (dateSelected)="updateDate($event)"
            [minDateForChanges]="minDate"
            [maxDateForChanges]="Utilities.maxDate"
            [labelText]="'delivery.situation.details.startDate' | translate"
            [requiredErrorText]="'move.newHome.start.startDateRequired' | translate"
            [tooltipName]="'Tooltip - ' + 'delivery.situation.details.startDate' | translate"
            [tooltipContent]="'delivery.situation.details.startDateHint' | translate"
          ></bdo-date-picker>

        </div>
      </div>
    </div>

  </ng-template>
</div>
