<div class="re-relative re-flex re-mb-2 re-pb-30 md:re-pb-20">
  <div class="re-w-30 re-h-30 re-mr-10">
    <svg-icon [src]="tariffAdvisorService.getPathForIcon(billingByDivision?.division, true)"></svg-icon>
  </div>
  <span class="re-text-sm bold re-self-center">{{billingByDivision?.division | verbrauchstyp}}</span>
</div>


<div class="re-flex re-justify-between re-mb-10">
  <div>{{ 'billing.check.currentAmount' | translate }}</div>
  <div>{{billingByDivision?.amount| currency: 'EUR'}}</div>
</div>

<fieldset class="re-mt-30 re-mb-0">
  <bdo-input [formControl]="$any(parentFormgroup?.get(inputNameAmount))"
              [inputType]="INPUT_TYPE.TEXT"
              [placeholder]="'billing.edit.inputAmount' | translate"
              [isWarning]="false"
              [labelText]="('billing.edit.inputAmount' ) | translate"
              [validationIconHidden]="globalLoadingState === LoadingState.ERROR"
              [attr.data-qa]="'amount-' + billingByDivision?.contractId + '-' + billingByDivision?.division">
  </bdo-input>

    <div class="ui-grid ui-grid-responsive ui-fluid re-mt-30" *ngIf="implausible"
          [attr.data-qa]="'implausible-' + billingByDivision?.contractId + '-' + billingByDivision?.division">
      <bdo-info-box [type]="'warning'">
        {{ 'billing.edit.amountTooHigh' | translate }}
      </bdo-info-box>
      <div class="re-container__content">
        <bdo-checkbox
          [required]="true"
          [checked]="parentFormgroup?.get(inputNameImplausibleChecked).value"
          (change)="parentFormgroup?.get(inputNameImplausibleChecked).setValue(!parentFormgroup?.get(inputNameImplausibleChecked).value)"
          [attr.data-qa]="'implausibleCheckbox-' + billingByDivision?.contractId + '-' + billingByDivision?.division">
            {{ 'billing.edit.amountTooHighConfirm' | translate }}
        </bdo-checkbox>
      </div>
    </div>
</fieldset>
