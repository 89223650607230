<div class="re-h-full re-fixed re-top-0 re-flex re-right-0 re-z-30 re-flex-col re-w-full re-bg-white re-border-l-0 re-border-l-accent-3-dark re-px-35 re-pt-25 re-pb-45 re-shadow-md re-shadow-gray-700/75 re-shadow-right-lg re-shadow-bottom
md:re-h-auto md:re-w-370 md:re-top-30 md:re-right-30 md:re-border-l-2">
  <button (click)="onClose()" class="dialog__close-btn re-w-20 re-h-20 hover:re-text-error re-self-end re-mb-20">
    <svg-icon
      src="assets/icons/close.svg"
      attr.aria-label="{{ 'general.action.close' | translate }}">
    </svg-icon>
  </button>

  <ng-content></ng-content>
</div>
