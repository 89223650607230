<div class="re-container-headline bdo-space--bottom">
	<h3>
		{{ 'confirmation.order.title' | translate }}
  </h3>
</div>
<div class="re-container-with-margin">

  <div class="re-copytext-with-margin">
    {{ 'confirmation.order.description' | translate }}
  </div>
  <h2 class="re-mb-45">{{ 'confirmation.title' | translate }}</h2>
  <ul class="bdo-list__bullet_bigger re-text-sm re-leading-m re-mb-30 lg:re-text-base lg:re-leading-2xl lg:re-mb-45">
    <li class="re-mb-5 md:re-mb-10" [innerHTML]="'confirmation.order.acknowledgment' | translate: { emailAddress: (emailAddress$ | async)?.email }"></li>
    <li class="re-mb-5 md:re-mb-10" [innerHTML]="'confirmation.order.documents' | translate"></li>
    <li [innerHTML]="'confirmation.order.supplyBegins' | translate: { startDate: tariff?.contractStartDate| date }"></li>
    <li class="re-mt-5 md:re-mt-10" *ngIf="wishvoucherAmount"
      [innerHTML]="'confirmation.order.wishvoucher' | translate: { amount: wishvoucherAmount | currency: 'EUR' }">
    </li>
    <ng-container *ngIf="tariffService.isEffzeh([tariff?.selectedTariff?.productId])">
      <li class="re-mt-5 md:re-mt-10">{{ 'confirmation.order.effzehVoucher' | translate}}</li>
      <li class="re-mt-5 md:re-mt-10">{{ 'confirmation.order.effzehPayout' | translate}}</li>
      <li class="re-mt-5 md:re-mt-10">{{ 'confirmation.order.effzehCompetition' | translate}}</li>
    </ng-container>
  </ul>

  <bdo-trustpilot *ngIf="trustpilotUniqueUrl"
                  [trustpilotUniqueUrl]="trustpilotUniqueUrl">
  </bdo-trustpilot>

  <bdo-netcologne-teaser *ngIf="!tariffService.isEffzeh([tariff?.selectedTariff?.productId])"
    [postCode]="postCode"
    [trackingLocation]="TRACKING.LOCATION.CONTRACT_CHANGE_DONE"
  ></bdo-netcologne-teaser>

  <bdo-effzeh-strom-teaser [productIds]="[tariff?.selectedTariff?.productId]"></bdo-effzeh-strom-teaser>

  <div class="re-block re-mt-30 lg:re-mt-45">
    <bdo-text-link-icon
      [iconPosition]="'right'"
      [linkText]="'dashboard.linkTo' | translate"
      [routerUrl]="'/dashboard'"
    ></bdo-text-link-icon>
  </div>

</div>
