import { Component, ContentChildren, QueryList, AfterContentInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccordionItemComponent } from '../accordion-item/accordion-item.component';

@Component({
  selector: 'bdo-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(AccordionItemComponent) items: QueryList<AccordionItemComponent>;
  private itemSubscription: Subscription = new Subscription();

  constructor() { }

  /**
   * Invoked when all children (items) are ready
   */
  ngAfterContentInit() {
    this.items.toArray().forEach((item) => {
      const subscription = item.toggle.subscribe({ next: () => {
        // Close the open item or open another one
        if (item.opened) {
          item.opened = false;
        } else {
          this.openItem(item);
        }
      } });
      this.itemSubscription.add(subscription);
    });
  }

  /**
   * Open an accordion item
   * @param item
   */
  openItem(item: AccordionItemComponent) {
    // close other items
    this.items.toArray().forEach((t) => t.opened = false);
    // open current item
    item.opened = true;
  }

  ngOnDestroy(): void {
    this.itemSubscription.unsubscribe();
  }

}
