import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BdoApiService } from '../../../services/bdo-api.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TrackingService } from '../../../services/tracking.service';
import { StorageService } from '../../../services/storage.service';
import { DELIVERY_TYPE } from '../../../enums/deliveryType';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { SwitchCardConfiguration } from '../../../../shared/models/switchCardConfiguration';
import { NavigationState } from '../../../models/navigationState';
import { Utilities } from '../../../../shared/utils/utilities';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-situation',
  templateUrl: './situation.component.html',
  styleUrls: ['./situation.component.scss']
})
export class SituationComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('form') form: ElementRef;
  @ViewChild('selectArea') selectArea: ElementRef;
  public context: string;
  public hasOfferContext: boolean = !!StorageService.getOfferContext();
  selectedSituation: string;
  scrollY: number;

  public configuration: Array<SwitchCardConfiguration> = [
    {
      id: 'wechsel',
      title: this.translateService.instant('delivery.situation.selection.change.title'),
      description: this.translateService.instant('delivery.situation.selection.change.description'),
      src: { default: this.translateService.instant('delivery.situation.selection.change.img') }
    },
    {
      id: 'umzug',
      title: this.translateService.instant('delivery.situation.selection.move.title'),
      description: this.translateService.instant('delivery.situation.selection.move.description'),
      src: { default: this.translateService.instant('delivery.situation.selection.move.img') }
    }
  ];
  private subscriptions = new Subscription();

  constructor(
    public location: Location,
    private apiService: BdoApiService,
    private trackingService: TrackingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {

    const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
    this.context = currentState?.context;

    // preselect if url is set
    if (activatedRoute.firstChild) {
      this.selectedSituation = activatedRoute.firstChild.routeConfig.path;
    }
    const situationData = StorageService.getSituationData();
    if (situationData) {
      if (situationData.deliveryType === DELIVERY_TYPE.MOVE) {
        this.router.navigate(['umzug'], {
          relativeTo: this.activatedRoute,
          replaceUrl: true,
          // pass state to subcategory
          state: this.location.getState()
        });
      } else {
        this.router.navigate(['wechsel'], {
          relativeTo: this.activatedRoute,
          replaceUrl: true,
          // pass state to subcategory
          state: this.location.getState()
        });
      }
    }
    this.subscriptions.add(this.router.events.subscribe({ next: (val) => {
      if (val instanceof NavigationEnd) {
        this.selectedSituation = activatedRoute.firstChild?.routeConfig.path;
      }
    } }));
  }

  ngOnInit() {
    this.trackingService.postTracking(TRACKING.LOCATION.DELIVERY_SITUATION, TRACKING.ACTION.ENTER);
  }

  ngAfterViewInit() {

    this.subscriptions.add(this.router.events.subscribe({ next: (val) => {
      if (val instanceof NavigationEnd) {

        // prevent scrollPositionRestoration
        scrollTo(0, this.scrollY);
        // make clear that something happened after selecting situation; on mobile scroll to form; on desktop scroll to selection
        if (document.documentElement.clientWidth > 980) {
          this.selectArea?.nativeElement?.scrollIntoView({ behavior: 'smooth' });
        } else {
          this.form?.nativeElement?.scrollIntoView({ behavior: 'smooth' });
        }
      }
    } }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * select situation and go to route
   *
   * @param situationRoute
   */
  public selectSituation(situationRoute) {
    this.scrollY = window.scrollY;
    this.router.navigate([situationRoute], { relativeTo: this.activatedRoute, replaceUrl: true });
    this.selectedSituation = situationRoute;
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.DELIVERY_SITUATION, TRACKING.ACTION.CLICK + ' ' + situationRoute);
  }

}
