function containsNewline(key) {
  if (!key) return null;
  switch (key.type) {
    case 'alias':
    case 'scalar':
    case 'double-quoted-scalar':
    case 'single-quoted-scalar':
      if (key.source.includes('\n')) return true;
      if (key.end) for (const st of key.end) if (st.type === 'newline') return true;
      return false;
    case 'flow-collection':
      for (const it of key.items) {
        for (const st of it.start) if (st.type === 'newline') return true;
        if (it.sep) for (const st of it.sep) if (st.type === 'newline') return true;
        if (containsNewline(it.key) || containsNewline(it.value)) return true;
      }
      return false;
    default:
      return true;
  }
}
export { containsNewline };