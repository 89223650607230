import { Component, Input } from '@angular/core';

@Component({
  selector: 'bdo-promo-link',
  templateUrl: './promo-link.component.html',
  styleUrls: ['./promo-link.component.scss']
})
export class PromoLinkComponent {
  @Input() linkText: string = '';
  @Input() href: string = '';
  @Input() routerUrl: string;
  @Input() openInNewTab: boolean = false;

}
