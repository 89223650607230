<div class="bdo-container bdo-space--bottom">
  <h3>
    {{ 'payment.overview.title' | translate }}
  </h3>
</div>

<div class="re-copytext-with-margin bdo-container--single">
  {{ 'payment.overview.description' | translate }}
</div>

<div class="bdo-container--single bdo-space--bottom bdo-loading__wrapper" [class.bdo-loading__wrapper--height]="isLoading">
  <bdo-loading *ngIf="isLoading"></bdo-loading>

  <ng-container *ngIf="paymentsData?.credits?.payments?.length > 0">
    <ng-container *ngTemplateOutlet="tableDataTemplate; context: {
      $implicit:paymentsData?.credits,
      dataType: PaymentType.POSITIVE
    }"></ng-container>
  </ng-container>

  <ng-container *ngIf="paymentsData?.receivables?.payments?.length > 0">
    <ng-container *ngTemplateOutlet="tableDataTemplate; context: {
      $implicit:paymentsData?.receivables,
      dataType: PaymentType.NEGATIVE
    }"></ng-container>
  </ng-container>

  <ng-container *ngIf="paymentsData?.futureReceivables?.payments?.length > 0">
    <ng-container *ngTemplateOutlet="tableDataTemplate; context: {
      $implicit:paymentsData?.futureReceivables,
      dataType: PaymentType.FUTURE
    }"></ng-container>
  </ng-container>

  <div *ngIf="!isLoading && ((!paymentsData) || (paymentsData.receivables.payments.length + paymentsData.futureReceivables.payments.length + paymentsData.credits.payments.length === 0))">
    <div class="bdo-table max-space-tablet">
      <table class="tablet-up-only receivables">
        <thead>
          <tr>
            <th class="col--bigger">{{ 'payment.overview.type.negative.title' | translate }}</th>
            <th class="col--smaller">{{ 'payment.overview.table.dateDue' | translate }}</th>
            <th class="col--smaller">{{ 'payment.overview.table.amount' | translate }}</th>
            <th>{{'payment.overview.table.defer' | translate}}</th>
          </tr>
        </thead>
      </table>
      <bdo-no-data
        [text]="'payment.noData' | translate">
      </bdo-no-data>
    </div>
  </div>

  <bdo-more-button *ngIf="!showAll"
                    [label]="'general.action.showMore' | translate"
                    (moreEvent)="showAll = true"
  ></bdo-more-button>
</div>
<bdo-faqs
  [keyName]="'payment.faqs'"
  [params]="{ accountId: customerNumber }"
></bdo-faqs>

<ng-template #tableDataTemplate let-data let-dataType="dataType">
  <div class="bdo-container--single bdo-table bdo-space--bottom tablet-up-only">
    <table [ngClass]="{'space--bottom': showAll, 'receivables': dataType === PaymentType.NEGATIVE}" >
      <thead>
        <tr>
          <th class="col--bigger" [ngSwitch]="dataType">
            <ng-container *ngSwitchCase="'negative'">
              {{ 'payment.overview.type.negative.title' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="'positive'">
              {{ 'payment.overview.type.positive.title' | translate }}
            </ng-container>
            <ng-container *ngSwitchCase="'future'">
              {{ 'payment.overview.type.future.title' | translate }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ 'payment.overview.type.unknown.title' | translate }}
            </ng-container>
          </th>
          <th class="col--smaller">{{ 'payment.overview.table.dateDue' | translate }}</th>
          <th class="col--smaller">{{ 'payment.overview.table.amount' | translate }}</th>
          <th *ngIf="dataType === PaymentType.NEGATIVE">{{'payment.overview.table.defer' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let payment of asPaymentsDataCreditsFiltered(data, dataType)">
          <td class="col--bigger">{{ payment?.title }}</td>
          <td class="bdo-font--monospace" [attr.data-qa]="'dateDue'">
            {{ payment.deferredTo ? (payment.deferredTo | date) : (payment?.dateDue | date) }}
          </td>
          <td class="bdo-font--monospace smaller">
            {{ payment?.amount | currency: 'EUR' }}
          </td>
          <td *ngIf="dataType === PaymentType.NEGATIVE">
            <div class="re-flex re-flex-col re-flex-nowrap" *ngIf="payment.deferredTo" [attr.data-qa]="'deferred'">
              <div>
                {{ 'payment.overview.table.deferred' | translate }}
                <bdo-inline-tooltip>{{ 'payment.overview.table.deferredHint' | translate }}</bdo-inline-tooltip>
              </div>
              <div class="re-text-xxs">{{ 'payment.overview.table.deferredFrom' | translate }} {{payment?.dateDue | date}}
              </div>
            </div>
            <bdo-text-link-icon
              [attr.data-qa]="'deferralLink'"
              [customIconSrc]="'general.img.edit' | translate"
              [linkText]="'payment.overview.table.defer' | translate"
              [extraData]="{
                payment: payment
              }"
              [routerUrl]="'verschieben/' + payment?.id"
              [iconPosition]="'right'"
              [tracking]="['Zahlungsübersicht', 'click', 'Später zahlen']"
              *ngIf="payment.isDeferrable"
            ></bdo-text-link-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="mobile-only" [ngClass]="{'space-bottom': showAll}">
    <div *ngFor="let payment of asPaymentsDataCreditsFiltered(data, dataType)">
      <div class="re-mb-10">
        <div class="bdo-tile bdo-table-row__container--mobile re-py-20 re-mb-1">
          <div>
            <div [ngSwitch]="dataType" class="bold">
              <ng-container *ngSwitchCase="'negative'">
                {{ 'payment.overview.type.negative.title' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'positive'">
                {{ 'payment.overview.type.positive.title' | translate }}
              </ng-container>
              <ng-container *ngSwitchCase="'future'">
                {{ 'payment.overview.type.future.title' | translate }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ 'payment.overview.type.unknown.title' | translate }}
              </ng-container>
            </div>

            {{ payment?.title }}
          </div>
          <div>
            <div class="bold">{{ 'payment.overview.table.dateDue' | translate }}</div>
            {{ payment?.dateDue | date }}
          </div>
          <div>
            <div class="bold">{{ 'payment.overview.table.amount' | translate }}</div>
            {{ payment?.amount | currency:'EUR' }}
          </div>
        </div>
        <div class="bdo-tile bdo-table-row__container--mobile" *ngIf="payment.deferredTo || payment.isDeferrable">
          <div class="re-flex re-flex-row md:re-flex-col" *ngIf="payment.deferredTo">
            <div class="re-mr-5">{{ 'payment.overview.table.deferred' | translate }}</div>
            <div class="re-text-xxs re-mr-5">{{ 'payment.overview.table.deferredFrom' | translate }} {{payment?.dateDue | date}}</div>
            <bdo-inline-tooltip>{{ 'payment.overview.table.deferredHint' | translate }}</bdo-inline-tooltip>
          </div>
          <bdo-text-link-icon
            [customIconSrc]="'general.img.edit' | translate"
            [linkText]="'payment.overview.table.defer' | translate"
            [extraData]="{
                payment: payment
              }"
            [routerUrl]="'verschieben/' + payment?.id"
            [iconPosition]="'right'"
            [tracking]="['Zahlungsübersicht', 'click', 'Später zahlen']"
            *ngIf="payment.isDeferrable"
          ></bdo-text-link-icon>
        </div>
      </div>
    </div>
  </div>
</ng-template>
