import { Component } from '@angular/core';
import { LoginService } from '../login.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { defaultValidatorProxy } from '../../shared/validators/default-validator-proxy';
import { htmlTagValidator } from '../../shared/validators/htmltag-validator';
import { ActivatedRoute, Router } from '@angular/router';
import { DebugLogger } from '../../shared/utils/debugLogger';
import { INPUT_TYPE } from '../../bdo/enums/inputType.enum';
import { passwordValidator } from '../../shared/validators/password-validator';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-password-renew',
  templateUrl: './password-renew.component.html',
  styleUrls: ['./password-renew.component.scss']
})
export class PasswordRenewComponent {
  public passwordChanged: boolean;
  public errorShown: boolean = false;
  public sendingData: boolean = false;
  public INPUT_TYPE = INPUT_TYPE;
  public PASSWORD_GROUP_NAME = 'pwGroup';

  public passwordRenewForm = new FormGroup({
    code: new FormControl('', [
      defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required'))
    ]),
    username: new FormControl('', [
      defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
    ]),
    [this.PASSWORD_GROUP_NAME]: new FormGroup({
      ['new-password']: new FormControl('', [
        htmlTagValidator(this.translateService),
        defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
        passwordValidator(this.translateService)
      ]),
      confirmPassword: new FormControl('', [
        htmlTagValidator(this.translateService),
        defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
        () => {
          const pwGroup = this.passwordRenewForm?.get([this.PASSWORD_GROUP_NAME]);
          if (pwGroup?.get('new-password').value !== pwGroup?.get('confirmPassword').value) {
            return { passwordsDoNotMatch: this.translateService.instant('register.error.passwordsDontMatch') };
          }
          return null;
      }])
    })
  });
  public tooManyTries: boolean;

  constructor(
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.activatedRoute.queryParams
      .subscribe({ next: params => {
          if (params.code) {
            this.passwordRenewForm.get('code').setValue(params.code);
          }
        } }
      );
  }

  send(form: any) {
    if (form.valid) {
      this.sendingData = true;
      this.loginService.renewPassword({
        newPassword: this.passwordRenewForm.get([this.PASSWORD_GROUP_NAME, 'new-password']).value,
        username: this.passwordRenewForm.get('username').value,
        code: this.passwordRenewForm.get('code').value
      }).subscribe({
        next: () => {
          this.sendingData = false;
          this.passwordChanged = true;
        },
        // eslint-disable-next-line rxjs/no-implicit-any-catch
        error: (error) => {
          this.sendingData = false;
          DebugLogger.error(this.constructor.name, 'send()', error);
          this.errorShown = true;
          if (error?.code === 'LimitExceededException') {
            this.tooManyTries = true;
          }
        }
      });
    } else {
      this.passwordRenewForm.markAllAsTouched();
    }
  }

  onFocus() {
    this.errorShown = false;
    this.tooManyTries = false;
  }

  closeMessage() {
    this.passwordChanged = false;
    this.router.navigate(['/login']);
  }

  /**
   * Calculate the Password Strength
   * @author: Martin Melcher */
  getPasswordStrength(pw: string): number {
    if (!pw) { return 0; }

    let rating: number = 0;

    // verwendete Zeichenklassen ermitteln
    let lower = false;
    let upper = false;
    let num = false;
    let other = false;

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < pw.length; i++) {
      if (pw[i].match(/[0-9]/)) {
        num = true;
        continue;
      }
      if (pw[i].match(/[a-z]/)) {
        lower = true;
        continue;
      }
      if (pw[i].match(/[A-Z]/)) {
        upper = true;
        continue;
      }
      other = true;
    }

    // Zeichenvorrat ermitteln
    let cpx = 1;
    if (lower) {
      cpx = cpx * 26;
    }
    if (upper) {
      cpx = cpx * 26;
    }
    if (num) {
      cpx = cpx * 10;
    }
    if (other) {
      cpx = cpx * 15;
    }
    //
    cpx = Math.log(Math.pow(cpx, pw.length)) / Math.log(10);

    // bar.css('width', 5+95*(cpx/80) + '%').css('max-width', '100%');
    if (pw.length < 8) {
      rating = 0;
    } else if (cpx < 25) {
      rating = 1;
    } else if (cpx < 40) {
      rating = 2;
    } else if (cpx < 60) {
      rating = 3;
    } else {
      rating = 4;
    }

    return rating;
  }

  getPasswordStrengthText(ratingNumber: number): string {
    const ratings = ['mind. 8 Zeichen', 'ausreichend', 'gut', 'sehr gut', 'hervorragend'];
    if (ratingNumber > ratings.length) {
      return '';
    } else {
      return ratings[ratingNumber];
    }
  }

}
