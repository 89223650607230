<div class="bdo-container bdo-space--bottom" *ngIf="paymentData">
  <h3 class="re-block re-mb-30 md:re-mb-45">
    {{ 'payment.defer.title' | translate }}
  </h3>
  <div class="re-mb-15 re-w-full md:re-w-600 md:re-mx-auto lg:re-w-900">
    <div class=" re-copytext re-my-30 md:re-my-45">
      {{ 'payment.defer.description' | translate }}
    </div>
    <h2 class="re-block re-mb-30">
      {{ 'payment.defer.subtitle' | translate }}
    </h2>
    <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">
      {{ 'general.error.error' | translate }}
    </bdo-info-box>
  </div>
</div>
  <div class="re-bg-white re-w-full re-flex re-pt-30 re-p-20 re-pb-45 md:re-pb-60 re-flex-col re-relative
                md:re-w-600 md:re-mx-auto md:re-p-30 md:re-pt-45
                lg:re-w-900">
    <div class="bdo-loading__wrapper" [class.bdo-loading__wrapper--height]="state === LoadingState.LOADING">
      <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>

      <div class="re-border-b re-border-monochrome-medium-light re-border-solid re-pb-30 re-mb-30 md:re-mb-45">
        <div class="re-mb-45 md:re-mb-30 re-flex re-flex-col md:re-flex-row re-justify-between re-text-sm md:re-text-base bold">
          <div [attr.data-qa]="'title'">{{paymentData?.title}}</div>
          <div [attr.data-qa]="'amount'" class="re-text-base">{{paymentData?.amount | currency: 'EUR'}}</div>
        </div>
        <div class="re-flex re-flex-col md:re-flex-row re-justify-between re-text-sm">
          <div>
            {{ 'payment.defer.recentDate' | translate }}
          </div>
          <div class="bold" [attr.data-qa]="'old_date'">
            {{paymentData?.dateDue | date}}
          </div>
        </div>
      </div>

      <div class="re-flex re-flex-col md:re-ml-auto re-pt-5">
        <div class="re-block re-mb-10 bold re-text-sm md:re-mb-30 md:re-text-base">
          {{ 'payment.defer.new.title' | translate }}
        </div>
        <div class="re-text-xs md:re-text-sm re-mb-30"
             [innerHTML]="'payment.defer.new.description' | translate:
                         { newDate: (paymentData?.deferrableUpTo | date) }">
        </div>
        <div class="re-w-full md:re-w-440">
          <bdo-date-picker
            [labelText]="'payment.defer.new.desiredDay' | translate"
            [tooltipName]="'tooltip-date'"
            [tooltipContent]="'payment.defer.new.desiredDayInfo' | translate"
            [selectedDate]="paymentData?.deferrableUpTo"
            [minDateForChanges]="paymentData?.dateDue"
            [maxDateForChanges]="paymentData?.deferrableUpTo"
            (dateSelected)="onDateChanged($event)">
          </bdo-date-picker>
        </div>
      </div>
    </div>
  </div>
  <div class="re-bg-white re-w-full re-flex re-p-25 md:re-p-30 re-flex-col re-relative re-mt-20
                md:re-w-600 md:re-mx-auto
                lg:re-w-900" *ngIf="selectedDate" [@fadeIn]>
    <div class="re-flex re-flex-col md:re-flex-row re-justify-between re-text-base bold">
      <div class="re-text-sm md:re-text-base re-mb-10 md:re-mb-0">{{ 'payment.defer.new.resultTitle' | translate }}</div>
      <div [attr.data-qa]="'selectedDate'">{{selectedDate | date}}</div>
    </div>
  </div>
  <div class="re-w-full re-mt-45
              md:re-w-600 md:re-mx-auto
              lg:re-w-900">
    <div class="re-pl-15 md:re-pl-0">
      <button class="re-button re-primary re-mb-15 re-block" (click)="save()">Speichern</button>
      <div>
        <bdo-text-icon
          [iconPosition]="'left'"
          [text]="'general.action.back' | translate"
          (itemClicked)="navigateBack()"
        >
        </bdo-text-icon>
      </div>
    </div>
  </div>
<bdo-simple-text-popup [isVisible]="showSuccessPopup" [title]="'payment.defer.new.success' | translate"
                       [icon]="'success'" (closePopupEvent)="onClosePopup()">
</bdo-simple-text-popup>
