/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AblesungStaende { 
    /**
     * Zählwerktyp, z.B. HT oder NT
     */
    typ?: string;
    /**
     * Zählerstand
     */
    stand?: string;
    /**
     * Angabe wieviel seit der letzten Ablesung verbraucht wurde
     */
    verbrauch?: string;
}

