<div *ngIf="!isGewe">
  <div class="error re-mb-5" *ngIf="validateCheckboxes && !rightOfWithdrawalAccepted">
    {{ 'legal.rightsOfWithdrawalRequired' | translate }}
  </div>
  <bdo-checkbox
    [checked]="rightOfWithdrawalAccepted"
    (change)="onChangeRightOfWithdrawalAccepted()"
    data-qa="rightOfWithdrawalCheckbox">
    <div [innerHTML]="'legal.rightsOfWithdrawal' | translate:
                      { linkRightsOfWithdrawal: linkRevoke }"></div>
  </bdo-checkbox>
</div>

<div [ngClass]="isGewe ? 're-mt-0' : 're-mt-15'">
  <div class="error re-mb-5" *ngIf="validateCheckboxes && !termsOfConditionsAccepted">
    {{ 'legal.termsOfConditionRequired' | translate }}
  </div>
  <bdo-checkbox
    [checked]="termsOfConditionsAccepted"
    (change)="onChangeTermsOfConditions()"
    data-qa="termsOfConditionsCheckbox">
    <div [innerHTML]="stringContractTerms"></div>
  </bdo-checkbox>
</div>

<div class="re-mt-15" *ngIf="communicationAccepted !== null">
  <bdo-checkbox
    [checked]="communicationAccepted"
    (change)="communicationAcceptedChange.emit(!communicationAccepted)"
    data-qa="communicationAcceptedCheckbox">
    <div [innerHTML]="'legal.accepted' | translate:
                      { companyName: tenantService.getCurrentTenantData().companyName }"></div>
  </bdo-checkbox>
</div>

<div class="re-summary-row re-mb-0 re-mt-30"
     [innerHTML]="'legal.privacyInfo' | translate:
                  { linkPrivacy: linkPrivacy }">
</div>
