<ng-container *ngIf="selectedState!=='withAccount'; else loginWithAccount">
  <div class="re-bg-white re-w-full re-text md:re-w-600 md:re-mx-auto lg:re-w-900">
    <div class="re-px-20">
      <h4 class="re-container__content h5 re-pt-30 md:re-pt-45 lg:re-pt-60 re-pb-30">
        {{ (selectedState==='anonymous' ? 'login.continueWithAccountId' : 'login.continueWithLogin') | translate }}
      </h4>
    </div>
    <div class="re-pb-30 re-px-20" *ngIf="state === LoadingState.ERROR">
      <div class="re-container__content">
        <bdo-info-box>
          {{ (isWrongCombination ? 'register.error.combinationNotFound' : 'general.error.error') | translate }}
        </bdo-info-box>
      </div>
    </div>
  </div>

  <div class="re-relative md:re-w-600 md:re-mx-auto lg:re-w-900 re-bg-white re-pb-30 md:re-pb-45 lg:re-pb-60">
    <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
    <bdo-authentication-form
      [includeDateSelection]="false"
      [includeCancelButton]="false"
      (click)="resetErrorBox()"
      (identificationSubmitted)="sendIdentificationData($event)"
    ></bdo-authentication-form>

    <div *ngIf="!isAnonymousOnly" class="re-bg-white re-form-container re-pt-45">
      <div class="re-max-w-440 re-mx-auto">
        <hr class="re-border-t re-mb-20 re-border-monochrome-medium-light">
        <div class="re-mb-5">
          {{ (selectedState==='anonymous' ? 'register.login.anonymousTitle' : 'register.integrated.subtitle2') | translate }}
        </div>
        <bdo-text-icon
          [attr.data-qa]="'toggleStateButton'"
          [iconPosition]="'right'"
          [text]=" (selectedState==='anonymous' ? 'register.login.changeToWithAccount' : 'register.login.changeToNoAccount') | translate"
          (click)="onStateChanged()"
        ></bdo-text-icon>
      </div>
    </div>
  </div>

</ng-container>

<ng-template #loginWithAccount>
  <bdo-login *ngIf="selectedState==='withAccount'"
    [destination]="destination"
    [title]="'login.continueWithLogin' | translate">
    <div class="re-mb-5">
      {{ 'register.integrated.subtitle2' | translate }}
    </div>
    <bdo-text-icon
      [iconPosition]="'right'"
      [text]="'register.login.changeToNoAccount' | translate"
      (click)="onStateChanged()"
    ></bdo-text-icon>
  </bdo-login>
</ng-template>
