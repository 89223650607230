<div class="re-px-20 re-py-25 md:re-px-30 re-xs">
  <div class="re-mb-15 md:re-mb-0 infotext" *ngIf="infoText">
    <div [innerHTML]="infoText"></div>
  </div>

  <div class="re-mt-15" *ngFor="let bonus of tariffData?.parts?.[0]?.contractPayments">
    <div class="bold">{{ bonus?.description }}</div>
    <div>{{ bonus?.payment }}</div>
  </div>

</div>
