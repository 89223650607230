import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CheckIdentificationData } from '../../../assets/js/com/ts_api_client';
import { defaultValidatorProxy } from '../../shared/validators/default-validator-proxy';
import { accountIdValidator } from '../../shared/validators/accountId-validator';
import { INPUT_TYPE } from '../../bdo/enums/inputType.enum';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationFormtype } from '../../shared/formtypes/authentication.formtype';

@Component({
  selector: 'bdo-authentication-form',
  templateUrl: './authentication-form.component.html',
  styleUrls: ['./authentication-form.component.scss']
})
export class AuthenticationFormComponent implements OnInit {
  @Input() includeDateSelection: boolean = true;
  @Input() includeCancelButton: boolean = true;
  @Input() accountId: string = '';
  @Input() linkToLoggedInService: string;
  @Output() identificationSubmitted: EventEmitter<CheckIdentificationData> = new EventEmitter();
  sendingData: boolean = false;
  meterNumber: string = this.activatedRoute?.snapshot?.queryParamMap?.get('meternumber') ?? '';
  selectedDate: Date = new Date(Date.now());
  minDate: Date;
  validateAll: boolean = false;
  validationIconHidden: boolean = false;
  public INPUT_TYPE = INPUT_TYPE;
  public form: FormGroup<AuthenticationFormtype> = new FormGroup({
    accountId: new FormControl<string>(this.activatedRoute?.snapshot?.queryParamMap?.get('accountid') ?? this.accountId, {
      validators: [
        defaultValidatorProxy(Validators.pattern(/^\d+$/), this.translateService.instant('register.account.accountIdOnlyDigits')),
        accountIdValidator(this.translateService),
        defaultValidatorProxy(Validators.required, this.translateService.instant('register.account.accountIdRequired')),
      ]
    }),
    meterNumber: new FormControl<string>(this.activatedRoute?.snapshot?.queryParamMap?.get('meternumber') ?? '', {
      validators: [
        defaultValidatorProxy(Validators.required, this.translateService.instant('register.meter.meterIdRequired')),
        defaultValidatorProxy(Validators.pattern(/^(?!\$.*$)/), this.translateService.instant('register.meter.meterIdInvalid')),
      ]
    }),
    dateSelection: new FormControl<string>('', { })
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    // Keep accountId after saving for another meter capture
    if (this.accountId) {
      this.form.controls.accountId.setValue(this.accountId);
    }
    this.minDate = this.getMinDate();
   }

  submit() {
    if (this.form.valid) {
      this.emitIdentificationData();
    } else {
      this.validateAll = true;
      this.form.markAllAsTouched();
    }
  }

  emitIdentificationData(): void {
    const identificationData: CheckIdentificationData = {
      'accountId': this.form.controls.accountId.value,
      'meterNumberOrRegisterCode': this.form.controls.meterNumber.value,
      'atDate': this.selectedDate
    };
    this.identificationSubmitted.emit(identificationData);
    this.validationIconHidden = true;
  }

  onFocus() {
    this.validationIconHidden = false;
  }

  updateDate(date: Date) {
    this.form.controls.dateSelection.setValue(date as any);
    this.selectedDate = date;
  }

  getMinDate(): Date {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() - 28);
    return date;
  }

  goToLoggedInService() {
    const options: NavigationExtras = {
      relativeTo: this.activatedRoute
    };
    if (this.meterNumber) {
      options.queryParams = {
        meternumber: this.meterNumber
      };
    }
    this.router.navigate([this.linkToLoggedInService], options);
  }
}
