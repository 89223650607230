import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TRACKING } from '../../../bdo/enums/trackingParts.enum';
import { TrackingService } from '../../../bdo/services/tracking.service';

@Component({
  selector: 'bdo-mediatext',
  templateUrl: './mediatext.component.html',
  styleUrls: ['./mediatext.component.scss']
})
export class MediatextComponent implements OnChanges {
  @Input() headline: string = '';   // TODO: mandantenspezifisch
  @Input() imgSrc: string = '';     // TODO: mandantenspezifisch
  @Input() videoSrc: string = '';   // TODO: mandantenspezifisch
  @Input() altImgText: string = ''; // TODO: mandantenspezifisch
  @Input() time: string = '';       // TODO: mandantenspezifisch
  @Input() tracking: string = '';

  videoOpen = false;
  trustedVideoSrc: SafeUrl;

  constructor(
    private sanitizer: DomSanitizer,
    private trackingService: TrackingService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.videoSrc && changes.videoSrc.currentValue) {
      this.trustedVideoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.videoSrc);
    }
  }

  showVideo() {
    this.trackingService.postSimpleTracking(this.tracking, TRACKING.ACTION.SHOW + ' Video : ' + this.headline);
    this.videoOpen = true;
  }

  closeVideo() {
    this.trackingService.postSimpleTracking(this.tracking, TRACKING.ACTION.CLOSE + ' Video : ' + this.headline);
    this.videoOpen = false;
  }
}
