import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { CustomerStoreService } from '../../../services/customer-store.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { LinkData } from '../../../models/LinkData';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bdo-documents-tile',
  templateUrl: './documents-tile.component.html',
  styleUrls: ['./documents-tile.component.scss']
})
export class DocumentsTileComponent implements OnChanges, OnDestroy {
  @Input() isRestricted: boolean;
  @Input() accountId: string;
  public TRACKING = TRACKING;
  public linkData: Array<LinkData>;
  private subscriptions = new Subscription();

  constructor(
    public translateService: TranslateService,
    public customerStore: CustomerStoreService
  ) {
    this.subscriptions.add(this.translateService.onLangChange.subscribe({
      next: () => {
        this.setLinkData();
      }
    } ));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setLinkData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setLinkData(): void {
    this.linkData = [
      {
        linkName: this.translateService.instant('dashboard.documents.links.documents'),
        href: '/dokumente/' + this.accountId,
        tracking: [TRACKING.LOCATION.DASHBOARD, TRACKING.ACTION.GOTO, TRACKING.LOCATION.DOCUMENTS]
      },
      ...( !this.isRestricted ? [
        { linkName: this.translateService.instant('dashboard.documents.links.siminvoice'),
        href: '/simulationsrechnung/' + this.accountId,
        tracking: [TRACKING.LOCATION.DASHBOARD, TRACKING.ACTION.GOTO, TRACKING.LOCATION.SIMINVOICE] }]
        : []
    )];
  }
}
