<div class="re-form-item-container">
  <bdo-input #postCodeInput
            [formControl]="form.controls.postCode"
            [inputType]="INPUT_TYPE.TEXT"
            [validationIconHidden]="state === LoadingState.ERROR"
            [labelText]="'address.postCode.title' | translate"
            [formTracking]="formTracking"
            [autocomplete]="'postal-code'"
            [enableAutocomplete]="true"
            >
  </bdo-input>
</div>

<div class="re-form-item-container">
  <bdo-input #cityInput
            *ngIf="!cities || cities.length <= 1"
            [formControl]="$any(form.controls.city)"
            [inputType]="INPUT_TYPE.TEXT"
            [validationIconHidden]="state === LoadingState.ERROR"
            [labelText]="'address.city.title' | translate"
            [formTracking]="formTracking"
            >
  </bdo-input>
  <bdo-dropdown *ngIf="cities?.length > 1"
    [labelText]="'address.city.title' | translate"
    [data]="citiesDropdown"
    [required]="true"
    [dataQa]="'i_cities_dropdown'"
    [selected]="selectedCity"
    [requiredErrorText]="'address.city.required' | translate"
    [forceValidate]="forceValidate"
    (itemSelected)="updateCity($event)">
  </bdo-dropdown>
</div>


<div class="re-form-item-container">
  <bdo-input
    [formControl]="form.controls.street"
    [trimOnBlur]="false"
    [enableAutocomplete]="false"
    [placeholder]="'address.street.title' | translate"
    [formTracking]="formTracking"
    #street
  >
  </bdo-input>
  <bdo-autosuggest
    [dataSource$]="streetsCache$"
    [searchString$]="form.controls.street.valueChanges"
    [for]="street"
    [sort]="Utilities.sortAutosuggest"
    (noItemsAvailable)="noStreetsAvailable$.next($event)"
    (itemSelected)="onItemSelected($event)"
    #autosuggestComponent
  >
  </bdo-autosuggest>
</div>

<div class="re-form-item-container">
  <bdo-input
    [formControl]="form.controls.housenumber"
    [autocomplete]="'street-address'"
    [enableAutocomplete]="true"
    [placeholder]="'address.housenumber.title' | translate"
    [formTracking]="formTracking"
  >
  </bdo-input>
</div>
