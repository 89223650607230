"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RealTimeConfigurationService = void 0;
var RealTimeConfigurationService = /** @class */function () {
  function RealTimeConfigurationService(siteCode, eventUrl, handler) {
    var _this = this;
    this.disconnect = function () {
      _this.eventSource.close();
    };
    this.siteCode = siteCode;
    this.eventSource = new EventSource(eventUrl);
    this.eventSource.onopen = function (message) {
      console.log("onopen called: ".concat(message));
    };
    this.eventSource.onerror = function (error) {
      console.log("SSE Client error called: ".concat(error));
    };
    this.eventSource.addEventListener(RealTimeConfigurationService.EVENT_NAME, function (message) {
      var messageEvent = message;
      var event = JSON.parse(messageEvent.data);
      if (_this.siteCode === event.siteCode) {
        handler && handler(event);
      }
    });
    this.eventSource.onmessage = function (message) {
      console.log(message);
    };
  }
  RealTimeConfigurationService.EVENT_NAME = "configuration-update-event";
  return RealTimeConfigurationService;
}();
exports.RealTimeConfigurationService = RealTimeConfigurationService;