import { Location } from '@angular/common';
import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractDetails, ContractCancelData, StatusResponse } from '../../../../../assets/js/com/ts_api_client/model/models';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { VERBRAUCHSTYP } from '../../../enums/verbrauchstyp.enum';
import { BillingAddressFormData } from '../../../models/billingAddressFormData';
import { LegalData } from '../../../models/legalData';
import { BdoApiService } from '../../../services/bdo-api.service';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { CONTEXT_FROM_SUMMARY } from '../../delivery/summary/summary.component';
import { DatadogService } from '../../../services/datadog.service';
import { TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { CancelHomeData } from '../../../models/cancelHome';
import { LanguageService } from '../../../services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-contract-cancel-summary',
  templateUrl: './contract-cancel-summary.component.html',
  styleUrls: ['./contract-cancel-summary.component.scss']
})
export class ContractCancelSummaryComponent implements OnInit {
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public oldContract: ContractDetails;
  public validateCheckboxes: boolean = false;
  public rightOfWithdrawalAccepted: boolean = false;
  public termsOfConditionsAccepted: boolean = false;
  public recentDivisionIds: Array<VERBRAUCHSTYP> = [];
  private recentHomeData: CancelHomeData;

  constructor(
    public location: Location,
    private dataDogService: DatadogService,
    public apiService: BdoApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private trackingService: TrackingService,
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_CANCEL_SUMMARY, TRACKING.ACTION.ENTER);
    this.oldContract = StorageService.getOldContract();
    this.recentHomeData = StorageService.getValue<CancelHomeData>(ALLOWED_KEYS.RECENT_HOME_DATA);
    if (!this.oldContract ) {
      this.dataDogService.trackCustomEvent('contract_cancel__summary__oldContractDataMissing');
    }
    if (!this.recentHomeData) {
      this.dataDogService.trackCustomEvent('contract_cancel__summary__recentHomeDataMissing');
    }

    this.recentDivisionIds = this.recentHomeData?.meterData.map((meterreading) => {
      for (const division of Object.keys(VERBRAUCHSTYP)) {
        if (meterreading.sparte === VERBRAUCHSTYP[division].toString()) {
          return VERBRAUCHSTYP[division];
        }
      }
    });
  }

  onEdit(wizardStep: number, fragment: string) {
    this.router.navigate(['../' + fragment], {
      relativeTo: this.activatedRoute,
      state: {
        context: CONTEXT_FROM_SUMMARY
      }
    });
  }

  onChangeRightOfWithdrawalAccepted(accepted: boolean) {
    this.rightOfWithdrawalAccepted = accepted;
    StorageService.setProperty<LegalData>(ALLOWED_KEYS.LEGAL_DATA, 'rightOfWithdrawalAccepted', this.rightOfWithdrawalAccepted);
  }

  onChangeTermsOfConditions(accepted: boolean) {
    this.termsOfConditionsAccepted = accepted;
    StorageService.setProperty<LegalData>(ALLOWED_KEYS.LEGAL_DATA, 'termsOfConditionsAccepted', this.termsOfConditionsAccepted);
  }

  submit() {
    this.validateCheckboxes = true;

    if (this.rightOfWithdrawalAccepted && this.termsOfConditionsAccepted) {
      this.state = LOADING_STATE.LOADING;

      const contract: ContractCancelData = {
        accountId: StorageService.getAuthData()?.checkIdentificationData.accountId,
        meterNumber: this.oldContract.meterNumbers[0],
        divisionId: this.oldContract.division,
        endDate: new Date(this.recentHomeData.date),
        email: StorageService.getValue<BillingAddressFormData>(ALLOWED_KEYS.BILLING_ADDRESS).email,
        contractCancelMeterData: this.recentHomeData.meterData.find(item => item.meterNumber === this.oldContract.meterNumbers[0])?.zaehlwerkeListe,
        cancelReasonId: this.recentHomeData.cancelReasonId
      };

      this.apiService.postContractCancelAnonymous(contract)
        .subscribe(
          {
            next: (result: StatusResponse) => {
              if (result.status !== 'error') {
                this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_CANCEL_SUMMARY, TRACKING.ACTION.SUCCESS);
                this.state = LOADING_STATE.IDLE;
                this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_CANCEL_SUMMARY, TRACKING.ACTION.GOTO, 'Bestätigungsseite');
                this.router.navigate(['../../danke'], { relativeTo: this.activatedRoute });
              } else {
                this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_CANCEL_SUMMARY, TRACKING.ACTION.ERROR);
                this.state = LOADING_STATE.ERROR;
              }
            },
            error: () => {
              this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_CANCEL_SUMMARY, TRACKING.ACTION.ERROR);
              this.state = LOADING_STATE.ERROR;
            }
          }
        );
    }
  }
}
