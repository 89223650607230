<div class="re-container-headline re-mb-30 lg:re-mb-60">
  <h3 data-qa="title">{{ 'payment.title' | translate }}</h3>
</div>

<form (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" [formGroup]="paymentForm" bdoScrollToInvalidControl>
  <div [formGroup]="paymentForm" class="re-bg-white re-w-full re-p-20 re-pb-45 re-mb-30
                                        md:re-w-600 md:re-pt-45 md:re-pb-60 md:re-mx-auto
                                        lg:re-w-900">
    <fieldset class="re-mb-0">
      <div [attr.data-qa]="'paymentMethod'">
        <div class="bold re-mb-30
                    md:re-mb-20 md:re-text-base md:re-leading-2xl">
          {{ 'payment.subtitle' | translate }}
        </div>

        <div class="re-mb-30 md:re-mb-20">
          <div class="re-flex re-items-start re-relative re-mb-20">
            <input id="sepa" type="radio" value="sepa" name="paymentMethod" formControlName="paymentMethod" selected>
            <label class="re-ml-15" for="sepa">{{ 'payment.sepa.title' | translate }}</label>
          </div>

          <div class="re-flex re-items-start re-relative">
            <input id="bankTransfer" type="radio" value="bankTransfer" name="paymentMethod" formControlName="paymentMethod">
            <label class="re-ml-15" for="bankTransfer">{{ 'payment.bankTransfer.title' | translate }}</label>
          </div>
        </div>

        <div>
          <div *ngIf="paymentForm.get('paymentMethod')?.value === 'sepa'">

            <bdo-sepa-form
              [parentForm]="paymentForm"
              [sepaRequired]="paymentForm.get('paymentMethod')?.value === 'sepa'"
            ></bdo-sepa-form>
            <p>{{ 'payment.sepa.refunds' | translate }}</p>
          </div>

          <p *ngIf="paymentForm.get('paymentMethod')?.value === 'bankTransfer'">
            {{ 'payment.bankTransfer.description' | translate }}
          </p>

        </div>
      </div>
    </fieldset>
  </div>
  <div class="re-w-full
              md:re-w-600 md:re-mx-auto
              lg:re-w-900">
    <div class="md:re-pl-0 re-pl-15">
      <bdo-validate-submit
        [textButtonLong]="'wizard.button.summary' | translate"
      ></bdo-validate-submit>
      <div class="re-pt-15">
        <bdo-text-icon
          [iconPosition]="'left'"
          [text]="'general.action.back' | translate"
          (itemClicked)="location.back()"
        >
        </bdo-text-icon>
      </div>
    </div>
  </div>
</form>
