import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { ChangePasswordData } from '../../bdo/models/changePasswordData';
import { INPUT_TYPE } from '../../bdo/enums/inputType.enum';
import { LOADING_STATE } from '../../bdo/enums/loadingState.enum';
import { defaultValidatorProxy } from '../../shared/validators/default-validator-proxy';
import { htmlTagValidator } from '../../shared/validators/htmltag-validator';
import { LoginService } from '../login.service';
import { passwordValidator } from '../../shared/validators/password-validator';
import { PasswordChangeError } from '../../bdo/enums/passwordChangeError.enum';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ToastService } from '../../bdo/services/toast.service';
import { DebugLogger } from '../../shared/utils/debugLogger';

@Component({
  selector: 'bdo-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent {
  @Input() username: string = '';
  @Output() editDone: EventEmitter<boolean> = new EventEmitter();
  public INPUT_TYPE = INPUT_TYPE;
  public validationIconHidden: boolean = false;
  public isPopupShown: boolean = false;
  public LoadingState = LOADING_STATE;
  public state = LOADING_STATE.IDLE;
  public errorMessage: string = '';

  public form = new FormGroup({
    password: new FormControl('', {
      validators: [
        defaultValidatorProxy(Validators.required, this.translateService.instant('register.password.required'))
      ]
    }),
    newPassword: new FormControl('', {
      validators: [
        htmlTagValidator(this.translateService),
        passwordValidator(this.translateService),
        defaultValidatorProxy(Validators.required, this.translateService.instant('register.password.required'))
      ]
    }),
    newPasswordRepeat: new FormControl('', {
      validators: [
        defaultValidatorProxy(Validators.required, this.translateService.instant('register.password.reenterRequired')),
        () => {
          if (this.form?.get('newPassword').value !== this.form?.get('newPasswordRepeat').value) {
            return { passwordsDoNotMatch: this.translateService.instant('register.error.passwordsDontMatch') };
          }
        }
      ]
    }),
  });

  constructor(
    private loginService: LoginService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {
    this.form.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      if (this.validationIconHidden) {
        this.onFocus();
      }
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const passwordData: ChangePasswordData = {
        currentPassword: this.form.get('password')?.value,
        newPassword: this.form.get('newPassword')?.value,
        username: this.username,
      };
      this.state = LOADING_STATE.LOADING;
      this.loginService.changePassword(passwordData).subscribe({ next: res => {
        this.toastService.pushToast({
          headline: this.translateService.instant('personaldata.password.success')
        });
        this.state = LOADING_STATE.IDLE;
        this.editDone.emit(true);
      }, error: (err: unknown) => {
        const error: string = err.toString();

        if (error?.startsWith(PasswordChangeError.INVALID_PASSWORD)) {
          this.errorMessage = this.translateService.instant('register.password.recentInvalid');
        } else if (error?.startsWith(PasswordChangeError.LIMIT_EXCEEDED)) {
          this.errorMessage = this.translateService.instant('register.error.loginLimitExceeded');
        } else {
          DebugLogger.debug('Password Change Error: ', error);
          this.errorMessage = this.translateService.instant('general.error.error');
        }
        this.state = LOADING_STATE.ERROR;
        this.validationIconHidden = true;
      } });
    } else {
      this.form.markAllAsTouched();
    }
  }

  onFocus() {
    this.errorMessage = '';
    this.state = LOADING_STATE.IDLE;
    this.validationIconHidden = false;
  }

}
