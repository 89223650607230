import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BankAccount, BankAssignment } from '../../../../../assets/js/com/ts_api_client';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { BdoApiService } from '../../../services/bdo-api.service';
import { FormGroup } from '@angular/forms';
import { PaymentFormtype } from '../../../../shared/formtypes/payment-formtype';

@Component({
  selector: 'bdo-personal-data-banking',
  templateUrl: './personal-data-banking.component.html',
  styleUrls: ['./personal-data-banking.component.scss']
})
export class PersonalDataBankingComponent implements OnInit {
  @Input() accountId: string;
  @Input() disabled: boolean = false;
  @Output() edit: EventEmitter<boolean> = new EventEmitter();

  public LoadingState = LOADING_STATE;
  public state = LOADING_STATE.IDLE;
  public bankData: BankAssignment;
  public isInEditMode = false;
  public paymentForm = new FormGroup<PaymentFormtype>({});

  constructor(
    private apiService: BdoApiService
  ) {
  }

  ngOnInit(): void {
    this.state = LOADING_STATE.LOADING;
    this.apiService.getBankAccountAssignment().subscribe(
      {
        next: (res) => {
          this.bankData = res;
          this.state = LOADING_STATE.IDLE;
        },
        error: () => {
          this.state = LOADING_STATE.ERROR;
        }
      });
  }

  editBankDataDone(bankAccount: BankAccount) {
    this.toggleEdit();
    this.bankData = {
      credit: bankAccount,
      debit: bankAccount
    };
  }

  toggleEdit() {
    this.isInEditMode = !this.isInEditMode;
    this.edit.emit(this.isInEditMode);
  }
}
