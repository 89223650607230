"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VariationStorage = void 0;
var visitorVariation_1 = require("./visitorVariation");
var VariationStorage = /** @class */function () {
  function VariationStorage() {
    var _this = this;
    this.variations = {};
    this.updateVariation = function (visitorCode, experimentId, variation) {
      if (!_this.variations[visitorCode]) {
        _this.variations[visitorCode] = {};
      }
      _this.variations[visitorCode][experimentId] = new visitorVariation_1.VisitorVariation(variation.id, variation.respoolTime);
      _this.saveToLocalStorage();
    };
    this.getVariationId = function (visitorCode, experimentId) {
      var savedVisitorVariation = _this.variations[visitorCode] && _this.variations[visitorCode][experimentId];
      if (savedVisitorVariation && savedVisitorVariation.isValid()) {
        return savedVisitorVariation.variationId;
      }
      return null;
    };
    this.getMapVariationId = function (visitorCode) {
      var visitorExperiments = _this.variations[visitorCode];
      if (visitorExperiments) {
        var variationIds_1 = {};
        Object.keys(visitorExperiments).map(function (key) {
          var variationKey = Number(key);
          variationIds_1[variationKey] = visitorExperiments[variationKey].variationId;
        });
        return variationIds_1;
      }
      return null;
    };
    this.loadFromLocalStorage = function () {
      var data = localStorage.kameleoonVariationStorage;
      if (data) {
        try {
          var dataJSON = JSON.parse(data);
          var newSavedVariations_1 = {};
          Object.entries(dataJSON).forEach(function (_a) {
            var visitorCode = _a[0],
              experiment = _a[1];
            var savedExperiments = experiment;
            Object.entries(savedExperiments).forEach(function (_a) {
              var experimentId = _a[0],
                visitorVariation = _a[1];
              var experimentIdNumber = parseInt(experimentId);
              if (!newSavedVariations_1[visitorCode]) {
                newSavedVariations_1[visitorCode] = {};
              }
              newSavedVariations_1[visitorCode][experimentIdNumber] = visitorVariation_1.VisitorVariation.createFromJSON(visitorVariation);
            });
          });
          _this.variations = newSavedVariations_1;
        } catch (e) {
          console.log(e);
        }
      }
    };
    this.saveToLocalStorage = function () {
      localStorage.setItem("kameleoonVariationStorage", JSON.stringify(_this.variations));
    };
    this.loadFromLocalStorage();
  }
  return VariationStorage;
}();
exports.VariationStorage = VariationStorage;