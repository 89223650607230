import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { forkJoin, Subscription, switchMap } from 'rxjs';
import { CompanyAddressData, CorrespondenceAddressData, PrivateAddressData } from '../../../../../assets/js/com/ts_api_client';
import { defaultValidatorProxy } from '../../../../shared/validators/default-validator-proxy';
import { CUSTOMERMODE } from '../../../enums/customerMode';
import { INPUT_TYPE } from '../../../enums/inputType.enum';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { DropdownItem } from '../../../models/dropdownItem';
import { BdoApiService } from '../../../services/bdo-api.service';
import { CustomerStoreService } from '../../../services/customer-store.service';
import { AddressFormComponent } from '../../address-form/address-form.component';
import { PrivateAddressFormtype } from '../../../../shared/formtypes/private-address.formtype';
import { GeweCompanyDataFormtype } from '../../../../shared/formtypes/gewe-company-data.formtype';
import { PersonalAddressFormtype } from '../../../../shared/formtypes/personal-address.formtype';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../services/toast.service';
import { Router } from '@angular/router';

@Component({
  selector: 'bdo-personal-data-address-form',
  templateUrl: './personal-data-address-form.component.html',
  styleUrls: ['./personal-data-address-form.component.scss']
})
export class PersonalDataAddressFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(AddressFormComponent, { static: true }) set content(content: AddressFormComponent) {
    if (content) { // initially setter gets called with undefined
        this.addressForm = content;
    }
  }
  @Input() correspondenceAddressData: CorrespondenceAddressData;
  @Output() editDone: EventEmitter<boolean> = new EventEmitter();
  public addressForm: AddressFormComponent;
  public forceValidate: boolean = false;
  public isGewe: boolean = false;
  public INPUT_TYPE = INPUT_TYPE;
  public state = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public titleDropdown: Array<DropdownItem>;
  public geweTitleDropdown: Array<DropdownItem>;
  public privTitleDropdown: Array<DropdownItem>;
  public customerModeDropdown: Array<DropdownItem>;
  public sectors: Array<DropdownItem> = [];
  public legalEntities: Array<DropdownItem> = [];
  public activeForm = new FormGroup<PersonalAddressFormtype>({
    customerMode: new FormControl('PRIV'),
    useForAll: new FormControl(true)
  });
  public privateAddressForm = new FormGroup<PrivateAddressFormtype>({
    title: new FormControl(null,
    [
      defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required'))
    ]),
    name: new FormControl('',
    [
      defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
      defaultValidatorProxy(Validators.maxLength(40), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 40 } ))
    ]),
    surname: new FormControl('',
    [
      defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
      defaultValidatorProxy(Validators.maxLength(40), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 40 } ))
    ]),
  });
  public geweAddressForm = new FormGroup<GeweCompanyDataFormtype>({
    title: new FormControl(null,
    [
      defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required'))
    ]),
    companyName: new FormControl('',
    [
      defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
      defaultValidatorProxy(Validators.maxLength(40), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 40 } ))
    ]),
    additional: new FormControl('', [
      defaultValidatorProxy(Validators.maxLength(40), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 40 } ))
    ]),
    sectorId: new FormControl('',
      [ defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required'))]
    ),
    legalEntityId: new FormControl('',
      [ defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required'))]
    ),
  });

  private subscriptions: Subscription = new Subscription();

  constructor(
    private apiService: BdoApiService,
    public customerStore: CustomerStoreService,
    private cdref: ChangeDetectorRef,
    private toastService: ToastService,
    private translateService: TranslateService,
    private router: Router
  ) { }


  ngOnInit(): void {
    this.subscriptions.add(this.translateService.onLangChange.pipe(
      switchMap(() =>
        forkJoin([
          this.apiService.getSalutations(CUSTOMERMODE.PRIV),
          this.apiService.getSalutations(CUSTOMERMODE.GEWE)
        ])
      )
    ).subscribe({
      next: ([privSalutations, geweSalutations]) => {
        this.customerModeDropdown = [
          { value: 'GEWE', label: this.translateService.instant('personaldata.address.commercial') },
          { value: 'PRIV', label: this.translateService.instant('personaldata.address.private') }
        ];

        this.privTitleDropdown = privSalutations?.list.map(
          salutation => new DropdownItem(salutation.id, this.translateService.instant('address.salutation.options.' + salutation.id))
        );
        this.geweTitleDropdown = geweSalutations?.list.map(
          salutation => new DropdownItem(salutation.id, this.translateService.instant('address.salutation.options.' + salutation.id))
        );
      }
    }));

    this.customerModeDropdown = [
      { value: 'GEWE', label: this.translateService.instant('personaldata.address.commercial') },
      { value: 'PRIV', label: this.translateService.instant('personaldata.address.private') }
    ];

    // Init Dropdown Data
    this.state = LOADING_STATE.LOADING;
    forkJoin([
      this.apiService.getSalutations(CUSTOMERMODE.PRIV),
      this.apiService.getSalutations(CUSTOMERMODE.GEWE),
      this.apiService.getSectors(),
      this.apiService.getLegalEntities()
    ]).subscribe(
      {
        next: ([privSalutations, geweSalutations, sectors, legalEntities]) => {
          this.privTitleDropdown = privSalutations?.list.map(
            salutation => new DropdownItem(salutation.id, this.translateService.instant('address.salutation.options.' + salutation.id))
          );
          this.geweTitleDropdown = geweSalutations?.list.map(
            salutation => new DropdownItem(salutation.id, this.translateService.instant('address.salutation.options.' + salutation.id))
          );

          this.sectors = sectors?.list
          // A-Z
          .sort((a, b) => {
            return a.text < b.text ? -1 : 1;
          })
          .map(sectorItem => new DropdownItem(sectorItem.sectorId, sectorItem.text));

          this.legalEntities = legalEntities?.list
          // A-Z and push Sonstige at the end of the list
          .sort((a, b) => {
            if (a.text === 'Sonstige') {
              return 1;
            }
            return a.text < b.text ? -1 : 1;
          })
          .map(entity => new DropdownItem(entity.legalEntityId, entity.text));

          this.state = LOADING_STATE.IDLE;
        },
        error: () => this.state = LOADING_STATE.ERROR,
        complete: () => this.subscriptions.add(this.activeForm.valueChanges.subscribe( { next: () => this.state = LOADING_STATE.IDLE })) // Remove errorBox
      }
    );
    this.router.navigate([], { fragment: 'correspondenceAddress', skipLocationChange: true });
  }

  ngAfterViewInit(): void {
    if (this.correspondenceAddressData?.companyAddressData) {
      this.activeForm.get('customerMode').setValue('GEWE');
    }

    this.cdref.detectChanges();
    this.activeForm?.addControl('address', this.addressForm.form);
    this.updateActiveForm(this.activeForm.get('customerMode').value === 'PRIV');

    this.subscriptions.add(this.activeForm.get('customerMode').valueChanges.subscribe({ next: (value) => {
      this.updateActiveForm(value === 'PRIV');
      this.cdref.detectChanges();
    } }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initActiveFormValues() {
    // Init existing values
    if (this.correspondenceAddressData?.companyAddressData && this.activeForm.get('customerMode').value === 'GEWE') {
      this.activeForm.get('gewe').get('companyName').setValue(this.correspondenceAddressData.companyAddressData.name1);
      this.activeForm.get('gewe').get('additional').setValue(this.correspondenceAddressData.companyAddressData.name2);
      this.activeForm.get('gewe').get('legalEntityId').setValue(this.correspondenceAddressData.companyAddressData.legalEntityId);
      this.activeForm.get('gewe').get('sectorId').setValue(this.correspondenceAddressData.companyAddressData.sectorId);
      this.activeForm.get('gewe').get('title').setValue(this.correspondenceAddressData.companyAddressData.titleId);
      this.addressForm.form.get('postCode').setValue(this.correspondenceAddressData.companyAddressData.postCode);
      this.addressForm.form.get('city').setValue(this.correspondenceAddressData.companyAddressData.cityName);
      this.addressForm.form.get('street').setValue(this.correspondenceAddressData.companyAddressData.streetName);
      this.addressForm.form.get('housenumber').setValue(this.correspondenceAddressData.companyAddressData.houseNum);
    }
    if (this.correspondenceAddressData?.privateAddressData && this.activeForm.get('customerMode').value === 'PRIV') {
      this.activeForm.get('priv').get('title').setValue(this.correspondenceAddressData.privateAddressData.titleId);
      this.activeForm.get('priv').get('name').setValue(this.correspondenceAddressData.privateAddressData.firstname);
      this.activeForm.get('priv').get('surname').setValue(this.correspondenceAddressData.privateAddressData.surname);
      this.activeForm.get('address')?.get('postCode').setValue(this.correspondenceAddressData.privateAddressData.postCode);
      this.activeForm.get('address')?.get('city').setValue(this.correspondenceAddressData.privateAddressData.cityName);
      this.activeForm.get('address')?.get('street').setValue(this.correspondenceAddressData.privateAddressData.streetName);
      this.activeForm.get('address')?.get('housenumber').setValue(this.correspondenceAddressData.privateAddressData.houseNum);
    }
    if (this.correspondenceAddressData) {
      this.activeForm.get('useForAll').setValue(this.correspondenceAddressData.useForAll);
    }
  }

  resetActiveForm() {
    this.privateAddressForm?.reset();
    this.geweAddressForm?.reset();
    this.addressForm?.form.reset();
  }

  updateActiveForm(isPrivateForm: boolean) {
    this.titleDropdown = isPrivateForm ? this.privTitleDropdown : this.geweTitleDropdown;
    this.activeForm.updateValueAndValidity();
    this.resetActiveForm();
    if (isPrivateForm) {
      this.activeForm.addControl('priv', this.privateAddressForm);
      this.activeForm.removeControl('gewe');
    } else {
      this.activeForm.addControl('gewe', this.geweAddressForm);
      this.activeForm.removeControl('priv');
    }
    this.isGewe = !isPrivateForm;

    this.activeForm.updateValueAndValidity();
    this.initActiveFormValues();
    this.cdref.detectChanges();
  }

  save() {
    if (this.activeForm.valid) {
      this.state = LOADING_STATE.LOADING;

      const privData: PrivateAddressData = this.activeForm.get('priv') ? {
        titleId: this.activeForm.get('priv').get('title').value,
        firstname: this.activeForm.get('priv').get('name').value,
        surname: this.activeForm.get('priv').get('surname').value,
        postCode: this.activeForm.get('address')?.get('postCode').value,
        cityName: this.activeForm.get('address')?.get('city').value,
        streetName: this.activeForm.get('address')?.get('street').value,
        houseNum: this.activeForm.get('address')?.get('housenumber').value
      } : null;
      const geweData: CompanyAddressData = this.activeForm.get('gewe') ? {
        name1: this.activeForm.get('gewe').get('companyName').value,
        name2: this.activeForm.get('gewe').get('additional').value,
        legalEntityId: this.activeForm.get('gewe').get('legalEntityId').value,
        sectorId: this.activeForm.get('gewe').get('sectorId').value,
        titleId: this.activeForm.get('gewe').get('title').value,
        postCode: this.activeForm.get('address')?.get('postCode').value,
        cityName: this.activeForm.get('address')?.get('city').value,
        streetName: this.activeForm.get('address')?.get('street').value,
        houseNum: this.activeForm.get('address').get('housenumber').value
      } : null;
      const data: CorrespondenceAddressData = {
        privateAddressData: privData,
        companyAddressData: geweData,
        useForAll: privData || geweData ? this.activeForm.get('useForAll').value : false
      };


      // Will delete if priv and companydata are null
      this.apiService.postUserCorrespondenceAddress(data)
      .subscribe(
        {
          next: () => {
            this.state = LOADING_STATE.IDLE;
            this.toastService.pushToast({
              headline: this.translateService.instant('personaldata.address.correspondence.success')
            });
            this.editDone.emit(true);
          },
          error: () => {
            this.state = LOADING_STATE.ERROR;
          }
        });

    } else {
      this.activeForm.markAllAsTouched();
      this.activeForm.updateValueAndValidity();
      this.forceValidate = true;
    }
  }
}
