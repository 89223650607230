<ng-container>
    <label [for]="inputName" class="animated fadeInUp"
      [class.floating]="getFloating(input)"
      [class.hidden]="getHidden(input)">
      {{labelText}}
    </label>
    <ng-container *ngFor="let error of errors">
      <div class="error" *ngIf="(((input.touched || focused) && showDelayed && !validationIconHidden) || (forceValidate && !(input.touched || focused))) &&
      (input.invalid && input.errors[error.name]) ">
        {{error.text}}
      </div>
    </ng-container>

    <div class="re-relative">
      <textarea #input="ngModel"
        [(ngModel)]="inputValue"
        [name]="inputName"
        [id]="inputName"
        (focus)="onFocus()"
        [required]="required"
        [bdoDefaultValidator]="errors"
        [placeholder]="placeholder"
        [rows]="rows"
        [maxlength]="maxLength"
        (blur)="onBlur()"
        (ngModelChange)="onChange($event)"></textarea>
      <div *ngIf="(maxLength)" class="re-text-xs re-text-right">{{inputValue?.length ? inputValue?.length : 0}} / {{maxLength}}</div>
      <div *ngIf="((forceValidate && !(input.touched || focused)) || showDelayed) && !validationIconHidden" class="validation-icon">
        <bdo-svg [name]="input.valid? 'icon-success' : 'icon-alert'" [styling]="input.valid? 'bdo-svg--success' : 'bdo-svg--error'"></bdo-svg>
      </div>
    </div>
</ng-container>
