/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TariffSummaryDurations { 
    /**
     * ID of the tariff
     */
    id?: string;
    /**
     * Name of the tariff
     */
    name?: string;
    contractSignedAt?: string;
    contractStart?: string;
    garantEndDate?: string;
    initTerm?: string;
    totalTerm?: string;
    cancelTerm?: string;
    cancelPeriod?: string;
    termString?: string;
}

