import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { CustomerStoreService } from '../bdo/services/customer-store.service';
import { inject } from '@angular/core';
import { BdoApiService } from '../bdo/services/bdo-api.service';
import { Observable, iif, map, of, switchMap, tap } from 'rxjs';
import { LoginService } from '../login/login.service';

export const guardRestrictedRegio: CanActivateFn = (route, state) : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const customerStoreService = inject(CustomerStoreService);
  const apiService = inject(BdoApiService);
  const loginService = inject(LoginService);
  const router = inject(Router);
  const accountId = customerStoreService.getAccountId();
  let loggedIn: boolean = false;

  // ignore if no account is set
  if (!accountId) {
    return true;
  }

  return loginService.isLoggedIn().pipe(
    tap(resLoggedIn => {
      loggedIn = resLoggedIn;
    }),
    switchMap((resLoggedIn) => {
      return iif(() => resLoggedIn,
                // switchmap the getAccounts call to not call the method immediately, since both expressions of iif are executed
                of(resLoggedIn).pipe(switchMap(() => apiService.getAccounts())),
                of([])
      );
    }),
    map(accounts => {
      if (!loggedIn) {
        return true;
      }
      const isRestricted = accounts.find(element => element?.accountId === accountId)?.eingeschraenkteRegio;
      if (isRestricted) {
        router.navigate(['/hinweis'], { replaceUrl: true });
      } else {
        return true;
      }
    })
  );
};
