import { Component, Input } from '@angular/core';

import { ALLOWED_KEYS, StorageService } from '../../../../services/storage.service';
import { TenantService } from '../../../../services/tenant.service';
import { ContractPaymentData } from '../../../../../../assets/js/com/ts_api_client';
import { AvailableDivisionInfos } from '../../new-home/product-selection/product-selection.component';
import { VERBRAUCHSTYP } from '../../../../enums/verbrauchstyp.enum';
import { TariffSelection } from '../../../../../shared/models/tariff-selection';

@Component({
  selector: 'bdo-tariffs-overview',
  templateUrl: './tariffs-overview.component.html'
})
export class TariffsOverviewComponent {
  @Input() public canceledDivisionIds: VERBRAUCHSTYP[];
  @Input() public impossibleDivisionIds: VERBRAUCHSTYP[];
  public paymentData: ContractPaymentData;
  public availableDivisionInfos: AvailableDivisionInfos;
  public tariffSelections: TariffSelection[];

  constructor(public tenantService: TenantService) {
    this.availableDivisionInfos = StorageService.getValue<AvailableDivisionInfos>(ALLOWED_KEYS.AVAILABLE_DIVISIONS);
    this.tariffSelections = StorageService.getTariffSelections();
  }

  getTariffByDivisionId(divisionId: VERBRAUCHSTYP): TariffSelection {
    return this.tariffSelections.find((item) => item.divisionConfiguration.id === divisionId.toString());
  }

  getTariffTitleByDivisionId(divisionId: VERBRAUCHSTYP) {
    return this.getTariffByDivisionId(divisionId)?.selectedTariff.name;
  }

}
