<div *ngIf="meters">
  <div *ngFor="let formFieldForFrontend of formFieldsForFrontend; let i = index;" class="re-mb-45">
    <ng-template #selfie [ngTemplateOutlet]="selfie"
                let-control [ngTemplateOutletContext]="{ $implicit: getMetersFormControl(i) }">

      <ng-container *ngIf="i > 0">
        <hr class="re-my-30 md:re-my-45 re-border-t re-border-monochrome-medium-light">

        <bdo-circle-icon-text-small
          data-qa="remove_meter"
          [iconStyle]="'minus'"
          [label]="'meterreadings.actions.removeMeter' | translate: { division: [sparte | verbrauchstyp] }"
          (clickEvent)="removeMeter(i)"
        ></bdo-circle-icon-text-small>
      </ng-container>

      <div class="re-form-item-container">
        <bdo-input
          [id]="'meterNumber' + i"
          [attr.data-qa_id]="'meterNumber' + i"
          (blur)="trackInvalid(formFieldForFrontend.meterFormControl, 'meterNumber' + i)"
          [formControl]="formFieldForFrontend.meterFormControl"
          [isWarning]="formFieldForFrontend.message === CheckMeterResponse.METER_NOT_EXISTS"
          [labelText]="(formFieldForFrontend.message === CheckMeterResponse.METER_NOT_EXISTS ? 'meterreadings.warning.meterNotExist' : 'general.customerData.meternumber') | translate"
          [placeholder]="'general.customerData.meternumber' | translate"
          [tooltipContent]="formFieldForFrontend.message === CheckMeterResponse.METER_NOT_EXISTS ? undefined : meterInfoContent"
        >
        </bdo-input>
        <div class="bdo-form__helptext"
          *ngIf="formFieldForFrontend.message === CheckMeterResponse.METER_NOT_EXISTS && sparte === Verbrauchstyp.Strom"
          [innerHTML]="'meterreadings.warning.nightStorageHeater' | translate: { contactFormLink: tenantService.getCurrentTenantData().hrefContactForm }"
          (click)="onClickHelp($event)">
        </div>
      </div>

      <div *ngIf="formFieldForFrontend.readings && formFieldForFrontend.readings.length && (formFieldForFrontend.meterFormControl.valid || formFieldForFrontend.meterFormControl.pending)">
        <div [@fadeAnimation] class="re-form-item-container" *ngFor="let reading of formFieldForFrontend.readings; let u = index">
          <bdo-input
            [id]="'reading' + i + u"
            [attr.data-qa_id]="'reading' + i + u"
            [formControl]="reading.readingFormControl"
            (blur)="trackInvalid(reading.readingFormControl, 'reading' + i + u);"
            [placeholder]="getPlaceholder(reading)"
            [tooltipContent]="meterreadingInfoService.getInfo(sparte)"
          >
          </bdo-input>
          <div class="bdo-form__helptext" [innerHTML]="'meterreadings.info.enterLater' | translate">
          </div>
        </div>
      </div>
      <div *ngIf="showSmartMeterHint && formFieldForFrontend?.meterCheckResult?.isSmartMeter">
        {{ 'meterreadings.smartmeter.readingInfo' | translate }}
      </div>

      <!-- Optional Contract Cancellation -->
      <div [formGroup]="control" *ngIf="cancelOptionsShown">
        <div class="bold re-text-sm re-mb-20">{{ 'delivery.situation.terminateTitle' | translate: { division: [sparte | verbrauchstyp] } }}</div>
        <div *ngIf="control.get('contractExisting')?.invalid && control.get('contractExisting')?.touched" class="re-mb-20 re-text-error">
          {{ 'general.action.chooseOption' | translate }}
        </div>
        <div class="re-flex re-items-start re-relative re-mb-15">
          <input [id]="'contractNotExists' + sparte + i" type="radio" [value]="'contractNotExists'" formControlName="contractExisting">
          <label class="re-ml-10" [for]="'contractNotExists' + sparte + i" >
            <span [innerHTML]="'delivery.situation.noContractExist' | translate"></span>
          </label>
        </div>
        <div class="re-flex re-items-start re-relative re-mb-15">
          <input [id]="'contractExists' + sparte + i" type="radio" [value]="'contractExists'" formControlName="contractExisting">
          <label class="re-ml-10" [for]="'contractExists' + sparte + i" >
            <span [innerHTML]="'delivery.situation.contractExist' | translate"></span>
          </label>
        </div>
        <div class="re-ml-40" *ngIf="control.get('contractExisting')?.value === 'contractExists'">
          <bdo-checkbox
            [checked]="control.get('automaticCancelEnabled').value"
            (change)="onAutomaticCancelChange(control.get('automaticCancelEnabled'))"
            data-qa="cancel_auto">
            <div [innerHtml]="'delivery.situation.withCancel' | translate:
                              { companyName: tenantService.getCurrentTenantData().companyName, division: [sparte | verbrauchstyp] }">
            </div>
          </bdo-checkbox>
        </div>
        <div *ngIf="control.get('contractExisting').value === 'contractExists' && control.get('automaticCancelEnabled')?.value && control.get('previousSupplier')"
          class="re-mt-20">
          <bdo-supplier-autosuggest
            [divisionId]="sparte"
            [supplierControl]="control.get('previousSupplier')"
            [supplierIdControl]="control.get('previousSupplierId')"
            [id]="'supplier_' + sparte + '_' + i"
          ></bdo-supplier-autosuggest>
        </div>
      </div>
      <!-- End Optional Contract Cancellation -->
    </ng-template>
  </div>
</div>



<hr class="re-my-30 md:re-my-45 re-border-t re-border-monochrome-medium-light">

<bdo-circle-icon-text-small
  data-qa="add_meter"
  [iconStyle]="'plus'"
  [label]="'meterreadings.actions.addMeter' | translate: { division: [sparte | verbrauchstyp] }"
  (clickEvent)="addMeterFormGroup()"
></bdo-circle-icon-text-small>
