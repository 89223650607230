<div class="bdo-loading__wrapper" [class.bdo-loading__wrapper--height]="isLoading">
  <div class="re-px-20 re-pt-30 md:re-px-30 md:re-pt-45">
    <bdo-loading *ngIf="isLoading"></bdo-loading>
    <h5>
      <div *ngIf="iconSrc" class="re-w-60 re-h-60 re-mb-5 md:re-inline-block md:re-mb-0">
        <bdo-svg-cms [iconSrc]="iconSrc" [iconSize]="60"></bdo-svg-cms>
      </div>
      {{tariffData?.name}}
    </h5>

    <div>
      <div *ngIf="tariffData?.benefits?.length > 0" class="re-mt-20 re-mb-30">
        <span class="bold">{{ 'summary.tariff.dataTitle' | translate }}</span>
        <ul class="bdo-list__bullet re-mt-30">
          <li *ngFor="let item of tariffData?.benefits">
            <span>{{item}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <hr class="re-border-t-3 re--mx-20 re-border-monochrome-light md:re--mx-30">

  <bdo-tariff-prices-overview
    [prices]="prices"
    [tariffData]="tariffData"
  ></bdo-tariff-prices-overview>

  <!-- Kostenberechnung -->
  <hr class="re-border-t-3 re--mx-20 re-border-monochrome-light md:re--mx-30">
  <bdo-tariff-prices-cost-info class="re-my-20 md:re-my-25"
    [tariffData]="tariffData"
  ></bdo-tariff-prices-cost-info>


  <ng-container *ngIf="tariffData?.durations?.[0]?.termString">
    <hr class="re-border-t-3 re--mx-20 re-border-monochrome-light md:re--mx-30">
    <!-- Details -->
    <bdo-tariff-details [tariffData]="tariffData"></bdo-tariff-details>
  </ng-container>
</div>
