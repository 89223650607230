import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BillingPlanItem, BillingPlanSuggestion, BillingPlanSuggestionItem, DivisionItem } from '../../../assets/js/com/ts_api_client';
import { VERBRAUCHSTYP } from '../enums/verbrauchstyp.enum';
import { BdoApiService } from './bdo-api.service';

export enum BILLING_STATES {
  ALL_OK,
  MISSING_READING,
  ADJUSTMENT_NECESSARY,
  ADJUSTMENT_NECESSARY_CHANGENOTALLOWED,
  LOWER_RECOMMENDED,
  LOWER_RECOMMENDED_CHANGENOTALLOWED
}

export interface BillingSuggestionResult {
  billingSuggestion: BillingPlanSuggestion;
  billingState: BILLING_STATES;
  changesAllowed: boolean;
  missingReadings: Array<DivisionItem>;
}

export interface BillingSuggestionResultByDivision {
  contractId: string,
  division: VERBRAUCHSTYP,
  recommended: boolean,
  billingState: BILLING_STATES;
  changesAllowed: boolean;
  currentAmount: number,
  proposedAmount: number
}

@Injectable({
  providedIn: 'root'
})
export class BillingPlanSuggestionService {
  private billingState = BILLING_STATES.ALL_OK;
  private missingReadings: Array<DivisionItem> = [];
  private changesAllowed: boolean = true;
  private billingSuggestionResultByDivision: Array<BillingSuggestionResultByDivision> = [];

  constructor(
    private apiService: BdoApiService
  ) {}

  getSuggestions(): Observable<BillingSuggestionResult> {
    return this.apiService.getBillingPlanSuggestion().pipe(
      map(( suggestion) => {
        this.changesAllowed = !suggestion.noChange;
        if (suggestion.missingReadings?.length > 0) {
          this.billingState = BILLING_STATES.MISSING_READING;
          this.missingReadings = suggestion.missingReadings;
        } else {
          const billingItemsRecommended = suggestion.items.filter(billingItem => billingItem.recommended);
          if (billingItemsRecommended.length > 0) {
            const isAdjustmentNecessary = billingItemsRecommended.some((item: BillingPlanSuggestionItem) => item.currentAmount < item.proposedAmount);
            const isLowerRecommended = billingItemsRecommended.some((item: BillingPlanSuggestionItem) => item.currentAmount > item.proposedAmount);
            if (isAdjustmentNecessary) {
              this.billingState = BILLING_STATES.ADJUSTMENT_NECESSARY;
            } else if (isLowerRecommended){
              this.billingState = BILLING_STATES.LOWER_RECOMMENDED;
            }
          }
          if (!this.changesAllowed) {
            if (this.billingState === BILLING_STATES.ADJUSTMENT_NECESSARY) {
              this.billingState = BILLING_STATES.ADJUSTMENT_NECESSARY_CHANGENOTALLOWED;
            } else if (this.billingState === BILLING_STATES.LOWER_RECOMMENDED) {
              this.billingState = BILLING_STATES.LOWER_RECOMMENDED_CHANGENOTALLOWED;
            }
          }
        }

        return {
          billingSuggestion: suggestion,
          billingState: this.billingState,
          changesAllowed: this.changesAllowed,
          missingReadings: this.missingReadings
        };
      })
    );
  }

  getSuggestionsByDivision(billingPlans: Array<BillingPlanItem>): Observable<Array<BillingSuggestionResultByDivision>> {
    return this.apiService.getBillingPlanSuggestion()
    .pipe(
      map((suggestion) => {
        this.billingSuggestionResultByDivision = [];
        this.changesAllowed = !suggestion.noChange;

        billingPlans.forEach(billingItem => {

          this.billingState = BILLING_STATES.ALL_OK;
          const hasMissingReadings: boolean = suggestion.missingReadings?.some(reading => reading.name === billingItem.division);

          const currentBillingSuggestion: BillingPlanSuggestionItem = suggestion.items?.find(item => item.contractId === billingItem.contractId);

          if (hasMissingReadings) {
            this.billingState = BILLING_STATES.MISSING_READING;
          } else {
            if (currentBillingSuggestion.recommended && currentBillingSuggestion.currentAmount < currentBillingSuggestion.proposedAmount) {
              this.billingState = BILLING_STATES.ADJUSTMENT_NECESSARY;
            } else if (currentBillingSuggestion.recommended && currentBillingSuggestion.currentAmount > currentBillingSuggestion.proposedAmount) {
              this.billingState = BILLING_STATES.LOWER_RECOMMENDED;
            }
            if (!this.changesAllowed) {
              if (this.billingState === BILLING_STATES.ADJUSTMENT_NECESSARY) {
                this.billingState = BILLING_STATES.ADJUSTMENT_NECESSARY_CHANGENOTALLOWED;
              } else if (this.billingState === BILLING_STATES.LOWER_RECOMMENDED) {
                this.billingState = BILLING_STATES.LOWER_RECOMMENDED_CHANGENOTALLOWED;
              }
            }
          }

          this.billingSuggestionResultByDivision.push({
            contractId: billingItem.contractId,
            division: billingItem.division as VERBRAUCHSTYP,
            recommended: this.billingState === BILLING_STATES.MISSING_READING ? false : currentBillingSuggestion.recommended,
            billingState: this.billingState,
            changesAllowed: this.changesAllowed,
            currentAmount: currentBillingSuggestion.currentAmount,
            proposedAmount: currentBillingSuggestion.proposedAmount
          });
        });

        return this.billingSuggestionResultByDivision;
      })
    );
  }
}
