import { Component, OnDestroy } from '@angular/core';
import { TenantService } from '../../services/tenant.service';
import { Location } from '@angular/common';
import { NavigationState } from '../../models/navigationState';
import { Utilities } from '../../../shared/utils/utilities';
import { LinkData } from '../../models/LinkData';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bdo-process-not-available',
  templateUrl: './process-not-available.component.html',
  styleUrls: ['./process-not-available.component.scss']
})
export class ProcessNotAvailableComponent implements OnDestroy {

  public context: string;
  public link: string;
  public title: string;
  public linkData: LinkData;
  private subscription: Subscription;

  constructor(
    public tenantService: TenantService,
    private translateService: TranslateService,
    private location: Location
  ) {
    const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
    this.context = currentState?.context;
    this.link = currentState?.link || this.tenantService.getCurrentTenantData().hrefContact;
    this.title = currentState?.process;
    this.subscription = this.translateService.stream('general.navigation.backToHomepage').subscribe({
      next: (value) => {
        this.linkData = {
          href: this.tenantService.getCurrentTenantData().hrefHomepage,
          linkName: value
        };
      }
    });
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }
}
