// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TRACKING {
  export enum LOCATION {
    'ACCOUNTINPROGRESS' = 'AccountInProgress',
    'ANONYMOUS_AUTHENTICATION' = 'Authentifizierung.KundenZaehlernummer',
    'ALERT' = 'alert',
    'ANONYMOUS_METERREADINGS' = 'zaehlerstand-erfassen-anonym',
    'APP_NO_TOS' = 'App.withoutToS',
    'APP' = 'App',
    'BANKING' = 'Bankdata',
    'BANKACCOUNT_EDIT' = 'BankAccount.EditAssignments',
    'BANKING_ASSIGNMENT_SUCCESS' = 'Bankdata.Assignment.Success',
    'BANKING_CREATE_SUCCESS' = 'Bankdata.Create.Success',
    'BANKING_CREATE' = 'Bankdata.Create',
    'BANKING_DELETE_SUCCESS' = 'Bankdata.Delete.Success',
    'BANKING_DELETE' = 'Bankdata.DELETE',
    'BILLING_PLAN' = 'Abschlagsplan',
    'BILLING_EDIT' = 'Abschlag.anpassen',
    'CONTACT' = 'Kontaktformular',
    'CONTACT_SUCCESS' = 'Kontaktformular.Success',
    'CONTRACT_CANCEL_STATUS' = 'Kündigungsprozess - - Schritt 1 / Kündigungsart',
    'CONTRACT_CANCEL_AUTH' = 'Kündigungsprozess - - Schritt 2 / Login Kündigung',
    'CONTRACT_CANCEL_CONTRACTS' = 'Kündigungsprozess - - Schritt 3 / Vertragsauswahl',
    'CONTRACT_CANCEL_DATE' = 'Kündigungsprozess - - Schritt 4 / Vertragsende',
    'CONTRACT_CANCEL_BILLING' = 'Kündigungsprozess - - Schritt 5 / Rechnungsadresse',
    'CONTRACT_CANCEL_SUMMARY' = 'Kündigungsprozess - - Schritt 6 / Zusammenfassungsseite',
    'CONTRACT_CANCEL_DONE' = 'Kündigungsprozess - - Schritt 7 / Bestätigungsseite',
    'CONTRACT_CHANGE_TARIFFSELECTION' = 'Tarifwechsel -- Schritt 1/Tarifauswahl',
    'CONTRACT_CHANGE_SUMMARY' = 'Tarifwechsel -- Schritt 2/Zusammenfassungsseite',
    'CONTRACT_CHANGE_DONE' = 'Tarifwechsel -- Schritt 3/Fertig',
    'CONTRACT_END_OVERVIEW' = 'Lieferstelle abmelden - Weichenseite Prozessauswahl',
    'CONTRACT_END_RECENT' = 'Lieferstelle abmelden - Schritt 1/Bisherige Adresse',
    'CONTRACT_END_BILLING' = 'Lieferstelle abmelden -- Schritt 2/Zahlungsart',
    'CONTRACT_END_BILLING_ADDRESS' = 'Lieferstelle abmelden -- Schritt 3/Rechnungsadresse',
    'CONTRACT_END_SUMMARY' = 'Lieferstelle abmelden -- Schritt 4/Zusammenfassungsseite ',
    'CONTRACT_END_DONE' = 'Lieferstelle abmelden -- Schritt 5/Fertig',
    'CONTRACT_NEW_ADDRESS' = 'Weitere Lieferstelle -- Schritt 1/Wo ist ihr Zähler?',
    'CONTRACT_NEW_PRODUCTS' = 'Weitere Lieferstelle -- Schritt 2/Spartenauswahl',
    'CONTRACT_NEW_TARIFFS' = 'Weitere Lieferstelle -- Schritt 3/Tarifauswahl',
    'CONTRACT_NEW_BILLING' = 'Weitere Lieferstelle -- Schritt 4/Zahlungsart',
    'CONTRACT_NEW_SUMMARY' = 'Weitere Lieferstelle -- Schritt 5/Zusammenfassungsseite',
    'CONTRACT_NEW_DONE' = 'Weitere Lieferstelle -- Schritt 6/Fertig',
    'CONTRACT_OVERVIEW' = 'Vertragsübersicht',
    'CAMPAIGN' = 'Campaign',
    'CONSTRUCTION' = 'Baustelle',
    'CONSUMPTION' = 'verbrauchsHistorie',
    'COOKIEBAR' = 'Cookiebar',
    'DASHBOARD' = 'Dashboard',
    'DELIVERY' = 'Tarifabschluss',
    'DELIVERY_CUSTOMERSTATUS' = 'Tarifabschluss -- Schritt 1/Kennen wir uns',
    'DELIVERY_TARIFFSELECTION' = 'Tarifabschluss -- Schritt 1/Tarifauswahl',
    'DELIVERY_PERSONALDATA' = 'Tarifabschluss -- Schritt 2/Persönliche Daten',
    'DELIVERY_SITUATION' = 'Tarifabschluss -- Schritt 3/Situation',
    'DELIVERY_PAYMENT' = 'Tarifabschluss -- Schritt 4/Zahlungsart',
    'DELIVERY_SUMMARY' = 'Tarifabschluss -- Schritt 5/Zusammenfassung',
    'DELIVERY_CONFIRMATION' = 'Tarifabschluss -- Bestätigung',
    'DOCUMENTS' = 'Dokumente',
    'EFFZEH_TEASER' = 'EffzehTeaser',
    'EFFZEH_LANDING' = 'EffzehLandingpage',
    'EMAIL_VERIFICATION' = 'EmailVerifizierung',
    'EXTERNAL_BILL_EXPLANATION' = 'Rechnungserklärung',
    'EXTERNAL_PRICECAP' = 'InformationenZurPreisbremse',
    'FEEDBACK' = 'Feedback',
    'FOOTER' = 'Footer',
    'FREQUENT_FUNCTIONS' = 'FrequentFunctions',
    'HOMEPAGE' = 'Homepage',
    'IMSYS_CHART' = 'Imsys.Chart',
    'LANGUAGE_SELECTION' = 'Sprachauswahl',
    'LOGIN' = 'Login',
    'LOGO' = 'Logo',
    'LOGOUT' = 'Logout',
    'LOGOUT_CONFIRMATION' = 'LogoutConfirmation',
    'LOGOUT_SUCCESS' = 'LogoutSuccess',
    'METERREADINGS' = 'Zaehlerstand',
    'METERREADINGS_ADD' = 'Zaehlerstand.erfassen',
    'METERREADINGS_EDIT' = 'Zählerstand.editieren',
    'METERREADINGS_SUCCESS' = 'Zählerstand.saved',
    'MOVE_AUTH' = 'Umzugsservice -- Schritt 1/Umzug mitteilen',
    'MOVE_RECENTHOME' = 'Umzugsservice -- Schritt 2/Mein bisheriges Zuhause',
    'MOVE_NEWHOME' = 'Umzugsservice -- Schritt 3/Neues Zuhause',
    'MOVE_TARIFF' = 'Umzugsservice -- Schritt 4/Tarifauswahl',
    'MOVE_PAYMENT' = 'Umzugsservice -- Schritt 5/Zahlungsart',
    'MOVE_SUMMARY' = 'Umzugsservice -- Schritt 6/Zusammenfassung',
    'MOVE_CONFIRMATION' = 'Umzugsservice -- Schritt 7/Fertig',
    'NETCOLOGNE_TEASER' = 'NetcologneTeaser',
    'NETCOLOGNE_LANDINGPAGE' = 'NetCologne-Landingpage',
    'OFFER' = 'Campaign -- DTA',
    'OPT_IN' = 'Opt-in Popup',
    'PASSWORD_FORGOT' = 'PasswortVergessen',
    'PAYMENTS' = 'Payments',
    'PAYMENTS_DEFERRAL' = 'Später zahlen',
    'PERSONAL_DATA' = 'Übersicht Persönliche Daten',
    'PERSONAL_DATA_OPT_IN_FROM_POPUP' = 'Persönliche Daten Opt-In via Popup',
    'PERSONAL_DATA_OPT_IN' = 'Persönliche Daten Opt-In',
    'PHONE_MODAL' = 'Phone Modal',
    'PRODUCT_SELECTION' = 'Produktauswahl',
    'PROGRESSBAR' = 'WizardProgressbar.noLink',
    'REGISTER' = 'Register',
    'REGISTER_ACTIVATE' = 'RegisterActivate',
    'REGISTER_SUCCESS' = 'RegistrierungSuccess',
    'SALESFORCE' = 'Salesforce',
    'SIMINVOICE' = 'Siminvoice',
    'SIMINVOICE_SUCCESS' = 'SiminvoiceSuccess',
    'STORAGEHEATER' = 'Nachtspeicherheizung',
    'TARIFF_ADVISOR' = 'Tarifrechner',
    'TERMSOFSERVICE_CONFIRMATION' = 'Nutzungsbedingungen.Bestätigung',
    'TERMSOFSERVICE' = 'Nutzungsbedingungen',
    'USERICON' = 'User-Icon',
    'USERNAME_FORGOT' = 'BenutzernameVergessen',
    'VEBOUT_EXISTING' = 'AuszugVorhanden',
    'VOUCHERS' = 'Gutscheine',
    'WEBSITE' = 'Webseite',
    'EMPTY' = '',
  }

  export enum ACTION {
    'ACCEPT'= 'accept',
    'CANCEL'= 'cancel',
    'CLICK'= 'click',
    'CLOSE'= 'close',
    'CREATED'= 'created',
    'DONE'= 'done',
    'DOWNLOAD'= 'Download',
    'ENTER'= 'Enter',
    'ERROR'= 'Error',
    'EXPIRED'= 'expired',
    'FAILURE'= 'Failure',
    'GOTO'= 'goto',
    'IMPLAUSIBLE' = 'is implausible',
    'INVALID' = 'invalid',
    'MORE' = 'more',
    'OPEN' = 'open',
    'SAVE' = 'speichern',
    'SET' = 'set',
    'SHOW' = 'show',
    'SUCCESS' = 'Success',
    'VIEW' = 'view',
    'EMPTY' = ''
  }

  export enum ELEMENTS {
    'BUTTON' = 'Button',
    'DIALOG' = 'Dialog',
    'METERNUMBER' = 'Zaehlernummer',
    'SUGGESTION' = 'Vorschlag',
    'THANKYOU' = 'Thankyou',
    'EMPTY' = ''
  }


  export enum FORM {
    'DELIVERY_PERSONALDATA_NAME' = 'Persönliche Daten',
    'DELIVERY_PERSONALDATA_SECTION_DATA' = 'Ihre Persönlichen Daten',
    'DELIVERY_PERSONALDATA_SECTION_ADDRESS' = 'Adresse Lieferstelle',
    'DELIVERY_PERSONALDATA_SECTION_OSACCOUNT' = 'Benutzerkonto für OnlineService',
    'DELIVERY_SITUATIONDATA_NAME' = 'Situation',
    'DELIVERY_SITUATIONDATA_SECTION' = 'Belieferung',
    'DELIVERY_METERDATA_SECTION' = 'Zählernummer und Zählerstand',
    'DELIVERY_PAYMENTDATA' = 'Zahlungsart',
    'MOVE_RECENTHOME_NAME' = 'Bisheriges Zuhause',
    'MOVE_RECENTHOME_SECTION_METERS' = 'Zählerstände bei Auszug',
    'MOVE_RECENTHOME_SECTION_MOVEOUTDATE' = 'Auszugsdatum',
    'MOVE_NEWHOME_NAME' = 'Neues Zuhause',
    'MOVE_NEWHOME_SECTION_ADDRESS' = 'Adresse',
    'MOVE_NEWHOME_SECTION_SELECTION' = 'Angaben zur Belieferung',
    'MOVE_PAYMENT_NAME' = 'Zahlungsart',
    'MOVE_PAYMENT_SECTION_OTHER' = 'Abweichende Zahlungsweise hinzufügen',
  }

  export enum FORM_ACTION {
    'VIEW' = 'view',
    'SELECT_AUTOSUGGEST' = 'select_autosuggest',
    'SELECT' = 'select',
    'INTERACTION' = 'field_interaction',
    'ERROR' = 'field_error',
    'SUBMIT' = 'submit',
  }
}
