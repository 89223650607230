;
export class ParallelHasher {
  constructor(workerUri, workerOptions) {
    this._queue = [];
    this._ready = true;
    const self = this;
    if (Worker) {
      self._hashWorker = new Worker(workerUri, workerOptions);
      self._hashWorker.onmessage = self._recievedMessage.bind(self);
      self._hashWorker.onerror = err => {
        self._ready = false;
        console.error('Hash worker failure', err);
      };
    } else {
      self._ready = false;
      console.error('Web Workers are not supported in this browser');
    }
  }
  /**
   * Hash a blob of data in the worker
   * @param blob Data to hash
   * @returns Promise of the Hashed result
   */
  hash(blob) {
    const self = this;
    let promise;
    promise = new Promise((resolve, reject) => {
      self._queue.push({
        blob,
        resolve,
        reject
      });
      self._processNext();
    });
    return promise;
  }
  /** Terminate any existing hash requests */
  terminate() {
    this._ready = false;
    this._hashWorker.terminate();
  }
  // Processes the next item in the queue
  _processNext() {
    if (this._ready && !this._processing && this._queue.length > 0) {
      this._processing = this._queue.pop();
      this._hashWorker.postMessage(this._processing.blob);
    }
  }
  // Hash result is returned from the worker
  _recievedMessage(evt) {
    var _a, _b;
    const data = evt.data;
    if (data.success) {
      (_a = this._processing) === null || _a === void 0 ? void 0 : _a.resolve(data.result);
    } else {
      (_b = this._processing) === null || _b === void 0 ? void 0 : _b.reject(data.result);
    }
    this._processing = undefined;
    this._processNext();
  }
}
