
<div class="re-mb-60 md:re-mb-90" bdoRouterLinkDirective>
  <bdo-faqs
    [keyName]="'personaldata.faqs'"
    [params]="{ contactFormLink: tenantService.getCurrentTenantData().hrefContactForm, company: tenant }"
  ></bdo-faqs>
</div>



<div class="bdo-container">
  <bdo-section-headline>
    <div class="re-mb-default">
      {{ 'personaldata.moreServices.title' | translate }}
    </div>
  </bdo-section-headline>
</div>

<bdo-promo-link-wrapper [promoLinkDataSet]="promoLinkDataSet"></bdo-promo-link-wrapper>
