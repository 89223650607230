import { Component, Input } from '@angular/core';
import { WizardStep } from '../../../bdo/models/wizardStep';
import { TRACKING } from '../../../bdo/enums/trackingParts.enum';
import { TrackingService } from '../../../bdo/services/tracking.service';

@Component({
  selector: 'bdo-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {

  @Input() activeWizardStep: number = 1;
  @Input() wizardSteps: Array<WizardStep>;

  constructor( private trackingService: TrackingService ) {
  }

  trackWizardStepClick(step) {
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.PROGRESSBAR, TRACKING.ACTION.CLICK + ' step' + step);
  }

}
