
export class FormValidationError {
  condition?: boolean;
  name: string;
  text: string;

  constructor(name: string, text: string, condition?: boolean) {
    this.condition = condition;
    this.name = name;
    this.text = text;
  }

}
