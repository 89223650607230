import { Component, Input, OnInit } from '@angular/core';
import { TariffSummaryParts, TariffSummaryResults, TariffTextItem } from '../../../../assets/js/com/ts_api_client';
import { CUSTOMERMODE } from '../../enums/customerMode';
import { VERBRAUCHSTYP } from '../../enums/verbrauchstyp.enum';
import { DatadogService } from '../../services/datadog.service';
import { BdoApiService } from '../../services/bdo-api.service';

@Component({
  selector: 'bdo-tariff-price-cap',
  templateUrl: './tariff-price-cap.component.html',
  styleUrls: ['./tariff-price-cap.component.scss']
})
export class TariffPriceCapComponent implements OnInit {
  @Input() tariffDataParts: TariffSummaryParts;
  @Input() isGewe: boolean;
  @Input() division: string;
  public priceCapInfo: string;
  public hasBonus: boolean;

  constructor(
    public apiService: BdoApiService,
    public datadogService: DatadogService
  ) { }

  ngOnInit(): void {
    this.hasBonus = this.tariffDataParts?.contractPayments.some(elem => elem.description.length > 0);

    let tariffTextKey: string = '';
    switch (this.division) {
      case VERBRAUCHSTYP.Strom:
        tariffTextKey = 'priceBrake_E';
        break;
      case VERBRAUCHSTYP.Gas:
        tariffTextKey = 'priceBrake_G';
        break;
    }

    if (tariffTextKey) {
      this.apiService.getTariffText(tariffTextKey)
        .subscribe({
          next: (tariffTextItem: TariffTextItem) => {
            this.priceCapInfo = tariffTextItem?.infotext;
          },
          error: () => {
            this.priceCapInfo = undefined;
          }
      });
    } else {
      this.datadogService.trackCustomEvent('Show PriceCap Info-I: Division ' + this.division + ' has no key for getTariffText().');
    }
  }

}
