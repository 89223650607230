import { assign, includes } from '../../tools/utils/polyfills';
import { createEndpointBuilder } from './endpointBuilder';
import { buildTags } from './tags';
import { INTAKE_SITE_US1, INTAKE_URL_PARAMETERS } from './intakeSites';
export function computeTransportConfiguration(initConfiguration) {
  var site = initConfiguration.site || INTAKE_SITE_US1;
  var tags = buildTags(initConfiguration);
  var endpointBuilders = computeEndpointBuilders(initConfiguration, tags);
  var replicaConfiguration = computeReplicaConfiguration(initConfiguration, tags);
  return assign({
    replica: replicaConfiguration,
    site: site
  }, endpointBuilders);
}
function computeEndpointBuilders(initConfiguration, tags) {
  return {
    logsEndpointBuilder: createEndpointBuilder(initConfiguration, 'logs', tags),
    rumEndpointBuilder: createEndpointBuilder(initConfiguration, 'rum', tags),
    sessionReplayEndpointBuilder: createEndpointBuilder(initConfiguration, 'replay', tags)
  };
}
function computeReplicaConfiguration(initConfiguration, tags) {
  if (!initConfiguration.replica) {
    return;
  }
  var replicaConfiguration = assign({}, initConfiguration, {
    site: INTAKE_SITE_US1,
    clientToken: initConfiguration.replica.clientToken
  });
  var replicaEndpointBuilders = {
    logsEndpointBuilder: createEndpointBuilder(replicaConfiguration, 'logs', tags),
    rumEndpointBuilder: createEndpointBuilder(replicaConfiguration, 'rum', tags)
  };
  return assign({
    applicationId: initConfiguration.replica.applicationId
  }, replicaEndpointBuilders);
}
export function isIntakeUrl(url) {
  // check if tags is present in the query string
  return INTAKE_URL_PARAMETERS.every(function (param) {
    return includes(url, param);
  });
}
