import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bdo-select-button',
  templateUrl: './select-button.component.html',
  styleUrls: ['./select-button.component.scss']
})
export class SelectButtonComponent {

  /** select-button is active */
  @Input() active: boolean;

  /** select-button is completed */
  @Input() completed: boolean;

  /** select-button is clickable */
  @Input() clickable: boolean;

  /** displayed title */
  @Input() title: string;

  /** path of the icon */
  @Input() iconSource: string;

  @Output() activate = new EventEmitter();

  onClick() {
    if (this.clickable) {
      this.activate.emit();
    }
  }
}
