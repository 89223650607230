import { Pipe, PipeTransform } from '@angular/core';
import { ABLESEGRUND } from '../enums/ablesegrund';

@Pipe({
  name: 'mapAblesegrund'
})
/** Maps IDs of 'Ablesegrund' to customer-readable Strings */
export class MapAblesegrundPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const mappedValue = ABLESEGRUND[value];

    if (!mappedValue) {
      return 'Undefiniert';
    } else {
      return mappedValue;
    }
  }

}
