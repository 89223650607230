import { Component, OnInit } from '@angular/core';
import { Utilities } from '../../../utils/utilities';

@Component({
  selector: 'bdo-poll-done',
  templateUrl: './poll-done.component.html',
  styleUrls: ['./poll-done.component.scss']
})
export class PollDoneComponent implements OnInit{

  // persist, that the user has already done a poll
  ngOnInit(): void {
    Utilities.createCookie('poll_shown', '1', 1.5 / 24);
  }
}
