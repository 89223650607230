export enum OFFER_CONTEXT {
  LIEFERANTENWECHSEL = 'LIEFERANTENWECHSEL',
  NEUANMELDUNG = 'NEUANMELDUNG',
  REAKQUISE = 'REAKQUISE',
  UMZUG = 'UMZUG',
  UPSELLING = 'UPSELLING'
}

/* Specially named  */
export enum OFFER_CONTEXT_SPECIAL {
  LIEFERANTENWECHSEL_C = 'LIEFERANTENWECHSEL_C',
  REAQUISE_INBOUND = 'REAQUISE_INBOUND',
  REAKQUISE_OUTBOUND = 'REAKQUISE_OUTBOUND',
  UMZUG_INTERN = 'UMZUG_INTERN',
  UPSELLING_EOGINMVP = 'UPSELLING_EOGINMVP',
  UPSELLING_UMZUG = 'UPSELLING_UMZUG'
}
