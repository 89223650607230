import { Component, OnInit } from '@angular/core';
import { ContractDetails } from '../../../../../assets/js/com/ts_api_client';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { BillingAddressFormData } from '../../../models/billingAddressFormData';
import { HomeData } from '../../../models/home';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { TrackingService } from '../../../services/tracking.service';
import { Utilities } from '../../../../shared/utils/utilities';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'bdo-contract-cancel-confirmation',
  templateUrl: './contract-cancel-confirmation.component.html',
  styleUrls: ['./contract-cancel-confirmation.component.scss']
})
export class ContractCancelConfirmationComponent implements OnInit {
  public oldContract: ContractDetails;
  public billingAddress: BillingAddressFormData;
  public homeData: HomeData;

  constructor(
    private trackingService: TrackingService,
    private locationStrategy: LocationStrategy
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_CANCEL_DONE, TRACKING.ACTION.ENTER);

    this.oldContract = StorageService.getOldContract();
    this.billingAddress = StorageService.getValue<BillingAddressFormData>(ALLOWED_KEYS.BILLING_ADDRESS);
    this.homeData = StorageService.getValue<HomeData>(ALLOWED_KEYS.RECENT_HOME_DATA);

    window.sessionStorage.clear();
    Utilities.disableBrowsersBackButton(this.locationStrategy);
  }
}
