import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectionTileData } from '../../models/selectionTileData';

@Component({
  selector: 'bdo-selection-tile',
  templateUrl: './selection-tile.component.html',
  styleUrls: ['./selection-tile.component.scss']
})
export class SelectionTileComponent {
  @Input() isSelected: boolean = false;
  @Input() isSelectable: boolean = true;
  @Input() data: SelectionTileData;
  @Output() selectionUpdated: EventEmitter<boolean> = new EventEmitter();

}
