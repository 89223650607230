"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VariationV2 = void 0;
var variable_1 = require("./variable");
var VariationV2 = /** @class */function () {
  function VariationV2(variation) {
    var _this = this;
    this.variables = {};
    this.getVariableByKey = function (key) {
      if (key) {
        return _this.variables[key];
      } else {
        return null;
      }
    };
    this.key = variation.key;
    for (var _i = 0, _a = variation.variables; _i < _a.length; _i++) {
      var variable = _a[_i];
      this.variables[variable.key] = new variable_1.Variable(variable);
    }
  }
  return VariationV2;
}();
exports.VariationV2 = VariationV2;