import { Component, Input } from '@angular/core';
import { BankAccountAssignments } from '../models/bank-account-assignments.interface';

@Component({
  selector: 'bdo-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss']
})
export class AssignmentsComponent {

  @Input() bankAccountAssignments: BankAccountAssignments;
}
