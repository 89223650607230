import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { switchMap, first, map } from 'rxjs/operators';

export function autosuggestAvailable(noSupplierAvailable$: BehaviorSubject<boolean>, errorText: string): AsyncValidatorFn {

  return (control: AbstractControl): Observable<(ValidationErrors | null)> => {

    // timer is needed, because "no results" is set async directly after typing, but later than this validator
    return timer(1000).pipe(
      switchMap(() => noSupplierAvailable$),
      first(),
      map((value) => {
        return !value ? (null) : ({
          no_results: errorText
        });
    }));

  };

}
