import { Component, Input } from '@angular/core';

@Component({
  selector: 'bdo-tariff-prices-workingprice',
  templateUrl: './tariff-prices-workingprice.component.html',
  styleUrl: './tariff-prices-workingprice.component.scss'
})

export class TariffPricesWorkingpriceComponent {
  @Input() price: number = null;
  @Input() description: string = '';
  @Input() textInfoI: string = '';
}
