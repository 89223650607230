import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unitPretty'
})
/** Returns the unit in a prettier form */
export class UnitPrettyPipe implements PipeTransform {

  transform(value: any): any {
    let result = value;
    if (!value) {
      return value;
    }
    value = value.toLowerCase();
    switch (value) {
      case 'kwh':
        result = 'kWh';
        break;
      case 'm3':
        result = 'm³';
        break;
      default:
        break;
    }
    return result;
  }

}
