<div
  class="infoBox re-flex re-flex-col {{marginBottom ? 're-mb-10' : ''}}"
  [ngClass]="[type]">
  <div class="re-relative re-flex re-flex-row">
    <div class="re-w-20 re-h-20 re-mx-15 re-mt-15 {{type}}"
         role="alert"
         attr.aria-label="{{ 'general.infobox.iconLabel.' + type | translate }}"><!-- TODO dynamic key! -->
      <svg-icon class="icon-info-box re-w-20 re-h-20 re-block re-leading-3"
                src="{{iconSrc}}"
      ></svg-icon>
    </div>
    <div class="re-items-center re-py-10 re-pr-10 re-text-xs">
      <div *ngIf="headline" class="bold re-mb-5">{{headline}}</div>
      <ng-content></ng-content>
      <div *ngIf="linkText" class="re-block re-mt-15 re-mb-10">
        <bdo-text-link-icon
          bdoRouterLinkDirective
          [linkText]="linkText"
          [href]="destination"
          [iconPosition]="'right'"
          [size]="'s'">
        </bdo-text-link-icon>
      </div>
    </div>
  </div>

</div>
