import moment from 'moment';
import { COMPETITION_ID } from '../enums/competitionId.enum';
import { Competition } from '../models/competition';

const competitionStartDate = moment('2024-09-30T00:00:00+01:00').toDate();
const competitionEndDate = moment('2024-10-28T00:00:00+01:00').toDate();
export const activeCompetition: Competition = {
  competitionId: COMPETITION_ID.IPAD_OKT_2024,
  startDate: competitionStartDate,
  endDate: competitionEndDate
};
