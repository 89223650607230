import { ChangeDetectorRef, Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { switchMap, tap, first } from 'rxjs/operators';
import { DropdownItem } from '../../models/dropdownItem';
import { BankAssignment, ContractPaymentData } from '../../../../assets/js/com/ts_api_client';
import { LOADING_STATE } from '../../enums/loadingState.enum';
import { LoginService } from '../../../login/login.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerStoreService } from '../../services/customer-store.service';
import { StorageService, ALLOWED_KEYS } from '../../services/storage.service';
import { BdoApiService } from '../../services/bdo-api.service';
import { AuthDataStorage } from '../../models/AuthData.storage';
import { FormFieldIdentifierTracking, TrackingService } from '../../services/tracking.service';
import { ActivatedRoute } from '@angular/router';
import { TRACKING } from '../../enums/trackingParts.enum';
import { PaymentParentFormtype } from '../../../shared/formtypes/payment-parent.formtype';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent implements OnInit, OnDestroy {

  @Input() trackingSepaForm: FormFieldIdentifierTracking;
  @Input() paymentForm: FormGroup<PaymentParentFormtype>;
  public paymentMethodDropdown: Array<DropdownItem> = [];
  public bankAssignment: BankAssignment = {};
  public accountId: string;
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public isLoggedIn$ = this.loginService.isLoggedIn$;
  public authMeternumber: string;
  public showDifferentPaymentFirst: boolean = false;
  private subscriptions = new Subscription();

  constructor(
    private loginService: LoginService,
    private customerStore: CustomerStoreService,
    private changeDetector: ChangeDetectorRef,
    private bdoApiService: BdoApiService,
    private activatedRoute: ActivatedRoute,
    private trackingService: TrackingService,
    private translateService: TranslateService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.accountId = this.customerStore.getAccountId();
    this.loadBankAssignment();

    this.paymentMethodDropdown.push({ value: 'sepa', label: this.translateService.instant('banking.sepa.sepaMandate') });
    this.paymentMethodDropdown.push({ value: 'bankTransfer', label: this.translateService.instant('banking.manual') });

    this.subscriptions.add(this.paymentForm?.get('paymentOptions')?.valueChanges.subscribe({ next: value => {
      this.updatePaymentOptions(value);
      this.changeDetector.detectChanges();
    } }));

    const paymentData = StorageService.getPaymentData();
    if (paymentData) {
      if (paymentData['paymentOptions'] === 'keepPayment') {
        this.paymentForm?.get('paymentOptions')?.setValue('keepPayment');
      } else {
        this.paymentForm?.get('paymentOptions')?.setValue('differentPayment');
        if (paymentData['paymentMethod'] === 'sepa') {
          this.paymentForm?.patchValue(paymentData);
        } else {
          this.paymentForm?.get('paymentMethod')?.setValue('bankTransfer');
          this.sepaRequired(false);
        }
      }
    }
  }


  updatePaymentMethod(selected: string) {
    this.paymentForm?.get('paymentMethod')?.setValue(selected);
    this.sepaRequired(selected === 'sepa');
    this.trackingService.postSimpleTracking(
      this.trackingSepaForm.orca_form_name, TRACKING.FORM_ACTION.SELECT, this.paymentForm?.get('paymentMethod')?.value
    );
  }

  updatePaymentOptions(value: string) {
    const payMethod = this.paymentForm?.get('paymentMethod');
    if (value === 'keepPayment') {
      this.paymentMethodRequired(false);
      this.sepaRequired(false);
      if (!this.bankAssignment) {
        this.loadBankAssignment();
      } else {
        this.updateBankAssignment();
      }
    } else {
      if (payMethod.value === null) {
        payMethod.setValue('sepa');
        this.sepaRequired(true);
      }
      this.paymentMethodRequired(true);
    }
  }

  paymentMethodRequired(value: boolean) {
    const paymentMethod = this.paymentForm?.get('paymentMethod');
    if (value) {
      paymentMethod.setValidators([Validators.required]);
    } else {
      paymentMethod.clearValidators();
      paymentMethod.setValue(null);
    }
    paymentMethod.markAsUntouched();
    paymentMethod.updateValueAndValidity();
  }

  sepaRequired(value: boolean) {
    if (!value) {
      this.paymentForm?.removeControl('sepaAuth');
      this.paymentForm?.removeControl('bankData');
    }
  }

  updateBankAssignment(): void {
    if (this.paymentForm?.get('paymentOptions')?.value === 'keepPayment' && this.bankAssignment?.debit) {
      this.paymentForm?.get('paymentMethod')?.setValue('sepa');
      this.paymentForm?.removeControl('bankData');
      this.paymentForm?.addControl('bankData', new FormControl(this.bankAssignment?.debit));
    }
  }

  private loadBankAssignment() {
    this.state = LOADING_STATE.LOADING;
    this.isLoggedIn$.pipe(
      first(),
      switchMap((loggedIn) => {
        // If page is reloaded an no accountId is in the store, set it to the first one
        if (!this.accountId && loggedIn) {
          return this.bdoApiService.getAccounts().pipe(tap({ next: res => {
              this.accountId = res[0]?.accountId;
              this.customerStore.setAccountId(this.accountId);
            } }));
        } else {
          return of(loggedIn);
        }
      }),
      switchMap((loggedIn) => {
        if (!loggedIn) {
          const authData = StorageService.getValue<AuthDataStorage>(ALLOWED_KEYS.AUTH_DATA);
          this.accountId = authData.checkIdentificationData.accountId;
          this.authMeternumber = authData.checkIdentificationData.meterNumberOrRegisterCode;
        }
        return loggedIn ? this.bdoApiService.getBankAccountAssignment() :
          this.bdoApiService.getBankAccountAssignmentAnonymous(this.accountId, this.authMeternumber);
      })
    ).subscribe(
      {
        next: (result: BankAssignment) => {
          this.bankAssignment = result;
          // preselect sepa-payment if account is using bank-transfer
          if (!result.hasOwnProperty('debit') && StorageService.getValue<ContractPaymentData>(ALLOWED_KEYS.PAYMENT_DATA) === null) {
            this.paymentForm?.get('paymentOptions')?.setValue('differentPayment');
            this.showDifferentPaymentFirst = true;
          }
          this.updateBankAssignment();
          this.state = LOADING_STATE.IDLE;
        },
        error: () => {
          this.state = LOADING_STATE.ERROR;
        }
      });
  }
}
