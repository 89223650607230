<div class=dashboard-wrapper>
  <bdo-loading *ngIf="loadingAll" [fullscreen]=true></bdo-loading>
  <div *ngIf="error" data-qa="errorbox" class="bdo-container--single">
    <bdo-info-box>
      {{ 'general.error.error' | translate }}
    </bdo-info-box>
  </div>
  <div class="bdo-container greeting" *ngIf="!error">
    <h3>
      {{ greeting | translate }}<span *ngIf="!loadingPartner && selectedAccount?.name?.length>0">, {{selectedAccount?.name}}</span>!
    </h3>
  </div>
  <ng-container *ngIf="accounts?.length">
    <div class="bdo-container dashboard__row re-flex re-items-stretch">
      <div class="bdo-tile-container re-flex-grow">
        <div class="bdo-column-tile dashboard__customer-info re-relative">
          <div class="re-relative" [class.re-h-full]="!alert || alert.id === 'NONE'">
            <bdo-loading *ngIf="loadingPartner"></bdo-loading>
            <bdo-customer-tile
              [account]="selectedAccount"
              [accounts]="accounts"
              [isRestricted]="isInactive || isRegional"
              (customerSelected)="onCustomerSelected($event)">
            </bdo-customer-tile>
          </div>
          <div class="re-relative re-mt-20" *ngIf="alert && alert.id !== 'NONE'">
            <bdo-alert-tile [alert]="alert" [accountId]="accountId"></bdo-alert-tile>
          </div>
        </div>
        <div class="bdo-column-tile--double re-flex-grow ">
          <bdo-loading *ngIf="loadingVerbrauch"></bdo-loading>
          <bdo-billing-consumption-tile [accountId]="accountId" class="re-relative"></bdo-billing-consumption-tile>
        </div>
      </div>
    </div>

    <div class="dashboard__row--big">
      <bdo-frequent-functions-tile [accountId]="accountId" [moveAvailable]="moveAvailable" [changeAvailable]="!(isInactive || isRegional)"
        (redirectEvent)="goTo($event)"></bdo-frequent-functions-tile>
    </div>

    <div class="bdo-container dashboard__row bdo-tile-container">
      <div class="bdo-column-tile" *ngIf="!isInactive || (isInactive && forderung?.offeneSumme)">
        <bdo-loading *ngIf="loadingForderung"></bdo-loading>
        <bdo-payment-tile [payments]="forderung" [bankAccountAssignments]="bankAccountAssignments"
          [isInactive]="isInactive"></bdo-payment-tile>
      </div>

      <div class="bdo-column-tile">
        <bdo-loading *ngIf="loadingForderung"></bdo-loading>
        <bdo-banking-tile [payments]="forderung" [bankAccountAssignments]="bankAccountAssignments"
          [kundennummer]="accountId"></bdo-banking-tile>
      </div>

      <div class="bdo-column-tile">
        <bdo-documents-tile [isRestricted]="isInactive || isRegional" [accountId]="accountId"></bdo-documents-tile>
      </div>
    </div>

    <ng-container *ngIf="(campaignService.currentCampaign$ | async)">
      <div class="bdo-container bdo-section-headline">
        <bdo-section-headline>{{ 'campaign.title' | translate }}</bdo-section-headline>
      </div>
      <div class="re-bdo-container dashboard__row campaigns re-w-full">
        <bdo-campaign-tile
          [mode]="CampaignTileType.TILE"
          [campaign]="campaignService.currentCampaign$ | async"
          [showDismissCheckbox]="false"
          (clickCampaignButton)="onClickCampaignButton()">
        </bdo-campaign-tile>
      </div>
    </ng-container>

    <bdo-more-services-tile [isRestricted]="isInactive || isRegional" [accountId]="accountId">
    </bdo-more-services-tile>

    <div *ngIf="showOptinTeaser"
         class="bdo-container-notablet--single re-mt-30 md:re-mt-45 lg:re-mt-75">
      <bdo-info-teaser
        [title]="'dashboard.optinTeaser.title' | translate"
        [buttonText]="'dashboard.optinTeaser.buttonText' | translate"
        [iconSrc]="'assets/icons/email.svg'"
        [buttonStyle]="'textLinkIcon'"
        [routerUrl]="'/daten/' + accountId"
        [fragment]="'optin'"
        [hasButton]="true">
        {{ 'dashboard.optinTeaser.description' | translate }}
      </bdo-info-teaser>
    </div>

  </ng-container>
  <!--
<div class="dashboard__row--big" style="background-color:turquoise; width: 100%; height:100px; padding:1em;">
    Der neue Heimvorteil o.Ä. Banner <br>
    Coming soon...
</div>

Gebuchte Tarife
<div class="dashboard__row bdo-container">
    <div class="bdo-tile-container">
        <div class="bdo-column-tile" style="background-color:#fff; padding:1em;">Tarif 1 <br> Coming soon...</div>
        <div class="bdo-column-tile" style="background-color:#fff; padding:1em;">Tarif 2 <br> Coming soon...</div>
    </div>
</div>
</div>-->

  <bdo-simple-text-popup [isVisible]="showWelcomeInProgress" (closePopupEvent)="onCloseWelcomeInProgress()">
    <bdo-popup-content [iconName]="'icon-alert'">
      <bdo-account-in-progress-warning (closeEvent)="onCloseWelcomeInProgress()"></bdo-account-in-progress-warning>
    </bdo-popup-content>
  </bdo-simple-text-popup>
</div>
