import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { combineLatest, ReplaySubject } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { Environment } from '../../../environments/environment';
import { LoginService } from '../../login/login.service';
import { Utilities } from '../../shared/utils/utilities';
import { INPUT_TYPE } from '../enums/inputType.enum';
import { STAGE } from '../enums/stage.enum';
import { TAGMAN_IDS } from '../enums/tagmanIds';
import { TRACKING } from '../enums/trackingParts.enum';
import { BdoApiService } from './bdo-api.service';
const FORM_ACTION = TRACKING.FORM_ACTION;

export interface ProductTracking {
  orca_kundenart: string;
  orca_jahresverbrauch: number;
  orca_preis: number;
  orca_product_id: string; // Produkt ID des abgeschlossenen Tarifs
  orca_product_name: string;
  orca_product_optionen?: string; // Entfällt, wenn die Info bereits im Tarifnamen steht
  orca_product_sparte: string;
  orca_campaign?: string; // Campaign like dta/LIEFERANTENWECHSEL or os/GAS_MVP_2
  orca_meter_quantity?: number;
}

export interface DeliveryTracking extends ProductTracking {
  orca_kundenstatus: string;
  orca_meter_quantity: number;
  orca_order_id: string;
  orca_zahlungsart: string;
}

export interface ContractsTracking {
  event: string,
  orca_gesamtverbrauch: number,
  orca_kundenart: string,
  orca_kundenstatus: string,
  orca_order_id: string,
  orca_ordered_products: Array<ProductTracking>,
  orca_zahlungsart: string,
  orca_campaign?: string
}

export interface BaseTracking {
  event: string;
  orca_event_object: string;
  orca_event_type?: string;
  orca_event_action?: string;
}

export interface FormTracking extends FormFieldIdentifierTracking {
  orca_form_action: TRACKING.FORM_ACTION;
  orca_field_type?: INPUT_TYPE;
}

export interface FormFieldIdentifierTracking {
  orca_form_name: string;
  orca_field_section: string;
  orca_field_name?: string;
}

@Injectable({
  providedIn: 'root'
})
/**
 * Service holds all the information and methods, to deal with tracking
 */
export class TrackingService {
  public cookieHandlingIsSet$ = new ReplaySubject<boolean>(1);
  private tagmanLoaded$ = new ReplaySubject<boolean>(1);
  private trackingAlreadyLoaded = false;
  private baseTracking: BaseTracking = {
    event: 'orca-event',
    orca_event_object: 'ORCA'
  };

  constructor(private router: Router, private apiService: BdoApiService, private loginService: LoginService, @Inject(DOCUMENT) private doc: Document
  ) {
    this.tagmanLoaded$.next(false);

    // If Cookie already set e.g. by Website, load tagmanager directly
    // Otherwise has to be loaded after first cookie event in updateCookieHandling
    if (Utilities.getCookie('cookiesAccepted') !== undefined) {
      this.loadTagmanScript(this.doc).pipe(first()).subscribe({ next: () => {
        this.setTrackingCookieHandling(Utilities.cookiesAccepted());
      } });
    }

    // Matomo / eTracker PageViews
    this.router.events.subscribe({ next: event => {
      if (event instanceof NavigationStart) {
        this.postTrackingPageView(event.url);
      }
    } });
  }



  /**
   * post tracking to matomo
   * wait for tagman script to be loaded and wait for a cookie-handling decision of the user
   * @param objectToPush
   * @private
   */
  public setTrackingCookieHandling(activateCookies: boolean) {
    this.tagmanLoaded$.pipe(
      filter((loaded) => loaded),
      first()
    ).subscribe({ next: () => {
      this.pushTracking({ 'event': 're_cookiebanner', 're_cookies_accepted': String(activateCookies) });
      this.cookieHandlingIsSet$.next((activateCookies)); // inform all tracking calls in the pipeline, that they can be sent now
    } });
  }

  public normalizeUrl(url: string) {
    // Don't track accountIDs or meternumbers contained in path
    const splitted = url.split('/');
    // Remove path elements when numbers are contained
    const maskedSplitted = splitted.map(elem =>
      /\d/.test(elem) ? '' : elem
    );
    const maskedUrl = maskedSplitted.join('/');

    // Don't transfer Query Params in URL for PageViews
    return maskedUrl.split('?')[0];
  }

  /**
   * load tagman script and set the cookiehandling on matomo once it is loaded
   * @param {boolean} activateCookies
   * @returns {() => void}
   */
    public updateCookieHandling(activateCookies: boolean): () => void {
      return () => {
        this.loadTagmanScript(this.doc).pipe(first()).subscribe({ next: () => {
            this.setTrackingCookieHandling(activateCookies);
          } });
      };
    }

  /** We currently do not change the Page Title */
  public postTrackingPageView(url: string): void {
    const maskedUrl = this.normalizeUrl(url);
    this.loginService.isLoggedIn$
      .pipe(first())
      .subscribe({ next: (isLoggedIn) => {
          this.postMatomo({
            'event': 'orca-pageview',
            'orca_pagename': maskedUrl,
            'orca_url': maskedUrl,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'orca_loggedIn': isLoggedIn
          });
        } }
      );
  }

  /** e.g. ORCA - Campaign - ShowBannerXY
   * Use this in individual cases otherwise use postTracking
   */
  public postSimpleTracking(type: string = '', ...action: string[]): void {
    let act: string = TRACKING.ACTION.CLICK;
    if (action.length > 0) {
      act = action.join(' ');
    }
    this.postMatomo({ 'event': 'orca-event', 'orca_event_object': 'ORCA',
      'orca_event_type': type, 'orca_event_action': act });
  }
  /** Tracking using predefined Tracking-Parts to keep consistency on format */
  public postTracking(type: TRACKING.LOCATION, action: TRACKING.ACTION, target: TRACKING.LOCATION | TRACKING.ELEMENTS = TRACKING.LOCATION.EMPTY): void {
    this.postSimpleTracking(type, (action + ' ' + target).trim());
  }

  /**
   * orca_form_name = Formular ID oder Name des Formulars // Persönliche Daten, Situation, Zahlungsart - Muss eindeutig sein, sollte ein sprechender Name sein
   * orca_field_type = Type des Inputs (text, checkbox,radio, etc.)
   * orca_field_name = Name des Formular Feldes - muss eindeutig sein, am besten ein sprechender Name zur Identifizierung im Tracking
   */
  public postFormTracking(form_name: TRACKING.FORM, form_action: TRACKING.FORM_ACTION, field_section?: string, field_name?: string, field_type = 'text'): void {
    const baseForm = {
      'event': 'orca_form',
      'orca_form_action': form_action,
      'orca_form_name': form_name
    };

    // field interactions track additional information
    const formInteraction = form_action.includes('field') ?
      {
        'orca_field_section': field_section,
        'orca_field_name': field_name,
        'orca_field_type': field_type
      }
      : {};

    this.postMatomo({ ...baseForm, ...formInteraction });
  }

  public postFormInteraction(data: FormTracking) {
    this.postMatomo({ ...{ event: 'orca_form' }, ...data });
  }

  public postAutosuggestTracking(orca_form_name: TRACKING.FORM, field_name: string, orca_search_string: string, selected_element: string, extraData?: any) {
    const baseForm = {
      event: 'orca_autosuggest',
      orca_form_action: FORM_ACTION.SELECT_AUTOSUGGEST,
      orca_form_name
    };
    const autosuggestSelection = {
      first_two_chars: orca_search_string.substr(0, 2),
      number_of_chars_before_select: orca_search_string.length,
      orca_search_string,
      selected_element,
      ...extraData
    };
    this.postMatomo({ ...baseForm, ...autosuggestSelection });
  }

  /**
   * specific tracking that is triggered after a successful contract posting on the delivery process
   * @param tracking
   */
  public postDeliveryTracking(tracking: DeliveryTracking, isOffer?: boolean) {
    this.postMatomo({
      event: 'orca-tarifabschluss' + (isOffer ? '-dta' : ''),
      ...tracking
    });
  }

  public postBaseAndAdditionalTracking(additionalAttributes: Record<string, unknown>, baseTracking = this.baseTracking) {
    this.postMatomo({
      ...baseTracking,
      ...additionalAttributes
    });
  }

  /** specific tracking for 'type of supply (type)' and 'business area (mode)'
   * orca_geschaeftsbereich = PRIV oder GEWE für Privatkunde oder Gewerbekunde
   * orca_versorgungsart = Art der Versorgung (Strom, Erdgas...)
   */
  public postModeAndTypeOnEnterTracking(mode: string, type: string) {
    this.postMatomo({
      event: 'orca-event',
      orca_event_object: 'ORCA',
      orca_event_type: TRACKING.LOCATION.DELIVERY_CUSTOMERSTATUS,
      orca_event_action: TRACKING.ACTION.ENTER,
      orca_geschaeftsbereich: mode,
      orca_versorgungsart: type
    });
  }

  private postMatomo(objectToPush: Record<string, unknown>) {
    combineLatest([
      this.tagmanLoaded$.pipe(filter((tagmanLoaded) => !!tagmanLoaded)),
      this.cookieHandlingIsSet$])
      .pipe(
        first()
      ).subscribe({ next: () => {
        this.pushTracking(objectToPush);
      } });
  }

  private pushTracking(objectToPush: Record<string, unknown>) {
    if (Environment.stage !== STAGE.Production) {
      console.debug('Debug Tracking: ', objectToPush);
    }
    try {
      window['_mtm'].push(objectToPush);
    } catch (error) {
      if (Environment.stage !== STAGE.Production) {
        console.log('matomo undefined error');
      }
    }
  }

  /**
   * loads Tagmanscript and returns Observable, that resolves once the tagman is loaded
   * @param {Document} doc
   * @returns {ReplaySubject<boolean>}
   */
  private loadTagmanScript(doc: Document): ReplaySubject<boolean> {

    if (this.trackingAlreadyLoaded) {
      return this.tagmanLoaded$;
    }
    this.trackingAlreadyLoaded = true;
    let containerId = '';

    switch (Environment.stage) {
      case STAGE.Development:
        containerId = TAGMAN_IDS.Development;
        break;
      case STAGE.Staging:
        containerId = TAGMAN_IDS.Staging;
        break;
      case STAGE.Production:
        containerId = TAGMAN_IDS.Production;
        break;
      default:
        break;
    }

    this.loginService.isLoggedIn$
      .pipe(first())
      .subscribe({ next: isLoggedIn => {
          const s = doc.createElement('script');
          s.type = 'text/javascript';
          s.setAttribute('stage', Environment.stage);
          s.setAttribute('containerUrl', containerId);
          s.setAttribute('initialLoginStatus', isLoggedIn.toString());
          s.addEventListener('load', () => {
            this.tagmanLoaded$.next(true);
          });
          s.src = './assets/js/tagmanscript.js';

          const head = doc.getElementsByTagName('head')[0];
          head.appendChild(s);
        } });
    return this.tagmanLoaded$;
  }
}

export class TrackingServiceMock {
  public postFormTracking() {}
  public postSimpleTracking() {}
  public postTracking(param1?: any, param2?: any) {}
  public postAutosuggestTracking() {}
  public postTrackingPageView() {}
  public setTrackingCookieHandling() {}
  public postModeAndTypeOnEnterTracking() {}
  public postFormInteraction() {}
  public postBaseAndAdditionalTracking() {}
}
