import { Component } from '@angular/core';

@Component({
  selector: 'bdo-charge-flex',
  templateUrl: './charge-flex.component.html',
  styleUrl: './charge-flex.component.scss'
})
export class ChargeFlexComponent {

}
