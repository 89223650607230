<h3 class="bdo-container re-mb-45 md:re-mb-75">{{ 'contract.cancel.auth.title' | translate }}</h3>

<div class="re-mb-60 md:re-mb-105">
  <bdo-anonymous-or-login
    [destination]="'/kuendigen/neu/vertragsauswahl'"
    [isAnonymousOnly]="true"
    [trackingLocation]="TRACKING.LOCATION.CONTRACT_CANCEL_AUTH"
    [trackingDestination]="'Vertragsauswahl'"
  ></bdo-anonymous-or-login>
</div>

<bdo-faqs
  [keyName]="'contract.cancel.faqs'"
></bdo-faqs>
