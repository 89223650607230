import { Component, Input } from '@angular/core';

@Component({
  selector: 'bdo-square-icon-text',
  templateUrl: './square-icon-text.component.html',
  styleUrls: ['./square-icon-text.component.scss']
})
export class SquareIconTextComponent {
  @Input() routerUrl: string;
  @Input() text: string;
  @Input() dataQa: string;
}
