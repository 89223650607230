<div *ngIf="loginService.isLoggedIn$ | async">
  <button (click)="feedbackClick()" class="feedback-button">{{ 'feedback.title' | translate }}</button>
</div>

<bdo-simple-text-popup [isVisible]="showDialog" (closePopupEvent)="dialogAborted()">
  <bdo-feedback-dialog #feedbackDialog (done)="dialogFinished()"></bdo-feedback-dialog>
</bdo-simple-text-popup>

<bdo-simple-text-popup [isVisible]="showDone" (closePopupEvent)="doneFinished()" [icon]="'success'" [title]="'feedback.done.title' | translate">
  <bdo-feedback-done (done)="doneFinished()"></bdo-feedback-done>
</bdo-simple-text-popup>
