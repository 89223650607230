import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {
  Address,
  ContractMoveData,
  ContractMoveType,
  PersonalData,
  PersonalDataAddressData,
  SituationData,
  Zaehlerstand
} from '../../../../../assets/js/com/ts_api_client';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { LegalData } from '../../../models/legalData';
import { StorageService, ALLOWED_KEYS } from '../../../services/storage.service';
import { TenantService } from '../../../services/tenant.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CONTEXT_FROM_SUMMARY } from '../../delivery/summary/summary.component';
import { CustomerStoreService } from '../../../services/customer-store.service';
import { HomeData } from '../../../models/home';
import { MovePaymentFormData } from '../../../models/movePaymentFormData';
import { BdoApiService } from '../../../services/bdo-api.service';
import { LoginService } from '../../../../login/login.service';
import { first, switchMap } from 'rxjs/operators';
import { VerbrauchstypPipe } from '../../../pipes/verbrauchstyp.pipe';
import { AuthDataStorage } from '../../../models/AuthData.storage';
import { AvailableDivision, AvailableDivisionInfos } from '../new-home/product-selection/product-selection.component';
import { VERBRAUCHSTYP } from '../../../enums/verbrauchstyp.enum';
import { ContractsTracking, ProductTracking, TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { Md5 } from 'ts-md5';
import { CUSTOMERMODE } from '../../../enums/customerMode';
import { MOVE_TYPE } from '../../../enums/moveType';
import { difference, union } from 'lodash';
import { TariffAdvisorService } from '../../../services/tariff-advisor.service';
import { PathAndDivision } from '../../delivery/summary/legal-summary/legal-summary.component';
import { DatadogService } from '../../../services/datadog.service';
import { COOPERATION_PARTNER_IDS } from '../../../enums/cooperationPartnerId.enum';
import { TariffSelection } from '../../../../shared/models/tariff-selection';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'bdo-move-summary',
  templateUrl: './move-summary.component.html',
  styleUrls: ['./move-summary.component.scss']
})
export class MoveSummaryComponent implements OnInit {
  public state = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public errorMessage: string = null;
  public selectedTariffs: Array<TariffSelection> = [];
  public addressData: PersonalDataAddressData;
  public startDate: string;
  public unselectedDivisionIds: VERBRAUCHSTYP[] = [];
  public unselectableDivisionIds: VERBRAUCHSTYP[] = [];
  public recentDivisionIds: VERBRAUCHSTYP[] = [];
  public termsOfConditionsAccepted: boolean = false;
  public rightOfWithdrawalAccepted: boolean = false;
  public communicationAccepted: boolean | null = null;
  public validateCheckboxes: boolean = false;
  public pathToContractTerms: string = '';
  public divisionToContractTerms: string = '';
  public availableDivisionInfos: AvailableDivisionInfos;
  public pathAndDivisions: Array<PathAndDivision> = [];
  public isGewe: boolean = false;
  private customerMode: CUSTOMERMODE;
  private otherTenantDivisionIds: VERBRAUCHSTYP[];

  constructor(
    public location: Location,
    public translateService: TranslateService,
    private tenantService: TenantService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private customerStore: CustomerStoreService,
    private apiService: BdoApiService,
    private loginService: LoginService,
    private trackingService: TrackingService,
    private dataDogService: DatadogService,
    private verbrauchstypPipe: VerbrauchstypPipe,
    private tariffAdvisorService: TariffAdvisorService
  ) {

  }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.MOVE_SUMMARY, TRACKING.ACTION.ENTER);

    const selectedTariffs = StorageService.getTariffSelections();
    // Causes problems if data is not an array and is iterated in ngfor
    this.selectedTariffs = Array.isArray(selectedTariffs) ? selectedTariffs : [];
    this.customerMode = this.selectedTariffs[0]?.selectedTariff?.customerType as CUSTOMERMODE;
    this.isGewe = this.customerMode === CUSTOMERMODE.GEWE;
    this.availableDivisionInfos = StorageService.getValue<AvailableDivisionInfos>(ALLOWED_KEYS.AVAILABLE_DIVISIONS);
    const recentData = StorageService.getValue<HomeData>(ALLOWED_KEYS.RECENT_HOME_DATA);
    this.recentDivisionIds = recentData?.meterData.map((meterreading) => {
      for (const division of Object.keys(VERBRAUCHSTYP)) {
        if (meterreading.sparte === VERBRAUCHSTYP[division].toString()) {
          return VERBRAUCHSTYP[division];
        }
      }
    });
    this.unselectedDivisionIds = this.recentDivisionIds?.filter((divisionId) => {
      return this.selectedTariffs.length ? !this.selectedTariffs.find((item) => item.selectedTariff.division === divisionId) : true;
    });
    this.unselectableDivisionIds = this.availableDivisionInfos?.availableDivisions.filter((division) => {
      return !division.isProvided || !division.areaBelongsToCurrentTenant;
    }).map((item) => item.divisionId);

    this.otherTenantDivisionIds = this.availableDivisionInfos?.availableDivisions.filter((division) => {
      return !division.areaBelongsToCurrentTenant;
    }).map((item) => item.divisionId);

    this.startDate = Date.now().toString();

    this.selectedTariffs.forEach((divisionState: TariffSelection) => {
      this.pathAndDivisions.push({
        division: this.verbrauchstypPipe.transform(divisionState?.selectedTariff?.division),
        path: this.tariffAdvisorService.getDocumentLink() + divisionState?.selectedTariff?.layer?.downloads.find(item => item.title?.includes('Vertragsbedingungen')).link
      });
    });


    if (!this.addressData) {
      this.dataDogService.trackCustomEvent('contract_move__summary__addressDataMissing');
    }
    if (!selectedTariffs) {
      this.dataDogService.trackCustomEvent('contract_move__summary__selectedTariffsDataMissing');
    }
    if (!recentData) {
      this.dataDogService.trackCustomEvent('contract_move__summary__recentHomeDataMissing');
    }
  }

  onEdit(wizardStep: number, fragment: string) {
    this.trackingService.postBaseAndAdditionalTracking({
      orca_event_type: TRACKING.LOCATION.MOVE_SUMMARY,
      orca_event_action: 'goto edit-' + fragment
    });
    this.router.navigate(['../' + fragment], {
      relativeTo: this.activatedRoute,
      state: {
        context: CONTEXT_FROM_SUMMARY
      }
    });
  }

  onChangeTermsOfConditions(accepted: boolean) {
    this.termsOfConditionsAccepted = accepted;
    StorageService.setProperty<LegalData>(ALLOWED_KEYS.LEGAL_DATA, 'termsOfConditionsAccepted', this.termsOfConditionsAccepted);
  }

  onChangeRightOfWithdrawalAccepted(accepted: boolean) {
    this.rightOfWithdrawalAccepted = accepted;
    StorageService.setProperty<LegalData>(ALLOWED_KEYS.LEGAL_DATA, 'rightOfWithdrawalAccepted', this.rightOfWithdrawalAccepted);
  }

  onChangeCommunicationAccepted(accepted: boolean) {
    this.communicationAccepted = accepted;
    StorageService.setProperty<LegalData>(ALLOWED_KEYS.LEGAL_DATA, 'communicationAccepted', this.communicationAccepted);
  }

  submit() {
    this.validateCheckboxes = true;
    const personalData = StorageService.getValue<PersonalData>(ALLOWED_KEYS.PERSONAL_DATA);
    const situationData = StorageService.getValue<SituationData>(ALLOWED_KEYS.SITUATION_DATA);
    const paymentData = StorageService.getValue<MovePaymentFormData>(ALLOWED_KEYS.PAYMENT_DATA);
    const moveBankAccount = paymentData?.paymentOptions === 'keepPayment' || false;
    const reducedPaymentData = paymentData;
    delete reducedPaymentData.moveBankAccount;
    delete reducedPaymentData.paymentOptions;
    if (reducedPaymentData?.bankData?.iban) {
      // remove special chars
      reducedPaymentData.bankData.iban = reducedPaymentData.bankData.iban.replace(/[^a-zA-Z0-9]/g, '');
    }
    let cooperationPartnerId = this.tariffAdvisorService.getCooperationPartnerId(
      personalData?.addressData?.postCode,
      StorageService.getTariffData()?.map(tariff => tariff.productId)
    );
    // No Netcologne if moving out of region
    if (cooperationPartnerId === COOPERATION_PARTNER_IDS.NETCOLOGNE && this.availableDivisionInfos?.allUnavailableOrExternal) {
      cooperationPartnerId = '';
    }

    if (this.termsOfConditionsAccepted && this.rightOfWithdrawalAccepted) {
      this.state = LOADING_STATE.LOADING;
      const contractMoveTypes = this.generateContractMoveTypes(
        this.availableDivisionInfos.availableDivisions.map((item) => item.divisionId),
        this.otherTenantDivisionIds,
        this.recentDivisionIds,
        this.selectedTariffs.map((item) => item.selectedTariff.division as VERBRAUCHSTYP),
        this.availableDivisionInfos.availableDivisions);
      const moveData: ContractMoveData = {
        contractMoveTypes: contractMoveTypes,
        accountId: this.customerStore.getAccountId() || StorageService.getValue<AuthDataStorage>(ALLOWED_KEYS.AUTH_DATA)?.checkIdentificationData?.accountId,
        addressOut: StorageService.getValue<HomeData>(ALLOWED_KEYS.RECENT_HOME_DATA)?.addressData,
        addressIn: this.mapFlatAddressToAddress(personalData?.addressData),
        email: personalData?.email,
        moveInDate: situationData?.startDate,
        moveInMeterData: situationData?.meterData,
        moveBankAccount: moveBankAccount,
        moveOutDate: StorageService.getValue<HomeData>(ALLOWED_KEYS.RECENT_HOME_DATA)?.date,
        moveOutMeterData: StorageService.getValue<HomeData>(ALLOWED_KEYS.RECENT_HOME_DATA)?.meterData,
        moveTariffDataList: StorageService.getTariffData(),
        paymentData: reducedPaymentData,
        phonenumber: personalData?.phonenumber,
        emailOptIn: this.communicationAccepted,
        cooperationPartnerId
      };
      moveData.orderId = this.generateOrderId(moveData);

      this.loginService.isLoggedIn$.pipe(
        first(),
        switchMap((isLoggedIn) => {
          if (!isLoggedIn && moveData?.moveOutMeterData.length === 0) {
            const authMeterNumber: Zaehlerstand = {
              meterNumber: StorageService.getValue<AuthDataStorage>(ALLOWED_KEYS.AUTH_DATA)?.checkIdentificationData?.meterNumberOrRegisterCode
            };
            moveData.moveOutMeterData = Array.of(authMeterNumber);
          }
          return isLoggedIn ? this.apiService.postContractMove(moveData) : this.apiService.postContractMoveAnonymous(moveData);
        })
      ).subscribe(
        {
          next: (result: ContractMoveData) => {
            this.errorMessage = null;
            StorageService.setTrustPilotToken(result.trustpilotSecureToken);
            this.state = LOADING_STATE.IDLE;
            this.trackMove(moveData);
            this.router.navigate(['../../../danke'], { relativeTo: this.activatedRoute });
          },
          error: () => {
            this.trackingService.postTracking(TRACKING.LOCATION.MOVE_SUMMARY, TRACKING.ACTION.FAILURE);
            this.errorMessage = this.translateService.instant('general.error.error');
            this.state = LOADING_STATE.ERROR;
          }
        });
    }
  }

  public generateContractMoveTypes(availableDivisionIds: VERBRAUCHSTYP[],
                                   otherTenantDivisionIds: VERBRAUCHSTYP[],
                                   recentDivisionIds: VERBRAUCHSTYP[],
                                   selectedDivisionIds: VERBRAUCHSTYP[],
                                   divisionInfos: AvailableDivision[]) {

    const newDivisionIds = difference(availableDivisionIds, recentDivisionIds);
    const contractMoveTypes: ContractMoveType[] = [];
    union(availableDivisionIds, recentDivisionIds).forEach((divisionId) => {
      if (newDivisionIds.includes(divisionId) && selectedDivisionIds.includes(divisionId)) {
        contractMoveTypes.push({
          divisionId: divisionId,
          moveType: MOVE_TYPE.CONTRACT_START
        });
      } else if (otherTenantDivisionIds.includes(divisionId) && !(newDivisionIds.includes((divisionId)))) {
        const tenantName: string = divisionInfos.find(divisionInfo => divisionInfo.divisionId === divisionId).tenantNameShort;
        contractMoveTypes.push({
          divisionId: divisionId,
          moveType: MOVE_TYPE.CONTRACT_OTHER_TENANT,
          otherTenantUrl: divisionId === VERBRAUCHSTYP.Gas || divisionId === VERBRAUCHSTYP.Strom ?
            this.tenantService.getTenantDataByShort(tenantName)?.tariffAdvisorLinks[divisionId] : ''
        });
      } else if (selectedDivisionIds.includes(divisionId) && !(newDivisionIds.includes((divisionId)))) {
        contractMoveTypes.push({
          divisionId: divisionId,
          moveType: MOVE_TYPE.CONTRACT_SAME_TENANT
        });
      } else if ( !(newDivisionIds.includes((divisionId)))) {
        contractMoveTypes.push({
          divisionId: divisionId,
          moveType: MOVE_TYPE.CONTRACT_END
        });
      }
    });
    return contractMoveTypes;
  }

  trackMove(contract: ContractMoveData) {
    const situationData = StorageService.getValue<SituationData>(ALLOWED_KEYS.SITUATION_DATA);

    const tariffSelectionPrices = StorageService.getTariffSelections();
    const tariffTrackings: Array<ProductTracking> = contract?.moveTariffDataList?.map(tariff => {
      return {
      orca_product_id: tariff?.productId,
      orca_product_name: tariff?.tbTariffName,
      orca_product_optionen: '',
      orca_product_sparte: this.verbrauchstypPipe.transform(tariff?.divisionId, false),
      orca_jahresverbrauch: parseInt(tariff?.annualConsumption, 10),
      orca_preis: tariffSelectionPrices.find(item => item.type === tariff.divisionId)?.monthlyPrice,
      orca_kundenart: this.customerMode,
      orca_meter_quantity: situationData?.meterData?.filter(item => item.divisionId === tariff.divisionId)?.length
      };
    });
    const tracking: ContractsTracking = {
      event: 'orca-umzugsservice',
      orca_order_id: `orca-${contract.orderId}`,
      orca_ordered_products: tariffTrackings,
      orca_gesamtverbrauch: contract?.moveTariffDataList?.reduce((accumulator, item) => {
        return accumulator + parseFloat(item.annualConsumption);
      }, 0),
      orca_kundenstatus: 'Umzug',
      orca_kundenart: this.customerMode,
      orca_zahlungsart: contract.paymentData.bankData?.iban ? 'SEPA Mandat' : 'Überweisung'
    };
    this.trackingService.postBaseAndAdditionalTracking({ ...tracking });

    this.trackingService.postTracking(TRACKING.LOCATION.MOVE_SUMMARY, TRACKING.ACTION.SUCCESS);

    this.trackingService.postBaseAndAdditionalTracking({
      orca_event_type: TRACKING.LOCATION.MOVE_SUMMARY,
      orca_event_action: 'goto fertig'
    });
  }

  mapFlatAddressToAddress(personalData: PersonalDataAddressData): Address {
    const address: Address = {
      houseNum: personalData.housenumber,
      street:  {
        name : personalData.street,
        city : {
          name: personalData.city,
          postCode: personalData.postCode
        }
      }
    };
    return address;
  }

  private generateOrderId(contract: ContractMoveData) {
    return Md5.hashStr(JSON.stringify(contract));
  }

}
