<form class="re-form-container" (ngSubmit)="submit()" [formGroup]="form" (keydown.enter)="$event.preventDefault()" autocomplete="new-password" bdoScrollToInvalidControl>
  <bdo-loading *ngIf="sendingData"></bdo-loading>
  <div class="re-px-20" *ngIf="linkToLoggedInService" data-qa="hintToLogin">
    <div class="re-container__content re-mb-30">
      <h4 class="re-mb-30 md:re-mb-20 re-text-base">
        {{ 'login.continueWithAccountId' | translate }}
      </h4>
      <div class="re-mb-5">
        {{ 'register.login.anonymousTitle' | translate }}
      </div>
      <bdo-text-icon
        [iconPosition]="'right'"
        [text]="'register.login.changeToWithAccount' | translate"
        (click)="goToLoggedInService()"
      ></bdo-text-icon>
    </div>
  </div>
  <fieldset class="re-mb-0">
    <div class="bdo-form-item-container re-relative">
      <div class="re-form-item-container">
        <bdo-input #accountIdInput
                  ngDefaultControl
                  [formControl]="form.controls.accountId"
                  [inputType]="INPUT_TYPE.TEXT"
                  [validationIconHidden]="validationIconHidden"
                  [labelText]="'general.customerData.account' | translate"
                  [tooltipContent]="'register.account.accountIdHint' | translate"
                  >
        </bdo-input>
      </div>
    </div>

    <div class="bdo-form-item-container">
      <div class="re-form-item-container">
        <bdo-input #meterNumberInput
                  ngDefaultControl
                  [formControl]="form.controls.meterNumber"
                  [inputType]="INPUT_TYPE.TEXT"
                  [validationIconHidden]="validationIconHidden"
                  [labelText]="'general.customerData.meternumber' | translate"
                  [tooltipContent]="'register.meter.meterIdOrRegistercodeHintMeter' | translate"
                  >
        </bdo-input>
      </div>
    </div>

    <div class="bdo-form-item-container" *ngIf="includeDateSelection">
      <bdo-date-picker
        ngDefaultControl
        [labelText]="'meterreadings.info.meterreadingDate' | translate"
        [minDateForChanges]="minDate"
        [tooltipContent]="'meterreadings.edit.description' | translate"
        [attr.data-qa_mindateforchanges]="minDate"
        (dateSelected)="updateDate($event)">
      </bdo-date-picker>
    </div>

    <div>
      <button class="bdo-button-primary bdo-button--text" type="submit">
        {{ 'general.action.continue' | translate }}
      </button>
      <div class="re-mt-30"  *ngIf="includeCancelButton">
        <a class="bdo-nolink" [routerLink]="['/login']">
          <div class="bold"> {{ 'general.action.cancel' | translate }} </div>
        </a>
      </div>
    </div>

  </fieldset>

</form>
