import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Address, AddressData, SituationData } from '../../../../../assets/js/com/ts_api_client';
import { Utilities } from '../../../../shared/utils/utilities';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { MeterProductSelectionService } from '../../../services/meter-product-selection.service';
import { AvailableDivisionInfos } from '../../move/new-home/product-selection/product-selection.component';
import { TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { VERBRAUCHSTYP } from '../../../enums/verbrauchstyp.enum';

@Component({
  selector: 'bdo-contract-new-products',
  templateUrl: './contract-new-products.component.html',
  styleUrls: ['./contract-new-products.component.scss']
})
export class ContractNewProductsComponent implements OnInit {
  public address: AddressData | undefined;
  public nestedAddress: Address | undefined;
  public form = new FormGroup({});
  public forceValidateDate: boolean = false;
  public situationData: SituationData;
  public availableDivisionInfos: AvailableDivisionInfos;
  public TRACKING = TRACKING;

  constructor(
    public location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private meterProductService: MeterProductSelectionService,
    private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_NEW_PRODUCTS, TRACKING.ACTION.ENTER);
    this.address = StorageService.getAddressData();
    this.nestedAddress = Utilities.nestAddress(this.address);
  }

  onUpdateAvailableDivisions(availableDivisionInfos: AvailableDivisionInfos) {
    this.availableDivisionInfos = availableDivisionInfos;
  }

  save() {
    if (this.availableDivisionInfos?.allUnavailableOrExternal) {
      this.router.navigate(['../../dashboard']);
    } else {
      this.forceValidateDate = true;

      const divisionsSelected = this.form.get(['product-selection', VERBRAUCHSTYP.Strom, 'isChecked'])?.value ||
        this.form.get(['product-selection', VERBRAUCHSTYP.Gas, 'isChecked'])?.value;
      if (this.form.invalid || !divisionsSelected) {
        this.form.markAllAsTouched();
        this.form.updateValueAndValidity();
      } else {
        this.situationData = this.meterProductService.mapMeterDataToSituationData();
        StorageService.setValue(ALLOWED_KEYS.SITUATION_DATA, this.situationData);
        this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_NEW_PRODUCTS, TRACKING.ACTION.GOTO, 'Tarifauswahl');
        this.router.navigate(['../tarifauswahl'], {
          relativeTo: this.activatedRoute
        });
      }
    }
  }
}
