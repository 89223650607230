import { Injectable } from '@angular/core';
import { ALLOWED_KEYS, StorageService } from './storage.service';
import { HomeData } from '../models/home';
import { AuthDataStorage } from '../models/AuthData.storage';
import { VERBRAUCHSTYP } from '../enums/verbrauchstyp.enum';
import { VerbrauchstypPipe } from '../pipes/verbrauchstyp.pipe';
import { LoginService } from '../../login/login.service';
import { ListingPipe } from '../pipes/listing.pipe';

@Injectable({
  providedIn: 'root'
})
export class MoveService {

  constructor(private verbrauchstypePipe: VerbrauchstypPipe, private loginService: LoginService, private listingPipe: ListingPipe)  {
  }

  public getDivisionsFromRecentHome(): VERBRAUCHSTYP[] {
    const recentData = StorageService.getValue<HomeData>(ALLOWED_KEYS.RECENT_HOME_DATA);
    return recentData?.meterData.map((m) => {
      for (const division of Object.keys(VERBRAUCHSTYP)) {
        if (m.sparte === VERBRAUCHSTYP[division].toString()) {
          return VERBRAUCHSTYP[division];
        }
      }
    });
  }

  public isAnonymousButRegistered() {
    return !!StorageService.getValue<AuthDataStorage>(ALLOWED_KEYS.AUTH_DATA)?.isRegistered;
  }

  public divisionsForInfo(): string {
    const divisions = this.getDivisionsFromRecentHome()?.map((item) => this.verbrauchstypePipe
      .transform(item.toString()))
      .sort((a) => a === VERBRAUCHSTYP.Strom.toString() ? -1 : -1);
    return this.listingPipe.transform(divisions);
  }
}

