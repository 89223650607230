import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * proxy to use the default angular validators with an customErrorMessage (that fits the automatic display of the inputComponent)
 * @param {ValidatorFn} defaultValidator
 * @param {string} customErrorMessage
 * @returns {(control: AbstractControl) => {[p: string]: string}}
 */
export const defaultValidatorProxy = (defaultValidator: ValidatorFn, customErrorMessage: string) => {
  return (control: AbstractControl) => {
    return defaultValidator(control) === null ? null : {
      [defaultValidator.name]: customErrorMessage
    };
  };
};
