<button type="submit" class="re-button re-primary">
  <span class="re-hidden md:re-block"
    [innerHTML]="isContextSummary ? ('wizard.button.summary' | translate) : textButtonLong">
  </span>
  <span class="re-block md:re-hidden"
    [innerHTML]="sameTextForAllViewports ?
                 isContextSummary ? ('wizard.button.summary' | translate) : textButtonLong
                 : ('general.action.continue' | translate)">
  </span>
</button>
