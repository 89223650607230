import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { ContractPaymentData } from '../../../../../assets/js/com/ts_api_client';
import { ActivatedRoute, Router } from '@angular/router';
import { CONTEXT_FROM_SUMMARY } from '../../delivery/summary/summary.component';
import { FormFieldIdentifierTracking, TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { PaymentParentFormtype } from '../../../../shared/formtypes/payment-parent.formtype';
import { NavigationState } from '../../../models/navigationState';
import { Utilities } from '../../../../shared/utils/utilities';

@Component({
  selector: 'bdo-contract-end-payment',
  templateUrl: './contract-end-payment.component.html',
  styleUrls: ['./contract-end-payment.component.scss']
})
export class ContractEndPaymentComponent {
  public context: string;
  public CONTEXT_FROM_SUMMARY = CONTEXT_FROM_SUMMARY;
  public trackingData: FormFieldIdentifierTracking = {
    orca_form_name: TRACKING.LOCATION.CONTRACT_END_BILLING,
    orca_field_section: TRACKING.FORM.MOVE_PAYMENT_SECTION_OTHER
  };

  paymentEndContractForm = new FormGroup<PaymentParentFormtype>({
    paymentOptions: new FormControl('keepPayment', { validators: [Validators.required] }),
    paymentMethod: new FormControl(null)
  });

  constructor(
    public location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private trackingService: TrackingService
  ) {
    const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
    this.context = currentState?.context;
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_END_BILLING, TRACKING.ACTION.ENTER);
  }

  save() {
    if (this.paymentEndContractForm.invalid) {
      // mark all input fields as touched to display validation info
      this.paymentEndContractForm.markAllAsTouched();
    } else {

      const formData: ContractPaymentData = {};
      Object.keys(this.paymentEndContractForm.controls).forEach(key => {
        formData[key] = this.paymentEndContractForm.controls[key].value;
      });

      StorageService.setValue(ALLOWED_KEYS.PAYMENT_DATA, formData);
      if (this.context === CONTEXT_FROM_SUMMARY) {
        this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_END_BILLING, TRACKING.ACTION.GOTO, 'zusammenfassung');
        this.router.navigate(['../uebersicht'], {
          relativeTo: this.activatedRoute
        });
      } else {
        this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_END_BILLING, TRACKING.ACTION.GOTO, 'Rechnungsadresse');
        this.router.navigate(['../rechnungsadresse'], {
          relativeTo: this.activatedRoute
        });
      }
    }
  }
}
