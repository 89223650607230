"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConditionTypes = exports.CONFIGURATION_URL = exports.API_DATA_URL = exports.API_SSX_URL = exports.EVENTS_URL = exports.TARGETING_DATA_MAXIMUM_SIZE_BYTES = exports.MINUTES_SESSION_DURATION = exports.DEFAULT_MILLISECONDS_BETWEEN_TARGETING_DATA_UPDATE = exports.Milliseconds = void 0;
var Milliseconds;
(function (Milliseconds) {
  Milliseconds[Milliseconds["Second"] = 1000] = "Second";
  Milliseconds[Milliseconds["Minute"] = 60000] = "Minute";
  Milliseconds[Milliseconds["Hour"] = 3600000] = "Hour";
})(Milliseconds = exports.Milliseconds || (exports.Milliseconds = {}));
exports.DEFAULT_MILLISECONDS_BETWEEN_TARGETING_DATA_UPDATE = 30 * Milliseconds.Minute;
exports.MINUTES_SESSION_DURATION = 30 * Milliseconds.Minute;
exports.TARGETING_DATA_MAXIMUM_SIZE_BYTES = 500;
exports.EVENTS_URL = "https://events.kameleoon.com:8110/";
exports.API_SSX_URL = "https://api-ssx.kameleoon.com/";
exports.API_DATA_URL = "https://api-data.kameleoon.com/";
exports.CONFIGURATION_URL = "https://client-config.kameleoon.com/";
var ConditionTypes;
(function (ConditionTypes) {
  ConditionTypes["CUSTOM_DATUM"] = "CUSTOM_DATUM";
  ConditionTypes["EXCLUSIVE_EXPERIMENT"] = "EXCLUSIVE_EXPERIMENT";
  ConditionTypes["TARGET_EXPERIMENT"] = "TARGET_EXPERIMENT";
})(ConditionTypes = exports.ConditionTypes || (exports.ConditionTypes = {}));