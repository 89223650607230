<h4 class="re-container-with-margin re-mb-20 re-mt-60 md:re-mb-30 re-ml-20 md:re-ml-auto">
  {{ 'personaldata.address.title' | translate }}
</h4>
<div>
  <div class="re-relative re-container-with-margin re-mx-0 md:re-mx-auto re-h-100 re-bg-white" *ngIf="state === LoadingState.LOADING">
    <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
  </div>
  <div class="re-container-with-margin re-mx-0 md:re-mx-auto" data-qa="postalAddressErrorbox">
    <bdo-info-box *ngIf="state === LoadingState.ERROR">
      {{ 'general.error.error' | translate }}
    </bdo-info-box>
  </div>

  <ng-container *ngIf="state !== LoadingState.LOADING">

    <!-- Lieferadresse -->
    <div class="re-mb-3">
      <bdo-edit-card [isEditable]="false" class="re-relative">
        <div class="bold re-text-sm re-leading-m md:re-text-base md:re-leading-2xl re-mb-20 md:re-mb-15">
          {{ 'personaldata.address.delivery.title' | translate }}
          <bdo-inline-tooltip>
            <span [innerHtml]="'personaldata.address.delivery.info' | translate"></span>
          </bdo-inline-tooltip>
        </div>
        <div class="re-summary-row">
          <div>{{ 'address.street.title' | translate }}</div>
          <div>{{deliveryAddress?.streetName}} {{deliveryAddress?.houseNum}}</div>
        </div>
        <div class="re-summary-row re-mb-0">
          <div>{{ 'address.postCode.postCodeAndCity' | translate }}</div>
          <div>{{deliveryAddress?.postCode}} {{deliveryAddress?.cityName}}</div>
        </div>
      </bdo-edit-card>
    </div>

    <!-- Meine Postanschrift -->
    <div class="re-mb-3" data-qa="partnerAddress">
      <bdo-edit-card class="re-relative"
        [isEditable]="!isEditingPartnerAddress"
        [disabled]="disabled"
        (edit)="isEditingPartnerAddress = true; edit.emit(true);">

        <div class="bold re-text-sm re-leading-m md:re-text-base md:re-leading-2xl re-mb-20 md:re-mb-15">
          {{ 'personaldata.address.post.title' | translate }}
          <bdo-inline-tooltip>
            <span [innerHtml]="'personaldata.address.post.info' | translate"></span>
          </bdo-inline-tooltip>
        </div>

        <div *ngIf="!isEditingPartnerAddress && !partnerAddress">
          {{ 'personaldata.address.post.default' | translate }}
        </div>
        <div *ngIf="!isEditingPartnerAddress && postalAddresses?.partnerAddressData">
          <div class="re-summary-row">
            <div>{{ 'address.street.title' | translate }}</div>
            <div>{{postalAddresses?.partnerAddressData?.streetName}} {{postalAddresses?.partnerAddressData?.houseNum}}</div>
          </div>
          <div class="re-summary-row re-mb-0">
            <div>{{ 'address.postCode.postCodeAndCity' | translate }}</div>
            <div>{{postalAddresses?.partnerAddressData?.postCode}} {{postalAddresses?.partnerAddressData?.cityName}}</div>
          </div>
        </div>

        <div *ngIf="isEditingPartnerAddress">
          <bdo-personal-data-partner-address-form
            id="partnerAddress"
            [addressData]="postalAddresses?.partnerAddressData"
            (editDone)="onEditDone()"
          ></bdo-personal-data-partner-address-form>
        </div>

      </bdo-edit-card>
    </div>

    <!-- Abweichender Empfänger -->
    <bdo-edit-card class="re-relative" data-qa="correspondenceAddress"
      [isEditable]="!isEditingCorrespondenceAddress"
      [isDeletable]="!isEditingCorrespondenceAddress && !!correspondenceAddress"
      [disabled]="disabled"
      (delete)="onDelete()"
      (edit)="isEditingCorrespondenceAddress = true; edit.emit(true);">

      <div class="bold re-text-sm re-leading-m md:re-text-base md:re-leading-2xl re-mb-20 md:re-mb-15">
        {{ 'personaldata.address.correspondence.title' | translate }}
        <bdo-inline-tooltip>
          <span [innerHtml]="'personaldata.address.correspondence.info' | translate"></span>
        </bdo-inline-tooltip>
      </div>

      <ng-container *ngIf="!isEditingCorrespondenceAddress; else correspondenceForm">
        <div *ngIf="!correspondenceAddress?.companyAddressData && !correspondenceAddress?.privateAddressData">
          {{ 'personaldata.address.correspondence.default' | translate }}
        </div>

        <div *ngIf="correspondenceAddress?.privateAddressData">
          <div class="re-summary-row">
            <div>{{ 'address.personalInfo.name' | translate }}</div>
            <div>{{correspondenceAddress?.privateAddressData.firstname}} {{correspondenceAddress?.privateAddressData.surname}}</div>
          </div>
          <div class="re-summary-row">
            <div>{{ 'address.street.title' | translate }}</div>
            <div>{{correspondenceAddress?.privateAddressData.streetName}} {{correspondenceAddress?.privateAddressData.houseNum}}</div>
          </div>
          <div class="re-summary-row re-mb-0">
            <div>{{ 'address.postCode.postCodeAndCity' | translate }}</div>
            <div>{{correspondenceAddress?.privateAddressData.postCode}} {{correspondenceAddress?.privateAddressData.cityName}}</div>
          </div>
        </div>

        <div *ngIf="correspondenceAddress?.companyAddressData">
          <div class="re-summary-row">
            <div>{{ 'address.personalInfo.name' | translate }}</div>
            <div>{{correspondenceAddress?.companyAddressData.name1}} {{correspondenceAddress?.companyAddressData.name2}}</div>
          </div>
          <div class="re-summary-row">
            <div>{{ 'address.street.title' | translate }}</div>
            <div>{{correspondenceAddress?.companyAddressData.streetName}} {{correspondenceAddress?.companyAddressData.houseNum}}</div>
          </div>
          <div class="re-summary-row re-mb-0">
            <div>{{ 'address.postCode.postCodeAndCity' | translate }}</div>
            <div>{{correspondenceAddress?.companyAddressData.postCode}} {{correspondenceAddress?.companyAddressData.cityName}}</div>
          </div>
        </div>
      </ng-container>

      <ng-template #correspondenceForm>
        <bdo-personal-data-address-form
          id="correspondenceAddress"
          (editDone)="onEditDone()"
          [correspondenceAddressData]="correspondenceAddress"
        ></bdo-personal-data-address-form>
      </ng-template>

    </bdo-edit-card>

  </ng-container>
</div>
