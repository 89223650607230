import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ACTIVATION_STATE } from '../../bdo/enums/activationState.enum';
import { TRACKING } from '../../bdo/enums/trackingParts.enum';
import { TrackingService } from '../../bdo/services/tracking.service';
import { Location } from '@angular/common';
import { NavigationState } from '../../bdo/models/navigationState';
import { Utilities } from '../../shared/utils/utilities';

@Component({
  selector: 'bdo-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  activationState = ACTIVATION_STATE.NONE;
  ActivationStateEnum = ACTIVATION_STATE;
  code: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private trackingService: TrackingService,
    private location: Location
  ) {
    const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
    if (currentState?.activate) {
      this.activationState = currentState.activate;
      this.trackingService.postSimpleTracking(TRACKING.LOCATION.REGISTER, TRACKING.ACTION.ENTER + 'WithActivation.' + this.activationState);
    } else if (this.route?.snapshot?.queryParamMap?.get('registercode')) {
      this.trackingService.postSimpleTracking(TRACKING.LOCATION.REGISTER, TRACKING.ACTION.ENTER + 'WithRegistercode');
    } else {
      this.trackingService.postTracking(TRACKING.LOCATION.REGISTER, TRACKING.ACTION.ENTER);
    }
  }

  // todo
  ngOnInit() {
    this.code = this.route.snapshot.queryParamMap.get('code');
  }

  back() {
    this.trackingService.postTracking(TRACKING.LOCATION.REGISTER, TRACKING.ACTION.GOTO, TRACKING.LOCATION.LOGIN);
  }

}
