import { applyReviver } from '../doc/applyReviver.js';
import { NODE_TYPE, isDocument } from './identity.js';
import { toJS } from './toJS.js';
class NodeBase {
  constructor(type) {
    Object.defineProperty(this, NODE_TYPE, {
      value: type
    });
  }
  /** Create a copy of this node.  */
  clone() {
    const copy = Object.create(Object.getPrototypeOf(this), Object.getOwnPropertyDescriptors(this));
    if (this.range) copy.range = this.range.slice();
    return copy;
  }
  /** A plain JavaScript representation of this node. */
  toJS(doc, {
    mapAsMap,
    maxAliasCount,
    onAnchor,
    reviver
  } = {}) {
    if (!isDocument(doc)) throw new TypeError('A document argument is required');
    const ctx = {
      anchors: new Map(),
      doc,
      keep: true,
      mapAsMap: mapAsMap === true,
      mapKeyWarned: false,
      maxAliasCount: typeof maxAliasCount === 'number' ? maxAliasCount : 100
    };
    const res = toJS(this, '', ctx);
    if (typeof onAnchor === 'function') for (const {
      count,
      res
    } of ctx.anchors.values()) onAnchor(res, count);
    return typeof reviver === 'function' ? applyReviver(reviver, {
      '': res
    }, '', res) : res;
  }
}
export { NodeBase };