/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OfferData { 
    /**
     * accountId of existing contract, also knows as VK
     */
    account?: string;
    /**
     * partnerId of customer, also known as GP / GeschäftspartnerId
     */
    partner?: string;
    /**
     * campaign id indicating through which channel the order was referred, e.g. seo, sea, display, direct, affiliate ...
     */
    campaign?: string;
    /**
     * number of a meter
     */
    meterNumber?: string;
    /**
     * offer id / fca
     */
    offerId?: string;
    offerDate?: Date;
    /**
     * Email address
     */
    email?: string;
    /**
     * (Mobile)phone or fax number
     */
    mobile?: string;
    /**
     * id reflecting title attribute like \'Herr\', \'Frau\', \'keine Anrede\', \'Firma\' etc.
     */
    title?: string;
    /**
     * firstname of person
     */
    nameFirst?: string;
    /**
     * lastname of person
     */
    nameLast?: string;
    /**
     * PRIV -> private customer; GEWE -> business customer
     */
    customerMode?: string;
}

