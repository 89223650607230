<div *ngIf="bankAccountAssignments?.hasBankAccounts">
  <div class="re-mb-5">
    {{ 'dashboard.banking.description' | translate }}
  </div>
  <div class="bold" data-qa="debit-assignment">
    <div *ngIf="bankAccountAssignments?.debit; else noDebit">{{ bankAccountAssignments?.debit?.bankname}}:&nbsp;{{ bankAccountAssignments?.debit?.iban}}</div>
    <ng-template #noDebit>{{ 'banking.assignment.noDebit' | translate }}</ng-template>
  </div>
  <ng-container *ngIf="bankAccountAssignments?.debit !== bankAccountAssignments?.credit">
    <div *ngIf="bankAccountAssignments?.credit; else noCredit" data-qa="credit-assignment">
      <div class="bold re-mt-30">{{ 'dashboard.banking.credit' | translate }}:</div>
        {{ bankAccountAssignments?.credit?.bankname}}:&nbsp;{{ bankAccountAssignments?.credit?.iban}}
      </div>
    <ng-template #noCredit>
      <div class="re-mt-45" data-qa="manual-credit">
        {{ 'personaldata.banking.manualCredit' | translate }}
      </div>
    </ng-template>
  </ng-container>
</div>
<div class="re-mb-default" *ngIf="!bankAccountAssignments?.debit">
  <div [ngClass]="bankAccountAssignments?.debit === bankAccountAssignments?.credit ? 're-mt-90' : 're-mt-15'">
    <div data-qa="sepa-hint">
      {{ 'dashboard.banking.withoutSepaWithBankAccounts' | translate }}
    </div>
  </div>
</div>
