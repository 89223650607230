import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'bdo-text-icon',
  templateUrl: './text-icon.component.html',
  styleUrls: ['./text-icon.component.scss']
})
export class TextIconComponent {
  @Input() text: string = '';
  @Input() iconPosition: string = '';
  @Input() isPart: boolean = false; // Set this, if it is part of a bigger component and shall not be focusable on its own
  @Input() noColor: boolean = false; // e.g. on colored background to keep contrast high

  @Output() itemClicked: EventEmitter<any> = new EventEmitter();

  onClick() {
    this.itemClicked.emit();
  }

}
