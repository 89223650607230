<form #authenticationForm="ngForm" class="re-form-container re-py-0" (ngSubmit)="submit(authenticationForm)" bdoScrollToInvalidControl [bdoScrollToInvalidControlForm]="authenticationForm"
  autocomplete="new-password">
  <fieldset class="re-mb-0">

    <div class="re-form-item-container-row">
      <div>{{ 'general.customerData.meternumber' | translate }}</div>
      <div>{{ meterreading?.sparte | verbrauchstyp }}-Nr. {{ meterreading?.meterNumber }}</div>
    </div>

    <div class="re-form-item-container-row">
      <div>{{ 'meterreadings.info.meterreadingDate' | translate }}</div>
      <div>{{ identificationData?.atDate | date }}</div>
    </div>

    <div class="bdo-form-item-container">
      <bdo-info-box *ngIf="readingExists"
        [type]="'warning'">
        {{ 'meterreadings.error.dateAlreadyRegistered' | translate }}
      </bdo-info-box>
    </div>

    <div *ngFor="let zaehlwerk of meterreading?.zaehlwerkeListe" class="bdo-form-item-container">
      <div>
        <bdo-single-line-input #meterInput
          [inputName]="'zaehler-' + meterreading.meterNumber + '-' + zaehlwerk.zaehlwerknummer"
          [inputValue]="zaehlwerk?.wert?.toString() || ''"
          [inputType]="INPUT_TYPE.TEXT"
          placeholder="{{ 'meterreadings.meterreadingIn' | translate }} {{zaehlwerk.einheit | unitPretty}} {{ '' | zaehlwerktyp: zaehlwerk.typ }}"
          labelText="{{ 'meterreadings.meterreadingIn' | translate }} {{zaehlwerk.einheit | unitPretty}} {{ '' | zaehlwerktyp: zaehlwerk.typ }}"
          [required]="true"
          [forceValidate]="validateAll"
          [validationIconHidden]="validationIconHidden"
          [errors]="getMeterreadingValidationErrors(zaehlwerk)"
          (inputValueChanged)="updateMeterValue(zaehlwerk, $event)"
          (inputFocused)="onChange();"
        ></bdo-single-line-input>
      </div>

      <div class="re-absolute re-right-0 re-top-0">
        <bdo-inline-tooltip>
          <div [innerHTML]="meterreadingInfoService.getInfo(meterreading.sparte)"></div>
        </bdo-inline-tooltip>

      </div>
    </div>

    <div class="bdo-form-item-container" *ngIf="implausible">
      <bdo-info-box [type]="'warning'">
        {{ 'meterreadings.input.confirmMeterreading' | translate }}
      </bdo-info-box>

      <div class="bdo-form-item-container">
        <bdo-checkbox [required]="true" [checked]="meterreading.geprueft"
          (change)="meterreading.geprueft=!meterreading.geprueft">
          {{ 'meterreadings.input.confirmation' | translate }}
        </bdo-checkbox>
      </div>
    </div>

    <div class="bdo-space--bottom">
      <button class="bdo-button-primary bdo-button--text" type="submit">
        {{ 'general.action.save' | translate }}
      </button>
    </div>

    <div>
      <a class="bdo-nolink" [routerLink]="['/login']" data-qa="cancel">
        <div class="bold">{{ 'general.action.cancel' | translate }}</div>
      </a>
    </div>

  </fieldset>

</form>
