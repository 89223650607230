import { monitor } from '../tools/monitor';
import { getZoneJsOriginalValue } from '../tools/getZoneJsOriginalValue';
/**
 * Add an event listener to an event target object (Window, Element, mock object...).  This provides
 * a few conveniences compared to using `element.addEventListener` directly:
 *
 * * supports IE11 by: using an option object only if needed and emulating the `once` option
 *
 * * wraps the listener with a `monitor` function
 *
 * * returns a `stop` function to remove the listener
 */
export function addEventListener(configuration, eventTarget, eventName, listener, options) {
  return addEventListeners(configuration, eventTarget, [eventName], listener, options);
}
/**
 * Add event listeners to an event target object (Window, Element, mock object...).  This provides
 * a few conveniences compared to using `element.addEventListener` directly:
 *
 * * supports IE11 by: using an option object only if needed and emulating the `once` option
 *
 * * wraps the listener with a `monitor` function
 *
 * * returns a `stop` function to remove the listener
 *
 * * with `once: true`, the listener will be called at most once, even if different events are listened
 */
export function addEventListeners(configuration, eventTarget, eventNames, listener, _a) {
  var _b = _a === void 0 ? {} : _a,
    once = _b.once,
    capture = _b.capture,
    passive = _b.passive;
  var listenerWithMonitor = monitor(function (event) {
    if (!event.isTrusted && !event.__ddIsTrusted && !configuration.allowUntrustedEvents) {
      return;
    }
    if (once) {
      stop();
    }
    listener(event);
  });
  var options = passive ? {
    capture: capture,
    passive: passive
  } : capture;
  // Use the window.EventTarget.prototype when possible to avoid wrong overrides (e.g: https://github.com/salesforce/lwc/issues/1824)
  var listenerTarget = window.EventTarget && eventTarget instanceof EventTarget ? window.EventTarget.prototype : eventTarget;
  var add = getZoneJsOriginalValue(listenerTarget, 'addEventListener');
  eventNames.forEach(function (eventName) {
    return add.call(eventTarget, eventName, listenerWithMonitor, options);
  });
  function stop() {
    var remove = getZoneJsOriginalValue(listenerTarget, 'removeEventListener');
    eventNames.forEach(function (eventName) {
      return remove.call(eventTarget, eventName, listenerWithMonitor, options);
    });
  }
  return {
    stop: stop
  };
}
