import { animate, state, style, transition, trigger } from '@angular/animations';

export const FadeAnimation = [
    // the fade-in/fade-out animation.
    trigger('fadeAnimation', [

        // the "in" style determines the "resting" state of the element when it is visible.
        state('in', style({ opacity: 1 })),

        // fade in when created
        transition(':enter', [
            style({ opacity: 0 }),
            animate('{{speed}}')
        ], { params: { speed: '300ms' } }),

        // fade out when destroyed
        transition(':leave',
            animate('{{speed}}', style({ opacity: 0 }))
            , { params: { speed: '100ms' } })
    ])
];
