import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'bdo-feedback-done',
  templateUrl: './feedback-done.component.html',
  styleUrls: ['./feedback-done.component.scss']
})
export class FeedbackDoneComponent {
  @Output() done: EventEmitter<any> = new EventEmitter();

  closeAll() {
    this.done.emit();
  }
}
