<div class="dashboard-tile" [attr.title]="tileTitle" >
  <h4 *ngIf="tileTitle" class="dashboard-tile__title re-mb-20 md:re-mb-15">
    {{tileTitle}}
  </h4>

  <ng-content></ng-content>

  <div class="text-arrow re-mt-auto re-pt-15" *ngIf="linkData?.length > 0">
    <div *ngFor="let link of linkData" class="re-mt-15">
      <bdo-text-link-icon
        (click)="$event.stopPropagation();"
        [linkText]="link.linkName"
        [href]="null"
        [routerUrl]="link.href"
        [fragment]="link.fragment"
        [iconPosition]="'right'"
        [tracking]="link.tracking">
      </bdo-text-link-icon>
    </div>
  </div>
</div>
