<div class="center">
  <img class="bdo-space--bottom" src="assets/icons/bdo/icon-check-big.svg">
</div>

<h4 class="bdo-space--top bdo-space--bottom re-text-center">{{ 'register.successTitle' | translate }}</h4>
<p class="bdo-space--top">
{{ 'register.successInfo' | translate }}</p>

<div class="center">
  <button class="bdo-button-primary bdo-space--top" (click)="close()">{{ 'general.navigation.backToLogin' | translate }}</button>
</div>
