<div class="re-container-headline re-mb-30 re-mt-20 re-block
          md:re-mb-45 md:re-mt-35
          lg:re-mb-60">
  <h3>{{title}}</h3>
</div>

<div class="re-container-with-margin" *ngIf="copytext">
  <div class="re-copy-text re-mb-45 re-mt-60 re-block">
    {{copytext}}
  </div>
</div>

<div *ngIf="showRecentTariff">
  <div class="re-container-with-margin">
    <h5 class="re-mb-20 md:re-mb-30 re-block">
      {{ 'tariff.selection.title' | translate }}
    </h5>
  </div>

  <div class="bdo-container--single re-relative" [ngClass]="stateOldContract === LoadingState.LOADING ? 'bdo-loading__wrapper--height re-mt-30' : ''">
    <bdo-loading *ngIf="stateOldContract === LoadingState.LOADING"></bdo-loading>

    <div class="bdo-container--single re-mb-30 md:re-mb-60">
      <bdo-info-box class="bdo-container--single" *ngIf="stateOldContract === LoadingState.ERROR">
        {{ 'general.error.error' | translate }}
      </bdo-info-box>
    </div>

    <div *ngIf="stateOldContract !== LoadingState.ERROR"
      class="re-flex re-flex-col re-bg-white re-p-20 re-pb-30 re-text-base re-leading-2xl re-mb-30 re-w-full re-mx-auto
             md:re-flex-row md:re-text-left md:re-mb-45 md:re-w-600 md:re-p-30 md:re-pr-45
             lg:re-w-900">
      <div class="re-flex re-flex-col md:re-flex-row re-mr-15 re-mb-15 md:re-mb-0">
        <div class="re-w-60 re-h-60 re-mr-15 re-mb-5 md:re-inline-block md:re-mb-0">
          <svg-icon [src]="tariffAdvisorService.getPathForIcon(oldContract?.division)" [svgAriaLabel]="'Tarif-Icon'"></svg-icon>
        </div>
        <div class="re-flex re-flex-col">
          <h5 class="bold re-mb-5" [attr.data-qa]="'oldContractName'">{{oldContract?.tariffName}}</h5>
          <div class="re-text-sm">
            {{ 'general.customerData.meternumber' | translate }}:
            <span class="bold">{{oldContract?.meterNumbers[0]}}</span>
          </div>
        </div>
      </div>
      <div class="re-flex re-flex-col md:re-ml-auto" *ngIf="oldMonthlyPrice">
        <div class="md:re-mb-5 md:re-ml-auto bold">{{oldMonthlyPrice | currency: 'EUR'}}</div>
        <div class="re-font-lighten re-text-xs re-leading-s"
             [innerHTML]="'tariff.selection.billingPerConsumption' | translate: { consumption: annualConsumption.toString() }">
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="stateOldContract !== LoadingState.ERROR" class="re-container-with-margin">
  <!--Title-->
  <h5 class="re-mb-20 md:re-mb-30 re-block" *ngIf="newTariffTitle">
    {{newTariffTitle}}
  </h5>

  <!--Consumption-->
  <div class="re-relative" [ngClass]="stateConsumption === LoadingState.LOADING ? 'bdo-loading__wrapper--height re-mt-30' : ''">
    <bdo-loading *ngIf="stateConsumption === LoadingState.LOADING"></bdo-loading>

    <div *ngIf="isConsumptionChangeable; else isConsumptionNotChangeable">
      <bdo-tariff-consumption-selection
        [consumption]="annualConsumption"
        [postCode]="address?.postCode"
        [divisionId]="divisionId"
        [isInEditMode]="false"
        (calculate)="onCalculate($event)"
        (edit)="onEdit($event)"
      ></bdo-tariff-consumption-selection>
    </div>
    <ng-template #isConsumptionNotChangeable>
      <div class="re-bg-white re-w-full re-text re-flex re-p-20 re-pr-100 re-h-80 re-items-center re-relative
                    md:re-w-600 md:re-mx-auto md:re-px-30
                    lg:re-w-900">
        <div class="re-pt-3 md:re-text-base re-flex re-flex-row">
          <div>{{ 'tariff.selection.consumption' | translate }}</div>
          <span class="bold">&nbsp;{{annualConsumption | number}}&nbsp;{{divisionId | divisionToUnit: 'billing'}}</span>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div class="bdo-container--single">
  <div [class.re-mb-15]="state === LoadingState.ERROR">
    <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">
      {{ 'general.error.error' | translate }}
    </bdo-info-box>
  </div>
</div>

<div class="bdo-container--single re-relative" [ngClass]="state === LoadingState.LOADING ? 'bdo-loading__wrapper--height re-mt-30' : ''">
  <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
</div>

<div class="bdo-container">
  <div [@fadeIn] *ngIf="tilesShown && stateOldContract !== LoadingState.ERROR"
  class="bdo-tariff-tile-container re-gap-20 re-flex re-flex-col re-items-center re-justify-center re-flex-grow-0 rate-price-tile--sum
  lg:re-items-stretch lg:re-flex-row lg:re-flex-nowrap">
    <ng-container *ngFor="let tariff of tariffs">
      <bdo-tariff-tile [tariffData]="tariff"
                       [isSmall]="tariffs?.length >= 4"
                       (selectTariff)="onTariffSelected($event)"
                       class="rate-price-tile re-mb-40 lg:re-mb-0 re-flex re-flex-col">
      </bdo-tariff-tile>
                      <!-- TODO: must be build in for tariffs with options -->
                      <!-- (changeOption)="onChangeOption($event)" -->
    </ng-container>
  </div>
</div>



<div class="re-container-with-margin re-mt-30" *ngIf="linkForTextLinkIcon">
  <bdo-text-link-icon
    [iconPosition]="'left'"
    [linkText]="'general.action.back' | translate"
    [routerUrl]="linkForTextLinkIcon"
  ></bdo-text-link-icon>
</div>
