import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'bdo-verbrauchs-animation',
  templateUrl: './verbrauchs-animation.component.html',
  styleUrls: ['./verbrauchs-animation.component.scss']
})
/** Animating Data as a Circle */
export class VerbrauchsAnimationComponent implements OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('canvas', { static: true }) canvasElement: ElementRef;
  @ViewChild('canvas__container', { static: true }) canvasContainerElement: ElementRef;

  @Input() expected: number;
  @Input() real: number;
  @Input() reference: number;
  @Input() unit: string;
  @Input() hasCredit: boolean;
  // Skipping the animation and just showing the finished picture
  skipAnimating: boolean = false;

  color_good: string = '#148700';
  color_bad: string = '#e30613';
  color_default: string = '#039ee0';
  color_background: string = '#cccdcd';
  color_font: string = '#383838';

  font_default: string = 'Suisse Light';
  font_bold: string = 'Suisse Medium';

  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  // Coordinates x and y in the center of the element
  x: number;
  y: number;
  // Number currently shown in Animation
  current: number;
  // If the Animation already finished the "expected"-part
  expdone: boolean = false;
  // To keep the ratio the same independent of canvas size
  ratio: number;
  // Icon showing a good or bad state
  base_image: HTMLImageElement;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  constructor() {
  this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe( { next: evt => {
    // Calc new Width and Height
      this.initCanvas();
    // Draw new
    this.skipAnimating = true;
    window.requestAnimationFrame(this.expCircle.bind(this));
    } });
  }

  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    this.ngAfterViewInit();
  }

  ngAfterViewInit() {
    this.initCanvas();
    // Animating
    window.requestAnimationFrame(this.expCircle.bind(this));
    // why not: if(this.skipAnimating) this.drawEndScreen();
    // this.testDrawing();
  }

  initCanvas() {
    this.canvas = this.canvasElement.nativeElement;
    const container = this.canvasContainerElement.nativeElement.getBoundingClientRect();

    this.canvas.width = container.width;
    this.canvas.height = container.width;

    this.ctx = this.canvas.getContext('2d');
    this.x = this.canvas.width / 2;
    this.y = this.canvas.height / 2;
    this.current = (this.skipAnimating ? this.real : 0);
    this.expdone = false;
    this.ratio = (this.expected + (Math.abs(this.expected - this.real))) / 100;
    this.base_image = new Image();
    this.base_image.src = (this.hasCredit ? 'assets/fonts/animation_icons/good.png' : 'assets/fonts/animation_icons/bad.png');
    this.ctx.lineWidth = this.scale(10);
    this.ctx.strokeStyle = this.color_font;
    this.ctx.textAlign = 'center';
  }

  testDrawing() {
    // var lstart = (start / this.reference) * 100;
    const lcurrent = (this.current / this.reference) * 100;

    // Clear Canvas
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    // Draw Default
    this.drawCircle(0, 100, this.color_background, 1);
    // Draw Expected
    this.drawCircle(0, lcurrent, this.color_default, 1);
  }

  expCircle = function() {

    // Draws the expected Part of the Circle
    if (this.current !== this.expected) {
      this.drawCircles(0, this.current, this.color_default, true);
      this.writeProgress(this.current);
      this.current = Math.round((this.current + this.ratio) * 100) / 100;
      if (this.skipAnimating) { this.current = this.expected; }
      if (this.current > this.expected) { this.current = this.expected; }
      window.requestAnimationFrame(this.expCircle.bind(this));
    } else {
      this.drawCircles(0, this.current, this.color_default, true);
      this.writeProgress(this.current);
      this.expdone = true;
      // When animating wait a moment before drawing the next part
      if (this.skipAnimating) {
        window.requestAnimationFrame(this.realCircle.bind(this));
      } else {
        setTimeout(() => {
          window.requestAnimationFrame(this.realCircle.bind(this));
        }, 1000);
      }
    }
  };

  drawCircles(start, current, color, clear) {
    // No colors if no up-to-date State
    if (this.current < 1) { color = this.color_background; }
    const lstart = (start / this.reference) * 100;
    const lcurrent = (current / this.reference) * 100;
    if (clear) {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.drawCircle(0, 100, this.color_background, 1);
    }
    if (lcurrent > 200) {
      if (lstart < 100) {
        this.drawCircle(lstart, 100, color, 1);
        this.drawCircle(0, 100, color, 2);
        this.drawCircle(0, lcurrent - 200, color, 3);
      } else if (lstart < 200) {
      this.drawCircle(lstart - 100, 100, color, 2);
        this.drawCircle(0, lcurrent - 200, color, 3);
      } else {
      this.drawCircle(lstart - 200, lcurrent - 200, color, 3);
      }
    } else if (lcurrent > 100) {
        if (lstart < 100) {
        this.drawCircle(lstart, 100, color, 1);
          this.drawCircle(0, lcurrent - 100, color, 2);
        } else {
          this.drawCircle(lstart - 100, lcurrent - 100, color, 2);
        }
    } else { this.drawCircle(lstart, lcurrent, color, 1); }

}
  drawCircle(start, end, color, level) {
    if (start === 0) { start = 0.15; }
    if (end === 100) { end = 99.85; }
    this.ctx.beginPath();
    this.ctx.arc(this.x, this.y, this.scale(75) + level * this.scale(13), (start / 50 - 0.5) * Math.PI, (end / 50 - 0.5) * Math.PI, false);
    this.ctx.strokeStyle = color;
    this.ctx.stroke();
  }

  drawEndScreen() {
    this.drawCircles(0, this.expected, this.color_default, true);
    this.writeProgress(this.real);
    this.ctx.font = (this.scale(10)) + 'px ' + this.font_default;
    const w1 = this.ctx.measureText('Sie haben ' + (this.unit !== '%' ? this.real - this.expected + ' ' + this.unit : '')).width;

    if (this.expected < this.real) {
        /* var w2 = this.ctx.measureText("mehr").width;
        var x1 = this.x - ((w2 + w1 +this. scale(4)) / 2) + w1 / 2;
        this.ctx.fillText("Sie haben " + (this.unit !== "%" ? (100*this.real - 100*this.expected)/100 + " " + this.unit : ""), x1, this.y + this.scale(25));
        this.ctx.font = (this.scale(10) | 0) + "px " + this.font_bold; */
        this.drawCircles(this.expected, this.real, this.color_bad, false);
        /* this.ctx.fillStyle = this.color_bad;
        this.ctx.fillText("mehr", x1 + w1 / 2 + w2 / 2 + this.scale(2), this.y + this.scale(25)); */
    } else if (this.expected > this.real) {
        /* var w2 = this.ctx.measureText("weniger").width
        var x1 = this.x - ((w2 + w1 + this.scale(4)) / 2) + w1 / 2
        this.ctx.fillText("Sie haben " + (this.unit !== "%" ? (100*this.real - 100*this.expected)/100 + " " + this.unit : ""),
                            this.x - ((w2 + w1 + 4) / 2) + w1 / 2, this.y + this.scale(25));
        this.ctx.font = (this.scale(10) | 0) + "px " + this.font_bold; */
        this.drawCircles(this.real, this.expected, this.color_good, false);
        /* this.ctx.fillStyle = this.color_good;
        this.ctx.fillText("weniger", x1 + w1 / 2 + w2 / 2 + this.scale(2), this.y + this.scale(25)); */
    } else {
      /* this.ctx.fillText("verbrauch wie", this.x, this.y + this.scale(25)); */
    }
      /* this.ctx.font = (this.scale(10) | 0) + "px " + this.font_default;
      this.ctx.fillStyle = this.color_font;
      this.ctx.fillText((this.expected != this.real ? "verbraucht als" : "") + " erwartet", this.x, this.y + this.scale(35)); */
      let radius = this.scale(83);
      let real = (this.real / this.reference) * 100;

      if (real > 200) {
          radius += this.scale(26);
          real -= 200;
      } else if (real > 100) {
          radius += this.scale(13);
          real -= 100;
      }
      const xfactor = Math.cos(2 * Math.PI * (real + 75 < 100 ? real + 75 : real - 25) / 100);
      const yfactor = Math.sin(2 * Math.PI * (real + 75 < 100 ? real + 75 : real - 25) / 100);
      const xi = radius * xfactor - ((0.0907 * this.canvas.width - this.scale(10)) / 2) + (xfactor - 1) / 2 * this.scale(10);
      const yi = radius * yfactor - ((0.0907 * this.canvas.height - this.scale(10)) / 2) + (yfactor - 1) / 2 * this.scale(10);
      // No Icon if no up-to-date State
      if (this.current > 1) {
        this.ctx.drawImage(this.base_image, this.x + xi, this.y + yi, 0.0907 * this.canvas.width, 0.0907 * this.canvas.width);
      }
  }

  drawRealCircle() {
    this.drawCircles(0, this.expected, this.color_default, true);
    this.writeProgress(this.current);
    if (this.real <= this.expected) {
      this.drawCircles(this.current, this.expected, this.color_good, false);
      if (this.current <= this.real) {
        this.drawEndScreen();
      } else {
        this.current = Math.round((this.current - this.ratio) * 100) / 100;
        window.requestAnimationFrame(this.realCircle.bind(this));
      }
    } else {
      this.drawCircles(this.expected, this.current, this.color_bad, false);
      if (this.current >= this.real) {
        this.drawEndScreen();
      } else {
        this.current = Math.round((this.current + this.ratio) * 100) / 100;
        window.requestAnimationFrame(this.realCircle.bind(this));
      }
    }
  }

  writeProgress(number) {
    /* if (this.unit === "%") number = (number / this.reference) * 100;
    this.ctx.font = (this.scale(37) | 0) + "px " + this.font_bold;
    var w1 = this.ctx.measureText(Math.round(number).toString()).width
    this.ctx.font = (this.scale(14) | 0) + "px " + this.font_default;
    if (number > 1000) this.ctx.font = (this.scale(10) | 0) + "px " + this.font_default;

    var w2 = this.ctx.measureText(this.unit).width
    this.ctx.font = (this.scale(37) | 0) + "px " + this.font_bold;
    this.ctx.fillText(Math.round(number).toString(), this.x, this.y - this.scale(15));
    this.ctx.font = (this.scale(14) | 0) + "px " + this.font_default;
    this.ctx.fillText(this.unit, this.x + w1 / 2 + w2 / 2, this.y - this.scale(15));
    this.ctx.font = (this.scale(8) | 0) + "px " + this.font_default;
    this.ctx.fillText((this.expdone ? "bisher verbraucht" : "erwarteter Verbrauch"), this.x, this.y - this.scale(3)); */
  }

  scale(defaultSize) {
    if (this.canvas.width) {
      return defaultSize / 250 * this.canvas.width;
    } else {
      return 0;
    }
  }

  realCircle = function() {
    if (this.skipAnimating) { this.current = this.real; }
    if (this.checkNewLevel()) {
        const lcurrent = (this.current / this.reference) * 100;
        this.current = (lcurrent < 120 ? this.reference : this.reference * 2);
        this.drawCircles(0, this.expected, this.color_default, true);
        this.drawCircles(this.expected, this.current, (this.real <= this.expected ? this.color_good : this.color_bad), false);
        this.writeProgress(this.current);
        if (this.skipAnimating === true) {
          this.drawRealCircle();
        } else {
          setTimeout(function () {
            this.drawRealCircle();
          }, 500);
        }
    } else { this.drawRealCircle(); }
  };

  checkNewLevel() {
    const lcurrent = (this.current / this.reference) * 100;
    const lratio = (this.ratio / this.reference) * 100;
    return (
      ((lcurrent >= 100 - lratio / 2 && lcurrent <= 100 + lratio / 2) ||
      (lcurrent >= 200 - lratio / 2 && lcurrent <= 200 + lratio / 2)) && this.expected < this.real
    );
  }
}
