import { Component } from '@angular/core';
import { TRACKING } from '../../../enums/trackingParts.enum';

@Component({
  selector: 'bdo-offer-tariff-advisor',
  templateUrl: './offer-tariff-advisor.component.html',
  styleUrls: ['./offer-tariff-advisor.component.scss']
})
export class OfferTariffAdvisorComponent {
  public TRACKING_LOCATION = TRACKING.LOCATION;

}


