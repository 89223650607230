import { Component, Input } from '@angular/core';
import { VERBRAUCHSTYP } from '../../../../enums/verbrauchstyp.enum';
import { LinkData } from '../../../../models/LinkData';
import { VerbrauchstypPipe } from '../../../../pipes/verbrauchstyp.pipe';
import { TranslateService } from '@ngx-translate/core';

export interface ExternalTenantConfiguration {
  changeTenant: boolean;
  logo: string;
  companyName: string;
  divisionIconSrc: string;
  divisions: {
    divisionId: VERBRAUCHSTYP;
    tariffAdvisorLink: string;
  }[];
}

@Component({
  selector: 'bdo-external-tenant-tile',
  templateUrl: './external-tenant-tile.component.html',
  styleUrls: ['./external-tenant-tile.component.scss']
})
export class ExternalTenantTileComponent {

  @Input() externalTenantConfiguration: ExternalTenantConfiguration;

  constructor(
    private verbrauchstypPipe: VerbrauchstypPipe,
    private translateService: TranslateService
  ) { }

  getLinkData(): Array<LinkData> {
    const linkData: Array<LinkData> = [];
    if (this.externalTenantConfiguration.divisions.length > 1) {
      this.externalTenantConfiguration.divisions.forEach(division => {
        linkData.push({
          linkName: this.translateService.instant('externalTile.divisionTitle', { division: this.verbrauchstypPipe.transform(division.divisionId) }),
          href: division.tariffAdvisorLink
        });
      });
    } else {
      linkData.push({
        linkName: this.translateService.instant('externalTile.title'),
        href: this.externalTenantConfiguration?.divisions[0]?.tariffAdvisorLink
      });
    }
    return linkData;
  }

}
