<bdo-large-popup [isVisible]="isVisible" (closePopupEvent)="onClose()" closeButtonModifier="">
  <div class="re-bg-monochrome-light">
    <div class="re-pt-45 re-pb-30 re-px-20 re-text-left md:re-px-45">
      <div class="re-mb-25">
        <h1 class="h3">{{ 'tariff.overlay.title' | translate }}<span [innerHTML]="tariffData?.name"></span></h1>
      </div>
      <div>
        <bdo-tariff-prices-overview
          [tariffData]="tariffData"
          [prices]="prices"
        ></bdo-tariff-prices-overview>
      </div>
    </div>

    <bdo-tariff-prices-cost-info [tariffData]="tariffData" class="re-block re-mx-20 md:re-mx-45 re-mb-20 md:re-mb-45"></bdo-tariff-prices-cost-info>

    <div *ngIf="tariffData?.layer?.text?.length > 0" class="re-pt-45 re-pb-30 re-px-20 re-text-left md:re-px-45 md:re-py-45 xl:re-px-60 re-bg-white">
      <div>
        <h2 class="re-mb-30" [innerHTML]="tariffData?.layer?.headline"></h2>
        <div class="description re-text-sm lg:re-text-base re-leading-tight" [innerHTML]="tariffData?.layer?.text"></div>
        <div *ngIf="tariffData?.layer?.note?.length > 0" class="description re-text-sm lg:re-text-base re-leading-tight" [innerHTML]="tariffData?.layer?.note"></div>
      </div>
    </div>
    <div
      class="re-pt-45 re-pb-30 re-px-20 re-text-left md:re-px-45 md:re-py-45 xl:re-px-60 re-bg-white-smoke">
      <h2 class="re-mb-30"><span>{{ 'tariff.overlay.titleDocuments' | translate }}</span></h2>
      <div class="download re-mb-20 re-text-sm re-leading-s" *ngFor="let download of tariffData?.layer?.downloads">
        <a [href]="tariffAdvisorService.getDocumentLink() + download?.link" download="" class="re-group re-flex re-flex-col-reverse md:re-flex-row re-font-regular">
          <div class="title re-text-black group-hover:re-text-primary re-mr-10 re-mb-10 lg:re-mb-10 re-pt-7" [innerHTML]="download?.title"></div>
          <div>
            <div class="badge re-py-7 re-px-15 re-bg-white re-rounded-sm re-uppercase group-hover:re-bg-primary group-hover:re-text-white re-text-black re-inline-block re-text-center re-dl">
              <span [innerHTML]="download?.extension"></span>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</bdo-large-popup>
