<button [ngxTippy]="tippyTemplate"
        [tippyClassName]="'bdo-inline-tooltip re-w-[335px] lg:re-w-[410px] re-max-w-[335px] lg:re-max-w-[410px] re-leading-m'"
        [tippyProps]="tippyProps"
        type="button"
        [attr.aria-label]="'general.tooltip.label' | translate">
  <ng-container *ngTemplateOutlet="infoIcon"></ng-container>
</button>

<ng-template #infoIcon>
  <div class="re-fill-current re-m-auto re-text-auto hover:re-cursor-pointer hover:re-text-primary"
    [ngClass]="size === 'large' ? 're-w-30 re-h-30' : 're-w-20 re-h-20'">
    <svg-icon *ngIf="size !== 'large'" svgClass="re-block" src="assets/icons/system_icons/info.svg"></svg-icon>
    <svg-icon *ngIf="size === 'large'" svgClass="re-block" src="assets/icons/system_icons/info_30x30.svg"></svg-icon>
  </div>
</ng-template>

<div #tippyTemplate
  class="re-hidden re-relative re-border re-border-mineshaft re-w-[335px] lg:re-w-[410px]"
  role="tooltip"
  title="{{name || 'Infotext'}}">
  <button class="close re-absolute re-right-15 re-top-15 re-flex re-w-22 re-h-22 mx-w-22 re-items-center re-no-underline re-justify-center re-bg-mineshaft"
    [attr.aria-label]="'general.action.close' | translate"
    [title]="'general.action.close' | translate"
    type="button">
    <svg-icon
      class="re-text-white re-w-22"
      src="assets/icons/close.svg"
      >
    </svg-icon>
  </button>
  <div class="re-m-15 re-mr-45 re-leading-m re-text-sm re-font-normal re-text-left re-font-lighten">
    <ng-content></ng-content>
  </div>
</div>

