<bdo-large-popup [isVisible]="show()" (closePopupEvent)="closePopup()" data-qa="opt-in-popup">
  <div class="re-flex re-flex-auto re-flex-col re-min-h-full
              md:re-flex-row-reverse">
    <div class="re-max-h-1/2
                md:re-w-1/2 md:re-min-w-1/2">
        <img class="re-object-cover re-h-full re-w-full"
             [src]="'optInPopup.logo' | translate"
             alt=""/>
      </div>

      <div class="re-flex re-flex-auto re-flex-col re-justify-between re-px-20 re-pt-30
                  md:re-p-45 md:re-pb-60 md:re-w-1/2 md:re-min-w-1/2">
        <div class="re-max-w-full re-mb-30 re-overflow-hidden">
          <h1 class="re-text-primary re-overflow-hidden re-mb-15
                     md:re-mb-30 md:re-py-5">
            {{ 'optInPopup.headline' | translate }}
          </h1>
          <p class="md:re-text-sm">
            {{ 'optInPopup.description' | translate }}
          </p>
        </div>
        <div class="re-flex re-flex-col re-justify-end re-pb-20
                    md:re-pb-0">
          <a class="re-button re-primary re-px-25 re-text-center
                    md:re-mr-auto"
                  data-qa="opt-in-button"
                  (click)="onClickSaveNow()"
                  >
            {{ 'optInPopup.button' | translate }}
          </a>
        </div>
      </div>
  </div>
</bdo-large-popup>
