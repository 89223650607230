import { Component, EventEmitter, ElementRef, HostListener, Input, OnChanges, OnDestroy, Output, Renderer2, SimpleChanges } from '@angular/core';
import { TenantService } from '../../../bdo/services/tenant.service';
import { FadeAnimation } from '../../animations/fade.animation';
import { ConfigurableFocusTrap, ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';

@Component({
  selector: 'bdo-simple-text-popup',
  templateUrl: './simple-text-popup.component.html',
  styleUrls: ['./simple-text-popup.component.scss'],
  animations: FadeAnimation
})
export class SimpleTextPopupComponent implements OnDestroy, OnChanges {

  @Input() title: string;
  @Input() icon: 'success' | 'error' | 'warning' | '' = '';
  @Input() isVisible: boolean;
  @Input() isManuallyClosable: boolean = true;
  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Output() closePopupEvent: EventEmitter<any> = new EventEmitter();
  closeButtonModifier = '';

  private focusTrap: ConfigurableFocusTrap;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private focusTrapFactory: ConfigurableFocusTrapFactory,
    public tenantService: TenantService
  ) { }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      if (this.isVisible) {
        this.onClose();
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyFocusTrap();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isVisible &&
      changes.isVisible.currentValue !== changes.isVisible.previousValue &&
      changes.isVisible.currentValue === true) {
      this.renderer.addClass(document.body, 'popupOpen');
      this.focusTrap = this.focusTrapFactory.create(this.el.nativeElement);
      this.focusTrap.focusFirstTabbableElement();
    }
    if (changes.isVisible && changes.isVisible.currentValue === false) {
      this.destroyFocusTrap();
    }
  }

  destroyFocusTrap(){
    this.renderer.removeClass(document.body, 'popupOpen');
    if (this.focusTrap) {
      this.focusTrap.destroy();
    }
  }

  onClose() {
    // on close popup should only be possible, if the close indicator is shown
    if (this.isManuallyClosable) {
      this.isVisibleChange.emit(false);
      this.closePopupEvent.emit(null);
      this.destroyFocusTrap();
    }
  }

}
