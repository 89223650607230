import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TariffAdvisorService } from '../../services/tariff-advisor.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { first, Subscription, switchMap } from 'rxjs';
import { ALLOWED_KEYS, StorageService } from '../../services/storage.service';
import {
  AddressData,
  AddressTariffData,
  ContractDetails,
  ContractLevel,
  PersonalData,
  SituationData,
  TariffSummary,
  TariffSummaryContractLevel,
  TariffSummaryResults
} from '../../../../assets/js/com/ts_api_client';
import { VERBRAUCHSTYP } from '../../enums/verbrauchstyp.enum';
import { SelectButtonConfiguration } from '../../../shared/atoms/select-button/select-button-configuration.model';
import { SelectButtonGroupComponent } from '../../../shared/atoms/select-button-group/select-button-group.component';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { defaultValidatorProxy } from '../../../shared/validators/default-validator-proxy';
import { CONTEXT_FROM_SUMMARY } from '../delivery/summary/summary.component';
import { TRACKING } from '../../enums/trackingParts.enum';
import { TrackingService } from '../../services/tracking.service';
import { FadeInAnimation } from '../../../shared/animations/fade-in.animation';
import { BdoApiService } from '../../services/bdo-api.service';
import moment from 'moment';
import { LOADING_STATE } from '../../enums/loadingState.enum';
import { CUSTOMERMODE } from '../../enums/customerMode';
import { ConsumptionFormtype } from '../../../shared/formtypes/consumption.formtype';
import { LoginService } from '../../../login/login.service';

import { CustomerStoreService } from '../../services/customer-store.service';
import { NavigationState } from '../../models/navigationState';
import { Utilities } from '../../../shared/utils/utilities';
import { PriceCapService } from '../../services/price-cap.service';
import { TariffSelection } from '../../../shared/models/tariff-selection';
import { CONTRACT_DETAILS_TILE_MODE } from '../../enums/contract-details-tile-mode.enum';
import { TranslateService } from '@ngx-translate/core';

export interface TariffConfiguration {
  division: string;
  consumption: number;
  postCode: string;
  city: string;
  street: string;
  houseNum: string;
}

interface DivisionConfigurations {
  [key: string]: SelectButtonConfiguration;
}

export const defaultDivisionConfigurations: DivisionConfigurations = {
  F4: undefined, N2: undefined, S1: undefined, T4: undefined, V2: undefined, ZZ: undefined,
  [VERBRAUCHSTYP.Gas]: {
    id: VERBRAUCHSTYP.Gas,
    completed: false,
    active: false,
    iconSrc: 'assets/icons/gas_30x30.svg',
    title: 'Erdgas'
  },
  [VERBRAUCHSTYP.Strom]: {
    id: VERBRAUCHSTYP.Strom,
    completed: false,
    active: false,
    iconSrc: 'assets/icons/energy_30x30.svg',
    title: 'Strom'
  }
};


@Component({
  selector: 'bdo-tariff-advisor',
  templateUrl: './tariff-advisor.component.html',
  styleUrls: ['./tariff-advisor.component.scss'],
  animations: [
    FadeInAnimation
  ]
})
export class TariffAdvisorComponent implements OnInit {

  @ViewChild('selectButtonSection') selectButtonSection: SelectButtonGroupComponent;
  @Input() trackingLocation: TRACKING.LOCATION = TRACKING.LOCATION.MOVE_TARIFF;
  @Input() processContext: string = 'MOVE';

  public ContractDetailsTileMode = CONTRACT_DETAILS_TILE_MODE;
  public tariffSelections: TariffSelection[];
  public tariffConfiguration: TariffConfiguration;
  public selectButtonConfigurations: SelectButtonConfiguration[];
  public valid: boolean = false;
  public productIds: Array<string> = [];
  public availableTariffs: Array<TariffSummaryResults>;
  public state = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public isBaseSupplyArea: boolean = true;
  public address: AddressData;
  public currentTariff: ContractDetails;
  public accountId: string = '';
  public customerMode: CUSTOMERMODE = CUSTOMERMODE.PRIV;
  public consumptionEditMode: boolean = true;

  public consumptionForm = new FormGroup<ConsumptionFormtype>({
    selectedStep: new FormControl(''),
    consumption: new FormControl('', {
      validators: [
        defaultValidatorProxy(Validators.required, this.translateService.instant('tariffAdvisor.error.consumptionRequired')),
        defaultValidatorProxy(Validators.pattern(/^\d*$/), this.translateService.instant('general.error.wrongNumberFormat'))
      ]
    })
  });
  public isOneDivision: boolean = false;
  public isFromSummary: boolean;

  private subscriptions: Subscription = new Subscription();

  constructor(
    public tariffAdvisorService: TariffAdvisorService,
    public activatedRoute: ActivatedRoute,
    public location: Location,
    public priceCapService: PriceCapService,
    private router: Router,
    private trackingService: TrackingService,
    private apiService: BdoApiService,
    private loginService: LoginService,
    private customerStore: CustomerStoreService,
    private translateService: TranslateService
  ) {
    const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
    this.isFromSummary = currentState?.context === CONTEXT_FROM_SUMMARY;

    const storedMeterData = StorageService.getValue<SituationData>(ALLOWED_KEYS.SITUATION_DATA)?.meterData;
    let city: string, housenumber: string, street: string, postCode: string;
    const personalAddressData = StorageService.getValue<PersonalData>(ALLOWED_KEYS.PERSONAL_DATA)?.addressData; // move
    const address = StorageService.getValue<AddressData>(ALLOWED_KEYS.ADDRESS_DATA); // new contract
    if (personalAddressData) {
      city = personalAddressData.city;
      housenumber = personalAddressData.housenumber;
      street = personalAddressData.street;
      postCode = personalAddressData.postCode;
    } else if (!personalAddressData && address) {
      city = address.cityName;
      housenumber = address.houseNum;
      street = address.streetName;
      postCode = address.postCode;
    }

    this.tariffSelections = storedMeterData
      // create divisionStates for all found divisions
      .map((meterData, index): TariffSelection => {
        const type: VERBRAUCHSTYP = Object.values(VERBRAUCHSTYP)
          .find((item) => {
            return meterData.divisionId === item;
          });
        // use saved division from SessionStorage to load the prior selection of the customer
        const tariffSelections = StorageService.getTariffSelections();
        const tariffSelection = tariffSelections?.find((item) => item.type === type);
        if (tariffSelection) {
          if (!tariffSelection.divisionConfiguration?.id) {
            tariffSelection.divisionConfiguration = defaultDivisionConfigurations[type];
          }
          return tariffSelection;
        }
        return {
          type: type,
          isSelected: index === 0,
          consumption: undefined,
          selectedTariff: undefined,
          divisionConfiguration: defaultDivisionConfigurations[type]
        };
      })
      // remove duplicate divisions (one tariff per division)
      .reduce((result, divisionState: TariffSelection) => {
        if (!result.find((item) => divisionState.type === item.type)) {
          divisionState.numberOfMeterNumbers = 1;
          result.push(divisionState);
        } else {
          const foundItem = result.find((item) => divisionState.type === item.type);
          foundItem.numberOfMeterNumbers = foundItem.numberOfMeterNumbers + 1;
        }
        return result;
      }, [] as TariffSelection[])
      .sort((a, b) => {
        return a.type === VERBRAUCHSTYP.Strom ? -1 : 1;
      });

    this.subscriptions.add(this.activatedRoute.params.subscribe({ next: params => {
      const step = params['step'];
      this.selectDivision(this.tariffSelections.find((item, index) => (index === (step - 1)))?.type);
      this.availableTariffs = [];
      this.updateSelectedDivisionState('selectedTariff', '');
      this.initSelectButtons();
    } }));
    this.isOneDivision = this.tariffSelections.length === 1;

    this.tariffConfiguration = {
      city,
      houseNum: housenumber,
      postCode: postCode,
      division: this.getSelectedDivisionState().type,
      consumption: 0,
      street: street
    };

    this.address = {
      cityName: city,
      houseNum: housenumber,
      postCode: postCode,
      streetName: street
    };
  }

  ngOnInit(): void {
    this.trackingService.postTracking(this.trackingLocation, TRACKING.ACTION.ENTER);
    this.consumptionEditMode = true;

    this.apiService.getIsBaseSupply(this.address, this.tariffConfiguration.division)
      .subscribe({ next: res => {
        this.isBaseSupplyArea = res?.info?.toLowerCase() === 'true';
      } });

      // Fallback for refresh
      const authData = StorageService.getAuthData();
      this.accountId = this.customerStore.getAccountId() || authData?.checkIdentificationData?.accountId;
      if (!this.accountId) {
        this.apiService.getAccounts().subscribe( { next: res => {
          this.accountId = res[0]?.accountId;
          this.customerStore.setAccountId(this.accountId);
        } });
      }
  }

  public onCalculate(): void {
    this.trackConsumption();
    this.calculateTariffs();
  }

  public trackConsumption() {
    this.trackingService.postBaseAndAdditionalTracking({
      event: 'orca-tarifrechner',
      orca_tarifrechner: 'plz_eingabe',
      orca_plz: this.tariffConfiguration?.postCode,
      orca_product_sparte: this.getSelectedDivisionState()?.divisionConfiguration?.title,
      orca_plz_strasse: this.tariffConfiguration?.street,
      orca_jahresverbrauch: this.consumptionForm?.controls?.consumption?.value?.toString()
    });
  }

  public selectDivision(id: string): void {
    this.tariffSelections.forEach((item) => {
      if (item.type === id) {
        item.isSelected = true;
        if (item.consumption) {
          this.consumptionForm.controls.consumption.setValue(item.consumption.toString());
          const step: string = this.tariffAdvisorService.getStepByConsumption(item.divisionConfiguration.id, item.consumption);
          this.consumptionForm.controls.selectedStep.setValue(step);
        }
      } else {
        item.isSelected = false;
      }
    });
  }

  public getSelectedDivisionState(): TariffSelection {
    return this.tariffSelections.find((item) => item.isSelected);
  }

  public getCompletedDivisions(): TariffSelection[] {
    return this.tariffSelections.filter((item) => item.selectedTariff);
  }

  public updateSelectedDivisionState(key: keyof TariffSelection, value: any): void {
    const selectedDivisionConfiguration = this.getSelectedDivisionState() as any;
    selectedDivisionConfiguration[key] = value;
  }

  public onTariffSelected(tariff: TariffSummaryResults): void {
    this.trackingService.postBaseAndAdditionalTracking({
      event: 'orca-tarifrechner',
      orca_tarifrechner: 'tarifanfrage',
      orca_plz: this.tariffConfiguration?.postCode,
      orca_product_sparte: this.getSelectedDivisionState()?.divisionConfiguration?.title,
      orca_plz_strasse: this.tariffConfiguration?.street,
      orca_jahresverbrauch: this.consumptionForm.controls.consumption.value,
      orca_product_id: tariff.productId,
      orca_product_name: tariff.name
    });
    this.updateSelectedDivisionState('selectedTariff', tariff);
    this.updateSelectedDivisionState('monthlyPrice', this.getMonthlyPrice(tariff));
    if (this.getCompletedDivisions().length === this.tariffSelections.length) {
      this.valid = true;
    }
    this.selectButtonSection.completeStep(this.getSelectedDivisionState().divisionConfiguration.id);
    this.activateNextStep();
  }

  mapContractLevels(detailsLevel: Array<ContractLevel>): Array<TariffSummaryContractLevel> {
    const mapped: Array<TariffSummaryContractLevel> = [];
    detailsLevel.forEach(level => {
      mapped.push({
        baseGross: level.baseGross,
        baseNet: level.baseNet,
        description: level.description,
        infoText: level.infoText,
        level: parseInt(level.contractLevelNumber, 10) || null,
        priceDate: level.priceDate.toString(),
        workGross: level.workGross,
        workNet: level.workNet
      });
    });
    return mapped;
  }

  public activateNextStep(): void {
    const result = this.selectButtonSection.activateNext();
    this.save();
    // Jump directly back to move-summarypage
    if (this.isFromSummary) {
      this.trackingService.postBaseAndAdditionalTracking({
        orca_event_type: this.trackingLocation,
        orca_event_action: 'goto zusammenfassung'
      });
      this.router.navigate(['../../uebersicht'], {
        relativeTo: this.activatedRoute
      });
    } else if (!result) {
      // Skip tariff-summary if only one tariff is selected
      if (this.isOneDivision) {
        this.trackingService.postBaseAndAdditionalTracking({
          orca_event_type: this.trackingLocation,
          orca_event_action: 'goto zahlungsart'
        });
        this.router.navigate(['../../zahlungsart'], {
          relativeTo: this.activatedRoute
        });
      } else {
        this.trackingService.postBaseAndAdditionalTracking({
          orca_event_type: this.trackingLocation,
          orca_event_action: 'goto Tarifauswahl-Zusammenfassung'
        });
        this.router.navigate(['../../tarifauswahl/zusammenfassung'], {
          relativeTo: this.activatedRoute,
        });
      }
    } else {
      this.trackingService.postBaseAndAdditionalTracking({
        orca_event_type: this.trackingLocation,
        orca_event_action: 'goto next-tariff'
      });
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['../../tarifauswahl/' + this.getCurrentStep().toString()], {
        relativeTo: this.activatedRoute
      });
    }
  }

  public onEdit(): void {
    this.updateSelectedDivisionState('selectedTariff', undefined);
  }

  public getSum(): number {
    const selectedDivisions = this.getCompletedDivisions();
    return selectedDivisions.filter((item) => item.selectedTariff).reduce((sum, item) => {
      return sum + item.monthlyPrice;
    }, 0);
  }

  public getCurrentStep(): number {
    const currentStep = this.tariffSelections.indexOf(this.tariffSelections.find((item) => item.isSelected)) + 1;
    return currentStep === 0 ? 1 : currentStep;
  }

  public save(): void {
    StorageService.setTariffSelections(this.tariffSelections);
    // Map the tariffs for the summary page
    const summaryTariffs: Array<AddressTariffData> = [];
    this.tariffSelections.forEach(divisionState => {
      summaryTariffs.push({
        mode: divisionState.selectedTariff?.customerType,
        postCode: this.tariffConfiguration?.postCode,
        cityName: this.tariffConfiguration?.city,
        streetName: this.tariffConfiguration?.street,
        houseNum: this.tariffConfiguration?.houseNum,
        annualConsumption: divisionState.consumption?.toString(),
        type: divisionState.type === 'S4' ? 'e' : 'g',
        divisionId: divisionState.type,
        productId: divisionState.selectedTariff?.productId,
        tariff: divisionState.selectedTariff?.name?.replace('&shy;', ''),
        tbTariffName: divisionState.selectedTariff?.name?.replace('&shy;', ''),
        hash: '0a143564fba4e5a734d0f68f230d7ad0e16151e05836fc7f17fe1758f7ca6f7f'
      });
    });
    StorageService.setTariffData(summaryTariffs);
  }

  public onBack() {
    this.location.back();
  }

  private calculateTariffs(): void {
    this.trackingService.postSimpleTracking(this.trackingLocation, 'calculate Tariff');
    this.consumptionForm.updateValueAndValidity();
    this.consumptionEditMode = false;
    if (this.consumptionForm.valid) {

      this.tariffConfiguration = {
        ...this.tariffConfiguration,
        consumption: parseFloat(this.consumptionForm.controls.consumption.value),
        division: this.getSelectedDivisionState()?.type
      };
      this.getProductIds();
      this.updateSelectedDivisionState('consumption', this.consumptionForm?.controls?.consumption?.value);
    }
  }

  private getMonthlyPrice(tariff: TariffSummaryResults): number {
    if (tariff?.parts?.[0]?.priceCap) {
      if (this.customerMode === CUSTOMERMODE.PRIV) {
        return tariff?.parts?.[0]?.priceCap?.monthlyPriceFirstYearGross;
      } else {
        return tariff?.parts?.[0]?.priceCap?.monthlyPriceDuringPriceCapPeriodNet;
      }
    } else {
      if (this.customerMode === CUSTOMERMODE.PRIV) {
        return tariff?.parts[0]?.periodicPrices.find(elem => elem.year === 1)?.monthlyPriceGross;
      } else {
        return tariff?.parts[0]?.periodicPrices.find(elem => elem.year === 1)?.monthlyPriceNet;
      }
    }
  }

  private getTariffProductIds(customerMode: CUSTOMERMODE = CUSTOMERMODE.PRIV) {
    return this.apiService.getTariff({
      beginDate: moment(moment.now()).format('yyyy-MM-DD').toString(),
      division: this.tariffConfiguration.division,
      annualConsumption: this.tariffConfiguration.consumption,
      postCode: this.tariffConfiguration.postCode,
      productIds: this.tariffAdvisorService.getNecessaryProductIds(this.processContext, this.isBaseSupplyArea, customerMode),
      cityName: this.tariffConfiguration.city,
      streetName: this.tariffConfiguration.street,
      houseNum: this.tariffConfiguration.houseNum,
      customerMode: customerMode
    });
  }

  private initSelectButtons() {
    this.selectButtonConfigurations = this.tariffSelections.map(({ type, selectedTariff, isSelected }) => {
      return {
        ...defaultDivisionConfigurations[type],
        id: type,
        active: isSelected, // activate first element
        completed: !!selectedTariff
      };
    });
  }

  private getProductIds() {
    this.state = LOADING_STATE.LOADING;
    this.availableTariffs = [];
    const identificationData = StorageService.getAuthData()?.checkIdentificationData;

    this.loginService.isLoggedIn$.pipe(
      first(),
      switchMap((loggedIn) => {
        return loggedIn ?
          this.apiService.getLoadProfileMode() :
          this.apiService.getLoadProfileModeAnonymous(identificationData.accountId, identificationData.meterNumberOrRegisterCode);
      }),
      switchMap((loadProfileCustomerMode) => {
        this.customerMode = loadProfileCustomerMode.customerMode as CUSTOMERMODE;
        return this.getTariffProductIds(this.customerMode);
      })
    ).subscribe({
      next: (result: TariffSummary) => {
        this.availableTariffs = result.results;
        this.state = LOADING_STATE.IDLE;
      },
      error: () => {
        this.state = LOADING_STATE.ERROR;
      }
    });

  }
}
