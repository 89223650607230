import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { Address } from '../../../../assets/js/com/ts_api_client';
import { LOADING_STATE } from '../../../bdo/enums/loadingState.enum';
import { BdoApiService } from '../../../bdo/services/bdo-api.service';
import { CustomerStoreService } from '../../../bdo/services/customer-store.service';

@Component({
  selector: 'bdo-accountid-with-address',
  templateUrl: './accountid-with-address.component.html',
  styleUrls: ['./accountid-with-address.component.scss']
})
export class AccountidWithAddressComponent implements OnInit {
  @Input() public accountId: string;
  @Input() public address: Address;

  public LoadingState = LOADING_STATE;
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  constructor(
    private customerStore: CustomerStoreService,
    private apiService: BdoApiService
  ) { }

  public ngOnInit(): void {
    if (!this.accountId && !this.address) {
      this.state = LOADING_STATE.LOADING;
      this.accountId = this.customerStore.getAccountId();
      this.apiService.getAccountInfo()
        .pipe(
          map((account) => account.address),
        ).subscribe({
        next: (address) => this.onSuccess(address),
        error: () => this.onError()
      });
    }
  }

  private onSuccess(address: Address) {
    this.address = address;
    this.state = LOADING_STATE.IDLE;
  }

  private onError() {
    this.state = LOADING_STATE.ERROR;
  }

}
