import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

/**
 * Validates a birthdate in the format 'DD.MM.YYYY'
 * @param {string} customErrorMessage
 * @returns {(control: AbstractControl) => {[p: string]: string}}
 */
export function birthdateValidator(translateService: TranslateService, errorText?: string) {
  return (control: AbstractControl): { [key: string]: string | true } | null => {
      if (!control.value) {
        return null;
      }

      errorText = errorText ? errorText : translateService.instant('address.personalInfo.birthdate.invalid');

      const regex = RegExp('^[0-9]{2}\.[0-9]{2}\.[0-9]{4}');
      const isValidDate = moment(control.value, 'DD.MM.YYYY', true).isValid();

      if (!regex.test(control?.value) || !isValidDate) {
        return { ['birthdate']: (errorText ? errorText : true) };
      }
      return null;
    };
}
