<div [ngClass]="[currentHintTileConfig?.isDashboardTile ? 'dashboard-tile' : 're-bg-white re-px-20 re-py-30 md:re-p-45',
                 'border-' + currentHintTileConfig?.borderTopColor]">
  <div class="alert__container">
    <div class="re-flex re-flex-col md:re-flex-row">
      <div class="re-w-40 re-h-40 re-mr-20 re-mb-10 re-self-center re-flex-shrink-0 re-relative
                  md:re-mb-0 md:re-self-start md:re-inline-block">
        <svg-icon [src]="currentHintTileConfig?.iconPath"></svg-icon>
        <div *ngIf="currentHintTileConfig?.iconWithBadge" class="badge__container">
          <svg-icon src="assets/icons/badge.svg"></svg-icon>
        </div>
      </div>
      <div class="re-flex-col">
        <div *ngIf="currentHintTileConfig?.title" class="bold">{{currentHintTileConfig?.title}}</div>
        <div [innerHTML]="currentHintTileConfig?.text"></div>
        <div *ngIf="currentHintTileConfig?.linkType !== ''" class="re-mt-10">
          <bdo-text-icon
            *ngIf="currentHintTileConfig?.linkType === 'texticon'"
            [iconPosition]="'right'"
            [text]="currentHintTileConfig?.linkText"
            (itemClicked)="toTarget()"
          ></bdo-text-icon>

          <button
            *ngIf="currentHintTileConfig?.linkType === 'primaryButton' || currentHintTileConfig?.linkType === 'secondaryButton'"
            class="re-button-small re-block"
            [ngClass]="currentHintTileConfig?.linkType === 'primaryButton' ? 're-primary' : 're-secondary'"
            (click)="toTarget()"
            >
            {{currentHintTileConfig?.linkText}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
