import { Component, OnInit } from '@angular/core';
import { BdoApiService } from '../../services/bdo-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerStoreService } from '../../services/customer-store.service';
import { LOADING_STATE } from '../../enums/loadingState.enum';
import { LoginService } from '../../../login/login.service';
import { of, switchMap } from 'rxjs';

@Component({
  selector: 'bdo-customer-data-init',
  templateUrl: './customer-data-init.component.html',
  styleUrls: ['./customer-data-init.component.scss']
})
/** Initializes the application with the first accountId found and reroutes to child route containing that number */
export class CustomerDataInitComponent implements OnInit {
  public LoadingState = LOADING_STATE;
  public state: LOADING_STATE = LOADING_STATE.LOADING;

  constructor(
    private apiService: BdoApiService,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private customerStore: CustomerStoreService
  ) { }

  ngOnInit(): void {
    this.router.navigate([this.customerStore.getAccountId()], {
      relativeTo: this.route,
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }
}
