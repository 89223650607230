/* eslint-disable @typescript-eslint/naming-convention */
export enum VERBRAUCHSTYP {
    Nahwärme = 'N2',
    Fernwärme = 'F4',
    Strom = 'S4',
    Gas = 'G6',
    Wasser = 'T4',
    Abwasser = 'V2',
    Einspeiser = 'S1',
    Übergreifend = 'ZZ'
}
