<div class="bdo-container bdo-space--bottom">
  <h3>{{ 'username.forgot.title' | translate }}</h3>
</div>

<div class="bdo-container--single">

  <div class="bdo-space--bottom">
    {{ 'username.forgot.description' | translate }}
  </div>

  <div [ngSwitch]="registerError" *ngIf="registerError !== RegisterErrorEnum.NONE" class="bdo-container--single">
    <bdo-info-box *ngSwitchCase="RegisterErrorEnum.WRONG_COMBINATION">
      {{ 'username.forgot.error.invalidInput' | translate }}
    </bdo-info-box>
    <bdo-info-box *ngSwitchCase="'Not_yet_registered'">
      {{ 'username.forgot.error.notRegistered' | translate }}
    </bdo-info-box>
    <bdo-info-box *ngSwitchDefault>
      {{ 'username.forgot.error.unknownUsername' | translate }}
    </bdo-info-box>
  </div>

  <form #passwordForm="ngForm" class="bdo-form-container" (ngSubmit)="send(passwordForm)">
    <bdo-loading *ngIf="sendingData"></bdo-loading>
    <fieldset>
      <div class="bdo-form-item-container">
        <bdo-single-line-input
          #accountIdInput
          [inputName]="'accountId'"
          [placeholder]="'general.customerData.account' | translate"
          [labelText]="'general.customerData.account' | translate"
          [inputValue]="accountId"
          (inputValueChanged)="accountId=$event; onFocus()"
          (inputFocused)="onFocus()"
          [required]="true"
          [forceValidate]="validateAll"
          [validationIconHidden]="validationIconHidden"
          [errors]="[{text: ('register.account.accountIdRequired' | translate), name:'required'},
                      { condition: !containsOnlyDigits(passwordForm.value?.accountId), text: ('register.account.accountIdOnlyDigits' | translate), name: 'digits'},
                      { condition: !isValidAccountId(passwordForm.value?.accountId), text: ('register.account.accountIdInvalid' | translate), name: 'accountId'}]"
        ></bdo-single-line-input>
      </div>

      <div class="bdo-form-item-container">
        <bdo-single-line-input
          #meterNumberInput
          [inputName]="'meterNumber'"
          [placeholder]="'general.customerData.meternumber' | translate"
          [labelText]="'general.customerData.meternumber' | translate"
          [inputValue]="meterNumber"
          (inputValueChanged)="meterNumber=$event; onFocus()"
          (inputFocused)="onFocus()"
          [required]="true"
          [forceValidate]="validateAll"
          [validationIconHidden]="validationIconHidden"
          [errors]="[{text: ('register.meter.meterIdRequired' | translate), name:'required'}]"
        ></bdo-single-line-input>
        <span class="bdo-form__helptext">
          {{ 'register.meter.meterIdUncomplete' | translate }}
        </span>
      </div>

      <div class="bdo-space--bottom">
        <button class="bdo-button-primary bdo-button--text re-mb-30" type="submit">
          {{ 'general.action.continue' | translate }}
        </button>
        <a class="bdo-nolink" [routerLink]="['/login']" data-qa="cancel">
          <div class="bold"> {{ 'general.action.cancel' | translate }}</div>
        </a>
      </div>

      <div>
        <bdo-text-link-icon [ngClass]="'bdo-form__helptext'"
          [iconPosition]="'right'"
          [linkText]="'password.forgot.title' | translate"
          [routerUrl]="'/passwort-vergessen'"
          [tracking]="[TRACKING.LOCATION.USERNAME_FORGOT, TRACKING.ACTION.GOTO, TRACKING.LOCATION.PASSWORD_FORGOT]"
        ></bdo-text-link-icon>
      </div>

    </fieldset>
  </form>

</div>


<bdo-simple-text-popup [isVisible]="recognizedUsername.length > 0" (closePopupEvent)="closeMessage()">
  <bdo-username-success [username]="recognizedUsername" (closeEvent)="closeMessage()"></bdo-username-success>
</bdo-simple-text-popup>
