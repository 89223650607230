import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { DropdownItem } from '../../../../../src/app/bdo/models/dropdownItem';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'bdo-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
/** A Dropdown containing Strings and preselecting the given selected one unless required */
export class DropdownComponent implements OnInit, OnChanges {
  @Input() data: Array<DropdownItem>;
  @Input() forceValidate: boolean = false;
  @Input() requiredErrorText: string;
  @Input() selected: string = null; // Select the first item as selected and emit value unless noPreselection is true
  @Input() required: boolean = false; // = noPreSelection & placeholder = label
  @Input() labelText: string = '';
  @Input() ariaLabel: string = ''; // Set ariaLabel for a11y if labelText is not set
  @Input() dataQa: string = '';

  @Output() itemSelected: EventEmitter<any> = new EventEmitter();

  public form = new FormGroup({
    selected: new FormControl(this.selected)
  });

  constructor() { }

  ngOnInit(): void {
    if (!this.selected && this.data && !this.required) {
      this.form.controls.selected.setValue(this.data[0]?.value);
    }
    if (this.required && !this.selected) {
      this.form.controls.selected.setValue('');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue && !this.form.controls.selected.value && !this.required) {
      const newValue: string = this.selected ? this.selected : changes.data?.currentValue[0]?.value;
      this.form.controls.selected.setValue(newValue);
      this.itemSelected.emit(this.form.controls.selected.value);
    }
    if (changes.selected?.currentValue) {
      this.form.controls.selected.setValue(this.selected);
    }
  }

  selectItem(event: Event) {
    this.itemSelected.emit((event.target as HTMLInputElement).value);
  }
}
