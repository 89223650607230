<bdo-dashboard-tile
  [tileTitle]="'dashboard.paymentTile.title' | translate"
  [linkData]="[{linkName: !isInactive || payments?.offeneSumme ? ('dashboard.paymentTile.link' | translate) : '',
              href: '/zahlungen/' + customerStore.getAccountId(),
              tracking: [TRACKING.LOCATION.DASHBOARD, TRACKING.ACTION.GOTO, TRACKING.LOCATION.PAYMENTS]
  }]"
>
  <div class="re-mb-default re-small" *ngIf="payments?.next; else paymentFallback">
    <div class="bold">{{ 'dashboard.paymentTile.nextPayment' | translate }}</div>
    <div class=" re-mb-default">{{ 'general.tariff.dueOn' | translate }} {{payments.nextDatum | date:'longDate' }}</div>
    <div class="bold bigger re-mb-default">{{payments.next | currency:'EUR'}}</div>
    <div *ngIf="bankAccountAssignments">
      <div *ngIf="bankAccountAssignments.debit; else noDebit">
        <div>{{ 'dashboard.banking.claimsWithSEPA' | translate }}</div>
        {{bankAccountAssignments.debit.bankname}}:&nbsp;{{bankAccountAssignments.debit.iban}}
      </div>
      <ng-template #noDebit>{{ 'dashboard.banking.noBankAccountForNextPayment' | translate }}</ng-template>
    </div>
  </div>
  <ng-template #paymentFallback>
    <div class="re-mb-default re-small">
      {{ 'dashboard.paymentTile.noOpenPayments' | translate }}</div>
  </ng-template>
</bdo-dashboard-tile>
