<ng-container *ngFor="let voucher of vouchers?.list">

  <bdo-selection-tile
    [data]="{
      id: voucher.activityCode,
      isSelected: false,
      logo: voucher.logo,
      title: voucher.title,
      subtitle: voucher.subtitle,
      value: voucher.value | currency: 'EUR',
      valuetitle: voucher.valuetitle,
      info: voucher.info
    }"
    [isSelectable]="false"
  ></bdo-selection-tile>

</ng-container>
