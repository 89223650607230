<a class="re-flex re-flex-col re-bg-white re-px-20 re-py-30 lg:re-py-45 re-text-center md:re-max-w-290 lg:re-max-w-440 md:re-h-full re-group"
  [routerLink]="destination"
  (click)="track(trackingDestination)">
  <div class="re-w-75 re-h-75 md:re-h-90 md:re-w-90 lg:re-h-120 lg:re-w-120 re-mb-20 re-mx-auto group-hover:re-text-primary group-focus:re-text-primary"
       [attr.aria-label]="''">
    <svg-icon class="md:re-hidden" [src]="iconSrc?.sm || iconSrc?.default"></svg-icon>
    <svg-icon class="re-hidden md:re-inline lg:re-hidden" [src]="iconSrc?.md || iconSrc?.default"></svg-icon>
    <svg-icon class="re-hidden lg:re-inline" [src]="iconSrc?.lg || iconSrc?.default"></svg-icon>
  </div>
  <div class="re-flex re-flex-1 re-flex-col re-justify-between">
    <h2 class="re-mb-40 md:re-mb-45 md:re-px-10" [ngClass]="{'re-mb-20': description}"
        innerHTML="{{title}}"
    ></h2>
    <div *ngIf="description?.length > 0" class="re-mb-20 md:re-mb-45">{{description}}</div>
    <div class="re-mx-auto">
      <bdo-text-icon
        [iconPosition]="'right'"
        [text]="linktext"
        [isPart]="true"
      ></bdo-text-icon>
    </div>

  </div>
</a>
