import { Component, OnInit } from '@angular/core';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { HintTileConfig } from '../../../../shared/atoms/hint-tile/hint-tile.component';
import { TenantService } from '../../../services/tenant.service';
import { CustomerStoreService } from '../../../services/customer-store.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-contract-end-status',
  templateUrl: './contract-end-status.component.html',
  styleUrls: ['./contract-end-status.component.scss']
})
export class ContractEndStatusComponent implements OnInit {
  public TRACKING = TRACKING;
  public hintTileConfig: HintTileConfig;
  public accountId: string;

  constructor(
    public tenantService: TenantService,
    private translateService: TranslateService,
    private customerStore: CustomerStoreService
  ) {}

  ngOnInit(): void {
    this.accountId = this.customerStore.getAccountId();

    this.hintTileConfig = {
      borderTopColor: '',
      iconPath: 'assets/icons/energy-out_30x30.svg',
      iconWithBadge: false,
      text: this.translateService.instant('contract.end.status.toEnd.description'),
      linkType: 'texticon',
      linkText: this.translateService.instant('contract.end.status.toEnd.link'),
      linkPath: '/abmelden/' + this.accountId + '/neu',
      isDashboardTile: false,
      title: this.translateService.instant('contract.end.status.toEnd.title'),
      trackingLocation: this.TRACKING.LOCATION.CONTRACT_END_OVERVIEW,
      trackingDestination: this.TRACKING.LOCATION.CONTRACT_END_RECENT
    };
  }
}
