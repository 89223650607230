import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, timer } from 'rxjs';
import { switchMap, first, map } from 'rxjs/operators';

export function autosuggestMatches(autosuggestItems$: Observable<string[]>, errorText: string): AsyncValidatorFn {

  return (control: AbstractControl): Observable<(ValidationErrors | null)> => {

    // timer is needed, because "no results" is set async directly after typing, but later than this validator
    return timer(100).pipe(
      switchMap(() => autosuggestItems$),
      first(),
      map((item) => {
        return item.includes(control.value) ? (null) : ({
          no_results: errorText
        });
      }));

  };

}
