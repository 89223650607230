import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Forderung } from '../../../../../../assets/js/com/ts_api_client';
import { BankAccountAssignments } from '../models/bank-account-assignments.interface';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

enum MODE {
  'DEBIT' = 'debit',
  'CREDIT' = 'credit',
  'ZERO' = 'zero'
}

@Component({
  selector: 'bdo-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss']
})
export class BalanceComponent implements OnDestroy {

  @Input() kundennummer: string;
  @Input() bankAccountAssignments: BankAccountAssignments;
  @Input()
  set payments(payments: Forderung) {
    if (payments) {
      this.mode = payments.offeneSumme < 0 ? MODE.CREDIT :
        payments.offeneSumme > 0 ? MODE.DEBIT :
        MODE.ZERO;
    }
    this._payments = payments;
  }
  get payments() {
    return this._payments;
  }

  public Mode = MODE;
  public mode: MODE;

  public config: { [key: string]: {
      withBankAccounts: string;
      withoutSepa: string;
      sepaText: string }
  } = {
    debit: {
      sepaText: this.translateService.instant('dashboard.banking.claimsWithSEPA'),
      withoutSepa: this.translateService.instant('dashboard.banking.claimsWithoutSEPA'),
      withBankAccounts: this.translateService.instant('dashboard.banking.withoutSepaWithBankAccounts')
    },
    credit: {
      sepaText: this.translateService.instant('dashboard.banking.creditMemoWithSEPA'),
      withoutSepa: this.translateService.instant('dashboard.banking.creditMemoWithoutSEPA'),
      withBankAccounts: this.translateService.instant('dashboard.banking.creditMemoWithoutSEPA')
    },
  };

  private _payments: Forderung;
  private subscribtionLanguage: Subscription = new Subscription();

  constructor(
    public translateService: TranslateService
  ) {
    this.subscribtionLanguage.add(this.translateService.onLangChange.subscribe({
      next: () => {
        this.config = {
          debit: {
            sepaText: this.translateService.instant('dashboard.banking.claimsWithSEPA'),
            withoutSepa: this.translateService.instant('dashboard.banking.claimsWithoutSEPA'),
            withBankAccounts: this.translateService.instant('dashboard.banking.withoutSepaWithBankAccounts')
          },
          credit: {
            sepaText: this.translateService.instant('dashboard.banking.creditMemoWithSEPA'),
            withoutSepa: this.translateService.instant('dashboard.banking.creditMemoWithoutSEPA'),
            withBankAccounts: this.translateService.instant('dashboard.banking.creditMemoWithoutSEPA')
          },
        };
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscribtionLanguage.unsubscribe();
  }
}
