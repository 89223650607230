<bdo-tariff-selection
  [title]="title"
  [copytext]="this.campaignContext ? ( 'contract.edit.campaign.description' | translate) : null"
  [showRecentTariff]="true"
  [tariffSelectionLink]="'../uebersicht'"
  [linkForTextLinkIcon]="linkForTextLinkIcon"
  [newTariffTitle]="(this.campaignContext || this.offerContext ? 'tariff.new' : 'contract.edit.chooseTariff') | translate"
  [trackingLocation]="TRACKING_LOCATION.CONTRACT_CHANGE_TARIFFSELECTION">
</bdo-tariff-selection>


