/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { TariffSummary } from '../model/tariffSummary';
// @ts-ignore
import { TariffTextItem } from '../model/tariffTextItem';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class TariffService {

    protected basePath = 'http://localhost:8080/BdoSpiders/orca';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
            if (firstBasePath != undefined) {
                basePath = firstBasePath;
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Returns tariff and price information via anypoint tariff api
     * Returns tariff and price information via anypoint tariff api
     * @param xTenant 
     * @param beginDate date of delivery of tariff
     * @param division 
     * @param annualConsumption estimated annual consumption of units for customer
     * @param postCode postcode where tariff should be priced for
     * @param annualConsumptionNt estimated annual consumption of units for off-peak rates
     * @param productIds optional productIds for a given tariff
     * @param cityName city, necessary for certain price information when postcode is not enough
     * @param streetName street, necessary for certain price information when postcode is not enough
     * @param houseNum housenumber, necessary for certain price information when postcode is not enough
     * @param process The process the tariffs should be fetched for
     * @param customerMode 
     * @param offerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTariffSummaries(xTenant: string, beginDate: string, division: string, annualConsumption: number, postCode: string, annualConsumptionNt?: number, productIds?: Array<string>, cityName?: string, streetName?: string, houseNum?: string, process?: string, customerMode?: string, offerId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<TariffSummary>;
    public getTariffSummaries(xTenant: string, beginDate: string, division: string, annualConsumption: number, postCode: string, annualConsumptionNt?: number, productIds?: Array<string>, cityName?: string, streetName?: string, houseNum?: string, process?: string, customerMode?: string, offerId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<TariffSummary>>;
    public getTariffSummaries(xTenant: string, beginDate: string, division: string, annualConsumption: number, postCode: string, annualConsumptionNt?: number, productIds?: Array<string>, cityName?: string, streetName?: string, houseNum?: string, process?: string, customerMode?: string, offerId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<TariffSummary>>;
    public getTariffSummaries(xTenant: string, beginDate: string, division: string, annualConsumption: number, postCode: string, annualConsumptionNt?: number, productIds?: Array<string>, cityName?: string, streetName?: string, houseNum?: string, process?: string, customerMode?: string, offerId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getTariffSummaries.');
        }
        if (beginDate === null || beginDate === undefined) {
            throw new Error('Required parameter beginDate was null or undefined when calling getTariffSummaries.');
        }
        if (division === null || division === undefined) {
            throw new Error('Required parameter division was null or undefined when calling getTariffSummaries.');
        }
        if (annualConsumption === null || annualConsumption === undefined) {
            throw new Error('Required parameter annualConsumption was null or undefined when calling getTariffSummaries.');
        }
        if (postCode === null || postCode === undefined) {
            throw new Error('Required parameter postCode was null or undefined when calling getTariffSummaries.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (beginDate !== undefined && beginDate !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>beginDate, 'beginDate');
        }
        if (division !== undefined && division !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>division, 'division');
        }
        if (annualConsumption !== undefined && annualConsumption !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>annualConsumption, 'annualConsumption');
        }
        if (annualConsumptionNt !== undefined && annualConsumptionNt !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>annualConsumptionNt, 'annualConsumptionNt');
        }
        if (postCode !== undefined && postCode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>postCode, 'postCode');
        }
        if (productIds) {
            productIds.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'productIds');
            })
        }
        if (cityName !== undefined && cityName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>cityName, 'cityName');
        }
        if (streetName !== undefined && streetName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>streetName, 'streetName');
        }
        if (houseNum !== undefined && houseNum !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>houseNum, 'houseNum');
        }
        if (process !== undefined && process !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>process, 'process');
        }
        if (customerMode !== undefined && customerMode !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerMode, 'customerMode');
        }
        if (offerId !== undefined && offerId !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>offerId, 'offerId');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/tariff`;
        return this.httpClient.request<TariffSummary>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns cms texts via anypoint tariff api
     * Returns cms texts via anypoint tariff api
     * @param xTenant 
     * @param key cms key for tariff text
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getText(xTenant: string, key: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<TariffTextItem>;
    public getText(xTenant: string, key: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<TariffTextItem>>;
    public getText(xTenant: string, key: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<TariffTextItem>>;
    public getText(xTenant: string, key: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json; charset=UTF-8', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (xTenant === null || xTenant === undefined) {
            throw new Error('Required parameter xTenant was null or undefined when calling getText.');
        }
        if (key === null || key === undefined) {
            throw new Error('Required parameter key was null or undefined when calling getText.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenant !== undefined && xTenant !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant', String(xTenant));
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json; charset=UTF-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/tariff/text/${this.configuration.encodeParam({name: "key", value: key, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<TariffTextItem>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
