import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BdoApiService } from '../../services/bdo-api.service';
import { TariffSummaryResults, TariffTextItem } from '../../../../assets/js/com/ts_api_client';
import { CUSTOMERMODE } from '../../enums/customerMode';
import { TariffPriceAnnual } from '../../../shared/models/tariff-price.annual';
import { TariffAdvisorService } from '../../services/tariff-advisor.service';
import { TARIFF_TEXT_KEYS } from '../../enums/tariffTextKeys';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'bdo-tariff-tile',
  templateUrl: './tariff-tile.component.html',
  styleUrls: ['./tariff-tile.component.scss'],
})
export class TariffTileComponent implements OnChanges {
  @Input() tariffData: TariffSummaryResults;
  @Input() isSmall: boolean = false;
  @Output() selectTariff = new EventEmitter<TariffSummaryResults>();

  public iconSrc: string;
  public showTariffDetails: boolean = false;
  public isGewe: boolean;
  public hasBonus: boolean;
  public prices: TariffPriceAnnual[];
  public infotext: string = '';

  constructor(
    public apiService: BdoApiService,
    public tariffAdvisorService: TariffAdvisorService,
    private translateService: TranslateService
  ) { }

  updatePrices() {
    this.iconSrc = this.tariffData?.iconUrl ? this.translateService.instant('general.url.iconPathCms') + this.tariffData?.iconUrl :
                   this.tariffAdvisorService.getPathForIcon(this.tariffData?.division);
    this.isGewe = this.tariffData?.customerType === CUSTOMERMODE.GEWE;
    this.hasBonus = this.tariffData?.parts[0]?.contractPayments.some(elem => elem.description.length > 0);
    this.prices = this.tariffAdvisorService.getPeriodicPricesByTimeSlices(this.tariffData);

    this.apiService.getTariffText(this.hasBonus ? TARIFF_TEXT_KEYS.InfoI_withBonus : TARIFF_TEXT_KEYS.InfoI_withoutBonus).pipe()
      .subscribe({
        next: (tariffTextItem: TariffTextItem) => {
          this.infotext = tariffTextItem?.infotext;
        },
        error: () => {
          this.infotext = undefined;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updatePrices();
  }

  /**
   * close tariff-details modal
   */
  closeTariffDetails() {
    this.showTariffDetails = false;
  }

  onSelect() {
    this.selectTariff.emit(this.tariffData);
  }


  /**
   * toggle optionstate and emit changed optionId, enable-state and frameContractId
   * @param optionId

   toggleOption(optionId: string) {
    const optionOfTariff = this.tariff?.options.find((item) => item.data.optionId === optionId);
    if (optionOfTariff) {
      optionOfTariff.enabled = !optionOfTariff.enabled;
    }
    this.changeOption.emit({ optionId: optionOfTariff.data.optionId, enabled: optionOfTariff.enabled, frameContractId: this.tariff.frameContractId });
  }
  */
}
