<h3 class="bdo-container re-mb-45 md:re-mb-75">{{ 'move.title' | translate }}</h3>

<div class="re-mb-60 md:re-mb-105">
  <bdo-anonymous-or-login [destination]="'/umzug/' + placeholderAccountId + '/neu/bisheriges-zuhause'"></bdo-anonymous-or-login>
</div>

<h2 class="re-container-with-margin re-mb-20 md:re-mb-30">
  {{ 'move.start.title' | translate }}
</h2>
<div class="re-container-with-margin re-mb-20 md:re-mb-30 re-text-sm re-leading-m md:re-text-base md:re-leading-2xl">
  {{ 'move.start.description' | translate }}
</div>
<div class="re-container-with-margin re-mb-20 md:re-mb-30">
  <ul class="bdo-list__bullet_bigger re-text-sm re-leading-m re-mb-45 lg:re-text-base lg:re-leading-2xl">
    <li class="re-mb-10">
      {{ 'move.start.needs.dates' | translate }}
    </li>
    <li class="re-mb-10">
      {{ 'move.start.needs.meternumbers' | translate }}
    </li>
    <li>
      {{ 'move.start.needs.address' | translate }}
    </li>
  </ul>
</div>
<div class="re-container-with-margin re-text-sm re-leading-m md:re-text-base md:re-leading-2xl">
  <div class="re-mb-20 md:re-mb-30">
    {{ 'move.start.descriptionEnd' | translate }}
  </div>
  <div [innerHtml]="'move.start.somethingIsMissing' | translate:
       { contactLink: tenantService.getCurrentTenantData().hrefContact }"></div>
</div>
