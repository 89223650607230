 <div class="re-relative">
  <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
  <h4 class="re-text-center re-mb-10">{{ 'feedback.dialog.greeting' | translate }}{{customerName}}</h4>
  <p>
    {{ 'feedback.dialog.title' | translate }}
  </p>
  <p class="bdo-space--bottom">
    {{ 'feedback.dialog.usage.question' | translate }}
  </p>

  <form #form="ngForm" (ngSubmit)="submitPressed()" (click)="state=LoadingState.IDLE">
    <fieldset>

      <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">
        {{ 'general.error.error' | translate }}
      </bdo-info-box>

      <p>
        <span class="error" [style.visibility]="(noStarError && showNoStarError)? 'visible' : 'hidden' ">
          {{ 'feedback.required' | translate }}
        </span>
      </p>

      <div class="center">
        <div class="row">
          <div class="column">
            <img class="star" (click)="clickOntoStar1()" [src]="starSrc1">
            <span>{{ 'feedback.dialog.usage.hard' | translate }}</span>
          </div>
          <div class="column">
            <img class="star" (click)="clickOntoStar2()" [src]="starSrc2">
            <span> </span>
          </div>
          <div class="column">
            <img class="star" (click)="clickOntoStar3()" [src]="starSrc3">
            <span> </span>
          </div>
          <div class="column">
            <img class="star" (click)="clickOntoStar4()" [src]="starSrc4">
            <span>{{ 'feedback.dialog.usage.easy' | translate }}</span>
          </div>
        </div>
      </div>

      <div class="bdo-space--bottom">
        <bdo-multi-line-input
          [placeholder]="'feedback.dialog.requestNotes' | translate"
          (keyup)=onKey()
          [rows]="3"
          (inputValueChanged)="text = $event"
          [maxLength]="255"
          [inputName]="'notes'"
        >
        </bdo-multi-line-input>
      </div>

      <div class="bdo-form-item-container">
        <bdo-single-line-input
          #mailInput
          [inputName]="'FeedbackEmail'"
          [inputValue]="customerEmail"
          [inputType]="INPUT_TYPE.EMAIL"
          [placeholder]="'address.email.title' | translate"
          [labelText]="'address.email.title' | translate"
          [required]="false"
          (inputValueChanged)="customerEmail=$event;"
          [errors]="[{text: ('address.email.invalid' | translate), name: 'email'}]"
        ></bdo-single-line-input>
      </div>

      <div class="bdo-space--bottom">
        <bdo-checkbox
          [checked]="isError"
          (change)="isError=!isError" >
          {{ 'feedback.dialog.errorOccurred' | translate }}
        </bdo-checkbox>
      </div>

      <div class="center">
        <button class="bdo-button-primary" type="submit">{{ 'general.action.submit' | translate }}</button>
      </div>

    </fieldset>
  </form>
</div>
