import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { of, Subscription, timer } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  Address,
  MetersCheckResult,
  ZaehlerIdentifier,
  Zaehlwerk
} from '../../../../../assets/js/com/ts_api_client';
import { FadeAnimation } from '../../../../shared/animations/fade.animation';
import { ZaehlerstandValidatorDirective } from '../../../../shared/validators/zaehlerstand-validator.directive';
import { CHECK_METER_RESPONSE } from '../../../enums/checkMeterResponse.enum';
import { DELIVERY_TYPE } from '../../../enums/deliveryType';
import { INPUT_TYPE } from '../../../enums/inputType.enum';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { VerbrauchstypPipe } from '../../../pipes/verbrauchstyp.pipe';
import { BdoApiService } from '../../../services/bdo-api.service';
import { TenantService } from '../../../services/tenant.service';
import { FormFieldIdentifierTracking, FormTracking, TrackingService } from '../../../services/tracking.service';
import { UnitPrettyPipe } from '../../../pipes/unit-pretty.pipe';
import { ZaehlwerktypPipe } from '../../../pipes/zaehlwerktyp.pipe';
import { MeterreadingInfoService } from '../../../services/meterreading-info.service';
import { defaultValidatorProxy } from '../../../../shared/validators/default-validator-proxy';
import { VERBRAUCHSTYP } from '../../../enums/verbrauchstyp.enum';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { activeMeterValidator } from '../../../../shared/validators/active-meter-validator';

interface FormFieldForFrontend {
  meterFormControl: UntypedFormControl;
  meterCheckResult: MetersCheckResult;
  contractExisting?: UntypedFormControl;
  automaticCancelEnabled?: UntypedFormControl;
  previousSupplier?: UntypedFormControl;
  previousSupplierId?: UntypedFormControl;
  message?: CHECK_METER_RESPONSE;
  readings: {
    readingFormControl: UntypedFormControl;
    zaehlwerkData: Zaehlwerk }[];
}

@Component({
  selector: 'bdo-meter-number',
  templateUrl: './meter-number.component.html',
  styleUrls: ['./meter-number.component.scss'],
  animations: FadeAnimation
})
export class MeterNumberComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() parentForm: UntypedFormGroup;
  @Input() withRegisters: boolean = true;
  @Input() deliveryType: DELIVERY_TYPE;
  @Input() readingsOptional: boolean;
  @Input() sparte = '';
  @Input() address: Address;
  @Input() storedMeterData: Array<MetersCheckResult>;
  @Input() public showSmartMeterHint: boolean = false;
  @Input() formTracking: FormFieldIdentifierTracking;
  @Input() trackingEventType: string = TRACKING.LOCATION.PRODUCT_SELECTION;
  @Input() cancelOptionsShown: boolean = false;
  @Input() meterIdentifiersInUse: ZaehlerIdentifier[];

  @Output() meterCheckResult: EventEmitter< { [key: string]: MetersCheckResult }> = new EventEmitter();

  public meters: UntypedFormGroup;
  public meterData: { [key: string]: MetersCheckResult } = {};
  public CheckMeterResponse = CHECK_METER_RESPONSE;
  public formFieldsForFrontend: FormFieldForFrontend[] = [];
  public meterInfoContent = '';

  public contractExisting = new UntypedFormGroup({});
  public meterIndex: number = 0;
  public Verbrauchstyp = VERBRAUCHSTYP;

  private zaehlerValidator: ZaehlerstandValidatorDirective = new ZaehlerstandValidatorDirective(this.translateService);
  private subscriptions: Subscription = new Subscription();


  constructor(
    public tenantService: TenantService,
    public apiService: BdoApiService,
    public meterreadingInfoService: MeterreadingInfoService,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private trackingService: TrackingService,
    private unitPrettyPipe: UnitPrettyPipe,
    private zaehlwerktypPipe: ZaehlwerktypPipe,
    private verbrauchstypPipe: VerbrauchstypPipe
  ) {  }

  ngOnInit(): void {
    if (this.storedMeterData?.length) {
      this.storedMeterData.forEach((meter) => {
        this.addMeterFormGroup(meter);
      });
      this.parentForm?.addControl('meterData', this.meters);
    } else {
      this.addMeterFormGroup();
    }

    if (this.meters) {
      this.meters.updateValueAndValidity();
    }
    this.cd.detectChanges();

    const division = this.verbrauchstypPipe.transform(this.sparte, false);
    const divisionTranslated = this.verbrauchstypPipe.transform(this.sparte);

    this.meterInfoContent = `
      <div>${this.translateService.instant('meterreadings.info.meternumberInfo1', { division: divisionTranslated })  }</div>
      <div class="re-flex re-flex-row re-mt-15 mobile-only">
        <img src="assets/img/meter-tooltip-${division.toLowerCase()}-s.png" alt="${this.translateService.instant('meterreadings.info.infoPictureAlt')}">
      </div>
      <div class="re-flex re-flex-row re-mt-15 tablet-up-only">
        <img src="assets/img/meter-tooltip-${division.toLowerCase()}-xl.png" alt="${this.translateService.instant('meterreadings.info.infoPictureAlt')}">
      </div>
      <div class="re-mt-15">${this.translateService.instant('meterreadings.info.meternumberInfo2')}</div>
      `;
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  public trackInvalid(formControl: UntypedFormControl, inputName: string) {
    if (formControl.invalid) {
      this.trackFormError(inputName, INPUT_TYPE.TEXT);
    }
  }

  public trackFormError(inputName: string, inputType: INPUT_TYPE) {
    let formTracking: FormTracking = {
      orca_form_name: TRACKING.FORM.DELIVERY_SITUATIONDATA_NAME,
      orca_form_action: TRACKING.FORM_ACTION.ERROR,
      orca_field_section: TRACKING.FORM.DELIVERY_METERDATA_SECTION,
      orca_field_name: inputName,
      orca_field_type: inputType
    };
    formTracking = { ...formTracking, ... this.formTracking };
    this.trackingService.postFormInteraction(formTracking);
  }

  public checkMeterNumber(controlValue, forceCheck: boolean = false) {
    if (!controlValue) {
      return of(null);
    }

    const meterNumber = controlValue;
    const postCode = this.address.street.city?.postCode;
    const street = this.address.street.name;
    const city = this.address.street.city?.name;
    const division = this.sparte;
    const moveInDate = this.parentForm?.get('startDate').value;

    // if cancelOptionsShown is true we have to set the deliveryType depending on the fields
    if (this.cancelOptionsShown) {
      this.deliveryType = this.formFieldsForFrontend.filter(frontendfield =>
        frontendfield.contractExisting.value === 'contractExists').length > 0 ? DELIVERY_TYPE.CHANGE : DELIVERY_TYPE.MOVE;
    }

    // only delay, if the value has changed
    return timer(Object.keys(this.meterData).includes(meterNumber) ? 0 : 1000).pipe(
      switchMap(() => {
        // if value has not changed, do not revalidate but use the message before
        if (!forceCheck && Object.keys(this.meterData).includes(meterNumber)) {
          return of(this.meterData[meterNumber]);
        } else {
          return this.apiService.checkMeter({
            postCode,
            street,
            city,
            division,
            deliveryType: this.deliveryType,
            moveInDate,
            meterNumber
          });
        }
      }),
      tap({ next: this.updateMeterReadingFormFields() }),
      map((result) => {
        return this.mapErrorMessage(result.message);
      }),
      catchError((error: unknown) => {
        // reset message if it has been set before, i.E. if the user has entered an
        // unknown meterNumber and then enters a meternumber, that triggers an exception
        // in the backend
        const formFields = this.getFormfieldByMeterNumber(meterNumber);
        if (formFields) {
          const { meterFormGroup, formFieldForFrontend } = formFields;
          formFieldForFrontend.message = null;
          formFieldForFrontend.readings = [];
          if (meterFormGroup.controls.readingsGroup) {
            meterFormGroup.removeControl('readingsGroup');
          }
        }
        return of({
          error: (error as HttpErrorResponse)?.status === HttpStatusCode.BadRequest ?
            this.translateService.instant('meterreadings.error.wrongFormat') :
            this.translateService.instant('general.error.error')
        });
      })
    );
  }

  /**
   * add a new meter form group, if meter is provided, it is prefilled with data
   * @param {Meter} meter
   */
  public addMeterFormGroup(meter?: MetersCheckResult) {
    this.meterIndex++;
    const newMeterFormName = `meterGroup${this.meterIndex}`;
    const meterInput = new UntypedFormControl(meter ? meter.meterNumber : '', [
      (control) => {
        if (!this.meters) {
          return null;
        }
        const existingMeterNumber = Object.entries(this.meters.controls).find(
          ([key, meterGroup]) => meterGroup.get('meterNumber').value.trim() === control.value.trim()
            && meterGroup.get('meterNumber') !== control && !!meterGroup.get('meterNumber').value);
        return existingMeterNumber ? {
          error: this.translateService.instant('delivery.error.alreadyEntered')
        } : null;
      },
      () => {
        if (!this.meters) {
          return null;
        }
        const numberOfFilledMeterNumbers = Object.entries(this.meters.controls).filter(([key, meterGroup]) => !!meterGroup.get('meterNumber').value).length;
        return numberOfFilledMeterNumbers === 0 ? {
          error: this.translateService.instant('delivery.error.minimunOneMeter')
        } : null;
      },
      defaultValidatorProxy(Validators.maxLength(18), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 18 })),
      // meternumbers of other divisions are checked with another async validator
      activeMeterValidator(this.meterIdentifiersInUse?.filter(identifier => identifier.sparte === this.sparte), this.translateService)
    ],);
    this.subscriptions.add(
      meterInput.valueChanges.subscribe({ next: (value) => {
          // remove warning vor invalid input
          this.formFieldsForFrontend.forEach((formField) => {
            if (formField.meterFormControl.invalid || !formField.meterFormControl.value.trim()) {
              formField.message = null;
            }
          });
          if (this.meters) {
            Object.entries(this.meters.controls).forEach(([key, group]) => {
              const meterControl = group.get('meterNumber');
              if (meterControl !== meterInput && meterControl.value.trim() !== meterInput.value.trim() && meterInput.value && meterControl.invalid) {
                meterControl.updateValueAndValidity();
              }
            });
          }
        } })
    );
    const contractExistingControl = new UntypedFormControl((meter?.termination === 'none' ? 'contractNotExists' :
      (meter?.termination === 'self' || meter?.termination === 'auto' ) ? 'contractExists' : ''), {
      validators: [
        defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
      ] });
    const cancelEnabled = new UntypedFormControl(meter?.termination !== 'none' || false);
    const previousSupplierControl = new UntypedFormControl(meter?.previousSupplier || '');
    const previousSupplierIdControl = new UntypedFormControl(meter?.previousSupplierId || '');
    if (this.cancelOptionsShown) {
      this.subscriptions.add(contractExistingControl.valueChanges.subscribe({ next: (value) => {
          this.trackingService.postSimpleTracking(this.trackingEventType, TRACKING.FORM_ACTION.SELECT, value);
          cancelEnabled.setValue(meter?.termination === 'auto' || false);
        } }));
    }
    const minDate = new UntypedFormControl(meter?.deliveryDate?.minDate || '');

    const meterFormGroup = new UntypedFormGroup({
      meterNumber: meterInput,
      minDate,
      ...( this.cancelOptionsShown && {
        contractExisting: contractExistingControl,
        automaticCancelEnabled: cancelEnabled,
        previousSupplier: previousSupplierControl,
        previousSupplierId: previousSupplierIdControl
      }) // add attributes only if shown
    });
    this.formFieldsForFrontend.push({
      meterFormControl: meterInput,
      meterCheckResult: meter,
      ...( this.cancelOptionsShown && {
        contractExisting: contractExistingControl,
        automaticCancelEnabled: cancelEnabled,
        previousSupplier: previousSupplierControl,
        previousSupplierId: previousSupplierIdControl
      }), // add attributes only if shown
      readings: [] as any
    });
    if (meter && this.withRegisters) {
      meter.register?.forEach((register, index) => {
        this.addRegisterControl(register, meterFormGroup, index) ;
      });
    }
    if (!this.meters) {
      this.meters = new UntypedFormGroup({
        [newMeterFormName]: meterFormGroup
      });
      this.parentForm?.addControl('meterData', this.meters);
    } else {
      this.meters.addControl(newMeterFormName, meterFormGroup);
    }
    this.activateAllAsyncValidatorsOnAllMeters();
  }

  public onClickHelp($event: Event) {
    if (($event.target as HTMLElement).tagName === 'A') {
      this.trackingService.postSimpleTracking(
        TRACKING.LOCATION.DELIVERY_SITUATION,
        TRACKING.ACTION.GOTO,
        TRACKING.LOCATION.CONTACT,
        'customer_has_doppeltarifzaehler'
      );
    }
  }

  public getPlaceholder(reading: { readingFormControl: UntypedFormControl; zaehlwerkData: Zaehlwerk }) {
    const formatReadingOptional = this.translateService.instant('general.tariff.optional');
    const formatWithoutReadingType = this.translateService.instant('meterreadings.meterreading');
    const formatWithReadingType = `${ this.translateService.instant('meterreadings.meterreadingIn') } ${this.unitPrettyPipe.transform(reading.zaehlwerkData.einheit)}`;
    const HT_NT = ['HT', 'NT'].includes(reading.zaehlwerkData.typ) ? reading.zaehlwerkData.typ : '';
    if (this.readingsOptional) {
      return formatWithoutReadingType + ' ' + (HT_NT ? HT_NT + ' ' : '') + formatReadingOptional;
    } else {
      return (reading.zaehlwerkData.einheit ? formatWithReadingType :
        formatWithoutReadingType) + (HT_NT ? ' ' + `${this.zaehlwerktypPipe.transform('', HT_NT)}` : '');
    }
  }

  public removeMeter(id: number) {
    const removedField = this.formFieldsForFrontend.splice(id, 1);
    const groupNameToDelete = Object.keys(this.meters?.controls)[id];
    this.meters.removeControl(groupNameToDelete);

    const meternumber = removedField[0]?.meterCheckResult?.meterNumber;
    delete this.meterData[meternumber];

    this.meterCheckResult.emit(this.meterData);
  }


  getMetersFormControl(index: number): AbstractControl {
    return this.meters.get(Object.keys(this.meters?.controls)[index]);
  }

  onAutomaticCancelChange(control: AbstractControl) {
    control.setValue(!control.value);
    this.trackingService.postSimpleTracking(
      this.trackingEventType, TRACKING.FORM_ACTION.SELECT, control.value ? 'Kündigung' : 'bereits gekündigt'
    );
  }

  private mapErrorMessage(message: string) {
    switch (message) {
      case CHECK_METER_RESPONSE.METER_FOUND:
        return null;
      case CHECK_METER_RESPONSE.METER_NOT_EXISTS:
        return null;
      case CHECK_METER_RESPONSE.METER_WRONG_ADDRESS:
        return {
          error: this.translateService.instant('delivery.error.wrongMeterToAddress')
        };
      case CHECK_METER_RESPONSE.METER_WRONG_DIVISION:
        return {
          error: this.translateService.instant('delivery.error.wrongDivisionToMeter',
          { division: [this.verbrauchstypPipe.transform(this.sparte)] })
        };
      default:
        return {
          error: this.translateService.instant('general.error.error')
        };
    }
  }


  private updateMeterReadingFormFields() {
    return (result: MetersCheckResult) => {
      const formFields = this.getFormfieldByMeterNumber(result.meterNumber);
      if (!formFields) {
        return;
      }
      const { meterFormGroup, formFieldForFrontend } = formFields;
      formFieldForFrontend.meterCheckResult = result;
      formFieldForFrontend.message = result.message as CHECK_METER_RESPONSE;
      meterFormGroup.get('minDate').setValue(result?.deliveryDate?.minDate);
      // remove old checks with other meterNumbers, that are not part of the form any more
      Object.keys(this.meterData).forEach((meterNumber) => {
        if (!this.getFormfieldByMeterNumber(meterNumber)) {
          delete (this.meterData[meterNumber]);
        }
      });
      const meterData = {
        [result.meterNumber]: result
      };
      this.meterData = {
        ...this.meterData,
        ...meterData
      };
      if (result.message === CHECK_METER_RESPONSE.METER_FOUND || result.message === CHECK_METER_RESPONSE.METER_NOT_EXISTS) {
        // add this meter to meterData (emitted to parent, i.E. to save to sessionStorage on form submit)
        this.meterCheckResult.emit(this.meterData);
        if (!this.withRegisters || result.isSmartMeter) {
          return;
        }
        if (result.exists === false) {
          result.register = [{
            zaehlwerkId: 0,
            zaehlwerknummer: '001'
          }];
        }
        const existingReadingGroup = meterFormGroup.get('readingsGroup') as UntypedFormGroup;
        // remove old readingsGroup to reset values
        if (existingReadingGroup) {
          const numberOfCurrentReadings = Object.keys(existingReadingGroup.controls).length;
          // remove last inputs, if current meterCheck result has less registers than in a prior check
          if (result.register.length < numberOfCurrentReadings) {
            for (let i = numberOfCurrentReadings; i > result.register.length; i--) {
              const readingName = Object.keys(existingReadingGroup.controls)[i - 1];
              existingReadingGroup.removeControl(readingName);
              formFieldForFrontend.readings.splice(i - 1, 1);
            }
          }
          result.register.forEach((registerData, index) => {
            if (formFieldForFrontend.readings.length) {
              formFieldForFrontend.readings[index].zaehlwerkData = registerData;
              formFieldForFrontend.readings[index].readingFormControl.updateValueAndValidity();
            }
          });
        }
        formFieldForFrontend.readings.forEach((reading) => reading.readingFormControl.updateValueAndValidity());
        formFieldForFrontend.readings = [...formFieldForFrontend.readings];
        // create formControl for every zaehlwerk to generate a new input in the form (gets looped in the template)
        result.register.forEach((zaehlwerk, index) => {
          this.addRegisterControl(zaehlwerk, meterFormGroup, index);
        });
      } else {
        if (meterFormGroup.get('readingsGroup')) {
          meterFormGroup.removeControl('readingsGroup');
          formFieldForFrontend.readings = [] as any;
        }
      }
      this.cd.detectChanges();
    };
  }

  /**
   * activates CheckMeterNumber on Control
   */
  private activateAllAsyncValidatorsOnAllMeters() {
    if (this.meters) {
      Object.entries(this.meters.controls).forEach(
        ([key, value]) => {
          value.get('meterNumber').setAsyncValidators([(control) => this.checkMeterNumber(control.value.trim())]);
        }
      );
      if (this.cancelOptionsShown) {
        Object.entries(this.meters.controls).forEach(
          ([key, value]) => {
            value.get('contractExisting').setAsyncValidators([(control) => this.checkMeterNumber(value.get('meterNumber').value, true)]);
          }
        );
      }
    }
  }
  private generateReadingName(index: number) {
    return 'meterReading_' + index;
  }

  private isValidNumber(value: string): boolean {
    if (!value || value.length < 1) {
      return true;
    }
    try {
      return !!value.match(/^\d+(,\d+)*$/);
    } catch (error) {
      return true;
    }
  }

  private getFormfieldByMeterNumber(meterNumber: string) {
    const meterGroups = this.meters as UntypedFormGroup;
    const meterGroupKey = Object.keys(meterGroups.controls).find((key) => {
      return meterGroups.get([key, 'meterNumber']).value.trim() === meterNumber.trim();
    });
    if (!meterGroupKey) {
      return;
    }
    const meterFormGroup = this.meters.get(meterGroupKey) as UntypedFormGroup;
    const formFieldForFrontend = this.formFieldsForFrontend.find((item) => item.meterFormControl === meterFormGroup.get('meterNumber'));
    return { meterFormGroup, formFieldForFrontend };
  }


  /**
   * add a registercontrol to reactive forms group and to displaygroup on frontend, if the wert-value of register
   * is defined, it gets prefilled
   * there is another object beneath the formGroups to hold extra data like the unit and type of the registers
   * it is also easier to loop through an array than through a formgroup object
   * @param {Zaehlwerk} register
   * @param {FormGroup} meterFormGroup
   * @param {number} index
   */
  private addRegisterControl(register: Zaehlwerk, meterFormGroup: UntypedFormGroup, index: number) {
    const name = this.generateReadingName(index);
    const readingTextInput = new UntypedFormControl(register.wert ? register.wert : '',
      [
        (control) => {
          this.zaehlerValidator.sparte = this.verbrauchstypPipe.transform(this.sparte);
          const validationError = this.zaehlerValidator.validate(control);
          return validationError ? validationError : null;
        },
        (control) => !this.isValidNumber(control.value) ? { error: this.translateService.instant('meterreadings.input.validNumberRequired') } : null
      ]
    );
    const readingsGroup = meterFormGroup.get('readingsGroup') as UntypedFormGroup;

    // if no readingGroup available, add a new readingsGroup and add the register formcontrol
    if (!readingsGroup) {
      meterFormGroup.addControl('readingsGroup', new UntypedFormGroup({ [name]: readingTextInput }));
      this.addRegisterFormField(meterFormGroup, readingTextInput, register);
    } else {
      if (!readingsGroup.get(name)) {
        readingsGroup.addControl(name, readingTextInput);
        this.addRegisterFormField(meterFormGroup, readingTextInput, register);
      }
    }
  }

  private addRegisterFormField(meterFormGroup: UntypedFormGroup, readingTextInput: UntypedFormControl, register: Zaehlwerk) {
    const formFieldForFrontend = this.formFieldsForFrontend.find((item) => item.meterFormControl === meterFormGroup.get('meterNumber'));
    formFieldForFrontend.readings.push({
      readingFormControl: readingTextInput,
      zaehlwerkData: register
    });
  }

}
