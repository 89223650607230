import { Component, OnInit } from '@angular/core';
import { TenantService } from '../../../services/tenant.service';
import {
  Address,
  AnonymousMeterreading,
  CheckIdentificationData,
  StatusResponse,
  Zaehlerstand
} from '../../../../../assets/js/com/ts_api_client';
import { LoginService } from '../../../../login/login.service';
import { REGISTER_ERROR } from '../../../enums/registerError.enum';
import { RESPONSE_STATUS } from '../../../enums/responseStatus.enum';
import { BdoApiService } from '../../../services/bdo-api.service';
import { forwardCustomHttpStatusCodes, RETURN_CODES } from '../../../enums/returnCodes.enum';
import { SAVE_METER_ERROR } from '../../../enums/saveMeterError.enum';
import { forkJoin } from 'rxjs';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { TrackingService } from '../../../services/tracking.service';
import { ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';

enum STATE {
  'VALIDATION' = 'Validation',
  'DATAENTRY' = 'DataEntry'
}

@Component({
  selector: 'bdo-anonymous-meter-reading',
  templateUrl: './anonymous-meter-reading.component.html',
  styleUrls: ['./anonymous-meter-reading.component.scss']
})
export class AnonymousMeterReadingComponent implements OnInit {
  public state: STATE = STATE.VALIDATION;
  public sendingData: boolean = false;
  public stateEnum = STATE;
  RegisterErrorEnum = REGISTER_ERROR;
  registerError: REGISTER_ERROR = this.RegisterErrorEnum.NONE;
  isRegisteredUser: boolean;
  SaveMeterErrorEnum = SAVE_METER_ERROR;
  saveMeterError: SAVE_METER_ERROR = this.SaveMeterErrorEnum.NONE;
  identificationData: CheckIdentificationData;
  meterreading: Zaehlerstand;
  savingDone: boolean = false;
  implausible: boolean = false;
  savingFailed: boolean = false;
  accountId: string = this.activatedRoute?.snapshot?.queryParamMap?.get('accountid') ?? '';
  linkToLoggedInService: string;
  public address: Address;

  constructor(
    public tenantService: TenantService,
    private loginService: LoginService,
    private trackingService: TrackingService,
    private activatedRoute: ActivatedRoute,
    private apiService: BdoApiService

  ) {
    this.linkToLoggedInService = this.accountId ? ('../../zaehlerstand/' + this.accountId) : undefined;
  }

  ngOnInit() {
    this.trackingService.postTracking(TRACKING.LOCATION.ANONYMOUS_METERREADINGS, TRACKING.ACTION.ENTER);
  }

  resetErrorBox() {
    this.registerError = REGISTER_ERROR.NONE;
    this.saveMeterError = SAVE_METER_ERROR.NONE;
    this.savingFailed = false;
  }

  sendIdentificationData(identificationData: CheckIdentificationData) {
    this.identificationData = identificationData;
    this.sendingData = true;
    forkJoin([
      this.loginService.checkIdentification(identificationData.accountId, identificationData.meterNumberOrRegisterCode, identificationData.atDate),
      this.apiService.checkSmartMeterAnonymous(identificationData)
    ])
      .subscribe(
        {
          next: ([loginStatus, smartMeterCheck]) => this.onCheckSuccess(loginStatus, smartMeterCheck),
          error: () => this.onCheckError()
        });
  }

  sendAnonymousMeterData(meterreading: Zaehlerstand) {
    this.sendingData = true;
    const data: AnonymousMeterreading = { meterreading: meterreading, identifier: this.identificationData };
    this.apiService.postMeterreadingAnonymous(data)
      .pipe(
        forwardCustomHttpStatusCodes()
      ).subscribe({
        next: (res: HttpResponse<any>) => {
          const status = res.status;
          this.sendingData = false;
          if (status === RETURN_CODES.PROGNOSISDIFF) {
            this.implausible = true;
            if (meterreading.geprueft) {
              this.savingDone = true;
              this.trackingService.postTracking(TRACKING.LOCATION.METERREADINGS, TRACKING.ACTION.SUCCESS);
            } else {
              this.trackingService.postTracking(TRACKING.LOCATION.METERREADINGS, TRACKING.ACTION.IMPLAUSIBLE);
            }
          } else if (status === RETURN_CODES.METER_ALREADY_BILLED) {
            this.saveMeterError = SAVE_METER_ERROR.METER_ALREADY_BILLED;
            this.trackingService.postTracking(TRACKING.LOCATION.METERREADINGS, TRACKING.ACTION.ERROR);
          } else if (status === RETURN_CODES.NO_ACTIVE_CONTRACT) {
            this.saveMeterError = SAVE_METER_ERROR.NO_ACTIVE_CONTRACT;
            this.trackingService.postTracking(TRACKING.LOCATION.METERREADINGS, TRACKING.ACTION.ERROR);
          } else {
            // Reset
            this.savingDone = true;
            this.trackingService.postTracking(TRACKING.LOCATION.METERREADINGS, TRACKING.ACTION.SUCCESS);
          }
        },
        error: (error: unknown) => {
          this.sendingData = false;
          this.savingFailed = true;
          this.trackingService.postTracking(TRACKING.LOCATION.METERREADINGS, TRACKING.ACTION.ERROR);
        }
      });
  }

  toggleMeterState() {
    window.document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
    this.sendingData = true;

    forkJoin([
      this.apiService.getMeterreadingAnonymous(this.identificationData),
      this.apiService.getAddressAnonymous(this.identificationData.accountId, this.identificationData.meterNumberOrRegisterCode)
    ])
      .subscribe(
        {
          next: ([meterreading, address]) => {
            this.meterreading = meterreading;
            this.address = address.premiseAddress;
            this.state = STATE.DATAENTRY;
            this.sendingData = false;
          },
          error: () => { this.sendingData = false; }
        });
  }

  showRegisterError(registerError: REGISTER_ERROR) {
    this.registerError = registerError;
  }

  showCheckSmartMeterError(error: SAVE_METER_ERROR) {
    this.saveMeterError = error;
  }

  closeMessage() {
    this.savingDone = false;
    this.state = STATE.VALIDATION;
    this.meterreading = {};
    this.identificationData.atDate = undefined;
    this.identificationData.meterNumberOrRegisterCode = undefined;
    this.implausible = false;
    window.document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  private onCheckSuccess(loginStatus: StatusResponse, smartMeterCheck: StatusResponse) {
    this.sendingData = false;
    if (loginStatus.status === RESPONSE_STATUS.Error) {
      this.showRegisterError(loginStatus.info as REGISTER_ERROR);
      return;
    }

    if (smartMeterCheck.status === RESPONSE_STATUS.Error) {
      this.showCheckSmartMeterError(smartMeterCheck.info as SAVE_METER_ERROR);
      return;
    }

    if (loginStatus.status !== RESPONSE_STATUS.Success || smartMeterCheck.status !== RESPONSE_STATUS.Success) {
      throw new Error(`Illegal status: ${loginStatus.status} or ${smartMeterCheck.status}`);
    }

    // If user is already registered, the name is contained in "info"
    this.isRegisteredUser = (loginStatus.info !== '');
    this.toggleMeterState();

    return;
  }

  private onCheckError() {
    this.sendingData = false;
    this.showRegisterError(REGISTER_ERROR.WRONG_COMBINATION);
  }
}
