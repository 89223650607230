/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Object containing private address data
 */
export interface CompanyAddressData { 
    /**
     * id reflecting title attribute like \'Herr\', \'Frau\', \'keine Anrede\', \'Firma\' etc.
     */
    titleId?: string;
    /**
     * GEWE first person
     */
    name1?: string;
    /**
     * GEWE second person
     */
    name2?: string;
    /**
     * GEWE customer \'Branche\'; internal SAP ID of sector
     */
    sectorId?: string;
    /**
     * GEWE customer \'Rechtsform\'; internal SAP ID of legal entity
     */
    legalEntityId?: string;
    /**
     * Zip Code / PLZ
     */
    postCode?: string;
    cityName?: string;
    streetName?: string;
    houseNum?: string;
}

