import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Environment } from '../../environments/environment';
import { TENANT } from '../bdo/enums/tenant.enum';
import { Observable } from 'rxjs';

/**
 * navigates to /baustelle if tenant is 'Leichlingen' -> no move available actually
 */

export const guardTenantMove: CanActivateFn = (route, state) : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router);
  if (Environment.tenant === TENANT.StadtwerkeLeichlingen) {
    return router.navigate(['baustelle']);
  } else {
    return true;
  }

};
