import { Component, OnInit } from '@angular/core';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { PLACEHOLDER_ROUTE_ACCOUNTID } from '../../../services/customer-store.service';
import { TenantService } from '../../../services/tenant.service';
import { TrackingService } from '../../../services/tracking.service';

@Component({
  selector: 'bdo-move-start',
  templateUrl: './move-start.component.html',
  styleUrls: ['./move-start.component.scss']
})
export class MoveStartComponent implements OnInit {

  public placeholderAccountId : string = PLACEHOLDER_ROUTE_ACCOUNTID;

  constructor(
    public tenantService: TenantService,
    public trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.MOVE_AUTH, TRACKING.ACTION.ENTER);
  }

}
