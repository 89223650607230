<form (ngSubmit)="sendAnswer.emit(freetextForm.get('answer').value)">

  <bdo-multi-line-input
    [placeholder]="'satisfactionPoll.freetext' | translate"
    [required]="true"
    [maxLength]="maxLength"
    [validationIconHidden]="true"
    [inputName]="'message'"
    [inputValue]="freetextForm.get('answer').value"
    (inputValueChanged)="freetextForm.get('answer').setValue($event)"
  >
  </bdo-multi-line-input>

  <button type="submit" class="re-button re-primary re-w-full re-mt-20 md:re-w-auto">
    {{ 'general.action.submit' | translate }}
  </button>
</form>
