"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TargetingEngine = void 0;
var TargetingEngine = /** @class */function () {
  function TargetingEngine() {}
  var _a;
  _a = TargetingEngine;
  TargetingEngine.checkTargetingTree = function (targetingTree, targetingData) {
    var result = null;
    // checking if the tree has no targeting condition
    if (null == targetingTree) {
      result = true;
    } else {
      // checking if the tree is a leaf
      var targetingCondition = targetingTree.targetingCondition;
      if (null != targetingCondition) {
        result = _a.checkTargetingCondition(targetingCondition, targetingData);
      } else {
        // computing left child result
        var leftChildResult = _a.checkTargetingTree(targetingTree.leftChild, targetingData);
        var mustComputeRightChildResult = false;
        if (true == leftChildResult) {
          if (!targetingTree.orOperator) {
            mustComputeRightChildResult = true; // true AND anything, needs to know the anything
          }
        } else if (false == leftChildResult) {
          if (targetingTree.orOperator) {
            mustComputeRightChildResult = true; // false OR anything, needs to know the anything
          }
        } else if (null == leftChildResult) {
          mustComputeRightChildResult = true; // (undefined OR anything) or (undefined AND anything), needs to know the anything
        }
        // computing right child result if we must do it
        var rightChildResult = null;
        if (mustComputeRightChildResult) {
          rightChildResult = _a.checkTargetingTree(targetingTree.rightChild, targetingData);
        }
        // computing result
        if (true == leftChildResult) {
          if (targetingTree.orOperator) {
            result = true; // true OR anything
          } else {
            if (true == rightChildResult) {
              result = true; // true AND true
            } else if (false == rightChildResult) {
              result = false; // true AND false
            } else if (null == rightChildResult) {
              result = null; // true AND undefined
            }
          }
        } else if (false == leftChildResult) {
          if (targetingTree.orOperator) {
            if (true == rightChildResult) {
              result = true; // false OR true
            } else if (false == rightChildResult) {
              result = false; // false OR false
            } else if (null == rightChildResult) {
              result = null; // false OR undefined
            }
          } else {
            result = false; // false AND anything
          }
        } else if (null == leftChildResult) {
          if (targetingTree.orOperator) {
            if (true == rightChildResult) {
              result = true; // undefined OR true
            } else if (false == rightChildResult) {
              result = null; // undefined OR false
            } else if (null == rightChildResult) {
              result = null; // undefined OR undefined
            }
          } else {
            if (true == rightChildResult) {
              result = null; // undefined AND true
            } else if (false == rightChildResult) {
              result = false; // undefined AND false
            } else if (null == rightChildResult) {
              result = null; // undefined AND undefined
            }
          }
        }
      }
    }
    // returning result
    return result;
  };
  // check targeting condition
  TargetingEngine.checkTargetingCondition = function (targetingCondition, targetingData) {
    var result = null;
    // obtaining targeting
    if (targetingCondition != null) {
      result = targetingCondition.check(targetingData(targetingCondition.conditionType));
      // correcting targeting result in the case an exclusion rule is asked for
      if (true != targetingCondition.include) {
        if (null == result) {
          result = true;
        } else {
          result = !result;
        }
      }
    } else {
      result = true;
    }
    // returning result
    return result;
  };
  return TargetingEngine;
}();
exports.TargetingEngine = TargetingEngine;