<div class="re-mb-30 lg:re-mb-75">

  <div class="re-bg-white re-w-full re-text re-mb-60 md:re-mb-105  re-pb-30 md:re-pb-45 lg:re-pb-60
                  md:re-w-600 md:re-mx-auto
                  lg:re-w-900">
    <div class="re-px-20">
      <div class="re-container__content re-pt-30 md:re-pt-45 lg:re-pt-60">
        <h4 class=" h5 re-mb-30">
          {{title}}
        </h4>
      </div>
    </div>
    <div class="re-container__content re-pl-15 re-pr-50 md:re-px-0 re-mb-10" #errorBoxContainer>
      <bdo-info-box *ngIf="loginError">
        {{ loginError }}
      </bdo-info-box>
      <ng-container *ngIf="activationState !== ActivationStateEnum.NONE">
        <div [ngSwitch]="activationState">
          <bdo-info-box *ngSwitchCase="ActivationStateEnum.SUCCESS" [type]="'success'">
            {{ 'login.activateSuccess' | translate }}
          </bdo-info-box>
          <bdo-info-box *ngSwitchCase="ActivationStateEnum.ACCOUNT_ALREADY_ACTIVATED">
            {{ 'login.error.linkAlreadyInUse' | translate }}
          </bdo-info-box>
          <bdo-info-box *ngSwitchCase="ActivationStateEnum.CODE_INVALID">
            {{ 'register.error.invalidLink' | translate }}
          </bdo-info-box>
          <bdo-info-box *ngSwitchDefault>
            {{ 'general.error.error' | translate }}
          </bdo-info-box>
        </div>
      </ng-container>
    </div>
    <bdo-loading *ngIf="sendingData" [fullscreen]=true></bdo-loading>
    <bdo-login-form
      [name]="recognizedUsername"
      (submitLogin)="onSubmitLogin($event, errorBoxContainer)"
      [(isFetching)]="isFetching"
      (hideErrorEvent)="onHideError()"
    ></bdo-login-form>
    <ng-container *ngIf="!content?.elementRef?.nativeElement?.children?.length">
      <hr class="md:re-max-w-440 re-my-30 re-mx-20 md:re-mx-auto re-border-t re-mt-45 re-mb-20 re-border-monochrome-medium-light">
      <div class="re-px-20">
        <div class="re-container__content">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
      </div>
    </ng-container>

  </div>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<bdo-simple-text-popup [isVisible]="tosShown" (closePopupEvent)="onTosClosed(false)">
  <bdo-terms-of-service-confirmation (closeToSConfirmation)="onTosClosed($event)"></bdo-terms-of-service-confirmation>
</bdo-simple-text-popup>
