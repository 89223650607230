<div class="badge-area md:re-w-600 md:re-mx-auto re-pb-20 re-flex re-flex-row lg:re-w-900">
  <bdo-select-button
    *ngFor="let selectButton of selectButtons"
    [title]="selectButton.title"
    [active]="selectButton.active"
    [completed]="selectButton.completed"
    [iconSource]="selectButton.iconSrc"
    [clickable]="clickable"
    (activate)="onActivate(selectButton)"
  ></bdo-select-button>
</div>
