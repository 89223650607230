import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Contract, ContractPaymentData, SituationData, TariffData, ZaehlerIdentifier } from '../../../../../assets/js/com/ts_api_client';
import { StorageService } from '../../../services/storage.service';
import { TenantService } from '../../../services/tenant.service';
import { OFFER_CONTEXT, OFFER_CONTEXT_SPECIAL } from '../../../../../../src/app/bdo/enums/offer-context';
import { StoragePersonalData } from '../../../models/storagePersonalData';
import { DELIVERY_TYPE } from '../../../enums/deliveryType';
import { AuthDataStorage } from '../../../models/AuthData.storage';
import { BdoApiService } from '../../../services/bdo-api.service';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { iif, mergeMap, of, tap } from 'rxjs';
import { TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';

@Component({
  selector: 'bdo-offer-status',
  templateUrl: './offer-status.component.html',
  styleUrls: ['./offer-status.component.scss']
})
export class OfferStatusComponent implements OnInit {
  public isValidOffer: boolean;
  public loadingState: LOADING_STATE = LOADING_STATE.LOADING;
  public LoadingState = LOADING_STATE;
  public destination: string;
  public offerContext: string;
  public tracking: Array<string>;
  private token: string;
  private offerId: string;

  constructor(
    public tenantService: TenantService,
    private apiService: BdoApiService,
    private activatedRoute: ActivatedRoute,
    private trackingService: TrackingService
  ) {
    this.activatedRoute.queryParams
      .subscribe({ next: params => {
        this.offerId = params.offerId;
        this.token = params.token;
      } });
  }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.OFFER, TRACKING.ACTION.ENTER);
    let offer: Contract;
    let meterNumber: string;
    let meterNotAvailable: boolean = false;

    window.sessionStorage.clear();

    this.apiService.getOffer(this.offerId, this.token).pipe(
      tap((offerResponse: Contract) => {
        offer = offerResponse;
        meterNumber = offer.situationData.meterData[0]?.meterNumber;
      }),
      mergeMap((offerResponse: Contract) =>
        iif(() => offerResponse.tariffData?.[0]?.campaignId === OFFER_CONTEXT.UPSELLING,
                this.apiService.getMetersAnonymously(offerResponse.situationData.accountID, meterNumber),
                of(offerResponse)))
    ).subscribe({
      next: (meterResponse: ZaehlerIdentifier[]) => {
        if (meterResponse.length > 0) {
          meterNotAvailable = meterResponse.length === 0 || meterResponse.filter(meter => meter.meterNumber === meterNumber && !meter.inaktiv).length === 0;
        }
        if (!offer) {
          this.isValidOffer = false;
          this.loadingState = LOADING_STATE.ERROR;
          this.trackingService.postTracking(TRACKING.LOCATION.OFFER, TRACKING.ACTION.EXPIRED);
        } else {
        this.loadingState = LOADING_STATE.IDLE;
        this.isValidOffer = true;
        this.initData(offer, meterNotAvailable);
        this.trackingService.postSimpleTracking(TRACKING.LOCATION.OFFER, TRACKING.ACTION.VIEW, offer.tariffData?.[0]?.campaignId);
        this.tracking = [TRACKING.LOCATION.OFFER, TRACKING.ACTION.GOTO, offer.tariffData?.[0]?.campaignId];
        }
      },
      error: (error: unknown) => {
        this.isValidOffer = false;
        this.loadingState = LOADING_STATE.ERROR;
        this.trackingService.postTracking(TRACKING.LOCATION.OFFER, TRACKING.ACTION.EXPIRED);
      }
    });
  }

  initData(offer: Contract, meterNotAvailable: boolean) {
    // There should not be more than one item
    const campaignId = meterNotAvailable ? OFFER_CONTEXT.REAKQUISE : offer.tariffData?.[0]?.campaignId;
    let tempDeliveryType: string;
    if (campaignId?.includes(OFFER_CONTEXT.NEUANMELDUNG) || campaignId?.includes(OFFER_CONTEXT.UMZUG)) {
      tempDeliveryType = DELIVERY_TYPE.MOVE;
    } else {
      tempDeliveryType = DELIVERY_TYPE.CHANGE;
    }

    const personalData: StoragePersonalData = offer.personalData;
    personalData.streetIsChangeable = false;
    personalData.housenumIsChangeable = false;
    personalData.registrationData = {
      tosConfirmed: null,
      username: '',
      passwordGroup: {
        password: '',
        confirmPassword: ''
      }
    };
    const situationData: SituationData = offer.situationData;
    situationData.deliveryType = tempDeliveryType;
    const paymentData: ContractPaymentData = offer.paymentData;
    const tariffData: TariffData[] = offer.tariffData;

    StorageService.setOfferContext(campaignId as OFFER_CONTEXT | OFFER_CONTEXT_SPECIAL);
    StorageService.setOfferId(this.offerId);
    StorageService.setOfferVouchers(offer.offerVouchers);
    StorageService.setPersonalData(personalData);
    StorageService.setSituationData(situationData);
    StorageService.setPaymentData(paymentData);
    StorageService.setTariffData(tariffData);
    if (StorageService.getOfferContext()?.includes(OFFER_CONTEXT.UPSELLING)) {
      const authData: AuthDataStorage = {
        checkIdentificationData: {
            accountId: offer.situationData.accountID,
            meterNumberOrRegisterCode: offer.situationData.meterData?.[0]?.meterNumber
          }
        };
      StorageService.setAuthData(authData);
      this.destination = `/vertrag/${offer.situationData.accountID}/anpassen/tarifauswahl`;
    } else {
      this.destination = '/vertrag-abschliessen/neu/vertragsauswahl';
    }
  }
}
