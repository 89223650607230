import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { VERBRAUCHSTYP } from '../../../../enums/verbrauchstyp.enum';
import { BdoApiService } from '../../../../services/bdo-api.service';
import { StorageService } from '../../../../services/storage.service';
import {
  TariffData,
  TariffSummary,
  TariffSummaryResults
} from '../../../../../../assets/js/com/ts_api_client';
import { CUSTOMERMODE } from '../../../../enums/customerMode';
import { TariffAdvisorService } from '../../../../services/tariff-advisor.service';
import { TariffPriceAnnual } from '../../../../../shared/models/tariff-price.annual';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'bdo-tariff-summary',
  templateUrl: './tariff-summary.component.html',
  styleUrls: ['./tariff-summary.component.scss']
})
export class TariffSummaryComponent implements OnInit {
  @Input() selectedTariff: TariffData;
  @Output() public tariffDataChange = new EventEmitter<TariffSummaryResults>();

  public CUSTOMERMODE = CUSTOMERMODE;
  public Verbrauchstyp = VERBRAUCHSTYP;
  public prodConstId: string;
  public isLoading = false;
  public prices: TariffPriceAnnual[];
  public tariffData: TariffSummaryResults;
  public meterTooltip = '';
  public iconSrc: string;
  public hasBonus: boolean = false;
  public isGewe: boolean;

  constructor(
    public tariffAdvisorService: TariffAdvisorService,
    private apiService: BdoApiService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.selectedTariff = this.selectedTariff ?? StorageService.getTariffData()?.[0];
    const startDate = StorageService.getSituationData()?.startDate;
    const addressData = StorageService.getPersonalData()?.addressData;
    const moveTariffData = StorageService.getFirstTariffSelection()?.selectedTariff;
    this.prodConstId = this.selectedTariff.productId;

    if (moveTariffData) {
      this.tariffData = moveTariffData;
      this.setTariffParameters();
    } else {
      this.isLoading = true;
      this.apiService.getTariff({
        beginDate: moment(startDate).format('yyyy-MM-DD').toString(),
        division: this.selectedTariff.divisionId,
        annualConsumption: parseInt(this.selectedTariff.annualConsumption, 10),
        postCode: addressData.postCode,
        productIds: Array.of(this.prodConstId),
        cityName: addressData.city,
        streetName: addressData.street,
        houseNum: addressData.housenumber,
        annualConsumptionNt: parseInt(this.selectedTariff.annualConsumptionNt, 10) || null,
        offerId: StorageService.getOfferId() || null
      }).subscribe(
        {
          next: (res: TariffSummary) => {
            this.tariffData = res.results.find(item => item.productId === this.prodConstId);
            this.setTariffParameters();
            this.isLoading = false;
          },
          error: () => {
            this.isLoading = false;
          }
        });
    }
  }

  setTariffParameters() {
    this.prices = this.tariffAdvisorService.getPeriodicPricesByTimeSlices(this.tariffData);
    this.updateMeterTooltip();
    this.tariffDataChange.emit(this.tariffData);
    this.hasBonus = this.tariffData?.parts[0]?.contractPayments.some(elem => elem.description.length > 0);
    this.iconSrc = this.tariffData?.iconUrl ? `${this.translateService.instant('general.url.iconPathCms')}${this.tariffData?.iconUrl}` :
                    this.tariffAdvisorService.getPathForIcon(this.tariffData?.division);
    this.isGewe = this.tariffData?.customerType === CUSTOMERMODE.GEWE;
  }

  updateMeterTooltip() {
    this.meterTooltip = this.tariffAdvisorService.getMeterTooltip(this.selectedTariff?.mode);
  }

  getWorkingPriceInfo(costs: Array<string>): string {
    if (!costs) {
      return '';
    }
    let info = '';

    for (const text of costs) {
      if (!text.includes('Konstant')) {
        info += '<div>' + text + '</div>';
      }

    }
    return info;
  }
}
