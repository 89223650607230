import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FadeInAnimation } from '../../../shared/animations/fade-in.animation';
import { defaultValidatorProxy } from '../../../shared/validators/default-validator-proxy';
import { TRACKING } from '../../enums/trackingParts.enum';
import { VerbrauchstypPipe } from '../../pipes/verbrauchstyp.pipe';
import { TrackingService } from '../../services/tracking.service';
import { ConsumptionFormtype } from '../../../shared/formtypes/consumption.formtype';
import { ConsumptionStep, TariffAdvisorService } from '../../services/tariff-advisor.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-tariff-consumption-selection',
  templateUrl: './tariff-consumption-selection.component.html',
  styleUrls: ['./tariff-consumption-selection.component.scss'],
  animations: [
    FadeInAnimation
  ]
})
export class TariffConsumptionSelectionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() consumption: number;
  @Input() postCode: string;
  @Input() divisionId: string;
  @Input() isInEditMode: boolean = false;
  @Input() hasMultipleMeters: boolean = false;
  @Input() steps: Array<ConsumptionStep> = [ ];
  @Input() consumptionForm = new FormGroup<ConsumptionFormtype>({
    selectedStep: new FormControl(''),
    consumption: new FormControl('', {
      validators: [
        defaultValidatorProxy(Validators.required, this.translateService.instant('tariffAdvisor.error.consumptionRequired')),
        defaultValidatorProxy(Validators.pattern(/^\d*$/), this.translateService.instant('general.error.wrongNumberFormat'))
      ]
    })
  });
  @Input() isTrackingDisabled: boolean = false;
  @Output() calculate: EventEmitter<number> = new EventEmitter();
  @Output() edit: EventEmitter<boolean> = new EventEmitter();

  private subscriptions: Subscription = new Subscription();

  constructor(
    private translateService: TranslateService,
    private tariffAdvisorService: TariffAdvisorService,
    private trackingService: TrackingService,
    private divisionPipe: VerbrauchstypPipe
  ) { }

  ngOnInit(): void {
    const updateSelectedStepSubscription = this.consumptionForm.controls.selectedStep.valueChanges.subscribe({ next: (step) => {
      if (parseInt(step, 10) > 0) {
        const consumption = this.getConsumptionByStep(step);
        this.consumptionForm.controls.consumption.setValue(consumption?.toString());
      }
    } });
    const consumptionSubscription = this.consumptionForm.controls.consumption.valueChanges.subscribe({ next: (value) => {
      const correspondingConsumptionStep = this.getStepByConsumption(value);
      const isCustomConsumption = !correspondingConsumptionStep;
      if (isCustomConsumption) {
        this.consumptionForm.controls.selectedStep.setValue('0');
      }
      if (!this.isTrackingDisabled) {
        this.trackingService.postBaseAndAdditionalTracking({
          event: 'orca-tarifrechner',
          orca_tarifrechner: 'plz_eingabe',
          orca_plz: this.postCode,
          orca_plz_strasse: '',
          orca_product_sparte: this.divisionPipe.transform(this.divisionId, false),
          orca_jahresverbrauch: value?.toString()
        });
      }
    } });

      this.subscriptions.add(updateSelectedStepSubscription);
      this.subscriptions.add(consumptionSubscription);

    if (!this.consumption) {
      // Prefill with second step
      this.consumptionForm.controls.selectedStep.setValue(this.tariffAdvisorService.getConsumptionSteps(this.divisionId)?.[1]?.step);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.divisionId?.currentValue) {
      this.steps = this.tariffAdvisorService.getConsumptionSteps(changes.divisionId?.currentValue);
      this.consumptionForm.controls.consumption.setValue(undefined);
      this.consumptionForm.updateValueAndValidity();
    }

    if (changes.consumption?.currentValue) {
      const consumption = changes.consumption?.currentValue;
      this.consumptionForm.controls.consumption.setValue(consumption);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onCalculate() {
    this.consumptionForm.markAllAsTouched();
    this.consumptionForm.updateValueAndValidity();
    if (this.consumptionForm.valid) {
      this.calculate.emit(parseFloat(this.consumptionForm.controls.consumption.value));
      this.isInEditMode = !this.isInEditMode;
    }
  }

  getConsumptionByStep(step: string) {
    return this.tariffAdvisorService.getConsumptionSteps(this.divisionId)?.find(item => {
      return item.step === step;
    })?.value;
  }

  getStepByConsumption(value: string): string {
    return this.tariffAdvisorService.getConsumptionSteps(this.divisionId)?.find(item => item.value.toString() === value)?.step;
  }

  onEdit() {
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.TARIFF_ADVISOR, TRACKING.FORM_ACTION.SELECT, 'Verbrauchsanpassung');
    this.isInEditMode = !this.isInEditMode;
    this.edit.emit(this.isInEditMode);
  }

}
