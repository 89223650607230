<bdo-bank-account-form [parentForm]="parentForm" [formTracking]="formTracking"></bdo-bank-account-form>

<div [formGroup]="form">
  <bdo-checkbox
    [checked]="form.get('sepaAuth')?.value"
    [required]="sepaRequired && form.get('sepaAuth')?.touched"
    [errorText]="'banking.sepa.sepaMandatMissing' | translate"
    (change)="form.get('sepaAuth')?.setValue(!form.get('sepaAuth')?.value)"
    data-qa="sepaAuth">
    <div>{{ 'banking.sepa.title' | translate }}</div>
    <p class="re-mb-30 re-leading-m" [innerHTML]="'banking.sepa.disclaimer' | translate"></p>
  </bdo-checkbox>
</div>
