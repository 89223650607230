import { Component, OnInit } from '@angular/core';
import { Environment } from '../../../../environments/environment';
import { TENANT } from '../../../bdo/enums/tenant.enum';

@Component({
  selector: 'bdo-svg-definitions',
  templateUrl: './svg-definitions.component.html',
  styleUrls: ['./svg-definitions.component.scss']
})
export class SvgDefinitionsComponent implements OnInit {

  public iconColor: string = '';
  private currentTenant = Environment.tenant;

  // from cms we only get the svg as image with black fill. so we need a filter to manipulate the color.
  // the filter reduces specific tones of color, so it has the same color like the font. To get the parameters
  // go to a color matrix mixer, load the svg from cms and change the offsets to get the prefered color.
  private colorDefault: string = '0 0 0 0 0.04 0 0 0 0 0.04 0 0 0 0 0.04 0 0 0 1 0';
  private colorBELKAW: string = '0 0 0 0 0 0 0 0 0 0.05 0 0 0 0 0.16 0 0 0 1 0';
  private colorSWA: string = '0 0 0 0 0.015 0 0 0 0 0.04 0 0 0 0 0.28 0 0 0 1 0';

  constructor() { }

  ngOnInit() {
    switch (this.currentTenant){
      case TENANT.Belkaw:
        this.iconColor = this.colorBELKAW;
        break;

      case TENANT.StadtwerkeSanktAugustin:
        this.iconColor = this.colorSWA;
        break;

      default:
        this.iconColor = this.colorDefault;
    }
  }

}
