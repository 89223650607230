import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ZaehlerIdentifier, Zaehlerstand, Zaehlwerk } from '../../../../../../assets/js/com/ts_api_client';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ZaehlerstandValidatorDirective } from '../../../../../shared/validators/zaehlerstand-validator.directive';
import { BdoApiService } from '../../../../services/bdo-api.service';
import { CheckIdentificationData } from '../../../../../../assets/js/com/ts_api_client';
import { CustomerStoreService } from '../../../../services/customer-store.service';
import { LoginService } from '../../../../../login/login.service';
import { first, switchMap } from 'rxjs/operators';
import { LOADING_STATE } from '../../../../enums/loadingState.enum';
import { StorageService, ALLOWED_KEYS } from '../../../../services/storage.service';
import { HomeData } from '../../../../models/home';
import { defaultValidatorProxy } from '../../../../../shared/validators/default-validator-proxy';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { FormFieldIdentifierTracking } from '../../../../services/tracking.service';
import { TRACKING } from '../../../../enums/trackingParts.enum';
import { MeterreadingInfoService } from '../../../../services/meterreading-info.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-recent-meter-readings',
  templateUrl: './recent-meter-readings.component.html',
  styleUrls: ['./recent-meter-readings.component.scss']
})
export class RecentMeterReadingsComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() meter: ZaehlerIdentifier;

  @Output() readingDataChange = new EventEmitter<Zaehlerstand>();

  public meterReading: Zaehlerstand;
  public validationIconHidden: boolean = false;
  public minDate: Date;
  public maxDate: Date;
  public LoadingState = LOADING_STATE;
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public formTracking: FormFieldIdentifierTracking = {
    orca_field_name: '',
    orca_field_section: TRACKING.FORM.MOVE_RECENTHOME_SECTION_METERS,
    orca_form_name: TRACKING.FORM.MOVE_RECENTHOME_NAME
  };
  private zaehlerValidator: ZaehlerstandValidatorDirective = new ZaehlerstandValidatorDirective(this.translateService);
  private accountId: string = '';

  constructor(
    public meterreadingInfoService: MeterreadingInfoService,
    private translateService: TranslateService,
    private apiService: BdoApiService,
    private customerStore: CustomerStoreService,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.state = LOADING_STATE.LOADING;
    this.zaehlerValidator.sparte = this.meter?.sparte;
    this.accountId = this.customerStore.getAccountId() || StorageService.getAuthData()?.checkIdentificationData?.accountId;

    // check Storage for meterReadings otherwise getMeterreading from SAP
    const meters = StorageService.getValue<HomeData>(ALLOWED_KEYS.RECENT_HOME_DATA)?.meterData;
    let isInStorage: boolean = false;
    if (meters) {
      meters.map((meter: Zaehlerstand) => {
        if (this.meter?.meterNumber === meter.meterNumber) {
          this.meterReading = meter;
          this.setMinMaxDate();
          this.setControls();
          isInStorage = true;
          this.state = LOADING_STATE.IDLE;
        }
      });
    }
    if (!isInStorage) {
      this.getMeterreading(new Date(Date.now()));
    }
  }

  dateChanged(newDate: Date) {
    if (!moment(newDate).isSame(this.meterReading?.datum, 'day')) {
      this.getMeterreading(newDate);
    }
  }

  getMeterreading(newDate: Date) {
    this.state = LOADING_STATE.LOADING;
    const identificationData: CheckIdentificationData = {
      accountId: this.accountId,
      meterNumberOrRegisterCode: this.meter?.meterNumber,
      atDate: new Date(newDate)
    };

    this.loginService.isLoggedIn$.pipe(
      first(),
      switchMap((loggedIn) => {
        return loggedIn ? this.apiService.getZaehlerstand(this.meter?.meterNumber, new Date(newDate)) :
               this.meter.isSmartMeter ? this.buildSmartMeterAnonymous() : this.apiService.getMeterreadingAnonymous(identificationData);
      })
    ).subscribe(
      {
        next: (meterReading: Zaehlerstand) => {
          this.meterReading = meterReading;
          this.setMinMaxDate();
          this.setControls();
          this.saveReading();
          this.state = LOADING_STATE.IDLE;
        },
        error: () => {
          this.state = LOADING_STATE.ERROR;
        }
      });
  }

  setMinMaxDate() {
    this.minDate = this.meterReading.minDateForChanges ? this.meterReading.minDateForChanges : moment().subtract(7, 'd').startOf('day').toDate();
    this.maxDate = this.meterReading.maxDateForChanges ? this.meterReading.maxDateForChanges : new Date(Date.now());
  }

  buildSmartMeterAnonymous(): Observable<Zaehlerstand> {
    const newMeter: Zaehlerstand = {
      zaehlerstandId: null,
      meterNumber: this.meter.meterNumber,
      sparte: this.meter.sparte,
      datum: new Date().getTime() as any,
      minDateForChanges: null,
      maxDateForChanges: null,
      isUserEditable: true,
      geprueft: null,
      zaehlwerkeListe: [
        { zaehlwerkId: null,
         zaehlwerknummer: null,
         einheit: null,
         wert: null,
         typ: '',
         equipment: this.meter.equipment }
      ]
    };
    return of(newMeter);
  }

  setControls() {
    this.meterReading.zaehlwerkeListe.forEach((zaehlwerk: Zaehlwerk) => {
      this.parentForm.addControl('reading-' + this.meterReading.meterNumber + '-' + zaehlwerk.zaehlwerknummer, new FormControl());
      this.parentForm.get('reading-' + this.meterReading.meterNumber + '-' + zaehlwerk.zaehlwerknummer).setValue(zaehlwerk.wert?.toString().replace('.',','));
      this.parentForm.get('reading-' + this.meterReading.meterNumber + '-' + zaehlwerk.zaehlwerknummer).setValidators([
        defaultValidatorProxy(Validators.pattern(/^\d+(,\d+)*$/), this.translateService.instant('meterreadings.input.validNumberRequired')),
        (control) => this.zaehlerValidator.validate(control),
      ]);
    });
  }

  getPlaceholder(zaehlwerkData: Zaehlwerk) {
    const formatReadingOptional = this.translateService.instant('general.tariff.optional');
    const formatWithoutReadingType = this.translateService.instant('meterreadings.meterreading');
    const HT_NT = ['HT', 'NT'].includes(zaehlwerkData.typ) ? zaehlwerkData.typ : '';
    return formatWithoutReadingType + ' ' + (HT_NT ? HT_NT + ' ' : '') + formatReadingOptional;
  }

  saveReading() {
    // registers inputs are string with comma, so we need to convert them back to a number/float (especially for Wärme)
    this.meterReading.zaehlwerkeListe.map(register => {
      register.wert = parseFloat((register?.wert)?.toString()?.replace(',','.'));
    });
    this.readingDataChange.emit(this.meterReading);
  }
}
