import { Component, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'bdo-accordion-item',
  templateUrl: './accordion-item.component.html',
  styleUrls: ['./accordion-item.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '*',
        'overflow-y': 'hidden' // eslint-disable-line @typescript-eslint/naming-convention
      })),
      state('closed', style({
        height: '0',
        'padding-bottom': 0, // eslint-disable-line @typescript-eslint/naming-convention
        'overflow-y': 'hidden' // eslint-disable-line @typescript-eslint/naming-convention
      })),
      transition('open => closed', [
        animate('0.3s ease-in-out')
      ]),
      transition('closed => open', [
        animate('0.3s ease-in-out')
      ]),
    ]),
  ],
})
export class AccordionItemComponent {
  /**
   * If the panel is opened or closed
   */
  @Input() opened = false;

  /**
   * Text to display in the item title bar
   */
  @Input() title: string;

  /**
   * Emitted when user clicks on item titlebar
   * @type {EventEmitter<any>}
   */
  @Output() toggle: EventEmitter<any> = new EventEmitter<any>();

  itemSelected(itemContainer: HTMLElement = null) {
    if (!this.opened) {
      setTimeout(() => {
        itemContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 300);
    }

    this.toggle.emit();
  }

  animationDone(itemContainer: HTMLElement = null) {
    if (!this.opened) {
      itemContainer.style.visibility = 'hidden';
    }

  }

  animationStarted(itemContainer: HTMLElement = null) {
    if (this.opened) {
      itemContainer.style.visibility = 'visible';
    }

  }
}
