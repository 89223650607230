import { Injectable } from '@angular/core';
import { Environment } from '../../../environments/environment';
import { STAGE } from '../enums/stage.enum';

@Injectable({
  providedIn: 'root'
})
export class TrustpilotService {

  constructor() { }

  /**
   * Build Trustpilot Link with business generated link from backend
   * @param trustSecretToken
   * @private
   */
  public buildTrustpilotUniqueUrl(trustSecretToken: string): string | undefined {
    if (!trustSecretToken) {
      return undefined;
    }
    let baseUrl = 'https://de.trustpilot.com/evaluate-bgl/embed/rheinenergie.tp-testing.com';
    if (Environment.stage === STAGE.Production ) {
      baseUrl = 'https://de.trustpilot.com/evaluate-bgl/embed/rheinenergie.com';
    }
    return `${baseUrl}?p=${encodeURIComponent(trustSecretToken)}`;
  }
}
