import { ChangeDetectorRef, Component, isDevMode, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { combineLatest, Observable, ReplaySubject, startWith } from 'rxjs';
import { filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import * as smoothscroll from 'smoothscroll-polyfill';
import { BackendStatus } from './bdo/enums/backendStatus';
import { TRACKING } from './bdo/enums/trackingParts.enum';
import { BdoApiService } from './bdo/services/bdo-api.service';
import { CampaignService } from './bdo/services/campaign.service';
import { MaintenanceService } from './bdo/services/maintenance.service';
import { TrackingService } from './bdo/services/tracking.service';
import { LoginService } from './login/login.service';
import { DatadogService } from './bdo/services/datadog.service';
import { DebugLogger } from './shared/utils/debugLogger';
import { KameleoonService } from './bdo/services/kameleoon.service';
import { CustomerStoreService } from './bdo/services/customer-store.service';
import { LanguageService } from './bdo/services/language.service';
import { ReadonlyTenantsService } from './bdo/services/readonly-tenants.service';
import { ToastService } from './bdo/services/toast.service';

@Component({
  selector: 'bdo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  // maintenance data
  public maintenanceData$ = this.maintenanceService.maintenanceData$;
  public maintenanceStatus$ = this.maintenanceService.maintenanceStatus$;
  public isNavigating: boolean = false;

  public BackendStatus = BackendStatus;
  public tosAccepted$ = this.loginService.tosAccepted$;
  public campaignPopupIsReadyToShow$: Observable<boolean>;
  public showOnlineServiceRemovedMessage$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public popupsAreReadyToShow$: Observable<boolean>;
  constructor(
    public campaignService: CampaignService,
    public readonlyTenantsService: ReadonlyTenantsService,
    public toastService: ToastService,
    private apiService: BdoApiService,
    private trackingService: TrackingService,
    private loginService: LoginService,
    private router: Router,
    private customerStore: CustomerStoreService,
    private maintenanceService: MaintenanceService,
    private _cdRef: ChangeDetectorRef,
    private datadogService: DatadogService,
    private kameleoonService: KameleoonService,
    private languageService: LanguageService,
  ) {
    this.loginService.configure();

    this.checkTOSAfterPageReload();

    // activate if there is a usecase for kameleoon

    if (!isDevMode()) {
      this.trackingService.cookieHandlingIsSet$.subscribe({ next: (cookiesAllowed) => {
        if (this.datadogService.getIsLoaded() && !cookiesAllowed) {
          this.datadogService.unloadDatadog();
        }
        if (!this.datadogService.getIsLoaded() && cookiesAllowed) {
          this.datadogService.loadDatadog(cookiesAllowed);
        }
        if (cookiesAllowed) {
          this.kameleoonService.load();
        } else {
          this.kameleoonService.unload();
        }
      } });
    }

    // IE-Scrolling
    smoothscroll.polyfill();
  }

  public ngOnInit(): void {
    this.languageService.setInitialLanguage();
    /* Set readOnly Tenants here
      if (Environment.tenant === TENANT.X) {
        this.setOnlineServiceRemoved();
      }
    */

    this.popupsAreReadyToShow$ = combineLatest([
      this.loginService.isLoggedIn$, this.tosAccepted$
    ]).pipe(
      map(([isLoggedIn, tosAccepted]) => isLoggedIn && tosAccepted),
      filter((isReady) => isReady),
      shareReplay(1)
    );

    this.campaignPopupIsReadyToShow$ = this.popupsAreReadyToShow$.pipe(
      filter((isReady) => isReady),
      switchMap(() => this.campaignService.currentCampaign$.pipe(filter(Boolean))),
      map((campaign) => !!campaign),
      shareReplay(1)
    );

    /*
    Show loading animation while navigation
     */
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart
        || event instanceof NavigationEnd
        || event instanceof NavigationCancel
        || event instanceof NavigationError),
      ).subscribe({ next: (e) => {
        // set flag isNavigating to true on NavigationStart and to fals on NavigationEnd
        this.isNavigating = (e instanceof NavigationStart);
        this._cdRef.detectChanges();
    } });

    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map((e: NavigationEnd): string => {
        return this.trackingService.normalizeUrl(e.url);
      })
    ).subscribe({ next: (pageView) => this.datadogService.trackPageView(pageView) });
  }

  /**
   * this method gets called when the user initially is entering the application while logged in
   * if the user is not logged in, the logic is provided by the LoginComponent after login
   */
  public checkTos() {
    DebugLogger.debug(this.constructor.name, 'checkTos()', 'start check') ;
    this.apiService.getToS()
      .subscribe({ next: res => {
        DebugLogger.debug(this.constructor.name, 'checkTos()', 'result', res) ;
        if (!res.confirmed) { // logging out if ToS not accepted
          this.trackingService.postTracking(TRACKING.LOCATION.APP_NO_TOS, TRACKING.ACTION.GOTO, TRACKING.LOCATION.LOGIN);
          // check if internal state of app is accepted (by clicking the accepted button)
          this.loginService.logout();
          this.customerStore.resetData();
          this.router.navigate(['/login']);
        } else {
          this.loginService.tosAccepted$.next(true);
        }
      } });
  }

  public onCloseOnlineServiceRemovedMessage() {
    this.showOnlineServiceRemovedMessage$.next(false);
  }

  /**
   * checks if the OS removed message should be shown
   * @private
   */
  private setOnlineServiceRemoved() {
    combineLatest([
      this.loginService.isLoggedIn$, this.tosAccepted$
    ]).pipe(
      map(([isLoggedIn, tosAccepted]) => {
        return isLoggedIn && tosAccepted;
      }),
    ).subscribe(this.showOnlineServiceRemovedMessage$);
  }

  /**
   * checks the TOS status after a full page reload as soon as the Cognito Library detects a valid login session
   * @private
   */
  private checkTOSAfterPageReload() {
    this.loginService.isLoggedIn$.pipe(filter((isLoggedIn) => !!isLoggedIn), first()).subscribe({ next: () => {
        if (!this.loginService.tosPending) {
          this.checkTos();
        }
      } });
  }

}
