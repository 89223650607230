<div class="re-mb-30">
  <div class="re-summary-row">
    <div>{{ 'general.tariff.contractBegin' | translate }}</div>
    <div>{{ newHomeData?.startDate | date }}</div>
  </div>
  <div class="re-summary-row">
    <div>{{ 'address.postCode.postCodeAndCity' | translate }}</div>
    <div>{{ personalData?.addressData?.postCode }}, {{ personalData?.addressData?.city }}</div>
  </div>
  <div class="re-summary-row">
    <div>{{ 'address.street.streetAndHousenumber' | translate }}</div>
    <div>{{ personalData?.addressData.street }} {{ personalData?.addressData.housenumber }}</div>
  </div>
  <div class="re-summary-row" *ngIf="!hasEmail">
    <div>{{ 'address.email.title' | translate }}</div>
    <div>{{ personalData?.email }}</div>
  </div>
  <div class="re-summary-row" *ngIf="personalData?.phonenumber">
    <div>{{ 'address.phonenumber.title' | translate }}</div>
    <div>{{ personalData?.phonenumber }}</div>
  </div>
</div>

<div *ngFor="let meter of newHomeData?.meterData">
  <h5 class="re-mb-30 re-mt-45">
    {{ 'general.tariff.meterWithDivision' | translate: { division: meter.divisionId | verbrauchstyp } }}
  </h5>
  <div class="re-summary-row">
    <div>{{ 'general.customerData.meternumber' | translate }}</div>
    <div>{{ meter?.meterNumber}}</div>
  </div>
  <ng-template [ngIf]="meter.register?.length">
    <div class="re-summary-row" *ngFor="let register of meter?.register">
      <div>{{ 'meterreadings.meterreading' | translate }} {{ ['HT', 'NT'].includes(register.typ) ? ('' | zaehlwerktyp: register.typ) : '' }}</div>
      <div>{{ register?.wert ? register.wert + ' ' + [register.einheit | unitPretty] :
              (meter.isSmartMeter ? 'meterreadings.smartmeter.readingInfoShort' : 'meterreadings.info.enterLaterShort') | translate }}</div>
    </div>
  </ng-template>

</div>
