<div class="bdo-container re-mb-30 md:re-mb-60">
  <h3>{{ 'contract.new.products.title' | translate }}</h3>
</div>

<div class="bdo-container--single">
  <h4 class="re-mb-20 md:re-mb-30">{{ 'contract.new.products.addressTitle' | translate }}</h4>
  <div class="re-bg-white re-px-20 re-py-30 md:re-px-30 md:re-py-45 re-mb-45 md:re-mb-60">
    <div class="re-summary-row">
      <div>{{ 'address.street.streetAndHousenumber' | translate }}</div>
      <div>{{address?.streetName}} {{address?.houseNum}}</div>
    </div>
    <div class="re-summary-row re-mb-0">
      <div>{{ 'address.postCode.postCodeAndCity' | translate }}</div>
      <div>{{address?.postCode}} {{address?.cityName}}</div>
    </div>
  </div>

</div>

<form (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" bdoScrollToInvalidControl [bdoScrollToInvalidControlForm]="form">
  <bdo-product-selection
    [address]="nestedAddress"
    [newHomeForm]="form"
    [forceValidateDate]="forceValidateDate"
    [cancelOptionsShown]="true"
    [abortIfNoDivisions]="true"
    [trackingEventType]="TRACKING.LOCATION.CONTRACT_NEW_PRODUCTS"
    [isContextContractNew]="true"
    (updateAvailableDivisions)="onUpdateAvailableDivisions($event)"
  ></bdo-product-selection>


  <div class="bdo-container--single re-mt-45 md:re-mt-60">
    <bdo-validate-submit
      [textButtonLong]="(availableDivisionInfos?.allUnavailableOrExternal ?
                           'general.navigation.backToStart' : 'wizard.button.productSelection') | translate"
    ></bdo-validate-submit>
    <div class="re-pt-20">
      <bdo-text-icon
        [iconPosition]="'left'"
        [text]="'general.action.back' | translate"
        (itemClicked)="location.back()"
      ></bdo-text-icon>
    </div>
  </div>
</form>
