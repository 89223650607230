import { Component, Input, OnInit } from '@angular/core';
import { TariffSummaryResults, TariffTextItem } from '../../../../assets/js/com/ts_api_client';
import { CUSTOMERMODE } from '../../enums/customerMode';
import { VERBRAUCHSTYP } from '../../enums/verbrauchstyp.enum';
import { BdoApiService } from '../../services/bdo-api.service';
import { TariffAdvisorService } from '../../services/tariff-advisor.service';

@Component({
  selector: 'bdo-tariff-prices-cost-info',
  templateUrl: './tariff-prices-cost-info.component.html',
  styleUrls: ['./tariff-prices-cost-info.component.scss']
})
export class TariffPricesCostInfoComponent implements OnInit {
  @Input() tariffData: TariffSummaryResults;
  public infoText: string = '';

  constructor(
    private tariffAdvisorService: TariffAdvisorService,
    private apiService: BdoApiService
  ) { }

  ngOnInit(): void {
    this.apiService.getTariffText(
      this.tariffAdvisorService.getKeyForTariffInfo(this.tariffData?.customerType as CUSTOMERMODE, this.tariffData?.division as VERBRAUCHSTYP))
      .subscribe({
        next: (res: TariffTextItem) => {
          this.infoText = res?.infotext;
        },
        error: () => {
          this.infoText = undefined;
        }
      });
  }
}
