<bdo-coop-teaser *ngIf="isRE && isInNetcologneRegion"
  class="re-block re-mt-45"
  data-qa="netcologne-teaser"
  [title]="'teaser.netcologne.title' | translate"
  [linkData]="[{
    href: 'teaser.netcologne.link' | translate,
    linkName: 'teaser.netcologne.linkName' | translate,
    tracking: [trackingLocation, TRACKING.ACTION.GOTO, TRACKING.LOCATION.NETCOLOGNE_LANDINGPAGE]
  }]"
  [iconSrc]="'teaser.netcologne.logo' | translate"
>
  {{ 'teaser.netcologne.description' | translate }}
</bdo-coop-teaser>
