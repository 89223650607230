import { Component, Output, EventEmitter } from '@angular/core';
import { TRACKING } from '../../bdo/enums/trackingParts.enum';
import { TrackingService } from '../../bdo/services/tracking.service';

@Component({
  selector: 'bdo-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss']
})
export class RegisterSuccessComponent {
  @Output() closeEmitter: EventEmitter<string> = new EventEmitter();

  constructor (
    private trackingService: TrackingService
  ) { }

  close() {
    this.trackingService.postTracking(TRACKING.LOCATION.REGISTER_SUCCESS, TRACKING.ACTION.CLOSE);
    this.closeEmitter.emit('/login');
  }

}
