import { Component, OnInit } from '@angular/core';
import { CustomerStoreService } from '../../services/customer-store.service';
import { Router } from '@angular/router';
import { ReadonlyTenantsService } from '../../services/readonly-tenants.service';

@Component({
  selector: 'bdo-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss']
})
export class PersonalDataComponent implements OnInit {
  public disabled: boolean = this.readonlyTenantsService.isCurrentTenantReadonly();

  public accountId: string;
  public optInEditTriggeredByPopup: boolean;

  constructor(
    private router: Router,
    private customerStore: CustomerStoreService,
    private readonlyTenantsService: ReadonlyTenantsService
  ) { }

  ngOnInit(): void {
    this.accountId = this.customerStore.getAccountId();
    this.optInEditTriggeredByPopup = this.router.url.includes('optin') && this.router.getCurrentNavigation().extras.state?.isEdit;
    this.disabled = this.optInEditTriggeredByPopup || this.disabled;
  }

  onEditChange(isEditing: boolean, scrollToFragment: string) {
    this.disabled = isEditing;
    this.router.navigate([], { fragment: scrollToFragment, skipLocationChange: true });
  }

}
