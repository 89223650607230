import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskAll'
})
export class MaskAllPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (!value) {
      return value;
    }
    return value.replace(/./gi, '*');
  }

}
