/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Empfehlung auf Vertrag Ebene, dabei wird auch z.B. max. Grenze/Korrekturbetrag berücksichtigt.
 */
export interface BillingPlanSuggestionItem { 
    /**
     * accountId of existing contract, also knows as VK
     */
    accountId?: string;
    /**
     * contractId of existing contract
     */
    contractId?: string;
    proposedAmount?: number;
    currentAmount?: number;
    /**
     * true if billing plan should be adjusted by user, false if billing plan amount if within limits
     */
    recommended?: boolean;
    errorMessage?: string;
}

