import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

// Nicht-Leerzeichen + 6 Zeichen + Nicht-Leerzeichen = 8 Zeichen gesamt
export const passwordPattern = /^(?!\s)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[~!@#$%^&*_\-+=`|\\(){}[\]:;"'<>,.?\/]).{6,}.+[^\s]$/;

/**
 * Validates a password
 * @returns {(control: AbstractControl) => {[p: string]: string}}
 * @param control
 * @param errorText
 */
export function passwordValidator(translateService: TranslateService, errorText?: string) {
  return (control: AbstractControl): { [key: string]: string | true } | null => {
    const regex = RegExp(passwordPattern);
    if (!control.value) {
      return null;
    }

    errorText = errorText ? errorText : translateService.instant('register.error.passwordPolicy');

    if (!regex.test(control?.value)) {
      return { ['password']: (errorText ? errorText : true) };
    }
    return null;
  };
}
