<div class="accordion accordion-element re-bg-white re-text-sm re-block re-mb-5 re-font-regular ">
  <button #accordionitem class="title re-w-full re-text-left question re-text-secondary re-py-15 re-pl-15 re-pr-60 re-leading-m re-relative re-cursor-pointer hover:re-text-secondary-hover"
          (click)="itemSelected(accordionitem);"
          [attr.aria-expanded]="opened">
          {{ title }}
    <span [class.opened]="opened" class="re-absolute re-right-0 re-top-0 re-mr-15 re-mt-15">
      <svg-icon src="assets/icons/plusminus.svg"></svg-icon>
    </span>
  </button>
  <div #accordioncontent class="accordion-element re-bg-white re-text-sm re-block re-mb-5 re-font-lighten re-px-15 re-pb-30 re-lean-m" [class.hidden]="!opened" [@openClose]="opened ? 'open' : 'closed'" [attr.aria-hidden]="!opened"
  (@openClose.start)="animationStarted(accordioncontent)" (@openClose.done)="animationDone(accordioncontent)">
    <ng-content></ng-content>
  </div>
</div>
