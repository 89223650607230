import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map as _map } from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import {
  Address,
  MetersCheckResult,
  SituationData,
  Supplier,
  Suppliers,
  TariffData
} from '../../../../../assets/js/com/ts_api_client';
import { Utilities } from '../../../../shared/utils/utilities';
import { autosuggestAvailable } from '../../../../shared/validators/autosuggest-available-validator';
import { autosuggestMatches } from '../../../../shared/validators/autosuggest-matches-validator';
import { defaultValidatorProxy } from '../../../../shared/validators/default-validator-proxy';
import { INPUT_TYPE } from '../../../enums/inputType.enum';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { DELIVERY_TYPE } from '../../../enums/deliveryType';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { BdoApiService } from '../../../services/bdo-api.service';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { TenantService } from '../../../services/tenant.service';
import { TrackingService } from '../../../services/tracking.service';
import { CONTEXT_FROM_SUMMARY } from '../summary/summary.component';
import { SituationFormFormtype } from '../../../../shared/formtypes/situation-form.formtype';
import { KameleoonService } from '../../../services/kameleoon.service';
import { Location } from '@angular/common';
import { NavigationState } from '../../../models/navigationState';
import { OFFER_CONTEXT } from '../../../enums/offer-context';
import { TranslateService } from '@ngx-translate/core';
import { VERBRAUCHSTYP } from '../../../enums/verbrauchstyp.enum';
import { Environment } from '../../../../../environments/environment';
import { TENANT } from '../../../enums/tenant.enum';

export interface MeterDataFormStructure {
  [key: string]: {
    meterNumber: string;
    minDate: string;
    readingsGroup: {
      wert: number;
    };
    contractExisting?: string;
    automaticCancelEnabled?: boolean;
    previousSupplier?: string;
    previousSupplierId?: string;
  };
}

@Component({
  selector: 'bdo-situation-form',
  templateUrl: './situation-form.component.html',
  styleUrls: ['./situation-form.component.scss']
})
export class SituationFormComponent implements OnInit, OnDestroy {
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public forceValidate = false;
  public minDate: Date;
  public maxDate: Date;
  public supply: string;
  // Autosuggest
  public suppliers$: Observable<string[]>;
  public searchString$: Observable<string>;
  public sortAutosuggest = Utilities.sortAutosuggest;
  public noSupplierAvailable$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public divisionId: string;
  public deliveryType: DELIVERY_TYPE;
  public meterData: { [key: string]: MetersCheckResult };
  public dateChanged: boolean = false;
  public storedMeterData: Array<MetersCheckResult>;
  public address: Address;
  public tariffData: TariffData;

  public offerContext: string;
  public isReacquisition: boolean;
  public isRE = Environment.tenant === TENANT.Rheinenergie;

  public situationForm = new FormGroup<SituationFormFormtype>({
    startDate: new FormControl(new Date(), {
      validators: [
        () => {
          if (!this.meterData) {
            return null;
          }
        }
      ]
    })
  });

  public inputTypeMappings = {
    'termination': INPUT_TYPE.RADIO,
    'startDate': INPUT_TYPE.TEXT,
    'previousSupplier': INPUT_TYPE.TEXT,
    'revocationWanted': INPUT_TYPE.CHECKBOX
  }; // Needed for Tracking
  public showRegisterInputs: boolean;
  public CONTEXT_FROM_SUMMARY = CONTEXT_FROM_SUMMARY;
  public context: string;

  private suppliers: Array<Supplier>;
  private subscriptions = new Subscription();

  constructor(
    public tenantService: TenantService,
    private apiService: BdoApiService,
    private trackingService: TrackingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private kameleoonService: KameleoonService,
    private location: Location,
    private translateService: TranslateService
  ) {
    const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
    this.context = currentState?.context;
  }


  ngOnInit(): void {
    this.offerContext = StorageService.getOfferContext();
    this.isReacquisition = this.offerContext?.includes(OFFER_CONTEXT.REAKQUISE);

    this.tariffData = StorageService.getTariffData()?.[0];
    this.storedMeterData = StorageService.getSituationData()?.meterData;
    const addressData = StorageService.getPersonalData()?.addressData;
    this.address = {
      houseNum: addressData?.housenumber,
      street: {
        city: {
          name: addressData?.city,
          postCode: addressData?.postCode
        },
        name: addressData?.street
      }
    };
    this.supply = this.mapSupply();
    this.deliveryType = this.activatedRoute.snapshot.data.situation as DELIVERY_TYPE;
    this.showRegisterInputs = this.isStateMove();
    this.divisionId = this.tariffData?.divisionId;
    this.suppliers$ = this.apiService.getSuppliers(this.divisionId).pipe(
      map((suppliers: Suppliers) => {
        this.suppliers = suppliers.list;
        return _map(suppliers.list, 'name');
      }),
      shareReplay(1));
    if (!this.isStateMove()) {
      if (this.isReacquisition){
        this.situationForm.addControl('revocationWanted', new FormControl(false, [Validators.required]));
      }
      else {
        this.situationForm.addControl('termination', new FormControl('auto', [Validators.required]));
      }
      this.situationForm.addControl('previousSupplier', new FormControl('', [Validators.required]));
      // no need to set previousSupplierId to be required
      this.situationForm.addControl('previousSupplierId', new FormControl(''));
      this.initSupplierValidator();
      this.searchString$ = this.situationForm.get('previousSupplier').valueChanges;
      this.subscriptions.add(this.suppliers$.subscribe()); // intial load of suppliers
    }

    // Prefill Values
    if (StorageService.getSituationData()) {
      const data = StorageService.getSituationData();

      // meter data is handled in the meterNumber-Component
      delete data.meterData;
      // delete deliveryType, since this is a meta-info and not a valid input (set automatically by selected situation (Wechsel/Umzug))
      delete data.deliveryType;

      if (this.isStateMove()) {
        delete data.previousSupplier;
        delete data.previousSupplierId;
        delete data.termination;
        delete data.revocationWanted;
      } else {
        if (this.isReacquisition){
          data.revocationWanted = !!data.revocationWanted;
        }
        else {
          data.termination = data.termination || 'auto';
        }
        data.previousSupplier = data.previousSupplier || '';
        data.previousSupplierId = data.previousSupplierId || '';
      }

      this.situationForm.patchValue({
        ...data,
        startDate: new Date(data.startDate)
      });


      // Fixing DateFormat for Calendar Input
      const defaultDate = data?.startDate ? data.startDate : Date.now();
      if (defaultDate === data?.startDate) {
        this.dateChanged = true;
      }
      this.situationForm.controls.startDate.setValue(new Date(defaultDate));
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initSupplierValidator() {
    const supplierControl = this.situationForm.get('previousSupplier');
    if ((this.situationForm.get('termination')?.value === 'auto') || this.isReacquisition) {
      this.setSupplierValidators();
    }

    if (this.situationForm.get('termination')){
      this.subscriptions.add(
        this.situationForm.get('termination').valueChanges.subscribe({ next: termination => {
          if (termination === 'auto') {
            this.setSupplierValidators();
          } else {
            supplierControl.clearAsyncValidators();
            supplierControl.clearValidators();
            this.situationForm.get('previousSupplier').setValue('');
          }
          supplierControl.updateValueAndValidity();
        } })
      );
    }
  }

  setSupplierValidators() {
    const supplierControl = this.situationForm.get('previousSupplier');
    supplierControl.setValidators([
      defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
      defaultValidatorProxy(Validators.minLength(2), this.translateService.instant('general.validator.minLength', { numberOfCharacters: 2 }))
    ]);

    supplierControl.setAsyncValidators([
      autosuggestAvailable(this.noSupplierAvailable$, this.translateService.instant('general.validator.autosuggestNoResult')),
      autosuggestMatches(this.suppliers$, this.translateService.instant('delivery.situation.error.previousSupplierNotValid'))
    ]);
  }

  mapSupply() {
    const isElectricity = this.tariffData?.type === 'e' || this.tariffData?.divisionId === VERBRAUCHSTYP.Strom;
    return isElectricity ? this.translateService.instant('general.division.S4') : this.translateService.instant('general.division.G6');
  }

  save() {
    if (this.situationForm.valid)  {
      const formData: SituationData = {};
      Object.keys(this.situationForm.controls).filter(key => key !== 'meterData').forEach(key => {
        formData[key] = this.situationForm.controls[key].value;
      });
      this.updateMeterDataValues();

      // convert key-value to array
      formData['meterData'] = Object.entries(this.meterData).map(([key, value]) => value);
      formData.deliveryType = this.deliveryType;

      StorageService.updateSituationData(formData);
      if (this.context === CONTEXT_FROM_SUMMARY) {
        this.router.navigate(['../../uebersicht'],  {
          relativeTo: this.activatedRoute
        });
      } else {
        this.kameleoonService.processConversion(KameleoonService.Goals.DELIVERY_SITUATION_TO_BANKDATA);

        this.router.navigate(['../../zahlungsart'],  {
          relativeTo: this.activatedRoute
        });
      }
    } else {
      this.forceValidate = true;
      this.situationForm.markAllAsTouched();
    }
  }

  trackInvalid(inputName: string) {
    const formControl = this.situationForm.get(inputName);
    const inputType = this.inputTypeMappings[inputName];

    if (formControl.invalid) {
      this.trackFormError(inputName, inputType);
    }
  }

  trackFormError(inputName: string, inputType: string) {
    this.trackingService.postFormTracking(
      TRACKING.FORM.DELIVERY_SITUATIONDATA_NAME,
      TRACKING.FORM_ACTION.ERROR,
      TRACKING.FORM.DELIVERY_SITUATIONDATA_SECTION,
      inputName,
      inputType
    );
  }

  updateDate(date: Date, changedManually: boolean) {
    this.situationForm.controls.startDate.setValue(date);
    if (changedManually) {
      this.dateChanged = true;
    }
  }

  public onMeterCheckResult($event: { [key: string]: MetersCheckResult }) {
    this.meterData = $event;
    // find latest minDate in all meters
    let latestMinDate = Object.entries($event).reduce((result, meter) => {
      const [groupName, meterCheckResult] = meter;
      if (!result || meterCheckResult.deliveryDate.minDate > result) {
        return new Date(meterCheckResult.deliveryDate.minDate);
      }
      return result;
    }, new Date(0));
    latestMinDate = new Date(new Date(latestMinDate).getTime() + 21600000);
    if (this.minDate !== latestMinDate) {
      this.minDate = latestMinDate;
      if ((this.situationForm.controls.startDate.value < new Date(this.minDate)) || (!this.dateChanged && !this.isStateMove())) {
        this.updateDate(this.minDate, false);
      }
    }
    // date field new form value after check cycle, so we have to manually detect changes after setting the min date
    this.cd.detectChanges();
  }

  public onItemSelected(selectedSupplierName: string) {
    this.trackingService.postAutosuggestTracking(
      TRACKING.FORM.DELIVERY_SITUATIONDATA_SECTION,
      'previousSupplier',
      this.situationForm.get('previousSupplier').value,
      selectedSupplierName
    );

    this.situationForm.get('previousSupplier').setValue(selectedSupplierName);
    const selectedSupplier: Supplier | undefined = this.suppliers.find((item) => item.name === selectedSupplierName);
    this.situationForm.get('previousSupplierId').setValue(selectedSupplier.id);
  }

  public isStateMove(): boolean {
    return this.deliveryType === DELIVERY_TYPE.MOVE;
  }

  private updateMeterDataValues() {
    const meterFormData = this.situationForm.get('meterData').value as MeterDataFormStructure;
    if (meterFormData && this.meterData) {
      Object.entries(this.meterData).forEach(([meterNumber, meterData]) => {
        const meterFormGroupValue = Object.entries(meterFormData).find(([groupName, group]) => {
          return group.meterNumber === meterNumber;
        });
        if (!meterFormGroupValue) {
          delete this.meterData[meterNumber];
        }
        if (meterFormGroupValue && meterFormGroupValue[1] && meterFormGroupValue[1].readingsGroup) {
          Object.entries((meterFormGroupValue[1]).readingsGroup).forEach(([key, value], index) => {
            meterData.register[index].wert = value;
          });
        }
      });
    }

  }

}
