import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Supplier, Suppliers } from '../../../../assets/js/com/ts_api_client';
import { Utilities } from '../../../shared/utils/utilities';
import { INPUT_TYPE } from '../../enums/inputType.enum';
import { BdoApiService } from '../../services/bdo-api.service';
import { map as _map } from 'lodash';
import { map, shareReplay } from 'rxjs/operators';
import { AbstractControl, Validators } from '@angular/forms';
import { autosuggestAvailable } from '../../../shared/validators/autosuggest-available-validator';
import { autosuggestMatches } from '../../../shared/validators/autosuggest-matches-validator';
import { defaultValidatorProxy } from '../../../shared/validators/default-validator-proxy';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-supplier-autosuggest',
  templateUrl: './supplier-autosuggest.component.html',
  styleUrls: ['./supplier-autosuggest.component.scss']
})
export class SupplierAutosuggestComponent implements OnInit, OnDestroy {
  @Input() divisionId: string;
  @Input() supplierControl: AbstractControl;
  @Input() supplierIdControl: AbstractControl;
  @Input() id: string; // optional, if the id cannot be the name of the input due to duplicate id problems
  public suppliers$: Observable<string[]>;
  public searchString$: Observable<string>;
  public sortAutosuggest = Utilities.sortAutosuggest;
  public noSupplierAvailable$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public INPUT_TYPE = INPUT_TYPE;
  private suppliers: Array<Supplier>;
  private subscriptions = new Subscription();

  constructor(
    private apiService: BdoApiService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.suppliers$ = this.apiService.getSuppliers(this.divisionId).pipe(
      map((suppliers: Suppliers) => {
        this.suppliers = suppliers.list;
        return _map(suppliers.list, 'name');
      }),
      shareReplay(1)
    );
    this.searchString$ = this.supplierControl?.valueChanges;
    this.subscriptions.add(this.suppliers$.subscribe()); // intial load of suppliers
    this.setSupplierValidators(this.supplierControl);
  }

  public ngOnDestroy(): void {
    this.clearSupplierValidators(this.supplierControl);
    this.subscriptions.unsubscribe();
  }

  setSupplierValidators(control: AbstractControl) {
    control?.setValidators([
      defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
      defaultValidatorProxy(Validators.minLength(2), this.translateService.instant('general.validator.minLength', { numberOfCharacters: 2 }))
    ]);

    control?.setAsyncValidators([
      autosuggestAvailable(this.noSupplierAvailable$, this.translateService.instant('address.autosuggestNoResults')),
      autosuggestMatches(this.suppliers$, this.translateService.instant('delivery.situation.error.previousSupplierNotValid'))
    ]);
    control?.updateValueAndValidity();
  }

  clearSupplierValidators(control: AbstractControl) {
    control?.clearAsyncValidators();
    control?.clearValidators();
    control?.setValue('');
    control?.updateValueAndValidity();
    this.supplierIdControl?.setValue('');
  }

/*   initSupplierValidator(control: AbstractControl) {
    this.subscriptions.add(
      this.situationForm.get('termination').valueChanges.subscribe(termination => {
        if (termination === 'auto') {
          this.setSupplierValidators(control);
        } else {
          control.clearAsyncValidators();
          control.clearValidators();
          control.setValue('');
        }
        control.updateValueAndValidity();
      })
    );
  } */

  public onItemSelected(selectedSupplierName: string) {
/*     this.trackingService.postAutosuggestTracking(
      TRACKING.FORM.DELIVERY_SITUATIONDATA_SECTION,
      'previousSupplier',
      this.situationForm.get('previousSupplier').value,
      selectedSupplierName
    ); */

    this.supplierControl.setValue(selectedSupplierName);
    const selectedSupplier: Supplier | undefined = this.suppliers.find((item) => item.name === selectedSupplierName);
    this.supplierIdControl?.setValue(selectedSupplier.id);
  }
}
