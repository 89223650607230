import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bdo-single-accordion',
  templateUrl: './single-accordion.component.html',
  styleUrls: ['./single-accordion.component.scss']
})
export class SingleAccordionComponent implements OnInit {
  @Input() shortInfo: string = '';
  @Input() arrowRight: boolean = false;
  @Input() isOpenInitially: boolean = false;
  @Input() position: 'left' | 'right' = 'left';
  @Input() buttonStyles: string = '';
  isOpen: boolean = false;

  constructor() { }

  ngOnInit() {
    this.isOpen = this.isOpenInitially;
   }

}
