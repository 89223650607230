<div class="re-container-headline re-mb-30 md:re-mb-60">
  <h3 data-qa="title">
    {{ (hasOfferContext ? 'offer.situation.title' : 'delivery.situation.title') | translate }}
  </h3>
</div>

<bdo-switch-card
  *ngIf="!hasOfferContext"
  [configuration]="configuration"
  [selectedTile]="selectedSituation"
  (selected)="selectSituation($event)"
></bdo-switch-card>

<router-outlet #form></router-outlet>
<div class="re-mx-20
            md:re-w-600 md:re-mx-auto
            lg:re-w-900">
  <div class="re-pt-15">
    <bdo-text-icon
      [iconPosition]="'left'"
      [text]="'general.action.back' | translate"
      (itemClicked)="location.back()"
    ></bdo-text-icon>
  </div>
</div>
