import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BdoApiService } from '../../services/bdo-api.service';
import { CustomerStoreService } from '../../services/customer-store.service';
import { Prognose, Zaehlerhistorie, VertragsIdentifier } from '../../../../../src/assets/js/com/ts_api_client';
import { ActivatedRoute } from '@angular/router';
import { TRACKING } from '../../enums/trackingParts.enum';
import { TrackingService } from '../../services/tracking.service';
import { PromoLinkData } from '../../../shared/molecules/promo-link-wrapper/promo-link-wrapper.component';
import { TranslateService } from '@ngx-translate/core';
import { ContentFilter } from '../../models/contentFilter';
import { VerbrauchstypPipe } from '../../pipes/verbrauchstyp.pipe';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bdo-verbrauch',
  templateUrl: './verbrauch.component.html',
  styleUrls: ['./verbrauch.component.scss']
})
export class VerbrauchComponent implements OnInit, OnDestroy {
  @Output() contractSelected: EventEmitter<string> = new EventEmitter();
  prognose: Prognose;
  vertragsIdentifiers: VertragsIdentifier;
  historie: Array<Zaehlerhistorie> = [];
  selectedActiveContract: string = '';
  selectedInactiveContract: string = '';
  isLoading: boolean = false;
  public accountId: string = '';
  public promoLinkDataSet: Array<PromoLinkData>;
  public contentFilterActive: Array<ContentFilter> = [];
  public contentFilterInactive: Array<ContentFilter> = [];
  private subscriptions: Subscription = new Subscription();

  constructor(
    private apiService: BdoApiService,
    private trackingService: TrackingService,
    private customerStore: CustomerStoreService,
    private route: ActivatedRoute,
    public translateService: TranslateService,
    public verbrauchstypPipe: VerbrauchstypPipe
  ) { }

  ngOnInit() {
    this.trackingService.postTracking(TRACKING.LOCATION.CONSUMPTION, TRACKING.ACTION.ENTER);

    const id = this.route.snapshot.params['kundennummer'];
    if (id) {
      this.customerStore.setAccountId(id);
    }
    this.accountId = this.customerStore.getAccountId();
    this.isLoading = true;
    this.apiService.getVertragsIdentifiers()
      .subscribe(
        {
          next: (data) => {
            this.vertragsIdentifiers = data;
            if (data['aktiv'][0]) { this.selectedActiveContract = data['aktiv'][0].contractId; }
            if (data['inaktiv'][0]) {this.selectedInactiveContract = data['inaktiv'][0].contractId; }
            this.contentFilterActive = data?.aktiv?.map(item => {
              let filter: ContentFilter = {
                id: item.contractId,
                title: this.verbrauchstypPipe.transform(item.sparte)
              };
              return filter;
            });
            this.contentFilterInactive = data?.inaktiv?.map(item => {
              let filter: ContentFilter = {
                id: item.contractId,
                title: this.verbrauchstypPipe.transform(item.sparte)
              };
              return filter;
            });
            this.isLoading = false;
          },
          error: () => {
            this.isLoading = false;
          }
        });

    this.setPromoLinkData();

    this.subscriptions.add(this.translateService.onLangChange.subscribe({
      next: () => {
        this.setPromoLinkData();
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setPromoLinkData(): void {
    this.promoLinkDataSet = [
      {
        linkText: this.translateService.instant('consumptionHistory.relatedTopics.billingPlanLinkText'),
        routerUrl: '/abschlag/' + this.accountId
      },
      {
        linkText: this.translateService.instant('consumptionHistory.relatedTopics.documentsLinkText'),
        routerUrl: '/dokumente/' + this.accountId
      }
    ];
  }

  updateSelectedContract(vertragsnummer: string, inactiveContract: boolean = false) {
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONSUMPTION, 'setVertragsnummer');
    if (inactiveContract) {
      this.selectedInactiveContract = vertragsnummer;
    } else {
      this.selectedActiveContract = vertragsnummer;
    }
    this.contractSelected.emit(vertragsnummer);
  }

}
