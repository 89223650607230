import { inject } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DebugLogger } from '../shared/utils/debugLogger';
import { LoginService } from '../login/login.service';

export const guardAuth: CanActivateFn = (route, state) : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const loginService = inject(LoginService);
  return loginService.isLoggedIn().pipe(map((isLoggedIn) => {
    if (!isLoggedIn) {
      DebugLogger.debug('guardAuth: ', 'is logged out -> redirect to loginPage');
      loginService.redirectToLoginPage(state.url);
      return false;
    } else {
      DebugLogger.debug('guardAuth: ', 'is logged in');
      return true;
    }
  }));
};
