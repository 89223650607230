import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'bdo-username-success',
  templateUrl: './username-success.component.html',
  styleUrls: ['./username-success.component.scss']
})
export class UsernameSuccessComponent {
  @Input() username: string = '';
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();

  close() {
    this.closeEvent.emit();
  }

}
