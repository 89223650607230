<div class="re-summary-row-nowrap" *ngIf="showAccountId">
  <div>{{ 'general.customerData.account' | translate }}</div>
  <div>{{accountId}}</div>
</div>

<div *ngIf="billingAddress?.keepRecipient">
  <div class="re-summary-row-nowrap" *ngFor="let recipient of billingAddress.recentRecipientPersons">
    <div>{{ 'address.personalInfo.name' | translate }}</div>
    <div>{{recipient.firstname}} {{recipient.lastname}}</div>
  </div>

  <div class="re-summary-row-nowrap" *ngIf="billingAddress?.recentRecipientOrg?.nameOrg1">
    <div>{{ 'address.personalInfo.name' | translate }}</div>
    <div>{{ billingAddress?.recentRecipientOrg.nameOrg1}} {{ billingAddress?.recentRecipientOrg.nameOrg2}}</div>
  </div>
</div>

<div class="re-summary-row-nowrap" *ngIf="!billingAddress?.keepRecipient">
  <div>{{ 'address.personalInfo.name' | translate }}</div>
  <div>{{billingAddress?.newRecipientGivenName}} {{billingAddress?.newRecipientSurname}}</div>
</div>

<div class="re-summary-row-nowrap">
  <div>{{ 'address.street.streetAndHousenumber' | translate }}</div>
  <div>{{billingAddress?.streetName}} {{billingAddress?.houseNum}}</div>
</div>

<div class="re-summary-row-nowrap">
  <div>{{ 'address.postCode.postCodeAndCity' | translate }}</div>
  <div>{{billingAddress?.postCode}} {{billingAddress?.cityName}}</div>
</div>

<div class="re-summary-row-nowrap re-mb-0">
  <div>{{ 'address.email.title' | translate }}</div>
  <div>{{billingAddress?.email}}</div>
</div>
