import { Component } from '@angular/core';
import { PollQuestionComponent } from '../poll-question/poll-question.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'bdo-freetext-question',
  templateUrl: './freetext-question.component.html',
  styleUrls: ['./freetext-question.component.scss']
})
export class FreetextQuestionComponent extends PollQuestionComponent {
  public maxLength = 255;
  public freetextForm = new FormGroup({
    answer: new FormControl('')
  });

  constructor() {
    super();
  }

}
