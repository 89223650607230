import { containsNewline } from './util-contains-newline.js';
function flowIndentCheck(indent, fc, onError) {
  if (fc?.type === 'flow-collection') {
    const end = fc.end[0];
    if (end.indent === indent && (end.source === ']' || end.source === '}') && containsNewline(fc)) {
      const msg = 'Flow end indicator should be more indented than parent';
      onError(end, 'BAD_INDENT', msg, true);
    }
  }
}
export { flowIndentCheck };