import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

export enum RETURN_CODES {
    OK = 200,
    ERROR = 400,
    PROGNOSISDIFF = 210,
    TOKEN_NOT_FOUND = 211,
    CONSUMPTION_NOT_FOUND = 212,
    PREDICTION_NOT_FOUND = 213,
    METER_ALREADY_BILLED = 460,
    NO_ACTIVE_CONTRACT = 461,
    METERREADING_EXISTS = 409
}


export function forwardCustomHttpStatusCodes() {
  return catchError((response: unknown) => {
    // forward custom statuscode to success-stream
    if ([RETURN_CODES.METER_ALREADY_BILLED, RETURN_CODES.NO_ACTIVE_CONTRACT, RETURN_CODES.METERREADING_EXISTS].includes((response as HttpResponse<any>).status)) {
      return of(response);
    } else {
      throw response;
    }
  });
}
