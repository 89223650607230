/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Eine Statusantwort über den Erfolg des Aufrufs und ggf. weitere Statusinfos
 */
export interface StatusResponse { 
    status?: string;
    info?: string;
}

