import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, filter, map, pluck, switchMap, tap } from 'rxjs/operators';
import { MaintenanceStatusResponse } from '../../../assets/js/com/ts_api_client';
import { Utilities } from '../../shared/utils/utilities';
import { BackendStatus } from '../enums/backendStatus';
import { BdoApiService } from './bdo-api.service';
import { TRACKING } from '../enums/trackingParts.enum';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Service holds all the information and methods, to deal with maintenance
 */
export class MaintenanceService {

  /** states, that are allowed to run the application **/
  public static ALLOWED_STATES = [BackendStatus.UnderMaintenance, BackendStatus.SoonUnderMaintenance, BackendStatus.Available];

  /**
   * holds the current backendstatus, can be updated
   * @type {ReplaySubject<MaintenanceStatusResponse>}
   */
  public backendStatus$ = new ReplaySubject<MaintenanceStatusResponse>(1);
  public backendStatusNotNull = this.backendStatus$.pipe(filter((backendStatus) => !!backendStatus));

  public maintenanceData$ = this.backendStatusNotNull.pipe(
    map((backendStatus) => backendStatus ? backendStatus.maintenance : undefined)
  );
  public maintenanceStatus$ = this.backendStatus$.pipe(map((backendStatus) => backendStatus.status));

  public serviceUnavailable$ = new ReplaySubject<boolean>(1);
  public showPopup$ = new ReplaySubject<boolean>(1);

  // maintenance data

  private plannedUACookieName: string = 'plannedUA';

  constructor(private apiService: BdoApiService,
              private trackingService: TrackingService) {
    this.subscribeToBackendStatusChange();
  }

  /**
   * set state observables on status change or go to maintenance page
   */
  public subscribeToBackendStatusChange() {
    this.backendStatus$
      .pipe(
        tap({ next: (statusResponse) => {
          if (!statusResponse) {
            return;
          }
          switch (statusResponse.status) {
            case BackendStatus.Available:
              this.serviceUnavailable$.next(false);
              this.trackingService.postSimpleTracking(TRACKING.LOCATION.APP, 'Available');
              break;
            case BackendStatus.Unavailable:
              this.trackingService.postSimpleTracking(TRACKING.LOCATION.APP, 'Unavailable');
              this.serviceUnavailable$.next(true);
              this.openMaintenancePopup();
              break;
            case BackendStatus.UnderMaintenance:
              this.trackingService.postSimpleTracking(TRACKING.LOCATION.APP, 'UnderMaintenance');
              this.serviceUnavailable$.next(true);
              this.openMaintenancePopup();
              break;
            case BackendStatus.SoonUnderMaintenance:
              this.serviceUnavailable$.next(false);
              if (!Utilities.getCookie(this.plannedUACookieName)) {
                this.trackingService.postSimpleTracking(TRACKING.LOCATION.APP, 'SoonUnderMaintenance');
                this.openMaintenancePopup();
                Utilities.createCookie(this.plannedUACookieName, '1', 2.0 / 24.0);
              }
              break;
          }
        } })
      ).subscribe();
  }

  /**
   * sets the state showPopup to true, can be used to open a popup, if the popup opening mechanism subscribes
   * to the showPopup Observable
   */
  public openMaintenancePopup() {
    this.showPopup$.next(true);
  }

  /**
   * sets the state showPopup to false, can be used to close a popup, if the popup closing mechanism subscribes
   * to the showPopup Observable
   */
  public closeMaintenancePopup() {
    this.showPopup$.next(false);
  }

  /**
   * calls the backend getStatus and updates the internal backendStatus$ of this Service
   * @returns {Observable<void | any>}
   */
  public checkBackendStatus(): Observable<void | any> {
    return this.apiService.getStatus().pipe(
      tap({ next: status => this.updateBackendStatus(status) }),
      catchError(() => this.onCheckStatusError())
    );
  }

  /**
   * updates the backend status in this service
   * @param {MaintenanceStatusResponse} status
   */
  private updateBackendStatus(status: MaintenanceStatusResponse) {
    this.backendStatus$.next(status);
  }

  /**
   * if the backend status call fails, we go to the maintenance page
   * @returns {Observable<never>}
   */
  private onCheckStatusError(): Observable<MaintenanceStatusResponse> {
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.APP, 'ERROR');
    this.updateBackendStatus({ status: BackendStatus.Unavailable });
    this.openMaintenancePopup();
    return (of({ status: BackendStatus.Unavailable }));
  }

}
