import { Component } from '@angular/core';

@Component({
  selector: 'bdo-section-headline',
  templateUrl: './section-headline.component.html',
  styleUrls: ['./section-headline.component.scss']
})
export class SectionHeadlineComponent {

}
