import { Component, Output, EventEmitter } from '@angular/core';
import { TRACKING } from '../../bdo/enums/trackingParts.enum';
import { TrackingService } from '../../bdo/services/tracking.service';

@Component({
  selector: 'bdo-terms-of-service-confirmation',
  templateUrl: './terms-of-service-confirmation.component.html',
  styleUrls: ['./terms-of-service-confirmation.component.scss']
})
export class TermsOfServiceConfirmationComponent {
  @Output() closeToSConfirmation: EventEmitter<boolean> = new EventEmitter();
  tosAccepted: boolean = false;
  tosTouched: boolean = false;

  constructor(
    private trackingService: TrackingService
  ) { }

  trackIt() {
    this.trackingService.postTracking(TRACKING.LOCATION.TERMSOFSERVICE_CONFIRMATION, TRACKING.ACTION.OPEN, TRACKING.LOCATION.TERMSOFSERVICE);
  }

  onTosClicked() {
    this.tosAccepted = !this.tosAccepted;
    this.tosTouched = true;
  }

  acceptTos(accepted: boolean) {

    if (accepted) { // close and go on

      this.trackingService.postTracking(TRACKING.LOCATION.TERMSOFSERVICE_CONFIRMATION, TRACKING.ACTION.ACCEPT);
      this.closeToSConfirmation.emit(accepted);
    } else { // Show Error
      this.trackingService.postSimpleTracking(TRACKING.LOCATION.TERMSOFSERVICE_CONFIRMATION, TRACKING.ACTION.ACCEPT + ' without checking Checkbox');
      this.tosTouched = true;
    }
  }

}
