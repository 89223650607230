/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * partner address data with referencing ids
 */
export interface PartnerAddressData { 
    /**
     * address id of an address
     */
    addressId?: string;
    /**
     * partnerId of customer, also known as GP / GeschäftspartnerId
     */
    partnerId?: string;
    /**
     * Zip Code / PLZ
     */
    postCode?: string;
    cityName?: string;
    streetName?: string;
    houseNum?: string;
}

