import { Component, EventEmitter, Output } from '@angular/core';
import { TenantService } from '../../services/tenant.service';
import { LoginService } from '../../../login/login.service';
import { TRACKING } from '../../enums/trackingParts.enum';
import { TrackingService } from '../../services/tracking.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'bdo-account-in-progress-warning',
  templateUrl: './account-in-progress-warning.component.html',
  styleUrls: ['./account-in-progress-warning.component.scss']
})
export class AccountInProgressWarningComponent {
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  public context: string = '';

  constructor(
    private tenantService: TenantService,
    private loginService: LoginService,
    private trackingService: TrackingService,
    private route: ActivatedRoute
  ) {
    this.context = this.route?.snapshot?.data?.context;
  }

  goToMainPage() {
    this.trackingService.postTracking(TRACKING.LOCATION.ACCOUNTINPROGRESS, TRACKING.ACTION.GOTO, TRACKING.LOCATION.HOMEPAGE);
    this.loginService.logout().subscribe(
      { next: res => {
        // Waiting for logout Call, so it doesn't get canceled before redirecting
        window.location.href = this.tenantService.getCurrentTenantData().hrefHomepage;
      }, error: (error: unknown) => {
        window.location.href = this.tenantService.getCurrentTenantData().hrefHomepage;
      } });
  }


  onClose() {
    this.trackingService.postTracking(TRACKING.LOCATION.ACCOUNTINPROGRESS, TRACKING.ACTION.GOTO, TRACKING.LOCATION.LOGIN);
    this.closeEvent.emit();
  }

}
