import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'bdo-svg-cms',
  templateUrl: './svg-cms.component.html',
  styleUrls: ['./svg-cms.component.scss']
})
export class SvgCmsComponent implements OnInit {

  @Input() iconSrc: string = '';
  @Input() iconSize: number = 90;
  public iconSizeInPx: string = '';

  constructor() { }

  ngOnInit(): void {
    this.iconSizeInPx = this.iconSize + 'px';
  }

}
