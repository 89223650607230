export const DOCUMENTGROUP = {
  'ACCOUNT' : 'Mein Kundenkonto',
  'PERSDATA' : 'Meine Daten',
  'CONTRACT' : 'Meine Verträge',
  'CONSUMPT' : 'Mein Verbrauch',
  'OPENITEM' : 'Offene Forderungen',
  'INVOICE' : 'Rechnungen',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'ALL' : 'Alle Dokumente'
};
