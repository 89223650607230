<div class="re-container-headline re-mb-30 md:re-mb-45 lg:re-mb-60">
  <h3>
    {{ (isMove ? 'move.recentHome.title' : 'contract.end.recentHome.title') | translate }}
  </h3>
</div>

<div class="re-container-with-margin re-mb-45 re-ml-20 md:re-mb-60 md:re-ml-auto" *ngIf="!isMove">
  <div class="re-text-sm" bdoRouterLinkDirective bdoLinkTracking
       [trackingLocation]="trackingLocation"
       innerHTML="{{ 'contract.end.recentHome.description' | translate: { accountId: accountId } }}"
  ></div>
</div>

<h4 class="re-container-with-margin re-mb-30 re-ml-20 md:re-mb-60 md:re-ml-auto">
  {{ (isMove ? 'move.recentHome.address.title' : 'contract.end.recentHome.address.title') | translate}}
</h4>

<div class="re-mb-15 re-bdo-tile" *ngIf="accountId && address">
  <bdo-accountid-with-address
    [accountId]="accountId"
    [address]="address"
  ></bdo-accountid-with-address>
</div>

<form (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" [formGroup]="recentHomeForm" bdoScrollToInvalidControl>
  <div class="re-bg-white re-w-full re-px-20 re-py-30 re-text re-mb-75
              md:re-w-600 md:re-mx-auto md:re-pb-60 md:re-mb-90
              lg:re-w-900">
    <div class="re-form-item-container re-mb-0">
      <div class="re-relative">
        <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
      </div>

      <bdo-date-picker
        [noPreselection]="true"
        [selectedDate]="recentHomeForm?.get('moveoutDate').value"
        (dateSelected)="recentHomeForm?.get('moveoutDate').setValue($event)"
        [minDateForChanges]="minDate"
        [maxDateForChanges]="Utilities.maxDate"
        [labelText]="(isMove ? 'move.recentHome.address.endDate' : 'contract.end.recentHome.address.endDate') | translate"
        [requiredErrorText]="'move.recentHome.address.endDateRequired' | translate"
        [forceValidate]="forceValidate"
        [attr.data-qa_mindateforchanges]="minDate"
        [attr.data-qa_selecteddate]="recentHomeForm?.get('moveoutDate').value">
      </bdo-date-picker>

    </div>
  </div>

  <!-- Meterreadings for recent home-->
  <div class="re-container-with-margin re-mt-20 re-mb-45 re-ml-20 md:re-mb-60 md:re-ml-auto">
    <h4 class="re-mb-20 md:re-mb-30">
      {{ (isMove ? 'move.recentHome.meterreadings.title' : 'contract.end.recentHome.meterreadings.title') | translate }}
    </h4>
    <div class="re-text-sm"
         innerHTML="{{ (isMove ? 'move.recentHome.meterreadings.description' : 'contract.end.recentHome.meterreadings.description') | translate }}"
    ></div>
  </div>

  <div *ngIf="stateRecentMeters === LoadingState.LOADING">
    <div class="re-bg-white re-w-full re-h-80 md:re-w-600 md:re-mx-auto lg:re-w-900"></div>
    <div class="re-bg-white re-w-full re-h-80 re-mb-20 md:re-w-600 md:re-mx-auto lg:re-w-900">
      <div class="re-relative">
        <bdo-loading *ngIf="stateRecentMeters === LoadingState.LOADING"></bdo-loading>
      </div>
    </div>
  </div>

  <div *ngFor="let division of divisions">
    <h5 class="re-container-with-margin re-mb-20">
      {{ 'general.tariff.meterWithDivision' | translate: { division: division | verbrauchstyp } }}
    </h5>
    <div *ngFor="let meter of meters">
      <div *ngIf="meter.sparte === division">
        <bdo-recent-meter-readings
          [meter]="meter"
          [parentForm]="recentHomeForm"
          (readingDataChange)="onReadingChange($event)"
        >
        </bdo-recent-meter-readings>
      </div>
    </div>
  </div>


  <div class="re-w-full
              md:re-w-600 md:re-mx-auto
              lg:re-w-900">
    <div class="md:re-pl-0 re-pl-15">
      <bdo-validate-submit
        [isContextSummary]="context === CONTEXT_FROM_SUMMARY"
        [textButtonLong]="(isMove ? 'wizard.button.newHome' : 'wizard.button.payment') | translate"
      ></bdo-validate-submit>
      <div class="re-pt-15">
        <bdo-text-icon
          [iconPosition]="'left'"
          [text]="'general.action.back' | translate"
          (itemClicked)="location.back()"
        ></bdo-text-icon>
      </div>
    </div>
  </div>
</form>
