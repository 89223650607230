export class DateUtils {
  public static isIso8601(value: any) {
    const iso8601Regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z?/;
    return typeof value === 'string' && iso8601Regex.test(value);
  }

  public static convertToDate(object: any): any {
    if (!object || typeof object !== 'object') {
      return object;
    }

    for (const key of Object.keys(object)) {
      const value = object[key];
      if (this.isIso8601(value)) {
        object[key] = new Date(value);
      } else if (typeof value === 'object') {
        this.convertToDate(value);
      }
    }

    return object;
  }
}
