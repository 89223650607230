<div class="bdo-container re-mb-30
            md:re-mb-60">
  <h3>{{ 'contract.new.address.title' | translate }}</h3>
</div>

<div class="bdo-container--single re-mb-30 md:re-mb-45 md:re-text-base re-leading-2xl">
  {{ 'contract.new.address.description' | translate }}
</div>

<div *ngIf="state === LoadingState.LOADING">
  <div class="re-bg-white re-w-full re-h-80 re-mb-20 md:re-w-600 md:re-mx-auto lg:re-w-900 re-relative">
    <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
  </div>
</div>
<div class="bdo-container--single">
  <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">
    {{ 'general.error.error' | translate }}
  </bdo-info-box>
</div>

<div class="re-mb-45 md:re-mb-60">
  <bdo-switch-card
    [configuration]="configuration"
    [selectedTile]="selected"
    (selected)="onSelect($event)"
  ></bdo-switch-card>
</div>


<ng-container  *ngIf="selected === TILESTATE.OTHER; else current">
  <div class="bdo-container--single re-mt-30 md:re-mt-60">
    <h4 class="re-mb-15 md:re-mb-10">{{ 'contract.new.address.other.title' | translate }}</h4>
    <div class="re-mb-30 md:re-text-base re-leading-2xl">{{ 'contract.new.address.other.description' | translate }}</div>
  </div>

  <form
    [formGroup]="form"
    (ngSubmit)="save()"
    (keydown.enter)="$event.preventDefault()"
    bdoScrollToInvalidControl
    [bdoScrollToInvalidControlForm]="form">
    <div class="re-bg-white re-w-full re-p-20 re-py-45 re-text re-mb-45
                md:re-w-600 md:re-mx-auto md:re-pt-20 md:re-pb-60 md:re-mb-60
                lg:re-w-900">
      <fieldset class="re-mb-0 md:re-mt-45">
        <bdo-address-form></bdo-address-form>
      </fieldset>
    </div>
    <div class="bdo-container--single re-mt-45 re-mb-20 md:re-mt-60">
      <bdo-validate-submit
        [textButtonLong]="'wizard.button.products' | translate"
      ></bdo-validate-submit>
    </div>
  </form>
</ng-container>

<ng-template #current>
  <div class="bdo-container--single re-mb-20" *ngIf="selected === TILESTATE.CURRENT">
    <bdo-validate-submit
      (click)="save()"
      [textButtonLong]="'wizard.button.products' | translate"
      data-qa="save_btn"
    ></bdo-validate-submit>
  </div>
</ng-template>

<div class="bdo-container--single">
  <bdo-text-link-icon
    [iconPosition]="'left'"
    [linkText]="'general.action.back' | translate"
    [routerUrl]="'/dashboard'"
    [tracking]="[TRACKING.LOCATION.CONTRACT_NEW_ADDRESS, TRACKING.ACTION.CLICK]"
  ></bdo-text-link-icon>
</div>
