import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../../services/storage.service';
import { CUSTOMERMODE } from '../../../../enums/customerMode';
import { StoragePersonalData } from '../../../../models/storagePersonalData';

@Component({
  selector: 'bdo-personal-data-summary',
  templateUrl: './personal-data-summary.component.html'
})
export class PersonalDataSummaryComponent implements OnInit {
  public personalData: StoragePersonalData;
  public isGewe: boolean;
  private mode: CUSTOMERMODE;

  ngOnInit(): void {
    this.personalData = StorageService.getPersonalData();
    this.mode = StorageService.getTariffData()?.[0]?.mode as CUSTOMERMODE;
    this.isGewe = this.mode === CUSTOMERMODE.GEWE;
  }

}
