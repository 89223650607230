import { Component, OnInit } from '@angular/core';
import { TRACKING } from '../../enums/trackingParts.enum';
import { TrackingService } from '../../services/tracking.service';

@Component({
  selector: 'bdo-contract-cancel-status',
  templateUrl: './contract-cancel-status.component.html',
  styleUrls: ['./contract-cancel-status.component.scss']
})
export class ContractCancelStatusComponent implements OnInit {
  public TRACKING = TRACKING;

  constructor(
    private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_CANCEL_STATUS, TRACKING.ACTION.ENTER);
  }



}
