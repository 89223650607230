/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TariffPriceBonuses { 
    /**
     * title of bonus
     */
    description?: string;
    /**
     * brief description of bonus
     */
    payment?: string;
    /**
     * indicates if this is a one time bonus or a recurring one
     */
    onetime?: boolean;
    /**
     * baseGross or net value of bonus
     */
    value?: number;
    /**
     * information text about bonus taken from tariff information
     */
    informationText?: string;
}

