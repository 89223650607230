import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cutMinus'
})
/**
 * Deletes Minus in front
 */
export class CutMinusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (typeof value === 'number' && value < 0) {
      value = Math.abs(value);
    } else if (typeof value === 'string' && value.startsWith('-')) {
      value = value.toString().slice(1);
    }
    return value;
  }

}
