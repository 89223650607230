import { Component } from '@angular/core';
import { PollQuestionComponent } from '../poll-question/poll-question.component';

@Component({
  selector: 'bdo-agree-question',
  templateUrl: './agree-question.component.html',
  styleUrls: ['./agree-question.component.scss']
})
export class AgreeQuestionComponent extends PollQuestionComponent {

}
