import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  UntypedFormControl,
  FormControlDirective
} from '@angular/forms';
import { Injector, Input, ViewChild, Directive } from '@angular/core';
import { Observable, ReplaySubject, Subscription } from 'rxjs';


// Helper types

/**
 * Extract arguments of function
 */
export type ArgumentsType<F> = F extends (...args: infer A) => any ? A : never;

/**
 * Creates an object like O. Optionally provide minimum set of properties P which the objects must share to conform
 */
type ObjectLike<O extends object, P extends keyof O = keyof O> = Pick<O, P>;

/*
* Extract a touched changed observable from an abstract control
* @param control AbstractControl like object with markAsTouched method
*/
export const extractTouchedChanges = (control: ObjectLike<AbstractControl, 'markAsTouched' | 'markAsUntouched'>): Observable<boolean> => {
  const prevMarkAsTouched = control.markAsTouched;
  const prevMarkAsUntouched = control.markAsUntouched;

  const touchedChanges$ = new ReplaySubject<boolean>(1);

  function nextMarkAsTouched(...args: ArgumentsType<AbstractControl['markAsTouched']>) {
    touchedChanges$.next(true);
    prevMarkAsTouched.bind(control)(...args);
  }

  function nextMarkAsUntouched(...args: ArgumentsType<AbstractControl['markAsUntouched']>) {
    touchedChanges$.next(false);
    prevMarkAsUntouched.bind(control)(...args);
  }

  control.markAsTouched = nextMarkAsTouched;
  control.markAsUntouched = nextMarkAsUntouched;

  return touchedChanges$;
};


@Directive()
export class ControlValueAccessorConnectorDirective implements ControlValueAccessor {
  @ViewChild(FormControlDirective, { static: true })
  public formControlDirective: FormControlDirective;
  @Input() formControlName: string;
  @Input()
  set formControl (formControl: UntypedFormControl | AbstractControl) {
    if (!this._formControl) {
      this._formControl = formControl;
    }
    this._formControl = formControl;

    this.touchedChangedSubcription = extractTouchedChanges(this.control).subscribe({ next: (value) => {
      this.onChange(value);
    } });

    this.inputName = Object.keys(this.formControl.parent.controls).find(
      (control) => this.formControl.parent.controls[control] === this.control);
    this.dataQa = 'i-' + this.inputName;
  }
  get formControl () {
    return this._formControl;
  }

  get control() {
    return this.formControl || this.controlContainer.control.get(this.formControlName);
  }

  public dataQa: string;
  public inputName: string;
  public touchedChangedSubcription: Subscription;
  private _formControl: UntypedFormControl | AbstractControl;

  constructor(private injector: Injector) {
  }

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  registerOnTouched(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnTouched(fn);
  }

  registerOnChange(fn: any): void {
    this.formControlDirective.valueAccessor.registerOnChange(fn);
  }

  writeValue(obj: any): void {
    this.formControlDirective.valueAccessor.writeValue(obj);
  }

  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
  }

  onChange(value) {}
}
