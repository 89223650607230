<div class="bdo-container re-mb-50 md:re-table">
  <div class="md:re-table-row">
    <div class="md:re-table-cell md:re-align-middle md:re-max-w-700">
      <h3 class="bdo-space--bottom">{{ 'construction.title' | translate }}</h3>

      <div class="md:re-hidden bdo-space--bottom">
        <ng-container *ngTemplateOutlet="picture"></ng-container>
      </div>

      <p class="re-copytext-with-margin">
        {{ 'construction.description' | translate }}
      </p>

      <a *ngIf="linkToContactRouter; else externalLink" [routerLink]="linkToContactRouter" class="bdo-button-primary">
        {{ 'general.navigation.linkContactForm' | translate }}
      </a>

      <ng-template #externalLink>
        <a *ngIf="linkToContactHref" [href]="linkToContactHref" class="bdo-button-primary">
          {{ 'general.navigation.linkContactForm' | translate }}
        </a>
      </ng-template>
    </div>

    <div class="re-hidden md:re-table-cell re-align-middle bdo-space--bottom">
      <ng-container *ngTemplateOutlet="picture"></ng-container>
    </div>
  </div>
</div>


<ng-template #picture>
  <img class="icon bdo-space--bottom" src="{{ 'construction.img' | translate }}" alt="">
</ng-template>
