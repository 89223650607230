import { Component, Input } from '@angular/core';
import { Srcs } from '../../models/switchCardConfiguration';

@Component({
  selector: 'bdo-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})

export class CardComponent {

  @Input() title: string;
  @Input() first: boolean;
  @Input() subtitle: string;
  @Input() icon: Srcs;
  @Input() selected: boolean;

  constructor() {
  }

}
