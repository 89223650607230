/**
 * Stringifies a comment.
 *
 * Empty comment lines are left empty,
 * lines consisting of a single space are replaced by `#`,
 * and all other lines are prefixed with a `#`.
 */
const stringifyComment = str => str.replace(/^(?!$)(?: $)?/gm, '#');
function indentComment(comment, indent) {
  if (/^\n+$/.test(comment)) return comment.substring(1);
  return indent ? comment.replace(/^(?! *$)/gm, indent) : comment;
}
const lineComment = (str, indent, comment) => str.endsWith('\n') ? indentComment(comment, indent) : comment.includes('\n') ? '\n' + indentComment(comment, indent) : (str.endsWith(' ') ? '' : ' ') + comment;
export { indentComment, lineComment, stringifyComment };