import { Component, Input, OnInit } from '@angular/core';
import { HomeData } from '../../../../models/home';
import { ALLOWED_KEYS, StorageService } from '../../../../services/storage.service';
import { ZaehlerIdentifier, Zaehlerstand } from '../../../../../../assets/js/com/ts_api_client';

export interface MeterReadingWithSmartMeter {
  meterReading: Zaehlerstand;
  isSmartMeter: boolean;
}

@Component({
  selector: 'bdo-old-home-summary',
  templateUrl: './old-home-summary.component.html',
  styleUrls: ['./old-home-summary.component.scss']
})
export class OldHomeSummaryComponent implements OnInit {
  @Input() contextContractEnd: boolean = false;
  public data: HomeData;
  public meterReadings: Array<MeterReadingWithSmartMeter> = [];

  ngOnInit(): void {
    this.data = StorageService.getValue<HomeData>(ALLOWED_KEYS.RECENT_HOME_DATA);

    const moveOutMeterReadings: Array<Zaehlerstand> = this.data?.meterData;
    const moveOutMeters: Array<ZaehlerIdentifier> = this.data?.meters;
    moveOutMeters?.map((meter: ZaehlerIdentifier) => {
      const result = moveOutMeterReadings.filter(reading => {
        return reading.meterNumber === meter.meterNumber;
      });
      this.meterReadings.push({
        meterReading: result[0],
        isSmartMeter: meter.isSmartMeter
      });
    });

    this.meterReadings.sort((a, b) => {
      return a.meterReading?.sparte === 'S4' && b.meterReading?.sparte !== 'S4' ? -1 : 1;
    });
  }
}
