import { Injector } from '@angular/core';
import { MaintenanceService } from '../bdo/services/maintenance.service';
import { lastValueFrom } from 'rxjs';
import { BackendStatus } from '../bdo/enums/backendStatus';
import { DateUtils } from '../shared/utils/dateUtils';
import { map } from 'rxjs/operators';

export function checkMaintenance(injector: Injector) {
  return () => lastValueFrom(
    injector.get(MaintenanceService).checkBackendStatus()
      .pipe(
          // we don't use the date part of the checkBackendStatus response yet, but in case we use it in the future, we need to convert its dates to Date-Objects
          //map((statusResponse) => DateUtils.convertToDate(statusResponse))
      ),
    {
      defaultValue: {
        status: BackendStatus.Unavailable
      }
    }
  );
}
