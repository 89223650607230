import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookiebannerComponent } from './cookiebanner/cookiebanner.component';
import { SharedModule } from '../shared/shared.module';

/* We need this wrapper module to enable Custom Elements Schema only for web components and keep it off for everything else */

@NgModule({
  declarations: [
    CookiebannerComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    CookiebannerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WebComponentsWrapperModule { }
