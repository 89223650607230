import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BillingPlan, BillingPlanItem } from '../../../../../assets/js/com/ts_api_client';
import { INPUT_TYPE } from '../../../enums/inputType.enum';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { DropdownItem } from '../../../models/dropdownItem';
import { BdoApiService } from '../../../services/bdo-api.service';
import { CustomerStoreService } from '../../../services/customer-store.service';
import { DetailedBillingPlan, DetailedBillingPlanItem } from '../../../services/detailed-billingplan.service';
import { TariffAdvisorService } from '../../../services/tariff-advisor.service';
import { TrackingService } from '../../../services/tracking.service';
import { Subscription } from 'rxjs';
import { VERBRAUCHSTYP } from '../../../enums/verbrauchstyp.enum';
import { StorageService } from '../../../services/storage.service';
import { Utilities } from '../../../../shared/utils/utilities';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'bdo-billing-edit',
  templateUrl: './billing-edit.component.html',
  styleUrls: ['./billing-edit.component.scss']
})
export class BillingEditComponent implements OnInit, OnDestroy {
  @Input() detailedBillingPlan: DetailedBillingPlan;
  @Output() done: EventEmitter<boolean> = new EventEmitter(); // done with or without changes
  public INPUT_TYPE = INPUT_TYPE;
  public billingDayDropdown: Array<DropdownItem>;
  public stateGlobal: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public curSelectedDay: string;
  public savingDone: boolean = false;
  public validateAll: boolean = false;
  public accountId: string;
  public formgroup = new FormGroup({});
  public newTotalAmount: number;
  protected readonly StorageService = StorageService;
  protected readonly Utilities = Utilities;
  private subscription = new Subscription();

  constructor(
    private trackingService: TrackingService,
    public apiService: BdoApiService,
    public toastService: ToastService,
    public tariffAdvisorService: TariffAdvisorService,
    private customerStore: CustomerStoreService,
  ) { }

  public ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.BILLING_EDIT, TRACKING.ACTION.ENTER);
    this.accountId = this.customerStore.getAccountId();
    this.billingDayDropdown = [
      new DropdownItem('05', '5. des Monats'),
      new DropdownItem('20', '20. des Monats')
    ];

    this.setBillingPlanDay();

    this.subscription.add(this.formgroup.valueChanges.subscribe({ next: () => {
      this.stateGlobal = LOADING_STATE.IDLE;
      // calculate the new total amount
      let newTotal: number = 0;
      this.detailedBillingPlan.billingPlanItemSet.forEach((billingAmount: DetailedBillingPlanItem) => {
        newTotal += parseInt(this.formgroup.get('amount-' + billingAmount.contractId + '-' + billingAmount.division)?.value, 10);
      });
      this.newTotalAmount = newTotal;
    } }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public saveBilling(): void {
    this.formgroup.updateValueAndValidity();

    if (this.formgroup.invalid)  {
      // mark all input fields as touched to display validation info
      this.formgroup.markAllAsTouched();
      return;
    }

    // get the amount for each division in each contract
    let cumulAmount: number = 0;
    this.detailedBillingPlan.billingPlanItemSet.forEach((billingItem: DetailedBillingPlanItem) => {
      billingItem.amount = parseFloat(this.formgroup.get('amount-' + billingItem.contractId + '-' + billingItem.division).value);
      cumulAmount += billingItem.amount;

      this.trackingService.postTracking(TRACKING.LOCATION.BILLING_EDIT, TRACKING.ACTION.SAVE);
    });

    const newBillingPlan: BillingPlan = {
      billingPlanDay: this.curSelectedDay,
      cumulAmount,
      billingPlanItemSet: this.detailedBillingPlan.billingPlanItemSet
    };

    this.stateGlobal = LOADING_STATE.LOADING;
    this.apiService.putBillingPlan(newBillingPlan)
    .subscribe(
      {
        next: (res) => {
          this.detailedBillingPlan.billingPlanItemSet = [];
          newBillingPlan?.billingPlanItemSet.map((item: BillingPlanItem) => {
            const itemToDetailed: DetailedBillingPlanItem = {
              billingPlanID: item.billingPlanID,
              contractId: item.contractId,
              division: item.division as VERBRAUCHSTYP,
              amount: item.amount
            };
            this.detailedBillingPlan.billingPlanItemSet.push(itemToDetailed);
          });

          this.detailedBillingPlan.billingPlanDay = newBillingPlan?.billingPlanDay;
          this.toastService.pushToast({
            headline: 'Abschlag erfolgreich geändert',
            dataQa: 'success'
          });
          this.closeMessage();
          this.stateGlobal = LOADING_STATE.IDLE;
        },
        error: () => {
          this.stateGlobal = LOADING_STATE.ERROR;
        }
      });
  }

  public closeMessage() {
    this.savingDone = false;
    this.done.emit(true);
  }


  public onCancel() {
    this.done.emit(false);
  }

  private setBillingPlanDay() {
    // If the user has a planDay other than 5 or 20, he may keep it
    if (this.detailedBillingPlan && this.detailedBillingPlan.billingPlanDay !== '05' && this.detailedBillingPlan.billingPlanDay !== '20') {
      let day = this.detailedBillingPlan.billingPlanDay;
      day = (+day).toString(); // Remove leading zero for display
      this.billingDayDropdown.unshift(
        new DropdownItem(this.detailedBillingPlan.billingPlanDay, day + '. des Monats')
      );
    }
    this.curSelectedDay = this.detailedBillingPlan?.billingPlanDay;

  }

}
