<div class="re-w-full re-bg-white re-relative md:re-w-600 md:re-mx-auto md:re-mb-0 lg:re-w-900"
     [ngClass]="{'re-px-20 re-py-30 md:re-px-30 md:re-py-45': showFrame,
               're-mb-1': isEditable}">

  <ng-content></ng-content>
  <div class="re-absolute re-right-0 re-top-0 re-mr-20 re-mt-20">
    <button class="re-w-65 re-h-65 re-hidden md:re-inline-block
          hover:re-text-white hover:re-bg-secondary-hover focus:re-text-white focus:re-bg-secondary-hover
            disabled:re-opacity-60 disabled:re-text-brand-primary disabled:re-bg-transparent disabled:re-cursor-not-allowed"
      [class.re-mr-10]="isEditable"
      data-qa="delete-desktop"
      [disabled]="disabled"
      [title]="'general.action.delete' | translate"
      (click)="onDelete()"
      *ngIf="isDeletable">
      <div class="re-h-30 re-w-30 re-mx-auto">
        <svg-icon src="{{ 'general.img.delete' | translate }}"></svg-icon>
      </div>
    </button>
    <button class="re-button-square-icon re-hidden md:re-inline-block"
      data-qa="edit-desktop"
      [disabled]="disabled"
      [title]="'general.action.edit' | translate"
      (click)="onEdit()"
      *ngIf="isEditable">
      <div class="re-h-30 re-w-30 re-mx-auto">
        <svg-icon src="{{ 'general.img.edit' | translate }}"></svg-icon>
      </div>
    </button>
  </div>

</div>
<button class="re-bg-white re-mb-1 re-px-20 re-py-15 re-w-full re-text-left md:re-hidden hover:re-cursor-pointer re-group
                disabled:re-text-opacity-60 disabled:re-cursor-not-allowed disabled:re-text-brand-primary"
        data-qa="delete-mobile"
        [disabled]="disabled"
        (click)="onDelete()"
        *ngIf="isDeletable">
  <div class="re-inline-block bold">{{ 'general.action.delete' | translate }}</div>
  <div class="re-h-30 re-w-30 re-ml-10 re-inline-block" [ngClass]="disabled ? '' : 'group-hover:re-text-secondary-hover'">
    <svg-icon class="icon" src="{{ 'general.img.delete' | translate }}"></svg-icon>
  </div>
</button>
<button class="re-bg-white re-px-20 re-py-15 re-w-full re-text-left md:re-hidden hover:re-cursor-pointer re-group
                disabled:re-text-opacity-60 disabled:re-cursor-not-allowed disabled:re-text-brand-primary"
        data-qa="edit-mobile"
        [disabled]="disabled"
        (click)="onEdit()"
        *ngIf="isEditable">
  <div class="re-inline-block bold">{{ 'general.action.edit' | translate }}</div>
  <div class="re-h-30 re-w-30 re-ml-10 re-inline-block" [ngClass]="disabled ? '' : 'group-hover:re-text-secondary-hover'">
    <svg-icon src="{{ 'general.img.edit' | translate }}"></svg-icon>
  </div>
</button>
