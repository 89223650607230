import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../../../services/storage.service';
import { VerbrauchstypPipe } from '../../../../pipes/verbrauchstyp.pipe';
import { MetersCheckResult, SituationData } from '../../../../../../assets/js/com/ts_api_client';
import { DELIVERY_TYPE } from '../../../../enums/deliveryType';

@Component({
  selector: 'bdo-situation-summary',
  styleUrls: ['./situation-summary.component.scss'],
  templateUrl: './situation-summary.component.html'
})
export class SituationSummaryComponent implements OnInit {
  public situationData: SituationData;
  public divisionTitle: string;
  public DeliveryType = DELIVERY_TYPE;
  public meterData: MetersCheckResult[];

  constructor(private verbrauchstypPipe: VerbrauchstypPipe) { }

  ngOnInit(): void {
    this.situationData = StorageService.getSituationData();
    this.divisionTitle = this.verbrauchstypPipe.transform(StorageService.getTariffData()?.[0]?.divisionId);
    if (this.situationData?.meterData) {
      this.meterData = Object.entries(this.situationData.meterData).map(([, checkResult]) => checkResult);
    }
  }

}
