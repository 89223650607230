import { Component, Input } from '@angular/core';
import { Forderung } from '../../../../../assets/js/com/ts_api_client';
import { CustomerStoreService } from '../../../services/customer-store.service';
import { BankAccountAssignments } from '../banking-tile/models/bank-account-assignments.interface';
import { TRACKING } from '../../../enums/trackingParts.enum';

@Component({
  selector: 'bdo-payment-tile',
  templateUrl: './payment-tile.component.html',
  styleUrls: ['./payment-tile.component.scss']
})
export class PaymentTileComponent {
  @Input() payments: Forderung;
  @Input() isInactive: boolean = false;
  @Input() bankAccountAssignments: BankAccountAssignments;
  public TRACKING = TRACKING;

  constructor(
    public customerStore: CustomerStoreService
  ) { }

}
