import { Component, OnInit } from '@angular/core';
import { PersonalData, SituationData } from '../../../../../../assets/js/com/ts_api_client';
import { ALLOWED_KEYS, StorageService } from '../../../../services/storage.service';

@Component({
  selector: 'bdo-new-home-summary',
  templateUrl: './new-home-summary.component.html',
  styleUrls: ['./new-home-summary.component.scss']
})
export class NewHomeSummaryComponent implements OnInit {
  public personalData: PersonalData;
  public newHomeData: SituationData;
  public hasEmail: boolean = true;

  ngOnInit(): void {
    this.personalData = StorageService.getValue<PersonalData>(ALLOWED_KEYS.PERSONAL_DATA);
    this.newHomeData = StorageService.getValue<SituationData>(ALLOWED_KEYS.SITUATION_DATA);
    this.hasEmail = StorageService.getValue<boolean>(ALLOWED_KEYS.HAS_EMAIL);
  }

}
