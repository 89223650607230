import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PollQuestion } from '../../../../../assets/js/com/ts_api_client';
import { POLLQUESTIONTYPE } from '../../../../bdo/enums/pollQuestionType.enum';

@Component({
  selector: 'bdo-poll-question',
  templateUrl: './poll-question.component.html',
  styleUrls: ['./poll-question.component.scss']
})
export class PollQuestionComponent {
  @Input() questionData: PollQuestion;
  @Output() sendAnswer: EventEmitter<string> = new EventEmitter();
  public POLLQUESTIONTYPE = POLLQUESTIONTYPE;

}
