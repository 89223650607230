import { Pipe, PipeTransform } from '@angular/core';
import { ABLESEART } from '../enums/ableseart';

@Pipe({
  name: 'mapAbleseart'
})
/** Maps IDs of 'Ableseart' to customer-readable Strings */
export class MapAbleseartPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const mappedValue = ABLESEART[value];

    if (!mappedValue) {
      return 'Undefiniert';
    } else {
      return mappedValue;
    }

  }

}
