<div class="re-container-headline re-mb-30 re-mt-20
            md:re-mb-45 md:re-mt-35
            lg:re-mb-60">
  <h3 data-qa="title">
    {{ (hasOfferContext ? 'offer.personalData.title' : 'delivery.personalData.title') | translate }}
  </h3>
</div>
<div *ngIf="hasOfferContext"
  class="re-block re-mx-20 re-mb-45 re-mt-30
        md:re-w-600 md:re-mx-auto md:re-mt-60
        lg:re-w-900 re-copytext">
  {{ 'delivery.personalData.description' | translate }}
</div>

<!-- shown on all backend errors -->
<div class="bdo-container--single">
  <div [class.re-mb-15]="state === LoadingState.ERROR" data-qa="errorbox">
    <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">
      {{ 'general.error.error' | translate }}
    </bdo-info-box>
  </div>
</div>

<form (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" bdoScrollToInvalidControl [formGroup]="personalDataForm">

  <div class="re-bg-white re-w-full re-p-20 re-pb-5 re-text
              md:re-w-600 md:re-mx-auto md:re-pb-45
              lg:re-w-900"
      [ngClass]="hasOfferContext ?
                  're-mb-30' :
                  're-mb-60 md:re-mb-75'">
    <div class="re-container__content re-mb-20 re-pt-0
                lg:re-mb-30 lg:re-pt-20">
      <h5>
        {{ 'delivery.personalData.subtitle' | translate }}
      </h5>
    </div>

    <fieldset>
      <div class="re-form-item-container">
        <bdo-dropdown
          [dataQa]="'select_title'"
          [data]="titleDropdown"
          [required]="true"
          [selected]="(personalDataForm.get('title').valueChanges | async)"
          [forceValidate]="forceValidate"
          [requiredErrorText]="'address.salutation.salutationRequired' | translate"
          [labelText]="'address.salutation.title' | translate"
          (blur)="trackInvalid('name')"
          (itemSelected)="personalDataForm.get('title').setValue($event)"
        ></bdo-dropdown>
      </div>

      <div class="re-form-item-container" data-qa="name1" *ngIf="isGewe">
        <bdo-input [formControl]="$any(personalDataForm.get('name1'))"
                   [inputType]="inputTypeMappings.name1"
                   [maxlength]="40"
                   [validationIconHidden]="state === LoadingState.ERROR"
                   [labelText]="'address.personalInfo.name1' | translate"
                   (blur)="trackInvalid('name1')"
        ></bdo-input>
      </div>

      <div class="re-form-item-container" data-qa="name2" *ngIf="isGewe">
        <bdo-input [formControl]="$any(personalDataForm.get('name2'))"
                   [inputType]="inputTypeMappings.name2"
                   [maxlength]="40"
                   [validationIconHidden]="state === LoadingState.ERROR"
                   [labelText]="('address.personalInfo.name2Optional' | translate)"
                   (blur)="trackInvalid('name2')"
        ></bdo-input>
      </div>

      <div class="re-form-item-container" data-qa="name" *ngIf="!isGewe">
        <bdo-input [formControl]="$any(personalDataForm.get('name'))"
                   [inputType]="inputTypeMappings.name"
                   [validationIconHidden]="state === LoadingState.ERROR"
                   [labelText]="'address.personalInfo.firstname' | translate"
                   (blur)="trackInvalid('name')"
                   autocomplete="given-name"
        ></bdo-input>
      </div>

      <div class="re-form-item-container" data-qa="surname" *ngIf="!isGewe">
        <bdo-input [formControl]="$any(personalDataForm.get('surname'))"
                   [inputType]="inputTypeMappings.surname"
                   [maxlength]="35"
                   [validationIconHidden]="state === LoadingState.ERROR"
                   [labelText]="'address.personalInfo.surname' | translate"
                   (blur)="trackInvalid('surname')"
                   autocomplete="family-name"
        ></bdo-input>
      </div>

      <div class="re-form-item-container" *ngIf="isGewe" data-qa="sectors">
        <bdo-dropdown
          [data]="sectors"
          [required]="true"
          [selected]="(personalDataForm.get('sectorId').valueChanges | async)"
          [forceValidate]="forceValidate"
          [requiredErrorText]="'address.business.sector.required' | translate"
          [labelText]="'address.business.sector.title' | translate"
          (blur)="trackInvalid('sectorId')"
          (itemSelected)="personalDataForm.get('sectorId').setValue($event)"
          ></bdo-dropdown>
      </div>

      <div class="re-form-item-container" *ngIf="isGewe" data-qa="legalEntities">
        <bdo-dropdown
          [data]="legalEntities"
          (itemSelected)="personalDataForm.get('legalEntityId').setValue($event)"
          [required]="true"
          [selected]="(personalDataForm.get('legalEntityId').valueChanges | async)"
          [forceValidate]="forceValidate"
          [requiredErrorText]="'address.business.legalEntity.required' | translate"
          [labelText]="'address.business.legalEntity.title' | translate"
          (blur)="trackInvalid('legalEntityId')"
          ></bdo-dropdown>
      </div>

      <div class="re-form-item-container" *ngIf="isGewe" data-qa="commercialRegisterNr">
        <bdo-input [formControl]="$any(personalDataForm.get('commercialRegisterNr'))"
                   [inputType]="inputTypeMappings.commercialRegisterNr"
                   [maxlength]="30"
                   [validationIconHidden]="state === LoadingState.ERROR"
                   [labelText]="'address.business.commercialRegisterNr' | translate"
                   (blur)="trackInvalid('commercialRegisterNr')"
        ></bdo-input>
      </div>

      <div class="re-form-item-container" *ngIf="isGewe" data-qa="nameCo">
        <bdo-input [formControl]="$any(personalDataForm.get('nameCo'))"
                   [inputType]="inputTypeMappings.nameCo"
                   [maxlength]="40"
                   [validationIconHidden]="state === LoadingState.ERROR"
                   [labelText]="'address.personalInfo.nameCo' | translate"
                   (blur)="trackInvalid('nameCo')"
        ></bdo-input>
      </div>

      <div class="re-form-item-container" *ngIf="!isGewe" data-qa="birthdate">
        <bdo-input [formControl]="$any(personalDataForm.get('birthdate'))"
                   [inputType]="inputTypeMappings.birthdate"
                   [maxlength]="35"
                   [validationIconHidden]="state === LoadingState.ERROR"
                   [labelText]="'address.personalInfo.birthdate.title' | translate"
                   [help]="'address.personalInfo.birthdate.hint' | translate"
                   [mask]="'date'"
                   (blur)="trackInvalid('birthdate')"
        ></bdo-input>
      </div>

      <div class="re-form-item-container">
        <bdo-input [formControl]="$any(personalDataForm.get('phonenumber'))"
                   [inputType]="inputTypeMappings.phonenumber"
                   [maxlength]="35"
                   [validationIconHidden]="state === LoadingState.ERROR"
                   [labelText]="'address.phonenumber.title' | translate"
                   [tooltipContent]="'delivery.personalData.phoneNumberHint' | translate"
                   [help]="'delivery.personalData.phoneNumberHintShort' | translate"
                   (blur)="trackInvalid('phonenumber')"
          ></bdo-input>
      </div>

      <div class="re-form-item-container">
        <bdo-input #emailInput
                   [formControl]="$any(personalDataForm.get('email'))"
                   [inputType]="inputTypeMappings.email"
                   [validationIconHidden]="state === LoadingState.ERROR"
                   [labelText]="'address.email.title' | translate"
                   (blur)="trackInvalid('email')">
        </bdo-input>
      </div>
      <div class="re-flex">
        <div class="re-text-error re-h-20 re-w-20 re-mr-10">
          <svg-icon class="re-w-20 re-h-20 re-block" src="assets/icons/wizard_steps/checkmark.svg"></svg-icon>
        </div>
        <div>{{ 'delivery.personalData.emailBenefit' | translate }}</div>
      </div>

    </fieldset>
    <div class="re-flex re-flex-row re-items-center re-relative re-container__content re--mt-5 re-mb-30
              lg:re-mt-0">
      <span class="re-mt-2
                  lg:re-mt-0">
        <h5>
          {{ 'delivery.personalData.titleAddress' | translate }}
        </h5>
      </span>
      <span *ngIf="hasOfferContext" class="re-ml-10 re-flex">
        <bdo-inline-tooltip class="re-flex re-self-center">
          <div [innerHTML]="'delivery.personalData.noAddressChange' | translate"></div>
        </bdo-inline-tooltip>
      </span>
    </div>


    <!--
    ADDRESS FORM
    -->
    <bdo-address [orderForm]="personalDataForm" id="address"></bdo-address>

  </div>

  <ng-container *ngIf="!hasOfferContext">
    <div class="re-container-headline re-mb-30
                md:re-w-600 md:re-mx-auto
                lg:re-w-900">
      <h4>
        {{ 'register.delivery.title' | translate }}
      </h4>
    </div>
    <div class="re-bg-white re-w-full re-p-20 re-text re-mb-30
                md:re-w-600 md:re-mx-auto md:re-p-45 md:re-pb-5
                lg:re-w-900">
      <div class="re-container__content">
        <bdo-quick-register-form [personalDataForm]="personalDataForm" [registrationData]="registrationData"></bdo-quick-register-form>
      </div>
    </div>
  </ng-container>


  <div class="re-w-full
              md:re-w-600 md:re-mx-auto
              lg:re-w-900">
    <div class="re-pl-15
                md:re-pl-0">
      <bdo-validate-submit
        [isContextSummary]="context === CONTEXT_FROM_SUMMARY && (!addressHasChanged() || allMetersAreUnknown)"
        [textButtonLong]="'wizard.button.supply' | translate"
      ></bdo-validate-submit>
      <div class="re-pt-20">
        <bdo-text-icon
          [iconPosition]="'left'"
          [text]="'general.action.back' | translate"
          (itemClicked)="location.back()"
        >
        </bdo-text-icon>
      </div>
    </div>
  </div>
</form>
