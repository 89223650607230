"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Variation = /** @class */function () {
  function Variation(_a) {
    var id = _a.id,
      deviation = _a.deviation,
      respoolTime = _a.respoolTime,
      customJson = _a.customJson;
    this.id = id;
    this.deviation = deviation;
    this.respoolTime = respoolTime;
    this.customJson = customJson;
  }
  return Variation;
}();
exports.default = Variation;