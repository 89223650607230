import { Component, Input, OnDestroy } from '@angular/core';
import { NgxTippyProps, NgxTippyService } from 'ngx-tippy-wrapper';

@Component({
  selector: 'bdo-inline-tooltip',
  templateUrl: './inline-tooltip.component.html',
  styleUrls: ['./inline-tooltip.component.scss']
})
/** See https://github.com/farengeyt451/ngx-tippy-wrapper for more options */
export class InlineTooltipComponent implements OnDestroy {
  @Input() tippyProps: NgxTippyProps = {
    animation: false,
    allowHTML: true,
    interactive: true,
    trigger: 'click',
    maxWidth: 410,
    onShow: (instance) => {
      instance.reference.firstElementChild.classList.add('re-text-primary');
      instance.popper.querySelector('.close').addEventListener('click', () => {
        instance.hide();
      });
    },
    onHide: (instance) => {
      instance.reference.firstElementChild.classList.remove('re-text-primary');
      instance.popper.querySelector('.close').removeEventListener('click', null);
    }
  };
  @Input() name: string;
  @Input() size: 'default' | 'large' = 'default';

  constructor(private tippyService: NgxTippyService) { }

  ngOnDestroy(): void {
    if (this.name) {
      try {
        this.tippyService.destroy(this.name);
      } catch (error) {
        // tippy cannot destroy, because it might have been destroyed automatically
      }
    }
  }
}
