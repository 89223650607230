import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TrackingService } from '../../bdo/services/tracking.service';
import { LoginService } from '../login.service';
import { REGISTER_ERROR } from '../../bdo/enums/registerError.enum';
import { BdoApiService } from '../../bdo/services/bdo-api.service';
import { TRACKING } from '../../bdo/enums/trackingParts.enum';
import * as AccountIdValidator from '../../shared/validators/accountId-validator';

@Component({
  selector: 'bdo-username-forgot',
  templateUrl: './username-forgot.component.html',
  styleUrls: ['./username-forgot.component.scss']
})
export class UsernameForgotComponent implements OnInit {
  public TRACKING = TRACKING;
  errorShown: boolean = false;
  sendingData: boolean = false;
  validateAll: boolean = false;
  validationIconHidden: boolean = false;
  accountId: string = '';
  meterNumber: string = '';
  recognizedUsername: string = '';

  RegisterErrorEnum = REGISTER_ERROR;
  registerError: string = REGISTER_ERROR.NONE;

  constructor(
    private trackingService: TrackingService,
    private loginService: LoginService,
    private apiService: BdoApiService,
    private router: Router
  ) { }

  ngOnInit() {
    this.trackingService.postTracking(TRACKING.LOCATION.USERNAME_FORGOT, TRACKING.ACTION.ENTER);
  }

  send(form: any) {
    this.validateAll = true;
    if (form.valid) {
      this.sendingData = true;
      this.loginService.checkIdentification(this.accountId, this.meterNumber)
      .subscribe(
        {
          next: (res) => {
            this.sendingData = false;
            if (res.status === 'SUCCESS') {
              if (res.info && res.info.length > 0) {
                // TODO: show Popup with Name
                this.recognizedUsername = res.info;
                this.trackingService.postTracking(TRACKING.LOCATION.USERNAME_FORGOT, TRACKING.ACTION.SUCCESS);
              } else { // Valid Combination but not yet registered
                this.registerError = 'Not_yet_registered';
                this.showRegisterError(this.registerError as REGISTER_ERROR);
              }
            } else { // STATUS: ERROR
              this.registerError = res.info;
              this.showRegisterError(this.registerError as REGISTER_ERROR);
            }
          },
          error: () => {
            this.showRegisterError('Error' as REGISTER_ERROR);
          }
        });
    }
  }

  onFocus() {
    this.registerError = REGISTER_ERROR.NONE;
    this.validationIconHidden = false;
  }

  containsOnlyDigits(value: string): boolean {
    if (!value) { return true; }
    return /^\d+$/.test(value);
  }

  isValidAccountId(value: string): boolean {
   return AccountIdValidator.isValidAccountId(value);
  }

  showRegisterError(registerError: REGISTER_ERROR) {
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.USERNAME_FORGOT, TRACKING.ACTION.FAILURE + registerError);
    this.registerError = registerError;
    this.validationIconHidden = true;
  }

  closeMessage() {
    this.trackingService.postTracking(TRACKING.LOCATION.USERNAME_FORGOT, TRACKING.ACTION.GOTO, TRACKING.LOCATION.LOGIN);
    this.router.navigate(['/login'], { state: { username: this.recognizedUsername } });
  }

}
