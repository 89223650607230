/** Alert Type */
export enum ALERT_TYPE {
  DEMAND_OVERDUE = 'DEMAND_OVERDUE',
  PAYMENT_REMINDER = 'PAYMENT_REMINDER',
  MISSING_MAIL_ADDRESS = 'MISSING_MAIL_ADDRESS',
  MISSING_SEPA = 'MISSING_SEPA',
  TOS_CHANGED = 'TOS_CHANGED',
  NONE = ''
}

export enum ALERT_TEXT {
  DEMAND_OVERDUE = 'Es sind noch nicht alle fälligen Zahlungen beglichen, evtl. haben Sie bereits eine Mahnung ' +
    'erhalten, bitte sorgen Sie schnellstmöglich für einen Ausgleich.',
  PAYMENT_REMINDER = 'Ihre nächste Zahlung ist demnächst fällig, bitte denken Sie daran, rechtzeitig zu bezahlen.',
  MISSING_MAIL_ADDRESS = 'Leider ist uns Ihre E-Mail-Adresse nicht bekannt. Bitte ergänzen Sie diese, damit Sie ' +
    'unseren Service vollumfänglich nutzen können.',
  MISSING_SEPA = 'Sie haben uns noch kein SEPA-Mandat erteilt. Möchten Sie dies ändern?',
  TOS_CHANGED = '<div class="bold bdo-space--bottom">Neue Nutzungsbedingungen zum 19.10.2020</div>' +
    '<div>Ab sofort erhalten Sie alle Ihre Dokumente und Rechnungen direkt in Ihre Postbox - sicher und umweltschonend, ohne Papier!</div>',
  NONE = ''
}
