import { Location } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { birthdateValidator } from '../../../../shared/validators/birthdate-validator';
import { defaultValidatorProxy } from '../../../../shared/validators/default-validator-proxy';
import { externalEmailValidator } from '../../../../shared/validators/external-email-validator';
import { phonenumberValidator } from '../../../../shared/validators/phonenumber-validator';
import { INPUT_TYPE } from '../../../enums/inputType.enum';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { DropdownItem } from '../../../models/dropdownItem';
import { BdoApiService } from '../../../services/bdo-api.service';
import { StorageService } from '../../../services/storage.service';
import { TrackingService } from '../../../services/tracking.service';
import { RegistrationData } from '../../../../../assets/js/com/ts_api_client';
import { birthdateFullAgeValidator } from '../../../../shared/validators/birthdate-fullage-validator';
import { CONTEXT_FROM_SUMMARY } from '../summary/summary.component';
import { CUSTOMERMODE } from '../../../enums/customerMode';
import punycode from 'punycode/';
import moment from 'moment';
import { PersonalDataFormtype } from '../../../../shared/formtypes/personal-data.formtype';
import { KameleoonService } from '../../../services/kameleoon.service';
import { filter, first } from 'rxjs/operators';
import { StoragePersonalData } from '../../../models/storagePersonalData';
import { NavigationState } from '../../../models/navigationState';
import { Utilities } from '../../../../shared/utils/utilities';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-personal-data-form',
  templateUrl: './personal-data-form.component.html',
  styleUrls: ['./personal-data-form.component.scss']
})
export class PersonalDataFormComponent implements OnInit, OnDestroy, AfterViewChecked {

  public CUSTOMERMODE: CUSTOMERMODE;
  public LoadingState = LOADING_STATE;
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public titleDropdown: Array<DropdownItem>;
  public TRACKING = TRACKING;
  public CONTEXT_FROM_SUMMARY = CONTEXT_FROM_SUMMARY;
  public registrationData: RegistrationData;

  public hasOfferContext: boolean = !!StorageService.getOfferContext();

  public personalDataForm = new FormGroup<PersonalDataFormtype>({
    title: new FormControl('',
    [
      defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required'))
    ]),
    name: new FormControl('',
    [
      defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
      defaultValidatorProxy(Validators.maxLength(35), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 35 }))
    ]),
    surname: new FormControl('',
    [
      defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
      defaultValidatorProxy(Validators.maxLength(35), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 35 }))
    ]),
    birthdate: new FormControl('',
    [
      defaultValidatorProxy(Validators.required,this.translateService.instant('general.validator.required')),
      birthdateValidator(this.translateService),
      defaultValidatorProxy(Validators.maxLength(35), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 35 })),
      birthdateFullAgeValidator(this.translateService)
    ]),
    phonenumber: new FormControl('',
    [
      defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
      phonenumberValidator(this.translateService),
      defaultValidatorProxy(Validators.maxLength(35), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 35 }))
    ]),
    email: new FormControl('',
    [
      defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
      defaultValidatorProxy(Validators.email, this.translateService.instant('address.validator.validEmail')),
      externalEmailValidator(this.translateService),
      defaultValidatorProxy(Validators.maxLength(241), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 241 })),
    ])
  });
  public forceValidate = false;
  public inputTypeMappings = {
    'title': INPUT_TYPE.SELECT,
    'name': INPUT_TYPE.TEXT,
    'surname': INPUT_TYPE.TEXT,
    'birthdate': INPUT_TYPE.TEXT,
    'phonenumber': INPUT_TYPE.TEL,
    'email': INPUT_TYPE.EMAIL,

    // gewe only
    'name1': INPUT_TYPE.TEXT,
    'name2': INPUT_TYPE.TEXT,
    'nameCo': INPUT_TYPE.TEXT,
    'commercialRegisterNr': INPUT_TYPE.TEXT,
    'sectorId': INPUT_TYPE.SELECT,
    'legalEntityId': INPUT_TYPE.SELECT,
  }; // Needed for Tracking

  public context: string;
  public allMetersAreUnknown: boolean;
  public sectors: Array<DropdownItem>;
  public legalEntities: Array<DropdownItem>;
  public isGewe: boolean = false;

  private subscriptions = new Subscription();
  private mode: CUSTOMERMODE;
  private personalData: StoragePersonalData;

  constructor(
    public location: Location,
    private apiService: BdoApiService,
    private trackingService: TrackingService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private kameleoonService: KameleoonService,
    private translateService: TranslateService
  ) {
    const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
    this.context = currentState?.context;
    if (this.context) {
      this.allMetersAreUnknown = StorageService.getSituationData()?.meterData?.every((meter) => meter.exists === false);
    }
  }

  public ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.DELIVERY_PERSONALDATA, TRACKING.ACTION.ENTER);
    this.trackingService.postFormTracking(TRACKING.FORM.DELIVERY_PERSONALDATA_NAME, TRACKING.FORM_ACTION.VIEW);
    this.mode = StorageService.getTariffData()?.[0]?.mode as CUSTOMERMODE;
    this.isGewe = this.mode === CUSTOMERMODE.GEWE;
    this.personalData = StorageService.getPersonalData();
    this.subscriptions
      .add(this.apiService.getSalutations(this.isGewe ? CUSTOMERMODE.GEWE : CUSTOMERMODE.PRIV).subscribe( { next: res => {
        this.titleDropdown = res.list.map(
          salutation => new DropdownItem(salutation.id, this.translateService.instant('address.salutation.options.' + salutation.id))
        );
        if (this.readTitle()) {
          this.personalDataForm.get('title').setValue(this.titleDropdown
            .map((item) => item?.value)
            .find((value) => value === this.readTitle())
          );
        }
      } }));

    if (this.isGewe) {
      this.apiService.getSectors().subscribe({
        next: (sector) => {
          this.sectors = sector.list
            // A-Z
            .sort((a, b) => {
              return a.text < b.text ? -1 : 1;
            })
            .map(sectorItem => new DropdownItem(sectorItem.sectorId, sectorItem.text));
          // preselect possible value of storage
          const sectorId = this.personalData?.sectorId;
          if (sectorId) {
            this.personalDataForm.get('sectorId').setValue(this.sectors
              .map((item) => item?.value)
              .find((value) => value === sectorId)
            );
          }
        },
        error: () => this.setError('getSectors')
      });
      this.apiService.getLegalEntities().subscribe({
        next: (legalEntity) => {
          this.legalEntities = legalEntity.list
            // A-Z and push Sonstige at the end of the list
            .sort((a, b) => {
              if (a.text === 'Sonstige') {
                return 1;
              }
              return a.text < b.text ? -1 : 1;
            })
            .map(entity => new DropdownItem(entity.legalEntityId, entity.text));
          // preselect possible value of storage
          const legalEntityId = this.personalData?.legalEntityId;
          if (legalEntityId) {
            this.personalDataForm.get('legalEntityId').setValue(this.legalEntities
              .map((item) => item?.value)
              .find((value) => value === legalEntityId)
            );
          }
        },
        error: () => this.setError('getLegalEntities')
      });

      // add extra controls for gewe
      this.personalDataForm.addControl('legalEntityId', new FormControl('',
        [ defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required'))]
      ));
      this.personalDataForm.addControl('sectorId', new FormControl('',
        [ defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required'))]
      ));
      this.personalDataForm.addControl('commercialRegisterNr', new FormControl(''));
      this.personalDataForm.addControl('nameCo', new FormControl(''));
      this.personalDataForm.removeControl('birthdate');
      this.personalDataForm.removeControl('name');
      this.personalDataForm.removeControl('surname');

      const name1FormField = new FormControl('');
      name1FormField.setValidators([
        defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
        defaultValidatorProxy(Validators.maxLength(35), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 35 })),
      ]);
      this.personalDataForm.addControl('name1', name1FormField);

      const name2FormField = new FormControl('');
      name2FormField.setValidators([
        defaultValidatorProxy(Validators.maxLength(35), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 35 })),
      ]);
      this.personalDataForm.addControl('name2', name2FormField);

    }

    if (this.personalData) {
      // addressData is set in the addressComponent
      const dataWithoutAddress: StoragePersonalData = this.personalData;
      this.registrationData = dataWithoutAddress?.registrationData;
      delete dataWithoutAddress?.registrationData;
      delete dataWithoutAddress.addressData;
      delete dataWithoutAddress['sectorName'];
      delete dataWithoutAddress['legalEntityName'];
      this.personalDataForm.patchValue(dataWithoutAddress as any);
    }
  }
  //
  // public invalid() {
  //   return Utilities.findInvalidControlsRecursive(this.personalDataForm);
  // }

  public ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public readTitle() {
    return this.personalData?.title;
  }

  public setError(trackingType: string) {
    this.trackingService.postSimpleTracking(trackingType, TRACKING.ACTION.FAILURE);
    this.state = LOADING_STATE.ERROR;
  }

  public trackInvalid(inputName: string) {
    const formControl = this.personalDataForm.get(inputName);
    const inputType = this.inputTypeMappings[inputName];

    if (formControl.invalid) {
      this.trackFormError(inputName, inputType);
    }
  }

  public trackFormError(inputName: string, inputType: string) {
    this.trackingService.postFormTracking(
      TRACKING.FORM.DELIVERY_PERSONALDATA_NAME,
      TRACKING.FORM_ACTION.ERROR,
      TRACKING.FORM.DELIVERY_PERSONALDATA_SECTION_DATA,
      inputName,
      inputType
    );
  }
  public back() {
    this.location.back();
  }

  public addressHasChanged() {
    return this?.personalDataForm?.get(['addressData', 'street'])?.dirty || this?.personalDataForm?.get(['addressData', 'housenumber'])?.dirty;
  }

  public save(): void {
    this.forceValidate = true;
    if (this.personalDataForm.invalid)  {
      // mark all input fields as touched to display validation info
      this.personalDataForm.markAllAsTouched();
      this.trackingService.postSimpleTracking(TRACKING.FORM.DELIVERY_PERSONALDATA_NAME, ' invalid');
      // Iterate over all invalid values
      Object.keys(this.personalDataForm.controls).forEach(key => {
        this.trackInvalid(key);
      });
      return;
    } else {
      this.trackingService.postFormTracking(TRACKING.FORM.DELIVERY_PERSONALDATA_NAME, TRACKING.FORM_ACTION.SUBMIT);
      this.trackingService.postSimpleTracking(TRACKING.LOCATION.DELIVERY_PERSONALDATA, TRACKING.ACTION.GOTO, TRACKING.LOCATION.DELIVERY_SITUATION);
      // Save Data and goto next page (when all data is set)
      const formData: StoragePersonalData = {};
      Object.keys(this.personalDataForm.controls).forEach(key => {
        formData[key] = this.personalDataForm.controls[key].value;
      });
      // addressData may be disabled, so get it anyway
      const addrData = this.personalDataForm?.getRawValue()['addressData'];
      delete addrData['cityDisplay'];
      formData['addressData'] = addrData;

      if (formData['legalEntityId']) {
        formData['legalEntityName'] = this.legalEntities.find((item) => item.value === formData['legalEntityId'])?.label;
      }
      if (formData['sectorId']) {
        formData['sectorName'] = this.sectors.find((item) => item.value === formData['sectorId'])?.label;
      }

      // email convert vowel mutations
      formData['email'] = punycode.toUnicode(formData['email']);

      formData.housenumIsChangeable = this.personalData?.housenumIsChangeable;
      formData.streetIsChangeable = this.personalData?.streetIsChangeable;
      StorageService.setPersonalData(formData);
      this.kameleoonService.kameleoonLoaded$.pipe(filter((loaded) => !!loaded), first()).subscribe({ next: () => {
        this.kameleoonService.processConversion(KameleoonService.Goals.PASSED_PERSONAL_DATA_PAGE);
      } });
      if (this.context === CONTEXT_FROM_SUMMARY &&
        (!this.addressHasChanged() || this.allMetersAreUnknown)
      ) {
        this.router.navigate(['../uebersicht'],  {
          relativeTo: this.activatedRoute
        });
      // changed adress and meters were known -> go to situation and then to summary
      } else if (this.context === CONTEXT_FROM_SUMMARY && this.addressHasChanged() && !this.allMetersAreUnknown) {
        this.router.navigate(['../situation'],  {
          relativeTo: this.activatedRoute,
          state: {
            context: CONTEXT_FROM_SUMMARY
          }
        });
      } else {
        this.router.navigate(['../situation'],  {
          relativeTo: this.activatedRoute
        });
      }
    }
  }
}
