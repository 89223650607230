import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FullAddress, PartnerEmail, PersonalDataAddressData } from '../../../../../assets/js/com/ts_api_client/model/models';
import { defaultValidatorProxy } from '../../../../shared/validators/default-validator-proxy';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { AuthDataStorage } from '../../../models/AuthData.storage';
import { BdoApiService } from '../../../services/bdo-api.service';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import punycode from 'punycode/';
import { BillingAddressFormData } from '../../../models/billingAddressFormData';
import { TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-contract-cancel-address',
  templateUrl: './contract-cancel-address.component.html',
  styleUrls: ['./contract-cancel-address.component.scss']
})
export class ContractCancelAddressComponent implements OnInit {
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public accountId: string = '';
  public address: PersonalDataAddressData;
  public firstname: string = '';
  public surname: string = '';
  public form = new FormGroup({
    emailAddress: new FormControl<string>('',
    [
      defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required'))
    ])
  });
  private authMeterNumber: string = '';
  private initialEmail: string = '';

  constructor(
    private translateService: TranslateService,
    private apiService: BdoApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_CANCEL_BILLING, TRACKING.ACTION.ENTER);
    const authData: AuthDataStorage = StorageService.getValue<AuthDataStorage>(ALLOWED_KEYS.AUTH_DATA);
    this.accountId = authData?.checkIdentificationData?.accountId;
    this.authMeterNumber = authData?.checkIdentificationData?.meterNumberOrRegisterCode;

    const data: BillingAddressFormData = StorageService.getValue<BillingAddressFormData>(ALLOWED_KEYS.BILLING_ADDRESS);
    if (data) {
      this.form.get('emailAddress').setValue(data.email);
      this.initialEmail = data.email;
      this.address = {
        city: data.cityName,
        housenumber: data.houseNum,
        postCode: data.postCode,
        street: data.streetName
      };
      this.firstname = data.recentRecipientPersons[0].firstname;
      this.surname = data.recentRecipientPersons[0].lastname;
    } else {
      this.state = LOADING_STATE.LOADING;

      this.apiService.getAddressAnonymous(this.accountId, this.authMeterNumber)
        .subscribe(
          {
            next: (resAddress: FullAddress) => {
              this.address = {
                street: resAddress?.postalAddress?.street?.name,
                housenumber: resAddress?.postalAddress?.houseNum,
                postCode: resAddress?.postalAddress?.street?.city?.postCode,
                city: resAddress?.postalAddress?.street?.city?.name
              };
              this.firstname = resAddress?.postalAddress?.name1;
              this.surname = resAddress?.postalAddress?.name2;
            },
            error: () => {
              this.state = LOADING_STATE.ERROR;
            }
          }
        );

      this.apiService.getEmailAnonymous(this.accountId, this.authMeterNumber)
        .subscribe(
          {
            next: (email: PartnerEmail) => {
              this.form.get('emailAddress')?.setValue(email?.email);
              this.initialEmail = email?.email;
              this.state = LOADING_STATE.IDLE;
            },
            error: () => {
              this.state = LOADING_STATE.ERROR;
            }
          }
        );
    }
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else {
      if (this.initialEmail !== this.form.get('emailAddress').value) {
        this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_CANCEL_BILLING, TRACKING.ACTION.SET, 'E-Mail');
      }
      const data: BillingAddressFormData = {
        email: punycode.toUnicode(this.form.get('emailAddress').value),
        streetName: this.address.street,
        houseNum: this.address.housenumber,
        postCode: this.address.postCode,
        cityName: this.address.city,
        recentRecipientPersons: [{
          firstname: this.firstname,
          lastname: this.surname
        }],
        keepRecipient: true,
        recentRecipientOrg: null
      };
      StorageService.setValue(ALLOWED_KEYS.BILLING_ADDRESS, data);

      this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_CANCEL_BILLING, TRACKING.ACTION.GOTO, 'zusammenfassung');
      this.router.navigate(['../uebersicht'], { relativeTo: this.activatedRoute });
    }
  }
}
