<div class="bdo-container re-mb-45">
  <h3>
    {{ 'meterreadings.title' | translate }}
  </h3>
</div>

<div class="bdo-container--single re-mb-45 md:re-mb-90">

  <div class="re-mb-45 md:re-mb-30 re-bg-white re-p-30">
    <bdo-accountid-with-address></bdo-accountid-with-address>
  </div>

  <!-- Erfassen -->
  <div *ngIf="meterDropdown?.length > 0" class="re-bg-white re-p-30 md:re-pb-45" [ngClass]="isEditMode ? 're-mb-3' : 're-mb-60'">
    <div class="re-relative" [class.bdo-loading__wrapper--height]="state === LoadingState.LOADING">
      <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
    </div>
    <div [ngClass]="{ 're-container__content': meterDropdown?.length > 1 }" *ngIf="state !== LoadingState.LOADING">
      <bdo-dropdown
        [data]="meterDropdown"
        [labelText]="'general.customerData.meternumber' | translate"
        [selected]="selectedItem"
        (itemSelected)="updateMeters($event)">
      </bdo-dropdown>
      <button *ngIf="addMeterreadingShown && !isEditMode && !disabled"
        class="bdo-button-primary re-mt-30 md:re-mt-45"
        type="button"
        data-qa="add_btn"
        (click)="toggleAddMeterreading(false)">
        <div class="re-text-center">{{ 'meterreadings.actions.enterMeterreading' | translate }}</div>
      </button>
    </div>
  </div>

  <div id="addReadingSection" *ngIf="isEditMode" class="re-mb-60 re-bg-white re-p-30 md:re-pb-45" bdoScrollOnInit>
    <p class="re-form-item-container bold re-mb-15 md:re-mb-30">{{'meterreadings.actions.addNewReadingTitle' | translate}}</p>
    <bdo-zaehlerstand-eingabe-form
      [submittedMeterNumber]="selectedMeter"
      [zaehleridentifiers]="meterIdentifiers"
      [(selectedDate)]="selectedDate"
      (done)="toggleAddMeterreading($event, true);"
      (saved)="toggleAddMeterreading($event, false, true);"
    ></bdo-zaehlerstand-eingabe-form>
  </div>

  <!-- Zählerstände und Verbräuche -->
  <h4 class="re-mb-30">{{ 'meterreadings.history.title' | translate }}</h4>

  <div *ngIf="state !== LoadingState.LOADING && meterDropdown?.length === 0" class="re-bg-white re-px-15 re-py-30 re-mb-10 md:re-px-30 lg:re-mb-0">
    {{ 'general.error.noDataFound' | translate }}
  </div>

  <div class="re-relative"
    [class.bdo-loading__wrapper--height]="state === LoadingState.LOADING">
    <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
    <bdo-zaehlerstand-historie-tabelle
      *ngIf="state !== LoadingState.LOADING"
      [ablesungen]="meterHistory?.ablesungen"
      [zaehlerNummer]="meterHistory?.meterNumber"
      (edit)="onEdit($event)"
    ></bdo-zaehlerstand-historie-tabelle>
  </div>
  <div class="re-mt-15 md:re-mt-30 empty-no-margin">
    <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">
      {{ 'general.error.error' | translate }}
    </bdo-info-box>
  </div>

</div>

<bdo-faqs
  [keyName]="'meterreadings.faqs'"
  [params]="{ contactLink: tenantService.getCurrentTenantData().hrefContact ,
              phoneContactLink: tenantService.getCurrentTenantData().hrefPhoneContact }"
></bdo-faqs>

<bdo-poll-frame *ngIf="showPoll && !toastService.currentToast() && !isFromSiminvoice"
                (closeEvent)="showPoll = false">
  <bdo-customer-satisfaction-poll
    [pollId]="2"
    [triggerPoint]="'Zaehlerstand'">
  </bdo-customer-satisfaction-poll>
</bdo-poll-frame>
