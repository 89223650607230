import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { DeliveryTracking, TrackingService } from '../../../services/tracking.service';
import { BdoApiService } from '../../../services/bdo-api.service';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { AddressData, Contract, StatusResponse, TariffData, TariffSummaryResults } from '../../../../../assets/js/com/ts_api_client';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { Location } from '@angular/common';
import { LegalData } from '../../../models/legalData';
import { VerbrauchstypPipe } from '../../../pipes/verbrauchstyp.pipe';
import { DELIVERY_TYPE } from '../../../enums/deliveryType';
import { Md5 } from 'ts-md5/dist/md5';
import { CUSTOMERMODE } from '../../../enums/customerMode';
import { TariffAdvisorService } from '../../../services/tariff-advisor.service';
import { PathAndDivision } from './legal-summary/legal-summary.component';
import { DatadogService } from '../../../services/datadog.service';
import { StoragePersonalData } from '../../../models/storagePersonalData';
import { TranslateService } from '@ngx-translate/core';

export const CONTEXT_FROM_SUMMARY = 'CONTEXT_FROM_SUMMARY';

export enum SUBMISSION_ERRORS {
 WRITE_CONTRACT_FAILED = 'WRITE_CONTRACT_FAILED',
 REGISTRATION_FAILED = 'REGISTRATION_FAILED',
 UNKNOWN_ERROR = 'UNKNOWN_ERROR',
 WRITE_CONTRACT_FAILED_ALREADY_APPLIED = 'WRITE_CONTRACT_FAILED_ALREADY_APPLIED',
 WRITE_CONTRACT_FAILED_OTHER_CONTRACT = 'WRITE_CONTRACT_FAILED_OTHER_CONTRACT',
 WRITE_CONTRACT_INVALID_SCHUFA = 'WRITE_CONTRACT_INVALID_SCHUFA'
}

@Component({
  selector: 'bdo-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  public tariffData: TariffData;
  public state = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public termsOfConditionsAccepted: boolean = false;
  public rightOfWithdrawalAccepted: boolean = false;
  public communicationAccepted: boolean = false;
  public errorMessage: string = null;
  public validateCheckboxes: boolean = false;
  public isGewe: boolean = false;
  public isHeatpump: boolean = false;
  public pathAndDivisions: Array<PathAndDivision> = [];
  public addressData: AddressData;
  public personalData: StoragePersonalData;
  public hasVouchers: boolean = false;
  private orderId: string;
  private tariffSummary: TariffSummaryResults;

  constructor(
    public tracking: TrackingService,
    public translateService: TranslateService,
    public location: Location,
    private apiService: BdoApiService,
    private router: Router,
    private dataDogService: DatadogService,
    private verbrauchstypPipe: VerbrauchstypPipe,
    private activatedRoute: ActivatedRoute,
    private tariffAdvisorService: TariffAdvisorService
  ) { }

  ngOnInit(): void {
    this.tracking.postTracking(TRACKING.LOCATION.DELIVERY_SUMMARY, TRACKING.ACTION.ENTER);
    this.tariffData = StorageService.getTariffData()?.[0];
    this.personalData = StorageService.getPersonalData();
    this.hasVouchers = StorageService.getOfferVouchers()?.list?.length > 0;

    if (!this.tariffData) {
      this.dataDogService.trackCustomEvent('delivery__summary__tariffDataMissing');
    }
    if (!this.personalData) {
      this.dataDogService.trackCustomEvent('delivery__summary__personalDataMissing');
    }
    this.addressData = {
      postCode: this.personalData?.addressData?.postCode,
      cityName: this.personalData?.addressData?.city,
      streetName: this.personalData?.addressData?.street,
      houseNum: this.personalData?.addressData?.housenumber
    };
    this.isGewe = this.tariffData?.mode === CUSTOMERMODE.GEWE;
    this.isHeatpump = this.tariffData?.tariff?.toLowerCase().includes('wärmepumpe');
  }

  onChangeTermsOfConditions(accepted: boolean) {
    this.termsOfConditionsAccepted = accepted;
    StorageService.setProperty<LegalData>(ALLOWED_KEYS.LEGAL_DATA, 'termsOfConditionsAccepted', this.termsOfConditionsAccepted);
  }

  onChangeRightOfWithdrawalAccepted(accepted: boolean) {
    this.rightOfWithdrawalAccepted = accepted;
    StorageService.setProperty<LegalData>(ALLOWED_KEYS.LEGAL_DATA, 'rightOfWithdrawalAccepted', this.rightOfWithdrawalAccepted);
  }

  onChangeCommunicationAccepted(accepted: boolean) {
    this.communicationAccepted = accepted;
    StorageService.setProperty<LegalData>(ALLOWED_KEYS.LEGAL_DATA, 'communicationAccepted', this.communicationAccepted);
  }

  /**
   *
   * @param wizardStep Number of the wizard step to go to
   * @param fragment part of the destination page
   */
  onEdit(wizardStep: number, fragment: string) {
    this.tracking.postSimpleTracking(TRACKING.LOCATION.DELIVERY_SUMMARY, TRACKING.ACTION.GOTO, 'edit-' + fragment);
    switch (wizardStep) {
      case 1:
        this.router.navigate(['../persoenliche-daten'], {
          relativeTo: this.activatedRoute,
          fragment,
          state: {
            context: CONTEXT_FROM_SUMMARY
          }
        });
        break;
      case 3:
        this.router.navigate(['../zahlungsart'],  {
          relativeTo: this.activatedRoute,
          state: {
            context: CONTEXT_FROM_SUMMARY
          }
        });
        break;
      case 2:
        this.router.navigate(['../situation'],  {
          relativeTo: this.activatedRoute,
          state: {
            context: CONTEXT_FROM_SUMMARY
          }
        });
        break;
      default:
        break;
    }
  }

  submit() {
    this.validateCheckboxes = true;
    if (!this.termsOfConditionsAccepted || !this.rightOfWithdrawalAccepted) {
      return;
    }
    const trackingPixelData = StorageService.getTrackingPixelData();

    const tariffData: Array<TariffData> = StorageService.getTariffData();
    const selectedTariff = StorageService.getTariffSelections();
    tariffData[0].campaignId = trackingPixelData?.re_channel;
    tariffData[0].resellerId = trackingPixelData?.re_partnerid;
    tariffData[0].tariff ??= selectedTariff[0]?.selectedTariff?.name; // Must be set for offer context

    const contract: Contract = {
      paymentData: StorageService.getPaymentData(),
      situationData: StorageService.getSituationData(),
      tariffData: tariffData,
      personalData: StorageService.getPersonalData()
    };
    if (contract.paymentData?.bankData?.iban) {
      // remove special chars
      contract.paymentData.bankData.iban = contract.paymentData.bankData.iban.replace(/[^a-zA-Z0-9]/g, '');
    }
    this.orderId = this.generateOrderId(contract).toString();

    contract.tariffData.forEach(tariff => tariff.orderId = this.orderId);
    contract.personalData.emailOptIn = this.communicationAccepted;
    // Mapping old structure to new structure - TODO: Use new supplier method once situation page is updated
    contract.situationData.meterData.forEach( item => {
      item.previousSupplier = contract.situationData.previousSupplier;
      item.previousSupplierId = contract.situationData.previousSupplierId;
      item.termination = contract.situationData.termination;
    });
    contract.cooperationPartnerId = this.tariffAdvisorService.getCooperationPartnerId(
      contract?.personalData?.addressData?.postCode,
      [this.tariffData.productId]
    ),
    contract.acceptedOfferId = StorageService.getOfferId() ?? undefined;

    // Use the calculated price
    // contract.tariffData.proposedMonthlyPrice = this.tariffSummary?.costs?.divisionCosts[0]?.monthlyPriceGross.toString();
    this.state = LOADING_STATE.LOADING;
    this.apiService.postContractStart(contract).subscribe({
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      error: (error: {
        error: StatusResponse
      }) => {
        switch (error?.error?.info) {
          case (SUBMISSION_ERRORS.REGISTRATION_FAILED): {
            this.trackContract(contract);
            this.state = LOADING_STATE.IDLE;
            this.router.navigate(['../../danke'], {
              relativeTo: this.activatedRoute,
              state: {
                error: SUBMISSION_ERRORS.REGISTRATION_FAILED
              }
            });
          }
          break;
          case (SUBMISSION_ERRORS.UNKNOWN_ERROR):
          case (SUBMISSION_ERRORS.WRITE_CONTRACT_FAILED):
          case (SUBMISSION_ERRORS.WRITE_CONTRACT_FAILED_ALREADY_APPLIED):
          case (SUBMISSION_ERRORS.WRITE_CONTRACT_FAILED_OTHER_CONTRACT):
            this.errorMessage = this.translateService.instant('summary.error.' + error.error.info);
            this.state = LOADING_STATE.ERROR;
            break;
          case (SUBMISSION_ERRORS.WRITE_CONTRACT_INVALID_SCHUFA):
            this.state = LOADING_STATE.IDLE;
            this.router.navigate(['../../ablehnung'], {
              relativeTo: this.activatedRoute,
              state: {
                error: SUBMISSION_ERRORS.REGISTRATION_FAILED
              }
            });
            break;
          default:
            this.state = LOADING_STATE.ERROR;
            this.errorMessage = this.translateService.instant('general.error.error');
        }
        // In case of unexpected error
        if (!error?.error?.info) {
          this.state = LOADING_STATE.ERROR;
          this.errorMessage = this.translateService.instant('general.error.error');
        }
      },
      next: (contractStartResponse) => {
        this.trackContract(contract);
        StorageService.setTrustPilotToken(contractStartResponse.trustpilotSecureToken);
        this.errorMessage = null;
        this.state = LOADING_STATE.IDLE;
        this.router.navigate(['../../danke'], { relativeTo: this.activatedRoute });
      }
    });
  }

  public onTariffDataChange(tariffSummary: TariffSummaryResults) {
    this.tariffSummary = tariffSummary;
    this.pathAndDivisions = [{
      division: this.verbrauchstypPipe.transform(this.tariffData?.divisionId),
      path: this.tariffAdvisorService.getDocumentLink() + tariffSummary?.layer?.downloads.find(item => item.title.includes('Vertragsbedingungen')).link
    }];
  }

  private generateOrderId(contract: Contract) {
    return Md5.hashStr(JSON.stringify(contract));
  }

  private trackContract(contract: Contract) {
    // Only take one contract for now
    const tariffData = contract.tariffData[0];
    let annualConsumption = 0;
    annualConsumption = parseInt(tariffData.annualConsumption, 10);
    if (isNaN(annualConsumption)) {
      annualConsumption = 0;
    }
    const tracking: DeliveryTracking = {
      orca_order_id: `orca-${this.orderId}`,
      orca_product_id: tariffData.productId,
      orca_product_name: tariffData.tariff,
      orca_product_sparte: this.verbrauchstypPipe.transform(tariffData.divisionId, false),
      orca_jahresverbrauch: annualConsumption,
      orca_preis: tariffData.mode === CUSTOMERMODE.PRIV ?
                                                this.tariffSummary?.parts[0].periodicPrices.find(item => item.year === 1).monthlyPriceGross :
                                                this.tariffSummary?.parts[0].periodicPrices.find(item => item.year === 1).monthlyPriceNet,
      orca_kundenstatus: contract.situationData.deliveryType === DELIVERY_TYPE.CHANGE ? 'Neukunde ohne Versorger' : 'Umzug',
      orca_kundenart: contract?.tariffData[0].mode,
      orca_zahlungsart: contract.paymentData.bankData?.iban ? 'SEPA Mandat' : 'Überweisung',
      orca_meter_quantity: contract?.situationData?.meterData?.length,
      orca_campaign: !!StorageService.getOfferId() ? ('dta/' + StorageService.getOfferContext()) : ''
    };
    this.tracking.postDeliveryTracking(tracking, !!StorageService.getOfferId());
    StorageService.setProperty(ALLOWED_KEYS.TRACKINGPIXEL_DATA, 'orderId', tracking.orca_order_id);
    this.tracking.postSimpleTracking(TRACKING.LOCATION.DELIVERY_SUMMARY, TRACKING.ACTION.GOTO, TRACKING.LOCATION.DELIVERY_CONFIRMATION);
  }

}
