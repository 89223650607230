import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TariffSummaryResults } from '../../../../assets/js/com/ts_api_client';
import { TariffPriceAnnual } from '../../../shared/models/tariff-price.annual';
import { TariffAdvisorService } from '../../services/tariff-advisor.service';
import { CUSTOMERMODE } from '../../enums/customerMode';

@Component({
  selector: 'bdo-tariff-overlay',
  templateUrl: './tariff-overlay.component.html',
  styleUrls: ['./tariff-overlay.component.scss']
})
export class TariffOverlayComponent implements OnInit {

  @Input() prices: TariffPriceAnnual[];
  @Input() tariffData: TariffSummaryResults;
  @Input() isVisible: boolean = false;
  @Input() isGewe: boolean = false;
  @Output() close: EventEmitter<void> = new EventEmitter(); // eslint-disable-line @angular-eslint/no-output-native
  public hasBonus = false;
  public CUSTOMERMODE = CUSTOMERMODE;
  public meterTooltip: string;

  constructor(
    public tariffAdvisorService: TariffAdvisorService
  ) { }

  ngOnInit(): void {
    this.hasBonus = this.tariffData?.parts[0]?.contractPayments.some(elem => elem.description.length > 0);
    this.meterTooltip = this.tariffAdvisorService.getMeterTooltip(this.tariffData?.customerType);
  }

  onClose() {
    this.close.emit();
  }
}
