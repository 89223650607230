<a *ngIf="routerUrl; else externalLink" [routerLink]="[routerUrl]" class="re-bg-white re-w-full re-h-full re-flex re-items-center">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<ng-template #externalLink>
  <a [href]="href" [target]="openInNewTab ? '_blank' : '_self'" class="re-bg-white re-w-full re-h-full re-flex re-items-center">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-template>

<ng-template #content>
  <div class="re-w-full re-px-20 lg:re-px-30">
    <div class="re-flex re-justify-between">
      <h5 class="re-self-center re-inline-block">{{linkText}}</h5>
      <bdo-svg name="icon-arrow-right" styling="position-right" class="re-self-center"></bdo-svg>
    </div>
  </div>
</ng-template>
