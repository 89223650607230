import { Directive, HostListener, Input } from '@angular/core';
import { TrackingService } from '../services/tracking.service';

@Directive({
  selector: '[bdoLinkTracking]'
})
/**
 * Directive to use Angular Routing in Links from the tenantService
 * Add this to the container with an innerHtml containing a relative Angular Link
 */
export class LinkTrackingDirective {
  @Input() trackingLocation: string;
  @Input() useContent: boolean = true; // Use a content or take the url as the destination string

  constructor(
    private trackingService: TrackingService
  ) {}

  @HostListener('click', ['$event'])
  public onClick(e) {
    // detect closest a tag, if the a-tag contains another div tag that is recognized as the click target
    const element = (e.target.closest('[bdolinktracking] a'));

    if (element) {
      const href = element.getAttribute('href');
      const content = element.textContent;
      const destination = this.useContent ? content : href;
      this.trackingService.postSimpleTracking(this.trackingLocation, ' goto ' + destination);
    }
  }

}
