/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * phone, mobile or fax number of a person or business
 */
export interface PhoneIdentifier { 
    addressId?: string;
    /**
     * (Mobile)phone or fax number
     */
    number?: string;
    isDefault?: boolean;
}

