import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-validate-submit',
  templateUrl: './validate-submit.component.html',
  styleUrls: ['./validate-submit.component.scss']
})

export class ValidateSubmitComponent {

  @Input() textButtonLong: string = this.translateService.instant('general.action.continue');
  @Input() sameTextForAllViewports = false;
  @Input() isContextSummary: boolean = false;

  constructor(
    private translateService: TranslateService
  ) { }

}
