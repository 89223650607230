import { Component, Input } from '@angular/core';
import { TrackingService } from '../../../bdo/services/tracking.service';

@Component({
  selector: 'bdo-text-link-icon',
  templateUrl: './text-link-icon.component.html',
  styleUrls: ['./text-link-icon.component.scss']
})
export class TextLinkIconComponent {
  @Input() linkText: string = '';
  @Input() href: string = '';
  @Input() fragment: string;
  @Input() customIconSrc: string = '';
  @Input() iconPosition: 'right' | 'left' | ''  = '';
  @Input() routerUrl: string;
  @Input() extraData: { [key: string]: any };
  @Input() openInNewTab: boolean = false;
  @Input() tracking: Array<string> = [];
  @Input() size: 'lg' | 's' = 'lg';

  constructor(private trackingService: TrackingService) {}

  trackIt() {
    if (this.tracking?.length > 0) { this.trackingService.postSimpleTracking(...this.tracking); }
  }

}
