<div *ngIf="items" class="re-relative re-w-full re-z-30" [class.re-hidden]="!(show && focused)">
  <div [style.max-height]="state === LoadingState.ERROR ? '100px' : calculateHeight(items)"
       [style.height]="state === LoadingState.ERROR ? '100px' : calculateHeight(items)"
       [class.re-table]="state === LoadingState.ERROR"
       [class.re-overflow-scroll]="items.length > 6"
       class="re-absolute re-w-full re-overflow-hidden re-mb-3 re-overflow-x-hidden
              re-border-monochrome-dark re-bg-monochrome-light
              re-border re-border-1 re-border-t-0"
  >
    <div *ngIf="state === LoadingState.ERROR" class="suggestitem re-cursor-pointer hover:re-bg-monochrome-medium-light re-px-15 re-h-50 re-flex re-flex-row re-items-center"
      [class.re-table-cell]="state === LoadingState.ERROR"
      [class.re-align-middle]="state === LoadingState.ERROR"
    >
      <span class="no-results">{{ 'general.validator.autosuggestNoResult' | translate }}</span>
    </div>
    <div *ngFor="let item of items; let i = index;" #items
         (mouseenter)="markAsSelected(i)"
         (mouseleave)="markAsUnselected(i)"
         (mousedown)="onClick(i)"
         (touchend)="onClick(i)"
         (touchmove)="onSwipe(i)"
         [class.re-bg-monochrome-medium-light]="item.selected"
         class="suggestitem re-cursor-pointer re-px-15 re-h-50 re-flex re-flex-row re-items-center">
      <span class="re-truncate">
        <span [innerHTML]="item.before"></span><span [innerHTML]="item.searchstring" class="re-font-regular"></span><span [innerHTML]="item.after"></span>
      </span>
    </div>
  </div>
</div>
