<div class="re-container-headline re-mb-30 md:re-mb-60">
  <h3 class="h3">
    {{'contract.cancel.status.title' | translate}}
  </h3>
</div>
<p class="re-container-with-margin re-mb-30 re-copytext"
  [innerHTML]="'contract.cancel.status.description' | translate"
></p>

<div class="bdo-link-tile-group">

  <bdo-link-tile
    [title]="'contract.cancel.status.cancelTitle' | translate"
    [linktext]="'contract.cancel.status.cancelLink' | translate"
    [iconSrc]="{default: 'assets/icons/bdo/home-out_75x75.svg'}"
    [destination]="'/abmelden'"
    [trackingLocation]="TRACKING.LOCATION.CONTRACT_CANCEL_STATUS"
    [trackingDestination]="'Auszug'"
  ></bdo-link-tile>

  <bdo-link-tile
    [title]="'contract.cancel.status.endTitle' | translate"
    [linktext]="'contract.cancel.status.endLink' | translate"
    [iconSrc]="{default: 'assets/icons/bdo/arrow-inout_75x75.svg'}"
    [destination]="'/kuendigen'"
    [trackingLocation]="TRACKING.LOCATION.CONTRACT_CANCEL_STATUS"
    [trackingDestination]="'Kündigung'"
  ></bdo-link-tile>

</div>
