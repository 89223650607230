import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BdoApiService } from '../../../services/bdo-api.service';
import { ZaehlerIdentifier, Zaehlerstand } from '../../../../../assets/js/com/ts_api_client';
import { LoginService } from '../../../../login/login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerStoreService } from '../../../services/customer-store.service';
import { TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { DatePipe, Location } from '@angular/common';
import { NavigationState } from '../../../models/navigationState';
import { Utilities } from '../../../../shared/utils/utilities';

@Component({
  selector: 'bdo-siminvoice-overview',
  templateUrl: './siminvoice-overview.component.html',
  styleUrls: ['./siminvoice-overview.component.scss']
})
export class SiminvoiceOverviewComponent implements OnInit {
  public startDate: Date = new Date();
  public nowDate: Date = new Date(Date.now());
  public minDateForChanges: Date = new Date();
  public maxDateForChanges: Date = new Date();
  public selectedDate: Date = new Date(Date.now());
  public meters: Array<ZaehlerIdentifier> = [];
  public divisions: Array<string> = [];
  public invalidText: boolean = false;
  public meterreadings: Array<Zaehlerstand> = [];
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public savingDone: boolean = false;
  public TRACKING = TRACKING;
  public savingFailed: boolean = false;
  public calculateClicked: boolean = false;
  public hasMissingReading: boolean = false;
  public dateMeterreadingAdd: Date;
  public contextMeterreadingAdd: string;
  public startDateString: string = this.datePipe.transform(this.startDate, 'dd.MM.yyyy');

  public form = new FormGroup({
    startpoint: new FormControl({ value: this.startDateString, disabled: true })
  });
  private accountId: string;

  constructor(
    private trackingService: TrackingService,
    public router: Router,
    public route: ActivatedRoute,
    public apiService: BdoApiService,
    public loginService: LoginService,
    public customerStore: CustomerStoreService,
    private datePipe: DatePipe,
    public activatedRoute: ActivatedRoute,
    private location: Location
  ) {
      const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
      this.contextMeterreadingAdd = currentState?.context;
      this.dateMeterreadingAdd = currentState?.date;
  }

  ngOnInit() {
    this.trackingService.postTracking(TRACKING.LOCATION.SIMINVOICE, TRACKING.ACTION.ENTER);
    this.state = LOADING_STATE.LOADING;
    this.accountId = this.customerStore.getAccountId();
    this.minDateForChanges.setDate(this.nowDate.getDate() - 28);
    this.maxDateForChanges.setDate(this.nowDate.getDate() + 7);
    this.initMeters();
    this.initStartdate();
    if (this.dateMeterreadingAdd) {
      this.selectedDate = this.dateMeterreadingAdd;
    }
  }

  onUpdateDate(event: Date) {
    this.selectedDate = event;
    this.invalidText = false;
    this.savingFailed = false;
    this.hasMissingReading = false;
  }

  initMeters() {
    let smartMeterExisting: boolean = false;
    this.apiService.getZaehlerIdentifiers(false).subscribe(
      {
        next: (result: Array<ZaehlerIdentifier>) => {
          if (result.length > 0) {
            result.map((zaehler: ZaehlerIdentifier) => {
              if (!zaehler.inaktiv) {
                this.meters.push(zaehler);
                smartMeterExisting = smartMeterExisting || zaehler.isSmartMeter;
                if (!this.divisions.includes(zaehler.sparte)) {
                  this.divisions.push(zaehler.sparte);
                }
                // sort divisions to show strom first
                this.divisions.sort((a, b) => {
                  return a === 'S4' && b !== 'S4' ? -1 : 1;
                });
              }
            });
          }
          this.state = LOADING_STATE.IDLE;
        },
        error: () => {
          this.state = LOADING_STATE.ERROR;
        }
      });
  }

  initStartdate() {
    this.apiService.getAccountPeriod(this.accountId).subscribe(
      {
        next: (event) => {
          this.startDate = new Date(event?.periodStart);
          this.startDateString = this.datePipe.transform(this.startDate, 'dd.MM.yyyy');
          this.form.get('startpoint').setValue(this.startDateString);
          this.state = LOADING_STATE.IDLE;
        },
        error: () => {
          this.state = LOADING_STATE.ERROR;
        }
      });
  }

  calculate() {
    if (!this.hasMissingReading) {
      if (this.calculateClicked === false) {
        this.state = LOADING_STATE.LOADING;
        this.calculateClicked = true;
        this.trackingService.postSimpleTracking(TRACKING.LOCATION.SIMINVOICE, 'startSiminvoice');
        this.apiService.getSimInvoice(this.accountId, this.selectedDate.getTime()).subscribe(
          {
            next: (event) => {
              this.savingDone = true;
              this.state = LOADING_STATE.IDLE;
            },
            error: () => {
              this.state = LOADING_STATE.ERROR;
              this.savingFailed = true;
              this.trackingService.postTracking(TRACKING.LOCATION.SIMINVOICE, TRACKING.ACTION.FAILURE);
            }
          });
      }
    } else {
      this.invalidText = true;
      this.calculateClicked = false;
    }
  }

  missingReading(event: Zaehlerstand) {
    if (event.zaehlwerkeListe[0].wert === null) {
      this.hasMissingReading = true;
    }
  }

  toDashboard() {
    this.router.navigate(['dashboard']);
  }

  getKundennummer(): string {
    return this.customerStore.getAccountId();
  }
}
