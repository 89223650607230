<button class="accordion__head md:re-items-center re-w-full" [attr.data-qa]="shortInfo" (click)="isOpen=!isOpen" type="button"
        [ngClass]="[ position === 'left' ? 're-justify-start' : 're-justify-end', buttonStyles ]">
  <ng-content select="[slot=customHeader]"></ng-content>
  <div class="arrow-icon" *ngIf="!arrowRight">
    <bdo-svg [name]="isOpen ? 'icon-edge-arrow-up' : 'icon-edge-arrow-down'" styling="bdo-svg accordion-iconPosition"
      class="icon"></bdo-svg>
  </div>
  <div class="bold">
    {{shortInfo}}
  </div>
  <div class="arrow-icon re-ml-10 re-self-center" *ngIf="arrowRight">
    <bdo-svg [name]="isOpen ? 'icon-edge-arrow-up' : 'icon-edge-arrow-down'" styling="bdo-svg accordion-iconPosition"
      class="icon"></bdo-svg>
  </div>
</button>
<p class="accordion__content" *ngIf="isOpen">
  <ng-content></ng-content>
</p>
