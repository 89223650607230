<div class="re-mb-default">
  <h3 class="bdo-container re-mb-default">
    {{ 'meterreadings.actions.enterMeterreading' | translate }}
  </h3>

  <div *ngIf="state === stateEnum.DATAENTRY" class="bdo-tile re-mb-15">
    <bdo-accountid-with-address
      [accountId]="identificationData.accountId"
      [address]="address"
    ></bdo-accountid-with-address>
  </div>

  <div class="bdo-container--single" *ngIf="state === stateEnum.VALIDATION">
    <div class="re-mb-default" bdoRouterLinkDirective
      [innerHTML]="'meterreadings.anonymous.description' | translate">
    </div>
  </div>

  <div *ngIf="saveMeterError === SaveMeterErrorEnum.IS_SMART_METER" class="bdo-container--single">
    <bdo-info-box>
      {{'meterreadings.smartmeter.readingNotOk' | translate }}
    </bdo-info-box>
  </div>
  <div *ngIf="saveMeterError === SaveMeterErrorEnum.METER_ALREADY_BILLED" class="bdo-container--single">
    <bdo-info-box>
      {{ 'meterreadings.error.meterAlreadyBilled' | translate }}
    </bdo-info-box>
  </div>
  <div *ngIf="saveMeterError === SaveMeterErrorEnum.NO_ACTIVE_CONTRACT" class="bdo-container--single">
    <bdo-info-box>
      {{ 'meterreadings.error.meterBeforeMovein' | translate }}
    </bdo-info-box>
  </div>
  <div [ngSwitch]="registerError"
    *ngIf="registerError !== RegisterErrorEnum.NONE && registerError !== RegisterErrorEnum.USERNAME_ASSIGNED"
    class="bdo-container--single">
    <bdo-info-box *ngSwitchCase="RegisterErrorEnum.WRONG_COMBINATION">
      {{ 'meterreadings.error.combinationNotFound' | translate }}
    </bdo-info-box>
    <bdo-info-box *ngSwitchCase="RegisterErrorEnum.NOT_INTENDED_FOR_OS">
      <span [innerHTML]="'register.error.unuseableAccount' | translate:
             { contactLink: tenantService.getCurrentTenantData().hrefContact, ariaLabel: 'register.aria.customerService' | translate }"
      ></span>
    </bdo-info-box>
    <bdo-info-box *ngSwitchDefault>
      {{ 'general.error.error' | translate }}
    </bdo-info-box>
  </div>
  <div class="bdo-container--single" *ngIf="savingFailed">
    <bdo-info-box>
      {{ 'general.error.error' | translate }}
    </bdo-info-box>
  </div>
  <div class="bdo-container--single bdo-loading__wrapper re-mb-45 md:re-mb-90 re-bg-white re-py-30 md:re-py-45 lg:re-py-60"
    [class.bdo-loading__wrapper--height]="sendingData">
    <bdo-loading *ngIf="sendingData"></bdo-loading>

    <bdo-authentication-form *ngIf="state === stateEnum.VALIDATION"
      [accountId]="identificationData?.accountId"
      [linkToLoggedInService]="linkToLoggedInService ? linkToLoggedInService : undefined"
      (click)="resetErrorBox()"
      (identificationSubmitted)="sendIdentificationData($event)">
    </bdo-authentication-form>
    <bdo-anonymous-meter-reading-form *ngIf="state === stateEnum.DATAENTRY"
      (click)="resetErrorBox()"
      [identificationData]="identificationData"
      [meterreading]="meterreading" [implausible]="implausible"
      (anonymousMeterDataSubmitted)="sendAnonymousMeterData($event)">
    </bdo-anonymous-meter-reading-form>
  </div>

  <bdo-faqs
    [keyName]="'meterreadings.faqs'"
    [params]="{ contactLink: tenantService.getCurrentTenantData().hrefContact ,
                phoneContactLink: tenantService.getCurrentTenantData().hrefPhoneContact }"
  ></bdo-faqs>
</div>


<bdo-simple-text-popup [isVisible]="savingDone" (closePopupEvent)="closeMessage()"
  [title]="'meterreadings.confirmation.saved' | translate" [icon]="'success'">
  <div *ngIf="!isRegisteredUser" class="re-mt-75 md:re-mt-60">
    <div class="re-mb-default" [innerHtml]="'register.description.anonymous' | translate"></div>
  </div>
  <div class="re-absolute re-bottom-0 re-mb-30 md:re-relative md:re-inset-auto md:re-mb-0">
    <div class="re-mb-20">
      <a *ngIf="!isRegisteredUser" class="re-button re-primary bdo-button__mobile--full"
        [routerLink]="'/registrieren'"
        [state]="{ identificationData: identificationData}">
        {{ 'register.linkTo' | translate }}
      </a>
      <a *ngIf="isRegisteredUser" class="re-button re-primary bdo-button__mobile--full"
        [href]="tenantService.getCurrentTenantData().hrefHomepage">
        {{ 'general.navigation.linkToHomepage' | translate }}
      </a>
    </div>
    <div class="re-mb-15">
      <bdo-text-icon (itemClicked)="closeMessage()" [iconPosition]="'right'"
        [text]="'meterreadings.actions.enterAnotherMeterReading' | translate">
      </bdo-text-icon>
    </div>
  </div>
</bdo-simple-text-popup>

