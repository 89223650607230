export enum REGISTER_ERROR {
    ACCOUNT_EXISTS = 'ACCOUNT_EXISTS',
    MALFORMED_MAILADDRESS = 'MALFORMED_MAILADDRESS',
    MALFORMED_PASSWORD = 'MALFORMED_PASSWORD',
    WRONG_COMBINATION = 'WRONG_COMBINATION',
    USERNAME_ASSIGNED = 'USERNAME_ASSIGNED',
    MALFORMED_USERNAME = 'MALFORMED_USERNAME',
    MAIL_ERROR = 'MAIL_ERROR',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
    NOT_INTENDED_FOR_OS = 'NOT_INTENDED_FOR_OS',
    NONE = ''
}
