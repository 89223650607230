import { Component, Input } from '@angular/core';
import { TenantService } from '../../services/tenant.service';
import { MaintenanceService } from '../../services/maintenance.service';

@Component({
  selector: 'bdo-maintenance-warning',
  templateUrl: './maintenance-warning.component.html',
  styleUrls: ['./maintenance-warning.component.scss']
})
export class MaintenanceWarningComponent {
  @Input() soonUnderMaintenance: boolean;
  @Input() infoText: string;
  @Input() start: Date;
  @Input() end: Date;
  public maintenanceShowPopup$ = this.maintenanceService.showPopup$;
  public serviceUnavailable$ = this.maintenanceService.serviceUnavailable$;

  constructor(
    public tenantService: TenantService,
    private maintenanceService: MaintenanceService
  ) { }

  close() {
    if (this.soonUnderMaintenance) {
      this.maintenanceService.closeMaintenancePopup();
    } else {
      window.location.href = this.tenantService.getCurrentTenantData().hrefContactForm;
    }
  }

}
