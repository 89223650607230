/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Describes PostCode/City pair with available streets as string array
 */
export interface CityAndStreets { 
    name?: string;
    /**
     * Zip Code / PLZ
     */
    postCode?: string;
    streets?: Array<string>;
}

