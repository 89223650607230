<ng-container *ngIf="personalData">
  <div class="re-summary-row">
    <div>{{ 'address.salutation.title' | translate}}</div>
    <div>{{ ('address.salutation.options.' + personalData.title) | translate }}</div>
  </div>
  <ng-container *ngIf="!isGewe">
    <div class="re-summary-row">
      <div>{{ 'address.personalInfo.firstname' | translate }}</div>
      <div>{{ personalData.name }}</div>
    </div>
    <div class="re-summary-row">
      <div>{{ 'address.personalInfo.surname' | translate }}</div>
      <div>{{ personalData.surname }}</div>
    </div>
    <div class="re-summary-row" *ngIf="personalData.birthdate && !isGewe">
      <div>{{ 'address.personalInfo.birthdate.title' | translate }}</div>
      <div data-qa='birthdate'>{{ personalData.birthdate }}</div>
    </div>
  </ng-container>

  <ng-container *ngIf="isGewe">
    <div class="re-summary-row">
      <div>{{ 'address.personalInfo.name1' | translate }}</div>
      <div data-qa='name1'>{{ personalData.name1 }}</div>
    </div>
    <div class="re-summary-row" *ngIf="personalData.name2">
      <div>{{ 'address.personalInfo.name2Optional' | translate }}</div>
      <div data-qa='name2'>{{ personalData.name2 }}</div>
    </div>
    <div class="re-summary-row">
      <div>{{ 'address.business.sector.title' | translate }}</div>
      <div data-qa='sector'>{{ personalData['sectorName'] }}</div>
    </div>
    <div class="re-summary-row">
      <div>{{ 'address.business.legalEntity.title' | translate }}</div>
      <div data-qa='legalEntity'>{{ personalData['legalEntityName'] }}</div>
    </div>
    <div class="re-summary-row" *ngIf="personalData.commercialRegisterNr">
      <div>{{ 'address.business.commercialRegisterNr' | translate }}</div>
      <div data-qa='commercialRegisterNr'>{{ personalData.commercialRegisterNr }}</div>
    </div>
    <div class="re-summary-row" *ngIf="personalData.nameCo">
      <div>{{ 'address.personalInfo.nameCo' | translate }}</div>
      <div data-qa='commercialRegisterNr'>{{ personalData.nameCo }}</div>
    </div>
  </ng-container>

  <div class="re-summary-row">
    <div>{{ 'address.phonenumber.title' | translate }}</div>
    <div>{{ personalData.phonenumber }}</div>
  </div>
  <div class="re-summary-row">
    <div>{{ 'address.email.title' | translate }}</div>
    <div>{{ personalData.email }}</div>
  </div>

  <div *ngIf="personalData.registrationData?.username" class="re-mt-30">
    <h5 class="re-mb-30">{{ 'summary.personalData.onlineService' | translate }}</h5>
    <div class="re-summary-row">
      <div>{{ 'register.username.title' | translate }}</div>
      <div>{{ personalData.registrationData.username }}</div>
    </div>
    <div class="re-summary-row re-mb-0">
      <div>{{ 'register.password.title' | translate }}</div>
      <div>{{ personalData.registrationData?.passwordGroup?.password | maskAll }}</div>
    </div>
  </div>

</ng-container>
