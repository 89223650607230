import { Component, Input } from '@angular/core';
import { INPUT_TYPE } from '../../../bdo/enums/inputType.enum';
import { TRACKING } from '../../../bdo/enums/trackingParts.enum';
import { FormFieldIdentifierTracking, FormTracking, TrackingService } from '../../../bdo/services/tracking.service';

@Component({
  selector: 'bdo-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
  @Input() checked: boolean = false;
  @Input() required: boolean = false;
  @Input() errorText: string = '';
  @Input() disabled: boolean = false;
  @Input() formTracking: FormFieldIdentifierTracking;
  @Input() ariaLabel: string = '';

  constructor(
    private trackingService: TrackingService
  ) { }

  onSelection() {
    if (this.formTracking) {
      const formTracking: FormTracking = {
        ...this.formTracking,
        orca_field_type: INPUT_TYPE.CHECKBOX,
        orca_form_action: TRACKING.FORM_ACTION.INTERACTION
      };
      this.trackingService.postFormInteraction(formTracking);
    }
  }

}
