<div class="re-container-headline re-mb-30 md:re-mb-45 lg:re-mb-60">
  <h3>{{ 'summary.titleEnd' | translate }}</h3>
</div>
<div class="re-container-with-margin">
  <div class="re-copytext-with-margin">
    {{ 'summary.descriptionEnd' | translate }}
  </div>
</div>

<!-- shown on all backend errors -->
<div class="bdo-container--single">
  <div [class.re-mb-15]="state === LoadingState.ERROR">
    <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">
      {{ 'general.error.error' | translate }}
    </bdo-info-box>
  </div>
</div>
<div [class.bdo-loading__wrapper--height]="state === LoadingState.LOADING">
  <div class="re-px-20 re-py-30 re-w-full re-mb-1 re-bg-white re-relative
              md:re-px-30 md:re-py-45 md:re-w-600 md:re-mx-auto md:re-mb-0
              lg:re-w-900" *ngIf="state === LoadingState.LOADING">
    <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
  </div>
  <div *ngIf="state !== LoadingState.LOADING">
    <!-- Contract and cancellation details -->
    <h4 class="re-container-with-margin re-mb-20 re-ml-20
             md:re-mb-30 md:re-ml-auto">
      {{ 'contract.cancel.summary.subtitle' | translate }}
    </h4>
    <bdo-edit-card
      (edit)="onEdit(1, 'vertragsauswahl')">

      <h5 class="re-mb-20 md:re-mb-30">
        {{ 'contract.cancel.summary.titlePreviousContract' | translate: { division: oldContract?.division | verbrauchstyp } }}
      </h5>
      <div class="re-summary-row re-mb-0">
        <div>{{ 'contract.previous' | translate }}</div>
        <div>{{oldContract?.tariffName}}</div>
      </div>
    </bdo-edit-card>

    <div class="re-mt-3">
      <bdo-edit-card [attr.data-qa]="'contractendcard'"
                     (edit)="onEdit(2, 'vertragsende')">
        <bdo-contract-cancel-overview></bdo-contract-cancel-overview>
      </bdo-edit-card>
    </div>

    <!-- Invoice Recipient -->
    <h4 class="re-container-with-margin re-mt-60 re-mb-20 re-ml-20
             md:re-mb-30 md:re-ml-auto">
      {{ 'address.invoiceRecipient' | translate }}
    </h4>
    <bdo-edit-card
      (edit)="onEdit(3, 'rechnungsadresse')">
      <bdo-billing-address-summary
        [showAccountId]="true">
      </bdo-billing-address-summary>
    </bdo-edit-card>

    <!-- Legal Notice -->

    <div class="re-container-with-margin re-mt-60 re-ml-20 md:re-ml-auto">
      <h4 class="re-mb-30">{{ 'summary.legal.title' | translate }}</h4>
      <bdo-info-box *ngIf="translateService.instant('summary.legal.hint') !== ''" [type]="'info'" [marginBottom]="false">
        {{ 'summary.legal.hint' | translate }}
      </bdo-info-box>
    </div>
    <bdo-edit-card
      [isEditable]="false">
      <bdo-legal-summary
        [endingProcess]="'contractCancel'"
        [canceledDivisions]="recentDivisionIds"
        [validateCheckboxes]="validateCheckboxes"
        (rightOfWithdrawalChange)="onChangeRightOfWithdrawalAccepted($event)"
        (tosChange)="onChangeTermsOfConditions($event)">
      </bdo-legal-summary>
    </bdo-edit-card>

    <div class="re-w-full md:re-w-600 lg:re-w-900 md:re-mx-auto re-mt-30 md:re-mt-45">
      <div class="md:re-pl-0 re-pl-20">
        <bdo-validate-submit
          (click)="submit()"
          [textButtonLong]="'contract.cancel.summary.submit' | translate"
        ></bdo-validate-submit>
        <div class="re-pt-20">
          <bdo-text-icon
            [iconPosition]="'left'"
            [text]="'general.action.back' | translate"
            (itemClicked)="location.back()"
          >
          </bdo-text-icon>
        </div>
      </div>
    </div>
  </div>
</div>
