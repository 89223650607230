

<div class="re-bdo-container dashboard__row campaigns re-w-full re-mb-45 md:re-mb-120 md:re-mt-60" bdoRouterLinkDirective>
  <bdo-campaign-tile
    [mode]="CampaignTileType.TILE"
    [campaign]="cancelCampaign"
    [showDismissCheckbox]="false"
    (clickCampaignButton)="onClickCampaignButton()"
  ></bdo-campaign-tile>
</div>

<div class="bdo-container re-mb-30
            md:re-mb-60">
  <h3>{{ 'contract.cancel.date.title' | translate }}</h3>
</div>

<form (ngSubmit)="save()" (keydown.enter)="$event.preventDefault()" [formGroup]="form" bdoScrollToInvalidControl>
  <h4 class="re-container-with-margin re-mb-30 re-ml-20 md:re-ml-auto">
    {{ 'contract.cancel.date.selection.title' | translate }}
  </h4>

  <div class="re-bg-white re-w-full re-px-20 re-py-30 re-text re-mb-75
                md:re-w-600 md:re-mx-auto md:re-pb-60 md:re-mb-90
                lg:re-w-900">
    <fieldset class="re-mb-0">
      <div class="re-mb-30 re-relative">
        <div class="re-mb-20 md:re-table-row">
          <div class="md:re-table-cell md:re-pb-5 md:re-pr-40">
            {{ 'contract.previous' | translate }}
          </div>
          <div class="bold md:re-table-cell">
            {{tariffName}}
          </div>
        </div>
        <div class="md:re-table-row">
          <div class="md:re-table-cell md:re-pb-5 ">
            {{ 'general.customerData.meternumber' | translate }}
          </div>
          <div class="bold md:re-table-cell">
            {{meternumber}}
          </div>
        </div>
      </div>

      <div class="re-mb-20">
        {{ 'contract.cancel.date.selection.dateInfo' | translate }}
      </div>

      <bdo-date-picker
        [selectedDate]="$any(form?.get('canceldate').value)"
        [minDateForChanges]="minDate"
        [maxDateForChanges]="Utilities.maxDate"
        [labelText]="'contract.cancel.canceldate' | translate"
        [attr.data-qa]="'canceldate'"
        (dateSelected)="onDateChanged($event)"
      ></bdo-date-picker>

      <!-- Kündigungsgrund -->
      <h5 class="re-mb-30 re-mt-60">{{ 'contract.cancel.date.selection.reason' | translate }}</h5>
      <div class="re-mb-20">{{ 'contract.cancel.date.selection.reasonInfo' | translate }}</div>
      <div class="bdo-loading__wrapper" [class.bdo-loading__wrapper--height]="loadingCancelReasonState === LoadingState.LOADING">
        <bdo-loading *ngIf="loadingCancelReasonState === LoadingState.LOADING"></bdo-loading>
        <bdo-dropdown *ngIf="cancelReasonDropdown?.length > 1"
          [data]="cancelReasonDropdown"
          [required]="true"
          [dataQa]="'i_cancelreasons_dropdown'"
          [labelText]="'contract.cancel.date.selection.reason' | translate"
          [selected]="form.get('cancelReasonId').valueChanges | async"
          [requiredErrorText]="'contract.cancel.date.selection.reasonRequired' | translate"
          [forceValidate]="forceValidate"
          (itemSelected)="onReasonChanged($event)">
        </bdo-dropdown>
      </div>

    </fieldset>
  </div>


  <!-- Zählerstände bei Kündigung -->
  <ng-container *ngIf="meter">
    <div class="re-container-with-margin re-mt-20 re-mb-45 re-ml-20 md:re-mb-60 md:re-ml-auto">
      <h4 class="re-mb-20 md:re-mb-30">{{ 'contract.cancel.date.meterreadings.title' | translate }}</h4>
      <div class="re-text-sm" innerHTML="{{ 'contract.cancel.date.meterreadings.description' | translate }}"></div>
    </div>

    <div *ngIf="state === LoadingState.LOADING">
      <div class="re-bg-white re-w-full re-h-80 md:re-w-600 md:re-mx-auto lg:re-w-900"></div>
      <div class="re-bg-white re-w-full re-h-80 re-mb-20 md:re-w-600 md:re-mx-auto lg:re-w-900">
        <div class="re-relative">
          <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
        </div>
      </div>
    </div>

    <h5 class="re-container-with-margin re-mb-20">
      {{ 'general.tariff.meterWithDivision' | translate: { division: division | verbrauchstyp } }}
    </h5>
    <bdo-recent-meter-readings
      [meter]="meter"
      [parentForm]="form"
      (readingDataChange)="onReadingChange($event)"
    >
    </bdo-recent-meter-readings>
  </ng-container>

  <!-- Weiter -->
  <div class="bdo-container--single re-mt-45 md:re-mt-60">
    <bdo-validate-submit
      [textButtonLong]="'wizard.button.paymentAddress' | translate"
    ></bdo-validate-submit>
    <div class="re-mt-20">
      <bdo-text-icon
        [iconPosition]="'left'"
        [text]="'general.action.back' | translate"
        (itemClicked)="location.back()"
      ></bdo-text-icon>
    </div>
  </div>
</form>
