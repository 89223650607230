<div class="bdo-container" *ngIf="context === 'standalone'; else withoutwrapper">
  <div class="bdo-container-single re-mb-45 md:re-mb-90 re-bg-white re-p-30 md:re-pb-45">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</div>

<ng-template #withoutwrapper>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-template>

<ng-template #content>
  <h4 class="re-mb-15">{{ 'dashboard.accountInProgress.title' | translate }}</h4>
  <p class="re-mb-30">{{ 'dashboard.accountInProgress.description' | translate }}</p>

  <div class="bdo-space--bottom">
    <a role="button" class="bdo-button-primary" (click)="goToMainPage()">
      {{ 'general.navigation.linkToHomepage' | translate }}
    </a>
  </div>
  <bdo-text-icon
    (itemClicked)="onClose()"
    [iconPosition]="'right'"
    [text]="'general.navigation.backToLogin' | translate"
  ></bdo-text-icon>
</ng-template>
