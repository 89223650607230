import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { ContractDetails } from '../../../../../assets/js/com/ts_api_client';
import { CUSTOMERMODE } from '../../../enums/customerMode';
import { VERBRAUCHSTYP } from '../../../enums/verbrauchstyp.enum';
import { StorageService } from '../../../services/storage.service';
import { TariffAdvisorService } from '../../../services/tariff-advisor.service';
import { CONTRACT_DETAILS_TILE_MODE } from '../../../enums/contract-details-tile-mode.enum';
import { Environment } from '../../../../../environments/environment';

/* Contracts without a specific end date are set to Fri Dec 31 9999 */
const MAXCONTRACTDATE = new Date(253402214400000).toISOString();

@Component({
  selector: 'bdo-contract-details-tile',
  templateUrl: './contract-details-tile.component.html',
  styleUrls: ['./contract-details-tile.component.scss']
})
export class ContractDetailsTileComponent implements OnInit {
  @Input() accountId: string = '';
  @Input() contract: ContractDetails;
  @Input() mode: CONTRACT_DETAILS_TILE_MODE;
  @Input() isBaseSupply: boolean;
  @Input() customerMode: CUSTOMERMODE = CUSTOMERMODE.GEWE;
  @Input() consumption: string;
  @Input() showPriceCap: boolean = false;
  @Input() isNsh1Nsh2Tariff: boolean = false;
  @Output() tariffCancel: EventEmitter<ContractDetails> = new EventEmitter();
  @Output() tariffChange: EventEmitter<ContractDetails> = new EventEmitter();
  public CUSTOMERMODE = CUSTOMERMODE;
  public ContractDetailsTileMode = CONTRACT_DETAILS_TILE_MODE;
  public Verbrauchstyp = VERBRAUCHSTYP;
  public today = moment().startOf('day').toDate();
  public MAXCONTRACTDATE = MAXCONTRACTDATE;
  public isAnonymous: boolean = false;
  public showTariffChangeButton: boolean = true;

  constructor(
    public tariffAdvisorService: TariffAdvisorService
  ) { }

  ngOnInit(): void {
    this.isAnonymous = !!StorageService.getAuthData()?.checkIdentificationData?.accountId;
    this.showTariffChangeButton = Environment.tariffChangeIsEnabled();
  }

  getIsBaseTariff(tariffName: string): boolean {
    return tariffName.includes('Grundversorgung');
  }
}
