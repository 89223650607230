/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SimInvoice { 
    /**
     * accountId of existing contract, also knows as VK
     */
    accountId?: string;
    /**
     * Lower date bound for simulated invoice
     */
    dateFrom?: Date;
    /**
     * Upper date bound for simulated invoice
     */
    dateTo?: Date;
    /**
     * Flag indicating pdf export of simulated invoice
     */
    printPdf?: boolean;
    divisions?: Array<string>;
}

