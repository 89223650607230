<h3 class="bdo-container re-mb-45">{{ 'contract.overview.title' | translate }}</h3>

<p class="re-container-with-margin re-mb-30 re-text-sm re-leading-m md:re-text-base md:re-leading-2xl">
  {{ 'contract.overview.description' | translate }}
</p>

<div class="bdo-container--single">
  <div [class.re-mb-15]="state === LoadingState.ERROR">
    <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR"
      data-qa="error">
      {{ errorMessage }}
    </bdo-info-box>
  </div>
</div>

<!-- selection of accountId -->
<div *ngIf="!errorMessage" class="re-px-20 re-py-15 re-w-full re-bg-white re-relative re-mb-45
            md:re-px-30 md:re-py-20 md:re-w-600 md:re-mx-auto lg:re-mb-90
            lg:re-w-900">
  <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
  <bdo-accountid-with-address *ngIf="address"
    [accountId]="selectedAccount?.accountId"
    [address]="Utilities.nestAddress(address)"
  ></bdo-accountid-with-address>
</div>

<div class="re-mb-45 md:re-mb-90" *ngIf="selectedAccount?.accountId && address">
  <bdo-contract-details [accountId]="selectedAccount?.accountId" [meterNumber]="meterNumber" [address]="address"></bdo-contract-details>
</div>

<!-- New -->
<bdo-square-icon-text *ngIf="canAddTariff && selectedAccount"
  [routerUrl]="'/anmelden/' + selectedAccount?.accountId + '/neu/adresse'"
  [text]="'contract.overview.addTariff' | translate"
  [dataQa]="'contract_new_btn'">
</bdo-square-icon-text>

<!-- FAQ -->
<bdo-faqs
  [keyName]="'contract.overview.faqs'"
  [params]="{ contactLink: tenantService.getCurrentTenantData().hrefContact }"
></bdo-faqs>
