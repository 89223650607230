<div class="re-container-headline bdo-space--bottom">
  <h3>
    {{ 'summary.title' | translate }}
  </h3>
</div>
<div class="re-container-with-margin">
  <div class="re-copytext-with-margin">
    {{ 'summary.description' | translate }}
  </div>
</div>

<!-- shown on all backend errors -->
<div class="bdo-container--single">
  <div [class.re-mb-15]="state === LoadingState.ERROR">
    <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">
      {{ errorMessage }}
    </bdo-info-box>
  </div>
</div>

<div class="bdo-loading__wrapper" [class.bdo-loading__wrapper--height]="state === LoadingState.LOADING">
  <div class="re-px-20 re-py-30 re-w-full re-mb-1 re-bg-white re-relative
              md:re-px-30 md:re-py-45 md:re-w-600 md:re-mx-auto md:re-mb-0
              lg:re-w-900" *ngIf="state === LoadingState.LOADING">
    <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
  </div>
  <div *ngIf="state !== LoadingState.LOADING">

    <h4 class="re-container-with-margin re-mb-20 re-ml-20
              md:re-mb-30 md:re-ml-auto">
      {{ 'summary.supply.title' | translate }}
    </h4>
    <bdo-edit-card
      [isEditable]="false">
      <bdo-contract-details-summary
        [oldContract]="oldContract"
        [newTariff]="tariff">
      </bdo-contract-details-summary>
    </bdo-edit-card>

    <h4 class="re-container-with-margin re-mb-20 re-ml-20 re-mt-45
              md:re-mb-30 md:re-ml-auto md:re-mt-60">
      {{ 'tariff.new' | translate }}
    </h4>
    <bdo-edit-card
      [isEditable]="!campaignContext"
      [showFrame]="false"
      (edit)="onEdit(3, 'tarifauswahl')">
      <bdo-tariff-data-summary
      [selectedDivision]="tariff"
    ></bdo-tariff-data-summary>
    </bdo-edit-card>

    <!-- Voucher Offer -->
    <ng-container *ngIf="hasVouchers">
      <h4 class="re-container-with-margin re-mb-20 re-mt-60 re-ml-20 md:re-mb-30 md:re-ml-auto">
        {{ 'summary.voucher.title' | translate }}
      </h4>
      <div class="re-w-full md:re-w-600 lg:re-w-900 md:re-mx-auto">
        <bdo-wishvoucher></bdo-wishvoucher>
      </div>
    </ng-container>

    <h4 class="re-container-with-margin re-mb-20 re-ml-20 re-mt-45
              md:re-mb-30 md:re-ml-auto md:re-mt-60">
      {{ 'summary.contact.title' | translate }}
    </h4>
    <bdo-edit-card
      [isEditable]="false">
      <div *ngIf="((emailAddress$ | async)?.hasEmail || hasEmailInOffer); else enterEmail" data-qa="has-email">
        <ul class="bdo-list__bullet ">
          <li>
            {{ 'summary.contact.emailPresent' | translate }}
          </li>
        </ul>
      </div>
      <ng-template #enterEmail>
        <div class="re-form-item-container">
          <div class="re-mb-30">
            {{ 'summary.contact.emailNotPresent' | translate }}
          </div>
          <div [formGroup]="form" data-qa="has-no-email" *ngIf="form?.get('email')">
            <bdo-input [formControl]="$any(form.get('email'))"
                       [inputType]="INPUT_TYPE.EMAIL"
                       [labelText]="'address.email.title' | translate">
            </bdo-input>
          </div>
        </div>
      </ng-template>

    </bdo-edit-card>

    <div class="re-container-with-margin re-mt-60 re-ml-20 md:re-ml-auto">
      <h4 class="re-mb-30">{{ 'summary.legal.title' | translate }}</h4>
      <bdo-info-box *ngIf="translateService.instant('summary.legal.hint') !== ''" [type]="'info'" [marginBottom]="false">
        {{ 'summary.legal.hint' | translate }}
      </bdo-info-box>
    </div>
    <bdo-edit-card [isEditable]="false">
      <bdo-legal-summary
      [isGewe]="isGewe"
      [validateCheckboxes]="validateCheckboxes"
      [pathAndDivisions]="pathAndDivisions"
      [communicationAcceptedMode]="'check'"
      (tosChange)="onChangeTermsOfConditions($event)"
      (rightOfWithdrawalChange)="onChangeRightOfWithdrawalAccepted($event)"
      (communicationAcceptedChange)="onChangeCommunicationAccepted($event)"
    ></bdo-legal-summary>
    </bdo-edit-card>

    <div class="re-w-full md:re-w-600 lg:re-w-900 md:re-mx-auto re-mt-30 md:re-mt-45">
      <div class="md:re-pl-0 re-pl-20">
        <bdo-validate-submit
          (click)="submit()"
          [textButtonLong]="'summary.submitWithPay' | translate"
        ></bdo-validate-submit>
        <div class="re-pt-15">
          <bdo-text-icon
            [iconPosition]="'left'"
            [text]="'general.action.back' | translate"
            (itemClicked)="location.back()"
          >
          </bdo-text-icon>
        </div>
      </div>
    </div>
  </div>
</div>
