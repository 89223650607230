<bdo-dashboard-tile
  [tileTitle]="getTileTitle()"
  [linkData]="[{
    linkName: (bankAccountAssignments?.debit ? 'dashboard.banking.linkChangeData' : 'dashboard.banking.linkChangeType') | translate,
    href: '/daten/' + kundennummer ,
    fragment: 'banking',
    tracking: [TRACKING.LOCATION.DASHBOARD, TRACKING.ACTION.GOTO, TRACKING.LOCATION.BANKING]
  }]">
  <div *ngIf="!!bankAccountAssignments && !!payments" class="re-small">
    <bdo-balance *ngIf="!!payments && payments.offeneSumme && !!bankAccountAssignments"
      [bankAccountAssignments]="bankAccountAssignments"
      [payments]="payments"
      [kundennummer]="kundennummer"
    ></bdo-balance>
    <bdo-assignments [bankAccountAssignments]="bankAccountAssignments"
                     *ngIf="!!bankAccountAssignments && !payments.offeneSumme">
    </bdo-assignments>
  </div>

</bdo-dashboard-tile>
