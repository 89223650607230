<div class="re-container-headline bdo-space--bottom">
  <h3>
    {{ 'confirmation.order.title' | translate }}
  </h3>
</div>
<div class="re-container-with-margin">
  <div class="re-copytext re-mb-45 lg:re-mt-60">
    {{ 'confirmation.order.description' | translate }}
  </div>
  <h2 class="re-mb-15 lg:re-mb-30">
    {{ 'confirmation.title' | translate }}
  </h2>
  <ul class="bdo-list__bullet_bigger re-text-sm re-leading-m re-mb-45 lg:re-text-base lg:re-leading-2xl lg:re-mb-60">
    <li class="re-mb-5" *ngIf="email"
      [innerHTML]="'confirmation.order.acknowledgment' | translate: { emailAddress: email }">
    </li>
    <li *ngIf="previousSuppliers.length > 0" class="re-mb-5"
      [innerHTML]="'confirmation.order.cancelPreviousSupplier' | translate: { previousSupplier: previousSuppliers | listing }">
    </li>
    <li
      [innerHTML]="'confirmation.order.supplyBegins' | translate: { startDate: situationData?.startDate | date }">
    </li>
    <ng-container *ngIf="tariffService.isEffzeh([tariffData?.productId])">
      <li class="re-mt-5">{{ 'confirmation.order.effzehVoucher' | translate}}</li>
      <li class="re-mt-5">{{ 'confirmation.order.effzehPayout' | translate}}</li>
      <li class="re-mt-5">{{ 'confirmation.order.effzehCompetition' | translate}}</li>
    </ng-container>
  </ul>

  <bdo-trustpilot *ngIf="trustpilotUniqueUrl"
                  [trustpilotUniqueUrl]="trustpilotUniqueUrl">
  </bdo-trustpilot>

  <bdo-netcologne-teaser *ngIf="!tariffService.isEffzeh([tariffData?.productId])"
    [postCode]="addressData?.postCode"
    [trackingLocation]="TRACKING.LOCATION.CONTRACT_NEW_DONE"
  ></bdo-netcologne-teaser>

  <bdo-effzeh-strom-teaser [productIds]="[tariffData?.productId]"></bdo-effzeh-strom-teaser>

  <div class="re-block re-mt-30 lg:re-mt-45">
    <bdo-text-link-icon
      [linkText]="'dashboard.linkTo' | translate"
      [routerUrl]="'/dashboard'"
      [iconPosition]="'right'"
    ></bdo-text-link-icon>
  </div>
</div>
