import { Component, Input, OnInit } from '@angular/core';
import { TRACKING } from '../../enums/trackingParts.enum';
import { TariffAdvisorService } from '../../services/tariff-advisor.service';

@Component({
  selector: 'bdo-effzeh-strom-teaser',
  templateUrl: './effzeh-strom-teaser.component.html',
  styleUrl: './effzeh-strom-teaser.component.scss'
})
export class EffzehStromTeaserComponent implements OnInit {
  @Input() productIds: Array<string>;
  public TRACKING = TRACKING;
  public isEffzeh = false;

  constructor(
    private tariffService: TariffAdvisorService
  ) { }

  ngOnInit(): void {
    this.isEffzeh = this.tariffService.isEffzeh(this.productIds);
  }

}
