import { Component, Input, ViewEncapsulation, Output, HostListener, Renderer2, SimpleChanges,
  OnDestroy, OnChanges } from '@angular/core';
  import { TenantService } from '../../../bdo/services/tenant.service';
  import { EventEmitter } from '@angular/core';

@Component({
  selector: 'bdo-video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['./video-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoPopupComponent implements OnDestroy, OnChanges {
  @Input() title: string;
  @Input() isVisible: boolean;

  @Output() closePopupEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private renderer: Renderer2,
    public tenantService: TenantService
  ) { }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Esc') {
      if (this.isVisible) {
        this.onClose();
      }
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'popupOpen');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isVisible &&
      changes.isVisible.currentValue !== changes.isVisible.previousValue &&
      changes.isVisible.currentValue === true) {
      this.renderer.addClass(document.body, 'popupOpen');
    }
    if (changes.isVisible && changes.isVisible.currentValue === false) {
      this.renderer.removeClass(document.body, 'popupOpen');
    }
  }

  onClose() {
    this.closePopupEvent.emit(null);
  }

}
