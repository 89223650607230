import { inject } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DebugLogger } from '../shared/utils/debugLogger';
import { LoginService } from '../login/login.service';
import { AuthDataStorage } from '../bdo/models/AuthData.storage';
import { ALLOWED_KEYS, StorageService } from '../bdo/services/storage.service';

export const guardAuthAll: CanActivateFn = (route, state) : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const authData: AuthDataStorage = StorageService.getValue<AuthDataStorage>(ALLOWED_KEYS.AUTH_DATA);
  const loginService = inject(LoginService);
  return loginService.isLoggedIn().pipe(map((isLoggedIn) => {
    if (!isLoggedIn && !authData) {
      DebugLogger.debug('guardAuthAll: ', 'is logged out -> redirect to loginPage');
      loginService.redirectToLoginPage(state.url);
      return false;
    } else {
      DebugLogger.debug('guardAuthAll: ', 'authenticated');
      return true;
    }
  }));
};
