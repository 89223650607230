<div class="re-mt-60 md:re-mt-90 bdo-container">
  <bdo-section-headline>
    {{ 'general.headline.faqTitle' | translate }}
  </bdo-section-headline>
</div>

<div *ngIf="arrayOfFaqs.length > 0" class="bdo-container--single">
  <bdo-accordion>
    <ng-container *ngFor="let faqKey of arrayOfFaqs">
      <bdo-accordion-item title="{{ keyName + '.' + faqKey + '.question' | translate: params }}">
        <div [innerHTML]="keyName + '.' + faqKey + '.answer' | translate: params" bdoRouterLinkDirective></div>
      </bdo-accordion-item>
    </ng-container>
  </bdo-accordion>
</div>
