import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { VerbrauchstypPipe } from '../../../pipes/verbrauchstyp.pipe';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { VERBRAUCHSTYP } from '../../../enums/verbrauchstyp.enum';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { TrackingService } from '../../../services/tracking.service';
import { TrackingPixelData } from '../../../models/trackingPixelData';
import { Subscription } from 'rxjs';
import { KameleoonService } from '../../../services/kameleoon.service';
import { TariffData } from '../../../../../assets/js/com/ts_api_client';
import { StoragePersonalData } from '../../../models/storagePersonalData';

@Component({
  selector: 'bdo-customer-status',
  templateUrl: './customer-status.component.html',
  styleUrls: ['./customer-status.component.scss']
})
export class CustomerStatusComponent implements OnInit, OnDestroy {
  public TRACKING = TRACKING;
  private tariffData: Array<TariffData> = [];
  private personalData: StoragePersonalData;
  private trackingPixelData: TrackingPixelData;
  private subscriptions = new Subscription();

  constructor(
    public kameleoonService: KameleoonService,
    private trackingService: TrackingService,
    private route: ActivatedRoute,
    private verbrauchstypPipe: VerbrauchstypPipe,
    private router: Router
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    // If landing on customerstatus we clear any remaining offer infos from storage
    StorageService.clearOfferContext();
    StorageService.clearOfferId();

    const oldTitles = ['Umweltliebende', 'Umweltbewusste', 'Freiheitsliebende', 'Sicherheitsorientierte', 'Heimatliebende'];

    // Save Params from TariffAdvisor in model
    this.subscriptions.add(this.route.queryParamMap.subscribe(
      { next: res => {
        this.tariffData.push({
          postCode: res.get('zip'), // will be mapped to postCode
          powerModId: res.get('ext-id'),
          powerModBegin: res.get('begin'),
          hash: res.get('hash'),
          annualConsumption: res.get('AnnualConsumption'),
          type: res.get('type') as undefined,
          mode: res.get('mode') as undefined,
          divisionId: this.mapTypeToDivisionId(res.get('type')),
          productId: res.get('product-e') || res.get('product-g'),
          tariff: res.get('tariff-e') || res.get('tariff-g'),
          tbTariffName: res.get('bundletitle-e') || res.get('bundletitle-e'),
          annualConsumptionNt: res.get('AnnualConsumptionNt') || null,
        });

        if (oldTitles.indexOf(this.tariffData[0].tbTariffName) >= 0) {
          this.tariffData[0].tbTariffName = 'Tarif für ' + this.tariffData[0].tbTariffName;
        }

        StorageService.setTariffData(this.tariffData);
        this.trackingService.postModeAndTypeOnEnterTracking(this.tariffData[0].mode, this.verbrauchstypPipe.transform(this.tariffData[0].divisionId, false));

        this.trackingPixelData = new TrackingPixelData(
          res.get('re_channel'),
          res.get('re_partnerid'),
          res.get('s_id'),
          res.get('ad_start')
        );
        StorageService.setValue(ALLOWED_KEYS.TRACKINGPIXEL_DATA, this.trackingPixelData);

        this.personalData = {
          streetIsChangeable: res.get('street') === '' || res.get('street') === null,
          housenumIsChangeable: res.get('house') === '' || res.get('house') === null,
          addressData:{
            street: res.get('street'),
            housenumber: res.get('house'),
            postCode: res.get('zip'),
            city: res.get('city')
          }
        };
        StorageService.setPersonalData(this.personalData);

        // Skipping the page as we should be new customer
        if (res.get('skip') === 'true') {
          this.router.navigate(['/vertrag-abschliessen/neu'], { replaceUrl: true });
        }
      } }
    ));
    this.subscriptions.add(this.router.events.subscribe(
      { next: event => {
        if (event instanceof NavigationStart && event.url === '/vertrag-abschliessen/neu') {
          this.kameleoonService.processConversion(KameleoonService.Goals.DELIVERY_NEW_HERE);
        }
      } }
    ));
  }

  mapTypeToDivisionId(type: string): VERBRAUCHSTYP {
    let divisionId = null;
    switch (type) {
      case 'e':
        divisionId = VERBRAUCHSTYP.Strom;
        break;
      case 'g':
        divisionId = VERBRAUCHSTYP.Gas;
        break;
      default:
        break;
    }
    return divisionId;
  }
}
