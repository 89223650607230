import { Component, OnInit } from '@angular/core';
import { ContractDetails, ZaehlerIdentifier, Zaehlerstand } from '../../../../../../assets/js/com/ts_api_client';
import { HomeData } from '../../../../models/home';
import { ALLOWED_KEYS, StorageService } from '../../../../services/storage.service';
import { MeterReadingWithSmartMeter } from '../../../move/move-summary/old-home-summary/old-home-summary.component';

@Component({
  selector: 'bdo-contract-cancel-overview',
  templateUrl: './contract-cancel-overview.component.html',
  styleUrls: ['./contract-cancel-overview.component.scss']
})
export class ContractCancelOverviewComponent implements OnInit {
  public homeData: HomeData;
  public oldContract: ContractDetails;
  public meterReadings: Array<MeterReadingWithSmartMeter> = [];

  ngOnInit(): void {
    this.homeData = StorageService.getValue<HomeData>(ALLOWED_KEYS.RECENT_HOME_DATA);
    this.oldContract = StorageService.getOldContract();

    const moveOutMeterReadings: Array<Zaehlerstand> = this.homeData?.meterData;
    const moveOutMeters: Array<ZaehlerIdentifier> = this.homeData?.meters;
    moveOutMeters?.map((meter: ZaehlerIdentifier) => {
      const result = moveOutMeterReadings.filter(reading => {
        return reading?.meterNumber === meter?.meterNumber;
      });
      this.meterReadings.push({
        meterReading: result[0],
        isSmartMeter: meter?.isSmartMeter
      });
    });

    this.meterReadings.sort((a, b) => {
      return a.meterReading.sparte === 'S4' && b.meterReading.sparte !== 'S4' ? -1 : 1;
    });
  }

}
