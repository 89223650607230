/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * object containing all metadata for voucher
 */
export interface Voucher { 
    activityCode?: string;
    activityText?: string;
    voucherType?: string;
    logo?: string;
    title?: string;
    subtitle?: string;
    valuetitle?: string;
    value?: string;
    info?: string;
}

