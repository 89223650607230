import { Component, Input } from '@angular/core';
import { LinkData } from '../../../bdo/models/LinkData';

@Component({
  selector: 'bdo-dashboard-tile',
  templateUrl: './dashboard-tile.component.html',
  styleUrls: ['./dashboard-tile.component.scss']
})
/** A Basic Template for the Tiles on the Dashboard */
export class DashboardTileComponent {
  @Input() tileTitle: string;
  @Input() linkData: Array<LinkData> = [];

}
