import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Account, AccountInfo, DivisionItem } from '../../../../../assets/js/com/ts_api_client';
import { CustomerStoreService } from '../../../services/customer-store.service';
import { TenantService } from '../../../services/tenant.service';
import { DropdownItem } from '../../../models/dropdownItem';
import { TRACKING } from '../../../enums/trackingParts.enum';

@Component({
  selector: 'bdo-customer-tile',
  templateUrl: './customer-tile.component.html',
  styleUrls: ['./customer-tile.component.scss']
})
/** Customer Tile shows the information identifying the user and his currently selected customer-account */
export class CustomerTileComponent implements OnInit, OnChanges {
  @Input() nameShown: boolean = false;
  @Input() accounts: Array<Account>;
  @Input() account: AccountInfo;
  @Input() isRestricted: boolean;
  @Output() customerSelected: EventEmitter<string> = new EventEmitter();

  preSelectedCustomerNumber: string;
  accountsDropdown: Array<DropdownItem>;
  TRACKING = TRACKING;

  constructor(
    public kundenStore: CustomerStoreService, public tenantService: TenantService
  ) { }

  ngOnInit() {
    this.preSelectedCustomerNumber = this.kundenStore.getAccountId();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.accounts && changes.accounts.currentValue) {
      this.accountsDropdown = this.accounts.map(val => {
        let mappedValue = val.accountId;
        if (val.inaktiv) {
          mappedValue += ' (inaktiv)';
        }
        return { 'label': mappedValue, 'value': val.accountId };
      });
    }
  }

  updateKundennummer(selected: string) {
    this.kundenStore.setCurrentAccountIdIsInactive(this.accounts.find((item) => item.accountId === selected)?.inaktiv);
    this.kundenStore.setAccountId(selected);
    this.customerSelected.emit(selected);
  }

  getDivisionsAsStrings(divisions: Array<DivisionItem>) {
    return divisions?.map((item: DivisionItem) => item.name);
  }

}
