import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PaymentFormtype } from '../../../../shared/formtypes/payment-formtype';
import { BdoApiService } from '../../../services/bdo-api.service';
import { BankAccount } from '../../../../../assets/js/com/ts_api_client';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { ToastService } from '../../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-personal-data-banking-form',
  templateUrl: './personal-data-banking-form.component.html',
  styleUrls: ['./personal-data-banking-form.component.scss']
})
export class PersonalDataBankingFormComponent {
  @Input() formWithSepaBox: boolean = false;
  @Output() editDone: EventEmitter<BankAccount> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  public paymentForm = new FormGroup<PaymentFormtype>({ });
  public LoadingState = LOADING_STATE;
  public state = LOADING_STATE.IDLE;
  private bankAccountResult: BankAccount = null;

  constructor(
    private apiService: BdoApiService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  public onSubmit() {
    if (this.paymentForm.invalid) {
      this.paymentForm.markAllAsTouched();
      return;
    }

    this.state = LOADING_STATE.LOADING;

    const bankAccount: BankAccount = {
      iban: this.paymentForm.value.bankData.iban,
      accountholder: this.paymentForm.value.bankData.accountholder,
      bankname: this.paymentForm.value.bankData.bankname
    };

    this.apiService.postBankAccount(bankAccount).subscribe({
      next: (bankAccountResult: BankAccount) => {
        this.state = LOADING_STATE.IDLE;
        this.toastService.pushToast({
          headline: this.translateService.instant('personaldata.banking.success')
        });
        this.bankAccountResult = bankAccountResult;
        this.editDone.emit(this.bankAccountResult);
      }, error: () => {
        this.state = LOADING_STATE.ERROR;
      }
    });
  }

}
