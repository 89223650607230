<div class="re-flex re-flex-col md:re-divide-x md:re-divide-monochrome-medium-light md:re-text-m md:re-leading-l lg:re-text-l lg:re-leading-2xl
            md:re-flex-row lg:re-mx-30 2xl:re-mx-auto 2xl:re-w-1380" bdoRouterLinkDirective>
  <button class="bold re-py-20 re-pl-30 re-flex-auto re-left re-text-left re-border-solid re-border-t re-border-b-0 re-border-monochrome-medium-light
                md:re-flex-1 md:re-grid md:re-px-1 md:re-pl-0 md:re-py-30 md:re-border-b md:re-text-center re-group"
     (click)="goToMeterReading()">
    <div class="re-inline-flex re-align-middle re-items-center md:re-block">
      <div class="bdo-area-icon re-inline-block re-ml-30 md:re-ml-0 md:re-block">
        <svg-icon src="{{ 'dashboard.frequentFunctions.img.counter' | translate }}"></svg-icon>
      </div>
      <div class="bold re-inline-block re-ml-20 md:re-block md:re-mt-15 md:re-ml-0 group-hover:re-text-secondary-hover"
        [innerHTML]="'dashboard.frequentFunctions.meterreading' | translate">
      </div>
    </div>
  </button>

  <button class="bold re-py-20 re-pl-30 re-flex-auto re-left re-text-left re-border-solid re-border-t re-border-b-0 re-border-monochrome-medium-light
            md:re-flex-1 md:re-px-1 md:re-pl-0 md:re-py-30 md:re-border-b md:re-text-center re-group"
     [ngClass]="moveAvailable ? 're-pointer-events-auto' : 're-pointer-events-none'"
     data-qa="dashboard_move_btn"
     (click)="onGoToMove()">
    <div class="button__content--disabled re-inline-flex re-align-middle re-items-center md:re-block" [ngClass]="{'re-opacity-50': (!moveAvailable)}">
      <div class="bdo-area-icon re-inline-block re-ml-15 md:re-block md:re-ml-0">
        <svg-icon src="{{ 'dashboard.frequentFunctions.img.move' | translate }}"></svg-icon>
      </div>
      <div class="word-new-line re-inline-block re-ml-20 md:re-block md:re-mt-15 md:re-ml-0 group-hover:re-text-secondary-hover"
           [innerHTML]="'dashboard.frequentFunctions.move' | translate">
      </div>
    </div>
  </button>

  <a class="bold re-py-20 re-pl-30 re-flex-auto re-text-left re-border-solid re-border-t re-border-b re-border-monochrome-medium-light
                md:re-flex-1 md:re-px-1 md:re-pl-0 md:re-py-30 md:re-border-b md:re-text-center re-group"
    [ngClass]="changeAvailable ? 're-pointer-events-auto' : 're-pointer-events-none'"
    [routerLink]="canAddTariff ? '/vertrag/' + accountId : '/baustelle'"
    data-qa="dashboard_change_btn"
    (click)="trackingService.postTracking(TRACKING.LOCATION.FREQUENT_FUNCTIONS, TRACKING.ACTION.GOTO, TRACKING.LOCATION.CONTRACT_OVERVIEW)">
    <div class="re-inline-flex re-align-middle re-items-center md:re-block" [ngClass]="{'re-opacity-50': (!changeAvailable)}">
      <div class="bdo-area-icon re-inline-block md:re-block">
        <svg-icon src="{{ 'dashboard.frequentFunctions.img.changeContract' | translate }}"></svg-icon>
      </div>
      <div class="re-inline-block re-ml-20 md:re-block md:re-mt-15 md:re-mx-auto md:re-max-w-230 lg:re-max-w-300 group-hover:re-text-secondary-hover"
        [innerHTML]="'dashboard.frequentFunctions.changeContract' | translate">
      </div>
    </div>
  </a>
</div>
