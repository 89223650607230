import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AddressData, CombinedAddressData, CorrespondenceAddressData, PartnerAddressData } from '../../../../../assets/js/com/ts_api_client';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { BdoApiService } from '../../../services/bdo-api.service';
import { CustomerStoreService } from '../../../services/customer-store.service';
import { ToastService } from '../../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-personal-data-address',
  templateUrl: './personal-data-address.component.html',
  styleUrls: ['./personal-data-address.component.scss']
})
export class PersonalDataAddressComponent implements OnInit {
  @Input() disabled: boolean;
  @Output() edit: EventEmitter<boolean> = new EventEmitter();
  public LoadingState = LOADING_STATE;
  public state = LOADING_STATE.IDLE;
  public isEditingCorrespondenceAddress = false;
  public isEditingPartnerAddress = false;
  public postalAddresses: CombinedAddressData;
  public deliveryAddress: AddressData;
  public partnerAddress: PartnerAddressData;
  public correspondenceAddress: CorrespondenceAddressData;
  public isPopupShown: boolean = false;

  constructor(
    private apiService: BdoApiService,
    public customerStore: CustomerStoreService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.getPostalData();
  }

  getPostalData() {
    this.state = LOADING_STATE.LOADING;
    this.apiService.getUserPostalAddresses()
    .subscribe(
      {
        next: (res) => {
          this.postalAddresses = res;
          this.deliveryAddress = res?.deliveryAddressData;
          this.partnerAddress = res?.partnerAddressData;
          this.correspondenceAddress = res?.correspondenceAddressData;
          this.state = LOADING_STATE.IDLE;
        },
        error: () => {
          this.state = LOADING_STATE.ERROR;
        }
      });
  }

  onDelete() {
    this.state = LOADING_STATE.LOADING;
    this.apiService.deleteUserCorrespondenceAddress()
    .subscribe(
      {
        next: () => {
          this.state = LOADING_STATE.IDLE;
          this.correspondenceAddress = null;
          this.postalAddresses.correspondenceAddressData = null;
          this.toastService.pushToast({
            headline: this.translateService.instant('personaldata.address.correspondence.delete')
          });
        },
        error: () => {
          this.state = LOADING_STATE.ERROR;
        }
      }
    );
  }

  onEditDone() {
    this.isEditingPartnerAddress = false;
    this.isEditingCorrespondenceAddress = false;
    this.getPostalData();
    this.edit.emit(false);
  }

}
