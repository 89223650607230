/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * containing moveType for a specific division
 */
export interface ContractMoveType { 
    /**
     * division of product e.g. S4 for power, G6 for gas or T4 for water
     */
    divisionId?: string;
    /**
     * identifier specifying the move type
     */
    moveType?: string;
    /**
     * url to partner company for referral
     */
    otherTenantUrl?: string;
}

