<div class="re-bg-white re-text re-w-full re-mb-3
              md:re-w-600 md:re-mx-auto
              lg:re-w-900" [attr.data-qa]="title">
  <div class="re-flex re-flex-col
              md:re-flex-row">
    <div class="re-w-full re-flex re-px-20 re-pt-20
                 md:re-px-30 md:re-w-2/5 md:re-justify-center md:re-items-center md:re-border-r-3 md:re-border-monochrome-light
                 lg:re-px-45">

      <svg-icon *ngIf="iconSrc" [applyClass]="true" [src]="iconSrc" class="re-stroke-none re-max-w-230 re-w-full"></svg-icon>
      <img *ngIf="externalIconSrc" class="re-max-w-230 re-w-full" [src]="externalIconSrc" alt=""/>
    </div>

    <!-- Move Tile -->
    <div class="re-w-full re-flex re-flex-col re-mt-30 re-px-20 re-pb-20
                md:re-mt-0 md:re-w-2/3 md:re-px-30 md:re-pt-30
                lg:re-px-45 lg:re-pt-45 lg:re-pb-30 ">
      <h4 class="re-inline">
        {{title}}
      </h4>
      <p class="re-mt-5 re-flex-1 re-mb-30 md:re-mt-15 md:re-mb-60">
        <ng-content></ng-content>
      </p>
      <div class="first:re-mt-0 re-mt-10" *ngFor="let item of linkData" >
        <bdo-text-link-icon
          [iconPosition]="'right'"
          [linkText]="item.linkName"
          [openInNewTab]="true"
          [href]="item.href"
          [tracking]="item?.tracking"
        ></bdo-text-link-icon>
      </div>

    </div>

  </div>
</div>
