import { Injectable } from '@angular/core';
import { VerbrauchstypPipe } from '../pipes/verbrauchstyp.pipe';
import { VERBRAUCHSTYP } from '../enums/verbrauchstyp.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MeterreadingInfoService {

  constructor(
    private translateService: TranslateService,
    private verbrauchstypPipe: VerbrauchstypPipe
  ) { }

  getInfo(divisionId: string): string {
    const division: VERBRAUCHSTYP = this.verbrauchstypPipe.transform(divisionId, false);
    const divisionTranslated: string = this.verbrauchstypPipe.transform(divisionId);

    if (divisionId === VERBRAUCHSTYP.Gas || divisionId === VERBRAUCHSTYP.Strom) {
      return `<div>${this.translateService.instant('meterreadings.info.specified', { division: divisionTranslated, unit: divisionId === VERBRAUCHSTYP.Strom ? 'kWh' : 'm&sup3;' })}</div>
      <div class="re-flex re-flex-row re-mt-15 mobile-only">
        <img src="assets/img/meter-tooltip-${division.toLowerCase()}-s.png" alt="Aufbau von Zählern">
      </div>
      <div class="re-flex re-flex-row re-mt-15 tablet-up-only">
        <img src="assets/img/meter-tooltip-${division.toLowerCase()}-xl.png" alt="Aufbau von Zählern">
      </div>
      `;
    } else {
      return this.translateService.instant('meterreadings.info.unspecified');
    }
  }

}
