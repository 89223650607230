import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TrackingService } from '../../bdo/services/tracking.service';
import { LoginService } from '../login.service';
import { TRACKING } from '../../bdo/enums/trackingParts.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { defaultValidatorProxy } from '../../shared/validators/default-validator-proxy';
import { INPUT_TYPE } from '../../bdo/enums/inputType.enum';
import { TranslateService } from '@ngx-translate/core';
import { ResetPasswordOutput } from 'aws-amplify/auth';

@Component({
  selector: 'bdo-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.scss']
})
export class PasswordForgotComponent implements OnInit {
  public TRACKING = TRACKING;
  public errorShown: boolean = false;
  public mailSent: boolean = false;
  public INPUT_TYPE = INPUT_TYPE;
  public sendingData: boolean = false;

  public forgotPasswordForm = new FormGroup({
    username: new FormControl('', [
      defaultValidatorProxy(Validators.required, this.translateService.instant('register.username.required')),
    ])
  });
  public resetPasswordResult: ResetPasswordOutput;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private trackingService: TrackingService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.trackingService.postTracking(TRACKING.LOCATION.PASSWORD_FORGOT, TRACKING.ACTION.ENTER);
  }

  send() {
    if (this.forgotPasswordForm.valid) {
      this.sendingData = true;
      this.loginService.resetPassword(this.forgotPasswordForm.get('username').value)
        .subscribe({ next: res => {
          this.resetPasswordResult = res;
          this.sendingData = false;
          this.errorShown = false;
          this.mailSent = true;
          this.trackingService.postTracking(TRACKING.LOCATION.PASSWORD_FORGOT, TRACKING.ACTION.SUCCESS);
        }, error: (error: unknown) => {
          this.trackingService.postTracking(TRACKING.LOCATION.PASSWORD_FORGOT, TRACKING.ACTION.ERROR);
          this.errorShown = true;
          this.sendingData = false;
        } });
    } else {
      this.forgotPasswordForm.markAllAsTouched();
    }
  }

  closeMessage() {
    this.mailSent = false;
    this.trackingService.postTracking(TRACKING.LOCATION.PASSWORD_FORGOT, TRACKING.ACTION.GOTO, TRACKING.LOCATION.LOGIN);

    this.router.navigate(['/passwort-erneuern']);
  }

}
