<div class="re-container-headline bdo-space--bottom">
	<h3>
		{{ 'confirmation.cancel.title' | translate }}
  </h3>
</div>

<div class="re-container-with-margin">
  <div class="re-mb-30 re-mt-30 md:re-mb-45 md:re-mt-45 re-copytext">
    {{ 'confirmation.end.description' | translate: { process: 'end' } }}
  </div>

  <div class="re-mb-30 md:re-mb-45">
    <h2>
      {{ 'confirmation.title' | translate }}
    </h2>
  </div>

  <ul class="bdo-list__bullet_bigger re-list-item re-mb-45 lg:re-mb-60">
    <li class="re-mb-5 md:re-mb-10" [innerHTML]="'confirmation.end.acknowledgment' | translate: { emailAddress: emailAddress }"></li>
    <li [innerHTML]="'confirmation.end.cancellationDate' | translate: { endDate: contractEndDate | date }"></li>
    <li [innerHTML]="'confirmation.end.secureSupply' | translate: { division: divisionsForInfo }"></li>
    <li>{{ 'confirmation.end.onlineAccount' | translate }}</li>
  </ul>

  <bdo-external-tenant-tile [externalTenantConfiguration]="externalTenantConfiguration"></bdo-external-tenant-tile>

  <div class="re-container-with-margin re-mt-45 md:re-mt-60">
    <bdo-text-link-icon
      [iconPosition]="'right'"
      [linkText]="'dashboard.linkTo' | translate"
      [routerUrl]="'/dashboard'"
    ></bdo-text-link-icon>
  </div>
</div>
