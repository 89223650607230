/**
 * One of defined aggregation types.
 */
export type TimeAggregation = 'ALL' | 'YEARLY' | 'MONTHLY' | 'DAILY';

export const TimeAggregation = {
  ALL: 'ALL' as TimeAggregation,
  YEARLY: 'YEARLY' as TimeAggregation,
  MONTHLY: 'MONTHLY' as TimeAggregation,
  DAILY: 'DAILY' as TimeAggregation
};
