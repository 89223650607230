import { AuthDataStorage } from '../models/AuthData.storage';
import { AddressData, CampaignInfo, ContractDetails, ContractPaymentData, SituationData, TariffData, Vouchers } from '../../../assets/js/com/ts_api_client';
import { TrackingPixelData } from '../models/trackingPixelData';
import * as _ from 'lodash';
import { OFFER_CONTEXT, OFFER_CONTEXT_SPECIAL } from '../enums/offer-context';
import { LegalData } from '../models/legalData';
import { StoragePersonalData } from '../models/storagePersonalData';
import { TariffSelection } from '../../shared/models/tariff-selection';
import { DateUtils } from '../../shared/utils/dateUtils';
export enum ALLOWED_KEYS {
  LEGAL_DATA = 'legalData',
  TARIFF_DATA = 'tariffData',
  ADDRESS_DATA = 'addressData',
  AVAILABLE_DIVISIONS = 'availableDivisions', // type AvailableDivisionInfos
  PERSONAL_DATA = 'personalData',
  SITUATION_DATA = 'situationData',
  TARIFF_SELECTION = 'tariffSelection', // type TariffSelection[]
  PAYMENT_DATA = 'paymentData',
  AUTH_DATA = 'authData', // type AuthDataStorage
  RECENT_HOME_DATA = 'recentHomeData',
  TRACKINGPIXEL_DATA = 'trackingPixelData',
  TP_TOKEN = 'trustpilotSecureToken', // Trustpilot token
  HAS_EMAIL = 'hasEmail',
  OLD_CONTRACT = 'oldContract',
  BILLING_ADDRESS = 'billingAddress',
  CAMPAIGNS = 'campaigns',
  OFFER_CONTEXT_DATA = 'offerContext',
  OFFER_ID = 'offerId',
  OFFER_VOUCHERS = 'offerVouchers',
  POLL_HAS_BEEN_SHOWN = 'pollShown',
  OPT_IN_POPUP_DISMSSED = 'optinPopupDismissed',
  CHARGE_FLEX = 'isChargeFlex'
}

export class StorageService {

  public static getValue<T>(storageKey: ALLOWED_KEYS, storage: Storage = window.sessionStorage): T | undefined {
    try {
      const content = JSON.parse(storage.getItem(storageKey));
      // Deserialize Dates
      return DateUtils.convertToDate(content);
    } catch (error) {
      return undefined;
    }
  }

  public static addUnique<T>(storageKey: ALLOWED_KEYS, item: T, storage: Storage = window.sessionStorage): Array<T> {
    const arrayOfItems = this.getValue<Array<T>>(storageKey, storage) || [];
    const newItems = _.union(arrayOfItems, [item]);
    this.setValue(storageKey, newItems, storage);
    return newItems;
  }
  public static remove<T>(storageKey: ALLOWED_KEYS, match: any, storage: Storage = window.sessionStorage): Array<T> {
    const arrayOfItems = this.getValue<Array<T>>(storageKey);
    const newItems =  _.without(arrayOfItems, _.find(arrayOfItems, match));
    this.setValue(storageKey, newItems, storage);
    return newItems;
  }

  public static setValue<T>(storageKey: ALLOWED_KEYS, value: T, storage: Storage = window.sessionStorage): void {
    storage.setItem(storageKey, JSON.stringify(value));
  }

  public static setProperty<T>(storageKey: ALLOWED_KEYS, key: keyof T, value: any, storage: Storage = window.sessionStorage) {
    const current = StorageService.getValue(storageKey);
    if (!current) {
      this.setValue(storageKey, {
        [key]: value
      }, storage);
    } else {
      current[key] = value;
      this.setValue(storageKey, current, storage);
    }
  }

  public static clearValues(storageKeys: ALLOWED_KEYS[], storage: Storage = window.sessionStorage): void {
    storageKeys.forEach((key) => { storage.removeItem(key); });
  }

  public static setPersonalData(personalData: StoragePersonalData) {
    StorageService.setValue(ALLOWED_KEYS.PERSONAL_DATA, personalData);
  }

  public static getPersonalData(): StoragePersonalData {
    return StorageService.getValue<StoragePersonalData>(ALLOWED_KEYS.PERSONAL_DATA);
  }

  public static setSituationData(situationData: SituationData) {
    StorageService.setValue(ALLOWED_KEYS.SITUATION_DATA, situationData);
  }

  public static getTariffSelections(): TariffSelection[] {
    return StorageService.getValue<TariffSelection[]>(ALLOWED_KEYS.TARIFF_SELECTION);
  }

  public static setTariffSelections(tariffSelection: TariffSelection[]): void {
    StorageService.setValue<TariffSelection[]>(ALLOWED_KEYS.TARIFF_SELECTION, tariffSelection);
  }

  public static clearTariffSelections(){
    StorageService.clearValues([ALLOWED_KEYS.TARIFF_SELECTION]);
  }

  public static setTrustPilotToken(trustpilotSecureToken: string){
    StorageService.setValue<string>(ALLOWED_KEYS.TP_TOKEN, trustpilotSecureToken);
  }

  public static getTrustPilotToken(){
    return StorageService.getValue<string>(ALLOWED_KEYS.TP_TOKEN);
  }

  public static getFirstTariffSelection(): TariffSelection {
    return StorageService.getTariffSelections()?.[0];
  }

  /**
   * Updates the situation data with the given data, keeps existing data
   * @param situationData
   */
  public static updateSituationData(situationData: Partial<SituationData>) {
    StorageService.setValue(ALLOWED_KEYS.SITUATION_DATA, {
      ...StorageService.getSituationData(),
      ...situationData
    });
  }

  public static setPollShown(shown: boolean, storage: Storage = window.sessionStorage): void {
    storage.setItem(ALLOWED_KEYS.POLL_HAS_BEEN_SHOWN, JSON.stringify(shown));
  }

  public static pollHasBeenShownInCurrentSession(storage: Storage = window.sessionStorage): boolean {
    const value = storage.getItem(ALLOWED_KEYS.POLL_HAS_BEEN_SHOWN);
    return value ? JSON.parse(value) : false;
  }

  public static getSituationData(): SituationData {
    return StorageService.getValue<SituationData>(ALLOWED_KEYS.SITUATION_DATA);
  }

  public static setPaymentData(paymentData: ContractPaymentData) {
    StorageService.setValue(ALLOWED_KEYS.PAYMENT_DATA, paymentData);
  }

  public static getPaymentData(): ContractPaymentData{
    return StorageService.getValue<ContractPaymentData>(ALLOWED_KEYS.PAYMENT_DATA);
  }

  public static setTariffData(tariffData: TariffData[]) {
    StorageService.setValue(ALLOWED_KEYS.TARIFF_DATA, tariffData);
  }

  public static getTariffData(): TariffData[] {
    return StorageService.getValue<TariffData[]>(ALLOWED_KEYS.TARIFF_DATA);
  }

  public static clearTariffData() {
    StorageService.clearValues([ALLOWED_KEYS.TARIFF_DATA]);
  }

  public static getAddressData(): AddressData {
    return StorageService.getValue<AddressData>(ALLOWED_KEYS.ADDRESS_DATA);
  }

  public static setAuthData(authData: AuthDataStorage) {
    return StorageService.setValue(ALLOWED_KEYS.AUTH_DATA, authData);
  }

  public static getAuthData(): AuthDataStorage {
    return StorageService.getValue<AuthDataStorage>(ALLOWED_KEYS.AUTH_DATA);
  }

  public static getOfferContext(): OFFER_CONTEXT | OFFER_CONTEXT_SPECIAL {
    return StorageService.getValue<OFFER_CONTEXT | OFFER_CONTEXT_SPECIAL>(ALLOWED_KEYS.OFFER_CONTEXT_DATA);
  }

  public static setOfferContext(context: OFFER_CONTEXT | OFFER_CONTEXT_SPECIAL){
    return StorageService.setValue(ALLOWED_KEYS.OFFER_CONTEXT_DATA, context?.toString());
  }

  static clearOfferContext() {
    StorageService.clearValues([ALLOWED_KEYS.OFFER_CONTEXT_DATA]);
  }

  public static getChargeFlexContext(): boolean {
    return !!StorageService.getValue(ALLOWED_KEYS.CHARGE_FLEX);
  }

  public static setChargeFlexContext(storage: Storage = window.sessionStorage): void {
    storage.setItem(ALLOWED_KEYS.CHARGE_FLEX, JSON.stringify(true));
  }

  static clearChargeFlexContext() {
    StorageService.clearValues([ALLOWED_KEYS.CHARGE_FLEX]);
  }

  public static getOfferVouchers(): Vouchers {
    return StorageService.getValue<Vouchers>(ALLOWED_KEYS.OFFER_VOUCHERS);
  }

  public static setOfferVouchers(vouchers: Vouchers){
    return StorageService.setValue(ALLOWED_KEYS.OFFER_VOUCHERS, vouchers);
  }

  static clearOfferVouchers() {
    StorageService.clearValues([ALLOWED_KEYS.OFFER_VOUCHERS]);
  }

  public static getCampaignsData(): Array<Partial<CampaignInfo>> {
    return StorageService.getValue<Array<Partial<CampaignInfo>>>(ALLOWED_KEYS.CAMPAIGNS, window.localStorage);
  }

  public static setOptInDismissed(accountId: string) {
    if (!accountId) {
      return;
    }
    return StorageService.addUnique(ALLOWED_KEYS.OPT_IN_POPUP_DISMSSED, accountId, window.localStorage);
  }

  public static getOptInDismissed(accountId: string): boolean {
    const dismissedAccounts = StorageService.getValue<string[]>(ALLOWED_KEYS.OPT_IN_POPUP_DISMSSED, window.localStorage) || [];
    return dismissedAccounts ? dismissedAccounts.includes(accountId) : true;
  }

  public static setLegalData(legalData: LegalData) {
    return StorageService.setValue(ALLOWED_KEYS.LEGAL_DATA, legalData);
  }

  public static getLegalData(): LegalData {
    return StorageService.getValue<LegalData>(ALLOWED_KEYS.LEGAL_DATA);
  }

  public static getOldContract(): ContractDetails {
    return StorageService.getValue<ContractDetails>(ALLOWED_KEYS.OLD_CONTRACT);
  }

  public static setOldContract(oldContract: ContractDetails) {
    return StorageService.setValue(ALLOWED_KEYS.OLD_CONTRACT, oldContract);
  }

  public static clearOldContract(){
    return StorageService.clearValues([ALLOWED_KEYS.OLD_CONTRACT]);
  }

  public static addCampaign(campaign: CampaignInfo): Array<Partial<CampaignInfo>> {
    return this.addUnique<Partial<CampaignInfo>>(ALLOWED_KEYS.CAMPAIGNS, { bannerId: campaign.bannerId, accountId: campaign.accountId }, window.localStorage);
  }

  public static removeCampaign(campaign: CampaignInfo): Array<Partial<CampaignInfo>> {
    return this.remove<Partial<CampaignInfo>>(ALLOWED_KEYS.CAMPAIGNS, { bannerId: campaign.bannerId, accountId: campaign.accountId }, window.localStorage);
  }

  public static getTrackingPixelData(): TrackingPixelData | null {
    const pixelData = StorageService.getValue<TrackingPixelData>(ALLOWED_KEYS.TRACKINGPIXEL_DATA);
    if (!pixelData?.ad_start) {
      return null;
    }
    const dateControl: Date = new Date(Date.parse(pixelData.ad_start));
    dateControl.setDate(dateControl.getDate() + pixelData?.ad_duration);
    if (dateControl >= new Date(Date.now())) {
      return pixelData;
    } else {
      return null;
    }
  }

  static setOfferId(offerId: string) {
    return StorageService.setValue(ALLOWED_KEYS.OFFER_ID, offerId);
  }

  static getOfferId() {
    return StorageService.getValue<string>(ALLOWED_KEYS.OFFER_ID);
  }

  static clearOfferId() {
    StorageService.clearValues([ALLOWED_KEYS.OFFER_ID]);
  }

  /*
  * This should be done in a guard when leaving the processes and preventing the user from going back in without the data
  * Clearing only the temporary contract data needed for a contract process. Leaving optIn or PollShown untouched
  */
  static clearAllContractData() {
    StorageService.clearChargeFlexContext();
    StorageService.clearOfferContext();
    StorageService.clearOfferId();
    StorageService.clearOfferVouchers();
    StorageService.clearOldContract();
    StorageService.clearTariffData();
    StorageService.clearTariffSelections();
  }
}
