<output *ngIf="fadeOut==='in' && toastService.currentToast()" [@slideIn] [@slideOut]
  class="re-fixed re-top-0 re-right-0 re-z-30 infoBox re-flex re-flex-col re-w-full md:re-w-300 md:re-top-30 md:re-right-30">
  <div class="re-flex" [ngClass]="[toastService.currentToast()?.type]">
    <bdo-info-box
      [marginBottom]="false"
      [headline]="toastService.currentToast().headline"
      [linkText]="toastService.currentToast().linktext"
      [variant]="'toast'"
      [type]="toastService.currentToast()?.type">
      {{toastService.currentToast()?.content}}
    </bdo-info-box>
    <button *ngIf="toastService.currentToast().closable"
      class="re-w-15 re-h-15 re-mt-10 re-mr-10 re-ml-auto re-cursor-pointer"
      type="button"
      title="{{ 'general.action.close' | translate }}"
      (click)="onClose(true)">
      <svg-icon class="re-w-15 re-h-15 re-block re-leading-3 re-text-monochrome-dark" src="assets/icons/close.svg"></svg-icon>
    </button>
  </div>
  <div class="re-h-3 re-relative re-bg-white" *ngIf="toastService.currentToast().durationIndicator">
    <div class="re-h-3 progress-bar"></div>
  </div>
</output>
