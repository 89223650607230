<div class="stage">
  <h1 [innerHTML]="'termsOfService.title' | translate"></h1>
</div>

<div class="bdo-container">

  <div class="space--bottom re-big">
    {{ 'termsOfService.service' | translate }}
  </div>
    <div class="bdo-section-headline">
      <bdo-section-headline>{{ 'termsOfService.subtitle' | translate:
                               { companyName: tenantService.getCurrentTenantData().companyName} }}</bdo-section-headline>
    </div>
  <div class="bdo-list" [innerHTML]="tenantService.getTosTemplate()"></div>
</div>
