import { Component, Input } from '@angular/core';

export interface PromoLinkData {
  linkText: string;
  href?: string;
  routerUrl?: string;
  openInNewTab?: boolean;
}

@Component({
  selector: 'bdo-promo-link-wrapper',
  templateUrl: './promo-link-wrapper.component.html',
  styleUrls: ['./promo-link-wrapper.component.scss']
})

export class PromoLinkWrapperComponent {
  @Input() promoLinkDataSet: Array<PromoLinkData>;
  @Input() inContainer: boolean = false;
}
