<a *ngIf="routerUrl; else externalLink"
  class="link-icon re-group"
  [routerLink]="routerUrl"
  [fragment]="fragment"
  [state]="extraData ? extraData : null" >
  <ng-container *ngTemplateOutlet="inner"></ng-container>
</a>
<ng-template #externalLink>
  <a *ngIf="href" [href]="href" class="link-icon re-group" [target]="openInNewTab? '_blank' : '_self'">
    <ng-container *ngTemplateOutlet="inner"></ng-container>
  </a>
</ng-template>

<ng-template #inner>
  <div (click)="trackIt()" class="re-flex re-flex-row re-items-center group-hover:re-text-primary group-focus:re-text-primary">
    <div *ngIf="iconPosition === 'left'">
      <div class="re-mr-15 re-text-primary"
        [ngClass]="{'re-animate-translate group-hover:-re-translate-x-5 group-focus:-re-translate-x-5' : !customIconSrc,
                    're-w-25 re-h-25' : size === 'lg', 're-w-15 re-h-15 ' : size === 's'}">
        <svg-icon class="custom-icon re-flex" [src]="customIconSrc || 'assets/icons/arrow-left.svg'"></svg-icon>
      </div>
    </div>
    <div class="bold re-leading-s"
      [ngClass]="{'re-text-sm' : size === 'lg', 're-text-xs' : size === 's'}">
      {{linkText}}
    </div>
    <div *ngIf="iconPosition === 'right'">
      <div class="re-ml-15 re-text-primary"
        [ngClass]="{'re-animate-translate group-hover:re-translate-x-5 group-focus:re-translate-x-5' : !customIconSrc,
                    're-w-25 re-h-25' : size === 'lg', 're-w-15 re-h-15 ' : size === 's'}">
        <svg-icon class="custom-icon re-flex" [src]="customIconSrc || 'assets/icons/arrow-right.svg'"></svg-icon>
      </div>
    </div>
  </div>

</ng-template>
