import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ZaehlerIdentifier } from '../../../assets/js/com/ts_api_client';
import { TranslateService } from '@ngx-translate/core';

export function activeMeterValidator(meterIdentifier: ZaehlerIdentifier[], translateService: TranslateService): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    let isValid = true;
    let errorMessage = '';
    if (!meterIdentifier?.length) { return null; }

    if (control.value) {
      const meternumber = control.value as string;

      if (meterIdentifier.find(element => element.meterNumber === meternumber)) {
        isValid = false;
        errorMessage = translateService.instant('meterreadings.error.alreadyRegistered');
      }
    }

    return isValid ? null : { 'activeMeter': errorMessage };
  };
}
