<h3 class="bdo-container re-mb-30 lg:re-mb-75">{{ 'login.serviceName' | translate }}</h3>

<bdo-login title="{{ 'login.title' | translate }}">

  <div class="re-mb-5">
    {{ 'register.integrated.subtitle2' | translate }}
  </div>
  <bdo-text-link-icon
    [iconPosition]="'right'"
    [linkText]="'register.integrated.submit' | translate"
    [routerUrl]="'/registrieren'"
  ></bdo-text-link-icon>
</bdo-login>

<!-- <div class="bdo-container--single re-mb-30 lg:re-mb-75">
  <h4>{{tenantService.getString('login.text.registerTitle')}}</h4>
  <p>{{ 'register.login.subtitle' | translate }}</p>
  <div [innerHtml]="tenantService.getString('login.text.registerList')"></div>

  <bdo-text-link-iconPosition [ngClass]="'bdo-form__helptext'"
    [iconPosition]="'right'"
    [linkText]="'register.linkTo' | translate"
    [routerUrl]="'/registrieren'"
    [tracking]="[TRACKING.LOCATION.LOGIN, TRACKING.ACTION.GOTO, TRACKING.LOCATION.REGISTER]"
  ></bdo-text-link-iconPosition>
</div> -->

<h2 class="re-container-with-margin re-mb-20 md:re-mb-30">
  {{ 'register.login.subtitle' | translate }}
</h2>
<div class="re-container-with-margin re-mb-30 lg:re-mb-75">
  <ul class="bdo-list__bullet_bigger re-text-sm re-leading-m re-mb-45 lg:re-text-base lg:re-leading-2xl">
    <li class="re-mb-10">
      {{ 'register.benefit.1' | translate }}
    </li>
    <li class="re-mb-10">
      {{ 'register.benefit.2' | translate }}
    </li>
    <li class="re-mb-10">
      {{ 'register.benefit.3' | translate }}
    </li>
    <li class="re-mb-10">
      {{ 'register.benefit.4' | translate }}
    </li>
    <li>
      {{ 'register.benefit.5' | translate }}
    </li>
  </ul>
</div>

<div class="bdo-area">

  <a class="bdo-area-button bold" (click)="trackingService.postTracking(TRACKING.LOCATION.LOGIN, TRACKING.ACTION.GOTO, TRACKING.LOCATION.ANONYMOUS_METERREADINGS)" routerLink="/zaehlerstand/erfassen">
    <div class="bdo-area-icon">
      <svg-icon src="{{ 'register.img.counter' | translate }}"></svg-icon>
    </div>
    <div class="bold">{{ 'meterreadings.actions.enterMeterreading'  | translate }}</div>
  </a>
  <a class="bdo-area-button bold" (click)="trackingService.postTracking(TRACKING.LOCATION.LOGIN, TRACKING.ACTION.GOTO, TRACKING.LOCATION.MOVE_AUTH)" routerLink="/umzug/auth">
    <div class="bdo-area-icon">
      <svg-icon src="{{ 'register.img.move' | translate }}"></svg-icon>
    </div>
    <div [innerHTML]="'move.linkTo' | translate"></div>
  </a>
</div>
