<div class="re-mb-30">
  <p class="bold re-text-s re-mb-30"
     [innerHTML]="'register.delivery.subtitle' | translate"></p>
  <div [innerHTML]="'register.benefit.delivery' | translate"></div>
</div>

<div class="re-mb-30">
  {{ 'register.delivery.description' | translate }}
</div>

<fieldset>

  <div class="re-form-item-container">
    <bdo-input [formControl]="$any(registerForm.get(['username']))"
               data-qa="username"
               [help]="'register.username.rules' | translate"
               [validationIconHidden]="state === LoadingState.ERROR ||
                  (!isRequired && !registerForm.get('username')?.value)"
               [placeholder]="'register.username.title' | translate">
    </bdo-input>
  </div>
  <div class="re-form-item-container">
    <bdo-input [formControl]="$any(registerForm?.controls?.passwordGroup?.controls?.password)"
               [validationIconHidden]="state === LoadingState.ERROR || (!isRequired &&
                !registerForm?.controls?.passwordGroup?.controls?.confirmPassword?.value
                && !registerForm?.controls?.username?.value)"
               [inputType]="INPUT_TYPE.PASSWORD"
               data-qa="password"
               [placeholder]="'register.password.title' | translate">
    </bdo-input>
    <bdo-password-strength [passwordNew]="registerForm?.controls?.passwordGroup?.controls?.password?.value"></bdo-password-strength>
  </div>

  <div class="re-form-item-container re-mb-0">
    <bdo-input
      [formControl]="$any(registerForm.controls?.passwordGroup?.controls?.confirmPassword)"
      [validationIconHidden]="state === LoadingState.ERROR || (!isRequired &&
      !registerForm?.controls?.passwordGroup?.controls?.confirmPassword?.value
      && !registerForm?.controls?.username?.value && !registerForm?.controls?.passwordGroup?.controls?.password?.value)"
      [inputType]="INPUT_TYPE.PASSWORD"
      data-qa="password-confirm"
      [placeholder]="'register.password.reenter' | translate">
    </bdo-input>
  </div>
</fieldset>
<div class="re-form-item-container re-mb-60">
  <bdo-checkbox
    [required]="(registerForm.controls?.tosConfirmed?.touched && registerForm?.controls?.tosConfirmed?.invalid)
            || (isRequired && registerForm?.controls?.tosConfirmed?.touched)"
    [errorText]="'register.terms.confirmTosRequired' | translate"
    [checked]="registerForm.controls?.tosConfirmed?.value"
    (change)="registerForm.controls?.tosConfirmed?.setValue(!registerForm.controls?.tosConfirmed?.value)"
    data-qa="dispatch_acceptTOS">
    <div [innerHtml]="'register.terms.confirmTos' | translate"></div>
  </bdo-checkbox>
</div>
