import { Component, Input, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { flatten as _flatten, map as _map, compact as _compact } from 'lodash';
import { Zaehlerhistorie, Ablesung, VertragsIdentifier } from '../../../../../assets/js/com/ts_api_client';
import { BdoApiService } from '../../../services/bdo-api.service';
import { VERBRAUCHSTYP } from '../../../enums/verbrauchstyp.enum';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { TrackingService } from '../../../services/tracking.service';

@Component({
  selector: 'bdo-historie',
  templateUrl: './historie.component.html',
  styleUrls: ['./historie.component.scss']
})

export class HistorieComponent implements OnInit, OnChanges {
  @Input() contractIdentifiers: VertragsIdentifier;
  @Input() vertragNummer: string;
  historie: Array<Zaehlerhistorie> = [];
  showTable: boolean = null;
  imsysContracts: Array<string> = [];
  sparteEnum = VERBRAUCHSTYP;
  ablesungen: Array<Ablesung> = [];
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;

  constructor(
    private apiService: BdoApiService,
    private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    // Save all contractIDs of imsys-contracts
    this.imsysContracts = this.contractIdentifiers?.aktiv?.filter((contract) => !!contract.imsys).map((contract) => contract.contractId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showContractHistory();
  }

  /**
   * get and show contract-history data
   */
  showContractHistory() {
    this.state = LOADING_STATE.LOADING;

    if (this.vertragNummer && this.contractIdentifiers) {
      this.apiService.getVertragsHistorie(this.vertragNummer).subscribe(
        {
          next: (res) => {
            this.historie = res;
            this.ablesungen = _compact(_flatten(_map(this.historie, 'ablesungen')));

            // show imsys; if imsys n/a in current meters show table
            this.showTable = !_compact(_flatten(_map(res, 'imsys')))[0];
            if (!this.showTable) {
              this.trackingService.postTracking(TRACKING.LOCATION.CONSUMPTION, TRACKING.ACTION.SHOW, TRACKING.LOCATION.IMSYS_CHART);
            }
            this.state = LOADING_STATE.IDLE;
          },
          error: () => {
            this.state = LOADING_STATE.ERROR;
          }
        });
    }
  }

  /**
   * toggle view chart/table for imsys-users
   *
   * @param forceTable
   */
  toggleView(forceTable = false) {
    this.showTable = !this.showTable || forceTable;
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONSUMPTION, TRACKING.ACTION.SHOW + (this.showTable ? 'table' : 'chart'));
  }
}
