<div class="re-space-y-30" *ngIf="!availableDivisionInfos.onlyExternalAreas && !availableDivisionInfos.noAvailableDivisions; else onlyExternal">
  <div *ngFor="let availableDivision of availableDivisionInfos?.availableDivisions">
    <div *ngIf="availableDivision.areaBelongsToCurrentTenant === false || availableDivision.isProvided === false || canceledDivisionIds.includes(availableDivision.divisionId); else areaBelongsToCurrentTenant">
      <div class="re-flex re-flex-row re-items-center">
        <svg-icon [applyClass]="true" class="re-text-brand-primary re-w-30 re-h-30" src="assets/icons/system_icons/alert.svg"></svg-icon>
        <h5 class="re-ml-10">{{availableDivision.divisionId | verbrauchstyp}}</h5>
      </div>
      <div data-qa="tariffs-overview-text-outside" *ngIf="availableDivision.areaBelongsToCurrentTenant === false">
        {{ 'move.newHome.products.notAvailable' | translate:
        { companyName: tenantService.getTenantDataByShort(availableDivision.tenantNameShort)?.companyName,
          atCompany: tenantService.getTenantDataByShort(availableDivision.tenantNameShort)?.tenantForTexts } }}
      </div>
      <div data-qa="tariffs-overview-text-not-provided" *ngIf="availableDivision.isProvided === false">
        {{ 'tariff.overview.noDelivery.outsideSupplyArea' | translate }}
      </div>
      <div data-qa="tariffs-overview-text-not-provided" *ngIf="canceledDivisionIds.includes(availableDivision.divisionId) && !impossibleDivisionIds.includes(availableDivision.divisionId)">
        {{ 'tariff.overview.noDelivery.cancel' | translate: { division: availableDivision.divisionId | verbrauchstyp } }}
      </div>
    </div>
   <ng-template #areaBelongsToCurrentTenant>
     <div class="re-flex re-flex-row re-items-center">
       <svg-icon [applyClass]="true" class="icon re-text-monochrome re-stroke-current re-fill-current re-w-30 re-h-30" src="assets/icons/system_icons/ok-circle.svg"></svg-icon>
       <h5 class="re-ml-10">{{ availableDivision.divisionId | verbrauchstyp }}</h5>
     </div>
     <ng-container #newTariff>
      <div data-qa="tariffs-overview-text">
        {{ 'tariff.overview.deliveryBegin' | translate: { tariffName: getTariffTitleByDivisionId(availableDivision.divisionId) } }}
      </div>
     </ng-container>

   </ng-template>
  </div>
</div>

<ng-template #onlyExternal>
  <div *ngIf="availableDivisionInfos.onlyExternalAreas === true" data-qa="onlyExternal">
    {{ 'tariff.overview.noDelivery.onlyExternal' | translate: { companyName: availableDivisionInfos.tenantNameOfArea } }}
  </div>
  <div *ngIf="availableDivisionInfos.noAvailableDivisions === true" data-qa="no-divisions-available"
       [innerHTML]="'tariff.overview.noDelivery.noDivisionsAvailable' | translate"></div>
</ng-template>
