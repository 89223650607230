import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { defaultValidatorProxy } from '../../../../shared/validators/default-validator-proxy';
import { externalEmailValidator } from '../../../../shared/validators/external-email-validator';
import { BdoApiService } from '../../../services/bdo-api.service';
import { CUSTOMERMODE } from '../../../enums/customerMode';
import { DropdownItem } from '../../../models/dropdownItem';
import { PersonalUserData, Person } from '../../../../../assets/js/com/ts_api_client';
import { StorageService, ALLOWED_KEYS } from '../../../services/storage.service';
import { BillingAddressFormData } from '../../../models/billingAddressFormData';
import { INPUT_TYPE } from '../../../enums/inputType.enum';
import punycode from 'punycode/';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { TrackingService } from '../../../services/tracking.service';
import { PersonalDataContractEndFormType } from '../../../../shared/formtypes/personal-data-contract-end.formtype';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-billing-address',
  templateUrl: './billing-address.component.html',
  styleUrls: ['./billing-address.component.scss']
})
export class BillingAddressComponent implements OnInit, OnDestroy, AfterViewInit {
  public INPUT_TYPE = INPUT_TYPE;
  public titleDropdown: Array<DropdownItem>;
  public forceValidate: boolean = false;
  public userData: PersonalUserData;
  public form = new FormGroup<PersonalDataContractEndFormType>({
    recipient: new FormControl('current-recipient', null),
    email: new FormControl('',
    [
      defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
      defaultValidatorProxy(Validators.email, this.translateService.instant('address.validator.validEmail')),
      externalEmailValidator(this.translateService),
      defaultValidatorProxy(Validators.maxLength(241), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 241 }))
    ])
  });

  private subscriptions = new Subscription();

  constructor(
    private apiService: BdoApiService,
    public location: Location,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private cdref: ChangeDetectorRef,
    private trackingService: TrackingService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_END_BILLING_ADDRESS, TRACKING.ACTION.ENTER);

    this.subscriptions.add(this.form.get('recipient').valueChanges.subscribe({ next: value => {
      if (value === 'new-recipient') {
        this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_END_BILLING_ADDRESS, TRACKING.FORM_ACTION.SELECT, 'Abweichender Rechnungsempfänger');
        this.addNameGroup();
      } else {
        this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_END_BILLING_ADDRESS, TRACKING.FORM_ACTION.SELECT, 'Bisheriger Rechnungsempfänger');
        this.removeNameGroup();
      }
    } }));

    this.apiService.getPersonalUserData().subscribe( { next: res => {
      this.userData = res;
    } });


  }

  ngAfterViewInit(): void {
    // Prefill
    const formData: BillingAddressFormData = StorageService.getValue(ALLOWED_KEYS.BILLING_ADDRESS);
    if (formData) {
      this.form.get('email').setValue(formData.email);
      this.form.get('address').get('street').setValue(formData.streetName);
      this.form.get('address').get('housenumber').setValue(formData.houseNum);
      this.form.get('address').get('postCode').setValue(formData.postCode);
      this.form.get('address').get('city').setValue(formData.cityName);
      this.form.get('recipient').setValue(formData.keepRecipient ? 'current-recipient' : 'new-recipient');
    }
    if (this.form.get('namegroup')) {
      this.form.get('namegroup')?.get('name').setValue(formData.newRecipientGivenName);
      this.form.get('namegroup')?.get('surname').setValue(formData.newRecipientSurname);
    }

    this.apiService.getSalutations(CUSTOMERMODE.PRIV).subscribe( { next: res => {
      this.titleDropdown = res.list.map(
        salutation => new DropdownItem(salutation.id, this.translateService.instant('address.salutation.options.' + salutation.id))
      );
      if (formData?.newRecipientTitle && this.form.get('namegroup')) {
        this.form.get('namegroup')?.get('title').setValue(this.titleDropdown
          .map((item) => item?.value)
          .find((value) => value === formData?.newRecipientTitle)
        );
      }
    } });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  addNameGroup() {
    this.form.addControl('namegroup', new FormGroup({
      title: new FormControl<string>('',
      [
        defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
      ]),
      name: new FormControl<string>('',
      [
        defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
        defaultValidatorProxy(Validators.maxLength(35), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 35 }))
      ]),
      surname: new FormControl<string>('',
      [
        defaultValidatorProxy(Validators.required, this.translateService.instant('general.validator.required')),
        defaultValidatorProxy(Validators.maxLength(35), this.translateService.instant('general.validator.maxLength', { numberOfCharacters: 35 }))
      ])
    }));
    this.form.updateValueAndValidity();
    this.cdref.detectChanges();
  }

  removeNameGroup() {
    this.form.removeControl('namegroup');
  }

  save() {
    this.forceValidate = true;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else {
      const recentRecipients: Array<Person> = [];
      if (this.userData.person1.firstname) {
        recentRecipients.push({
          firstname: this.userData.person1.firstname,
          lastname: this.userData.person1.lastname
        });
      }
      if (this.userData.person2.firstname) {
        recentRecipients.push({
          firstname: this.userData.person2.firstname,
          lastname: this.userData.person2.lastname
        });
      }

      const data: BillingAddressFormData = {
        email: punycode.toUnicode(this.form.get('email').value),
        streetName: this.form.get('address').get('street').value,
        houseNum: this.form.get('address').get('housenumber').value,
        postCode: this.form.get('address').get('postCode').value,
        cityName: this.form.get('address').get('city').value,
        keepRecipient: this.form.get('recipient').value === 'current-recipient',
        recentTitleId: this.userData.titleId,
        recentRecipientPersons: recentRecipients,
        recentRecipientOrg: {
          nameOrg1: this.userData.org?.nameOrg1,
          nameOrg2: this.userData.org?.nameOrg2
        },
        newRecipientTitle: this.form.get('namegroup')?.get('title').value,
        newRecipientGivenName: this.form.get('namegroup')?.get('name').value,
        newRecipientSurname: this.form.get('namegroup')?.get('surname').value,
        partnerKind: this.userData.partnerKind
      };
      StorageService.setValue(ALLOWED_KEYS.BILLING_ADDRESS, data);

      this.trackingService.postSimpleTracking(TRACKING.LOCATION.CONTRACT_END_BILLING_ADDRESS, TRACKING.ACTION.GOTO, 'zusammenfassung');
      this.router.navigate(['../uebersicht'], {
        relativeTo: this.activatedRoute
      });
    }
  }

}
