<form [formGroup]="form" (ngSubmit)="onSubmit()" class="re-relative"
  autocomplete="new-password">
  <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>

  <div class="re-form-item-container re-mb-0" (click)="onFocus()" name="password-change">
    <bdo-info-box *ngIf="state === LoadingState.ERROR"  class="re-mb-30 bdo-form-item-container">
      {{ errorMessage }}
    </bdo-info-box>

    <div class="re-mb-30 md:re-mb-20">
      <div class="re-inline-block md:re-mr-45 bold">{{ 'register.username.title' | translate }}</div>
      <div class="re-block md:re-inline-block">{{username}}</div>
    </div>

    <div class="bdo-form-item-container re-relative">
      <div class="re-form-item-container">
        <bdo-input #oldPassword
                  ngDefaultControl
                  [formControl]="$any(form.get('password'))"
                  [id]="'oldPassword'"
                  [inputType]="INPUT_TYPE.PASSWORD"
                  [validationIconHidden]="validationIconHidden"
                  [labelText]="'register.password.recent' | translate"
                  >
        </bdo-input>
      </div>
    </div>

    <div class="bdo-form-item-container">
      <div class="re-form-item-container">
        <bdo-input #newPassword
                  ngDefaultControl
                  [formControl]="$any(form.get('newPassword'))"
                  [inputType]="INPUT_TYPE.PASSWORD"
                  [validationIconHidden]="validationIconHidden"
                  [labelText]="'register.password.new' | translate"
                  [tooltipContent]="'register.password.help' | translate"
                  >
        </bdo-input>
        <bdo-password-strength [passwordNew]="form.get('newPassword')?.value"></bdo-password-strength>
      </div>
    </div>

    <div class="bdo-form-item-container">
      <div class="re-form-item-container">
        <bdo-input #newPasswordRepeat
                  ngDefaultControl
                  [formControl]="$any(form.get('newPasswordRepeat'))"
                  [inputType]="INPUT_TYPE.PASSWORD"
                  [validationIconHidden]="validationIconHidden"
                  [labelText]="'register.password.newRepeat' | translate"
                  >
        </bdo-input>
      </div>
    </div>

    <div class="re-mb-15 re-mt-45">
      <button class="bdo-button-primary bdo-button--text" type="submit">
        {{ 'general.action.save' | translate }}
      </button>
    </div>

    <button class="bdo-nolink" data-qa="cancel" type="button" (click)="editDone.emit(true);">
      <div class="bold">{{ 'general.action.cancel' | translate }}</div>
    </button>

  </div>

</form>
