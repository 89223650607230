import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { FormFieldIdentifierTracking } from '../../../bdo/services/tracking.service';
import { SepaFormtype } from '../../formtypes/sepa-formtype';


@Component({
  selector: 'bdo-sepa-form',
  templateUrl: './sepa-form.component.html',
  styleUrls: ['./sepa-form.component.scss']
})
export class SepaFormComponent implements OnInit {

  @Input() parentForm: UntypedFormGroup;
  @Input() sepaRequired: boolean;
  @Input() formTracking: FormFieldIdentifierTracking;

  form = new FormGroup<SepaFormtype>({
    sepaAuth: new FormControl(false , { validators: [Validators.requiredTrue] }),
  });

  ngOnInit(): void {
    this.parentForm?.addControl('sepaAuth', this.form.get('sepaAuth'));
  }

}
