import { Pipe, PipeTransform } from '@angular/core';
import { VERBRAUCHSTYP } from '../enums/verbrauchstyp.enum';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'verbrauchstyp'
})
/** convert [G6,S4] to "Gas, Strom" */
export class VerbrauchstypPipe implements PipeTransform {

  constructor(private translateService: TranslateService) { }

  transform(value: any, translate: boolean = true): any {
    if (value === null) { return ''; }
    if (!(value instanceof Array)) {
      return translate ? this.mapDivisionTranslated(value) : this.mapVerbrauch(value);
    } else {
      return value.map(y => translate ? this.mapDivisionTranslated(y) : this.mapVerbrauch(y));
    }
  }

  mapVerbrauch(key: string) {
    switch (key) {
      case VERBRAUCHSTYP.Fernwärme:
        return 'Fernwärme';
      case VERBRAUCHSTYP.Gas:
        return 'Erdgas';
      case VERBRAUCHSTYP.Nahwärme:
        return 'Nahwärme';
      case VERBRAUCHSTYP.Einspeiser:
        return 'Einspeiser';
      case VERBRAUCHSTYP.Strom:
        return 'Strom';
      case VERBRAUCHSTYP.Wasser:
        return 'Wasser';
      case VERBRAUCHSTYP.Abwasser:
        return 'Abwasser';
      case VERBRAUCHSTYP.Übergreifend:
        return 'Übergreifend';
      default:
        return '';
    }
  }

  mapDivisionTranslated(key: string) {
    const value = this.translateService.instant('general.division.' + key);
    return value === 'general.division.' + key ? '' : value;
  }

}
