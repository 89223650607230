import { Component, OnInit } from '@angular/core';
import { TenantService } from '../../services/tenant.service';
import { TRACKING } from '../../enums/trackingParts.enum';
import { TrackingService } from '../../services/tracking.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'bdo-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements OnInit {

  constructor(
    private trackingService: TrackingService,
    public tenantService: TenantService,
    public languageService: LanguageService
  ) { }

  ngOnInit() {
    this.trackingService.postTracking(TRACKING.LOCATION.TERMSOFSERVICE, TRACKING.ACTION.ENTER);
  }

}
