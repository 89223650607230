import { EventEmitter, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { DebugLogger } from '../../shared/utils/debugLogger';

export const PLACEHOLDER_ROUTE_ACCOUNTID = 'xACCOUNTIDx';

@Injectable({
  providedIn: 'root'
})
export class CustomerStoreService {

  public customerNameChangedEvent: EventEmitter<string> = new EventEmitter();
  public customerEmailChangedEvent: EventEmitter<string> = new EventEmitter();

  public currentAccountId$: ReplaySubject<string> = new ReplaySubject(1);
  public currentAccountIdIsInactive = false;

  private accountId: string = '';
  private customerName: string = 'Kunde';
  private customerEmail: string = '';
  private deepLink: string = '';

  constructor() {}

  setAccountId(accountId: string) {
    if (accountId !== this.accountId && accountId !== PLACEHOLDER_ROUTE_ACCOUNTID) {
      this.accountId = accountId || '';
      // asynchron access, if a component needs to wait for the first accountId to be set (outside of routing context, like campaign)
      this.currentAccountId$.next(this.accountId);
    }
  }

  setCurrentAccountIdIsInactive(isInactive: boolean) {
    this.currentAccountIdIsInactive = isInactive;
  }

  getAccountId() {
    return this.accountId;
  }

  deleteAccountId() {
    this.accountId = '';
    this.currentAccountId$.next('');
  }

  setCustomerName(customerName: string) {
    if (customerName !== this.customerName) {
      this.customerName = customerName;
      this.customerNameChangedEvent.emit(customerName);
    }
  }

  getCustomerName() {
    return this.customerName;
  }

  deleteCustomerName() {
    this.customerName = '';
  }


  getCustomerEmail() {
    return this.customerEmail;
  }

  deleteCustomerEmail() {
    this.customerEmail = '';
  }

  getDeepLink(): string {
    return this.deepLink;
  }

  setDeepLink(route: string) {
    DebugLogger.debug(this.constructor.name, 'setDeepLink', route);
    this.deepLink = route;
  }

  resetData() {
    this.deleteCustomerEmail();
    this.deleteCustomerName();
    this.deleteAccountId();
  }
}
