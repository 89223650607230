<bdo-coop-teaser *ngIf="isEffzeh"
  class="re-block re-mt-45"
  data-qa="effzeh-teaser"
  [title]="'teaser.effzeh.title' | translate"
  [linkData]="[{
    href: 'teaser.effzeh.link' | translate,
    linkName: 'teaser.effzeh.linkName' | translate,
    tracking: [TRACKING.LOCATION.EFFZEH_TEASER, TRACKING.ACTION.GOTO, TRACKING.LOCATION.EFFZEH_LANDING]
  }]"
  [externalIconSrc]="'teaser.effzeh.logo' | translate"
>
  {{ 'teaser.effzeh.description' | translate }}
</bdo-coop-teaser>
