import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parenthesisOnly'
})
/**
 * Takes the first occurence of a text in parenthesis and returns it
 * e.g. "Hochtarif (HT)" => "(HT)"
 */
export class ParenthesisOnlyPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return value;
    }
    const result = value.toString();
    const matches = result.match(/\(([^)]*)\)/);
    return matches?.length > 1 ? matches[0] : value;
  }

}
