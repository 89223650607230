<fieldset [formGroup]="addressForm">

  <div>
    <bdo-input
      [formControl]="addressForm.controls.cityDisplay"
      [placeholder]="'address.postCode.postCodeAndCityLong' | translate"
    >
    </bdo-input>
  </div>

  <div class="re-mt-25 re-w-full">
    <bdo-input
      [formControl]="addressForm.controls.street"
      [trimOnBlur]="false"
      [enableAutocomplete]="false"
      [placeholder]="'address.street.title' | translate"
      #street
    >
    </bdo-input>
    <bdo-autosuggest
      [dataSource$]="streets$"
      [searchString$]="searchString$"
      [for]="street"
      [sort]="sortAutosuggest"
      (noItemsAvailable)="noStreetsAvailable$.next($event)"
      (itemSelected)="onItemSelected($event)"
      #autosuggestComponent
    >

    </bdo-autosuggest>
  </div>

  <div class="re-mt-25">
    <bdo-input
      [formControl]="addressForm.controls.housenumber"
      [autocomplete]="'street-address'"
      [enableAutocomplete]="true"
      [placeholder]="'address.housenumber.title' | translate"
    >
    </bdo-input>
  </div>

</fieldset>
