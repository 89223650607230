import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BdoApiService } from '../../bdo/services/bdo-api.service';
import { EmailVerificationData } from '../../../assets/js/com/ts_api_client';
import { LOADING_STATE } from '../../bdo/enums/loadingState.enum';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../login.service';
import { TrackingService } from '../../bdo/services/tracking.service';
import { TRACKING } from '../../bdo/enums/trackingParts.enum';

@Component({
  selector: 'bdo-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.scss']
})
export class EmailVerifyComponent implements OnInit{
  public LoadingState = LOADING_STATE;
  public state: LOADING_STATE = LOADING_STATE.LOADING;
  public title: string;

  constructor (
    public loginService: LoginService,
    private apiService: BdoApiService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.EMAIL_VERIFICATION, TRACKING.ACTION.ENTER);
    this.title = this.translate.instant('emailVerification.title.loading');
    const emailData: EmailVerificationData = {
      token: this.route.snapshot.queryParamMap.get('token'),
      verificationCode: this.route.snapshot.queryParamMap.get('code')
    };

    this.apiService.postEmailVerificationAnonymous(emailData).subscribe({
      next: res => {
        this.state = LOADING_STATE.IDLE;
        this.title = this.translate.instant('emailVerification.title.finished');
      }, error: (error: unknown) => {
        this.state = LOADING_STATE.ERROR;
        this.title = this.translate.instant('emailVerification.title.finished');
      }
    });

  }

}
