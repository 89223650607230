<div class="bdo-container bdo-space--bottom">
  <h3>{{ 'siminvoice.title' | translate }}</h3>
</div>

<div class="bdo-container--single">
  <div class="re-container-with-margin re-mt-45 re-copytext">
    {{ 'siminvoice.description' | translate }}
  </div>
  <div class="bdo-container--single re-mt-30" *ngIf="savingFailed">
    <bdo-info-box>
      {{ 'siminvoice.error.general' | translate }}
    </bdo-info-box>
  </div>
  <form [formGroup]="form" class="re-relative" [ngClass]="{'re-h-200 re-overflow-y-clip' : state === LoadingState.LOADING}">
    <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
    <div class="re-container-with-margin re-mb-15 re-mt-45 md:re-mb-30 md:re-mt-60 re-ml-20 md:re-ml-auto">
      <h4>
      {{ 'siminvoice.period' | translate }}
      </h4>
    </div>
    <div class="re-bg-white re-w-full re-px-30 re-py-20 re-text
                md:re-w-600 md:re-mx-auto lg:re-w-900">
      <div class="re-form-item-container bdo-space--bottom bdo-space--top">
        <bdo-input
          formControlName="startpoint"
          [formControl]="$any(form.get('startpoint'))"
          [labelText]="'siminvoice.start' | translate"
        ></bdo-input>
        <div class="re-absolute re-right-0 re-top-0">
          <bdo-inline-tooltip [name]="'tooltip-startpoint'">
            {{ 'siminvoice.startTooltip' | translate }}
          </bdo-inline-tooltip>
        </div>
      </div>
      <div class="bdo-space--bottom re-form-item-container">{{ 'siminvoice.endHint' | translate:
        { minDate: minDateForChanges | date: 'dd.MM.yyyy', maxDate: maxDateForChanges | date: 'dd.MM.yyyy' } }}
      </div>
      <div class="re-form-item-container">
        <bdo-date-picker
          [selectedDate]="selectedDate"
          [minDateForChanges]="minDateForChanges"
          [maxDateForChanges]="maxDateForChanges"
          [labelText]=" 'siminvoice.end' | translate"
          (dateSelected)="onUpdateDate($event)"
        ></bdo-date-picker>
        <div class="re-absolute re-right-0 re-top-0">
          <bdo-inline-tooltip [name]="'tooltip-endpoint'">
            {{ 'siminvoice.endTooltip' | translate }}
          </bdo-inline-tooltip>
        </div>
      </div>
    </div>
    <ng-container *ngFor="let division of divisions">
      <div class="re-container-with-margin re-mb-15 re-mt-45 md:re-mb-30 md:re-mt-60 re-ml-20 md:re-ml-auto">
        <h4>
          {{ 'general.tariff.meterWithDivision' | translate: { division: division | verbrauchstyp } }}
        </h4>
      </div>
      <div *ngFor="let meter of meters" class="re-mb-20 md:re-mb-30">
        <ng-container *ngIf="meter.sparte === division">
          <bdo-siminvoice-meter-readings
            [meter]="meter"
            [selectedDate]="selectedDate <= nowDate ? selectedDate : nowDate"
            [invalidText]="invalidText"
            (validEvent)="missingReading($event)">
          </bdo-siminvoice-meter-readings>
        </ng-container>
      </div>
    </ng-container>
      <div class="re-mt-45  re-pl-15 md:re-mt-60 md:re-pl-0">
        <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
        <button class="re-button re-primary" (click)="calculate()">
          {{ 'siminvoice.buttonSubmit' | translate }}
        </button>
        <div class="re-pt-15">
          <bdo-text-icon [iconPosition]="'left'"
                         [text]="'dashboard.linkTo' | translate"
                         (click)="toDashboard()">
          </bdo-text-icon>
        </div>
      </div>
  </form>
</div>

<!-- Overlay -->
<bdo-simple-text-popup *ngIf="savingDone" [isVisible]="savingDone" [icon]="'success'" [title]="'siminvoice.saved' | translate" [isManuallyClosable]="false">
  <div class="info--text re-mb-20">
    {{ 'siminvoice.savedDescription' | translate }}
  </div>
    <div class="link">
      <bdo-text-link-icon
        [linkText]="'documents.linkTo' | translate"
        [routerUrl]="'/dokumente/' + getKundennummer()"
        [iconPosition]="'right'"
        [tracking]="[TRACKING.LOCATION.SIMINVOICE_SUCCESS, TRACKING.ACTION.GOTO, TRACKING.LOCATION.DOCUMENTS]">
      </bdo-text-link-icon>
    </div>
</bdo-simple-text-popup>

<!-- FAQs -->
<bdo-faqs [keyName]="'siminvoice.faqs'"></bdo-faqs>
