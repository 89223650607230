import { Component, Input } from '@angular/core';
import { TariffSummaryResults } from '../../../../assets/js/com/ts_api_client';

@Component({
  selector: 'bdo-tariff-details',
  templateUrl: './tariff-details.component.html',
  styleUrls: ['./tariff-details.component.scss']
})
export class TariffDetailsComponent {

  @Input() tariffData: TariffSummaryResults;

}
