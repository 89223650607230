<div class="re-bg-white re-text re-w-full re-p-20 re-mb-3 re-mt-30
              md:re-w-600 md:re-mx-auto md:re-py-0 md:re-pl-0 md:re-pr-30
              lg:re-w-900" [attr.data-qa]="externalTenantConfiguration.companyName">

    <!-- Move Tile -->
    <bdo-coop-teaser *ngIf="externalTenantConfiguration.changeTenant"
      [title]="(externalTenantConfiguration.divisions.length === 1 ?
               'externalTile.divisionTitle'  : 'externalTile.title') | translate:
               { division: externalTenantConfiguration.divisions[0]?.divisionId | verbrauchstyp }"
      [linkData]="getLinkData()"
      [iconSrc]="externalTenantConfiguration.logo"
    >
      <span [innerHTML]="'externalTile.description' | translate:
                         { companyName: externalTenantConfiguration.companyName }"></span>
    </bdo-coop-teaser>


    <!-- Contract-End Tile -->
    <bdo-coop-teaser *ngIf="!externalTenantConfiguration.changeTenant"
      [title]="'externalTile.contractEnd.title' | translate"
      [iconSrc]="externalTenantConfiguration.logo"
      [linkData]="[{
        linkName: 'externalTile.contractEnd.linkName' | translate,
        href: externalTenantConfiguration.divisions[0].tariffAdvisorLink
      }]"
    >
      <span [innerHTML]="'externalTile.contractEnd.description' | translate"></span>
    </bdo-coop-teaser>

</div>
