<div class="bdo-loading__wrapper" [class.bdo-loading__wrapper--height]="state === LoadingState.LOADING">
  <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>

  <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">
    {{ 'general.error.error' | translate }}
  </bdo-info-box>

  <div *ngIf="showTable && state !== LoadingState.ERROR">
    <div class="re-p-15 re-bg-white" *ngIf="aggregationOrdering[currentAggregationIndex - 1]">
      <a
        (click)="getDataPoints( aggregationOrdering[currentAggregationIndex - 1],
                                aggregations[aggregationOrdering[currentAggregationIndex - 1]].drillUp.startDate,
                                aggregations[aggregationOrdering[currentAggregationIndex - 1]].drillUp.endDate)"
      >
        <bdo-svg name="icon-arrow-left" styling="position-left"></bdo-svg>
        <span
          class="bold">{{ 'general.action.backTo' | translate }} {{ aggregations[aggregationOrdering[currentAggregationIndex - 1]].title }} </span></a>
    </div>

    <table *ngIf="dataPoints?.length > 0" class="re-w-full re-hidden md:re-table">
      <thead>
      <tr>
        <th>{{aggregations[aggregationOrdering[currentAggregationIndex]].timeUnitHeadline}}</th>
        <th>{{ 'meterreadings.history.consumptionIn' | translate }} {{ consumptionUnit | unitPretty }}</th>
        <th *ngIf="hasCosts">{{ 'billing.plan.grossInEuro' | translate }}</th>
      </tr>
      </thead>
      <tbody *ngIf="state !== LoadingState.LOADING">
      <tr *ngFor="let dataPoint of dataPoints">
        <td class="bdo-font--monospace">{{dataPoint.endDate | date:aggregations[aggregationOrdering[currentAggregationIndex]].timeUnitFormat}}</td>
        <td class="bdo-font--monospace">{{dataPoint.consumption}}</td>
        <td class="bdo-font--monospace" *ngIf="hasCosts">{{dataPoint.cost | currency : 'EUR'}}</td>
      </tr>
      </tbody>
    </table>
    <div class="md:re-hidden" *ngIf="state !== LoadingState.LOADING">
      <div *ngFor="let dataPoint of dataPoints"
           class="bdo-tile table-row__container--mobile space-line">
        <div>
          <div class="bold">{{aggregations[aggregationOrdering[currentAggregationIndex]].timeUnitHeadline}}</div>
          {{ dataPoint?.endDate | date:aggregations[aggregationOrdering[currentAggregationIndex]].timeUnitFormat}}
        </div>
        <div>
          <div class="bold">{{ 'meterreadings.history.consumptionIn' | translate }} {{ consumptionUnit | unitPretty }}</div>
          <span>{{ dataPoint?.consumption }}</span>
        </div>
        <div *ngIf="hasCosts">
          <div class="bold">{{ 'payment.overview.table.amount' | translate }}</div>
          <span>{{ dataPoint?.cost | currency : 'EUR' }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="dataPoints?.length === 0" class="re-mt-15">
      {{ 'general.error.noValues' | translate }}
    </div>
  </div>

  <div class="re-bg-white re-py-30 re-px-15" [ngClass]="{'re-hidden': showTable}" *ngIf="state !== LoadingState.ERROR">
    <h4 class="re-text-center">{{ chartTitle }}</h4>
    <div *ngIf="isVisibleChartTabs()" class="chart-tabs re-m-auto re-relative re-text-center re-mt-30 re-mb-40">
      <div *ngIf="showEurNonAvailableMessage" class="re-absolute re-w-full re-text-xs re-leading-s">
        <div class="re-bg-white re-relative re-m-auto re-border-gray-900 re-p-5 re-pr-15 re-text-left re-z-50 re-border-athens-grey re-border-2 re-w-[250px]">
          <bdo-svg (click)="showEurNonAvailableMessage = false"
                   class="re-absolute re-top-0 re-right-0 re-p-5"
                   name="icon-close"
                   styling="icon__close"
          ></bdo-svg>
          <p class="re-p-0 re-m-0">{{ 'consumptionHistory.diagram.error.noCosts' | translate }}</p>
        </div>
      </div>
      <div *ngIf="consumptionUnit?.length > 0"
           class="re-button re-cursor-pointer re-mr-10" (click)="updateUnit(ChartUnit[consumptionUnit])"
           [ngClass]="selectedUnit === consumptionUnit ? 're-primary' : 're-secondary'"
           title="In {{ consumptionUnit | unitPretty}} ausgeben">{{ this?.consumptionUnit | unitPretty}}
      </div>
      <div *ngIf="hasCosts && consumptionUnit?.length > 0"
           class="re-button re-cursor-pointer" (click)="updateUnit(ChartUnit.EUR)"
           [ngClass]="selectedUnit === ChartUnit.EUR ? 're-primary' : 're-secondary'" title="In Euro ausgeben">
           {{ 'billing.currency' | translate }}
      </div>

      <div class="re-button re-bg-secondary inactive" *ngIf="!hasCosts" (click)="showEurNonAvailableMessage = true"
           title="Die Kosten können auf dieser Ebene nicht angezeigt werden.">
           {{ 'billing.currency' | translate }}
      </div>

    </div>
    <div class="re-mb-20" *ngIf="state === LoadingState.IDLE">
      <a *ngIf="aggregationOrdering[currentAggregationIndex - 1]"
         (click)="getDataPoints(
         aggregationOrdering[currentAggregationIndex - 1],
         aggregations[aggregationOrdering[currentAggregationIndex - 1]].drillUp.startDate,
         aggregations[aggregationOrdering[currentAggregationIndex - 1]].drillUp.endDate)">
        <bdo-svg name="icon-arrow-left" styling="position-left"></bdo-svg>
        <span
          class="bold">{{ 'general.action.backTo' | translate }} {{ aggregations[aggregationOrdering[currentAggregationIndex - 1]].title }}
        </span>
      </a>
    </div>

    <div class="re-text-xxs" style="margin-left: 27px;">{{selectedUnit | unitPretty}}</div>
    <div class="chartWrapper bdo-font--monospace">
      <div #customYAxis id="customYAxis">
        <canvas></canvas>
        <div class="scroll-gradient"></div>
      </div>
      <div class="chartAreaWrapper re-relative" #chartAreaWrapper (scroll)="scrollChart()">
        <div *ngIf="dataPoints?.length === 0 && state !== LoadingState.LOADING" class="bdo-centered re-mx-30">
          {{ 'general.error.noValues' | translate }}
        </div>
        <div class="chartAreaInnerWrapper" [style.width.px]="chartDataWidth">
          <canvas baseChart *ngIf="state === LoadingState.IDLE && !showTable"
                  [data]="chartData"
                  [options]=barChartOptions
                  (chartClick)="showTooltip($event)"
                  chartType="bar">
          </canvas>
        </div>
        <div #tooltip id="tooltip"
             class="re-text-white re-absolute re-top-0 re-left-0 re-block re-bg-mineshaft re-z-50">
          <button
            class="close re-absolute re-right-15 re-top-15 re-flex re-w-22 re-h-22 mx-w-22 re-items-center re-no-underline re-justify-center re-bg-mineshaft"
            [attr.aria-label]="'general.action.close' | translate"
            (click)="closeTooltip()">
            <svg-icon
              class="re-text-white re-w-22"
              src="assets/icons/close.svg">
            </svg-icon>
          </button>
          <div class="re-m-15 re-mr-45 re-leading-m re-text-sm">
            <p>{{openTooltip?.title}}</p>
            <p class="re-mb-10">{{selectedUnit === ChartUnit.EUR ? (openTooltip?.cost | currency : 'EUR') : openTooltip?.consumption }}</p>
            <a
              class="whiten"
              *ngIf="openTooltip?.isDrillDownPossible && aggregations[aggregationOrdering[currentAggregationIndex + 1]]"
              (click)="getDataPoints(aggregationOrdering[currentAggregationIndex + 1], openTooltip?.startDate, openTooltip?.endDate)"><span
              >Zur {{ aggregations[aggregationOrdering[currentAggregationIndex + 1]].title }}</span>
              <bdo-svg name="icon-arrow-right" styling="position-right"></bdo-svg>
            </a>
          </div>
          <div #tooltipArrowDown class="arrow-down re-border-t-mineshaft">
            <div class="inner re-border-t-mineshaft"></div>
          </div>
        </div>
      </div>
      <div #chartScrollbar class="re-mb-20 re-flow-root">
        <div class="re-m-auto re-w-full re-md:w-1/4">
          <div class="re-float-left re-cursor-pointer" (click)="scroll(- 150)">
            <bdo-svg name="icon-arrow-left-long"
                     styling="position-left long{{displayScrollLeft ? '' : ' disabled'}}"></bdo-svg>
          </div>
          <div class="re-float-right re-cursor-pointer" (click)="scroll(+ 150)">
            <bdo-svg name="icon-arrow-right-long"
                     styling="position-right long{{displayScrollRight ? '' : ' disabled'}}"></bdo-svg>
          </div>
        </div>
      </div>
      <p class="re-text-xs re-leading-s re-block">{{ 'consumptionHistory.diagram.hintRounded' | translate }}</p>
    </div>
  </div>
</div>
