import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { FormValidationError } from '../../../bdo/models/formValidationError';

@Component({
  selector: 'bdo-single-line-input',
  templateUrl: './single-line-input.component.html',
  styleUrls: ['./single-line-input.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class SingleLineInputComponent {
  @ViewChild(NgModel, { static: true }) set content(content: NgModel) {
    if (content) { // initially setter gets called with undefined
        this.input = content;
    }
  }
  @Input() autocomplete: string;
  @Input() inputName: string = 'inputName';
  @Input() inputType: string;
  @Input() inputValue: string = '';
  // if placeholder or labelText are set (and not both), placeholder is set to labelText or the other way round
  @Input() // TODO: mandantenabhängig
    set placeholder (labelText) {
      if (!this._labelText) {
        this._labelText = labelText;
      }
      this._placeholder = labelText;
    }
    get placeholder () {
      return this._placeholder;
    }
  @Input() // TODO: mandantenabhängig
    set labelText(labelText) {
      if (!this._placeholder) {
        this._placeholder = labelText;
      }
      this._labelText = labelText;
    }
    get labelText() {
      return this._labelText;
    }

  @Input() required: boolean = false;
  @Input() minLength: number;
  @Input() maxLength: number;
  @Input() forceValidate: boolean;
  @Input() errors: Array<FormValidationError> = [];
  @Input() validationIconHidden: boolean = false;
  @Input() inputValueChangedDelayedTime = 1000;
  @Output() inputValueChanged = new EventEmitter<string>();
  @Output() inputValueChangedDelayed = new EventEmitter<string>();
  @Output() inputFocused = new EventEmitter<string>();
  @Output() inputBlurred = new EventEmitter<string>();

  public input: NgModel;
  public focused = false;
  public showDelayed = false;
  public isFloating: boolean;
  public isHidden: boolean;
  protected _labelText: string;
  protected _placeholder: string;
  protected timeout = null;

  onFocus() {
    this.focused = true;
    this.inputFocused.emit(this.inputName);
  }

  onBlur() {
    this.focused = false;
    this.inputBlurred.emit(this.inputName);
    this.showDelayed = true;
  }

  onChange(event) {
    if (this.timeout) { // if there is already a timeout in process cancel it
       window.clearTimeout(this.timeout);
    }
    this.timeout = null;
    this.showDelayed = false;
      this.timeout = window.setTimeout(() => {
        this.timeout = null;
        this.showDelayed = true;
        this.inputValueChangedDelayed.emit(event);
     }, this.inputValueChangedDelayedTime);
    this.inputValueChanged.emit(event);
  }

  calcEverything(input) {

    if (this.validationIconHidden) {
      this.isFloating = true;
      return;
    }

    this.isFloating = false;
    this.isHidden = false;

    if (input.valid) {
      if (this.focused) {
        this.isFloating = true;
      }
      if ( input.touched && !this.focused) {
        if (this.inputValue && this.inputValue.toString().length > 0) {
          this.isFloating = true;
        } else { // Input is empty
          this.isHidden = true;
          this.isFloating = true;
        }
      }
      if (!input.touched && !this.focused) {
        this.isFloating = true;
        if (!this.inputValue || this.inputValue.toString().length <= 0) {
          this.isHidden = true;
        }
      }
    } else { // not valid
      if (!input.touched && !this.focused && !this.forceValidate) {
        this.isFloating = true;
        if (!this.inputValue || this.inputValue.toString().length <= 0) {
          this.isHidden = true;
        }
      }
      if (this.showDelayed || (this.forceValidate && !(input.touched || this.focused))) {
        this.isFloating = false;
        this.isHidden = true;
      }
    }

  }

  getFloating(input): boolean {
    this.calcEverything(input);
    return this.isFloating;
  }

  getHidden(input): boolean {
    this.calcEverything(input);
    return this.isHidden;
  }
}
