<button type="button" class="re-bold hover:re-text-secondary-hover" (click)="onClickButton()">
  <div class="re-flex re-flex-row re-items-center">
    <svg-icon *ngIf="iconStyle === 'plus'" src="assets/icons/plus_circle.svg"></svg-icon>
    <svg-icon *ngIf="iconStyle === 'minus'" src="assets/icons/minus_circle.svg"></svg-icon>

    <span class="bold re-ml-20 re-text-left re-text-secondary
                 md:re-text-center md:re-mt-0">
      {{ label }}
    </span>
  </div>
</button>
