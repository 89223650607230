"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VisitorVariation = void 0;
var VisitorVariation = /** @class */function () {
  function VisitorVariation(variationId, respoolTime, assignmentDate) {
    var _this = this;
    this.isValid = function () {
      return !_this.respoolTime || _this.assignmentDate.getTime() / 1000 > _this.respoolTime;
    };
    this.variationId = Number(variationId);
    this.respoolTime = respoolTime;
    this.assignmentDate = assignmentDate !== null && assignmentDate !== void 0 ? assignmentDate : new Date();
  }
  VisitorVariation.createFromJSON = function (json) {
    var variationId = json.variationId,
      respoolTime = json.respoolTime,
      assignmentDate = json.assignmentDate;
    return new VisitorVariation(variationId, respoolTime, assignmentDate);
  };
  return VisitorVariation;
}();
exports.VisitorVariation = VisitorVariation;