/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A list of possible reasons and their ids for users to cancel a contract
 */
export interface CancelReasonData { 
    /**
     * id of reason for contract termination, e.g. I1, I2, ...
     */
    cancelReasonId?: string;
    /**
     * text of reason for contract termination
     */
    cancelText?: string;
}

