<div class="flex re-flex-col re-h-150">
  <div class="re-bg-monochrome-dark re-text-white re-p-15 re-pt-10 re-mb-15 re-h-90">
    <div class="re-text-sm re-mb-5">{{selectedYear}}</div>
    <div class="re-text-l">{{dateLabel}}</div>
  </div>
  <div class="re-flex re-flex-row re-h-15 re-text-center re-justify-between re-mx-35">
    <div>
      <button (click)="previousClicked('month')">
        <div class="icon re-text-black re-stroke-current">
          <svg class="re-w-15 re-h-15" viewBox="0 0 8 13" fill="none">
            <path d="M6.8398 11.4365L1.47729 6.19707L6.8398 0.99707" stroke="currentColor" stroke-width="2"/>
          </svg>
        </div>
      </button>
    </div>

    <span class="re-inline-block re-text-sm">{{periodLabel}}</span>

    <div>
      <button (click)="nextClicked('month')">
        <div class="icon re-text-black re-stroke-current">
          <svg class="re-w-15 re-h-15" viewBox="0 0 8 13" fill="none">
            <path d="M1.47736 0.99707L6.83986 6.23646L1.47736 11.4365" stroke="currentColor" stroke-width="2"/>
          </svg>
        </div>
      </button>
    </div>
  </div>
</div>
