import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TenantService } from '../../services/tenant.service';
import { BdoApiService } from '../../services/bdo-api.service';
import { PaymentsData } from '../../../../assets/js/com/ts_api_client/model/paymentsData';
import { CustomerStoreService } from '../../services/customer-store.service';
import { TRACKING } from '../../enums/trackingParts.enum';
import { TrackingService } from '../../services/tracking.service';
import { PaymentData, PaymentsDataCredits } from '../../../../assets/js/com/ts_api_client';
const NUMBER_OF_ROWS_ON_INIT = 2;

export enum PaymentType {
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
  FUTURE = 'future'
}

@Component({
  selector: 'bdo-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  public PaymentType = PaymentType;
  customerNumber: string;
  isLoading: boolean = false;
  paymentsData: PaymentsData;
  showAll = false;

  constructor(
    public tenantService: TenantService,
    private customerStore: CustomerStoreService,
    private route: ActivatedRoute,
    private trackingService: TrackingService,
    private apiService: BdoApiService
  ) { }

  /*
    since let variables are untyped, a cast to PaymentsDataCredits is mandatory to make use of the strictTemplateChecks
   */
  public asPaymentsDataCreditsFiltered(payments: any, type: string) {
    if (this.showAll || type === PaymentType.NEGATIVE) {
      return (payments as PaymentsDataCredits).payments;
    } else {
      return (payments as PaymentsDataCredits).payments.slice(0, NUMBER_OF_ROWS_ON_INIT);
    }
  }

  ngOnInit() {
    this.trackingService.postTracking(TRACKING.LOCATION.PAYMENTS, TRACKING.ACTION.ENTER);

    this.isLoading = true;
    this.customerNumber = this.customerStore.getAccountId();

    this.apiService.getPayments().subscribe(
      {
        next: (res) => {
          const sort = (a: PaymentData, b: PaymentData) => {
            const dateForSortingA = a.deferredTo || a.dateDue;
            const dateForSortingB = b.deferredTo || b.dateDue;
            return dateForSortingA < dateForSortingB ? -1 : 1;
          };
          res.receivables.payments = res.receivables.payments.sort(sort);
          this.paymentsData = res;
          const atLeastOneCategoryHasMoreThanInit = (
            res.futureReceivables?.payments?.length > NUMBER_OF_ROWS_ON_INIT
            || res.receivables?.payments?.length > NUMBER_OF_ROWS_ON_INIT
            || res.credits?.payments?.length > NUMBER_OF_ROWS_ON_INIT
          );
          this.showAll = !atLeastOneCategoryHasMoreThanInit;
          this.isLoading = false;
        },
        error: () => {
          this.paymentsData = null;
          this.trackingService.postTracking(TRACKING.LOCATION.PAYMENTS, TRACKING.ACTION.ERROR);
          this.isLoading = false;
        }
      });
  }

}
