<h3 class="bdo-container re-mb-30 md:re-mb-45">{{ 'personaldata.title' | translate }}</h3>

<p class="re-container-with-margin re-mb-30 md:re-mb-45 re-text-sm re-leading-m md:re-text-base md:re-leading-2xl">
  {{ 'personaldata.description' | translate }}
</p>

<bdo-personal-data-personal
  id="personal"
  [accountId]="accountId"
  [disabled]="disabled"
  (edit)="onEditChange($event, 'personal')"
></bdo-personal-data-personal>

<bdo-personal-data-banking
  id="banking"
  [accountId]="accountId"
  [disabled]="disabled"
  (edit)="onEditChange($event, 'banking')"
></bdo-personal-data-banking>

<div class="re-mt-60 md:re-mt-90">
  <bdo-personal-data-address
    id="address"
    [disabled]="disabled"
    (edit)="onEditChange($event, 'address')">
  </bdo-personal-data-address>
</div>

<div class="re-mt-60 md:re-mt-90">
  <bdo-personal-data-password
    id="password"
    [disabled]="disabled"
    (edit)="onEditChange($event, 'password')">
  </bdo-personal-data-password>
</div>

<div class="re-mt-60 md:re-mt-90">
  <bdo-personal-data-optin
    id="optin"
    [disabled]="disabled"
    [isInEditMode]="optInEditTriggeredByPopup"
    [optInEditTriggeredByPopup]="optInEditTriggeredByPopup"
    [accountId]="accountId"
    (edit)="onEditChange($event, 'optin')"
  ></bdo-personal-data-optin>
</div>

<div class="re-mt-60 md:re-mt-90">
  <bdo-personal-data-faqs></bdo-personal-data-faqs>
</div>
