"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.obtainNonce = void 0;
var HEXADECIMAL_ALPHABET = "0123456789ABCDEF";
var NONCE_BYTE_LENGTH = 8;
function obtainNonce() {
  var alphabetLength = HEXADECIMAL_ALPHABET.length;
  var result = "";
  for (var i = 0; i < NONCE_BYTE_LENGTH * 2; ++i) {
    var randomNumber = Math.floor(Math.random() * alphabetLength);
    result += HEXADECIMAL_ALPHABET.substring(randomNumber, randomNumber + 1);
  }
  return result;
}
exports.obtainNonce = obtainNonce;