<div class="center">
  <img class="bdo-space--bottom" src="assets/icons/bdo/icon-check-big.svg" alt="{{ 'general.imgText.check' | translate }}">

  <h4 class="center space--bottom">{{ 'username.success.title' | translate }}</h4>

  <p>{{ 'username.success.description' | translate }}</p>
  <p class="bold space--bottom">{{username}}</p>

  <button class="bdo-button-primary bdo-space--top bdo-button__mobile--full" (click)="close()">
    {{ 'general.navigation.backToLogin' | translate }}
  </button>
</div>
