<bdo-simple-text-popup [isVisible]="maintenanceShowPopup$ | async" (closePopupEvent)="close()"
                       [isManuallyClosable]="(serviceUnavailable$ | async) !== true"
                       [title]="(soonUnderMaintenance ? 'maintenance.soon.title' : 'maintenance.now.title') | translate"
                       [icon]="'error'">

  <div *ngIf="soonUnderMaintenance; else maintenanceNow">

    <div class="re-copytext-with-margin">
      <p>{{ 'maintenance.soon.subtitle' | translate }}</p>

      <p class="re-mb-30">{{ 'maintenance.soon.description' | translate }}</p>

      <p class="bold re-mb-30">{{start | date:'dd.MM.yyyy HH:mm'}} Uhr - {{end | date:'dd.MM.yyyy HH:mm'}} Uhr</p>

      <p>{{infoText}}</p>
    </div>
    <button class="bdo-button-primary" (click)="close()">{{ 'general.action.close' | translate }}</button>
  </div>

  <ng-template #maintenanceNow>
    <p [innerHTML]="'maintenance.now.description' | translate" class="re-copytext-with-margin"></p>

    <a class="bdo-button-primary" [href]="tenantService.getCurrentTenantData().hrefContactForm">
      {{ 'general.navigation.linkContactForm' | translate }}
    </a>
  </ng-template>

</bdo-simple-text-popup>
