
<form (ngSubmit)="save()" [formGroup]="activeForm" (keydown.enter)="$event.preventDefault()" bdoScrollToInvalidControl>
  <div class="re-relative">
    <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
    <fieldset class="re-mb-0 md:re-mt-45">

      <div [class.re-mb-15]="state === LoadingState.ERROR" data-qa="correspondenceAddressErrorbox">
        <bdo-info-box *ngIf="state === LoadingState.ERROR">
          {{ 'general.error.error' | translate }}
        </bdo-info-box>
      </div>

      <h5 class="re-mb-15 md:re-mb-30">{{ 'personaldata.address.add' | translate }}</h5>

      <ng-container>
        <div class="re-form-item-container">
          <bdo-dropdown
            [data]="customerModeDropdown"
            [required]="true"
            [selected]="(activeForm.get('customerMode').value)"
            [forceValidate]="forceValidate"
            [labelText]="'wizard.step.address' | translate"
            data-qa="customerModeSelect"
            (itemSelected)="activeForm.get('customerMode')?.setValue($event)"
          ></bdo-dropdown>
        </div>

        <hr class="re-mb-15 re-mt-45 re-border-t re-border-monochrome-medium-light">

        <ng-container *ngIf="!isGewe && activeForm.get('customerMode').value">
          <div class="re-form-item-container">
            <bdo-dropdown
              [data]="privTitleDropdown"
              [required]="true"
              [selected]="privateAddressForm.get('title').value"
              [forceValidate]="forceValidate"
              [requiredErrorText]="'address.salutation.salutationRequired' | translate"
              [labelText]="'address.salutation.title' | translate"
              data-qa="titleSelectPriv"
              (itemSelected)="privateAddressForm.get('title').setValue($event)"
              >
            </bdo-dropdown>
          </div>

          <div class="re-form-item-container" data-qa="name">
            <bdo-input [formControl]="$any(privateAddressForm?.get('name'))"
                      [inputType]="INPUT_TYPE.TEXT"
                      [validationIconHidden]="state === LoadingState.ERROR"
                      [labelText]="'address.personalInfo.firstname' | translate"
                      autocomplete="given-name"
            >
            </bdo-input>
          </div>

          <div class="re-form-item-container" data-qa="surname">
            <bdo-input [formControl]="$any(privateAddressForm?.get('surname'))"
                      [inputType]="INPUT_TYPE.TEXT"
                      [validationIconHidden]="state === LoadingState.ERROR"
                      [labelText]="'address.personalInfo.surname' | translate"
                      autocomplete="family-name"
            >
            </bdo-input>
          </div>

        </ng-container>

        <ng-container *ngIf="isGewe">
          <div class="re-form-item-container">
            <bdo-dropdown
              [data]="geweTitleDropdown"
              [required]="true"
              [selected]="(geweAddressForm.get('title').value)"
              [forceValidate]="forceValidate"
              [requiredErrorText]="'address.salutation.salutationRequired' | translate"
              [labelText]="'address.salutation.title' | translate"
              data-qa="titleSelectGewe"
              (itemSelected)="geweAddressForm.get('title').setValue($event)"
              >

            </bdo-dropdown>
          </div>

          <div class="re-form-item-container" data-qa="companyName">
            <bdo-input [formControl]="$any(geweAddressForm?.get('companyName'))"
                      [inputType]="INPUT_TYPE.TEXT"
                      [validationIconHidden]="state === LoadingState.ERROR"
                      [labelText]="'address.business.companyName' | translate"
                      autocomplete="organization"
            >
            </bdo-input>
          </div>

          <div class="re-form-item-container" data-qa="additional">
            <bdo-input [formControl]="$any(geweAddressForm?.get('additional'))"
                      [inputType]="INPUT_TYPE.TEXT"
                      [validationIconHidden]="state === LoadingState.ERROR"
                      [labelText]="'address.general.additional' | translate"
            >
            </bdo-input>
          </div>

          <div class="re-form-item-container" *ngIf="isGewe" data-qa="sectors">
            <bdo-dropdown
              [data]="sectors"
              [required]="true"
              [selected]="(geweAddressForm.get('sectorId')?.value)"
              [forceValidate]="forceValidate"
              [requiredErrorText]="'address.business.sector.required' | translate"
              [labelText]="'address.business.sector.title' | translate"
              data-qa="sectorSelect"
              (itemSelected)="geweAddressForm.get('sectorId')?.setValue($event)"
              >
            </bdo-dropdown>
          </div>

          <div class="re-form-item-container" *ngIf="isGewe" data-qa="legalEntities">
            <bdo-dropdown
              [data]="legalEntities"
              (itemSelected)="geweAddressForm.get('legalEntityId')?.setValue($event)"
              [required]="true"
              [selected]="geweAddressForm.get('legalEntityId')?.value"
              [forceValidate]="forceValidate"
              [requiredErrorText]="'address.business.legalEntity.required' | translate"
              [labelText]="'address.business.legalEntity.title' | translate"
              data-qa="legalEntitySelect"
            ></bdo-dropdown>
          </div>
        </ng-container>

        <bdo-address-form></bdo-address-form>

        <bdo-checkbox
          [checked]="activeForm.get('useForAll')?.value"
          (change)="activeForm.get('useForAll')?.setValue(!activeForm.get('useForAll')?.value)"
          data-qa="useForAllCheck"
        >
          {{ 'personaldata.address.useForAll' | translate }}
        </bdo-checkbox>

      </ng-container>


      <div class="re-mb-15 re-mt-45">
        <button class="bdo-button-primary bdo-button--text" type="submit">
          {{ 'general.action.save' | translate }}
        </button>
      </div>

      <button class="bdo-nolink" data-qa="cancel" type="button" (click)="editDone.emit(true)">
        <div class="bold">{{ 'general.action.cancel' | translate }}</div>
      </button>

    </fieldset>
  </div>
</form>
