<div class="date-container re-relative">
  <div *ngIf="tooltipContent">
    <div class="re-absolute re-right-0 re-top-0 re-z-10">
      <bdo-inline-tooltip>
        <div [innerHTML]="tooltipContent"></div>
      </bdo-inline-tooltip>
    </div>
  </div>

  <div class="re-form-item-container re-group">
    <ng-container class="error" *ngIf="(noPreselection && (form.get('selectedDate').touched && !form.get('selectedDate').value && !picker.opened) || (forceValidate && !form.get('selectedDate').value)); then error else label"></ng-container>
    <ng-template #error>
      <div class="error re-mr-20 re-mb-5" [class.re-invisible]="focused">{{requiredErrorText}}</div>
    </ng-template>
    <ng-template #label>
      <label [for]="labelText + 'date'" class="animated fadeInUp re-block re-mr-20 re-mb-5" [class.re-invisible]="!form.get('selectedDate').value">
        {{labelText}}
      </label>
    </ng-template>
    <div class="re-relative">
      <input class="re-truncate"
        name="date"
        [id]="labelText + 'date'"
        type="text"
        matInput
        readonly
        [formControl]="$any(form.get('selectedDate'))"
        [placeholder]="labelText"
        [required]="required"
        [min]="minDateForChanges"
        [max]="maxDateForChanges"
        [autocomplete]="'new-password'"
        [matDatepicker]="$any(picker)"
        (click)="picker.open()"
        (focusin)="picker.open()"
        (dateChange)="onDateChange($event)">
      <mat-datepicker #picker [calendarHeaderComponent]="customHeader"
                      (opened)="datepickerOpen = true"
                      (closed)="datepickerOpen = false"
      ></mat-datepicker>
      <svg-icon src="assets/icons/wizard_steps/calendar.svg"
        class="calendar__icon re-w-30 group-hover:re-text-secondary-hover bdo-date-icon"
        [ngClass]="{ 'opened': datepickerOpen }"
      ></svg-icon>
    </div>
  </div>
</div>
