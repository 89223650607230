import { Component, Input } from '@angular/core';
import { TRACKING } from '../../../bdo/enums/trackingParts.enum';
import { TenantService } from '../../../bdo/services/tenant.service';
import { TrackingService } from '../../../bdo/services/tracking.service';
import { Srcs } from '../../models/switchCardConfiguration';

@Component({
  selector: 'bdo-link-tile',
  templateUrl: './link-tile.component.html',
  styleUrls: ['./link-tile.component.scss']
})
export class LinkTileComponent {
  @Input() iconSrc: Srcs;
  @Input() title: string;
  @Input() linktext: string;
  @Input() destination: string;
  @Input() description: string = ''; // optional
  @Input() trackingLocation: string;
  @Input() trackingDestination: string;

  constructor(
    public tenantService: TenantService,
    private trackingService: TrackingService
  ) { }

  track(destination: string) {
    if (this.trackingLocation && destination) {
      this.trackingService.postSimpleTracking(this.trackingLocation, TRACKING.ACTION.GOTO, destination);
    }
  }

}
