import { Component, Input, OnInit } from '@angular/core';
import { VERBRAUCHSTYP } from '../../../../enums/verbrauchstyp.enum';
import {
  PersonalDataAddressData,
  TariffSummaryResults
} from '../../../../../../assets/js/com/ts_api_client';
import { CUSTOMERMODE } from '../../../../enums/customerMode';
import { TariffAdvisorService } from '../../../../services/tariff-advisor.service';
import { TariffPriceAnnual } from '../../../../../shared/models/tariff-price.annual';
import { TariffSelection } from '../../../../../shared/models/tariff-selection';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'bdo-tariff-data-summary',
  templateUrl: './tariff-data-summary.component.html',
  styleUrls: ['./tariff-data-summary.component.scss']
})
/** Copy of delivery tariff-summary but this one is able to show any tariff without the type suffixes like tariffE
 * Should be enabled for delivery as well
*/
export class TariffDataSummaryComponent implements OnInit {
  @Input() selectedDivision: TariffSelection;
  public CUSTOMERMODE = CUSTOMERMODE;
  public addressData: PersonalDataAddressData;
  public Verbrauchstyp = VERBRAUCHSTYP;
  public prodConstId: string;
  public isLoading = false;
  public prices: TariffPriceAnnual[];
  public tariffData: TariffSummaryResults;
  public meterTooltip = '';
  public title: string;
  public iconSrc: string;
  public hasBonus: boolean = false;
  public isGewe: boolean;

  constructor(
    public tariffAdvisorService: TariffAdvisorService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.tariffData = this.selectedDivision?.selectedTariff;
    this.iconSrc = this.tariffData?.iconUrl ? this.translateService.instant('general.url.iconPathCms') + this.tariffData?.iconUrl :
                   this.tariffAdvisorService.getPathForIcon(this.tariffData?.division);
    this.hasBonus = this.tariffData?.parts?.[0]?.contractPayments?.some(elem => elem.description?.length > 0);
    this.isGewe = this.tariffData?.customerType === CUSTOMERMODE.GEWE;
    this.prices = this.tariffAdvisorService.getPeriodicPricesByTimeSlices(this.tariffData);
    this.updateMeterTooltip();
  }

  updateMeterTooltip() {
    this.meterTooltip = this.tariffAdvisorService.getMeterTooltip(this.tariffData?.customerType);
  }

  getWorkingPriceInfo(costs: Array<string>): string {
    if (!costs) {
      return '';
    }
    let info = '';

    for (const text of costs) {
      if (!text.includes('Konstant')) {
        info += '<div>' + text + '</div>';
      }

    }
    return info;
  }
}
