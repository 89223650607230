"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Variable = void 0;
var Variable = /** @class */function () {
  function Variable(variable) {
    this.key = variable.key;
    this.type = variable.type;
    this.value = variable.value;
  }
  return Variable;
}();
exports.Variable = Variable;