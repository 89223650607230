import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { ReadonlyTenantsService } from '../bdo/services/readonly-tenants.service';

export const readonlyGuard: CanActivateFn = (route, state) : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router);
  const readonlyTenantsService = inject(ReadonlyTenantsService);
  if (readonlyTenantsService.isCurrentTenantReadonly()) {
    return router.navigate(['baustelle']);
  } else {
    return true;
  }
};
