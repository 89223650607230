import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

/**
 * Validates a birthdate in the format 'DD.MM.YYYY'
 * Ignores Dates in other formats
 * @param {string} customErrorMessage
 * @returns {(control: AbstractControl) => {[p: string]: string}}
 */
export function birthdateFullAgeValidator(translateService: TranslateService) {
  return (control: AbstractControl): { [key: string]: string | true } | null => {
    if (!control.value) {
      return null;
    }
    let errorTextUnder18 = translateService.instant('address.personalInfo.birthdate.fullAge');
    let errorTextOver120 = translateService.instant('address.personalInfo.birthdate.invalid');
    const regex = RegExp('^[0-9]{2}\.[0-9]{2}\.[0-9]{4}');
    const isValidDate = moment(control.value, 'DD.MM.YYYY', true).isValid();

    let is18 = true;
    let isUnder120 = true;
    if (isValidDate) {
      is18 = moment().diff(moment(control.value, 'DD.MM.YYYY', true), 'years') >= 18;
      isUnder120 = moment().diff(moment(control.value, 'DD.MM.YYYY', true), 'years') <= 120;
    }

    if (!is18) {
      return { ['birthdate']: (errorTextUnder18 || true) };
    } else if (!isUnder120) {
      return { ['birthdate']: (errorTextOver120 || true) };
    }
    return null;
  };
}
