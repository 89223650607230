import { ChangeDetectionStrategy, Component, computed, effect, input, signal, WritableSignal } from '@angular/core';
import { CurrentCampaign } from '../../../../services/campaign.service';
import { BdoApiService } from '../../../../services/bdo-api.service';
import { catchError, first, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { TenantService } from '../../../../services/tenant.service';
import { CustomerStoreService } from '../../../../services/customer-store.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Environment } from '../../../../../../environments/environment';
import { TENANT } from '../../../../enums/tenant.enum';
import { StorageService } from '../../../../services/storage.service';
import { TRACKING } from '../../../../enums/trackingParts.enum';
import { TrackingService } from '../../../../services/tracking.service';

@Component({
  selector: 'bdo-opt-in-popup',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './opt-in-popup.component.html',
  styleUrl: './opt-in-popup.component.scss'
})
export class OptInPopupComponent{
  public popupIsReadyToShow = input.required<boolean>();
  public currentCampaign = input.required<CurrentCampaign>();
  public hasNoCampaigns = input.required<boolean>();
  public popupIsOpen = signal(true);
  public accountId = toSignal(this.customerStoreService.currentAccountId$);
  public show = computed(() =>
    this.popupIsReadyToShow() === true &&
    Environment.tenant === TENANT.Rheinenergie &&
    this.accountId() &&
    !StorageService.getOptInDismissed(this.accountId()) &&
    (this.hasNoCampaigns() || !!this.currentCampaign()?.dismissed) &&
    this.popupIsOpen() &&
    this.hasNoOptIn());

  public hasNoOptIn: WritableSignal<boolean | undefined> = signal(undefined);

  constructor(
    private readonly apiService: BdoApiService,
    private readonly customerStoreService: CustomerStoreService,
    private readonly router: Router,
    private readonly trackingService: TrackingService,
    public readonly tenantService: TenantService
  ) {
    effect(() => {
      if (this.accountId()){
        this.loadOptInState();
      }
    });
    effect(() => {
      if (this.show()){
        this.trackingService.postTracking(TRACKING.LOCATION.OPT_IN, TRACKING.ACTION.ENTER);
      }
    });
  }

  public closePopup(){
    this.popupIsOpen.set(false);
    StorageService.setOptInDismissed(this.accountId());
  }

  public loadOptInState() {
    this.apiService.getOptin().pipe(
        map((res) => (!res.optInFlag)),
        // ignore error, just don't show the popup if optInState is unclear
        catchError(() => {
          this.closePopup();
          return of(false);
        })
    ).subscribe({
      next: value => this.hasNoOptIn.set(value)
    });
  }

  public onClickSaveNow() {
    this.closePopup();
    this.trackingService.postTracking(TRACKING.LOCATION.OPT_IN, TRACKING.ACTION.GOTO, TRACKING.LOCATION.PERSONAL_DATA);
    this.router.navigate(['/daten/' + this.accountId()], { fragment: 'optin', state: { isEdit: true } });
  }
}
