/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * object containing postal address data
 */
export interface AddressData { 
    /**
     * Zip Code / PLZ
     */
    postCode?: string;
    cityName?: string;
    streetName?: string;
    houseNum?: string;
}

