import { Inject, Injectable } from '@angular/core';
import moment from 'moment';
import { ReadonlyTenantConfiguration } from '../models/readonlyTenantConfiguration';
import { Environment } from '../../../environments/environment';
import { READONLY_TENANTS_CONFIGURATION_TOKEN } from '../configurations/readonly-tenants.configuration.token';


@Injectable({
  providedIn: 'root'
})

export class ReadonlyTenantsService {
  constructor(@Inject(READONLY_TENANTS_CONFIGURATION_TOKEN) private readonlyTenantsConfiguration: Array<ReadonlyTenantConfiguration>) {}

  public isCurrentTenantReadonly(): boolean {
    const currentTenant = this.readonlyTenantsConfiguration.find(configuration => configuration.tenant === Environment.tenant);
    if (currentTenant) {
      return moment().isAfter(currentTenant.readonlyFrom);
    }
    return false;
  }
}
