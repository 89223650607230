<div *ngIf="paymentData?.paymentMethod === 'sepa'; else non_sepa">
  <h5 class="re-mb-30">{{ 'banking.sepa.sepaMandate' | translate }}</h5>
  <div class="re-summary-row">
    <div>{{ 'banking.iban' | translate }}</div>
    <div>{{ paymentData?.bankData?.iban | maskIBAN }}</div>
  </div>
  <div class="re-summary-row re-mb-0">
    <div>{{ 'banking.bankname' | translate }}</div>
    <div>{{ paymentData?.bankData?.bankname }}</div>
  </div>
</div>
<ng-template #non_sepa>
  <h5 class="re-mb-30">{{ 'banking.manual' | translate }}</h5>
  <div *ngIf="!noFutureContract">{{ 'payment.bankTransfer.description' | translate }}</div>
  <div *ngIf="noFutureContract">{{ 'summary.payment.lastPaymentInfo' | translate }}</div>
</ng-template>
