<form [formGroup]="paymentForm">
  <div class="re-flex" [ngClass]="{'re-flex-col-reverse': !(this.showDifferentPaymentFirst), 're-flex-col': this.showDifferentPaymentFirst}">
    <div>
      <div class="re-form-item-container">
    <div class="re-flex re-items-start re-relative re-mb-15">
      <input id="differentPayment" type="radio" value="differentPayment" name="paymentOptions" formControlName="paymentOptions">
      <label class="re-ml-10" for="differentPayment">{{ 'payment.process.addDifferent' | translate }}
        <span *ngIf="showDifferentPaymentFirst"><br>{{ 'payment.process.sepaRecommendation' | translate }}</span>
      </label>
    </div>
  </div>
  <div *ngIf="paymentForm?.get('paymentOptions')?.value === 'differentPayment'">
    <div class="re-form-item-container re-pt-15 re-mb-20">
      <fieldset class="re-mb-0">
        <bdo-dropdown
          [data]="paymentMethodDropdown"
          [selected]="paymentForm?.get('paymentMethod')?.value"
          [labelText]="'payment.process.type' | translate"
          data-qa="paymentMethod"
          (itemSelected)="updatePaymentMethod($event)"
        >
        </bdo-dropdown>
      </fieldset>
    </div>
    <!-- SEPA - Mandat -->
    <div *ngIf="paymentForm?.get('paymentMethod')?.value === 'sepa'">
      <fieldset class="re-mb-0">
        <div class="re-form-item-container re-mb-0">

        <bdo-sepa-form
          [formTracking]="trackingSepaForm"
          [parentForm]="paymentForm"
          [sepaRequired]="paymentForm?.get('paymentMethod')?.value === 'sepa'"
        ></bdo-sepa-form>
      </div>
      </fieldset>
    </div>

    <!-- Manuelle Überweisung -->
    <div *ngIf="paymentForm?.get('paymentMethod')?.value === 'bankTransfer'">
      <div class="re-form-item-container re-mb-30 re-mt-30 md:re-mt-45" data-qa="textBankTransfer">
        {{ 'payment.process.bankTransferHint' | translate }}
      </div>
    </div>
  </div>
    </div>
    <div>
      <div class="re-form-item-container re-mb-15">
    <div class="re-flex re-items-start">
      <input id="keepPayment" type="radio" value="keepPayment" name="paymentOptions" formControlName="paymentOptions" selected>

      <div class="re-ml-10">
        <div class="re-pb-10">
          <label for="keepPayment">{{ 'payment.process.keep' | translate }}
            <span *ngIf="bankAssignment?.debit?.id"><br>{{ 'payment.process.sepaRecommendation' | translate }}</span>
          </label>
        </div>

        <div *ngIf="paymentForm?.get('paymentOptions')?.value === 'keepPayment'" class="re-relative">
          <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>

          <!-- keep - show bankAccount -->
          <div *ngIf="paymentForm?.get('paymentOptions')?.value === 'keepPayment' &&
                      bankAssignment?.debit && bankAssignment?.debit?.id">
              <div class="re-form-item-container re-relative re-mb-0">
              <div class="re-flex">
                <div class="bold inhaber--left re-min-w-120">{{ 'banking.accountholder' | translate }}:</div>
                <div class="inhaber--right re-pl-5" data-qa="prefill_inhaber">{{ bankAssignment?.debit?.accountholder }}</div>
              </div>
              <div class="re-flex">
                <div class="bold iban--left re-min-w-120">{{ 'banking.iban' | translate }}:</div>
                <div class="iban--right re-pl-5" data-qa="prefill_iban">{{ bankAssignment?.debit?.iban }}</div>
              </div>
            </div>
          </div>

          <!-- keep -  manual payment -->
          <div *ngIf="paymentForm?.get('paymentOptions')?.value === 'keepPayment' &&
                      (!bankAssignment?.debit || !bankAssignment?.debit.id)">
            <p class="bold re-mt-10 re-mb-30" data-qa="manualPayment"> {{ 'banking.manual' | translate }} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  </div>
</form>
