import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

/**
 * Prohibit the use of < and > in inputs as they are parsed out and cause errors in Backend
 * @param {string} customErrorMessage
 * @returns {(control: AbstractControl) => {[p: string]: string}}
 */
export function htmlTagValidator(translateService: TranslateService, errorText?: string) {
  return (control: AbstractControl): { [key: string]: string | true } | null => {
    if (!control.value) {
      return null;
    }

    errorText = errorText ? errorText : translateService.instant('register.password.htmlTags');

    if (control.value.includes('<') || control.value.includes('>')) {
      return { ['htmlTags']: (errorText ? errorText : true) };
    }
    return null;
  };
}
