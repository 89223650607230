<div class="bdo-container">
  <h4>{{ 'logout.title' | translate}}</h4>

  <div class="content" [innerHTML]="'logout.description' | translate"></div>

  <div class="bdo-space--bottom">
    <a class="bdo-button-primary" [href]="tenantService.getCurrentTenantData().hrefHomepage" (click)="goToMainPage()">
      {{ 'general.navigation.linkToHomepage' | translate }}
    </a>
  </div>

  <!-- use href instead of routerLink to avoid angular router navigation and reinit the app for the next user -->
  <bdo-text-link-icon
    [linkText]="'login.linkTo' | translate"
    [href]="'/login'"
    [iconPosition]="'right'"
    [tracking]="[TRACKING.LOCATION.LOGOUT_SUCCESS, TRACKING.ACTION.GOTO, TRACKING.LOCATION.LOGIN]">
  </bdo-text-link-icon>

</div>
