<h3 class="bdo-container re-mb-45 md:re-mb-75">{{ 'contract.edit.auth.title' | translate }}</h3>

<div class="re-mb-60 md:re-mb-90">
  <bdo-anonymous-or-login [destination]="'/vertrag/' + placeholderAccountId"></bdo-anonymous-or-login>
</div>

<!-- FAQs -->
<div class="re-mb-60 md:re-mb-90">
  <bdo-faqs
    [keyName]="'contract.edit.faqs'"
  ></bdo-faqs>
</div>

<!-- OnlineService -->
<div class="re-container-headline">
  <bdo-section-headline>
    <div class="re-mb-default">
      {{ 'register.integrated.title' | translate }}
    </div>
  </bdo-section-headline>
</div>
<h4 class="re-container-with-margin re-mb-20 md:re-mb-30">
  {{ 'register.integrated.subtitle' | translate }}
</h4>
<div class="re-container-with-margin re-text--small re-mb-20">
  {{ 'register.integrated.description' | translate }}
</div>
<div class="re-container-with-margin">
  <ul class="bdo-list__bullet re-text--small re-mb-30">
    <li class="re-mb-10">
      {{ 'register.benefit.1' | translate }}
    </li>
    <li class="re-mb-10">
      {{ 'register.benefit.2' | translate }}
    </li>
    <li class="re-mb-10">
      {{ 'register.benefit.3' | translate }}
    </li>
    <li class="re-mb-10">
      {{ 'register.benefit.4' | translate }}
    </li>
    <li>
      {{ 'register.benefit.5' | translate }}
    </li>
  </ul>

  <bdo-text-link-icon
    [iconPosition]="'right'"
    [linkText]="'register.integrated.submit' | translate"
    [routerUrl]="'/registrieren'"
  ></bdo-text-link-icon>
</div>
