import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tariffTitle'
})
export class TariffTitlePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === null) { return ''; }
    return this.mapTariff(value);
  }

  mapTariff(key) {

    switch (key) {
      case 'RESHN0001556197': // Prod RE Strom
      case 'RESHN0001553544': // AKZ RE Strom
        return 'Tarif für Heimatliebende';
      case 'RESHN0001555590': // Prod RE Strom
      case 'REGHN0000098275': // Prod RE Gas
      case 'BKSHN0000738399': // Prod Belkaw Strom
      case 'BKGHN0000113321': // Prod Belkaw Gas
      case 'SASHN0000000104': // Prod SWA Strom
      case 'SAGHN0000000059': // Prod SWA Gas
      case 'SAGHN0000000035': // Prod SWA Gas ?
      case 'RESHN0001553501': // AKZ RE Strom
      case 'REGHN0000097159': // AKZ RE Gas
      case 'REGHN0000097256': // AKZ RE Gas
      case 'BKSHN0000736997': // Akz Belkaw Strom
      case 'BKGHN0000112564': // Akz Belkaw Gas
      case 'SASHN0000000055': // Akz SWA Strom
      case 'SAGHN0000000033': // Akz SWA Gas
      case 'RESGN0000463141': // GEWE Prod RE Strom
      case 'REGGN0000047709': // GEWE Prod RE Gas
      case 'BKSGN0000156234': // GEWE Prod Belkaw Strom
      case 'BKGGN0000028332': // GEWE Prod Belkaw Gas
      case 'SASGN0000000039': // GEWE Prod SWA Strom
      case 'SAGGN0000000004': // GEWE Prod SWA Gas
        return 'Tarif für Sicherheitsorientierte';
      case 'RESHG0000000475': // Prod RE Strom & Gas
      case 'REGHG0000000000': // Prod RE Gas
      case 'BKSHG0000000323': // Prod Belkaw Strom
      case 'BKGHG0000000000': // Prod Belkaw Gas & Akz Belkaw Gas
      case 'RESHG0000000471': // Akz RE Strom
      case 'BKSHG0000000317': // Akz Belkaw Strom
      case 'RESGG0000000247': // GEWE Prod RE Strom
      case 'REGGG0000000000': // GEWE Prod RE Gas
      case 'BKSGG0000000064': // GEWE Prod Belkaw Strom
      case 'BKGGG0000000000': // GEWE Prod Belkaw Gas
        return 'Tarif für Freiheitsliebende';
      case 'REGHN0000098276': // Prod RE Gas
      case 'BKSHN0000738400': // Prod Belkaw Strom
      case 'BKGHN0000113322': // Prod Belkaw Gas
      case 'SASHN0000000105': // Prod SWA Strom
      case 'SAGHN0000000060': // Prod SWA Gas
      case 'SAGHN0000000036': // Prod SWA Gas ?
      case 'REGHN0000097172': // Akz RE Gas
      case 'REGHN0000097257': // Akz RE Gas
      case 'BKSHN0000736998': // Akz Belkaw Strom
      case 'BKGHN0000112570': // Akz Belkaw Gas
      case 'SASHN0000000058': // Akz SWA Strom
      case 'SAGHN0000000052': // Akz SWA Gas
      case 'RESGN0000463142': // GEWE Prod RE Strom
      case 'REGGN0000047710': // GEWE Prod RE Gas
      case 'BKSGN0000156235': // GEWE Prod Belkaw Strom
      case 'BKGGN0000028333': // GEWE Prod Belkaw Gas
      case 'SASGN0000000040': // GEWE Prod SWA Strom
      case 'SAGGN0000000005': // GEWE Prod SWA Gas
        return 'Tarif für Umweltbewusste';
      default:
        return 'Ihr Tarif';
    }
  }

}
