<div class="bdo-container bdo-space--bottom re-relative">
  <h3 class="re-mb-30 md:re-mb-45">{{ 'move.productSelection.title' | translate }}</h3>

  <!-- area if not all tariffs have been selected -->
  <div class="re-bg-white re-w-full re-text re-flex re-p-20 re-items-left re-mb-50 re-justify-between re-flex-col
            md:re-w-600 md:re-mx-auto md:re-flex-row
            lg:re-w-900" *ngIf="getCompletedDivisions()?.length && !isOneDivision">
    <div class="re-flex re-flex-col">
      <div *ngFor="let tariff of getCompletedDivisions()">
        <svg-icon [applyClass]="true" class="re-fill-current re-stroke-current re-h-20 re-w-20 re-text-0 re-align-middle" src="{{ tariff?.divisionConfiguration?.iconSrc }}"></svg-icon>
        <span class="bold">{{ 'general.division.' + tariff?.divisionConfiguration?.id | translate }}</span>
        <span [innerHTML]="tariff.selectedTariff?.name"></span>
      </div>
    </div>
    <div class="re-flex re-flex-col re-mt-30 md:re-ml-auto md:re-mt-0">
      <div class="re-text-base md:re-ml-auto bold">
        {{getSum() | number:'1.2-2'}} €
      </div>
      <div class="re-text-xs">
        {{ 'tariffAdvisor.summary.monthlyCosts' | translate }}
      </div>
    </div>
  </div>

  <p *ngIf="!isOneDivision" class="re-w-full md:re-w-600 md:re-mx-auto lg:re-w-900 bold"> {{getCurrentStep()}} von {{ tariffSelections?.length}}</p>

  <div class="re-w-full md:re-w-600 lg:re-w-900 md:re-mx-auto re-mb-30 md:re-mb-45">
    <div class="re-text-sm re-leading-m md:re-text-base md:re-leading-2xl">
      {{ (isOneDivision ? 'tariffAdvisor.descriptionSingle' : 'tariffAdvisor.descriptionMulti') | translate }}
    </div>
    <div class="bold re-mt-15 md:re-mt-5" *ngIf="getSelectedDivisionState()?.numberOfMeterNumbers > 1">
      {{ 'tariffAdvisor.hintMultipleMeters' | translate: { division: getSelectedDivisionState()?.type | verbrauchstyp } }}
    </div>
  </div>

  <bdo-select-button-group
    #selectButtonSection
    [clickable]="false"
    [selectButtons]="selectButtonConfigurations"
    (activate)="selectDivision($event.id)"
  ></bdo-select-button-group>

  <bdo-tariff-consumption-selection
    [consumption]="getSelectedDivisionState()?.consumption"
    [postCode]="tariffConfiguration?.postCode"
    [divisionId]="this.getSelectedDivisionState()?.divisionConfiguration.id"
    [consumptionForm]="consumptionForm"
    [isInEditMode]="consumptionEditMode"
    [hasMultipleMeters]="getSelectedDivisionState()?.numberOfMeterNumbers > 1"
    [isTrackingDisabled]="true"
    (calculate)="onCalculate()"
  ></bdo-tariff-consumption-selection>

  <div class="bdo-container--single re-mt-20">
    <div [class.re-mb-15]="state === LoadingState.ERROR">
      <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">
        {{ 'general.error.error' | translate }}
      </bdo-info-box>
    </div>
  </div>

  <div class="bdo-container--single re-relative" [ngClass]="state === LoadingState.LOADING ? 'bdo-loading__wrapper--height re-mt-30' : ''">
    <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
  </div>

  <ng-container>
    <div *ngIf="consumptionForm.controls.consumption.value && !consumptionEditMode && !getSelectedDivisionState()?.selectedTariff"
      [@fadeIn]
      class="bdo-tariff-tile-container re-gap-20 re-flex re-flex-col re-items-center re-justify-center re-flex-grow-0 re-my-45 lg:re-my-60
            lg:re-items-stretch lg:re-flex-row lg:re-flex-nowrap rate-price-tile--sum">
      <ng-container *ngFor="let tariff of availableTariffs">
        <bdo-tariff-tile [tariffData]="tariff"
                        [isSmall]="availableTariffs?.length >= 4"
                        (selectTariff)="onTariffSelected($event)"
                        class="rate-price-tile re-mb-40 lg:re-mb-0 re-flex re-flex-col"></bdo-tariff-tile>
      </ng-container>
    </div>
  </ng-container>

  <div class="re-w-full re-mt-30
              md:re-mt-45
              md:re-w-600 md:re-mx-auto
              lg:re-w-900">
      <bdo-text-icon
        [iconPosition]="'left'"
        [text]="'general.action.back' | translate"
        (itemClicked)="onBack()"
      >
      </bdo-text-icon>
  </div>
</div>
