/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Customermode derived from loadProfile (Bedarfsart/Lastprofil)
 */
export interface LoadProfileCustomerMode { 
    /**
     * PRIV -> private customer; GEWE -> business customer
     */
    customerMode?: string;
}

