<bdo-minimal-header class="header"></bdo-minimal-header>

<main class="main-content">
  <router-outlet>
    <bdo-loading *ngIf="isNavigating" [fullscreen]=true></bdo-loading>
    <bdo-breadcrumb class="re-mb-30 re-block"></bdo-breadcrumb>
    <bdo-hotline-slide-over></bdo-hotline-slide-over>
  </router-outlet>
</main>

<bdo-svg-definitions></bdo-svg-definitions>

<bdo-minimal-footer></bdo-minimal-footer>

<bdo-maintenance-warning
  [infoText]="(maintenanceData$ | async)?.infotext"
  [start]="(maintenanceData$ | async)?.start"
  [end]="(maintenanceData$ | async)?.end"
  [soonUnderMaintenance]="(maintenanceStatus$ | async) === BackendStatus.SoonUnderMaintenance"
></bdo-maintenance-warning>

<bdo-campaign-popup
  *ngIf="campaignPopupIsReadyToShow$ | async"
  [popupIsReadyToShow$]="campaignPopupIsReadyToShow$"
  [campaign]="campaignService.currentCampaign$ | async"
></bdo-campaign-popup>


<bdo-opt-in-popup
  *ngIf="popupsAreReadyToShow$ | async"
  [popupIsReadyToShow]="popupsAreReadyToShow$ | async"
  [currentCampaign]="campaignService.currentCampaign$ | async"
  [hasNoCampaigns]="campaignService.hasNoCampaigns$ | async"
/>

<bdo-online-service-removed-message
  [readonlyHasStarted]="readonlyTenantsService.isCurrentTenantReadonly()"
  [showMessage]="showOnlineServiceRemovedMessage$ | async"
  (closeMessage)="onCloseOnlineServiceRemovedMessage()"
></bdo-online-service-removed-message>

<bdo-toast-notification
  *ngIf="toastService.currentToast()"
></bdo-toast-notification>

<bdo-cookiebanner></bdo-cookiebanner>
