<button class="card re-block re-relative re-bg-white re-border re-border-white re-text-left hover:re-border-monochrome-dark re-cursor-pointer re-mx-20 re-pt-20 re-mt-20
            md:re-mt-0 md:re-py-0 md:re-mx-0 bdo-stretch"
            type="button"
            [ngClass]="{
                        're-mt-20': !selected && !first || selected && first,
                        're-mt-45': selected && !first,
                        'inactive re-pb-20': selected === false,
                        'active re-pt-30': selected,
                        're-pb-30': selected !== false}">
  <div *ngIf="selected === true" class="checkmark re-absolute mb-15 re-bg-success re-text-white re-p-15">
    <svg-icon src="assets/icons/wizard_steps/checkmark.svg"></svg-icon>
  </div>
  <div class="re-flex re-flex-row md:re-flex-col">
    <div [class.re-hidden]="selected === false" [class.re-block]="selected !== false"
        class="re-stroke-current re-fill-current re-m-auto re-text-auto group-hover:re-text-secondary-hover
                re-min-w-75 re-ml-15 re-mr-30
                md:re-pt-30 md:re-w-90 md:re-mx-auto md:re-min-w-0 md:re-block md:re-h-auto
                lg:re-pt-45 lg:re-w-120">
      <svg-icon class="md:re-hidden" [src]="icon?.sm || icon?.default"></svg-icon>
      <svg-icon class="re-hidden md:re-inline lg:re-hidden" [src]="icon?.md || icon?.default"></svg-icon>
      <svg-icon class="re-hidden lg:re-inline" [src]="icon?.lg || icon?.default"></svg-icon>
    </div>
    <div class="md:re-pb-30" [ngClass]="{'re-mx-auto': selected === false}">
      <h2 class="md:re-text-center md:re-mt-30 md:re-mb-20
                  lg:re-mb-15"
            [class.re-mb-0]="selected === false"
            [class.re-mb-10]="selected !== false"
           >
        {{title}}
    </h2>
      <div class="re-text-xs re-leading-s re-pr-30
                  md:re-px-30 md:re-text-center md:re-block md:re-pb-0
                  lg:re-px-90"
           [class.re-hidden]="selected === false" [innerHTML]="subtitle">
      </div>
    </div>
  </div>

</button>
