const ALIAS = Symbol.for('yaml.alias');
const DOC = Symbol.for('yaml.document');
const MAP = Symbol.for('yaml.map');
const PAIR = Symbol.for('yaml.pair');
const SCALAR = Symbol.for('yaml.scalar');
const SEQ = Symbol.for('yaml.seq');
const NODE_TYPE = Symbol.for('yaml.node.type');
const isAlias = node => !!node && typeof node === 'object' && node[NODE_TYPE] === ALIAS;
const isDocument = node => !!node && typeof node === 'object' && node[NODE_TYPE] === DOC;
const isMap = node => !!node && typeof node === 'object' && node[NODE_TYPE] === MAP;
const isPair = node => !!node && typeof node === 'object' && node[NODE_TYPE] === PAIR;
const isScalar = node => !!node && typeof node === 'object' && node[NODE_TYPE] === SCALAR;
const isSeq = node => !!node && typeof node === 'object' && node[NODE_TYPE] === SEQ;
function isCollection(node) {
  if (node && typeof node === 'object') switch (node[NODE_TYPE]) {
    case MAP:
    case SEQ:
      return true;
  }
  return false;
}
function isNode(node) {
  if (node && typeof node === 'object') switch (node[NODE_TYPE]) {
    case ALIAS:
    case MAP:
    case SCALAR:
    case SEQ:
      return true;
  }
  return false;
}
const hasAnchor = node => (isScalar(node) || isCollection(node)) && !!node.anchor;
export { ALIAS, DOC, MAP, NODE_TYPE, PAIR, SCALAR, SEQ, hasAnchor, isAlias, isCollection, isDocument, isMap, isNode, isPair, isScalar, isSeq };