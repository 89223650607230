<div class="re-container-content
            md:re-w-600 md:re-mx-auto
            lg:re-w-900">
  <div [ngClass]="{'re-mb-20 md:re-mb-20': selectedTile !== 'none', 're-mb-60 md:re-mb-45': selectedTile === 'none'}"
      class="re-flex re-flex-col
             md:re-flex-row" #selectArea>
    <div (click)="onSelect(tile.id)" class="re-flex-1 re-group" [ngClass]="first ? 'md:re-mr-10' : 'md:re-ml-10'" [attr.data-qa]="tile.id + '_btn'"
      *ngFor="let tile of configuration; let first = first">
      <bdo-card
        [title]="tile?.title"
        [subtitle]="tile?.description"
        [icon]="tile?.src"
        [first]="first"
        [selected]="!selectedTile ? undefined : (selectedTile === tile?.id)"
      ></bdo-card>
    </div>
  </div>
</div>
