<ng-container *ngIf="addressData">
  <h5 class="re-mb-30">{{ 'summary.address.titleDelivery' | translate }}</h5>

  <div class="re-summary-row">
    <div>
      {{ 'address.postCode.postCodeAndCity' | translate }}
    </div>
    <div>
      {{addressData.postCode}}, {{addressData.cityName}}
    </div>
  </div>

  <div class="re-summary-row">
    <div>
      {{ 'address.street.streetAndHousenumber' | translate }}
    </div>
    <div>
      {{addressData.streetName}} {{addressData.houseNum}}
    </div>
  </div>

</ng-container>
