import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  PersonalData,
  RegistrationData,
  SituationData,
} from '../../../../../assets/js/com/ts_api_client';
import { FormGroup } from '@angular/forms';
import { LoginService } from '../../../../login/login.service';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { HomeData } from '../../../models/home';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { TenantService } from '../../../services/tenant.service';
import punycode from 'punycode/';
import { RESPONSE_STATUS } from '../../../enums/responseStatus.enum';
import { AuthDataStorage } from '../../../models/AuthData.storage';
import { MoveService } from '../../../services/move.service';
import { AvailableDivisionInfos } from '../new-home/product-selection/product-selection.component';
import { TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { defaultDivisionConfigurations } from '../../tariff-advisor/tariff-advisor.component';
import { VERBRAUCHSTYP } from '../../../enums/verbrauchstyp.enum';
import { ExternalTenantConfiguration } from './external-tenant-tile/external-tenant-tile.component';
import { QuickRegisterFormtype } from '../../../../shared/formtypes/quick-register.formtype';
import { Utilities } from '../../../../shared/utils/utilities';
import { LocationStrategy } from '@angular/common';
import { TrustpilotService } from '../../../services/trustpilot.service';
import { TariffAdvisorService } from '../../../services/tariff-advisor.service';


@Component({
  selector: 'bdo-move-confirmation',
  templateUrl: './move-confirmation.component.html',
  styleUrls: ['./move-confirmation.component.scss']
})
export class MoveConfirmationComponent implements OnInit, AfterViewInit {
  public LoadingState = LOADING_STATE;
  public email: string;
  public cancelDate: Date;
  public startDate: Date;
  public trustpilotUniqueUrl: string;
  public registerForm = new FormGroup<{
    registrationData?: FormGroup<QuickRegisterFormtype>
  }>({});
  public registrationShown: boolean = false;
  public registrationDone: boolean = false;
  public registerState: LOADING_STATE = LOADING_STATE.IDLE;
  public divisionsForInfo: string = '';
  public availableDivisionInfos: AvailableDivisionInfos;
  public isRegistered: boolean;
  public externalTenantConfigurations: ExternalTenantConfiguration[];
  public postCode: string;
  public TRACKING = TRACKING;
  public productIds: Array<string>;
  private authData: AuthDataStorage;

  constructor (
    public tenantService: TenantService,
    public moveService: MoveService,
    public loginService: LoginService,
    public tariffService: TariffAdvisorService,
    private trackingService: TrackingService,
    private locationStrategy: LocationStrategy,
    private trustpilotService: TrustpilotService
  ) { }

  ngAfterViewInit(): void {
    // Cleanup all sessionData but not before the view has been init (there are dependencies on the storage)
    window.sessionStorage.clear();
    Utilities.disableBrowsersBackButton(this.locationStrategy);
  }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.MOVE_CONFIRMATION, TRACKING.ACTION.ENTER);

    this.divisionsForInfo = this.moveService.divisionsForInfo();
    this.isRegistered = this.moveService.isAnonymousButRegistered();
    this.availableDivisionInfos = StorageService.getValue<AvailableDivisionInfos>(ALLOWED_KEYS.AVAILABLE_DIVISIONS);
    this.postCode = StorageService.getPersonalData()?.addressData?.postCode;
    this.trustpilotUniqueUrl = this.trustpilotService.buildTrustpilotUniqueUrl(StorageService.getTrustPilotToken());
    this.externalTenantConfigurations = this.availableDivisionInfos?.availableDivisions
      .filter(item => [VERBRAUCHSTYP.Strom, VERBRAUCHSTYP.Gas].includes(item.divisionId))
      .filter((item) => !item.areaBelongsToCurrentTenant)
      .reduce((result, item) => {
        const tenantData = this.tenantService.getTenantDataByShort(item.tenantNameShort);
        if (tenantData) {
          const existingConfigInResult = result.find((config) => config.companyName === tenantData.companyName);
          if (existingConfigInResult) {
            if (!existingConfigInResult.divisions.find((division) => division.divisionId === item.divisionId)) {
              existingConfigInResult.divisions.push({
                divisionId: item.divisionId,
                tariffAdvisorLink: tenantData.tariffAdvisorLinks[item.divisionId]
              });
            }
          } else {
            const config: ExternalTenantConfiguration =  {
              changeTenant: true,
              companyName: tenantData.companyName,
              logo: tenantData.bannerLogo,
              divisionIconSrc: defaultDivisionConfigurations[item.divisionId]?.iconSrc,
              divisions: [{
                divisionId: item.divisionId,
                tariffAdvisorLink: tenantData.tariffAdvisorLinks[item.divisionId]
              }]
            };
            result.push(config);
          }
          return result;
        }
        return undefined;
      }, new Array<ExternalTenantConfiguration>());
    this.email = StorageService.getValue<PersonalData>(ALLOWED_KEYS.PERSONAL_DATA)?.email;
    this.startDate = StorageService.getValue<SituationData>(ALLOWED_KEYS.SITUATION_DATA)?.startDate ?
                    new Date(StorageService.getValue<SituationData>(ALLOWED_KEYS.SITUATION_DATA)?.startDate) :
                    null;
    this.cancelDate = StorageService.getValue<HomeData>(ALLOWED_KEYS.RECENT_HOME_DATA)?.date ?
                    new Date(StorageService.getValue<HomeData>(ALLOWED_KEYS.RECENT_HOME_DATA)?.date) :
                    null;

    // Check if registration shall be shown
    this.authData = StorageService.getValue<AuthDataStorage>(ALLOWED_KEYS.AUTH_DATA);
    if (this.authData && !this.authData?.isRegistered) {
      this.registrationShown = true;
      this.trackingService.postBaseAndAdditionalTracking({
        orca_event_type: 'Umzugsbestätigung',
        orca_event_action: 'show Registration'
      });
    } else {
      this.registrationShown = false;
    }
    this.productIds = StorageService.getTariffData()?.map(tariff => tariff.productId);
  }

  register() {
    if (this.registerForm.valid) {
      const registrationData: RegistrationData = {};
      registrationData.accountId = this.authData.checkIdentificationData.accountId;
      registrationData.meterNumberOrRegisterCode = this.authData.checkIdentificationData.meterNumberOrRegisterCode;
      registrationData.mail = punycode.toUnicode(this.email);
      registrationData.username = this.registerForm.get('registrationData')?.get('username')?.value;
      registrationData.password = this.registerForm.get('registrationData')?.get('passwordGroup')?.get('password').value;

      this.loginService.register(registrationData)
        .subscribe(
          {
            next: (res) => {
              if (res.status === RESPONSE_STATUS.Error) {
                this.trackingService.postSimpleTracking(TRACKING.LOCATION.MOVE_CONFIRMATION, 'Register.' + TRACKING.ACTION.ERROR);
                this.registerState = LOADING_STATE.ERROR;
              } else {
                // Show Success Message
                this.registerState = LOADING_STATE.IDLE;
                this.registrationDone = true;
                this.trackingService.postSimpleTracking(TRACKING.LOCATION.MOVE_CONFIRMATION, 'Register.' + TRACKING.ACTION.SUCCESS);
              }
            },
            error: () => {
              this.trackingService.postSimpleTracking(TRACKING.LOCATION.MOVE_CONFIRMATION, 'Register.' + TRACKING.ACTION.ERROR);
              this.registerState = LOADING_STATE.ERROR;
            }
          });
    } else {
      this.registerForm.markAllAsTouched();
    }
  }

}
