import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CheckIdentificationData } from '../../../assets/js/com/ts_api_client';
import { LOADING_STATE } from '../../bdo/enums/loadingState.enum';
import { TRACKING } from '../../bdo/enums/trackingParts.enum';
import { ALLOWED_KEYS, StorageService } from '../../bdo/services/storage.service';
import { TrackingService } from '../../bdo/services/tracking.service';
import { LoginService } from '../login.service';
import { AuthDataStorage } from '../../bdo/models/AuthData.storage';
import { PLACEHOLDER_ROUTE_ACCOUNTID } from '../../bdo/services/customer-store.service';

@Component({
  selector: 'bdo-anonymous-or-login',
  templateUrl: './anonymous-or-login.component.html',
  styleUrls: ['./anonymous-or-login.component.scss']
})
export class AnonymousOrLoginComponent implements OnInit, OnDestroy {
  @Input() destination: string;
  @Input() isAnonymousOnly: boolean = false;
  @Input() trackingLocation: string;
  @Input() trackingDestination: string;
  public selectedState: 'anonymous' | 'withAccount' | 'renewPassword' = 'anonymous';
  public anonymousIdentificationData: CheckIdentificationData;
  public isWrongCombination: boolean = false;
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;

  constructor(
    private loginService: LoginService,
    private trackingService: TrackingService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.ANONYMOUS_AUTHENTICATION, TRACKING.ACTION.ENTER);
  }

  ngOnDestroy(): void {
    // Remove auth data, if not authenticating anonymously
    if (this.selectedState !== 'anonymous') {
      StorageService.clearValues([ALLOWED_KEYS.AUTH_DATA]);
    }
  }

  onStateChanged() {
    if (this.selectedState === 'anonymous') {
      this.selectedState = 'withAccount';
    } else {
      this.selectedState = 'anonymous';
      this.trackingService.postTracking(TRACKING.LOCATION.ANONYMOUS_AUTHENTICATION, TRACKING.ACTION.ENTER);
    }
  }

  sendIdentificationData(checkIdentificationData: CheckIdentificationData) {
    this.anonymousIdentificationData = checkIdentificationData;
    this.state = LOADING_STATE.LOADING;
    this.loginService.checkIdentification(checkIdentificationData.accountId, checkIdentificationData.meterNumberOrRegisterCode)
      .subscribe(
        {
          next: (statusResponse) => {
            if (statusResponse.status === 'SUCCESS') {
              this.state = LOADING_STATE.IDLE;
              this.trackingService.postTracking(TRACKING.LOCATION.ANONYMOUS_AUTHENTICATION, TRACKING.ACTION.SUCCESS);
              // save anonymous data in storage and continue
              StorageService.setValue<AuthDataStorage>(ALLOWED_KEYS.AUTH_DATA, {
                checkIdentificationData,
                isRegistered: true
              });
              if (this.trackingLocation && this.trackingDestination) {
                this.trackingService.postSimpleTracking(this.trackingLocation, TRACKING.ACTION.GOTO, this.trackingDestination);
              }

              this.router.navigate([this.destination.replace(PLACEHOLDER_ROUTE_ACCOUNTID, checkIdentificationData.accountId)]);
            } else {
              this.state = LOADING_STATE.ERROR;
              this.trackingService.postTracking(TRACKING.LOCATION.ANONYMOUS_AUTHENTICATION, TRACKING.ACTION.FAILURE);
              this.isWrongCombination = true;
            }
          },
          error: () => {
            this.state = LOADING_STATE.ERROR;
            this.trackingService.postTracking(TRACKING.LOCATION.ANONYMOUS_AUTHENTICATION, TRACKING.ACTION.FAILURE);
          }
        }
      );
  }

  resetErrorBox() {
    this.state = LOADING_STATE.IDLE;
    this.isWrongCombination = false;
  }

}
