"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FeatureFlagV2 = void 0;
var rule_1 = require("./rule");
var variationV2_1 = require("./variationV2");
var FeatureFlagV2 = /** @class */function () {
  function FeatureFlagV2(featureFlag) {
    var _this = this;
    this.variations = {};
    this.rules = [];
    this.getVariationByKey = function (key) {
      if (key) {
        return _this.variations[key];
      } else {
        return null;
      }
    };
    this.id = featureFlag.id;
    this.featureKey = featureFlag.featureKey;
    this.defaultVariationKey = featureFlag.defaultVariationKey;
    for (var _i = 0, _a = featureFlag.variations; _i < _a.length; _i++) {
      var variation = _a[_i];
      this.variations[variation.key] = new variationV2_1.VariationV2(variation);
    }
    for (var _b = 0, _c = featureFlag.rules; _b < _c.length; _b++) {
      var rule = _c[_b];
      this.rules.push(new rule_1.Rule(rule));
    }
  }
  return FeatureFlagV2;
}();
exports.FeatureFlagV2 = FeatureFlagV2;