<div class="bdo-container re-mb-30
            md:re-mb-60">
  <h3>{{ 'billing.address.title' | translate }}</h3>
</div>

<div class="bdo-container--single re-mb-30 md:re-mb-45 md:re-text-base re-leading-2xl">
  {{ 'billing.description' | translate }}
</div>

<form
  [formGroup]="form"
  (ngSubmit)="save()"
  (keydown.enter)="$event.preventDefault()"
  bdoScrollToInvalidControl
  [bdoScrollToInvalidControlForm]="form">
  <div class="re-bg-white re-w-full re-p-20 re-pt-30 re-pb-45 re-mb-45 re-text
              md:re-w-600 md:re-mx-auto md:re-pt-45 md:re-pb-60 md:re-mb-60
              lg:re-w-900">
    <!-- Rechnungsempfänger -->
    <div class="re-mb-30 md:re-mb-20 re-form-item-container">
      <div class="bold re-text-sm re-leading-m re-mb-20
        md:re-text-base md:re-leading-2xl md:re-mb-30">
        {{ 'billing.address.recipient.title' | translate }}
    </div>

      <div class="re-flex re-items-start re-relative re-mb-20">
        <input id="current-recipient" type="radio" value="current-recipient" formControlName="recipient">
        <label class="re-ml-15" for="current-recipient">
          {{ 'billing.address.recipient.recent' | translate }}
          <div *ngIf="form.get('recipient').value === 'current-recipient'" class="re-mt-10 bold">
            <span *ngIf="userData?.person1?.firstname">{{ userData?.person1?.firstname}} {{ userData?.person1?.lastname}}</span>
            <span *ngIf="userData?.person2?.firstname">und {{ userData?.person2?.firstname}} {{ userData?.person2?.lastname}}</span>
            <span *ngIf="userData?.org?.nameOrg1">{{ userData?.org?.nameOrg1}} {{ userData?.org?.nameOrg2}}</span>
          </div>
        </label>
      </div>
      <div class="re-flex re-items-start re-relative" [ngClass]="{'re-mt-30 md:re-mt-45': form.get('recipient').value === 'current-recipient'}">
        <input id="new-recipient" type="radio" value="new-recipient" formControlName="recipient">
        <label class="re-ml-15" for="new-recipient">
          {{ 'billing.address.recipient.new' | translate }}
        </label>
      </div>
    </div>

    <ng-container *ngIf="form.get('namegroup')">
      <div class="re-form-item-container">
        <bdo-dropdown
          [data]="titleDropdown"
          [required]="true"
          [selected]="(form.get('namegroup').get('title').valueChanges | async)"
          [forceValidate]="forceValidate"
          [requiredErrorText]="'address.salutation.salutationRequired' | translate"
          [labelText]="'address.salutation.title' | translate"
          (itemSelected)="form.get('namegroup')?.get('title')?.setValue($event)"
        ></bdo-dropdown>
      </div>

      <div class="re-form-item-container" data-qa="name">
        <bdo-input [formControl]="$any(form.get('namegroup').get('name'))"
                  [labelText]="'address.personalInfo.firstname' | translate"
                  autocomplete="given-name"
        ></bdo-input>
      </div>

      <div class="re-form-item-container" data-qa="surname">
        <bdo-input [formControl]="$any(form.get('namegroup').get('surname'))"
                  [maxlength]="35"
                  [labelText]="'address.personalInfo.surname' | translate"
                  autocomplete="family-name"
        ></bdo-input>
      </div>
  </ng-container>

    <!-- Rechnungsadresse -->
    <div class="re-container__content re-mt-45 re-mb-20
                md:re-mt-60">
      <span class="bold re-text-sm re-leading-m
                  md:re-text-base md:re-leading-2xl">
        {{ 'billing.address.subtitle' | translate }}
      </span>
    </div>
    <fieldset class="re-mb-0 md:re-mt-45">
      <bdo-address-form [parentForm]="form"></bdo-address-form>
    </fieldset>
    <div class="re-form-item-container">
      <bdo-input #emailInput
                 [formControl]="$any(form.get('email'))"
                 [inputType]="INPUT_TYPE.EMAIL"
                 [labelText]="'address.email.title' | translate">
      </bdo-input>
    </div>
  </div>
  <div class="bdo-container--single re-mt-45 md:re-mt-60">
    <bdo-validate-submit
      [textButtonLong]="'wizard.button.summary' | translate"
    ></bdo-validate-submit>
    <div class="re-mt-20">
      <bdo-text-icon
       [iconPosition]="'left'"
       [text]="'general.action.back' | translate"
       (itemClicked)="location.back()"
      ></bdo-text-icon>
    </div>
  </div>
</form>
