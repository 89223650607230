import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TrackingService } from '../../../bdo/services/tracking.service';
import { TRACKING } from '../../../bdo/enums/trackingParts.enum';

export interface HintTileConfig {
  borderTopColor: 'red' | 'green' | '';
  iconPath: string;
  iconWithBadge: boolean;
  text: string;
  linkText: string;
  linkType: 'primaryButton' | 'secondaryButton' | 'texticon' | '';
  linkPath: string;
  isDashboardTile: boolean;
  title?: string;
  trackingLocation?: string;
  trackingDestination?: string;
}

@Component({
  selector: 'bdo-hint-tile',
  templateUrl: './hint-tile.component.html',
  styleUrls: ['./hint-tile.component.scss']
})
export class HintTileComponent {

  @Input() currentHintTileConfig: HintTileConfig;
  private TRACKING = TRACKING;

  constructor(
    private router: Router,
    private trackingService: TrackingService
  ) { }

  public toTarget() {
    if (this.currentHintTileConfig.trackingLocation && this.currentHintTileConfig.trackingDestination) {
      this.trackingService.postSimpleTracking(this.currentHintTileConfig.trackingLocation, TRACKING.ACTION.GOTO, this.currentHintTileConfig.trackingDestination);
    }
    this.router.navigate([this.currentHintTileConfig.linkPath]);
  }
}
