<h4 class="re-container-with-margin re-mb-20 md:re-mb-30">
  {{ 'personaldata.personal.title' | translate }}
</h4>

<bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">
  {{ 'general.error.error' | translate }}
</bdo-info-box>

<div class="re-bg-white re-w-full re-px-30 re-py-20 re-text re-mb-3
            md:re-w-600 md:re-mx-auto
            lg:re-w-900">
  <div class="re-inline">{{ 'general.customerData.account' | translate }}</div>
  <div class="re-inline bold">{{accountId}}</div>
</div>

<form (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()" [formGroup]="form" bdoScrollToInvalidControl>
  <bdo-edit-card
    [isEditable]="state !== LoadingState.LOADING && !isInEditMode"
    [disabled]="disabled"
    (edit)="changeEditMode()">

    <div class="re-relative">
      <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>

    <!-- show fields independently -->
    <div [ngClass]="{'re-summary-row': !isInEditMode, 're-form-item-container-row' : isInEditMode}">
      <div>{{ 'address.salutation.title' | translate }}</div>
      <div data-qa="titleValue">{{personalData?.titleValue}}</div>
    </div>

    <div [ngClass]="{'re-mb-45': personalData?.person2?.firstname || isGewe}">
      <!-- Partnerkind: PRIV -->
      <div *ngIf="!isGewe">
        <!-- Person 1 -->
        <div [ngClass]="{'re-summary-row': !isInEditMode, 're-form-item-container-row' : isInEditMode}">
          <div>{{ 'address.personalInfo.name' | translate }}</div>
          <div data-qa="person1-name">{{personalData?.person1?.firstname}} {{personalData?.person1?.lastname}}</div>
        </div>
        <div *ngIf="personalData?.person1?.birthdate">
          <div [ngClass]="{'re-summary-row': !isInEditMode, 're-form-item-container-row' : isInEditMode}">
            <div>{{ 'address.personalInfo.birthdate.title' | translate }}</div>
            <div data-qa="person1-dateOfBirth">{{personalData.person1.birthdate | date}}</div>
          </div>
        </div>

        <!-- Person 2 -->
        <div *ngIf="personalData?.person2?.firstname">
          <div [ngClass]="{'re-summary-row': !isInEditMode, 're-form-item-container-row' : isInEditMode}">
            <div>{{ 'address.personalInfo.name' | translate }}</div>
            <div data-qa="person2-name">{{personalData.person2.firstname}} {{personalData?.person2?.lastname}}</div>
          </div>
          <div *ngIf="personalData?.person2?.birthdate">
            <div [ngClass]="{'re-summary-row': !isInEditMode, 're-form-item-container-row' : isInEditMode}">
              <div>{{ 'address.personalInfo.birthdate.title' | translate }}</div>
              <div data-qa="person2-dateOfBirth">{{personalData.person2.birthdate | date}}</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Partnerkind: GEWE -->
      <div *ngIf="isGewe">
        <div [ngClass]="{'re-summary-row': !isInEditMode, 're-form-item-container-row' : isInEditMode}">
          <div>{{ 'address.personalInfo.name1' | translate }}</div>
          <div data-qa="org-name1">{{personalData?.org?.nameOrg1}}</div>
        </div>
        <div [ngClass]="{'re-summary-row': !isInEditMode, 're-form-item-container-row' : isInEditMode}">
          <div>{{ 'address.personalInfo.name2' | translate }}</div>
          <div data-qa="org-name2">{{personalData?.org?.nameOrg2}}</div>
        </div>
        <div [ngClass]="{'re-summary-row': !isInEditMode, 're-form-item-container-row' : isInEditMode}">
          <div>{{ 'address.business.sector.title' | translate }}</div>
          <div data-qa="org-sectorId">{{sectorDisplayText}}</div>
        </div>
        <div [ngClass]="{'re-summary-row': !isInEditMode, 're-form-item-container-row' : isInEditMode}">
          <div>{{ 'address.business.legalEntity.title' | translate}}</div>
          <div data-qa="org-legalEntityId">{{legalDisplayText}}</div>
        </div>
      </div>

    </div>

    <!-- show changeable fields statically  -->
    <div *ngIf="!isInEditMode">

      <div class="re-summary-row">
        <div>{{ 'address.phonenumber.title' | translate }}</div>
        <div data-qa="phonenumber">{{showPhonenumber}}</div>
      </div>

      <div *ngIf="personalData?.mobilenumber && personalData?.phonenumber">
        <div class="re-summary-row">
          <div>{{ 'address.phonenumber.mobileNumber' | translate }}</div>
          <div data-qa="mobilenumber">{{personalData.mobilenumber.number}}</div>
        </div>
      </div>

      <div class="re-summary-row re-mb-0">
        <div>{{ 'address.email.title' | translate }}</div>
        <div data-qa="email" [innerText]="personalData?.email?.smtpAddress ? personalData.email.smtpAddress : '-'"></div>
      </div>

      <bdo-info-box *ngIf="state !== LoadingState.LOADING && tmpEmail"
        class="re-block re-mt-25"
        [type]="'info'">
        <div [innerHTML]="'address.emailVerification.hint' | translate : {email:tmpEmail}"></div>
        <button class="bdo-nolink re-mt-15"
          type="button"
          (click)="resendVerification()">
          <div class="bold">{{'address.emailVerification.hintLink' | translate}}</div>
        </button>
      </bdo-info-box>

    </div>

    <!-- show changeable fields with input fields -->
    <div *ngIf="isInEditMode">

      <fieldset class="re-mb-0">
        <div *ngIf="form.get('phonenumber')">
          <div class="re-form-item-container" data-qa="input-phonenumber">
            <bdo-input
              [id]="'phonenumber'"
              [formControl]="$any(form.get('phonenumber'))"
              [placeholder]="'address.phonenumber.title' | translate"
            ></bdo-input>
          </div>
        </div>

        <div *ngIf="form.get('mobilenumber')">
          <div class="re-form-item-container" data-qa="input-mobilenumber">
            <bdo-input
              [id]="'mobilenumber'"
              [formControl]="$any(form.get('mobilenumber'))"
              [placeholder]="(form.get('phonenumber') ? 'address.phonenumber.mobileNumber' : 'address.phonenumber.title') | translate"
            ></bdo-input>
          </div>
        </div>

        <div *ngIf="form.get('email')">
          <div class="re-form-item-container" data-qa="input-email">
            <bdo-input
              [id]="'email'"
              [formControl]="$any(form.get('email'))"
              [placeholder]="'address.email.title' | translate"
            ></bdo-input>
          </div>
        </div>

      </fieldset>

      <div class="re-form-item-container bdo-space--bottom re-mt-25 re-mb-15">
        <button class="bdo-button-primary bdo-button--text" data-qa="submit" type="submit">
          {{ 'general.action.save' | translate }}
        </button>
      </div>

      <div class="re-form-item-container re-mb-0">
        <button class="bdo-nolink" (click)="changeEditMode()">
          <div class="bold">{{ 'general.action.cancel' | translate }}</div>
        </button>
      </div>
    </div>
    </div>
  </bdo-edit-card>
</form>
