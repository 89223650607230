<div class="re-container-headline bdo-space--bottom">
  <h3>
    {{ 'summary.title' | translate }}
  </h3>
</div>
<div class="re-container-with-margin">
  <div class="re-copytext-with-margin">
    {{ 'summary.description' | translate }}
  </div>
</div>

<!-- shown on all backend errors -->
<div class="bdo-container--single">
  <div [class.re-mb-15]="state === LoadingState.ERROR">
    <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">
      {{ errorMessage }}
    </bdo-info-box>
  </div>
</div>


<div class="bdo-loading__wrapper" [class.bdo-loading__wrapper--height]="state === LoadingState.LOADING">
  <div class="re-px-20 re-py-30 re-w-full re-mb-1 re-bg-white re-relative
              md:re-px-30 md:re-py-45 md:re-w-600 md:re-mx-auto md:re-mb-0
              lg:re-w-900" *ngIf="state === LoadingState.LOADING">
    <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
  </div>
  <div *ngIf="state !== LoadingState.LOADING">

    <h4 class="re-container-with-margin re-mb-20 re-ml-20
               md:re-mb-30 md:re-ml-auto">
      {{ 'summary.tariff.chosenTariff' | translate }}
    </h4>
    <div class="re-w-full re-mb-1 re-bg-white re-relative
                md:re-w-600 md:re-mx-auto md:re-mb-0
                lg:re-w-900">
      <bdo-tariff-summary (tariffDataChange)="onTariffDataChange($event)"></bdo-tariff-summary>
    </div>

    <!-- Voucher Offer -->
    <ng-container *ngIf="hasVouchers">
      <h4 class="re-container-with-margin re-mb-20 re-mt-60 re-ml-20 md:re-mb-30 md:re-ml-auto">
        {{ 'summary.voucher.title' | translate }}
      </h4>
      <div class="re-w-full md:re-w-600 lg:re-w-900 md:re-mx-auto">
        <bdo-wishvoucher></bdo-wishvoucher>
      </div>
    </ng-container>

    <h4 class="re-container-with-margin re-mb-20 re-mt-60 re-ml-20 md:re-mb-30 md:re-ml-auto">
      {{ 'summary.personalData.title' | translate }}
    </h4>
    <bdo-edit-card
      data-qa="summary-card-personal-data"
      (edit)="onEdit(1, 'persoenliche-daten')">
      <bdo-personal-data-summary></bdo-personal-data-summary>
    </bdo-edit-card>

    <h4 class="re-container-with-margin re-mb-20 re-mt-60 re-ml-20 md:re-mb-30 md:re-ml-auto">
      {{ 'summary.address.title' | translate }}
    </h4>
    <bdo-edit-card
      data-qa="personal-data-address"
      [isEditable]="!(personalData?.addressData?.city && personalData?.addressData?.street && personalData?.addressData?.housenumber)"
      (edit)="onEdit(1, 'address')">
      <bdo-address-summary [addressData]="addressData"></bdo-address-summary>
    </bdo-edit-card>

    <h4 class="re-container-with-margin re-mb-20 md:re-mb-30 re-mt-60 re-ml-20 md:re-ml-auto">
      {{ 'summary.supply.title' | translate }}
    </h4>
    <bdo-edit-card
      data-qa="summary-card-situation-data"
      (edit)="onEdit(2, 'situation')">
      <bdo-situation-summary></bdo-situation-summary>
    </bdo-edit-card>

    <h4 class="re-container-with-margin re-mb-20 re-mt-60 md:re-mb-30 re-ml-20 md:re-ml-auto">
      {{ 'summary.payment.title' | translate }}
    </h4>
    <bdo-edit-card
      (edit)="onEdit(3, 'zahlungsart')">
      <bdo-payments-summary></bdo-payments-summary>
    </bdo-edit-card>

    <div class="re-container-with-margin re-mt-60 re-ml-20 md:re-ml-auto">
      <h4 class="re-mb-30">{{ 'summary.legal.title' | translate }}</h4>
      <bdo-info-box *ngIf="translateService.instant('summary.legal.hint') !== ''" [type]="'info'" [marginBottom]="false">
        {{ 'summary.legal.hint' | translate }}
      </bdo-info-box>
    </div>
    <bdo-edit-card [isEditable]="false">
      <bdo-legal-summary
        [isGewe]="isGewe"
        [isHeatpump]="isHeatpump"
        [pathAndDivisions]="pathAndDivisions"
        [rightOfWithdrawalAccepted]="rightOfWithdrawalAccepted"
        [termsOfConditionsAccepted]="termsOfConditionsAccepted"
        [communicationAcceptedMode]="'always'"
        [validateCheckboxes]="validateCheckboxes"
        (tosChange)="onChangeTermsOfConditions($event)"
        (rightOfWithdrawalChange)="onChangeRightOfWithdrawalAccepted($event)"
        (communicationAcceptedChange)="onChangeCommunicationAccepted($event)"
      ></bdo-legal-summary>
    </bdo-edit-card>

    <div class="re-w-full md:re-w-600 lg:re-w-900 md:re-mx-auto re-mt-30 md:re-mt-45">
      <div class="md:re-mx-0 re-mx-20">
        <bdo-validate-submit
          (click)="submit()"
          [textButtonLong]="'summary.submitWithPay' | translate"
        ></bdo-validate-submit>
        <div class="re-pt-15">
          <bdo-text-icon
            [iconPosition]="'left'"
            [text]="'general.action.back' | translate"
            (itemClicked)="location.back()"
          >
          </bdo-text-icon>
        </div>
      </div>
    </div>
  </div>
</div>

