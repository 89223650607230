/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PollQuestion { 
    questionNo?: number;
    /**
     * One of: 1-10_SCALE, BOOLEAN, 1-5_SCALE, FREE_TEXT
     */
    questionType?: string;
    questionText?: string;
    /**
     * Optional description of a scale\'s lowest value, might be null when not set
     */
    scaleLow?: string;
    /**
     * Optional description of a scale\'s lowest value, might be null when not set
     */
    scaleHigh?: string;
}

