/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * DivisionId in Combination with short tenantname like BK(Belkaw), RE(Rheinenergie)
 */
export interface DivisionDetail { 
    /**
     * division of product e.g. S4 for power, G6 for gas or T4 for water
     */
    divisionId?: string;
    tenantNameShort?: string;
}

