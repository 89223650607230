<div [ngClass]="inContainer ? 'bdo-grid__without-margin' : 'bdo-grid'"
  class="re-mt-30 md:re-mt-45 re-auto-rows-55 md:re-auto-rows-100">
  <ng-container *ngFor="let promoLinkData of promoLinkDataSet">
    <bdo-promo-link
      [ngClass]="inContainer ? 'grid-columns__in-container' : 'grid-columns'"
      [href]="promoLinkData?.href"
      [routerUrl]="promoLinkData?.routerUrl"
      [linkText]="promoLinkData?.linkText"
      [openInNewTab]="promoLinkData?.openInNewTab"
    ></bdo-promo-link>
  </ng-container>
</div>
