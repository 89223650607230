<div class="re-container-headline bdo-space--bottom">
	<h3>
		{{ 'delivery.denial.title' | translate }}
  </h3>
</div>

<div class="re-container-with-margin re-copytext-with-margin">
  <div [innerHTML]="'delivery.denial.description' | translate"></div>
  <br>
  <div class="bold" [innerHTML]="'delivery.denial.schufaAddress' | translate"></div>
  <br>
  <div class="re-mb-90">{{ 'delivery.denial.questions' | translate }}</div>


  <a class="re-button re-primary" [href]="tenantService.getCurrentTenantData().hrefHomepage">
    {{ 'general.navigation.backToHomepage' | translate }}
  </a>
</div>
