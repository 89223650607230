<div class="re-container-headline bdo-space--bottom">
  <h3>
    {{ 'summary.title' | translate }}
  </h3>
</div>
<div class="re-container-with-margin">
  <div class="re-copytext-with-margin">
    {{ 'summary.description' | translate }}
  </div>
</div>

<!-- shown on all backend errors -->
<div class="bdo-container--single">
  <div [class.re-mb-15]="state === LoadingState.ERROR">
    <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR">{{ errorMessage }}</bdo-info-box>
  </div>
</div>

<div class="bdo-loading__wrapper" [class.bdo-loading__wrapper--height]="state === LoadingState.LOADING">
  <div class="re-px-20 re-py-30 re-w-full re-mb-1 re-bg-white re-relative
              md:re-px-30 md:re-py-45 md:re-w-600 md:re-mx-auto md:re-mb-0
              lg:re-w-900" *ngIf="state === LoadingState.LOADING">
    <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
  </div>
  <div *ngIf="state !== LoadingState.LOADING">

    <h4 class="re-container-with-margin re-mb-20 re-ml-20
               md:re-mb-30 md:re-ml-auto">
      {{ 'summary.address.recentHome' | translate }}
    </h4>
    <bdo-edit-card
      (edit)="onEdit(1, 'bisheriges-zuhause')">
      <bdo-old-home-summary></bdo-old-home-summary>
    </bdo-edit-card>

    <h4 class="re-container-with-margin re-mb-20 md:re-mb-30 re-mt-60 re-ml-20 md:re-ml-auto">
      {{ 'summary.address.newHome' | translate }}
    </h4>
    <bdo-edit-card
      (edit)="onEdit(2, 'neues-zuhause')">
      <bdo-new-home-summary></bdo-new-home-summary>
    </bdo-edit-card>

    <ng-container *ngIf="unselectedDivisionIds?.length || unselectableDivisionIds?.length">
      <h4 class="re-container-with-margin re-mb-20 md:re-mb-30 re-mt-60 re-ml-20 md:re-ml-auto">
        {{ 'summary.tariff.overview' | translate }}
      </h4>
      <bdo-edit-card [isEditable]="false">
        <bdo-tariffs-overview [canceledDivisionIds]="unselectedDivisionIds" [impossibleDivisionIds]="unselectableDivisionIds"></bdo-tariffs-overview>
      </bdo-edit-card>
    </ng-container>

    <h4 *ngIf="selectedTariffs?.length" class="re-container-with-margin re-mb-20 re-mt-60 md:re-mb-30 re-ml-20 md:re-ml-auto">
      <span *ngIf="selectedTariffs.length === 1">{{ 'summary.tariff.chosenTariff' | translate }}</span>
      <span *ngIf="selectedTariffs.length > 1">{{ 'summary.tariff.chosenTariffs' | translate }}</span>
    </h4>
    <div *ngFor="let tariff of selectedTariffs; let i = index" class="re-mb-30">
      <h5 class="re-container-with-margin re-mb-15 re-mt-30 md:re-mt-40">
        {{ 'tariff.specificTariff' | translate: { division: tariff.selectedTariff.division | verbrauchstyp } }}
      </h5>
      <bdo-edit-card
        [isEditable]="true"
        [showFrame]="false"
        (edit)="onEdit(3, 'tarifauswahl/' + (i+1))">
        <bdo-tariff-data-summary
          [selectedDivision]="tariff"
        ></bdo-tariff-data-summary>
      </bdo-edit-card>
    </div>

    <div *ngIf="selectedTariffs?.length > 1">
      <div class="re-px-20 re-py-20 re-w-full re-bg-white re-relative re-mt-45
                  md:re-px-30 md:re-py-30 md:re-w-600 md:re-mx-auto md:re-mb-0
                  lg:re-w-900">
        <bdo-tariff-total-summary></bdo-tariff-total-summary>
      </div>
    </div>

    <h4 class="re-container-with-margin re-mb-20 re-mt-60 md:re-mb-30 re-ml-20 md:re-ml-auto">
      {{ (selectedTariffs?.length > 0 ?
         'summary.payment.title' : 'summary.payment.last') | translate }}
    </h4>
    <bdo-edit-card
      (edit)="onEdit(4, 'zahlungsart')">
      <bdo-payments-summary [noFutureContract]="availableDivisionInfos?.allUnavailableOrExternal"></bdo-payments-summary>
    </bdo-edit-card>

    <div class="re-container-with-margin re-mt-60 re-ml-20 md:re-ml-auto">
      <h4 class="re-mb-30">{{ 'summary.legal.title' | translate }}</h4>
      <bdo-info-box *ngIf="translateService.instant('summary.legal.hint') !== ''" [type]="'info'" [marginBottom]="false">
        {{ 'summary.legal.hint' | translate }}
      </bdo-info-box>
    </div>
    <bdo-edit-card [isEditable]="false">
      <bdo-legal-summary
        [isGewe]="isGewe"
        [validateCheckboxes]="validateCheckboxes"
        [pathAndDivisions]="pathAndDivisions"
        [canceledDivisions]="unselectedDivisionIds"
        [communicationAcceptedMode]="'check'"
        (tosChange)="onChangeTermsOfConditions($event)"
        (rightOfWithdrawalChange)="onChangeRightOfWithdrawalAccepted($event)"
        (communicationAcceptedChange)="onChangeCommunicationAccepted($event)"
      ></bdo-legal-summary>
    </bdo-edit-card>

    <div class="re-w-full md:re-w-600 lg:re-w-900 md:re-mx-auto re-mt-30 md:re-mt-45">
      <div class="md:re-pl-0 re-pl-20">
        <bdo-validate-submit
          (click)="submit()"
          [textButtonLong]="(availableDivisionInfos?.allUnavailableOrExternal ?
                               'summary.submitCancel' : 'summary.submitWithPay') | translate"
        ></bdo-validate-submit>
        <div class="re-pt-15">
          <bdo-text-icon
            [iconPosition]="'left'"
            [text]="'general.action.back' | translate"
            (itemClicked)="location.back()"
          >
          </bdo-text-icon>
        </div>
      </div>
    </div>


  </div>
</div>
