/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Success Response for ContractAdd
 */
export interface ContractAddResponse { 
    /**
     * sha-1 secret to generate a form for the customer
     */
    trustpilotSecureToken?: string;
    status?: string;
    info?: string;
}

