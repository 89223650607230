<bdo-dashboard-tile>
  <div class="re-h-full re-flex re-justify-between re-flex-col re-min-h-250">
    <div>
      <div class="element__space-bottom">
        <bdo-dropdown
          [data]="accountsDropdown"
          [selected]="preSelectedCustomerNumber"
          [labelText]="('general.customerData.account' | translate) + ':'"
          (itemSelected)="updateKundennummer($event)">
        </bdo-dropdown>
      </div>

      <table class="re-mb-45">
        <tr>
          <td class="table--top">{{ 'general.tariff.deliveryAddress' | translate }}:</td>
          <td class="bold">{{account?.address.street.name}} {{account?.address.houseNum}}, {{account?.address.street.city?.postCode}} {{account?.address.street.city?.name}}</td>
        </tr>
        <tr>
          <td *ngIf="account?.divisions.length > 0">{{ 'general.customerData.supply' | translate }}:</td>
          <td class="bold">{{ getDivisionsAsStrings(account?.divisions) | verbrauchstyp | delimiter : ' | '}}</td>
        </tr>
      </table>
    </div>
    <!-- ctas -->
    <div class="re-pt-20 md:pt-30">
      <div class="re-mb-15" *ngIf="!isRestricted">
        <bdo-text-link-icon
          [iconPosition]="'right'"
          [linkText]="'dashboard.link.contractOverview' | translate"
          [routerUrl]="'/vertrag/' + this.kundenStore.getAccountId()"
          [tracking]="[TRACKING.LOCATION.DASHBOARD, TRACKING.ACTION.GOTO, TRACKING.LOCATION.CONTRACT_OVERVIEW]"
        >
        </bdo-text-link-icon>
      </div>
        <bdo-text-link-icon
          [iconPosition]="'right'"
          [linkText]="'dashboard.link.personalData' | translate"
          [routerUrl]="'/daten/' + this.kundenStore.getAccountId()"
          [tracking]="[TRACKING.LOCATION.DASHBOARD, TRACKING.ACTION.GOTO, TRACKING.LOCATION.PERSONAL_DATA]"
        >
        </bdo-text-link-icon>
    </div>
  </div>


</bdo-dashboard-tile>
