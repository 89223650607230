<bdo-simple-text-popup [isVisible]="showMessage"
                       [isManuallyClosable]="true"
                       [title]="readonlyHasStarted ?
                                ('removedOs.titleOnStartOfReadOnly' | translate) :
                                ('removedOs.title' | translate)"
                       [icon]="'warning'"
                       (closePopupEvent)="onClose()">
  <div class="re-copytext">
    <p [innerHtml]="readonlyHasStarted ?
    ('removedOs.descriptionOnStartOfReadOnly' | translate) :
    ('removedOs.description' | translate)"
    ></p>
  </div>
</bdo-simple-text-popup>
