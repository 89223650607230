/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BankAccount { 
    bankname?: string;
    accountholder?: string;
    /**
     * Cut off to only contain the last 4 digits and stars in front, e.g. ***1234
     */
    iban: string;
    id?: string;
}

