<div class="re-bg-white re-w-full re-pt-45 re-pb-20 re-text re-px-20
              md:re-w-600 md:re-mx-auto md:re-px-30
              lg:re-w-900 lg:re-pt-30">

  <div class="re-flex re-flex-col lg:re-flex-row re-text-center lg:re-text-left" data-qa="single-contract-details">
    <!-- left column: tariff icon and name -->
    <div
      class="re-mb-5 re-mx-auto md:re-mb-10 lg:re-mb-0 lg:re-w-350 lg:re-mr-30 lg:re-mx-0 lg:re-max-w-sm lg:re-min-w-mobile lg:re-flex lg:re-flex-col re-justify-end"
      [class.lg:re-w-full]="mode !== ContractDetailsTileMode.CANCEL_CONTRACT && !(contract?.billingAmount || contract?.contractStartDate > today)">
      <div class="re-w-60 re-h-60 re-mx-auto md:re-w-90 md:re-h-90 re-mb-10 md:re-mb-15">
        <svg-icon [src]="tariffAdvisorService.getPathForIcon(contract?.division)"></svg-icon>
      </div>
      <h2 class="re-mb-0 lg:re-text-center lg:re-text-base lg:re-leading-9">{{ contract?.tariffName }}</h2>
    </div>
    <!-- right column: contract details -->
    <div class="re-flex re-flex-col re-gap-y-20 re-mt-10 lg:re-mt-0 re-justify-end"
         *ngIf="contract?.billingAmount || contract?.contractStartDate < today">

      <div *ngIf="contract?.contractStartDate > today"
           class="re-text-success re-inline-block">{{ 'contract.details.contractActive' | translate }} {{contract?.contractStartDate | date}}</div>
      <div
        *ngIf="contract?.contractEndDate?.toISOString() !== MAXCONTRACTDATE"
        class="re-inline-block"
        [innerHTML]="'contract.details.contractInactive' | translate: { endDate: contract?.contractEndDate | date }">
      </div>

      <div>
        <!-- change tariff -->
        <div *ngIf="mode === ContractDetailsTileMode.CHANGE_CONTRACT">
          <ng-container *ngIf="contract?.isChangeable && contract?.contractId else contractNotChangeableHint">
            <ng-container *ngTemplateOutlet="changeTariffButton"></ng-container>
          </ng-container>
          <ng-template #contractNotChangeableHint>
            <div
              *ngIf="mode === ContractDetailsTileMode.CHANGE_CONTRACT && (!contract?.isChangeable || !contract?.contractId)"
              class="re-text-center lg:re-text-left"
              [attr.data-qa]="'hint-changeNotAvailable-' + contract?.contractId">
              <span *ngIf="!isNsh1Nsh2Tariff && contract?.division !== Verbrauchstyp.Wasser">{{ 'contract.details.notChangeable' | translate }}</span>
              <span *ngIf="isNsh1Nsh2Tariff">{{ 'contract.details.notChangeableNsh1Nsh2' | translate }}</span>
              <span *ngIf="contract?.division === Verbrauchstyp.Wasser">{{ 'contract.details.notChangeableWater' | translate }}</span>
            </div>
          </ng-template>
        </div>

        <ng-container *ngIf="mode === ContractDetailsTileMode.CANCEL_CONTRACT">
          <a *ngIf="!getIsBaseTariff(contract?.tariffName); else baseTariff"
             class="re-button-small re-primary re-text-center"
             [attr.data-qa]="'button_cancelTariff-' + contract?.contractId"
             (click)="tariffCancel.emit(contract)">
            {{ 'contract.cancel.linkTo' | translate }}
          </a>
          <div class="re-opacity-80 re-text-xs re-leading-s re-text-left"
               [attr.data-qa]="'baseSupply-hint-' + contract?.contractId" *ngIf="isBaseSupply">
            {{ (getIsBaseTariff(contract?.tariffName) ? 'contract.details.notChangeableBasicSupply' :
            'contract.details.hintChangeToBasicSupply') | translate }}
          </div>
          <ng-template #baseTariff>
            <ng-container *ngTemplateOutlet="changeTariffButton"></ng-container>
          </ng-template>
        </ng-container>

        <ng-template #changeTariffButton>
          <div class="flex flex-col">
            <div class="re-mb-20">{{ 'contract.details.tariffChangeable' | translate }}</div>
            <a
              *ngIf="showTariffChangeButton"
              class="re-button-small re-primary re-text-center"
              [attr.data-qa]="'button_changeTariff-' + contract?.contractId"
              [routerLink]="'../' + accountId + '/anpassen/tarifauswahl'"
              (click)="tariffChange.emit(contract)">
              {{ 'contract.edit.linkTo' | translate }}
            </a>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <hr class="re-mt-30 re-mb-20 re-border-t re-border-monochrome-medium-light">
  <bdo-single-accordion [shortInfo]="'contract.details.info.expand' | translate"
                        [arrowRight]="true"
                        [attr.data-qa]="'tariffdetails'"
                        [position]="'right'">
    <bdo-contract-details-tariff-info
      [accountId]="accountId"
      [customerMode]="customerMode"
      [contract]="contract"
      [showPriceCap]="showPriceCap"
    ></bdo-contract-details-tariff-info>
  </bdo-single-accordion>
</div>
