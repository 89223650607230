<button class="re-group" (click)="onClick()" type="button" [tabindex]="isPart ? -1 : 0">
  <div *ngIf="iconPosition === 'left'"
    class="re-mr-15 re-w-25 re-h-25 re-text-primary re-inline-block re-animate-translate group-hover:-re-translate-x-5 group-focus:-re-translate-x-5"
    [ngClass]="noColor ? 're-text-primary-hover' : 're-text-primary'">
    <svg-icon [src]="'assets/icons/arrow-left.svg'"></svg-icon>
  </div>
  <span class="bold" [ngClass]="{'group-focus:re-text-primary group-hover:re-text-primary' : !noColor}">{{text}}</span>
  <div *ngIf="iconPosition === 'right'"
    class="re-ml-15 re-w-25 re-h-25 re-inline-block re-animate-translate group-hover:re-translate-x-5 group-focus:re-translate-x-5"
    [ngClass]="noColor ? 're-text-primary-hover' : 're-text-primary'">
    <svg-icon class="custom-icon" [src]="'assets/icons/arrow-right.svg'"></svg-icon>
  </div>
</button>
