import { OnChanges, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { TenantService } from '../../../../services/tenant.service';
import { VERBRAUCHSTYP } from '../../../../enums/verbrauchstyp.enum';
import { VerbrauchstypPipe } from '../../../../pipes/verbrauchstyp.pipe';
import { StorageService } from '../../../../services/storage.service';
import { BdoApiService } from '../../../../services/bdo-api.service';
import { CustomerStoreService } from '../../../../services/customer-store.service';
import { DatadogService } from '../../../../services/datadog.service';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../../../login/login.service';
import { EMPTY, first, switchMap } from 'rxjs';
import { TariffTextItem } from '../../../../../../assets/js/com/ts_api_client';
import { TranslateService } from '@ngx-translate/core';

export interface PathAndDivision {
  division: string;
  path: string;
}

@Component({
  selector: 'bdo-legal-summary',
  templateUrl: './legal-summary.component.html',
  styleUrls: ['./legal-summary.component.scss']
})

export class LegalSummaryComponent implements OnChanges, OnInit {
  @Input() isGewe: boolean = false;
  @Input() isHeatpump: boolean = false;
  @Input() validateCheckboxes: boolean = false;
  @Input() termsOfConditionsAccepted: boolean = false;
  @Input() rightOfWithdrawalAccepted: boolean = false;
  @Input() communicationAcceptedMode: 'hidden' | 'check' | 'always' = 'hidden';
  @Input() pathAndDivisions: Array<PathAndDivision>;
  // if you have canceled Divisions, you have to set them in canceledDivisions and you have to set the process in endingProcess
  @Input() canceledDivisions: VERBRAUCHSTYP[] = [];
  @Input() endingProcess: 'contractEnd' | 'contractMove' | 'contractCancel' = 'contractMove';
  @Output() tosChange: EventEmitter<boolean> = new EventEmitter();
  @Output() rightOfWithdrawalChange: EventEmitter<boolean> = new EventEmitter();
  @Output() communicationAcceptedChange: EventEmitter<boolean> = new EventEmitter();

  public stringContractTerms: string = '';
  public communicationAccepted: boolean = null;
  public linkRevoke: string = '';
  public linkPrivacy: string = '';

  constructor(
    public tenantService: TenantService,
    private verbrauchstypPipe: VerbrauchstypPipe,
    private apiService: BdoApiService,
    private customerStore: CustomerStoreService,
    private dataDogService: DatadogService,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    const legalData = StorageService.getLegalData();
    if (legalData) {
      this.termsOfConditionsAccepted = legalData.termsOfConditionsAccepted;
      this.tosChange.emit(this.termsOfConditionsAccepted);
      this.rightOfWithdrawalAccepted = legalData.rightOfWithdrawalAccepted;
      this.rightOfWithdrawalChange.emit(this.rightOfWithdrawalAccepted);
      this.communicationAccepted = legalData?.communicationAccepted || null;
    }

    if (this.communicationAccepted === null) {
      switch (this.communicationAcceptedMode) {
        case 'check':
          this.loginService.isLoggedIn$.pipe(
            first(),
            switchMap((isLoggedIn) => { return isLoggedIn ? this.apiService.getOptin(this.customerStore.getAccountId()) : EMPTY; })
          ).subscribe({ next: res => {
            if (!res.optInFlag) {
              this.communicationAccepted = false;
              this.communicationAcceptedChange.emit(this.communicationAccepted);
            }
          } });
          break;
        case 'always':
          this.communicationAccepted = false;
          this.communicationAcceptedChange.emit(this.communicationAccepted);
          break;
        case 'hidden':
          this.communicationAccepted = null;
          break;
      }
    }

    this.apiService.getTariffText('revoke')
      .subscribe({
        next: (tariffTextItem: TariffTextItem) => {
          this.linkRevoke = tariffTextItem ? tariffTextItem.document : '';
        },
        error: () => {
          this.linkRevoke = '';
        }
    });

    this.apiService.getTariffText('dataprotection')
      .subscribe({
        next: (tariffTextItem: TariffTextItem) => {
          this.linkPrivacy = tariffTextItem ? tariffTextItem.document : '';
        },
        error: () => {
          this.linkPrivacy = '';
        }
    });

    if (!legalData) {
      this.dataDogService.trackCustomEvent('contract_move__summary__legalDataMissing');
    }

    if (this.isGewe) {
      this.onChangeRightOfWithdrawalAccepted(true);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.stringContractTerms = '';

    if (this.pathAndDivisions?.length > 0) {
      this.setContractTermsText();
    } else if (this.canceledDivisions?.length) {
      this.setEndingDivisionsText(false);
    }
  }

  setContractTermsText() {
    let index = 1;
    this.stringContractTerms = this.translateService.instant('legal.termsOfCondition.1');
    this.pathAndDivisions.forEach(singlePathAndDivision => {
      if (index === this.pathAndDivisions.length && this.pathAndDivisions.length > 1) {
        this.stringContractTerms = this.stringContractTerms + this.translateService.instant('legal.termsOfCondition.3') +
                                   this.translateService.instant('legal.termsOfCondition.2',
                                   { linkConditions: singlePathAndDivision.path, division: singlePathAndDivision.division });
      } else {
        this.stringContractTerms = this.stringContractTerms + this.translateService.instant('legal.termsOfCondition.2',
                                   { linkConditions: singlePathAndDivision.path, division: singlePathAndDivision.division });
        if (index + 2 <= this.pathAndDivisions.length) {
          this.stringContractTerms = this.stringContractTerms + ', ';
        }
      }

      if (index === this.pathAndDivisions.length) {
        this.stringContractTerms = this.stringContractTerms + this.translateService.instant('legal.termsOfCondition.4');
      }
      index++;
    });
    if (this.canceledDivisions?.length) {
      this.setEndingDivisionsText(true);
    }
  }

  onChangeTermsOfConditions() {
    this.termsOfConditionsAccepted = !this.termsOfConditionsAccepted;
    this.tosChange.emit(this.termsOfConditionsAccepted);
  }

  onChangeRightOfWithdrawalAccepted(staticAccepted: boolean = false) {
    this.rightOfWithdrawalAccepted = staticAccepted ? true : !this.rightOfWithdrawalAccepted;
    this.rightOfWithdrawalChange.emit(this.rightOfWithdrawalAccepted);
  }


  private setEndingDivisionsText(withLeadingLinebreak = false) {
    const uniqueCanceledDivisions = [...new Set(this.canceledDivisions)];
    let listOfDivisions: string = '';
    let surroundings: string = '';
    let index = 1;

    uniqueCanceledDivisions.forEach(singleDivision => {
      if (index === uniqueCanceledDivisions.length && uniqueCanceledDivisions.length > 1) {
        listOfDivisions += this.translateService.instant('legal.combineContracts') +
                           this.translateService.instant('contract.title', { division: this.verbrauchstypPipe.transform(singleDivision) });
      } else {
        listOfDivisions += this.translateService.instant('contract.title', { division: this.verbrauchstypPipe.transform(singleDivision) });
        if (index + 2 <= uniqueCanceledDivisions.length) {
          listOfDivisions += ', ';
        }
      }
      index++;
    });

    if (withLeadingLinebreak) {
      this.stringContractTerms += '<br />';
    }
    switch (this.endingProcess) {
      case 'contractCancel':
        surroundings = this.translateService.instant('legal.reason.cancel');
        break;
      case 'contractEnd':
        surroundings = this.translateService.instant('legal.reason.end');
        break;
      case 'contractMove':
        surroundings = this.translateService.instant('legal.reason.move');
        break;
      default:
        surroundings = this.translateService.instant('legal.reason.move');
        break;
    }
    if (this.pathAndDivisions?.length >= this.canceledDivisions?.length) {
      this.stringContractTerms += this.translateService.instant('legal.endingDivisions',
                                  { surroundings: surroundings, contracts: listOfDivisions });
    } else {
      this.stringContractTerms += this.translateService.instant('legal.endingAllDivisions',
                                  { surroundings: surroundings, contracts: listOfDivisions });
    }
  }

}
