import { Component, Output, EventEmitter, Input } from '@angular/core';
import { BdoApiService } from '../../../services/bdo-api.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { TrackingService } from '../../../services/tracking.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TENANT } from '../../../enums/tenant.enum';
import { Environment } from '../../../../../environments/environment';

@Component({
  selector: 'bdo-frequent-functions-tile',
  templateUrl: './frequent-functions-tile.component.html',
  styleUrls: ['./frequent-functions-tile.component.scss']
})
export class FrequentFunctionsTileComponent {
  @Input() isRegional: boolean = false;
  @Input() currentCustomerNumberIsInactive: boolean = false;
  @Input() moveAvailable: boolean = true;
  @Input() changeAvailable: boolean = true;
  @Input() accountId: string;
  @Output() redirectEvent: EventEmitter<string> = new EventEmitter();
  public TRACKING = TRACKING;
  public canAddTariff = !(Environment.tenant === TENANT.StadtwerkeLeichlingen);;

  constructor(
    public apiService: BdoApiService,
    public trackingService: TrackingService,
    public activatedRoute: ActivatedRoute,
    public router: Router

  ) { }

  goToMeterReading() {
    this.trackingService.postTracking(TRACKING.LOCATION.FREQUENT_FUNCTIONS, TRACKING.ACTION.GOTO, TRACKING.LOCATION.METERREADINGS);
    this.redirectEvent.emit('/zaehlerstand');
  }

  onGoToMove() {
    this.trackingService.postTracking(TRACKING.LOCATION.FREQUENT_FUNCTIONS, TRACKING.ACTION.GOTO, TRACKING.LOCATION.MOVE_NEWHOME);
    this.router.navigate([`../umzug/${this.accountId}/neu/bisheriges-zuhause`], {
      relativeTo: this.activatedRoute
    });
  }
}
