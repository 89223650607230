import { Component, Input } from '@angular/core';
import { FadeAnimation } from '../../animations/fade.animation';
import { SimpleTextPopupComponent } from '../simple-text-popup/simple-text-popup.component';

@Component({
  selector: 'bdo-large-popup',
  templateUrl: '../simple-text-popup/simple-text-popup.component.html',
  styleUrls: ['./large-popup.component.scss'],
  animations: FadeAnimation
})
export class LargePopupComponent extends SimpleTextPopupComponent {

  @Input() closeButtonModifier: string = '--white-large';


}
