import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { MetersCheckResult, SituationData } from '../../../assets/js/com/ts_api_client';
import { MeterDataFormStructure } from '../components/delivery/situation-form/situation-form.component';
import { VERBRAUCHSTYP } from '../enums/verbrauchstyp.enum';

@Injectable({
  providedIn: 'root'
})
export class MeterProductSelectionService {
  public meterData: {
    [key: string]: { // division
      [key: string]: MetersCheckResult
    }
  };
  public form = new UntypedFormGroup({});

  constructor() { }

  public initValues(meterData: any, form: UntypedFormGroup) {
    this.meterData = meterData;
    this.form = form;
  }

  public setMeterData(meterData) {
    this.meterData = meterData;
  }

  public mapMeterDataToSituationData() {
    const productSelectionControls = this.form.get('product-selection')?.['controls'];
    const divisionsSelected = this.form.get(['product-selection', VERBRAUCHSTYP.Strom, 'isChecked'])?.value ||
      this.form.get(['product-selection', VERBRAUCHSTYP.Gas, 'isChecked'])?.value;

    const formData: SituationData = {};
    Object.keys(productSelectionControls).filter(key => key !== 'meterData').forEach(key => {
      formData[key] = productSelectionControls[key].value;
    });

    // write values from reading-input fields into target structure for sessionStorage
    if (productSelectionControls[VERBRAUCHSTYP.Strom]?.get('isChecked').value) {
      this.writeReadingValuesIntoMeterData(VERBRAUCHSTYP.Strom);
    }
    if (productSelectionControls[VERBRAUCHSTYP.Gas]?.get('isChecked').value) {
      this.writeReadingValuesIntoMeterData(VERBRAUCHSTYP.Gas);
    }

    if (this.meterData) {
      // convert sparte key-value of meterdata key-value to meter array
      formData['meterData'] = Object.entries(this.meterData)
        // remove meterData, that is not checked
        .filter(([sparte, value]) => {
          return this.form.get('product-selection')?.get(sparte)?.get('isChecked')?.value;
        })
        .reduce((result, [sparte, value]) => {
          result.push(...Object.entries(value).map(([meternumber, meterdata]) => {
            let currentFormControl: AbstractControl; // Type FormFieldForFrontend
            try {
              currentFormControl = Object.values((this.form?.get('product-selection')?.get(sparte)?.get('meterData') as UntypedFormArray)?.controls).find(
                item => item?.get('meterNumber').value === meternumber
              );
            } catch {
              console.log(`
              meternumber: ${meternumber}
              sparte: ${sparte}
              ${this.form.get('product-selection')?.value}
              ${this.form.get('product-selection')?.get(sparte)}
              ${this.form.get('product-selection')?.get(sparte)?.get('meterData')}
              ${(this.form?.get('product-selection').get(sparte)?.get('meterData') as UntypedFormArray)?.controls}
              `);
            }
            const previousSupplier = currentFormControl.get('previousSupplier')?.value;
            const previousSupplierId = currentFormControl.get('previousSupplierId')?.value;
            const automaticCancelEnabled = currentFormControl.get('automaticCancelEnabled')?.value;
            const contractExisting = currentFormControl.get('contractExisting')?.value;
            const termination = contractExisting === undefined ? undefined : (
              contractExisting !== 'contractExists' ? 'none' : (
                automaticCancelEnabled ? 'auto' : 'self'
              )
            );

            return {
              ...meterdata,
              divisionId: sparte,
              termination: termination,
              previousSupplier: previousSupplier,
              previousSupplierId: previousSupplierId
            };
          }));
          return result;
        }, [] as MetersCheckResult[]);

      delete formData[VERBRAUCHSTYP.Strom];
      delete formData[VERBRAUCHSTYP.Gas];
      delete formData[VERBRAUCHSTYP.Wasser];
      return formData;
    }
  }

   /**
   * find the correct reading for a specific division and meternumber from the ReactiveForm and write the value into
   * the target-structure of the sessionStorage. A reading is child of a reading-group, that is child of a meter-number-field
   * that is child of a meter-number-group that is child of a sparte
   * @param division
   * @private
   */
  private writeReadingValuesIntoMeterData(division: string) {
    const meterFormData = this.form?.get('product-selection')?.get(division)?.get('meterData')?.value as MeterDataFormStructure;
    if (meterFormData && this.meterData) {
      Object.entries(this.meterData[division]).forEach(([meterNumber, meterData]) => {
        const meterFormGroupValue = Object.entries(meterFormData).find(([groupName, group]) => {
          return group.meterNumber === meterNumber;
        });
        if (!meterFormGroupValue) {
          delete this.meterData[meterNumber];
        }
        if (meterFormGroupValue && meterFormGroupValue[1] && meterFormGroupValue[1].readingsGroup) {
          Object.entries((meterFormGroupValue[1]).readingsGroup).forEach(([key, value], index) => {
            meterData.register[index].wert = value;
          });
        }
      });
    }
  }
}
