import { Component, Input } from '@angular/core';
import { LinkData } from '../../../bdo/models/LinkData';
import { TenantService } from '../../../bdo/services/tenant.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'bdo-coop-teaser',
  templateUrl: './coop-teaser.component.html',
  styleUrls: ['./coop-teaser.component.scss']
})
export class CoopTeaserComponent {
  @Input() title: string;
  @Input() iconSrc?: string; // Either set a local iconSrc
  @Input() externalIconSrc?: string; // or an external img link
  @Input() linkData: LinkData[];

  constructor(
    public tenantService: TenantService,
    public domSanitizer: DomSanitizer
  ) { }

}
