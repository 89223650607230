import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../services/storage.service';
import { TRACKING } from '../../enums/trackingParts.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-contract-edit',
  templateUrl: './contract-edit.component.html',
  styleUrls: ['./contract-edit.component.scss']
})
export class ContractEditComponent implements OnInit {
  public TRACKING_LOCATION = TRACKING.LOCATION;
  public campaignContext: boolean = false;
  public offerContext: string;
  public title: string;
  public linkForTextLinkIcon: string;
  private bannerId: string = '';

  constructor(
    public router: Router,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.offerContext = StorageService.getOfferContext();
    this.bannerId = this.activatedRoute?.snapshot?.queryParams['bannerId'];
    this.campaignContext = !!this.bannerId;

    if (this.campaignContext) {
      this.title = this.translateService.instant('contract.edit.campaign.title');
      this.linkForTextLinkIcon = '/dashboard';
    }
    else if (this.offerContext) {
      this.title = this.translateService.instant('offer.reacquisition.title');
    }
    else {
      this.title = this.translateService.instant('move.productSelection.title');
      this.linkForTextLinkIcon = '/vertrag';
    }
  }
}
