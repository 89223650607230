<div class="bdo-container--single">
  <p class="re-mb-default">
    {{ (!showRegistrationDataForm ?
      'register.description.text' :
      'register.description.activate') | translate
    }}
  </p>
</div>

<div [ngSwitch]="activationState" *ngIf="activationState !== ActivationStateEnum.NONE" class="bdo-container--single">
  <bdo-info-box *ngSwitchCase="ActivationStateEnum.ACTIVATION_FAILED">
    {{ 'register.error.expiredLink' | translate }}
  </bdo-info-box>
  <bdo-info-box *ngSwitchCase="ActivationStateEnum.CODE_INVALID">
    {{ 'register.error.invalidLink' | translate }}
  </bdo-info-box>
  <bdo-info-box *ngSwitchDefault>
    {{ 'general.error.error' | translate }}
  </bdo-info-box>
</div>

<div [ngSwitch]="registerError" *ngIf="registerError !== RegisterErrorEnum.NONE" class="bdo-container--single">
  <bdo-info-box *ngSwitchCase="RegisterErrorEnum.WRONG_COMBINATION">
    {{ 'register.error.combinationNotFoundWithCode' | translate }}
  </bdo-info-box>
  <bdo-info-box *ngSwitchCase="RegisterErrorEnum.ACCOUNT_EXISTS">
    {{ 'register.error.alreadyInUse' | translate }}
  </bdo-info-box>
  <bdo-info-box *ngSwitchCase="RegisterErrorEnum.USERNAME_ASSIGNED">
    {{ 'register.error.usernameAlreadyInUse' | translate }}
  </bdo-info-box>
  <bdo-info-box *ngSwitchCase="RegisterErrorEnum.MALFORMED_MAILADDRESS">
    {{ 'register.error.requestCorrectedEmail' | translate }}
  </bdo-info-box>
  <bdo-info-box *ngSwitchCase="RegisterErrorEnum.MALFORMED_PASSWORD">
    {{ 'register.error.requestNewPassword' | translate }}
  </bdo-info-box>
  <bdo-info-box *ngSwitchCase="RegisterErrorEnum.MALFORMED_USERNAME">
    {{ 'register.error.requestUsername' | translate }}
  </bdo-info-box>
  <bdo-info-box *ngSwitchCase="RegisterErrorEnum.MAIL_ERROR">
    {{ 'register.error.emailFailed' | translate }}
  </bdo-info-box>
  <bdo-info-box *ngSwitchCase="RegisterErrorEnum.NOT_INTENDED_FOR_OS">
    <span [innerHTML]="'register.error.unuseableAccount' | translate:
           { contactLink: tenantService.getCurrentTenantData().hrefContact, ariaLabel: 'register.aria.customerService' | translate }"
    ></span>
  </bdo-info-box>
  <bdo-info-box *ngSwitchDefault>
    {{ 'general.error.error' | translate }}
  </bdo-info-box>
</div>

<div class="bdo-container--single">
  <form #registerForm="ngForm" class="bdo-form-container" (ngSubmit)="register(registerForm)" autocomplete="new-password">
    <bdo-loading *ngIf="sendingData"></bdo-loading>

    <fieldset [class.hidden]="showRegistrationDataForm">
      <div class="bdo-form-item-container">
        <bdo-single-line-input
          [attr.data-qa]="'accountIdInput'"
          #accountIdInput
          [inputName]="'accountId'"
          [placeholder]="'general.customerData.account' | translate"
          [labelText]="'general.customerData.account' | translate"
          [inputValue]="accountId"
          (inputValueChanged)="accountId=$event; onFocus()"
          (inputFocused)="onFocus()"
          [required]="true"
          [forceValidate]="validateAll"
          [validationIconHidden]="validationIconHidden"
          [errors]="[{text: ('register.account.accountIdRequired' | translate), name:'required'},
                    { condition: !containsOnlyDigits(registerForm.value?.accountId), text: ('register.account.accountIdOnlyDigits' | translate), name: 'digits'},
                    { condition: !isValidAccountId(registerForm.value?.accountId), text: ('register.account.accountIdInvalid' | translate), name: 'accountId'}]"
        ></bdo-single-line-input>

        <div class="re-absolute re-right-0 re-top-0">
          <bdo-inline-tooltip>
            <span [innerHTML]="'register.account.accountIdHint' | translate"></span>
          </bdo-inline-tooltip>
        </div>
      </div>

      <div class="bdo-form-item-container">
        <bdo-single-line-input
          #meterNumberOrRegisterCodeInput
          [inputName]="'meterNumberOrRegisterCode'"
          [placeholder]="'register.meter.meterIdOrRegistercode' | translate"
          [labelText]="'register.meter.meterIdOrRegistercode' | translate"
          [inputValue]="meterNumberOrRegisterCode"
          (inputValueChanged)="meterNumberOrRegisterCode=$event; onFocus()"
          (inputFocused)="onFocus()"
          [required]="true"
          [forceValidate]="validateAll"
          [validationIconHidden]="validationIconHidden"
          [errors]="[{text: ('register.meter.meterIdOrRegistercodeRequired' | translate), name:'required'}]"
        ></bdo-single-line-input>

        <div class="re-absolute re-right-0 re-top-0">
          <bdo-inline-tooltip>
            <div>{{ 'register.meter.meterIdOrRegistercodeHintMeter' | translate }}</div>
            <div class="re-flex re-flex-row re-mt-15 mobile-only">
              <img src="assets/img/meter-tooltip-strom-s.png" alt="{{ 'register.aria.meter' | translate }}">
              </div>
              <div class="re-flex re-flex-row re-mt-15 tablet-up-only">
              <img src="assets/img/meter-tooltip-strom-xl.png" alt="{{ 'register.aria.meter' | translate }}">
            </div>
            <div>{{ 'register.meter.meterIdOrRegistercodeHintCode' | translate }}</div>
          </bdo-inline-tooltip>
        </div>
      </div>

      <div>
        <button class="bdo-button-primary bdo-button--text re-mb-30" type="submit">
          {{ 'general.action.continue' | translate }}
        </button>
        <a class="bdo-nolink" [routerLink]="['/login']" data-qa="cancel">
          <div class="bold"> {{ 'general.action.cancel' | translate }}</div>
        </a>
      </div>

    </fieldset>

    <fieldset *ngIf="showRegistrationDataForm">
      <div class="bdo-form-item-container">
          <bdo-single-line-input
          #mailInput
          [inputName]="'mail'"
          [inputValue]="mail"
          [inputType]="'email'"
          [placeholder]="'address.email.title' | translate"
          [labelText]="'address.email.title' | translate"
          (inputValueChanged)="mail=$event; onFocus()"
          (inputFocused)="onFocus()"
          [required]="true"
          [forceValidate]="validateAll"
          [validationIconHidden]="validationIconHidden"
          [errors]="[{text: ('address.email.required' | translate), name: 'required'},
                    {text: ('address.email.invalid' | translate), name: 'email'}]"
        ></bdo-single-line-input>
      </div>

      <div class="bdo-form-item-container">
          <bdo-single-line-input
          #mailRepeatInput
          [inputName]="'mailRepeat'"
          [inputValue]="mailRepeat"
          [inputType]="'email'"
          [placeholder]="'address.email.second' | translate"
          [labelText]="'address.email.second' | translate"
          (inputValueChanged)="mailRepeat=$event; onFocus()"
          (inputFocused)="onFocus()"
          [required]="true"
          [forceValidate]="validateAll"
          [validationIconHidden]="validationIconHidden"
          [errors]="[{ text: ('register.email.secondRequired' | translate), name: 'required'},
                    {text: ('address.email.invalid' | translate), name: 'email'},
                    {condition: registerForm.value?.mail!==registerForm.value?.mailRepeat, text: ('register.email.mustMatch' | translate), name: 'same'}]"
        ></bdo-single-line-input>
      </div>

      <div class="bdo-form-item-container">
          <bdo-single-line-input
          #usernameInput
          [inputName]="'usernameNew'"
          [inputValue]="usernameNew"
          [inputType]="'text'"
          [placeholder]="'register.username.title' | translate"
          [labelText]="'register.username.title' | translate"
          (inputValueChanged)="usernameNew=$event; onFocus(); checkingUsername = true;"
          (inputValueChangedDelayed)="checkUsernameAvailable(registerForm.value?.usernameNew)"
          [inputValueChangedDelayedTime]="1000"
          (inputFocused)="onFocus()"
          [required]="true"
          [minLength]="5"
          [forceValidate]="validateAll"
          [validationIconHidden]="validationIconHidden || checkingUsername"
          [errors]="[{ text: 'register.username.required' | translate, name: 'required'},
                    { condition: checkUsername(registerForm.value?.usernameNew), text: 'register.error.invalidCharacters' | translate, name: 'allowedChars'},
                    { condition: !usernameAvailable, text: 'register.error.usernameAlreadyExists' | translate, name: 'nameAvailable'},
                    { condition: checkingUsername, text: ('register.username.beingChecked' | translate), name: 'checkPending'},
                    { condition: registerForm.value?.usernameNew?.length>0 && registerForm.value?.usernameNew?.length<5, text: 'general.validator.minLength' | translate: { numberOfCharacters: 5 }, name: 'minLength'}]"
        ></bdo-single-line-input>
        <span class="bdo-form__helptext"> {{ 'register.username.rules' | translate }}</span>
      </div>

      <div class="bdo-form-item-container">
          <bdo-single-line-input
          #passwordNewInput
          [inputName]="'passwordNew'"
          [inputValue]="passwordNew"
          [inputType]="'password'"
          [placeholder]="'register.password.title' | translate"
          [labelText]="'register.password.title' | translate"
          (inputValueChanged)="passwordNew=$event; onFocus()"
          (inputFocused)="onFocus()"
          [required]="true"
          [forceValidate]="validateAll"
          [validationIconHidden]="validationIconHidden"
          [errors]="[{ condition: !isValidPassword , text: 'register.error.passwordPolicy' | translate, name: 'passwordPolicy'}]"
        ></bdo-single-line-input>
        <bdo-password-strength [passwordNew]="registerForm.value?.passwordNew"></bdo-password-strength>
      </div>

      <div class="bdo-form-item-container">
          <bdo-single-line-input
          #passwordRepeatInput
          [inputName]="'passwordRepeat'"
          [inputValue]="passwordRepeat"
          [inputType]="'password'"
          [placeholder]="'register.password.repeat' | translate"
          [labelText]="'register.password.repeat' | translate"
          (inputValueChanged)="passwordRepeat=$event; onFocus()"
          (inputFocused)="onFocus()"
          [required]="true"
          [forceValidate]="validateAll"
          [validationIconHidden]="validationIconHidden"
          [errors]="[{ text: ('register.password.reenterRequired' | translate), name: 'required'},
                    {condition: registerForm.value?.passwordNew!==registerForm.value?.passwordRepeat, text: ('register.error.passwordsDontMatch' | translate), name: 'same'}]"
        ></bdo-single-line-input>
      </div>

      <div class="bdo-form-item-container">
        <bdo-checkbox
          data-qa="tos-checkbox"
          [checked]="tosAccepted"
          [required]="tosTouched"
          [errorText]="'register.terms.confirmTosRequired' | translate"
          (change)="onTosClicked();">
          <span>
            {{ 'register.terms.termsAndConditions1' | translate }}
            <a class="bdo-link--inline"
               [routerLink]="['/nutzungsbedingungen']"
               (click)="trackIt()"
               target="_blank">
                {{ 'register.terms.termsAndConditions2' | translate }}</a>
            {{ 'register.terms.termsAndConditions3' | translate }}
          </span>
        </bdo-checkbox>
      </div>
      <div class="bdo-form-item-container" *ngIf="competitionHelperService.isActive()">
        <bdo-checkbox
          [checked]="competitionChecked"
          (change)="onCompetitionChanged()">
          <span [innerHTML]="'register.account.competition' | translate"></span>
        </bdo-checkbox>
      </div>

      <div class="bdo-space--bottom">
        <button class="bdo-button-primary bdo-button--text re-mb-30" type="submit">
          {{ 'register.submit' | translate }}
        </button>
        <a class="bdo-nolink" [routerLink]="['/login']" data-qa="cancel">
          <div class="bold"> {{ 'general.action.cancel' | translate }}</div>
        </a>
      </div>
    </fieldset>


  </form>
</div>

<!-- FAQs -->
<bdo-faqs
  [keyName]="'register.faqs'"
></bdo-faqs>


<bdo-simple-text-popup [isVisible]="registeringDone" (closePopupEvent)="closeMessage()">
  <bdo-register-success (closeEmitter)="closeMessage()"></bdo-register-success>
</bdo-simple-text-popup>
