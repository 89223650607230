import { Component, } from '@angular/core';
import { TRACKING } from '../../bdo/enums/trackingParts.enum';
import { TenantService } from '../../bdo/services/tenant.service';
import { TrackingService } from '../../bdo/services/tracking.service';

@Component({
  selector: 'bdo-loginpage',
  templateUrl: './loginpage.component.html',
  styleUrls: ['./loginpage.component.scss']
})
export class LoginpageComponent {
  public TRACKING = TRACKING;

  constructor(
    public tenantService: TenantService,
    public trackingService: TrackingService
  ) { }

}
