import { Component, Input } from '@angular/core';
import { AddressData } from '../../../../../../assets/js/com/ts_api_client';

@Component({
  selector: 'bdo-address-summary',
  templateUrl: './address-summary.component.html'
})
export class AddressSummaryComponent {
  @Input() addressData: AddressData;

}
