import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthDataStorage } from '../bdo/models/AuthData.storage';
import { BdoApiService } from '../bdo/services/bdo-api.service';
import { CustomerStoreService } from '../bdo/services/customer-store.service';
import { StorageService } from '../bdo/services/storage.service';
import { LoginService } from '../login/login.service';
import { VEBOUTGUARD_PROCESS } from '../bdo/enums/vebOutGuardProcess.enum';

export function guardVebOut(vebOutGuardProcess: VEBOUTGUARD_PROCESS): CanActivateFn {
  return (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) => {
    const apiService = inject(BdoApiService);
    const customerStore = inject(CustomerStoreService);
    const loginService = inject(LoginService);
    const router = inject(Router);
    const authData: AuthDataStorage = StorageService.getAuthData();
    const handleAccountId: () => Observable<string> = () => {
      let accountIdObs$: Observable<string>;
      if (customerStore.getAccountId()) {
        accountIdObs$ = of(customerStore.getAccountId());
      } else {
        // getAccounts
        accountIdObs$ = apiService.getAccounts().pipe(switchMap((accounts) => {
          const accountId = accounts.filter((account) => !account.inaktiv)?.[0]?.accountId;
          customerStore.setAccountId(accountId);
          return of(accountId);
        }), catchError((err: unknown) => {
          return of('');
        }));
      }
      return accountIdObs$;
    };

    return loginService.isLoggedIn$.pipe(
      switchMap((isLoggedIn) => {
        let accountId: string;
        let meterNumber: string;
        if (!isLoggedIn) {
          // Should be caught with AuthGuard
          if (!authData) return throwError(() => new Error('No AuthData found'));
          accountId = authData?.checkIdentificationData.accountId;
          meterNumber = authData?.checkIdentificationData.meterNumberOrRegisterCode;
          return of({ accountId, meterNumber });
        } else {
          return handleAccountId().pipe(switchMap((handledAccountId) => {
            return apiService.getZaehlerIdentifiers().pipe(
              map (identifiers => {
                const activeMeters = identifiers?.filter(identifier => !identifier.inaktiv);
                return { accountId: handledAccountId, meterNumber: activeMeters[0]?.meterNumber };
              }));
          }));
        }
      }),
      switchMap(({ accountId, meterNumber }) => {
        return apiService.getMoveOutAnonymous(accountId, meterNumber, vebOutGuardProcess === VEBOUTGUARD_PROCESS.CANCEL);
      }),
      switchMap((moveOutResponse) => {
        const hasVebOut: boolean = JSON.parse(moveOutResponse.info?.toLowerCase());
        if (hasVebOut) {
          return of(router.parseUrl('/hinweis-auszug'));
        } else {
          return of(true);
        }
      })
      , catchError((err: unknown) => {
          return of(true);
        }
      )
    );
  };
}

