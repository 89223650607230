import { Injectable } from '@angular/core';
import { Environment } from '../../../environments/environment';
import { TENANT, TENANT_ACRONYM } from '../enums/tenant.enum';
import { TranslateService } from '@ngx-translate/core';
import { Utilities } from '../../shared/utils/utilities';

import { HttpClient } from '@angular/common/http';
import { Observable, map, of, switchMap } from 'rxjs';
import { parse } from 'yaml';
import { TrackingService } from './tracking.service';
import { TRACKING } from '../enums/trackingParts.enum';

export interface LanguagesConfig {
  acronym: string,
  language: string,
  file: string
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public selectedLanguage: string = 're';
  // Using Three-letter-codes of ISO-639
  public availableLanguages: Array<LanguagesConfig> = [
    { acronym: 'DE', language: 'Deutsch', file: 're' },
    { acronym: 'EN', language: 'English', file: 'eng' }
  ];

  constructor(
    private translateService: TranslateService,
    private trackingService: TrackingService,
    private http: HttpClient
  ) { }

  // show language selection only for re tenant
  getLanguageSelectionAvailable(): boolean {
    return Environment.tenant === TENANT.Rheinenergie;
  }

  setInitialLanguage() {
    const cookieLanguage = Utilities.getCookie('language');
    if (cookieLanguage &&
      Environment.tenant === TENANT.Rheinenergie &&
      // Prevent unknown languages breaking application
      this.availableLanguages.some(config => config.file === cookieLanguage)) {
      this.selectedLanguage = cookieLanguage;
      this.translateService.use(this.selectedLanguage);
    } else {
      // set language to the current tenant language
      // therefore the language files for the tenants must be named like the deposited acronym
      const defaultLanguage = TENANT_ACRONYM[Environment.tenant];
      this.translateService.use(defaultLanguage);
      this.selectedLanguage = defaultLanguage;
    }
    // No language selection for non-re
    if (Environment.tenant === TENANT.Rheinenergie) {
      this.trackingService.postSimpleTracking(TRACKING.LOCATION.LANGUAGE_SELECTION, TRACKING.ACTION.SET,
        this.availableLanguages.find(config => config.file === this.selectedLanguage).language);
    }
  }

  setLanguage(newLanguage: string) {
    this.selectedLanguage = newLanguage;
    this.translateService.use(newLanguage);
    if (Environment.tenant === TENANT.Rheinenergie) {
      Utilities.createCookie('language', newLanguage, 30);
    }
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.LANGUAGE_SELECTION, TRACKING.ACTION.CLICK,
      this.availableLanguages.find(config => config.file === this.selectedLanguage).language);
  }

  getSelectedLanguage(): string {
    return this.selectedLanguage;
  }

  getKeysFAQinDefaultLang(keyName: string): Observable<Array<string>> {
    // we use default - translation to find how many faqs we will show
    return this.http
      .get('../../../assets/i18n/' + this.translateService.defaultLang + '.json', { responseType: 'text' })
      .pipe(
        map((data) => parse(data)),
        switchMap((res) => {
          const faqsToKeyNew = keyName.split('.').reduce((a,b) => a[b], res);
          return of(Object.keys(faqsToKeyNew));
        })
      );
  }
}
