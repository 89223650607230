<div class="re-w-full re-flex re-justify-center re-font-regular">
  <ul class="re-flex re-w-full re-p-0 re-my-0">
    <li (click)="trackWizardStepClick(i+1)"  [attr.data-qa]="'wizard-step'" class="re-flex-1" *ngFor="let wizardStep of wizardSteps; let i = index">
      <div class="grid-container" [attr.data-qa]="wizardStep.optional ? 'optional': undefined">

        <!-- icon of wizardStep -->
        <span class="re-w-30 re-h-30 re-stroke-current re-m-auto">
          <svg-icon src="{{wizardStep.iconPath}}" title="Schritt {{i+1}}: {{wizardStep.title | translate}}"></svg-icon>
        </span>

        <!-- show line -->
        <span class="secondRow step-line re-flex">
          <span class="re-my-auto re-transition-all re-h-3 re-z-0 re-w-1/2" [ngClass]="activeWizardStep >= i+1 ? 're-bg-primary' : 're-bg-white'"></span>
          <span class="re-my-auto re-transition-all re-h-3 re-z-0 re-w-1/2" [ngClass]="activeWizardStep > i+1 ? 're-bg-primary' : 're-bg-white'"></span>
        </span>


        <!-- show previous steps with clickable circle -->
        <a *ngIf="activeWizardStep > i+1"
           class="secondRow hoverTitle previousWizard
                  re-flex re-justify-center re-items-center re-box-content re-mx-auto re-z-10
                  re-rounded-full re-border-solid re-border-4 re-border-monochrome-light re-bg-primary
                hover:re-bg-monochrome-dark"
           [routerLink]="wizardStep.path"
           [attr.aria-label]="'wizardStep?.title' | translate"
           queryParamsHandling="merge">
          <span class="re-h-15 re-w-15 re-text-white checkmark">
            <svg-icon *ngIf="activeWizardStep > i+1" src="assets/icons/wizard_steps/checkmark.svg"></svg-icon>
          </span>
        </a>

        <!-- show active and next steps with small cirle without link -->
        <span *ngIf="activeWizardStep <= i+1"
              class="secondRow re-z-10 re-w-20 re-h-20 re-box-content re-m-auto re-border-4 re-rounded-full re-border-solid re-border-monochrome-light transition"
              [ngClass]="activeWizardStep === i+1 ? 're-bg-primary' : 're-bg-white'">
        </span>

        <!-- name of active step -->
        <span *ngIf="activeWizardStep === i+1"
              class="thirdRow re-leading-none re-text-xs re-m-auto re-text-center">
          {{ wizardStep?.title | translate }}
        </span>

        <!-- shows the name when hovering over the clickable circle -->
        <span class="thirdRow showtitle re-invisible re-leading-none re-text-xs re-m-auto re-text-center">
          {{ wizardStep?.title | translate }}
        </span>
      </div>
    </li>
  </ul>
</div>
