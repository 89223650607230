import { Component, Input } from '@angular/core';
import { TrackingService } from '../../../bdo/services/tracking.service';
@Component({
  selector: 'bdo-info-teaser',
  templateUrl: './info-teaser.component.html',
  styleUrls: ['./info-teaser.component.scss']
})
export class InfoTeaserComponent {

  @Input() title: string;
  @Input() buttonText: string;
  @Input() iconSrc: string = '';
  @Input() buttonStyle: 'button' | 'textLinkIcon';
  @Input() routerUrl: string;
  @Input() fragment: string;
  @Input() hasButton: boolean = true;
  @Input() tracking: Array<string> = [];

  constructor(private trackingService: TrackingService) {}

  trackIt() {
    if (this.tracking.length > 0) { this.trackingService.postSimpleTracking(...this.tracking); }
  }

}
