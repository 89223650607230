<div *ngIf="payments && mode !== Mode.ZERO" class="re-mb-default">
  <div class="bigger bold re-mb-default" data-qa="forderung" [ngClass]="mode === Mode.CREDIT ? 'good' : 'error'">
    {{payments?.offeneSumme | cutMinus | currency:'EUR'}}
  </div>
  <div *ngIf="bankAccountAssignments && bankAccountAssignments[mode];else withoutSepa" data-qa="with-sepa">
    <div data-qa="sepa-text">{{config[mode].sepaText}}</div>
    <div data-qa="assignment" *ngIf="bankAccountAssignments && bankAccountAssignments[mode]">{{bankAccountAssignments[mode].bankname}}:&nbsp;{{bankAccountAssignments[mode].iban}}</div>
  </div>
  <ng-template #withoutSepa>
    <div class="re-mb-default" data-qa="without-sepa">{{config[mode].withoutSepa}}</div>
    <div class="re-mb-default" data-qa="sepa-hint">{{config[mode].withBankAccounts}}</div>
  </ng-template>
</div>
