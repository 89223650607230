import { Component, OnInit, Input } from '@angular/core';

import { StorageService } from '../../../../services/storage.service';
import { ContractPaymentData } from '../../../../../../assets/js/com/ts_api_client';
import { Utilities } from '../../../../../shared/utils/utilities';

@Component({
  selector: 'bdo-payments-summary',
  templateUrl: './payments-summary.component.html'
})
export class PaymentsSummaryComponent implements OnInit {
  @Input() noFutureContract: boolean = false;

  public Utilities = Utilities;
  public paymentData: ContractPaymentData;

  ngOnInit(): void {
    this.paymentData = StorageService.getPaymentData();
  }

}
