import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ContentFilter } from '../../../bdo/models/contentFilter';

@Component({
  selector: 'bdo-filtered-content',
  templateUrl: './filtered-content.component.html',
  styleUrls: ['./filtered-content.component.scss']
})
export class FilteredContentComponent implements OnChanges {
  @Input() filters: Array<ContentFilter> = [];
  @Input() selected: string;
  @Output() filterSelected: EventEmitter<string> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters && !this.selected) {
      this.selected = (changes.filters.currentValue as Array<ContentFilter>)?.[0]?.id;
    }
  }

  onSelect(id: string) {
    this.selected = id;
    this.filterSelected.emit(this.selected);
  }

}
