export const ABLESEART = {
    /* TODO: Update when SAP delivers IDs
    '01': 'Ablesung durch Netzbetreiber',
    '50': 'Ablesung durch Netzbetreiber',
    '60': 'Ablesung durch Netzbetreiber',
    '02': 'Kundenselbstablesung',
    '06': 'Kundenselbstablesung',
    '51': 'Kundenselbstablesung',
    '61': 'Kundenselbstablesung',
    '03': 'Schätzung',
    '04': 'Schätzung',
    '05': 'Schätzung',
    '14': 'Schätzung',
    '11': 'Ablesung durch Nachmieter',
    '12': 'Ablesung durch Hausmeister',
    '13': 'Ablesung durch Fremdfirmen',
    '15': 'Ablesung durch Eigentümer',
    '52': 'Schätzung Netzbetreiber',
    '62': 'Schätzung Netzbetreiber' */
    'VNB': 'Ablesung durch Netzbetreiber',
    'SCH': 'Schätzung',
    'KSA': 'Kundenselbstablesung'
};
