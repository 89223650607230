import { Component } from '@angular/core';
import { PollQuestionComponent } from '../poll-question/poll-question.component';

@Component({
  selector: 'bdo-boolean-question',
  templateUrl: './boolean-question.component.html',
  styleUrls: ['./boolean-question.component.scss']
})
export class BooleanQuestionComponent extends PollQuestionComponent {

}
