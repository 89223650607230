import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { PaymentData } from '../../../../../assets/js/com/ts_api_client';
import { FadeInAnimation } from '../../../../shared/animations/fade-in.animation';
import { BdoApiService } from '../../../services/bdo-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { NavigationState } from '../../../models/navigationState';
import { Utilities } from '../../../../shared/utils/utilities';

@Component({
  selector: 'bdo-defer-payment',
  templateUrl: './defer-payment.component.html',
  styleUrls: ['./defer-payment.component.scss'],
  animations: [
    FadeInAnimation
  ]
})
export class DeferPaymentComponent implements OnInit {

  public selectedDate: Date;
  public LoadingState = LOADING_STATE;
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public paymentData: PaymentData;
  public showSuccessPopup = false;
  public subscriptions = new Subscription();

  constructor(
    public location: Location,
    public apiService: BdoApiService,
    private route: ActivatedRoute,
    public router: Router,
    private trackingService: TrackingService
  ) {
    const currentState: NavigationState = Utilities.getStateOfCurrentRoute(this.location);
    this.paymentData = currentState?.paymentData;
    if (this.paymentData) {
      this.selectedDate = new Date(this.paymentData.deferrableUpTo);
    }
  }

  ngOnInit(): void {
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.PAYMENTS_DEFERRAL, TRACKING.ACTION.ENTER);
    this.subscriptions.add(this.route.params.subscribe({ next: params => {
        // fetch payments if paymentData is not filled (not provided by routerNavigationExtras, because of a page reload i.E.)
     if (!this.paymentData) {
       this.state = LOADING_STATE.LOADING;
       this.apiService.getPayments().subscribe(
         {
           next: (res) => {
             const payments: Array<PaymentData> = res.receivables?.payments;
             this.paymentData = payments.find((item) => item.id === params['paymentId']);
             this.selectedDate = new Date(this.paymentData?.deferrableUpTo);
             this.state = LOADING_STATE.IDLE;
           },
           error: () => {
             this.state = LOADING_STATE.ERROR;
           }
         });
     }
    } }));
  }

  save() {
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.PAYMENTS_DEFERRAL, TRACKING.ACTION.SAVE);
    this.state = LOADING_STATE.LOADING;
    this.apiService.deferPayment({
      id: this.paymentData.id,
      deferredTo: this.selectedDate
    })
      .subscribe({
          next: () => {
            this.showSuccessPopup = true;
            this.state = LOADING_STATE.IDLE;
            this.trackingService.postSimpleTracking(TRACKING.LOCATION.PAYMENTS_DEFERRAL, TRACKING.ACTION.SUCCESS);
          },
          error: () => {
            this.state = LOADING_STATE.ERROR;
            this.trackingService.postSimpleTracking(TRACKING.LOCATION.PAYMENTS_DEFERRAL, TRACKING.ACTION.ERROR);
          }
        });
  }

  onDateChanged(selectedDate: Date) {
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.PAYMENTS_DEFERRAL, TRACKING.FORM_ACTION.SELECT, 'Termin');
    if (selectedDate > this.paymentData?.dateDue) {
      this.selectedDate = selectedDate;
    }
  }

  onClosePopup() {
    this.showSuccessPopup = false;
    this.navigateBack();
  }

  navigateBack() {
    this.router.navigate(['../../'], {
      relativeTo: this.route
    });
  }
}
