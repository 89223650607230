<div class="bdo-table">
  <table class="table--desktop">
    <thead>
    <tr>
      <th>{{ 'meterreadings.info.meterreadingDate' | translate }}</th>
      <th *ngIf="ablesungen" class="data--long  bdo-text--right">{{ 'meterreadings.meterreadingIn' | translate }} {{ablesungen[0]?.einheitStand | unitPretty}} </th>
      <th *ngIf="ablesungen" class="data--long  bdo-text--right">{{ 'meterreadings.history.consumptionIn' | translate }} {{ablesungen[0]?.einheitVerbrauch | unitPretty}}  <span aria-describedby="verbrauch_info">*</span></th>
      <th colspan="2" class="table-head--limited---delete">{{ 'meterreadings.history.meterreadingReason' | translate }}, {{ 'meterreadings.history.meterreadingType' | translate }}</th>
    </tr>
    </thead>
    <tbody *ngIf="ablesungen?.length > 0">
    <tr *ngFor="let ablesung of ablesungen | slice: 0:totalRecords">
      <td class="bdo-font--monospace">{{ablesung.ablesedatum | date}}</td>
      <td class="bdo-text--right bdo-font--monospace">
            <span *ngFor="let stand of ablesung?.staende">
              {{stand.stand | dotToComma}}
              <span *ngIf="ablesung?.staende?.length>1">
                {{ stand.typ }} <br>
              </span>
            </span>
      </td>
      <td class="bdo-text--right bdo-font--monospace">
            <span *ngFor="let stand of ablesung?.staende">
              {{stand.verbrauch | dotToComma }}
              <span *ngIf="ablesung?.staende?.length>1">
                {{ stand.typ }} <br>
              </span>
            </span>
      </td>
      <td> {{ablesung.ablesegrund | mapAblesegrund}}, {{ablesung.ableseart | mapAbleseart}}</td>
      <td>
        <button class="re-w-20 re-block icon"
        *ngIf="zaehlerNummer && ablesung.canBeEdited && !editDisabled"
          (click)="onEdit(ablesung.ablesedatum)"
          title="{{ 'general.imgText.edit' | translate }}">
          <svg-icon src="{{ 'general.img.edit' | translate }}"></svg-icon>
      </button>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<div class="re-mb-5 table--tablet"  *ngFor="let ablesung of ablesungen | slice: 0:totalRecords">
  <div class="bdo-tile space-line re-mb-2">
    <div class="re-mb-15">
      <div class="bold">{{ 'meterreadings.info.meterreadingDate' | translate }}</div>
      {{ablesung.ablesedatum | date}}
    </div>
    <div class="re-mb-15">
      <div class="bold">{{ 'meterreadings.meterreading' | translate }}</div>
      <span *ngFor="let stand of ablesung.staende">
            {{stand.stand | dotToComma }} {{ablesung.einheitStand | unitPretty}}
        <span *ngIf="ablesung.staende?.length>1">
              {{ stand.typ }} <br>
            </span>
          </span>
    </div>
    <div class="re-mb-15">
      <div class="bold">{{ 'meterreadings.history.consumptionWithRemark' | translate }}</div>
      <span *ngFor="let stand of ablesung.staende">
          {{stand.verbrauch | dotToComma }} {{ablesung.einheitVerbrauch | unitPretty}}
        <span *ngIf="ablesung.staende?.length>1">
            {{ stand.typ }} <br>
          </span>
        </span>
    </div>
    <div class="re-mb-15">
      <div class="bold">{{ 'meterreadings.history.meterreadingReason' | translate }}</div>
      {{ablesung.ablesegrund | mapAblesegrund}}
    </div>
    <div class="re-mb-15">
      <div class="bold">{{ 'meterreadings.history.meterreadingType' | translate }}</div>
      {{ablesung.ableseart | mapAbleseart}}
    </div>
  </div>
    <div class="bdo-tile" *ngIf="zaehlerNummer && ablesung.canBeEdited">
      <div class="re-flex-row re-flex bold hover-container">
        <button class="icon" (click)="onEdit(ablesung.ablesedatum)">
          {{ 'general.action.edit' | translate }}
          <svg-icon src="{{ 'general.img.edit' | translate }}"
                    class="re-w-24 re-ml-10">
          </svg-icon>
        </button>
      </div>
    </div>
</div>

<p id="verbrauch_info" class="re-mt-15 re-mb-30" *ngIf="ablesungen?.length > 0" >{{ 'meterreadings.history.consumptionSinceLastReading' | translate }}</p>

<bdo-more-button *ngIf="ablesungen?.length > totalRecords"
  [label]="'general.action.showMore' | translate"
  (moreEvent)="addRecords()"
></bdo-more-button>

<bdo-no-data
  *ngIf="!ablesungen || ablesungen.length < 1"
  [text]="'meterreadings.history.noMeterreadingsFound' | translate"
></bdo-no-data>
