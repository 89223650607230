import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../bdo/services/language.service';
import { Router } from '@angular/router';

@Component({
  selector: 'bdo-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit{
  @Input() params: object = {};
  @Input() keyName: string;
  public arrayOfFaqs: Array<string> = [];
  public countFaqs: Array<string>;

  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.languageService.getKeysFAQinDefaultLang(this.keyName).subscribe({
      next: (countFaqs) => { this.countFaqs = countFaqs; },
      complete: () => {
        this.countFaqs.forEach(faqKey => {
          const key: string = [this.keyName, faqKey, 'question'].join('.');
          if (this.translateService.instant(key) !== '') {
            this.arrayOfFaqs.push(faqKey);
          }
        });
      }
    });
  }
}
