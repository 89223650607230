import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PartnerAddressData } from '../../../../../assets/js/com/ts_api_client';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { BdoApiService } from '../../../services/bdo-api.service';
import { AddressFormComponent } from '../../address-form/address-form.component';
import { ToastService } from '../../../services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'bdo-personal-data-partner-address-form',
  templateUrl: './personal-data-partner-address-form.component.html',
  styleUrls: ['./personal-data-partner-address-form.component.scss']
})
export class PersonalDataPartnerAddressFormComponent implements OnInit {
  @ViewChild(AddressFormComponent, { static: true }) set content(content: AddressFormComponent) {
    if (content) { // initially setter gets called with undefined
      this.addressForm = content;
    }
  }
  @Input() addressData: PartnerAddressData;
  @Output() editDone: EventEmitter<boolean> = new EventEmitter();

  public addressForm: AddressFormComponent;
  public isPopupShown: boolean = false;
  public state = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;

  constructor(
    private apiService: BdoApiService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.addressData) {
      this.addressForm.form.get('postCode').setValue(this.addressData.postCode);
      this.addressForm.form.get('city').setValue(this.addressData.cityName);
      this.addressForm.form.get('street').setValue(this.addressData.streetName);
      this.addressForm.form.get('housenumber').setValue(this.addressData.houseNum);
    }
    this.router.navigate([], { fragment: 'partnerAddress', skipLocationChange: true });
  }

  save() {
    const form = this.addressForm?.form;
    if (this.addressForm?.form.valid) {
      this.state = LOADING_STATE.LOADING;

      const data: PartnerAddressData = {
        postCode: form.get('postCode').value,
        cityName: form.get('city').value,
        streetName: form.get('street').value,
        houseNum: form.get('housenumber').value,
        addressId: this.addressData.addressId,
        partnerId: this.addressData.partnerId
      };
      this.apiService.postPartnerAddress(data)
      .subscribe(
        {
          next: () => {
            this.state = LOADING_STATE.IDLE;
            this.toastService.pushToast({
              headline: this.translateService.instant('personaldata.address.post.success')
            });
            this.editDone.emit(true);
          },
          error: () => {
            this.state = LOADING_STATE.ERROR;
          }
        });
    } else {
      form.markAllAsTouched();
      form.updateValueAndValidity();
    }
  }

}
