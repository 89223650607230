import { AbstractControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

/**
 * Validates a phone number
 * is allowed to contain numbers, spaces, Slashes and Minus
 * @param {string} customErrorMessage
 * @returns {(control: AbstractControl) => {[p: string]: string}}
 */
export function phonenumberValidator(translateService: TranslateService, errorText?: string) {
  return (control: AbstractControl): { [key: string]: string | true } | null => {
      // const regex = RegExp('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$');
    const regex = RegExp('^[0-9 \/\-]*$');
    errorText = errorText ? errorText : translateService.instant('address.phonenumber.onlyDigits');

    if (!regex.test(control?.value)) {
      return { ['phonenumber']: (errorText ? errorText : true) };
    }
    return null;
  };
}
