import { Injectable, signal } from '@angular/core';
import { Toast } from '../../shared/models/toastData';
import { DebugLogger } from '../../shared/utils/debugLogger';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts = signal<Toast[]>([]);
  currentToast = signal<Toast>(undefined);

  constructor() { }

  pushToast(toast: Toast) {
    toast = this.setDefaultValues(toast);
    this.toasts.update(values => {
      return [...values, toast];
    });
    if (!this.currentToast()) {
      this.currentToast.set(toast);
    }
    DebugLogger.debug('ToastService', 'push', this.toasts());
  }

  removeToast() {
    this.toasts.update(values => {
      return [...values.slice(1)];
   });
   this.currentToast.set(undefined);
   if (this.toasts().length > 0) {
    this.currentToast.set(this.toasts()?.[0]);
   }
   DebugLogger.debug('ToastService', 'remove', this.toasts());
  }

  private setDefaultValues(toast: Toast): Toast {
    toast = this.setDefaultIfNotPresent(toast, 'type', 'success');
    toast = this.setDefaultIfNotPresent(toast, 'closable', true);
    toast = this.setDefaultIfNotPresent(toast, 'durationIndicator', true);
    toast = this.setDefaultIfNotPresent(toast, 'stackable', false);
    return toast;
  }

  private setDefaultIfNotPresent<K extends keyof Toast>(toast: Toast, key: K, defaultValue: Toast[K]) {
    if (!toast.hasOwnProperty(key)) {
      toast[key] = defaultValue;
    }
    return toast;
  }
}
