import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dotToComma'
})
export class DotToCommaPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) { return value; }
    value = value.toString();
    value = value.replace('.', ',');
    return value;
  }

}
