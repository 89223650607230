<div class="header__wrapper">
  <header class="header">
    <div class="nav-content-container">
      <!-- <div><img class="logo" src="assets/RE_Stammlogo_RGB_rot-schwarz.svg"></div> -->
      <a [routerLink]="['/dashboard']" class="logo-container header__padding">
        <img class="logo"
          alt="{{ 'general.imgText.logo' | translate }}"
          src="{{ 'general.img.logo' | translate }}"
          (click)="onClickOnLogo()"
        >
      </a>

      <div *ngIf="showLanguageSelection && !languageHidden" class="language-selection-container re-flex re-self-center bold re-items-center re-border-l-2 re-border-monochrome-light re-h-full re-max-w-155">
          <div class="re-w-30 re-h-30 re-absolute re-z-minus re-ml-15 md:re-ml-25">
            <svg-icon class="re-w-30 re-h-30 re-block" [src]="'assets/icons/sprachen.svg'"></svg-icon>
          </div>
          <select
            class="re-border-none re-bg-transparent re-pr-40 re-pl-55 md:re-pr-50 md:re-pl-65"
            title="{{ 'general.languageSelection' | translate }}"
            [formControl]="form.controls.selected"
            [attr.data-qa]="'dropdown_language'"
            [attr.aria-label]="'general.languageSelection' | translate"
            (change)="updateLanguage($event)"
            >
            <option *ngFor="let item of availableLanguages"
              [value]="item.value"
              [selected]="selectedLanguage === item.value">
              {{item.label}}
            </option>
          </select>
          <div class="re-w-15 re-h-15 re-absolute re-z-minus re-ml-90 md:re-ml-100">
            <svg-icon class="re-w-15 re-h-15 re-flex re-items-center" [src]="'assets/icons/edge-arrow-down.svg'"></svg-icon>
          </div>
      </div>

      <div class="login re-border-l-2 re-border-monochrome-light" *ngIf="!loginHidden">
        <button class="bold re-h-60 lg:re-h-80 re-px-15 lg:re-px-25 re-align-top hover:re-bg-secondary-hover hover:re-text-white"
          type="button"
          data-qa="logout-btn"
          [attr.aria-haspopup]="isLoggedIn? true : false"
          [attr.aria-label]="isLoggedIn? 'Logout' : 'Login'"
          (click)="onLog()"
          *ngIf="!(router?.url.startsWith('/login') && !isLoggedIn)">
          <div class="re-flex re-items-center">
            <div class="re-w-30 re-h-30 re-mr-10">
              <svg-icon *ngIf="!isLoggedIn" [src]="'assets/icons/system_icons/login.svg'"></svg-icon>
              <svg-icon *ngIf="isLoggedIn" [src]="'assets/icons/system_icons/logout.svg'"></svg-icon>
            </div>
            <span class="re-hidden md:re-inline">{{isLoggedIn? 'Logout' : 'Login'}}</span>
          </div>
        </button>
      </div>
    </div>
  </header>
</div>
