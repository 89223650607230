import { isDevMode } from '@angular/core';

/**
 * Logger, that shows a structured message in the console
 */
export class DebugLogger {

  /**
   * @param className name of the component/class, that called this method
   * @param methodName method name where the logging occured
   * @param debugContent 1...n logged objects or strings
   */
  static debug(className: string, methodName: string, ...debugContent) {
    if (isDevMode()) {
      console.log(`%c${className}:%c${methodName}()`,
        `color: #${this.hashCode(className)}; font-weight: bold`,
        `color: #${this.hashCode(methodName)};`,
        ...debugContent);
    }
  }

  /**
   * @param className name of the component/class, that called this method
   * @param methodName method name where the logging occured
   * @param debugContent 1...n logged objects or strings
   */
  static error(className: string, methodName: string, ...debugContent) {
    if (isDevMode()) {
      console.error(`%c${className}:%c${methodName}()`,
        `color: #${this.hashCode(className)}; font-weight: bold`,
        `color: #${this.hashCode(methodName)};`,
        ...debugContent);
    }
  }

  /**
   * calculate random color for a string
   * @param str
   * @private
   */
  private static hashCode(str) { // java String#hashCode
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return this.intToRGB(hash);
  }

  private static intToRGB(num: number) {
    const c = (num & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

    return '00000'.substring(0, 6 - c.length) + c;
  }
}
