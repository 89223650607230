import { Component, Input } from '@angular/core';
import { LinkData } from '../../../bdo/models/LinkData';

@Component({
  selector: 'bdo-text-box-page',
  templateUrl: './text-box-page.component.html',
  styleUrls: ['./text-box-page.component.scss']
})
export class TextBoxPageComponent {
  @Input() title: string;
  @Input() boxTitle: string;
  @Input() infoText: string;
  @Input() linkData: LinkData;

}
