<h3 class="re-container-headline re-mb-30 md:re-mb-45 lg:re-mb-60">
  {{ 'offer.status.title' | translate: { companyName: tenantService.getCurrentTenantData().companyName } }}
</h3>

<div class="re-relative" [ngClass]="{'md:re-max-w-[900px] md:re-mx-auto' : loadingState === LoadingState.LOADING }">
  <bdo-loading *ngIf="loadingState === LoadingState.LOADING"></bdo-loading>
  <div class="re-max-w-auto re-mx-20 md:re-w-600 md:re-mx-auto lg:re-w-900">
  <bdo-info-teaser
    *ngIf="isValidOffer; else showErrorPage"
    [title]="'offer.status.available.title' | translate"
    [buttonText]="'offer.status.available.button' | translate"
    [iconSrc]="'offer.status.available.iconSrc' | translate"
    [buttonStyle]="'button'"
    [routerUrl]="destination"
    [hasButton]="true"
    [tracking]="tracking">
    {{ 'offer.status.available.description' | translate }}
  </bdo-info-teaser>
  <ng-template #showErrorPage>
    <bdo-info-teaser
      [title]="'offer.status.notAvailable.title' | translate"
      [iconSrc]="'offer.status.notAvailable.iconSrc' | translate"
      [hasButton]="false">
      <div [innerHTML]="'offer.status.notAvailable.description' | translate: {website: tenantService.getCurrentTenantData().hrefHomepage}"></div>
    </bdo-info-teaser>
  </ng-template>
  </div>
</div>


