import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeData } from '../../../models/home';
import { LegalData } from '../../../models/legalData';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { CustomerStoreService } from '../../../services/customer-store.service';
import { LOADING_STATE } from '../../../enums/loadingState.enum';
import { CONTEXT_FROM_SUMMARY } from '../../delivery/summary/summary.component';
import { VERBRAUCHSTYP } from '../../../enums/verbrauchstyp.enum';
import { Location } from '@angular/common';
import { Contract, MetersCheckResult, Zaehlerstand, ContractPaymentData } from '../../../../../assets/js/com/ts_api_client';
import { BillingAddressFormData } from '../../../models/billingAddressFormData';
import { BdoApiService } from '../../../services/bdo-api.service';
import { CUSTOMERMODE } from '../../../enums/customerMode';
import { TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-contract-end-summary',
  templateUrl: './contract-end-summary.component.html',
  styleUrls: ['./contract-end-summary.component.scss']
})
export class ContractEndSummaryComponent implements OnInit {

  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public validateCheckboxes: boolean = false;
  public recentDivisionIds: Array<VERBRAUCHSTYP> = [];
  public termsOfConditionsAccepted: boolean = false;
  public rightOfWithdrawalAccepted: boolean = false;
  public billingAddress: BillingAddressFormData;
  public isGewe: boolean = false;
  private accountId: string = '';

  constructor(
    public location: Location,
    public translateService: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: BdoApiService,
    private customerStore: CustomerStoreService,
    private trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_END_SUMMARY, TRACKING.ACTION.ENTER);
    this.accountId = this.customerStore.getAccountId();
    this.billingAddress = StorageService.getValue(ALLOWED_KEYS.BILLING_ADDRESS);
    this.isGewe = this.billingAddress?.partnerKind === CUSTOMERMODE.GEWE;
    const recentData = StorageService.getValue<HomeData>(ALLOWED_KEYS.RECENT_HOME_DATA);
    this.recentDivisionIds = recentData?.meterData.map((meterreading) => {
      for (const division of Object.keys(VERBRAUCHSTYP)) {
        if (meterreading.sparte === VERBRAUCHSTYP[division].toString()) {
          return VERBRAUCHSTYP[division];
        }
      }
    });
  }

  onEdit(wizardStep: number, fragment: string) {
    this.router.navigate(['../' + fragment], {
      relativeTo: this.activatedRoute,
      state: {
        context: CONTEXT_FROM_SUMMARY
      }
    });
  }

  onChangeTermsOfConditions(accepted: boolean) {
    this.termsOfConditionsAccepted = accepted;
    StorageService.setProperty<LegalData>(ALLOWED_KEYS.LEGAL_DATA, 'termsOfConditionsAccepted', this.termsOfConditionsAccepted);
  }

  onChangeRightOfWithdrawalAccepted(accepted: boolean) {
    this.rightOfWithdrawalAccepted = accepted;
    StorageService.setProperty<LegalData>(ALLOWED_KEYS.LEGAL_DATA, 'rightOfWithdrawalAccepted', this.rightOfWithdrawalAccepted);
  }

  submit() {
    this.validateCheckboxes = true;

    if (this.termsOfConditionsAccepted && this.rightOfWithdrawalAccepted) {
      const meterData: HomeData = StorageService.getValue(ALLOWED_KEYS.RECENT_HOME_DATA);
      const paymentData: ContractPaymentData = StorageService.getValue(ALLOWED_KEYS.PAYMENT_DATA);

      const meters: Array<MetersCheckResult> = [];
      meterData.meterData.forEach((meterReading: Zaehlerstand) => {
        meters.push({
          divisionId: meterReading.sparte,
          meterNumber: meterReading.meterNumber,
          exists: true,
          isSmartMeter: meterData.meters.find(meter => meter.meterNumber === meterReading.meterNumber).isSmartMeter,
          register: meterReading.zaehlwerkeListe
        });
      });

      const contractData: Contract = {
        personalData: {
          title: this.billingAddress.newRecipientTitle ? this.billingAddress.newRecipientTitle : this.billingAddress.recentTitleId,
          name: this.billingAddress.newRecipientGivenName ? this.billingAddress.newRecipientGivenName : this.billingAddress.recentRecipientPersons?.[0]?.firstname,
          surname: this.billingAddress.newRecipientSurname ? this.billingAddress.newRecipientSurname : this.billingAddress.recentRecipientPersons?.[0]?.lastname,
          name1: '',
          name2: '',
          email: this.billingAddress.email,
          addressData: {
            street: this.billingAddress.streetName,
            housenumber: this.billingAddress.houseNum,
            postCode: this.billingAddress.postCode,
            city: this.billingAddress.cityName
          }
        },
        situationData: {
          accountID: this.accountId,
          endDate: meterData.date,
          deliveryType: 'MOVE_OUT',
          meterData: meters
        },
        paymentData: paymentData,
        tariffData: [{
          mode: this.billingAddress.partnerKind
        }]
      };
      this.state = LOADING_STATE.LOADING;
      this.apiService.postContractEnd(contractData).subscribe(
        {
          next: (res) => {
            this.state = LOADING_STATE.IDLE;
            this.router.navigate(['../../danke'], { relativeTo: this.activatedRoute });
            this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_END_SUMMARY, TRACKING.ACTION.SUCCESS);
          },
          error: () => {
            this.state = LOADING_STATE.ERROR;
            this.trackingService.postTracking(TRACKING.LOCATION.CONTRACT_END_SUMMARY, TRACKING.ACTION.FAILURE);
          }
        });
    }
  }
}
