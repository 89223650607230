import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TenantService } from '../../../services/tenant.service';

@Component({
  selector: 'bdo-denial',
  templateUrl: './denial.component.html',
  styleUrls: ['./denial.component.scss']
})
export class DenialComponent implements OnInit {

  constructor(
    public tenantService: TenantService,
    private locationStrategy: LocationStrategy,
  ) { }

  ngOnInit(): void {
    window.sessionStorage.clear();
    this.disableBrowsersBackButton();
  }

  private disableBrowsersBackButton() {
    history.pushState(null, null, window.location.href);
    this.locationStrategy.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

}
