<h3 class="bdo-container re-mb-30 md:re-mb-45">{{ 'contract.cancel.selection.title' | translate }}</h3>

<p class="re-container-with-margin re-mb-30 re-text-sm re-leading-m md:re-text-base md:re-leading-2xl">
  {{ 'contract.cancel.selection.description' | translate }}
</p>

<div class="bdo-container--single">
  <div [class.re-mb-15]="state === LoadingState.ERROR">
    <bdo-info-box class="bdo-container--single" *ngIf="state === LoadingState.ERROR" data-qa="error">
      {{ 'general.error.error' | translate }}
    </bdo-info-box>
  </div>
</div>

<!-- selection of accountId -->
<div class="re-px-20 re-py-15 re-w-full re-bg-white re-relative re-mb-45
            md:re-px-30 md:re-py-20 md:re-w-600 md:re-mx-auto lg:re-mb-90
            lg:re-w-900">
  <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
  <div *ngIf="selectedAccount">
    <bdo-accountid-with-address
      [accountId]="selectedAccount.accountId"
      [address]="Utilities.nestAddress(selectedAddress)"
    ></bdo-accountid-with-address>
  </div>
</div>



<!-- show contracts -->
<bdo-contract-details
  [accountId]="selectedAccount?.accountId"
  [meterNumber]="authMeterNumber"
  [cancelMode]="true"
  [address]="selectedAddress"
></bdo-contract-details>

