import { noop, throttle, requestIdleCallback } from '@datadog/browser-core';
/**
 * Maximum duration to wait before processing mutations. If the browser is idle, mutations will be
 * processed more quickly. If the browser is busy executing small tasks (ex: rendering frames), the
 * mutations will wait MUTATION_PROCESS_MAX_DELAY milliseconds before being processed. If the
 * browser is busy executing a longer task, mutations will be processed after this task.
 */
var MUTATION_PROCESS_MAX_DELAY = 100;
/**
 * Minimum duration to wait before processing mutations. This is used to batch mutations together
 * and be able to deduplicate them to save processing time and bandwidth.
 * 16ms is the duration of a frame at 60fps that ensure fluid UI.
 */
export var MUTATION_PROCESS_MIN_DELAY = 16;
export function createMutationBatch(processMutationBatch) {
  var cancelScheduledFlush = noop;
  var pendingMutations = [];
  function flush() {
    cancelScheduledFlush();
    processMutationBatch(pendingMutations);
    pendingMutations = [];
  }
  var _a = throttle(flush, MUTATION_PROCESS_MIN_DELAY, {
      leading: false
    }),
    throttledFlush = _a.throttled,
    cancelThrottle = _a.cancel;
  return {
    addMutations: function (mutations) {
      if (pendingMutations.length === 0) {
        cancelScheduledFlush = requestIdleCallback(throttledFlush, {
          timeout: MUTATION_PROCESS_MAX_DELAY
        });
      }
      pendingMutations.push.apply(pendingMutations, mutations);
    },
    flush: flush,
    stop: function () {
      cancelScheduledFlush();
      cancelThrottle();
    }
  };
}
