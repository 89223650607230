<div class="re-container-headline re-mb-30 md:re-mb-45 lg:re-mb-60" *ngIf="title">
  <h3>
    {{title}}
  </h3>
</div>

<div data-qa="gewe-info" class="re-bg-white re-w-full re-p-20 re-pt-30 re-mb-30
              md:re-w-600 md:re-p-45 md:re-pt-45 md:re-pb-45 md:re-mb-45 md:re-mx-auto
              lg:re-w-900">

  <div class="re-mb-15 md:re-mb-30">
    <h4 *ngIf="boxTitle">
      {{ boxTitle }}
    </h4>
  </div>
  <div class="lg:re-text-base lg:re-leading-2xl" *ngIf="infoText">
    <p [innerHTML]="infoText"></p>
  </div>
</div>

<div *ngIf="linkData"
  bdoRouterLinkDirective
  class="re-w-full re-px-20 md:re-w-600 md:re-px-0 md:re-mx-auto lg:re-w-900">
  <bdo-text-link-icon
    [href]="linkData?.href"
    [linkText]="linkData?.linkName"
    [iconPosition]="'left'"
  ></bdo-text-link-icon>
</div>
