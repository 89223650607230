import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BillingPlan, BillingPlanItem, PaymentData } from '../../../assets/js/com/ts_api_client';
import { VERBRAUCHSTYP } from '../enums/verbrauchstyp.enum';
import { BdoApiService } from './bdo-api.service';

export interface DetailedBillingPlanItem extends BillingPlanItem {
  division: VERBRAUCHSTYP; // fix, weil das Rückgabeobjekt vom Typ string ist, es aber eigentlich einen Verbrauchstyp darstellt
}

export interface DetailedBillingPlan extends BillingPlan {
  billingPlanCycle: BillingPlanCycleId;
  billingPlanCycleText: string;
  billingPlanItemSet: Array<DetailedBillingPlanItem>;
  nextBillingPlanPayments: PaymentData[];
}

export type BillingPlanCycleId = '00' | '01' | '02' | '03' | '04' | '06' | '12';

export const  BillingPlanCyclesMap = new Map<BillingPlanCycleId, string>(
  [
    ['00',  'Keine Abschläge'],
    ['01',  'Monatlich'],
    ['02',  'Alle 2 Monate'],
    ['03',  'Quartalsweise'],
    ['04',  'Alle 4 Monate'],
    ['06',  'Halbjährlich'],
    ['12',  'Jährlich']
  ]
);

const ABSCHLAGSFORDERUNG_TITLE = 'Abschlagsforderung';

@Injectable({
  providedIn: 'root'
})
export class DetailedBillingPlanService {

  constructor(private bdoApiService: BdoApiService) { }

  /**
   * maps the backendRequest to the corresponding Enums and aggregates payments and billingPlan
   * @returns {Observable<DetailedBillingPlan>}
   */
  public getDetailedBillingPlan(): Observable<DetailedBillingPlan> {
    return forkJoin([
      this.bdoApiService.getBillingPlan(),
      this.bdoApiService.getPayments()
    ]).pipe(
      map(([billingPlan, payments]) => {
        const billingPlanItemsByDivision: Array<BillingPlanItem> = [];
        billingPlan.billingPlanItemSet.map((item: BillingPlanItem) => {
          const billingItemToDivision = billingPlanItemsByDivision.find((searchItem) => searchItem.division == item.division);
          if (billingItemToDivision) {
            billingItemToDivision.amount += item.amount;
          } else {
            billingPlanItemsByDivision.push(item);
          }
        });
        return {
          ...billingPlan,
          billingPlanCycle: billingPlan.billingPlanCycle as BillingPlanCycleId,
          billingPlanCycleText: BillingPlanCyclesMap.get(billingPlan.billingPlanCycle as BillingPlanCycleId),
          billingPlanItemSet: billingPlanItemsByDivision.map((item) => ({ ...item, division: item.division as VERBRAUCHSTYP })),
          nextBillingPlanPayments: payments.futureReceivables.payments
            .filter(payment => payment.title === ABSCHLAGSFORDERUNG_TITLE)
            .slice(0, 2)
        };
      })
    );
  }
}
