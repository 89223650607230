<h5 class="re-mb-20 md:re-mb-30">
  {{ 'contract.cancel.canceldate' | translate }}
</h5>

<div class="re-summary-row">
  <div>{{ 'contract.cancel.summary.canceldate' | translate }}</div>
  <div [attr.data-qa]="'canceldate'">{{homeData?.date | date}}</div>
</div>

<div *ngFor="let meter of meterReadings">
  <h5 class="re-mb-30 re-mt-45">
    {{ 'general.customerData.yourMeterWithDivision' | translate: { division: meter?.meterReading?.sparte | verbrauchstyp } }}
  </h5>
  <div class="re-summary-row">
    <div>{{ 'general.customerData.meternumber' | translate }}</div>
    <div>{{meter?.meterReading?.meterNumber}}</div>
  </div>
  <ng-container *ngIf="meter?.meterReading?.zaehlwerkeListe?.length">
    <div class="re-summary-row" *ngFor="let register of meter?.meterReading?.zaehlwerkeListe">
      <div>{{ 'meterreadings.meterreading' | translate }} {{ ['HT', 'NT'].includes(register.typ) ? ('' | zaehlwerktyp: register.typ) : '' }}</div>
      <div>{{ register.wert ? register.wert + ' ' + [register.einheit | unitPretty] :
        (meter.isSmartMeter ? 'meterreadings.smartmeter.readingInfoShort' : 'meterreadings.info.enterLaterShort') | translate }}
      </div>
    </div>
  </ng-container>
</div>
