<h3 class="bdo-container re-mb-30 md:re-mb-45">{{ 'contract.cancel.address.title' | translate }}</h3>

<p class="re-container-with-margin re-mb-30 re-text-sm re-leading-m md:re-text-base md:re-leading-2xl">
  {{ 'contract.cancel.address.description' | translate }}
</p>

<div class="bdo-container--single">
  <div [class.re-mb-15]="state === LoadingState.ERROR">
    <bdo-info-box *ngIf="state === LoadingState.ERROR" class="bdo-container--single" data-qa="error">
      {{ 'general.error.error' | translate }}
    </bdo-info-box>
  </div>
</div>

<form
    [formGroup]="form"
    (ngSubmit)="save()"
    (keydown.enter)="$event.preventDefault()"
    bdoScrollToInvalidControl
    [bdoScrollToInvalidControlForm]="form">
  <div class="re-bg-white re-w-full re-px-20 re-py-30 re-text re-mb-75 md:re-w-600 md:re-mx-auto md:re-pb-60 md:re-mb-90 lg:re-w-900">
    <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>

    <fieldset class="re-mb-0">
      <div class="re-pb-15 md:re-table-row">
        <div class="md:re-table-cell md:re-pb-15 md:re-pr-40">{{ 'general.customerData.account' | translate }}</div>
        <div class="bold md:re-table-cell">{{accountId}}</div>
      </div>

      <div class="re-pb-15 md:re-table-row">
        <div class="md:re-table-cell md:re-pb-15">{{ 'address.personalInfo.name' | translate }}</div>
        <div class="bold md:re-table-cell">{{firstname}} {{surname}}</div>
      </div>

      <div class="re-pb-15 md:re-table-row">
        <div class="md:re-table-cell md:re-pb-15">{{ 'address.street.title' | translate }}</div>
        <div class="bold md:re-table-cell">{{address?.street}} {{address?.housenumber}}</div>
      </div>

      <div class="re-pb-15 md:re-table-row">
        <div class="md:re-table-cell md:re-pb-15">{{ 'address.postCode.postCodeAndCity' | translate }}</div>
        <div class="bold md:re-table-cell">{{address?.postCode}} {{address?.city}}</div>
      </div>

      <div class="re-form-item-container re-mt-45" data-qa="input-emailAddress">
        <bdo-input
          [id]="'emailAddress'"
          [formControl]="$any(form.get('emailAddress'))"
          [placeholder]="'address.email.title' | translate"
        ></bdo-input>
      </div>
    </fieldset>
  </div>

  <div class="bdo-container--single re-mt-45 md:re-mt-60">
    <bdo-validate-submit
      [textButtonLong]="'wizard.button.summary' | translate"
    ></bdo-validate-submit>
  </div>
</form>

<div class="bdo-container--single re-mt-20">
  <bdo-text-link-icon
    [iconPosition]="'left'"
    [linkText]="'general.action.back' | translate"
    [routerUrl]="'../vertragsende'"
  ></bdo-text-link-icon>
</div>
