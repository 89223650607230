import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'bdo-more-button',
  templateUrl: './more-button.component.html',
  styleUrls: ['./more-button.component.scss']
})
export class MoreButtonComponent {
  @Input() label: string;
  @Output() moreEvent: EventEmitter<boolean> = new EventEmitter();

  onMoreClicked() {
    this.moreEvent.emit();
  }

}
