import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../../../assets/js/com/ts_api_client';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {

  transform(value: Address, ...args: any[]): any {
    if (!value) {
      return '';
    }
    // conditional wrap on desktop to always let the ort appear in  next line if the street is too long (request of Tatjana for Ticket KIS-1856)
    return `<span class="re-whitespace-normal md:re-whitespace-nowrap">${value.street?.name} ${value?.houseNum},</span> <br class="mobile-only" /> <span>${value.street.city?.postCode} ${value.street.city?.name}</span>`;
  }
}
