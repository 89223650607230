import { Component, OnInit, Input, ElementRef, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { SlideInAnimation } from '../../animations/slide-in.animation';
import { SlideOutAnimation } from '../../animations/slide-out.animation';

@Component({
  selector: 'bdo-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
  animations: [ SlideInAnimation, SlideOutAnimation ]
})
export class InfoBoxComponent implements OnInit, OnChanges {
  @Input() type: 'error' | 'warning' | 'success' | 'info' | 'hint' = 'error';
  @Input() variant: 'default' | 'toast' = 'default';
  @Input() marginBottom: boolean = true;
  @Input() headline: string;
  @Input() linkText: string;
  @Input() destination: string;
  public iconSrc: string = 'assets/icons/system_icons/alert.svg';

  constructor(private element: ElementRef) { }

  ngOnInit() {
    // scroll directly to item
    if (this.variant !== 'toast' && this.type === 'error') {
      this.element.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    switch (this.type) {
      case 'error':
        this.iconSrc = 'assets/icons/system_icons/error_30x30.svg';
        break;

      case 'warning':
        this.iconSrc = 'assets/icons/system_icons/alert.svg';
        break;

      case 'success':
        this.iconSrc = 'assets/icons/system_icons/ok-circle.svg';
        break;

      case 'info':
      case 'hint':
        this.iconSrc = 'assets/icons/system_icons/info_30x30.svg';
        break;
    }
  }
}
