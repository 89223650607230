<div class="re-container-headline bdo-space--bottom">
	<h3>
		{{ 'contract.end.status.title' | translate }}
  </h3>
</div>

<div class="re-container-with-margin">
  <div class="re-mb-30 re-mt-30 md:re-mb-45 md:re-mt-45 re-copytext">
    {{'contract.end.status.description' | translate }}
  </div>
</div>

  <div class="bdo-link-tile-group re-mb-20">
    <bdo-link-tile
      [title]="'contract.end.status.toEdit.title' | translate"
      [linktext]="'contract.end.status.toEdit.link' | translate"
      [iconSrc]="{default: 'assets/icons/change_75x75.svg'}"
      [description]="'contract.end.status.toEdit.description' | translate"
      [destination]="'/vertrag/' + accountId"
      [trackingLocation]="TRACKING.LOCATION.CONTRACT_END_OVERVIEW"
      [trackingDestination]="TRACKING.LOCATION.CONTRACT_OVERVIEW"
    ></bdo-link-tile>

    <bdo-link-tile
      [title]="'contract.end.status.toMove.title' | translate"
      [linktext]="'contract.end.status.toMove.link' | translate"
      [iconSrc]="{default: 'assets/icons/location-transport_75x75.svg'}"
      [description]="'contract.end.status.toMove.description' | translate"
      [destination]="'/umzug/' + accountId + '/neu/bisheriges-zuhause'"
      [trackingLocation]="TRACKING.LOCATION.CONTRACT_END_OVERVIEW"
      [trackingDestination]="TRACKING.LOCATION.MOVE_RECENTHOME"
    ></bdo-link-tile>
  </div>

  <div class="re-container-with-margin re-mb-45">
    <bdo-hint-tile
      [currentHintTileConfig]="hintTileConfig"
    ></bdo-hint-tile>
  </div>

  <div class="re-container-with-margin">
    <div [innerHTML]="'contract.end.status.helpCenter' | translate:
                     { linkHelpCenter: tenantService.getCurrentTenantData().hrefHelpCenterCancel }"></div>
  </div>
