import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { VERBRAUCHSTYP } from '../..//bdo/enums/verbrauchstyp.enum';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[bdoZaehlerstandValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ZaehlerstandValidatorDirective, multi: true }]
})
export class ZaehlerstandValidatorDirective implements Validator {

  @Input('bdoZaehlerstandValidator') sparte: string;

  constructor(
    private translateService: TranslateService
  ) { }

  validate(control: AbstractControl): { [key: string]: any } {
    // this.sparte = this.sparte.toLowerCase();
    let isValid = true;
    let errorMessage = '';
    if (!this.sparte) { return null; }

    if (control.value) {
      const value = control.value.toString().replace('.', ','); // in case decimal dot comes through
      const inputSplitOnComma = value.split(',');
      const preCommaDigits = inputSplitOnComma[0];
      const postCommaDigits = inputSplitOnComma[1];

      if (inputSplitOnComma.length > 2) {
        isValid = false;
        errorMessage = this.translateService.instant('meterreadings.validator.onlyOneComma');
      } else if (preCommaDigits.length > 10) {
        isValid = false;
        errorMessage = this.translateService.instant('meterreadings.validator.tooManyPreDecimalPlaces');
      } else if (!(this.sparte.includes(VERBRAUCHSTYP.Fernwärme) || (this.sparte.includes(VERBRAUCHSTYP.Nahwärme))) && postCommaDigits) {
        isValid = false;
        errorMessage = this.translateService.instant('meterreadings.validator.noDecimalPlaces');
      } else if (
        (this.sparte.includes(VERBRAUCHSTYP.Fernwärme) || (
          this.sparte.includes(VERBRAUCHSTYP.Nahwärme))) &&
          postCommaDigits && postCommaDigits.length > 3
        ) {
        isValid = false;
        errorMessage = this.translateService.instant('meterreadings.validator.tooManyDecimalPlaces');
      }
    }

    return isValid ? null : { 'zaehlerstand': errorMessage };
  }
}
