import { Component, Input } from '@angular/core';
import { passwordPattern } from '../../validators/password-validator';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss']
})
export class PasswordStrengthComponent {
  @Input() passwordNew: string = '';
  amountOfPWStrengths: number = 4;

  constructor(
    private translateService: TranslateService
  ) { }

  /**
   * Calculate the Password Strength
   * @author: Martin Melcher */
  getPasswordStrength(pw: string): number {
    if (!pw || !passwordPattern.test(pw)) { return 0; }

    let rating: number = 0;

    // verwendete Zeichenklassen ermitteln
    let lower = false;
    let upper = false;
    let num = false;
    let other = false;

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < pw.length; i++) {
      if (pw[i].match(/[0-9]/)) {
        num = true;
        continue;
      }
      if (pw[i].match(/[a-z]/)) {
        lower = true;
        continue;
      }
      if (pw[i].match(/[A-Z]/)) {
        upper = true;
        continue;
      }
      other = true;
    }

    // Zeichenvorrat ermitteln
    let cpx = 1;
    if (lower) {
      cpx = cpx * 26;
    }
    if (upper) {
      cpx = cpx * 26;
    }
    if (num) {
      cpx = cpx * 10;
    }
    if (other) {
      cpx = cpx * 15;
    }
    //
    cpx = Math.log(Math.pow(cpx, pw.length)) / Math.log(10);

    // bar.css('width', 5+95*(cpx/80) + '%').css('max-width', '100%');
    if (pw.length < 6) {
      rating = 0;
    } else if (cpx < 25) {
      rating = 1;
    } else if (cpx < 40) {
      rating = 2;
    } else if (cpx < 60) {
      rating = 3;
    } else {
      rating = 4;
    }

    return rating;
  }

  getPasswordStrengthText(password: string): string {
    const ratings = [
      this.translateService.instant('password.strength.rating.0'),
      this.translateService.instant('password.strength.rating.1'),
      this.translateService.instant('password.strength.rating.2'),
      this.translateService.instant('password.strength.rating.3'),
      this.translateService.instant('password.strength.rating.4')
    ];
    const ratingNumber = this.getPasswordStrength(password);
    if (!passwordPattern.test(password)) {
      return this.translateService.instant('password.strength.help');
    } else if (ratingNumber > ratings.length) {
      return '';
    } else {
      return this.translateService.instant('password.strength.title') + ` ${ratings[ratingNumber]}`;
    }
  }

}
