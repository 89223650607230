import { Directive, Input, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { FormValidationError } from '../../bdo/models/formValidationError';

/** Given an array of Possible Errors and corresponding messages, returns the first validated error found */
@Directive({
  selector: '[bdoDefaultValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: DefaultValidatorDirective, multi: true }]
})
export class DefaultValidatorDirective implements Validator, OnChanges {

  @Input('bdoDefaultValidator') errors: Array<FormValidationError>;
  private _onChange: () => void;

  constructor() { }

  validate(control: AbstractControl): { [key: string]: any } {
    for (const element of this.errors) {
      if (element.condition) {
        return { [element.name]: element.text };
      }
    }

    return null;
  }

  registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }

  /** Handling the updates of the error conditions that are triggered by the Input Change */
  ngOnChanges(changes: SimpleChanges): void {
    // we are using setTimeout() to defer this code to another Javascript Virtual Machine turn, we are not specifying a value for the timeout.
    setTimeout(() => {
      if ('errors' in changes) {
        if (this._onChange) {
          this._onChange();
        }
      }
    });
  }
}
