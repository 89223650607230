import { Pipe, PipeTransform } from '@angular/core';
import { VERBRAUCHSTYP } from '../enums/verbrauchstyp.enum';

@Pipe({
  name: 'divisionToUnit'
})
/**
 * Maps the divisionId to the correct unit depending on the context
 *
 * "Bei Abrechnung gilt:
 * Grundlage für die Abrechnung ist der Verbrauch in Kilowattstunden (kWh).
 * Für die Umrechnung der von der Messeinrichtung erfassten Kubikmeter (m3 ) in die für die Abrechnung relevanten kWh
 * werden vom jeweiligen örtlichen Verteilnetzbetreiber basierend auf den Betriebsbedingungen entsprechende Umrechnungsfaktoren gebildet.
 *
 * Bei Zählerständen etc. gilt m³"
 */
export class DivisionToUnitPipe implements PipeTransform {

  transform(value: string, context: string): string {
    if (context === 'billing') {
      return this.mapDivisionForBilling(value);
    } else {
      return this.mapDivision(value);
    }
  }

  mapDivision(key: string) {
    switch (key) {
      case VERBRAUCHSTYP.Strom:
      case VERBRAUCHSTYP.Nahwärme:
      case VERBRAUCHSTYP.Fernwärme:
      case VERBRAUCHSTYP.Einspeiser:
        return 'kWh';
      case VERBRAUCHSTYP.Gas:
      case VERBRAUCHSTYP.Wasser:
      case VERBRAUCHSTYP.Abwasser:
        return 'm³';
      case VERBRAUCHSTYP.Übergreifend:
        return '';
      default:
        return '';
    }
  }

  mapDivisionForBilling(key: string) {
    switch (key) {
      case VERBRAUCHSTYP.Gas:
      case VERBRAUCHSTYP.Strom:
      case VERBRAUCHSTYP.Nahwärme:
      case VERBRAUCHSTYP.Fernwärme:
      case VERBRAUCHSTYP.Einspeiser:
        return 'kWh';
      case VERBRAUCHSTYP.Wasser:
      case VERBRAUCHSTYP.Abwasser:
        return 'm³';
      case VERBRAUCHSTYP.Übergreifend:
        return '';
      default:
        return '';
    }
  }

}
