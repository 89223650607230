import {
  Directive,
  HostListener,
  ElementRef,
  Input, Optional
} from '@angular/core';
import { UntypedFormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

@Directive({
  selector: '[bdoScrollToInvalidControl]'
})
export class ScrollToInvalidControlDirective {

  // fallback for non-reactive forms or forms, where the formGroup cannot be set globally due to sub-forms
  @Input() bdoScrollToInvalidControlForm: NgForm | UntypedFormGroup;

  constructor(
    private el: ElementRef,
    @Optional()
    private formGroup: FormGroupDirective
  ) {
  }

  /**
   * listen to the ngSubmit event of the form
   */
  @HostListener('ngSubmit') onSubmit() {
    if (this.formGroup?.control?.invalid || this.bdoScrollToInvalidControlForm?.invalid) {
      this.scrollToFirstInvalidControl();
    }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      'input.ng-invalid, p-calendar.ng-invalid, select.ng-invalid'
    );
    fromEvent(window, 'scroll')
      .pipe(
        debounceTime(100),
        take(1)
      )
      // focus the first element for accessibility
      .subscribe({ next: () => {
        window.setTimeout(() => {
          firstInvalidControl.focus();
        }, 100);
      } });

    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: 'smooth'
    });

  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }
}
