import { animate, state, style, transition, trigger } from '@angular/animations';

export const FadeInAnimation = [
  trigger('fadeIn', [
    state('in', style({ opacity: 1 })),
    state('out', style({ opacity: 0 })),
    transition(':enter', [
      style({ opacity: 0 }),
      animate(600)
    ])
  ])
];
