<ng-container *ngIf="!isInEditMode">
  <div class="re-bg-white re-w-full re-text re-flex re-p-20 re-pr-100 re-h-80 re-items-center re-relative
          md:re-w-600 md:re-mx-auto md:re-px-30
          lg:re-w-900">
    <div class="re-pt-3 bold md:re-text-base re-flex re-flex-col md:re-flex-row">
      <div>PLZ: {{postCode}}&nbsp;| {{consumption | number}}&nbsp;{{divisionId | divisionToUnit: 'billing'}}
      </div>
    </div>
    <button class="re-absolute re-right-0 re-top-0 re-w-80 re-h-80 re-primary re-button"
            [title]="'general.imgText.edit' | translate"
            data-qa="edit-desktop"
            (click)="onEdit()">
      <div class="re-h-30 re-w-30 re-mx-auto">
        <svg-icon src="{{ 'general.img.edit' | translate}}"></svg-icon>
      </div>
    </button>
  </div>
</ng-container>

<ng-container *ngIf="isInEditMode">
  <div class="re-bg-white re-w-full re-text re-flex re-p-20 re-flex-col
            md:re-w-600 md:re-mx-auto
            lg:re-w-900" [@fadeIn]>
    <div class="re-pt-3">{{ 'address.postCode.yourPostCode' | translate }} <span class="bold">{{ postCode }}</span></div>
  </div>
  <div class="re-bg-white re-w-full re-p-20 re-pb-10 re-text
            md:re-w-600 md:re-mx-auto
            re-border-monochrome-light re-border-solid re-border-t-3
            lg:re-w-900" [@fadeIn]>
    <fieldset class="md:re-mt-20 re-p-0">
      <div class="re-form-item-container re-mb-15">
        <fieldset class="amount-container amount-container--persons re-mb-0" [formGroup]="consumptionForm">

          <ng-container>
            <p class="re-text-sm re-leading-normal re-mb-5">
              <span>{{ 'tariffAdvisor.consumption.title.' + divisionId | translate }}</span>
            </p>
            <div class="amount-grid-container">
              <div class="amount-container__item" *ngFor="let i of steps" >
                <input type="radio" id="persons-{{i.step}}" name="rc-radio" value="{{i.step}}" [formControl]="$any(consumptionForm.controls.selectedStep)">
                <label for="persons-{{i.step}}" class="re-leading-normal">{{i.step}}</label>
              </div>
            </div>
          </ng-container>
        </fieldset>
      </div>
      <div class="re-form-item-container re-mb-30 re-pr-30 md:re-pr-0">
        <bdo-input labelText="{{ 'tariffAdvisor.consumption.yearlyConsumption' | translate: { unit: divisionId | divisionToUnit: 'billing' } }}"
                   [formControl]="$any(consumptionForm.controls.consumption)"
                   [help]="hasMultipleMeters ? ('tariffAdvisor.consumption.hintMultipleMeters' | translate) : ''"
        ></bdo-input>
      </div>
      <button class="re-button re-relative re-primary re-border-none" (click)="onCalculate()" data-qa="tariff-calculate-button">
        <span>{{ 'tariffAdvisor.consumption.calculate' | translate }}</span>
      </button>
    </fieldset>

  </div>
</ng-container>
