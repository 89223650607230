import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ContactMessage } from '../../../../assets/js/com/ts_api_client';
import { LOADING_STATE } from '../../enums/loadingState.enum';
import { DropdownItem } from '../../models/dropdownItem';
import { BdoApiService } from '../../services/bdo-api.service';
import { TRACKING } from '../../enums/trackingParts.enum';
import { TenantService } from '../../services/tenant.service';
import { TrackingService } from '../../services/tracking.service';
import { ActivatedRoute } from '@angular/router';
import { CustomerStoreService } from '../../services/customer-store.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'bdo-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  public state: LOADING_STATE = LOADING_STATE.IDLE;
  public LoadingState = LOADING_STATE;
  public validateAll = false;
  public TRACKING = TRACKING;
  public messageDefaultState: ContactMessage = {
    topic: '',
    message: null,
    subject: '',
    accountId: ''
  };
  public message: ContactMessage = { ...this.messageDefaultState };
  public reasons: DropdownItem[] = ['0000', '0010', '0020', '0030', '0040', '0050', '0060', '0070', '0080'].map((value) => {
    return {
      value,
      label: this.translateService.instant(`contact.select.option${value}`)
    };
  });
  public showPopup: boolean;
  private subscriptions = new Subscription();

  constructor(
    public route: ActivatedRoute,
    public tenantService: TenantService,
    private translateService: TranslateService,
    private customerStoreService: CustomerStoreService,
    private apiService: BdoApiService,
    private trackingService: TrackingService
  ) {}

  public onClosePopup() {
    this.showPopup = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTACT, TRACKING.ACTION.ENTER);
    this.message.accountId = this.customerStoreService.getAccountId();

    this.subscriptions.add(this.translateService.onLangChange.subscribe({
      next: () => {
        this.reasons = ['0000', '0010', '0020', '0030', '0040', '0050', '0060', '0070', '0080'].map((value) => {
          return {
            value,
            label: this.translateService.instant(`contact.select.option${value}`)
          };
        });
      }
    }) );

    // Prefilling topic if query param exists
    this.subscriptions.add(this.route.queryParamMap.subscribe(
      { next: res => {
        const reason = res.get('reason');
        if (reason) {
          this.message.topic = reason;
        }
      } }
    ));
  }

  public submit(form: NgForm) {
    // dropdown validation is currently not possible, maybe the same bug, that selected element cannot be changed from outside
    if (form.valid && !!this.message.topic) {
      this.validateAll = false;
      this.state = LOADING_STATE.LOADING;
      this.apiService.postContactMessage(this.message).subscribe({
        error: this.onError.bind(this),
        next: this.onSuccess.bind(this)
      });
    } else {
      this.validateAll = true;
    }
  }

  private onError() {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTACT, TRACKING.ACTION.ERROR);
    this.state = LOADING_STATE.ERROR;
  }

  private onSuccess() {
    this.trackingService.postTracking(TRACKING.LOCATION.CONTACT, TRACKING.ACTION.SUCCESS);
    this.showPopup = true;
    this.state = LOADING_STATE.IDLE;
    this.message.topic = null;
    this.message.subject = '';
    this.message.message = '';
  }

}
