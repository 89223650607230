import { Component, Input, OnInit } from '@angular/core';
import { Vouchers } from '../../../../assets/js/com/ts_api_client';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'bdo-wishvoucher',
  templateUrl: './wishvoucher.component.html',
  styleUrls: ['./wishvoucher.component.scss']
})
export class WishvoucherComponent implements OnInit{
  @Input() vouchers: Vouchers;

  ngOnInit(): void {
    if (!this.vouchers) {
      this.vouchers = StorageService.getOfferVouchers();
    }
  };

}
