import { VERBRAUCHSTYP } from '../enums/verbrauchstyp.enum';
import moment from 'moment';

export interface PriceCapValues {
  division: string;
  priceBrutto: number;
  priceNetto: number;
}

export const priceCapValidUntil = moment('2024-01-01T00:00:00+01:00').toDate();
export const priceCapValues: Array<PriceCapValues> = [
  { division: VERBRAUCHSTYP.Strom, priceBrutto: 40, priceNetto: 33.61 },
  { division: VERBRAUCHSTYP.Gas, priceBrutto: 12, priceNetto: 10.08 },
  { division: VERBRAUCHSTYP.Fernwärme, priceBrutto: 9.5, priceNetto: 7.98 },
  { division: VERBRAUCHSTYP.Nahwärme, priceBrutto: 9.5, priceNetto: 7.98 }
];
