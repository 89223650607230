import { Component, OnInit, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { Alert } from '../../../../../assets/js/com/ts_api_client/model/alert';
import { ALERT_TEXT, ALERT_TYPE } from '../../../../bdo/enums/alertType.enum';
import { BdoApiService } from '../../../services/bdo-api.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { TrackingService } from '../../../services/tracking.service';
import { HintTileConfig } from '../../../../shared/atoms/hint-tile/hint-tile.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bdo-alert-tile',
  templateUrl: './alert-tile.component.html',
  styleUrls: ['./alert-tile.component.scss']
})
export class AlertTileComponent implements OnInit, OnChanges, OnDestroy {
  @Input() alert: Alert = {};
  @Input() accountId: string;
  public TRACKING = TRACKING;
  public routerUrl: string;
  public newTab = false;
  public currentTileConfig: HintTileConfig;
  private alertText: string = '';
  private linkText: string;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private trackingService: TrackingService,
    private apiService: BdoApiService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.alertText = this.getAlertText(this.alert.id);
    this.setLinkAttributes(this.alert.id);
    this.subscriptions.add(this.translateService.onLangChange.subscribe({
      next: () => {
        this.setLinkAttributes(this.alert.id);
      }
    }) );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alert && changes.alert.currentValue) {
      this.alertText = this.getAlertText(this.alert.id);
      this.setLinkAttributes(this.alert.id);
    }
  }

  ngOnDestroy(): void {
      this.subscriptions.unsubscribe();
  }

  getAlertText(alertId: string): string {
    if (!alertId || !ALERT_TEXT[alertId]) {
      return '';
    }
    return ALERT_TEXT[alertId];
  }

  setLinkAttributes(alertId: string) {
    this.newTab = false;
    switch (alertId) {
      case ALERT_TYPE.DEMAND_OVERDUE:
      case ALERT_TYPE.PAYMENT_REMINDER:
        this.routerUrl = '/zahlungen/' + this.accountId;
        this.linkText = this.translateService.instant('dashboard.alertTile.link.payment');
        break;
      case ALERT_TYPE.MISSING_MAIL_ADDRESS:
        this.routerUrl = '/daten/' + this.accountId;
        this.linkText = this.translateService.instant('dashboard.alertTile.link.data');
        break;
      case ALERT_TYPE.MISSING_SEPA:
        this.routerUrl = '/daten/' + this.accountId;
        this.linkText = this.translateService.instant('dashboard.alertTile.link.banking');
        break;
      case ALERT_TYPE.TOS_CHANGED:
        this.routerUrl = '/nutzungsbedingungen';
        this.linkText = this.translateService.instant('dashboard.alertTile.link.details');
        this.newTab = true;
        break;
      default:
        break;
    }
    this.currentTileConfig = {
      borderTopColor: 'red',
      iconPath: 'assets/icons/bell.svg',
      iconWithBadge: true,
      text: this.alertText,
      linkType: 'texticon',
      linkText: this.linkText,
      linkPath: '',
      isDashboardTile: true
    };
  }

  inspectAlert(alert: Alert) {
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.DASHBOARD, TRACKING.ACTION.GOTO + ' ' + TRACKING.LOCATION.ALERT + ' ' + alert.id);
    this.apiService.postAlert(alert).subscribe();
  }

}
