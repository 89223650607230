import moment from 'moment';
import { Environment } from '../../../environments/environment';
import { TENANT } from '../enums/tenant.enum';
import { Inject, Injectable } from '@angular/core';
import { Competition } from '../models/competition';
import { COMPETITION_CONFIGURATION_TOKEN } from '../configurations/competition.configuration.token';
@Injectable({
  providedIn: 'root'
})
export class CompetitionHelperService {

  constructor(@Inject(COMPETITION_CONFIGURATION_TOKEN) public competition: Competition) {
  }
  public isActive() {
    const now = moment();
    return now.isAfter(moment(this.competition.startDate)) && now.isBefore(moment(this.competition.endDate)) && Environment.tenant === TENANT.Rheinenergie;
  }

  public getActiveCompetitionId() {
    return this.competition?.competitionId;
  }
}

