<div class="bdo-container bdo-space--bottom">
  <h3>
    {{ 'contact.title' | translate }}
  </h3>
</div>

<div class="bdo-container--single">

  <div class="re-copytext-with-margin bdo-space--bottom"
       [innerHtml]="'contact.description' | translate: { contactLink: tenantService.getCurrentTenantData().hrefContact }"></div>

  <div class="re-mb-15 re-bdo-tile">
    <bdo-accountid-with-address></bdo-accountid-with-address>
  </div>
  <!-- shown on all backend errors -->
  <bdo-info-box class="bdo-container--single"
    *ngIf="state === LoadingState.ERROR"
    data-qa="error">
    {{ 'general.error.error' | translate }}
  </bdo-info-box>

  <div class="bdo-tile bdo-loading__wrapper" [class.bdo-loading__wrapper--height]="state === LoadingState.LOADING">
    <bdo-loading *ngIf="state === LoadingState.LOADING"></bdo-loading>
    <div class="bdo-container__content" *ngIf="state !== LoadingState.LOADING">
      <form #form="ngForm" (ngSubmit)="submit(form)" bdoScrollToInvalidControl [bdoScrollToInvalidControlForm]="form">
        <div class="bdo-space--bottom">
          <fieldset class="re-mb-20 md:re-mb-30">
            <div class="bdo-space--bottom bdo-form-item-container">
              <bdo-dropdown [data]="reasons"
                            data-qa="contact_reason"
                            [selected]="message.topic"
                            [requiredErrorText]="'contact.missingReason' | translate"
                            [forceValidate]="validateAll"
                            [required]="true"
                            [labelText]="'contact.label.reason' | translate"
                            (itemSelected)="message.topic = $event">
              </bdo-dropdown>
            </div>
            <div class="bdo-form-item-container bdo-space--bottom">
              <bdo-single-line-input
                (inputValueChanged)="message.subject = $event"
                [inputName]="'subject'"
                [placeholder]="'contact.label.subject' | translate"
                [inputType]="'text'"
                [inputValue]="message.subject"
                [required]="false"
              ></bdo-single-line-input>
            </div>
            <div class="bdo-form-item-container bdo-space--bottom">
              <bdo-multi-line-input
                [placeholder]="'contact.label.message' | translate"
                [errors]="[{ text: 'contact.missingMessage' | translate, name: 'required' }]"
                [required]="true"
                [inputName]="'message'"
                [inputValue]="message.message"
                [forceValidate]="validateAll"
                [maxLength]="255"
                (inputValueChanged)="message.message = $event"
              >
              </bdo-multi-line-input>
            </div>
          </fieldset>
          <div class="center">
            <button data-qa="submit" class="bdo-button-primary" type="submit">{{ 'general.action.submit' | translate }}</button>
          </div>
        </div>
      </form>
      <a class="bdo-nolink" [routerLink]="['../../']">
        <span class="bold"> {{ 'general.action.cancel' | translate }} </span>
      </a>
    </div>
  </div>
</div>

<!-- Success Popup -->
<bdo-simple-text-popup [isVisible]="showPopup" (closePopupEvent)="onClosePopup()" [title]="'contact.successPopup.title' | translate" data-qa="success" [icon]="'success'">
  <div class="re-copytext-with-margin">
    <div class="bdo-space--bottom space--top">
      {{ 'contact.successPopup.description' | translate }}
    </div>
  </div>
  <div class="home-link">
    <bdo-home-link [tracking]="[ TRACKING.LOCATION.CONTACT_SUCCESS, TRACKING.ACTION.GOTO, TRACKING.LOCATION.DASHBOARD ]"></bdo-home-link>
  </div>
</bdo-simple-text-popup>
