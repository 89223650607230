import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { delay, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { CampaignService, CurrentCampaign } from '../../../services/campaign.service';
import { TrackingService } from '../../../services/tracking.service';
import { CAMPAIGN_TILE_TYPE } from '../campaign-tile/campaign-tile.component';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { DatadogService } from '../../../services/datadog.service';

export const POPUP_DELAY = 600;

@Component({
  selector: 'bdo-campaign-popup',
  templateUrl: './campaign-popup.component.html'
})
export class CampaignPopupComponent implements OnInit, OnDestroy, OnChanges {

  @Input() popupIsReadyToShow$: Observable<boolean>;
  @Input() campaign: CurrentCampaign;
  public CampaignTileType = CAMPAIGN_TILE_TYPE;
  public dismissIsChecked = false;
  public show$ = new ReplaySubject<boolean>(1);
  // if popup gets shown -> delay, if it gets hidden -> no delay
  public showDelayed$ = this.show$.pipe(
    switchMap((show) => {
      if (show) {
        return of(show).pipe(delay(POPUP_DELAY));
      }
      return of(show);
    })
  );

  public bannerId$: Observable<string> = this.campaignService.currentCampaign$?.pipe(
    tap({ next: (campaign) => {this.datadogService.trackCustomEvent('Popup: ' + campaign?.toString());} }),
    map((campaign) => campaign?.info?.bannerId)
  );

  private subscriptions = new Subscription();

  constructor(private campaignService: CampaignService,
              private trackingService: TrackingService,
              private datadogService: DatadogService) {
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.dismissIsChecked = false; // reset checkbox if campaign changes
  }

  public ngOnInit(): void {

    // connect showState to an outer isVisible$ Observable (to react on outer events like Login/Logout/Change Customer I
    this.subscriptions.add(this.popupIsReadyToShow$.pipe(
      filter((isReady) => isReady),
      map (() => !this.campaign.dismissed),
      tap ({ next: (showCampaignPopup: boolean) => {
        if (showCampaignPopup) {
          this.trackCampaignBannerWasShown();
        }
      } })
    ).subscribe(this.show$));
  }

  /**
   * hide this popup by setting show observable to false, dismiss the campaign if the checkbox is checked
   */
  public closeAndDismiss(): void {
    this.show$.next(false);
    if (this.dismissIsChecked) {
      this.campaignService.dismissCampaign(this.campaign);
    }
  }

  public onClickCheckbox(): void {
    this.dismissIsChecked = !this.dismissIsChecked;
  }

  /**
   * hide this popup and navigate to the campaign, always dismiss the current campaign
   */
  public onClickCampaignButton() {
    this.campaignService.dismissCampaign(this.campaign);
    this.campaignService.goToCampaign().pipe(
      first(),
    ).subscribe(
      { next: () => {
          this.show$.next(false);
        }
      });
  }

  /**
   * tracked as soon as the show-variable switches to true
   */
  private trackCampaignBannerWasShown(): void {
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.CAMPAIGN, TRACKING.ACTION.SHOW + ' Banner '  + this.campaign.info.bannerId);
  }

}

