export const NetcolognePostcodes: Array<string> = [
  '41539',
  '41540',
  '41541',
  '41542',
  '41569',
  '42489',
  '42555',
  '42579',
  '42799',
  '45525',
  '45527',
  '45529',
  '50126',
  '50127',
  '50129',
  '50169',
  '50170',
  '50171',
  '50181',
  '50189',
  '50226',
  '50259',
  '50321',
  '50354',
  '50374',
  '50389',
  '50667',
  '50668',
  '50670',
  '50672',
  '50674',
  '50676',
  '50677',
  '50678',
  '50679',
  '50733',
  '50735',
  '50737',
  '50739',
  '50765',
  '50767',
  '50769',
  '50823',
  '50825',
  '50827',
  '50829',
  '50858',
  '50859',
  '50931',
  '50933',
  '50935',
  '50937',
  '50939',
  '50968',
  '50969',
  '50996',
  '50997',
  '50999',
  '51061',
  '51063',
  '51065',
  '51067',
  '51069',
  '51103',
  '51105',
  '51107',
  '51109',
  '51143',
  '51145',
  '51147',
  '51149',
  '51371',
  '51373',
  '51375',
  '51377',
  '51379',
  '51381',
  '51399',
  '51427',
  '51429',
  '51465',
  '51467',
  '51469',
  '51491',
  '51503',
  '51519',
  '51570',
  '51597',
  '52062',
  '52064',
  '52066',
  '52068',
  '52070',
  '52072',
  '52074',
  '52076',
  '52078',
  '52080',
  '52134',
  '52146',
  '52222',
  '52223',
  '52224',
  '52249',
  '52349',
  '52351',
  '52353',
  '52355',
  '52372',
  '52399',
  '52441',
  '52477',
  '52499',
  '52511',
  '52525',
  '52531',
  '53111',
  '53113',
  '53115',
  '53117',
  '53119',
  '53121',
  '53123',
  '53125',
  '53127',
  '53129',
  '53173',
  '53175',
  '53177',
  '53179',
  '53225',
  '53227',
  '53229',
  '53332',
  '53340',
  '53343',
  '53347',
  '53359',
  '53604',
  '53721',
  '53757',
  '53773',
  '53783',
  '53797',
  '53840',
  '53842',
  '53844',
  '53859',
  '53879',
  '53881',
  '53902',
  '53909',
  '53913',
  '53919',
  '58452',
  '58453',
  '58454',
  '58455'
];
