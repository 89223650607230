import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { MaintenanceService } from '../../bdo/services/maintenance.service';
import { TrackingService } from '../../bdo/services/tracking.service';
import { LoginService } from '../login.service';
import { RESPONSE_STATUS } from '../../bdo/enums/responseStatus.enum';
import { ACTIVATION_STATE } from '../../bdo/enums/activationState.enum';
import { TRACKING } from '../../bdo/enums/trackingParts.enum';

@Component({
  selector: 'bdo-register-activate',
  templateUrl: './register-activate.component.html',
  styleUrls: ['./register-activate.component.scss']
})
export class RegisterActivateComponent implements OnInit, OnDestroy {
  private maintenanceSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private loginService: LoginService,
    private trackingService: TrackingService,
    private maintenanceService: MaintenanceService,
    private router: Router
  ) { }

  ngOnInit() {
    this.trackingService.postTracking(TRACKING.LOCATION.REGISTER_ACTIVATE, TRACKING.ACTION.ENTER);
    const code: string = this.route.snapshot.queryParamMap.get('code');
    const token: string = this.route.snapshot.queryParamMap.get('token');
    const registerCode: string = this.route.snapshot.queryParamMap.get('registercode');
    this.maintenanceSubscription = this.maintenanceService.serviceUnavailable$
    .pipe(
      // block stream, if service is unavailable to avoid activation
      filter((serviceUnavailable) => serviceUnavailable !== true),
      switchMap(() => this.loginService.activate(code, token, registerCode))
    )
    .subscribe(
      {
        next: (res) => {
          if (res.status === RESPONSE_STATUS.Success) {
            this.router.navigate(['/login'], { state: { activate: RESPONSE_STATUS.Success } });
          } else {
            // If user needs to register again, redirect to register instead of login
            if (res.info === ACTIVATION_STATE.ACTIVATION_FAILED) {
              this.router.navigate(['/registrieren'], { state: { activate: res.info } });
            } else {
              this.router.navigate(['/login'], { state: { activate: res.info } });
            }
          }
        },
        error: () => {
          this.router.navigate(['/login'], { state: { activate: RESPONSE_STATUS.Error } });
        }
      });
  }

  public ngOnDestroy(): void {
    this.maintenanceSubscription.unsubscribe();
  }

}
