import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BdoApiService } from '../../../services/bdo-api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ALLOWED_KEYS, StorageService } from '../../../services/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackingService } from '../../../services/tracking.service';
import { TRACKING } from '../../../enums/trackingParts.enum';
import { INPUT_TYPE } from '../../../enums/inputType.enum';
import { ContractPaymentData } from '../../../../../assets/js/com/ts_api_client';
import { MovePaymentFormData } from '../../../models/movePaymentFormData';
import { Subscription } from 'rxjs';
import { PaymentFormtype } from '../../../../shared/formtypes/payment-formtype';
import { KameleoonService } from '../../../services/kameleoon.service';

@Component({
  selector: 'bdo-payment',
  templateUrl: './payment.component.html'
})
export class PaymentComponent implements OnInit, AfterViewInit, OnDestroy {

  public TRACKING = TRACKING;

  // @Short-Term-Todo: outsourcing; (maybe) mapping should be automatically detected
  public inputTypeMappings = {
    'paymentMethod': INPUT_TYPE.RADIO,
    'sepaAuth': INPUT_TYPE.CHECKBOX,
  }; // Needed for Tracking


  public paymentForm = new FormGroup<PaymentFormtype>({
    paymentMethod: new FormControl('sepa', { validators: [Validators.required] })
  });

  private subscriptions = new Subscription();

  constructor(
    public trackingService: TrackingService,
    public apiService: BdoApiService,
    public location: Location,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private kameleoonService: KameleoonService
  ) {
  }

  ngOnInit(): void {
    this.trackingService.postTracking(TRACKING.LOCATION.DELIVERY_PAYMENT, TRACKING.ACTION.ENTER);

    // update form when paymentMethod is chosen
    this.paymentForm.get('paymentMethod').valueChanges.subscribe({ next: value => {
      this.sepaUpdate(value);
      this.changeDetector.detectChanges();
    } });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


  ngAfterViewInit() {

    // prefill form, if data is available
    if (StorageService.getValue(ALLOWED_KEYS.PAYMENT_DATA)) {
      const data = StorageService.getValue<MovePaymentFormData>(ALLOWED_KEYS.PAYMENT_DATA);
      if (data?.paymentMethod === 'bankTransfer') {
        this.paymentForm.get('paymentMethod').setValue('bankTransfer');
      } else {
        this.paymentForm.setValue(data);
      }
      this.changeDetector.detectChanges();
    }

  }

  /**
   * setup validation if sepa is (not) chosen
   *
   * @param value
   */
  sepaUpdate(value: string) {
    if (value !== 'sepa') {
      this.paymentForm.removeControl('sepaAuth');
      this.paymentForm.removeControl('bankData');
    }
  }

  /**
   * @Short-Term-TODO: outsourcing for reusability
   *
   * @param inputName
   */
  trackInvalid(inputName: string) {
    const formControl = this.paymentForm.get(inputName);
    const inputType = this.inputTypeMappings[inputName];

    if (formControl.invalid) {
      this.trackFormError(inputName, inputType);
    }
  }

  /**
   * * @Short-Term-TODO: outsourcing for reusability
   *
   * @param inputName
   * @param inputType
   */
  trackFormError(inputName: string, inputType: string) {
    this.trackingService.postFormTracking(
      TRACKING.FORM.DELIVERY_PAYMENTDATA,
      TRACKING.FORM_ACTION.ERROR,
      TRACKING.FORM.DELIVERY_PAYMENTDATA,
      inputName,
      inputType
    );
  }


  /**
   * submit-process
   * validate, track, save and go to next step
   */
  public save(): void {

    if (this.paymentForm.invalid) {
      // mark all input fields as touched to display validation info
      this.paymentForm.markAllAsTouched();

      this.trackingService.postSimpleTracking(TRACKING.FORM.DELIVERY_PAYMENTDATA, ' invalid');
      // Iterate over all invalid values
      Object.keys(this.paymentForm.controls).forEach(key => {
        this.trackInvalid(key);
      });
      return;
    }
    this.trackingService.postFormTracking(TRACKING.FORM.DELIVERY_PAYMENTDATA, TRACKING.FORM_ACTION.SUBMIT);
    this.trackingService.postSimpleTracking(
      TRACKING.LOCATION.DELIVERY_PAYMENT, TRACKING.FORM_ACTION.SELECT, 'Payment.' + this.paymentForm.get('paymentMethod').value
    );
    this.trackingService.postSimpleTracking(TRACKING.LOCATION.DELIVERY_PAYMENT, TRACKING.ACTION.GOTO + ' zusammenfassung');

    // Save Data and goto next page (when all data is set)
    const formData: ContractPaymentData = {};
    Object.keys(this.paymentForm.controls).forEach(key => {
      formData[key] = this.paymentForm.controls[key].value;
    });

    StorageService.setValue(ALLOWED_KEYS.PAYMENT_DATA, formData);

    this.kameleoonService.processConversion(KameleoonService.Goals.DELIVERY_BANKDATA_DONE);

    this.router.navigate(['../uebersicht'], { relativeTo: this.activatedRoute });
  }

}
