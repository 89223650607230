<div class="bdo-container re-mb-30
            md:re-mb-60">
  <h3>{{ 'delivery.status.title' | translate }}</h3>
</div>

<div class="bdo-link-tile-group">
  <bdo-link-tile class="re-order-2 md:re-order-1"
    [title]="'delivery.status.old.title' | translate"
    [linktext]="'delivery.status.old.button' | translate"
    [iconSrc]="{ default: 'delivery.status.old.imgSmall' | translate,
                 lg: 'delivery.status.old.img' | translate }"
    [description]="'delivery.status.old.description' | translate"
    [destination]="'/dashboard'"
    [trackingLocation]="TRACKING.LOCATION.DELIVERY_CUSTOMERSTATUS"
    [trackingDestination]="TRACKING.LOCATION.DASHBOARD"
  ></bdo-link-tile>

  <bdo-link-tile class="re-order-1 md:re-order-2"
    [title]="'delivery.status.new.title' | translate"
    [linktext]="'delivery.status.new.button' | translate"
    [iconSrc]="{ default: 'delivery.status.new.imgSmall' | translate,
                 lg: 'delivery.status.new.img' | translate }"
    [description]="'delivery.status.new.description' | translate"
    [destination]="'../neu'"
    [trackingLocation]="TRACKING.LOCATION.DELIVERY_CUSTOMERSTATUS"
    [trackingDestination]="TRACKING.LOCATION.DELIVERY_PERSONALDATA"
    data-qa="button_new-customer"
  ></bdo-link-tile>
</div>
