import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SwitchCardConfiguration } from '../../models/switchCardConfiguration';

@Component({
  selector: 'bdo-switch-card',
  templateUrl: './switch-card.component.html',
  styleUrls: ['./switch-card.component.scss']
})
export class SwitchCardComponent {
  @Input() configuration: SwitchCardConfiguration[];
  @Input() selectedTile: string;
  @Output() selected: EventEmitter<string> = new EventEmitter();

  onSelect(selectedTile: string) {
    this.selectedTile = selectedTile;
    this.selected.emit(selectedTile);
  }

}
