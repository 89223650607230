"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RuleType = void 0;
var RuleType;
(function (RuleType) {
  RuleType["Experimentation"] = "EXPERIMENTATION";
  RuleType["TargetedDelivery"] = "TARGETED_DELIVERY";
})(RuleType = exports.RuleType || (exports.RuleType = {}));