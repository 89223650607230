/**
 * NLB-GW
 *
 * Contact: service@rheinenergie.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * divisions and productId to change to
 */
export interface TariffChangeRequestTbProductsToChange { 
    /**
     * division of product e.g. S4 for power, G6 for gas or T4 for water
     */
    divisionId?: string;
    /**
     * productId / productId for a given tariff
     */
    productId?: string;
    /**
     * web tariff name from Tarifberater for contract e.g. Tarif für Freiheitsliebende, Tarif für Heimatverbundene
     */
    tbTariffName?: string;
    /**
     * keine Mind-Lfz/Kdg
     */
    noMindlfzKdg?: string;
    consumption?: number;
}

